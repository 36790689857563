import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  memo,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import { AuthBreadCrumb } from '../auth_common/AuthBreadCrumb';
import { AuthFooter } from '../auth_common/AuthFooter';
import { AuthHeader } from '../auth_common/AuthHeader';
import Auth_header_mobile from '../auth_common/Auth_header_mobile';
import ViewButton from '../school/ViewButton';
import { useNavigate } from 'react-router-dom';
import ViewProgramBtn from './ViewProgramBtn';
import Pagination from '../common/Pagination';
import PermissionComponent from '../auth/PermissionComponent';
import FilterComponent from '../filter/FilterComponent';
import {
  SIDEBAR_OPTIONS,
  inputFields,
  newfieldsToExport,
} from '../../shared/enums/programs_enum';
import SidebarFilter from '../common/SidebarFilter';
import {
  UseDataForExcel,
  UseFilter,
  UseDataForExcelPrograms,
} from '../../shared/hooks/UseExcel';
import ExportComponent from '../../components/common/ExportComponent';
import {
  useAllPrograms,
  useAllProgramsReport,
  useDeleteBulkProgram,
  useDraftBulkProgram,
  usePublishBulkProgram,
} from '../../shared/hooks/UseProgram';
import { ProgramFilterComponent } from './ProgramFilterComponent';

function ProgramComponent({}) {
  console.count('Program Component Mount');
  const { data, setPageNumber, reloadPrograms } = useAllPrograms();
  const { deleteBulkData } = useDeleteBulkProgram();
  const { draftBulkData } = useDraftBulkProgram();
  const { publishBulkData } = usePublishBulkProgram();
  const { programs, programs_loading, pages, program_page, total_programs } =
    data;

  const navigate = useNavigate();
  const [showExportButton, setShowExportButton] = useState(false);
  const { reportData, getData, loading_report } = useAllProgramsReport();

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcelPrograms();

  useEffect(() => {
    if (reportData && reportData.programs) {
      convertToReadable(reportData.programs, newfieldsToExport);
    }
  }, [reportData]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Programs', 'programs');
  };
  // Each Column Definition results in one Column.
  const [checkedEmployees, setCheckedEmployees] = useState([]);
  const [checkDeleteBulk, setCheckDeleteBulk] = useState(false);

  const selectAllEmployees = () => {
    if (checkedEmployees.length === programs.length) {
      setCheckedEmployees([]);
    } else {
      const employeeIDs = programs.map((employee) => employee._id);
      setCheckedEmployees(employeeIDs);
    }
  };

  const deleteSchoolBtnClicked = () => {
    deleteBulkData(checkedEmployees);
  };
  const bulkDraftProgramHandler = () => {
    draftBulkData(checkedEmployees);
  };
  const bulkActiveProgramHandler = () => {
    publishBulkData(checkedEmployees);
  };

  return (
    <>
      <Auth_header_mobile />
      <AuthHeader active={STUDENT_PROFILE_ENUM.ProgramSChools} />
      <AuthBreadCrumb title={'Programs'} />
      <section className='ptb-20'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='all-school-flex'>
              <div className='school-and-programs '>
                <ul>
                  <li>
                    <Link to={links.schools}>
                      <i className='fa fa-university'></i>
                      School
                    </Link>
                  </li>
                  <li>
                    <Link className='active-tab' to={links.programs}>
                      <i className='fa fa-tasks'></i> Programs
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                {checkedEmployees && checkedEmployees.length > 0 && (
                  <>
                    <button
                      className='btn btn-danger'
                      onClick={() => setCheckDeleteBulk(true)}
                    >
                      Delete all ({checkedEmployees.length}){' '}
                    </button>
                    <button
                      className='btn btn-warning'
                      onClick={() => bulkDraftProgramHandler()}
                    >
                      Move to draft ({checkedEmployees.length}){' '}
                    </button>
                    <button
                      className='btn btn-success'
                      onClick={() => bulkActiveProgramHandler()}
                    >
                      Publish ({checkedEmployees.length}){' '}
                    </button>
                  </>
                )}
              </div>
              <div className='export-flex'>
                <div className='add-student-btn'>
                  <PermissionComponent
                    name={'program'}
                    permission={'create'}
                    hideOnStudent={true}
                    hideOnAgent={true}
                  >
                    <Link to={links.addProgram} className='btn primary-btn-h '>
                      Add Program
                    </Link>
                  </PermissionComponent>
                </div>
                <div className=''>
                  <PermissionComponent
                    name={'program'}
                    permission={'create'}
                    hideOnStudent={true}
                    hideOnAgent={true}
                  >
                    {showExportButton ? (
                      !loading_report ? (
                        <ExportComponent handleOnExport={handleOnExport} />
                      ) : (
                        <button className='btn btn-generate'>
                          Generating...{' '}
                        </button>
                      )
                    ) : (
                      <button
                        className='btn btn-generate'
                        onClick={() => {
                          setShowExportButton(true);
                          getData();
                        }}
                      >
                        Generate Report
                      </button>
                    )}
                  </PermissionComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PermissionComponent name={'program'} permission={'get_all'}>
        <section className='students-section'>
          <div className='container-fluid'>
            <div className='row'>
              {/* {SIDEBAR_OPTIONS && (
                <SidebarFilter
                  SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                  dropdown_options={dropdownOptions}
                  // loadOptions={loadOptions}
                />
              )} */}
              <ProgramFilterComponent />
              <div className='col-md-10'>
                <div className='myapplication-table'>
                  <table className='table table-responsive table-sm  table-bordered table-striped  '>
                    <thead>
                      <tr>
                        <PermissionComponent
                          name={'program'}
                          permission={'delete'}
                          hideOnAcademicPartner={true}
                          hideOnAgent={true}
                          hideOnCounsellor={true}
                          hideOnStudent={true}
                        >
                          <th scope='col '>
                            {' '}
                            <input
                              type='checkbox'
                              onClick={() => selectAllEmployees()}
                            />
                          </th>
                        </PermissionComponent>
                        <th scope='col '>Name</th>

                        <th scope='col'>School</th>
                        <th scope='col'>Country</th>
                        <th scope='col'>State</th>
                        <th scope='col'>City</th>
                        <th scope='col'>Currency</th>
                        <th scope='col'>Application Fee</th>

                        <th scope='col'>Education Level</th>
                        <PermissionComponent
                          name={'program'}
                          permission={'get_all'}
                          hideOnAgent={true}
                          hideOnStudent={true}
                        >
                          <th scope='col'>Status</th>
                        </PermissionComponent>
                        <th scope='col'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {programs &&
                        programs.map((program) => {
                          return (
                            <tr key={program._id}>
                              <PermissionComponent
                                name={'program'}
                                permission={'delete'}
                                hideOnAcademicPartner={true}
                                hideOnAgent={true}
                                hideOnCounsellor={true}
                                hideOnStudent={true}
                              >
                                <td>
                                  <input
                                    type='checkbox'
                                    checked={checkedEmployees.includes(
                                      program._id
                                    )}
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setCheckedEmployees([
                                          ...checkedEmployees,
                                          program._id,
                                        ]);
                                      } else {
                                        setCheckedEmployees(
                                          checkedEmployees.filter(
                                            (id) => id !== program._id
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </PermissionComponent>
                              <td>{program.name}</td>
                              <td>
                                {program &&
                                  program.school &&
                                  program.school.name}
                              </td>
                              <td>{program.country}</td>
                              <td>{program.state}</td>
                              <td>{program.city}</td>
                              <td>
                                {program &&
                                  program.school &&
                                  program.school.market &&
                                  program.school.market.currency}
                              </td>
                              <td>{program.application_fee}</td>
                              <td>
                                {program &&
                                  program.education_level &&
                                  program.education_level.name}
                              </td>
                              <PermissionComponent
                                name={'program'}
                                permission={'get_all'}
                                hideOnAgent={true}
                                hideOnStudent={true}
                              >
                                <td>
                                  <span className='badge badge inclass'>
                                    {program.status}
                                  </span>
                                </td>
                              </PermissionComponent>

                              <td className='status-ic'>
                                <Link
                                  target='_blank'
                                  to={`/schools/${
                                    program &&
                                    program.school &&
                                    program.school._id
                                  }/programs/${program._id}`}
                                  className='badge badge-view'
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      {programs && programs.length == 0 && (
                        <tr>
                          <td colSpan={10}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <p> {total_programs} Records Found </p>
                <Pagination
                  count={total_programs}
                  pages={pages}
                  loading={programs_loading}
                />
              </div>
            </div>
          </div>
        </section>
      </PermissionComponent>
      {checkDeleteBulk && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setCheckDeleteBulk(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>
                    Do you really want to delete this record? This process
                    cannot be undone.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setCheckDeleteBulk(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteSchoolBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AuthFooter />
    </>
  );
}

export default memo(ProgramComponent);
