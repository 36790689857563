export const GET_APPLICATION_NOTES_STARTED = 'GET_APPLICATION_NOTES_STARTED';
export const GET_APPLICATION_NOTES = 'GET_APPLICATION_NOTES';
export const GET_APPLICATION_NOTES_ENDED = 'GET_APPLICATION_NOTES_ENDED';
export const GET_APPLICATION_NOTES_ERROR = 'GET_APPLICATION_NOTES_ERROR';
export const ADD_APPLICATION_NOTE_STARTED = 'ADD_APPLICATION_NOTE_STARTED';
export const ADD_APPLICATION_NOTE = 'ADD_APPLICATION_NOTE';
export const ADD_APPLICATION_NOTE_ENDED = 'ADD_APPLICATION_NOTE_ENDED';
export const ADD_APPLICATION_NOTE_ERROR = 'ADD_APPLICATION_NOTE_ERROR';
export const EDIT_APPLICATION_NOTE_STARTED = 'EDIT_APPLICATION_NOTE_STARTED';
export const EDIT_APPLICATION_NOTE = 'EDIT_APPLICATION_NOTE';
export const EDIT_APPLICATION_NOTE_ENDED = 'EDIT_APPLICATION_NOTE_ENDED';
export const EDIT_APPLICATION_NOTE_ERROR = 'EDIT_APPLICATION_NOTE_ERROR';
export const GET_APPLICATION_NOTE = 'GET_APPLICATION_NOTE';
export const GET_APPLICATION_NOTE_STARTED = 'GET_APPLICATION_NOTE_STARTED';
export const GET_APPLICATION_NOTE_ENDED = 'GET_APPLICATION_NOTE_ENDED';
export const GET_APPLICATION_NOTE_ERROR = 'GET_APPLICATION_NOTE_ERROR';
