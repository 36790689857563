import {
  GET_COUNTRIES_STARTED,
  GET_COUNTRIES,
  GET_COUNTRIES_ENDED,
  ADD_COUNTRY_STARTED,
  ADD_COUNTRY,
  ADD_COUNTRY_ENDED,
  EDIT_COUNTRY_STARTED,
  EDIT_COUNTRY,
  EDIT_COUNTRY_ENDED,
  GET_COUNTRY,
  GET_COUNTRY_STARTED,
  GET_COUNTRY_ENDED,
} from '../types/country_types';

const initialState = {
  countries_loading: true,
  countries: null,
  country_page: null,
  countries_pages: null,
  total_countries: 0,

  country: null,
  country_loading: null,

  add_country_loading: true,
  edit_country_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COUNTRIES_STARTED:
      return {
        ...state,
        countries: null,
        country_pages: null,
        country_page: null,
        total_countries: 0,
        countries_loading: true,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: payload.countries,
        country_pages: payload.pages,
        country_page: payload.page,
        total_countries: payload.total_countries,
      };
    case GET_COUNTRIES_ENDED:
      return {
        ...state,
        countries_loading: false,
      };

    case ADD_COUNTRY_STARTED:
      return {
        ...state,
        country_message: null,
        add_country_loading: true,
      };
    case ADD_COUNTRY:
      return {
        ...state,
        country_message: payload,
      };
    case ADD_COUNTRY_ENDED:
      return {
        ...state,
        add_country_loading: false,
      };
    case GET_COUNTRY_STARTED:
      return {
        ...state,
        country: null,
        country_loading: true,
      };
    case GET_COUNTRY:
      return {
        ...state,
        country: payload,
      };
    case GET_COUNTRY_ENDED:
      return {
        ...state,
        country_loading: false,
      };
    case EDIT_COUNTRY_STARTED:
      return {
        ...state,
        country_message: null,
        edit_country_loading: true,
      };
    case EDIT_COUNTRY:
      return {
        ...state,
        country_message: payload,
      };
    case EDIT_COUNTRY_ENDED:
      return {
        ...state,
        edit_country_loading: false,
      };

    default:
      return state;
  }
}
