import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
} from '../../components/Form/Form';
import * as Yup from 'yup';
import { countries } from '../../domain/countries';
import { useUpdateEducationInfo } from '../../shared/hooks/UseEducation';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';
import { useAllGradings } from '../../shared/hooks/UseGrading';
import SingleProfileComponent from '../cards/SingleProfileComponent';

export const EducationSummary = ({ student_info, hideEdit }) => {
  const { userEducationInfo, student } = student_info;
  const { updateEducation } = useUpdateEducationInfo();
  const { educationLevelData } = useAllEducationLevels();
  const { education_levels } = educationLevelData;
  const { grading_data } = useAllGradings();
  const { gradings } = grading_data;

  const [editing, setEditing] = useState(false);

  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='d-flex justify-content-between'>
          <div>
            <h2>Education Summary</h2>
          </div>
          {!hideEdit && (
            <div>
              <button
                className='btn btn-assign'
                onClick={() => setEditing(!editing)}
              >
                {' '}
                {editing ? (
                  <i className='fa fa-close'></i>
                ) : (
                  <i className='fa fa-pencil'></i>
                )}{' '}
              </button>
            </div>
          )}
        </div>

        {editing ? (
          <>
            <Formik
              initialValues={{
                country_of_education: userEducationInfo
                  ? userEducationInfo.country_of_education
                  : '',

                stream: userEducationInfo ? userEducationInfo.stream : '',

                highest_level_of_education:
                  userEducationInfo &&
                  userEducationInfo.highest_level_of_education
                    ? userEducationInfo.highest_level_of_education._id
                    : '',
                grading_scheme: userEducationInfo
                  ? userEducationInfo.grading_scheme
                  : '',
                grade_average: userEducationInfo
                  ? userEducationInfo.grade_average
                  : '',
                graduated_from_same_institution: userEducationInfo
                  ? userEducationInfo.graduated_from_same_institution
                  : false,
              }}
              validationSchema={Yup.object({
                country_of_education: Yup.string().required('Required'),

                highest_level_of_education: Yup.string().required('Required'),
                grading_scheme: Yup.string().required('Required'),
                grade_average: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                updateEducation(student._id, values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <span>
                      Please enter the information for the highest academic
                      level that you have completed.
                    </span>
                    <div className='row'>
                      <div className='col-md-6'>
                        <SelectBox
                          label='Country Of Education'
                          name='country_of_education'
                        >
                          <option value=''></option>
                          {countries &&
                            countries.map((e) => {
                              return <option>{e.name}</option>;
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-6'>
                        <SelectBox
                          label='Highest Level of Education'
                          name='highest_level_of_education'
                        >
                          <option value=''></option>
                          {education_levels &&
                            education_levels.map((item) => {
                              return (
                                <option value={item._id}>{item.name}</option>
                              );
                            })}
                        </SelectBox>
                      </div>

                      <div className='col-md-6'>
                        <SelectBox label='Grading System' name='grading_scheme'>
                          <option value=''></option>
                          {gradings &&
                            gradings.map((item) => {
                              return (
                                <option value={item._id}> {item.name} </option>
                              );
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-6'>
                        {formik.values.grading_scheme ? (
                          <>
                            {gradings &&
                            gradings.filter(
                              (item) =>
                                item._id === formik.values.grading_scheme
                            ) &&
                            gradings.filter(
                              (item) =>
                                item._id === formik.values.grading_scheme
                            )[0] &&
                            gradings.filter(
                              (item) =>
                                item._id === formik.values.grading_scheme
                            )[0].type === 'letter' ? (
                              <SelectBox
                                label='Grade Average'
                                name='grade_average'
                              >
                                <option value=''></option>
                                {gradings
                                  .filter(
                                    (item) =>
                                      item._id === formik.values.grading_scheme
                                  )[0]
                                  .letter_grades.map((item) => {
                                    return (
                                      <option value={item.name}>
                                        {' '}
                                        {item.name}{' '}
                                      </option>
                                    );
                                  })}
                              </SelectBox>
                            ) : (
                              <TextInput
                                label='Grade Average'
                                name='grade_average'
                              />
                            )}
                          </>
                        ) : (
                          <TextInput
                            label='Grade Average'
                            name='grade_average'
                          />
                        )}
                      </div>
                      <div className='col-md-6'>
                        {formik.values.grading_scheme ? (
                          <>
                            {gradings &&
                            gradings.filter(
                              (item) =>
                                item._id === formik.values.grading_scheme
                            ) &&
                            gradings.filter(
                              (item) =>
                                item._id === formik.values.grading_scheme
                            )[0] &&
                            gradings.filter(
                              (item) =>
                                item._id === formik.values.grading_scheme
                            )[0].type === 'letter' ? (
                              <SelectBox
                                label='Grade Average'
                                name='grade_average'
                              >
                                <option value=''></option>
                                {gradings
                                  .filter(
                                    (item) =>
                                      item._id === formik.values.grading_scheme
                                  )[0]
                                  .letter_grades.map((item) => {
                                    return (
                                      <option value={item.name}>
                                        {' '}
                                        {item.name}{' '}
                                      </option>
                                    );
                                  })}
                              </SelectBox>
                            ) : (
                              <TextInput
                                label='Grade Average'
                                name='grade_average'
                              />
                            )}
                          </>
                        ) : (
                          <TextInput
                            label='Grade Average'
                            name='grade_average'
                          />
                        )}
                        <TextInput
                          label='Stream (Maths / Bio/ Commerce) '
                          name='stream'
                        />
                      </div>
                      <div className='col-md-12'>
                        <label>Graduated from same institution *</label>
                        <CheckBox
                          label='Yes'
                          name='graduated_from_same_institution_no_check'
                          checked={
                            formik.values.graduated_from_same_institution
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue(
                                'graduated_from_same_institution',
                                true
                              );
                            }
                          }}
                        >
                          Yes
                        </CheckBox>
                        <CheckBox
                          label='No'
                          name='graduated_from_same_institution_check'
                          checked={
                            !formik.values.graduated_from_same_institution
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue(
                                'graduated_from_same_institution',
                                false
                              );
                            }
                          }}
                        >
                          No
                        </CheckBox>
                      </div>
                    </div>
                    <div className='save-button'>
                      <button className='btn primary-btn w-100'>
                        Save & Continue
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <div className='row'>
              <SingleProfileComponent
                col={4}
                label={'Country of Education'}
                value={
                  userEducationInfo && userEducationInfo.country_of_education
                }
              />
              <SingleProfileComponent
                col={4}
                label={'Highest Level of Education'}
                value={
                  userEducationInfo &&
                  userEducationInfo.highest_level_of_education &&
                  userEducationInfo.highest_level_of_education.name
                }
              />
              {/* <SingleProfileComponent
                col={4}
                label={'Grading  Scheme'}
                value={userEducationInfo && userEducationInfo.grading_scheme}
              /> */}
              <SingleProfileComponent
                col={4}
                label={'Grading  Average'}
                value={userEducationInfo && userEducationInfo.grade_average}
              />
              <SingleProfileComponent
                col={4}
                label={'Stream'}
                value={userEducationInfo && userEducationInfo.stream}
              />
              <SingleProfileComponent
                col={4}
                label={'Graduated From same institute?'}
                value={
                  userEducationInfo &&
                  userEducationInfo.graduated_from_same_institution
                    ? 'YES'
                    : 'NO'
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
