import moment from 'moment';

const PaymentTableComponent = ({ title, data }) => {
  return (
    <section className='payment-description ptb-30'>
      <div className='container-fluid'>
        <div> {title} </div>
        <div className='table-responsive'>
          <div></div>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Date</th>
                <th scope='col'>Description</th>
                <th scope='col'>Currency</th>
                <th scope='col'>Edcimp Commission</th>

                <th scope='col'> Paid From School</th>
                <th scope='col'> Agent Commission </th>
                <th scope='col'> Paid to Agent </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => {
                  return (
                    <tr>
                      <td>
                        {' '}
                        {item.createdAt &&
                          moment(item.createdAt).format('DD-MM-YYYY')}{' '}
                      </td>
                      <td>
                        {item.application_id} {item.school && item.school.name}{' '}
                        ({item.program && item.program.name})
                      </td>
                      <td> {item.currency} </td>
                      <td>{item.commission_prospect_edchimp} </td>
                      <td>
                        {item.paid_from_college ? (
                          <span className='badge badge inclass'>Paid</span>
                        ) : (
                          <span className='badge badge-draft'>Not Paid</span>
                        )}{' '}
                      </td>
                      <td>{item.commission_prospect_agent} </td>
                      <td>
                        {item.paid_to_agent ? (
                          <span className='badge badge inclass'>Paid</span>
                        ) : (
                          <span className='badge badge-draft'>Not Paid</span>
                        )}{' '}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PaymentTableComponent;
