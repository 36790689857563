import { useDispatch } from 'react-redux';
import {
  updateSchoolAttended,
  updateSchoolAttendedStudent,
} from '../../store/actions/school_attended_action';

export const useUpdateSchoolAttendedInfo = (student_id, data) => {
  const dispatch = useDispatch();

  const updateSchoolAttendedInfo = async (id, values) => {
    dispatch(updateSchoolAttended(id, values));
  };

  return {
    updateSchoolAttendedInfo,
  };
};
export const useUpdateSchoolAttendedInfoStudent = (
  student_id,
  student,
  data
) => {
  const dispatch = useDispatch();

  const updateSchoolAttendedInfoStudent = async (id, student, values) => {
    dispatch(updateSchoolAttendedStudent(id, student, values));
  };

  return {
    updateSchoolAttendedInfoStudent,
  };
};
