import React from 'react';
import OthersSubmission from './OthersSubmission';
import PrePaymentCard from './PrePaymentCard';
import PreSubmissionCard from './PreSubmissionCard';
import PostDecisionCard from './PostDecisionCard';
import PreDecision from './PreDecisionCard';
import EnrollmentConfirmationCard from './EnrollmentConfirmationCard';
import PreArrivalRequirementCard from './PreArivalRequirementCard';
import PostArrivalRequirementCard from './PostArrivalRequirementCard';
import { Link } from 'react-router-dom';
import PermissionComponent from '../auth/PermissionComponent';
import { PersonalInformationDocuments } from '../general_information/PersonalInformationDocuments';
import { SchoolAttendedDocuments } from '../education_history/SchoolAttendedDocuments';

function SingleApplicationApplicationRequirementsComponent({
  application,
  applicationRequirements,
  student,
}) {
  return (
    <div className='prepayment-box-card'>
      <PermissionComponent
        name={'application'}
        permission={'view'}
        hideOnAgent={true}
        hideOnStudent={true}
      >
        <div className='d-flex justify-content-end'>
          <div>
            <Link
              className='btn btn-primary active-tab'
              to={`/my-applications/${application._id}/add-requirement`}
            >
              Add New Requirement
            </Link>
          </div>
        </div>
      </PermissionComponent>
      <div className='col-md-12'>
        <PersonalInformationDocuments
          student_info={student}
          student_loading={false}
        />
      </div>
      {/* <div className='col-md-12'>
        <SchoolAttendedDocuments
          student_info={student}
          student_loading={false}
        />
      </div> */}
      <PrePaymentCard
        application={application}
        applicationRequirements={applicationRequirements}
      />
      {/* <PreSubmissionCard
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
      {/* <PreDecision
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
      {/* <PostDecisionCard
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
      {/* <EnrollmentConfirmationCard
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
      {/* <PreArrivalRequirementCard
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
      {/* <PostArrivalRequirementCard
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
      {/* <OthersSubmission
        application={application}
        applicationRequirements={applicationRequirements}
      /> */}
    </div>
  );
}

export default SingleApplicationApplicationRequirementsComponent;
