import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
function AllRolesAndPermissionsComponent({
  permissions_loading,
  permissions,
  permission_page,
  permission_pages,
  total_permissions,
  setPageNumber,
}) {
  const navigate = useNavigate();
  const [columnDefs] = useState([
    { field: 'name' },

    {
      field: 'Actions',
      pinned: 'right',
      cellRenderer: ViewButtonByID,
      cellRendererParams: {
        clicked: function (field) {
          navigate(`/settings/permissions/${field}/view`);
        },
        edit_clicked: function (field) {
          navigate(`/settings/permissions/${field}/edit`);
        },
      },
    },
  ]);
  return (
    <PermissionComponent name={'roles_permission'} permission={'get_all'}>
      <div>
        <p> {total_permissions}Records Found </p>
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <th scope='col '>Name</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {permissions &&
                permissions.map((permission) => {
                  return (
                    <tr key={permission._id}>
                      <td>{permission.name}</td>

                      <td className='status-ic'>
                        <Link
                          to={`/settings/permissions/${permission._id}/view`}
                          className='badge badge-view'
                        >
                          View
                        </Link>
                        <Link
                          to={`/settings/permissions/${permission._id}/edit`}
                          className='badge badge-edit'
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {permissions && permissions.length == 0 && (
                <tr>
                  <td colSpan={10}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={permissions}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={permissions}
          count={total_permissions}
          pages={permission_pages}
          loading={permissions_loading}
        />
      </div>
    </PermissionComponent>
  );
}

export default AllRolesAndPermissionsComponent;
