export const GET_SLABS_STARTED = 'GET_SLABS_STARTED';
export const GET_SLABS = 'GET_SLABS';
export const GET_SLABS_ENDED = 'GET_SLABS_ENDED';
export const GET_SLABS_ERROR = 'GET_SLABS_ERROR';
export const ADD_SLAB_STARTED = 'ADD_SLAB_STARTED';
export const ADD_SLAB = 'ADD_SLAB';
export const ADD_SLAB_ENDED = 'ADD_SLAB_ENDED';
export const ADD_SLAB_ERROR = 'ADD_SLAB_ERROR';
export const EDIT_SLAB_STARTED = 'EDIT_SLAB_STARTED';
export const EDIT_SLAB = 'EDIT_SLAB';
export const EDIT_SLAB_ENDED = 'EDIT_SLAB_ENDED';
export const EDIT_SLAB_ERROR = 'EDIT_SLAB_ERROR';
export const GET_SLAB = 'GET_SLAB';
export const GET_SLAB_STARTED = 'GET_SLAB_STARTED';
export const GET_SLAB_ENDED = 'GET_SLAB_ENDED';
export const GET_SLAB_ERROR = 'GET_SLAB_ERROR';
