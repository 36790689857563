import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AgentsComponent from '../../components/agents/AgentsComponent';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllAgents, useAllAgentsReport } from '../../shared/hooks/UseAgent';
import FilterComponent from '../../components/filter/FilterComponent';
import AgentFilter from '../../components/agents/AgentFilter';
import {
  UseDataForExcel,
  UseDataForExcelAgents,
  UseFilter,
} from '../../shared/hooks/UseExcel';
import ExportComponent from '../../components/common/ExportComponent';
import { inputFields } from '../../shared/enums/agents_enum';

function Agents() {
  const { data, setPageNumber } = useAllAgents();

  const [showExportButton, setShowExportButton] = useState(false);

  const { agents_loading, agents, agent_page, agent_pages, total_agents } =
    data;
  const { reportData, getData, loading_report } = useAllAgentsReport();
  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcelAgents();

  useEffect(() => {
    if (reportData && reportData.agents) {
      convertToReadable(reportData.agents, inputFields);
    }
  }, [reportData]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Agents', 'agents');
  };

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Agents'} />

      <SettingNavigationComponent active_link={SETTING_MENU_ENUM.AGENTS} />
      <PermissionComponent name={'agents'} permission={'get_all'}>
        <section className='profile-main ptb-20'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-6'>
                <div className='main-sec-heading'>
                  <h1>Agents</h1>
                </div>
              </div>
              <div className='col-md-6 col-6'>
                <PermissionComponent name={'agents'} permission={'create'}>
                  <div className='add-student-btn'>
                    <Link to={links.addAgent} className='btn primary-btn-h '>
                      Add Agent
                    </Link>
                  </div>
                  <div className=''>
                    <PermissionComponent
                      name={'program'}
                      permission={'create'}
                      hideOnStudent={true}
                      hideOnAgent={true}
                    >
                      {showExportButton ? (
                        !loading_report ? (
                          <ExportComponent handleOnExport={handleOnExport} />
                        ) : (
                          <button className='btn btn-generate'>
                            Generating...{' '}
                          </button>
                        )
                      ) : (
                        <button
                          className='btn btn-generate'
                          onClick={() => {
                            setShowExportButton(true);
                            getData();
                          }}
                        >
                          Generate Report
                        </button>
                      )}
                    </PermissionComponent>
                  </div>
                </PermissionComponent>
              </div>
            </div>
          </div>
        </section>

        {/* <FilterComponent /> */}
        <AgentFilter />
        <section className='students-section'>
          {agents && (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <AgentsComponent
                    agents_loading={agents_loading}
                    agents={agents}
                    agent_page={agent_page}
                    agent_pages={agent_pages}
                    total_agents={total_agents}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </PermissionComponent>
      <AuthFooter />
    </div>
  );
}

export default Agents;
