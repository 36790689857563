import React from 'react';
import { useParams } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import SearchProgramAndSchoolComponent from '../../components/search_program_schools/SearchProgramAndSchoolComponent';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllPrograms } from '../../shared/hooks/UseProgram';
import { useAllSchools } from '../../shared/hooks/UseSchool';

function SearchProgramAndSchools({
  hideBreadCrumb,
  hideFooter,
  hideStatus,
  hideAction,
  hideStartApplication,
}) {
  const { data, setPageNumber } = useAllPrograms();
  const { programs, programs_loading, program_pages, total_programs } = data;
  const { schools_data } = useAllSchools();
  const { schools, schools_loading } = schools_data;

  return (
    <div>
      {!hideBreadCrumb && (
        <>
          <Auth_header_mobile />
          <AuthHeader />
          <AuthBreadCrumb title={'Search Programs And Schools'} />
        </>
      )}

      <section className='profile-main ptb-30'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <SearchProgramAndSchoolComponent
                programs={programs}
                programs_loading={programs_loading}
                total_programs={total_programs}
                program_pages={program_pages}
                schools={schools}
                schools_loading={schools_loading}
                hideStartApplication={hideStartApplication}
              />
            </div>
          </div>
        </div>
      </section>
      {!hideFooter && <AuthFooter />}
    </div>
  );
}

export default SearchProgramAndSchools;
