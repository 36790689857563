import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { useAppSelector } from "../../state/hooks";
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';

const BeforeLoginRoute = ({ children }) => {
  const user = useCheckAuthenticated();
  const location = useLocation();
  return user.user_loading ? (
    <div> Loading...</div>
  ) : user.isAuthenticated ? (
    <Navigate to={'/dashboard'} state={{ from: location }} replace />
  ) : (
    children
  );
};

export default BeforeLoginRoute;
