import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import { AuthProfileTabs } from '../../components/auth_common/AuthProfileTabs';
import { AddressDetails } from '../../components/general_information/AddressDetails';
import { PersonalInformation } from '../../components/general_information/PersonalInforamtion';
import { PROFILE_TABS_ENUM } from '../../domain/profiletab';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import {
  useGetStudentProfile,
  useDeleteStudent,
} from '../../shared/hooks/UseStudent';
import PermissionComponent from '../../components/auth/PermissionComponent';

export const SingleStudent = () => {
  const navigate = useNavigate();
  const { deleteStudentFunction } = useDeleteStudent();
  const { getStudentInfo, student, student_loading } = useGetStudentProfile();
  const params = useParams();
  useEffect(() => {
    if (params) {
      getStudentInfo(params.id);
    }
  }, [params]);
  const [checkDeleteStudent, setCheckDeleteStudent] = useState(false);
  const deleteStudentBtnClicked = async () => {
    await deleteStudentFunction(params.id);
    navigate('/students');
  };
  return (
    <>
      <div className='wrapper'>
        <AuthHeader active={STUDENT_PROFILE_ENUM.STUDENTS} />
        <AuthBreadCrumb title={'Students / General Information'} />

        {/* page-section */}
        <section className='profile-main ptb-30'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div>
                  <div className='d-flex justify-content-between'>
                    <div className='main-sec-heading'>
                      <div className='d-flex'>
                        <div>
                          <h1>
                            {student &&
                              student.student &&
                              student.student.first_name}{' '}
                            {student &&
                              student.student &&
                              student.student.last_name}{' '}
                            (
                            {student &&
                              student.student &&
                              student.student.user_id}
                            )
                          </h1>
                        </div>
                        <PermissionComponent
                          name={'student'}
                          permission={'delete'}
                          hideOnAgent={true}
                          hideOnStudent={true}
                          hideOnCounsellor={true}
                        >
                          <div>
                            <a
                              className='badge badge-trash'
                              onClick={() => setCheckDeleteStudent(true)}
                            >
                              <i className='fa fa-trash'></i> Delete
                            </a>
                          </div>
                        </PermissionComponent>
                      </div>
                    </div>
                    <div>
                      <div className='add-student-btn'>
                        <Link
                          className='btn primary-btn-h '
                          to={`/search-programs-and-schools/${
                            student && student.student && student.student._id
                          }`}
                        >
                          Search Program & School
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <AuthProfileTabs
                  active={PROFILE_TABS_ENUM.GENERAL_INFORMATION}
                  isStudent={true}
                  id={params.id}
                />
              </div>
            </div>
          </div>
        </section>
        <section className='  personal-information '>
          {student && (
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <PersonalInformation
                    student_info={student}
                    student_loading={student_loading}
                  />
                </div>
                <div className='col-md-6'>
                  <AddressDetails
                    student_info={student}
                    student_loading={student_loading}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
        <AuthFooter />
        {checkDeleteStudent && (
          <div className='main-rel-bg'>
            <div class='really-fixed'>
              <div class='really-fixed-child modal-confirm'>
                <div className='modal-content'>
                  <div className='modal-header flex-column'>
                    <div className='icon-box'>
                      <i className='fa fa-trash'></i>
                    </div>
                    <h4 className='modal-title w-100'>Are you sure?</h4>
                    <button
                      type='button'
                      className='close'
                      onClick={() => setCheckDeleteStudent(false)}
                    >
                      ×
                    </button>
                  </div>
                  <div className='modal-body'>
                    <p
                      className='text-danger text-bold'
                      style={{ fontSize: '20px' }}
                    >
                      Once you click on the "Delete" button,all your data
                      (student information and applications) will be deleted
                      permanently.This action of yours is not reversible.
                    </p>
                  </div>
                  <div className='modal-footer justify-content-center'>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={() => setCheckDeleteStudent(false)}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => deleteStudentBtnClicked()}
                      className='btn btn-danger'
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
