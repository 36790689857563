import React from 'react';
import { URI } from '../../domain/constant';

function ImageComponent(props) {
  return (
    <div>
      {props.data.logo ? (
        <img src={`${URI}${props.data.logo}`} className='circle-image-table' />
      ) : (
        'NA'
      )}
    </div>
  );
}

export default ImageComponent;
