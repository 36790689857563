import React, { useState, useEffect, memo } from 'react';
import * as qs from 'qs';
import { City, Country, State } from 'country-state-city';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import PermissionComponent from '../auth/PermissionComponent';
import { useAllSchoolsFilters } from '../../shared/hooks/UseSchool';
import {
  useAllDisciplines,
  useFilterDisciplines,
} from '../../shared/hooks/UseDiscipline';
import Select from 'react-select';
import {
  useAllEducationLevels,
  useFilterEducationLevels,
} from '../../shared/hooks/UseEducationLevel';
export const ProgramFilterComponent = memo(({ col }) => {
  const queryParams = qs.parse(window.location.search.replace('?', ''));

  const navigate = useNavigate();
  const location = useLocation();
  const [countryCode, setCountryCode] = useState();
  const [country_name, setCountry_name] = useState(
    queryParams && queryParams.country ? queryParams.country : ''
  );
  const [stateCode, setStateCode] = useState(null);
  const [state_name, setStateName] = useState(
    queryParams && queryParams.state ? queryParams.state : ''
  );
  const [city, setCity] = useState(
    queryParams && queryParams.city ? queryParams.city : ''
  );
  const [name, setName] = useState(
    queryParams && queryParams.name ? queryParams.name : ''
  );
  const [status, setStatus] = useState(
    queryParams && queryParams.status ? queryParams.status : ''
  );
  const [school, setSchool] = useState(
    queryParams && queryParams.school ? queryParams.school : ''
  );
  const [discipline, setDiscipline] = useState(
    queryParams && queryParams.discipline ? queryParams.discipline : ''
  );
  const [sub_discipline, setSubDiscipline] = useState(
    queryParams && queryParams.sub_discipline ? queryParams.sub_discipline : ''
  );
  const [dropdown_options, setDropdown_options] = useState(null);
  const { education_levels } = useFilterEducationLevels();
  const [programLevel, setProgramLevel] = useState(
    queryParams && queryParams.education_level
      ? queryParams.education_level
      : ''
  );
  const { schools } = useAllSchoolsFilters();

  const { disciplines } = useFilterDisciplines();
  const handleSidebarChange = ({ name, value }) => {
    queryParams[name] = value;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    navigate(`${location.pathname}?${query}`);
  };
  const getSidebarValue = (item) => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // return urlParams.get(name);
    const queryParams = qs.parse(window.location.search.replace('?', ''));

    const searchValue = queryParams[item] ? queryParams[item] : '';
    return searchValue;
  };

  useEffect(() => {
    if (schools) {
      let finalSchoolOptions = [];

      if (country_name || state_name || city) {
        if (country_name && state_name && city) {
          const filteredData = schools.filter(
            (school) =>
              school.country === country_name &&
              school.state === state_name &&
              school.city === city
          );
          finalSchoolOptions = filteredData.map((school) => {
            return {
              label: `${school.name} (${school.city})`,
              value: school._id,
            };
          });
        } else {
          if (country_name && state_name) {
            const filteredData = schools.filter(
              (school) =>
                school.country === country_name && school.state === state_name
            );
            finalSchoolOptions = filteredData.map((school) => {
              return {
                label: `${school.name} (${school.city})`,
                value: school._id,
              };
            });
          } else {
            if (country_name) {
              const filteredData = schools.filter(
                (school) => school.country === country_name
              );
              finalSchoolOptions = filteredData.map((school) => {
                return {
                  label: `${school.name} (${school.city})`,
                  value: school._id,
                };
              });
            }
          }
        }
      } else {
        finalSchoolOptions = schools.map((school) => {
          return {
            label: `${school.name} (${school.city})`,
            value: school._id,
          };
        });
      }

      setDropdown_options({ ...dropdown_options, school: finalSchoolOptions });
    }
  }, [JSON.stringify(schools), country_name, state_name, city]);

  useEffect(() => {
    if (disciplines) {
      setDropdown_options({
        ...dropdown_options,
        disciplines: disciplines,
      });
    }
  }, [JSON.stringify(disciplines)]);

  useEffect(() => {
    if (window.location.search) {
      // setCountryCode(getSidebarValue('country'));
      const country_name = getSidebarValue('country');
      setCountry_name(country_name);
      if (country_name) {
        const all_countries = Country.getAllCountries();
        const filteredData = all_countries.filter(
          (item) => item.name == country_name
        );
        if (filteredData && filteredData.length > 0) {
          setCountryCode(filteredData[0].isoCode);
        }
      }
      const state_name = getSidebarValue('state');
      setStateName(state_name);
      if (state_name && countryCode) {
        const all_states = State.getStatesOfCountry(countryCode);
        const filteredData = all_states.filter(
          (item) => item.name == state_name
        );
        if (filteredData && filteredData.length > 0) {
          setStateCode(filteredData[0].isoCode);
        }
      }
      const city_name = getSidebarValue('city');
      setCity(city_name);
    }
  }, [window.location.search, countryCode, stateCode]);

  return (
    <div className={col ? `col-lg-${col}` : 'col-lg-2'}>
      <div
        className='card '
        style={{
          boxShadow: 'rgb(227 233 243) 0px 4px 22px',
          position: 'sticky',
          top: '100px',
          zIndex: 2,
        }}
      >
        <div
          className='card-header'
          style={{ background: '#14c6ef', color: '#fff' }}
        >
          <h5 className='card-title'> FILTER</h5>
        </div>

        <div className='card-body sidebar-component'>
          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              Program Name
            </label>
          </div>
          <div>
            <input
              type='text'
              className='form-control'
              value={name ? name : ''}
              onChange={(e) => {
                setName(e.target.value);
                handleSidebarChange({
                  name: 'name',
                  value: e.target.value,
                });
              }}
            />
          </div>
          <PermissionComponent
            name={'program'}
            permission={'get_all'}
            hideOnAgent={true}
            hideOnStudent={true}
          >
            <div className='topping'>
              <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
                Status
              </label>
            </div>
            <div>
              <select
                name='status'
                id='status'
                className='form-control'
                onChange={(e) => {
                  setStatus(e.target.value);
                  handleSidebarChange({
                    name: 'status',
                    value: e.target.value,
                  });
                }}
                value={status}
              >
                <option value=''></option>
                <option value='Active'> Active </option>
                <option value='Draft'> Draft </option>
              </select>
            </div>
          </PermissionComponent>

          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              Country
            </label>
          </div>
          <div>
            <select
              className='form-control'
              onChange={(e) => {
                setCountryCode(e.target.value);
                handleSidebarChange({
                  name: 'country',
                  value: Country.getCountryByCode(e.target.value).name,
                });
              }}
              value={countryCode ? countryCode : ''}
            >
              <option value=''>-</option>
              {Country.getAllCountries() &&
                Country.getAllCountries().map((e) => {
                  return (
                    <option key={e.isoCode} value={e.isoCode}>
                      {e.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              State
            </label>
          </div>
          <div>
            {countryCode &&
            State.getStatesOfCountry(countryCode) &&
            State.getStatesOfCountry(countryCode).length > 0 ? (
              <>
                <select
                  className='form-control'
                  label='State'
                  name='state'
                  value={stateCode ? stateCode : ''}
                  onChange={(e) => {
                    setStateCode(e.target.value);

                    handleSidebarChange({
                      name: 'state',
                      value: State.getStateByCodeAndCountry(
                        e.target.value,
                        countryCode
                      ).name,
                    });
                  }}
                >
                  <option value=''>-</option>
                  {State.getStatesOfCountry(countryCode).map((e) => {
                    return (
                      <option key={e.name} value={e.isoCode}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <input
                label='Province/ State'
                className='form-control'
                name='state'
              />
            )}
          </div>

          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              City
            </label>
          </div>
          <div>
            {countryCode &&
            stateCode &&
            City.getCitiesOfState(countryCode, stateCode) &&
            City.getCitiesOfState(countryCode, stateCode).length > 0 ? (
              <>
                <select
                  className='form-control'
                  label='City'
                  name='city'
                  onChange={(e) => {
                    setCity(e.target.value);
                    handleSidebarChange({
                      name: 'city',
                      value: e.target.value,
                    });
                  }}
                  value={city}
                >
                  <option value=''></option>
                  {City.getCitiesOfState(countryCode, stateCode).map((e) => {
                    return (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <input className='form-control' label='City' name='city' />
            )}
          </div>
          <div>
            <div className='topping'>
              <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
                Education Level
              </label>
            </div>
            <select
              className='form-control'
              onChange={(e) => {
                setProgramLevel(e.target.value);
                handleSidebarChange({
                  name: 'education_level',
                  value: e.target.value,
                });
              }}
              value={programLevel ? programLevel : ''}
            >
              <option value=''>-</option>
              {education_levels &&
                education_levels.map((item) => {
                  return <option value={item._id}> {item.name} </option>;
                })}
            </select>
          </div>
          <div className='d-flex justify-content-between'>
            <div>
              <label> School </label>
            </div>
            <div>
              <div
                onClick={() => setSchool('')}
                className='p-2'
                style={{ cursor: 'pointer' }}
              >
                <i className='fa fa-close'></i>
              </div>
            </div>
          </div>

          <Select
            value={
              dropdown_options &&
              dropdown_options.school &&
              dropdown_options.school.filter(
                (option) => option.value === school
              )
            }
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 10 }),
            }}
            // defaultValue={school}
            isClearable={false}
            options={dropdown_options && dropdown_options['school']}
            onChange={(e) => {
              if (e) {
                setSchool(e.value);
                handleSidebarChange({
                  name: 'school',
                  value: e.value,
                });
              }
            }}
          />
          <label> Discipline </label>

          <select
            name='discipline'
            onChange={(e) => {
              if (e) {
                setDiscipline(e.target.value);
                handleSidebarChange({
                  name: 'discipline',
                  value: e.target.value,
                });
              }
            }}
            className='form-control'
            value={discipline}
          >
            <option value=''></option>
            {dropdown_options &&
              dropdown_options.disciplines &&
              dropdown_options.disciplines
                .filter((item) => !item.parent)
                .map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
          </select>
          <label> Sub Discipline </label>

          <select
            name='sub_discipline'
            onChange={(e) => {
              if (e) {
                setSubDiscipline(e.target.value);
                handleSidebarChange({
                  name: 'sub_discipline',
                  value: e.target.value,
                });
              }
            }}
            className='form-control'
            value={sub_discipline}
          >
            <option value=''></option>
            {dropdown_options &&
              dropdown_options.disciplines &&
              dropdown_options.disciplines
                .filter((item) => item.parent && item.parent._id == discipline)
                .map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
          </select>
        </div>
        <div className='card-footer'>
          <a
            style={{ color: '#14c6ef' }}
            onClick={() => {
              setName('');
              setStatus('');
              setCountryCode('');
              setStateCode('');
              setCity('');
              setSchool(null);
              setDiscipline('');
              setSubDiscipline('');
              setProgramLevel('');
              navigate(`${location.pathname}`);
            }}
          >
            X Clear Filter
          </a>
        </div>
      </div>
    </div>
  );
});
