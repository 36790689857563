import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAllCrmUsers } from '../../shared/hooks/UseCrmUser';
export default function AssignAgentModalComponent({ handleSubmitForm }) {
  const { crm_user_data } = useAllCrmUsers();
  const { crm_users } = crm_user_data;

  return (
    <Formik
      initialValues={{
        crm_user: '',
      }}
      validationSchema={Yup.object({
        crm_user: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmitForm(values);
        resetForm();
        setSubmitting(false);
      }}
    >
      {(formik) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-md-12'>
                <label htmlFor='User'> Select User </label>
                <select
                  name='crm_user'
                  onChange={formik.handleChange}
                  value={formik.values.crm_user}
                  className='form-control'
                  id='User'
                >
                  <option value=''></option>
                  {crm_users &&
                    crm_users.map((item) => {
                      return (
                        <option value={item._id}>
                          {' '}
                          {item.first_name} {item.last_name}
                        </option>
                      );
                    })}
                </select>
                {formik.errors && formik.errors.crm_user && (
                  <p className='text-danger'> {formik.errors.crm_user} </p>
                )}
              </div>
              <div className=''>
                <button
                  className='btn btn-success btn-sm'
                  onClick={formik.handleSubmit}
                  type='submit'
                >
                  <i className='fa fa-save'></i>
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
