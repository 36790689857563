import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addSchool,
  getSchools,
  getSchool,
  editSchool,
  deleteSchool,
  getSchoolBySlug,
  draftSchool,
  activateSchool,
  getSchoolPrograms,
  filterSchools,
} from '../../store/actions/school_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { api } from '../../domain/api';

// Add Data
export const useCreateSchool = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.school);
  const addSchoolData = async (data) => {
    dispatch(addSchool(data));
  };
  return { data, addSchoolData };
};

// Get Single Data
export const useSingleSchool = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.school);
  useEffect(() => {
    dispatch(getSchool(id));
  }, [id]);
  return { data };
};

export const useAllSchoolsReport = () => {
  const [loading_report, setLoading_report] = useState(false);
  const [reportData, setReportData] = useState(null);
  const getData = async () => {
    setLoading_report(true);
    const { data } = await api.get('/schools/school-reports');
    setReportData(data);
    setLoading_report(false);
  };
  return {
    reportData,
    getData,
    loading_report,
  };
};

// Get Single Data
export const useSingleSchoolPrograms = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.school);
  useEffect(() => {
    dispatch(getSchoolPrograms(id));
  }, [id]);
  return { data, school_programs_data: data };
};
// Get Single Data
export const useSingleSchoolBySlug = (slug) => {
  const dispatch = useDispatch();
  const school_data = useSelector((state) => state.school);
  useEffect(() => {
    dispatch(getSchoolBySlug(slug));
  }, []);
  return { school_data };
};

//Edit Data
export const useUpdateSchool = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.School);
  const updateSchoolData = async (id, data) => {
    dispatch(editSchool(id, data));
  };
  return { updateSchoolData };
};
//Change Publish Status
export const useChangeStatusSchool = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.School);
  const draftSchoolData = async (id, data) => {
    dispatch(draftSchool(id));
  };
  const activateSchoolData = async (id) => {
    dispatch(activateSchool(id));
  };
  return { draftSchoolData, activateSchoolData };
};

// Get All Data
export const useAllSchools = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.school);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getSchools({}));
    }, 1000),
    []
  );

  return { data, setPageNumber, schools_data: data };
};

// Get All Data
export const useAllSchoolsFilters = () => {
  const [schools_data, setSchools_data] = useState([]);
  const getAllSchoolsData = async () => {
    try {
      if (schools_data.length === 0) {
        const { data } = await api.get('/schools/filter');
        setSchools_data(data.schools);
      }
    } catch (error) {
      setSchools_data([]);
    }
  };

  useEffect(() => {
    console.count('School Hook Mounted');
    getAllSchoolsData();
  }, []);

  return {
    schools: schools_data,
  };
};

// Get All Data
export const useAllSchoolsFiltersContainer = () => {
  const [schools, setSchools] = useState(null);
  const getSchools = async () => {
    const { data } = await api.get(`/schools/filter`);
    setSchools(data);
  };

  useEffect(() => {
    getSchools();
  }, []);

  return {
    schools_data: schools ? schools : { schools: [] },
  };
};

//Delete Data
export const useDeleteSchool = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.School);
  const deleteSchoolFunction = async (id) => {
    dispatch(deleteSchool(id));
  };
  return { deleteSchoolFunction };
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  const countries =
    Country.getAllCountries() &&
    Country.getAllCountries().map((e) => {
      return {
        label: e.name,
        value: e.name,
      };
    });

  useEffect(() => {
    if (countries) {
      setDropdownOptions({ ...dropdownOptions, country: countries });
    }
  }, [countries.label]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "parent_category") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};
