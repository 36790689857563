import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCreateApplication } from '../../shared/hooks/UseApplication';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';

function ProgramSearchCardComponent({ program, hideStartBtn }) {
  const { addApplicationData } = useCreateApplication();
  const user_data = useCheckAuthenticated();
  const { user } = user_data;
  const params = useParams();
  const navigate = useNavigate();
  const [intake, setIntake] = useState('');

  const handleStartApplication = async () => {
    const dataToSend = {};
    dataToSend.program = program._id;
    dataToSend.application_fee = program.application_fee;
    dataToSend.student = params.student;
    dataToSend.school = program.school && program.school._id;
    dataToSend.intake = intake;
    if (user && user.role === 'AGENT') {
      dataToSend.agent = user.id;
    }

    await addApplicationData(dataToSend);
    navigate(`/my-applications`);
  };
  return (
    <div className='similar-program-card'>
      <div className='similar-card-content'>
        <span> {program.education_level && program.education_level.name} </span>
        <h5>
          {program.name} {program.school && `(${program.school.name})`}
        </h5>
        {program?.school?.city && (
          <div className='location-map'>
            <i className='fa fa-map-marker'></i>
            {program?.school?.city} {program?.school?.state}{' '}
            {program?.school?.postal_code} {program?.school?.country}
          </div>
        )}

        <div className='similar-card-deadline-flex'>
          {/* <div>
            <h5>Earliest intake</h5>
            <h6>Sep 2023</h6>
          </div>
          <div>
            <h5>Deadline</h5>
            <h6>Jun 2023</h6>
          </div> */}
          {program.tuition && (
            <div>
              <h5>Tuition</h5>
              <h6>
                {program.school &&
                  program.school.market &&
                  program.school.market.currency}{' '}
                {program.tuition}{' '}
              </h6>
            </div>
          )}
          {program.application_fee && (
            <div>
              <h5>Application fee</h5>
              <h6>
                {program.school &&
                  program.school.market &&
                  program.school.market.currency}{' '}
                {program.application_fee}
              </h6>
            </div>
          )}
        </div>
        <div className='apply-button apply-flex justify-content-between'>
          {!hideStartBtn && (
            <div className='d-flex align-items-end'>
              {program.program_intakes &&
                program.program_intakes.length > 0 && (
                  <div>
                    <label> Intake </label>
                    <select
                      className='form-control'
                      value={intake}
                      onChange={(e) => setIntake(e.target.value)}
                    >
                      <option value=''></option>
                      {program.program_intakes &&
                        program.program_intakes.map((item) => {
                          return (
                            <option value={item.intake_name}>
                              {item.intake_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
              <div>
                <button
                  className='btn apply-filters  '
                  onClick={() => handleStartApplication()}
                >
                  Start Application
                </button>
              </div>
            </div>
          )}

          <div>
            <Link
              to={`${hideStartBtn ? '/website' : ''}/schools/${
                program.school._id
              }/programs/${program.slug}`}
              className='btn primary-btn w-100  '
              target={'_blank'}
            >
              Program Details <i className='fa fa-angle-right'></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramSearchCardComponent;
