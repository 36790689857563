import React from 'react';
import { Link } from 'react-router-dom';
import { URI } from '../../domain/constant';

function ViewTemplateComponent({ template }) {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='questions-card'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5>{template.title}</h5>
            </div>
          </div>

          <div>
            <strong> {template.requirement_stage} </strong>
          </div>
          <div>
            Required
            <strong> {template.is_required ? 'YES' : 'NO'} </strong>
          </div>
          <div dangerouslySetInnerHTML={{ __html: template.content }}></div>
          <div>
            Requirement Type
            <strong> {template.requirement_type} </strong>
          </div>
          {template.document && (
            <div>
              Document
              <strong>
                <a href={`${URI}${template.document}`} target='_blank'>
                  View{' '}
                </a>{' '}
              </strong>
            </div>
          )}
          {template.requirement_type === 'questionnaire' && (
            <div>
              <div> Questions </div>
              <div>
                {template.questions &&
                  template.questions.length > 0 &&
                  template.questions.map((question) => {
                    return (
                      <div className='questions-card-questions'>
                        <div>
                          <strong> {question.title} </strong>{' '}
                        </div>
                        <div>{question.question_type}</div>
                        {question.question_type === 'multiple_choice' && (
                          <>
                            {question.multiple_choice &&
                              question.multiple_choice.length > 0 &&
                              question.multiple_choice.map((answer) => {
                                return <div>-- {answer.answer_choice}</div>;
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewTemplateComponent;
