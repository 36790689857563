import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addCounsellor,
  getCounsellors,
  getCounsellor,
  editCounsellor,
  deleteCounsellor,
  updatePasswordCounsellor,
  assignAgent,
} from '../../store/actions/counsellor_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

// Add Data
export const useCreateCounsellor = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.counsellor);
  const addCounsellorData = async (data) => {
    dispatch(addCounsellor(data));
  };
  return { data, addCounsellorData };
};

// Get Single Data
export const useSingleCounsellor = (id) => {
  const dispatch = useDispatch();
  const counsellor_data = useSelector((state) => state.counsellor);
  useEffect(() => {
    dispatch(getCounsellor(id));
  }, [id]);
  return { counsellor_data };
};

//Edit Data
export const useUpdateCounsellor = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const updateCounsellorData = async (id, data) => {
    dispatch(editCounsellor(id, data));
  };
  return { updateCounsellorData };
};

//Assign Agent
export const useAssignAgent = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const assignAgentToCounsellor = async (id, data) => {
    dispatch(assignAgent(id, data));
  };
  return { assignAgentToCounsellor };
};

//Update CRM USER
export const useChangePasswordCounsellor = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const updateCounsellorPasswordData = async (id, data) => {
    dispatch(updatePasswordCounsellor(id, data));
  };
  return { updateCounsellorPasswordData };
};

// Get All Data
export const useAllCounsellors = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.counsellor);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getCounsellors({}));
    }, 1000),
    []
  );

  return { data, setPageNumber, counsellor_data: data };
};

//Delete Data
export const useDeleteCounsellor = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const deleteCounsellorFunction = async (id) => {
    dispatch(deleteCounsellor(id));
  };
  return { deleteCounsellorFunction };
};
