import React from 'react';
import { Alerts } from '../common/Alerts';
import { links } from '../../domain/links.enum';
import { Link } from 'react-router-dom';

export const AuthFooter = (props) => {
  return (
    <>
      <footer className='profile-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='profile-social-icon pull-left'>
                <ul>
                  <li>
                    <a
                      href='https://www.instagram.com/edchimp_'
                      target='_blank'
                    >
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>

                  <li>
                    <a href='https://www.youtube.com/@edchimp' target='_blank'>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                  <li>
                    <a href='mailto:connect@edchimp.com' target='_blank'>
                      <i className='fa fa-envelope'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='copyright-profile'>
                <p>copyright © 2023 Edchimp. All Right Reserved.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='profile-privacy pull-right'>
                <ul>
                  <li>
                    <Link to={links.privacyPolicy}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={links.termsAndConditions}>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={links.about}>About</Link>
                  </li>
                  <li>
                    <Link to={links.blogs}>Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Alerts />
    </>
  );
};
