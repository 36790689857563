import {
  GET_APPLICATION_REQUIREMENTS_STARTED,
  GET_APPLICATION_REQUIREMENTS,
  GET_APPLICATION_REQUIREMENTS_ENDED,
  ADD_APPLICATION_REQUIREMENT_STARTED,
  ADD_APPLICATION_REQUIREMENT,
  ADD_APPLICATION_REQUIREMENT_ENDED,
  EDIT_APPLICATION_REQUIREMENT_STARTED,
  EDIT_APPLICATION_REQUIREMENT,
  EDIT_APPLICATION_REQUIREMENT_ENDED,
  GET_APPLICATION_REQUIREMENT,
  GET_APPLICATION_REQUIREMENT_STARTED,
  GET_APPLICATION_REQUIREMENT_ENDED,
  GET_APPLICATION_REQUIREMENT_PROGRAM_STARTED,
  GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED,
  GET_APPLICATION_REQUIREMENT_PROGRAM,
} from '../types/application_requirement_types';

const initialState = {
  application_requirements_loading: true,
  application_requirements: null,
  application_requirement_page: null,
  application_requirement_pages: null,
  total_application_requirements: 0,

  application_requirement: null,
  application_requirement_loading: null,

  add_application_requirement_loading: true,
  edit_application_requirement_loading: true,

  program_application_requirements: null,
  program_application_requirements_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_APPLICATION_REQUIREMENTS_STARTED:
      return {
        ...state,
        application_requirements: null,
        application_requirement_pages: null,
        application_requirement_page: null,
        total_application_requirements: 0,
        application_requirements_loading: true,
      };
    case GET_APPLICATION_REQUIREMENTS:
      return {
        ...state,
        application_requirements: payload.application_requirements,
        application_requirement_pages: payload.pages,
        application_requirement_page: payload.page,
        total_application_requirements: payload.total_application_requirements,
      };
    case GET_APPLICATION_REQUIREMENTS_ENDED:
      return {
        ...state,
        application_requirements_loading: false,
      };

    case ADD_APPLICATION_REQUIREMENT_STARTED:
      return {
        ...state,
        application_requirement_message: null,
        add_application_requirement_loading: true,
      };
    case ADD_APPLICATION_REQUIREMENT:
      return {
        ...state,
        application_requirement_message: payload,
      };
    case ADD_APPLICATION_REQUIREMENT_ENDED:
      return {
        ...state,
        add_application_requirement_loading: false,
      };

    case GET_APPLICATION_REQUIREMENT_PROGRAM_STARTED:
      return {
        ...state,
        program_application_requirements: null,
        program_application_requirements_loading: true,
      };
    case GET_APPLICATION_REQUIREMENT_PROGRAM:
      return {
        ...state,
        program_application_requirements: payload,
      };
    case GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED:
      return {
        ...state,
        program_application_requirements_loading: false,
      };

    case GET_APPLICATION_REQUIREMENT_STARTED:
      return {
        ...state,
        application_requirement: null,
        application_requirement_loading: true,
      };
    case GET_APPLICATION_REQUIREMENT:
      return {
        ...state,
        application_requirement: payload,
      };
    case GET_APPLICATION_REQUIREMENT_ENDED:
      return {
        ...state,
        application_requirement_loading: false,
      };
    case EDIT_APPLICATION_REQUIREMENT_STARTED:
      return {
        ...state,
        application_requirement_message: null,
        edit_application_requirement_loading: true,
      };
    case EDIT_APPLICATION_REQUIREMENT:
      return {
        ...state,
        application_requirement_message: payload,
      };
    case EDIT_APPLICATION_REQUIREMENT_ENDED:
      return {
        ...state,
        edit_application_requirement_loading: false,
      };

    default:
      return state;
  }
}
