import React from 'react';
import { useApplicationRequirementsByProgram } from '../../shared/hooks/UseApplicationRequirement';
import { URI } from '../../domain/constant';
import { Link } from 'react-router-dom';
function ApplicationRequirementsComponent({ program }) {
  const { application_requirement_data } = useApplicationRequirementsByProgram(
    program.id
  );
  const {
    program_application_requirements,
    program_application_requirements_loading,
  } = application_requirement_data;
  return (
    <div>
      {program_application_requirements &&
        program_application_requirements.length > 0 && (
          <div className='overview-content'>
            <div className='admission-requirement-flex-b'>
              <div>
                <h3> Application Requirements</h3>
              </div>
              <div></div>
            </div>
            <div className='row'>
              {program_application_requirements.map((item) => {
                return (
                  <div className='col-md-12'>
                    <div className='questions-card'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h5>{item.title}</h5>
                        </div>
                        <div>
                          <Link
                            to={`/schools/${program.school._id}/programs/${program._id}/${item._id}/edit-application-requirements`}
                            class='btn btn primary-btn-h w-100 '
                          >
                            <i className='fa fa-edit'></i> Edit{' '}
                          </Link>
                        </div>
                      </div>

                      <div>
                        <strong> {item.requirement_stage} </strong>
                      </div>
                      <div>
                        Required
                        <strong> {item.is_required ? 'YES' : 'NO'} </strong>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>
                      <div>
                        Requirement Type
                        <strong> {item.requirement_type} </strong>
                      </div>
                      {item.document && (
                        <div>
                          Document
                          <strong>
                            <a href={`${URI}${item.document}`} target='_blank'>
                              View{' '}
                            </a>{' '}
                          </strong>
                        </div>
                      )}
                      {item.requirement_type === 'questionnaire' && (
                        <div>
                          <div> Questions </div>
                          <div>
                            {item.questions &&
                              item.questions.length > 0 &&
                              item.questions.map((question) => {
                                return (
                                  <div className='questions-card-questions'>
                                    <div>
                                      <strong> {question.title} </strong>{' '}
                                    </div>
                                    <div>{question.question_type}</div>
                                    {question.question_type ===
                                      'multiple_choice' && (
                                      <>
                                        {question.multiple_choice &&
                                          question.multiple_choice.length > 0 &&
                                          question.multiple_choice.map(
                                            (answer) => {
                                              return (
                                                <div>
                                                  -- {answer.answer_choice}
                                                </div>
                                              );
                                            }
                                          )}
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
    </div>
  );
}

export default ApplicationRequirementsComponent;
