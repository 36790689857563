import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import AddApplicationRequirementForm from './AddApplicationRequirementForm';
import { useCreateApplicationRequirement } from '../../shared/hooks/UseApplicationRequirement';

function AddApplicationRequirementsComponent({ program, school }) {
  const navigate = useNavigate();
  const { addApplicationRequirementData } = useCreateApplicationRequirement();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Application Requirements</h1>
        </div>

        <Formik
          initialValues={{
            requirement_stage: '',
            title: '',
          }}
          validationSchema={Yup.object({
            requirement_stage: Yup.string().required('Required'),
            title: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            // values.program = program;
            // addAgentData(values);
            values.program = program;
            addApplicationRequirementData(values);
            navigate(`/programs`);
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <AddApplicationRequirementForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddApplicationRequirementsComponent;
