import React from 'react';
import { DummyUser } from '../images';
import NotesForm from './NotesForm';
import { Formik } from 'formik';
import {
  useCreateApplicationNote,
  useAllApplicationNotes,
} from '../../shared/hooks/UseApplicationNote';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import moment from 'moment';
import { URI, getDocumentName } from '../../domain/constant';

function SingleApplicationNotesComponent({ application }) {
  const { addApplicationNoteData } = useCreateApplicationNote();
  const { application_note_data } = useAllApplicationNotes(application._id);
  const { application_notes, application_notes_loading } =
    application_note_data;
  const userData = useCheckAuthenticated();
  const { user } = userData;

  return (
    <>
      <section className='container-notes-box'>
        <div className='row'>
          <div className='col-md-6'>
            {application_notes &&
              application_notes.map((note) => {
                return (
                  <div
                    className={
                      note.createdBy && user.id === note.createdBy._id
                        ? 'container-notes darker'
                        : 'container-notes '
                    }
                  >
                    <img src={DummyUser} alt='Avatar' />
                    <p className=' badge success'>
                      {' '}
                      {note.createdBy && note.createdBy.first_name}{' '}
                    </p>
                    <p>
                      <b> {note.title} </b> <br />
                    </p>
                    {note.notes && (
                      <div
                        dangerouslySetInnerHTML={{ __html: note.notes }}
                      ></div>
                    )}
                    {note.media && note.media.length > 0 && (
                      <div>
                        {note.media.map((item, index) => {
                          return (
                            <div>
                              <a href={`${URI}${item}`} target='_blank'>
                                {getDocumentName(item)}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <span className='time-right'>
                      {note.createdAt &&
                        moment(note.createdAt).format('DD-MMM-YYYY hh:mm A')}
                    </span>
                  </div>
                );
              })}
          </div>
          <div className='col-md-6'>
            <div className='add-notes-form'>
              <h4> Add New Note </h4>
              <Formik
                initialValues={{
                  notes: '',
                  title: '',
                  media: [],
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  // handleSubmitData(values);
                  values.application = application._id;
                  await addApplicationNoteData(values);
                  // navigate('/schools');
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  return <NotesForm formik={formik} />;
                }}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SingleApplicationNotesComponent;
