import React from 'react';
import { Footer, Header } from '../../../components/common';
import {
  AcceptanceRate,
  AccessSchool,
  BestCommissions,
  CentralPlatform,
  DataDriven,
  DataInsights,
  EducationRecruiter,
  EventsAndWeb,
  FindProgram,
  Grow,
  Industry,
  LocalSupport,
  MainRecruiter,
  MoreSchool,
  OneEasy,
  OnePlatform,
  RecruitmentPartnerWebsite,
  Reward,
  SchoolPartnership,
  StudentHelped,
  StudentScholarship,
  Trusted,
} from '../../../components/images';
import Testimonial from '../component/Testimonial';
import Counter from '../component/Counter';
import { Link } from 'react-router-dom';
import { links } from '../../../domain/links.enum';

function Recruiters() {
  return (
    <div>
      <Header />
      <section className="sec-pad page-section recruiter-section-website">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="recruiter-content">
                <h2>
                  We are committed to your success and are dedicated to
                  supporting your growth.
                </h2>
                <p>
                  EdChimp is more than a platform. We are your trusted partner,
                  dedicated to supporting your growth and enabling you to excel
                  in your mission of helping numerous students realize their
                  aspirations for international education.
                </p>
                <div className="explore-button">
                  <Link to={links.registerAsAgent} className=" btn btn-join ">
                    Partner With Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="recruiter-images">
                <img src={MainRecruiter} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="growth ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={MoreSchool}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Access to Education Worldwide</h3>
                  <p>
                    EdChimp provides access to many educational institutions
                    globally, including Canada, the United Kingdom, the United
                    States, Australia, New Zealand and Europe
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mx-auto">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={OnePlatform}></img>
                </div>

                <div className="pointer-headings">
                  <h3>One Platform for All Your Needs</h3>
                  <p>
                    Find convenience with one multi-stream platform offering:
                    student and program recruitment, management of applications,
                    real-time updates on application status, visa guidelines and
                    assistance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={Grow}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Ed-tech with a Human Touch</h3>
                  <p>
                    You will be speaking with a member of our team guiding you
                    throughout the entire application process which leaves you
                    stress-free for any urgent problem resolution.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={Trusted}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Cutting-edge Industry Insights</h3>
                  <p>
                    Gain access to the latest industry information and updates
                    to all things education and studying abroad.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 mx-auto">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={Industry}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Reliable and Clear Payments</h3>
                  <p>
                    We ensure timely payouts at various stages of the EdChimp
                    application and enrollment process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* useless */}
      <section>
        <div className="container">
          <div className="row"></div>
        </div>
      </section>

      <section className="ptb-50 commissions">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-title text-center mb-70">
                <h2>
                  Gain Access to a Wider Selection of Schools, Enjoy Additional
                  Perks, and Receive Faster Commission Payouts by Partnering
                  with
                  <span> EdChimp!</span>
                </h2>
                <p>
                  Here to assist you in expanding your business by providing
                  top-notch opportunities and comprehensive support.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src={BestCommissions} />
                  <h3>Top Commissions</h3>
                  <p>
                    Fast and dependable commissions ensuring you are duly
                    rewarded for your dedicated efforts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src={Reward} />
                  <h3>Benefits and Incentives</h3>
                  <p>
                    Gain bonuses & enjoy privileged access to premier training
                    programs to enhance your personal and professional
                    development.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src={AccessSchool} />
                  <h3>Gain entry to over 1,000 schools</h3>
                  <p>
                    The most desirable destinations with the top schools and
                    their top programs are readily available to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="freature-section-six mb-50 ">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-8 mx-auto">
              <div
                className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className="section-title mb-30">
                  <h2>
                    A user-friendly platform that links you with the appropriate{' '}
                    {''}
                    <span> school!</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row ptb-50">
            <div className="col-md-12 mx-auto">
              <div className="row">
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={FindProgram} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Finding programs
                        <br /> more quickly
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={OneEasy} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        An application that
                        <br /> is easy to use
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={CentralPlatform} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Foremost
                        <br /> Platform
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={DataDriven} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Data-Directed
                        <br /> Insights
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ptb-50 international-education">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-10 mx-auto">
              <div
                className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className="section-title mb-30">
                  <h2>
                    Our Commitment Lies in Nurturing both your Well-Being & the
                    Interconnected Network of Global Our commitment lies in
                    nurturing both your well-being & the interconnected network
                    of <span>Education!</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={LocalSupport} />
                    </div>

                    <div className="local-support-content">
                      <h3>Local Support</h3>
                      <p>
                        We have a team of international recruitment experts
                        available to provide you with support throughout the
                        entire process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={EducationRecruiter} />
                    </div>

                    <div className="local-support-content">
                      <h3>Training</h3>
                      <p>
                        Enhance your knowledge and stay ahead of the competition
                        by partaking in carefully curated online training
                        programs offered by EdChimp
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={EventsAndWeb} />
                    </div>

                    <div className="local-support-content">
                      <h3>Events & Webinars</h3>
                      <p>
                        Stay informed about the latest trends and regulations
                        through a series of online webinars, training sessions,
                        and events that are held regularly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={DataInsights} />
                    </div>

                    <div className="local-support-content">
                      <h3>Data & Insights</h3>
                      <p>
                        Gain access to cutting-edge insights and knowledge in
                        the industry, empowering you to strategize, grow, and
                        accomplish your objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Recruiters;
