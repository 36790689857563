import React from 'react';

function OtherFees({ program }) {
  return (
    <div>
      <div className='single-school-sidebar other-fees'>
        <h4>Others Fees</h4>
        {program &&
          program.other_fees &&
          program.other_fees.map((item) => {
            return (
              <div className='cost-flex'>
                <div className='icon-cost'>{item.title}</div>
                <div className='fees-content'>
                  <h5>
                    {program.school &&
                      program.school.market &&
                      program.school.market.currency}
                    {item.fees}
                  </h5>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default OtherFees;
