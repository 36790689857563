import React from 'react';

import { countries } from '../../domain/countries';
import { Formik, Form } from 'formik';
import {
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from '../../components/Form/Form';
import { banks } from '../../domain/banks';

function BankFormComponent({ formik, hidePassword }) {
  return (
    <div>
      <div className='row'>
        <h3> Bank Account Information </h3>
        <div className='col-md-6'>
          <TextInput
            label='Bank Holder Name'
            name='bank_holder_name'
            type='text'
          />
        </div>
        <div className='col-md-6'>
          <TextInput label='Account Number' name='account_no' type='text' />
        </div>

        <div className='col-md-12'>
          <TextInput label='Bank Name' name='bank_name' type='text' />
        </div>

        <div className='col-md-6'>
          <TextInput label='IFSC Code' name='ifsc_code' type='text' />
        </div>

        <div className='col-md-6'>
          <TextInput label='Bank Address' name='bank_address' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Swift Code' name='swift_code' type='text' />
        </div>
      </div>
    </div>
  );
}

export default BankFormComponent;
