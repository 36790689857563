import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';
import {
  useSingleGrading,
  useUpdateGrading,
} from '../../shared/hooks/UseGrading';

import { useAllPermissions } from '../../shared/hooks/UsePermissions';
import PermissionComponent from '../auth/PermissionComponent';
import GradingForm from './GradingForm';
function EditGradingComponent() {
  const params = useParams();
  const { grading_data } = useSingleGrading(params.id);
  const { grading, grading_loading } = grading_data;
  const { updateGradingData } = useUpdateGrading();
  const navigate = useNavigate();
  const { data } = useAllPermissions();
  const { permissions } = data;
  return (
    <div>
      {grading && (
        <div className='personal-information-box clearfix'>
          <div className='main-sec-heading'>
            <h1>Edit Grading</h1>
          </div>

          <Formik
            initialValues={{
              name: '',
              countries: [],
              letter_grades: [],
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateGradingData(grading._id, values);
              navigate('/settings/grading-schemes');
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return <GradingForm formik={formik} />;
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default EditGradingComponent;
