import React from 'react';

import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import TemplateForm from './TemplateForm';
import {
  useCreateApplicationRequirement,
  useSingleTemplate,
  useUpdateTemplate,
} from '../../shared/hooks/UseTemplate';

function EditTemplateComponent({ program, school }) {
  const params = useParams();
  const navigate = useNavigate();
  const { updateTemplateData } = useUpdateTemplate();
  const { template_data } = useSingleTemplate(params.id);

  const { template } = template_data;
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Edit Template</h1>
        </div>
        {template && (
          <Formik
            initialValues={{
              requirement_stage: template.requirement_stage
                ? template.requirement_stage
                : '',
              title: template.title ? template.title : '',

              is_required: template.is_required ? template.is_required : false,

              content: template.content ? template.content : '',
              requirement_type: template.requirement_type
                ? template.requirement_type
                : '',
              document: template.document ? template.document : '',
              questions: template.questions ? template.questions : '',
              country: template.country ? template.country : [],
            }}
            validationSchema={Yup.object({
              requirement_stage: Yup.string().required('Required'),
              title: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              // values.program = program;
              // addAgentData(values);
              // values.program = program;
              updateTemplateData(template._id, values);
              navigate(`/settings/templates`);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return <TemplateForm formik={formik} />;
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default EditTemplateComponent;
