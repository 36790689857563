import React from 'react';

import { Formik, Form } from 'formik';
import {
  TextInput,
  SelectBox,
  CheckBox,
  TextArea,
  HTMLEditor,
} from '../../components/Form/Form';

function CountryFormComponent({ formik }) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput label='Name' name='name' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Currency' name='currency' type='text' />
        </div>

        <div className='col-md-12'>
          {/* <HTMLEditor
            label='Cost Of Living'
            name='cost_of_living'
            type='text'
          /> */}
          <HTMLEditor
            item='cost_of_living'
            formik={formik}
            col={12}
            value={formik.values.cost_of_living}
          />
        </div>
      </div>

      <div className='create-button'>
        <button type='submit' className='btn primary-btn'>
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default CountryFormComponent;
