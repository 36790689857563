import React from 'react';
import { Footer, Header } from '../../../components/common';
import BlogComponent from '../component/blog/BlogComponent';
import { immigration } from '../../../components/images/index';
import { choose } from '../../../components/images/index';
import { spousevisa } from '../../../components/images/index';
function Blog() {
  return (
    <div>
      <Header />
      <section className='blog sec-pad page-section '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='sec-heading'>
                <h1>Our Blogs</h1>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <BlogComponent
                title={
                  'Why should you seek the help of an immigration consultant?'
                }
                id={
                  'Why-should-you-seek-the-help-of-an-immigration-consultant?'
                }
                image={immigration}
                date={'08 July'}
              />
            </div>
            <div className='col-md-4'>
              <BlogComponent
                title={'8 reasons to choose Australia for higher studies'}
                id={'8-reasons-to-choose-Australia-for-higher-studies'}
                date={'10 June'}
                image={choose}
              />
            </div>
            <div className='col-md-4'>
              <BlogComponent
                title={
                  'What documents do I need for my Australian spouse visa?'
                }
                id={'What-documents-do-I-need-for-my-Australian-spouse-visa?'}
                date={'07 August'}
                image={spousevisa}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Blog;
