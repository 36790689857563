import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { useLogout } from '../../shared/hooks/UseAuth';
import {
  STUDENT_PROFILE_ENUM,
  STUDENT_PROFILE_MENU,
} from '../../domain/student_profile_menu';
import {
  useAllNotifications,
  useUpdateNotificationBulk,
} from '../../shared/hooks/UseNotification';
import { CloseImg, Logo, MenuBar } from '../images';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { getInitialName } from '../../shared/helpers/getIntialValue';
import { URI } from '../../domain/constant';

function Auth_header_mobile({ active }) {
  const { updateNotificationBulk } = useUpdateNotificationBulk();
  const { notification_data } = useAllNotifications();
  const { notifications, total_notifications } = notification_data;
  const [notification, setNotification] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const menuOpen = () => setMobileMenu(true);
  const [showMenu, setshowMenu] = useState(false);
  const { logoutUser } = useLogout();
  const user_data = useCheckAuthenticated();

  const { user } = user_data;

  return (
    <>
      <div className='mobile-menu nav mobile-menu-flex'>
        <div className='logo'>
          <Link to={links.dashBoardHome}>
            <img src={Logo} />
          </Link>
        </div>
        <div className='notification-flex'>
          <div className='notification-icon'>
            <ul style={{ display: 'flex' }}>
              <li
                onClick={() => {
                  setNotification(!notification);
                }}
              >
                <a href='#'>
                  <i className='fa fa-bell'></i>
                  <span className='total-no'> {total_notifications} </span>
                  <div
                    className='total-notificaton'
                    style={{ display: notification ? 'block' : 'none' }}
                  >
                    {notification && (
                      <div className='total-notification-dropdown'>
                        {notifications &&
                          notifications.map((item) => {
                            return (
                              <div>
                                <Link
                                  to={`${item.url}?notification=${item._id}`}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '0px',
                                  }}
                                >
                                  <p>{item.name}</p>
                                </Link>
                              </div>
                            );
                          })}
                        <div className='show-flex'>
                          <div>
                            <Link
                              className='notification-link '
                              to={`/notifications`}
                            >
                              <div className='status-ic'>
                                <button className='badge badge-view'>
                                  Show All
                                </button>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <button
                              className='badge badge-open'
                              onClick={() => updateNotificationBulk()}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {total_notifications} */}
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div className='menu-bars'>
            <div onClick={() => setMobileMenu(!mobileMenu)}>
              <img src={mobileMenu ? CloseImg : MenuBar} />
            </div>
          </div>
        </div>
      </div>
      <div className='main-mobile'>
        {mobileMenu ? (
          <div id='mobile-nav' className='mobile-nav'>
            <div className='close-with-flex'>
              <div>
                <Link to={links.dashBoardHome}>
                  <img src={Logo} />
                </Link>
              </div>
              <div className='close-l'>
                <a className='close-l' onClick={() => setMobileMenu(false)}>
                  <i className='fa fa-close'></i>
                </a>
              </div>
            </div>
            <div className='after-login'>
              <div className='after-login-flex'>
                <div className='user-circle'>
                  {' '}
                  {getInitialName(
                    user && `${user.first_name} ${user.last_name}`
                  )}
                </div>
                <div className='address-student'>
                  <h5>{user && `${user.first_name} ${user.last_name}`}</h5>
                  <p> {user.email} </p>

                  <div className='user-agent-flex'>
                    <div>
                      <span className='badge agent'> {user.role}</span>
                    </div>
                    <div onClick={() => logoutUser()}>
                      <span className='badge logout'>Logout</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ul>
              {STUDENT_PROFILE_ENUM &&
                STUDENT_PROFILE_MENU.map((e) => {
                  if (e.roles.includes(user.role)) {
                    return (
                      <li>
                        <Link
                          to={e.slug}
                          className={active === e.name ? 'active' : ''}
                        >
                          {e.name}
                        </Link>
                      </li>
                    );
                  }
                })}
              {user && user.role === 'CRM_USER' && (
                <li>
                  <Link to='/settings/education-levels'>Settings</Link>
                </li>
              )}
            </ul>
          </div>
        ) : null}
      </div>
      {/* sp */}
    </>
  );
}

export default React.memo(Auth_header_mobile);
