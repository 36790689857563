import React from 'react';
import { Link } from 'react-router-dom';
import { PROFILE_TABS } from '../../domain/profiletab';
import PermissionComponent from '../auth/PermissionComponent';

export const AuthProfileTabs = ({ active, isStudent, id }) => {
  return (
    <>
      <div className='main-profile-tabs'>
        <ul>
          {PROFILE_TABS &&
            PROFILE_TABS.map((m) => {
              return (
                <li
                  key={m.name}
                  className={active === m.name ? 'tab-active' : ''}
                >
                  <Link to={isStudent ? `/students/${id}${m.slug}` : m.slug}>
                    {m.name}
                  </Link>
                </li>
              );
            })}
          <PermissionComponent
            name={'student'}
            permission={'view'}
            hideOnAgent={false}
            hideOnAcademicPartner={false}
            hideOnCounsellor={false}
            hideOnStudent={true}
          >
            <li>
              <Link to={`/my-applications?student=${id}`}>Applications</Link>
            </li>
          </PermissionComponent>
        </ul>
      </div>
    </>
  );
};
