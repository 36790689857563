import React, { useState } from 'react';
import moment from 'moment';
function IntakesAccordian({ intake }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div className='status-flex '>
        <div className='icon-cost'>
          <span class='badge badge-open'>{intake.status}</span>
        </div>
        <div className='fees-content'>
          <h5>{intake.intake_name}</h5>
        </div>
        <div onClick={() => setIsActive(!isActive)}>
          <div>
            <i
              className={isActive ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
            ></i>
          </div>
        </div>
      </div>
      {isActive && (
        <div className='open-intakes'>
          <div className='cost-flex'>
            <div className='icon-cost'>{intake.status}</div>
            <div className='fees-content'>
              <h5>
                {intake.open_date &&
                  moment(intake.open_date).format('DD-MM-YYYY')}
              </h5>
            </div>
          </div>
          <div className='cost-flex'>
            <div className='icon-cost'>Submission deadline</div>
            <div className='fees-content'>
              <h5>
                {intake.submission_deadline &&
                  moment(intake.submission_deadline).format('DD-MM-YYYY')}
              </h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IntakesAccordian;
