import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addCrmUser,
  getCrmUsers,
  getCrmUser,
  editCrmUser,
  deleteCrmUser,
  updatePasswordCrmUser,
  getCrmUsersFilter,
} from '../../store/actions/crm_user_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

// Add Data
export const useCreateCrmUser = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.crm_user);
  const addCrmUserData = async (data) => {
    dispatch(addCrmUser(data));
  };
  return { data, addCrmUserData };
};

// Get Single Data
export const useSingleCrmUser = (id) => {
  const dispatch = useDispatch();
  const crm_user_data = useSelector((state) => state.crm_user);
  useEffect(() => {
    dispatch(getCrmUser(id));
  }, [id]);
  return { crm_user_data };
};

//Edit Data
export const useUpdateCrmUser = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const updateCrmUserData = async (id, data) => {
    dispatch(editCrmUser(id, data));
  };
  return { updateCrmUserData };
};

//Update CRM USER
export const useChangePasswordCrmUser = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const updateCrmUserPasswordData = async (id, data) => {
    dispatch(updatePasswordCrmUser(id, data));
  };
  return { updateCrmUserPasswordData };
};

// Get All Data
export const useAllCrmUsers = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.crm_user);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getCrmUsers({}));
    }, 1000),
    []
  );

  return { data, setPageNumber, crm_user_data: data };
};

// Get All Data Filters
export const useAllCrmUsersFilter = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const data = useSelector((state) => state.crm_user);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getCrmUsersFilter({}));
  }, []);

  return {
    data,
    setPageNumber,
    crm_users_data: data,
    setCRMUserSearchTerm: setSearchTerm,
  };
};

//Delete Data
export const useDeleteCrmUser = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const deleteCrmUserFunction = async (id) => {
    dispatch(deleteCrmUser(id));
  };
  return { deleteCrmUserFunction };
};
