import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addAgent,
  getAgents,
  getAgent,
  editAgent,
  deleteAgent,
  updatePasswordAgent,
  editAgentInfo,
  editAgentProfileStatus,
  editAgentBankInfo,
  getCurrentAgent,
  assignCRMUser,
  filterAgents,
  changeCommissionTier,
  assignBulkUser,
} from '../../store/actions/agent_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { api } from '../../domain/api';
// Add Data
export const useCreateAgent = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.agent);
  const addAgentData = async (data) => {
    dispatch(addAgent(data));
  };
  return { data, addAgentData };
};

// Get Single Data
export const useSingleAgent = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.agent);
  useEffect(() => {
    dispatch(getAgent(id));
  }, [id]);
  return { data };
};
// Get Single Data
export const useCurrentAgent = () => {
  const dispatch = useDispatch();
  const agent_data = useSelector((state) => state.agent);
  useEffect(() => {
    dispatch(getCurrentAgent());
  }, []);
  return { agent_data };
};

//Edit Data
export const useUpdateAgent = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateAgentData = async (id, data) => {
    dispatch(editAgent(id, data));
  };
  return { updateAgentData };
};
//Edit Data
export const useUpdateAgentBankAccount = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateAgentBankData = async (id, data) => {
    dispatch(editAgentBankInfo(id, data));
  };
  return { updateAgentBankData };
};
//Edit Data
export const useUpdateAgentStatus = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateAgentStatusData = async (id, data) => {
    dispatch(editAgentProfileStatus(id, data));
  };
  return { updateAgentStatusData };
};
//Edit Data
export const useAssignCRMUser = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateCRMUser = async (id, data) => {
    dispatch(assignCRMUser(id, data));
  };
  return { updateCRMUser };
};
export const useBulkAssignCRMUser = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateCRMUserBulk = async ({ agents, crm_user }) => {
    dispatch(assignBulkUser({ agents, crm_user }));
  };
  return { updateCRMUserBulk };
};
export const useChangeCommissionTier = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const changeCommissionTierFunction = async (id, data) => {
    dispatch(changeCommissionTier(id, data));
  };
  return { changeCommissionTierFunction };
};
//Edit Data
export const useUpdateAgentInfo = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateAgentData = async (id, data) => {
    dispatch(editAgentInfo(id, data));
  };
  return { updateAgentData };
};
//Edit Data
export const useChangePasswordAgent = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const updateAgentPasswordData = async (id, data) => {
    dispatch(updatePasswordAgent(id, data));
  };
  return { updateAgentPasswordData };
};

// Get All Data
export const useAllAgents = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.agent);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getAgents({}));
    }, 1000),
    []
  );

  return { data, setPageNumber };
};

// useagent-filter-hook
export const useAllAgentsFilters = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const data = useSelector((state) => state.agent);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(filterAgents({}));
  }, []);

  return {
    data,
    setPageNumber,
    agents_data: data,
    setAgentSearchTerm: setSearchTerm,
  };
};
export const useAllAgentsReport = () => {
  const [loading_report, setLoading_report] = useState(false);
  const [reportData, setReportData] = useState(null);
  const getData = async () => {
    setLoading_report(true);
    const { data } = await api.get('/agents/all-reports');
    setReportData(data);
    setLoading_report(false);
  };
  return {
    reportData,
    getData,
    loading_report,
  };
};

//Delete Data
export const useDeleteAgent = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Agent);
  const deleteAgentFunction = async (id) => {
    dispatch(deleteAgent(id));
  };
  return { deleteAgentFunction };
};
