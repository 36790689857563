import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AgentCompleteFormComponent from '../../components/agents/AgentCompleteFormComponent';
import AgentCompleteProfileFormComponent from '../../components/agents/AgentCompleteProfileFormComponent';
import EditAgentComponent from '../../components/agents/EditAgentComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import BankDetailsComponent from '../../components/agents/BankDetailsComponent';
import { useSingleAgent } from '../../shared/hooks/UseAgent';
import {
  useAllNotifications,
  useUpdateNotification,
} from '../../shared/hooks/UseNotification';
import * as qs from 'qs';
import AllEditAgentComponent from '../../components/agents/AllEditAgentComponent';

function EditAgent() {
  const params = useParams();
  const { data } = useSingleAgent(params.id);
  const { agent, agent_loading } = data;

  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [notificationParam, setNotificationParam] = useState(null);

  // const [notificatiton_data, reloadNotificationData] =
  //   useSelectAllNotification();
  const [updateData] = useUpdateNotification();

  useEffect(() => {
    if (notificationParam) {
      updateData(notificationParam, { is_read: true });
      // reloadNotificationData();
    }
  }, [notificationParam]);

  useEffect(() => {
    if (queryParams.notification) {
      setNotificationParam(queryParams.notification);
    }
  }, [queryParams && queryParams.notification]);

  return (
    <div className='wrapper'>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Edit Agent'} />

      {/* page-section */}

      <section className='personal-information ptb-20 '>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <AllEditAgentComponent
                // agent={agent}
                agent_info={agent && agent.agentInfo}
                agent={agent}
                agent_id={agent && agent.agent && agent.agent._id}
                bank_account_details={
                  agent &&
                  agent.agentInfo &&
                  agent.agentInfo.bank_account_details
                }
              />
              {/* <EditAgentComponent agent={agent} /> */}
            </div>
          </div>
        </div>
      </section>
      {/* <section className='personal-information-box ptb-20 '>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              {agent && (
                <div className='card-main-box'>
                  <AgentCompleteProfileFormComponent
                    agent={agent && agent.agentInfo}
                    agent_id={agent && agent.agent && agent.agent._id}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className='personal-information-box ptb-20 '>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card-main-box'>
                {agent && (
                  <BankDetailsComponent
                    bank_account_details={
                      agent &&
                      agent.agentInfo &&
                      agent.agentInfo.bank_account_details
                    }
                    agent_id={agent && agent.agent && agent.agent._id}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <AuthFooter />
    </div>
  );
}

export default EditAgent;
