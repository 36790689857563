import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { api } from '../../domain/api';
import * as qs from 'qs';
// Get All Data
export const useAllDashboard = () => {
  const location = useLocation();

  const [dashboard, setDashboard] = useState(null);

  const getDashboard = async () => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/dashboards?&${query}`);
    setDashboard(data);
  };

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      getDashboard();
    }, 1000),
    []
  );

  return { dashboard };
};
