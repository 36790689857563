import React from 'react';
import Slider from 'react-slick';
import { URI } from '../../domain/constant';

function SchoolGallery({ setShowFullGallery, customSettings, school }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...customSettings,
  };
  return (
    <>
      <section className='slider-single'>
        <div className='school-profile-photos'>
          <div className='absolute-btn'>
            <button
              className='btn btn-view'
              onClick={() => {
                setShowFullGallery(true);
              }}
            >
              View Photos
            </button>
          </div>
          <Slider {...settings}>
            {school.school_photos &&
              school.school_photos.map((item, index) => {
                return (
                  <div key={`gallery-${index}`}>
                    <img
                      src={`${URI}${item}`}
                      style={{ objectFit: 'cover', height: '70vh' }}
                    />
                  </div>
                );
              })}
          </Slider>
          {/* <ImageGallery items={images} lazyLoad={true} /> */}
        </div>
      </section>
    </>
  );
}

export default SchoolGallery;
