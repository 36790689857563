import React, { useState } from 'react';
import { DummySchool, DummyUser, LogoTemplate } from '../images';
import { URI } from '../../domain/constant';
import { Link } from 'react-router-dom';
function SingleApplicationProgramInformationComponent({
  program,
  application,
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className='box-show-and-hide'>
        <div
          className='main-heading-container'
          onClick={() => {
            setShow(!show);
          }}
        >
          <div className='school-details-box-flex'>
            <div>
              <h3>Program Information</h3>
            </div>
            <div className=''>
              <i
                className={show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
              ></i>
            </div>
          </div>
        </div>
        <div
          className='application-full-requirement'
          style={{ display: show ? 'block' : 'none' }}
        >
          <div className='main-application-content-flex'>
            <div className='dummy-image'>
              <img
                src={
                  program.school.logo
                    ? `${URI}${program.school.logo}`
                    : DummySchool
                }
              />
            </div>
            <div class='similar-card-content'>
              <span>
                {program.education_level && program.education_level.name}
              </span>
              <Link to={`/programs/${program._id}`}>
                <h5>{program.name}</h5>
              </Link>

              <div class='similar-card-deadline-flex'>
                {/* <div>
                  <h5>Earliest intake</h5>
                  <h6>Sep 2023</h6>
                </div>
                <div>
                  <h5>Deadline</h5>
                  <h6>Jun 2023</h6>
                </div> */}
                <div>
                  <h5>Tuition</h5>
                  <h6>
                    {program.school &&
                      program.school.market &&
                      program.school.market.currency}{' '}
                    {program.tuition}
                  </h6>
                </div>
                <div>
                  <h5>Delivery Method: </h5>
                  <span class='badge inclass'>
                    {program.delivery_method === `online`
                      ? 'Online'
                      : 'In Class'}
                  </span>
                </div>
                <div>
                  <h5>Level</h5>
                  <h6>
                    {program.education_level && program.education_level.name}
                  </h6>
                </div>
                {application.intake && (
                  <div>
                    <h5>Intake</h5>
                    <h6>{application.intake}</h6>
                  </div>
                )}

                {/* <div>
                  <h5>Required Level</h5>
                  <h6>Grade 12 / High School</h6>
                </div> */}
                {/* <div>
                  <h5>Academic Submission Deadline</h5>
                  <span class='badge danger'>Feb 07, 2023</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleApplicationProgramInformationComponent;
