import { useSelector, useDispatch } from 'react-redux';
import { removeAlert } from '../../store/actions/alert_action';

export const useAlert = () => {
  const data = useSelector((state) => state.alert_reducer);

  return data;
};
export const useCloseAlert = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.alert_reducer);
  const closeAlertFunction = (id) => {
    dispatch(removeAlert(id));
  };

  return { data, closeAlertFunction };
};
