import React from 'react';
import {
  Calandar,
  Calculator,
  Certificate,
  CostLiving,
  TutionFees,
} from '../../components/images';
function SingleProgramKeypointsComponent({ program }) {
  return (
    <div>
      <div className='single-school-sidebar  single-program-keypoints'>
        <div className='cost-flex'>
          <div className='icon-cost'>
            <img src={Certificate} />
          </div>
          <div className='fees-content'>
            <h5> {program.education_level && program.education_level.name}</h5>
            <h6>Program Level</h6>
          </div>
        </div>
        {program.discipline && (
          <div className='cost-flex'>
            <div className='icon-cost'>
              <img src={Certificate} />
            </div>
            <div className='fees-content'>
              <h5> {program.discipline.name}</h5>
              <h6>Discipline</h6>
            </div>
          </div>
        )}
        {program.sub_discipline && (
          <div className='cost-flex'>
            <div className='icon-cost'>
              <img src={Certificate} />
            </div>
            <div className='fees-content'>
              <h5> {program.sub_discipline.name}</h5>
              <h6>Sub Discipline</h6>
            </div>
          </div>
        )}

        <div className='cost-flex'>
          <div className='icon-cost'>
            <img src={Calandar} />
          </div>
          <div className='fees-content'>
            <h5>{program.length_breakdown}</h5>
            <h6>Program Length</h6>
          </div>
        </div>
        <div className='cost-flex'>
          <div className='icon-cost'>
            <img src={Calculator} />
          </div>
          <div className='fees-content'>
            <h5>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    program.school && program.school.market
                      ? program.school.market.cost_of_living
                      : 'Not Available',
                }}
              ></div>
            </h5>
            <h6>Cost of Living</h6>
          </div>
        </div>
        <div className='cost-flex'>
          <div className='icon-cost'>
            <img src={CostLiving} />
          </div>
          <div className='fees-content'>
            <h5>
              {program.school &&
                program.school.market &&
                program.school.market.currency}
              {program.tuition} / Year
            </h5>
            <h6>Tuition</h6>
          </div>
        </div>
        <div className='cost-flex'>
          <div className='icon-cost'>
            <img src={TutionFees} />
          </div>
          <div className='fees-content'>
            <h5>
              {program.school &&
                program.school.market &&
                program.school.market.currency}
              {program.application_fee}
            </h5>
            <h6>Application Fee</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleProgramKeypointsComponent;
