import {
  ADD_VISA_AND_STUDY_PERMIT_STARTED,
  ADD_VISA_AND_STUDY_PERMIT_SUCCESS,
  ADD_VISA_AND_STUDY_PERMIT_ENDED,
  ADD_VISA_AND_STUDY_PERMIT_ERROR,
} from '../types/Visa_and_study_permit.type';

const initialState = {
  add_visa_and_study_permit_loading: false,
  add_visa_and_study_permit_errors: null,
  add_visa_and_study_permit_success: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_VISA_AND_STUDY_PERMIT_STARTED:
      return {
        ...state,
        add_visa_and_study_permit_loading: true,
        add_visa_and_study_permit_errors: null,
        add_visa_and_study_permit_success: null,
      };
    case ADD_VISA_AND_STUDY_PERMIT_ENDED:
      return {
        ...state,
        add_visa_and_study_permit_loading: false,
      };
    case ADD_VISA_AND_STUDY_PERMIT_ERROR:
      return {
        ...state,
        add_visa_and_study_permit_errors: payload,
      };
    case ADD_VISA_AND_STUDY_PERMIT_SUCCESS:
      return {
        ...state,
        add_visa_and_study_permit_success: payload,
      };

    default:
      return state;
  }
}
