import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectBox, HTMLEditor } from '../Form/Form';
import * as Yup from 'yup';

import ProgramForm from './ProgramForm';
import {
  useCreateProgram,
  useSingleProgramBySlug,
  useUpdateProgram,
} from '../../shared/hooks/UseProgram';
import {
  useAllSchools,
  useAllSchoolsFilters,
} from '../../shared/hooks/UseSchool';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';
import { useNavigate, useParams } from 'react-router-dom';
import { programValidationSchema } from './ProgramValidation';
import { useAllDisciplines } from '../../shared/hooks/UseDiscipline';
function EditProgramComponent() {
  const params = useParams();
  const { program_data } = useSingleProgramBySlug(params.slug);
  const { program, program_loading } = program_data;
  const { schools } = useAllSchoolsFilters();

  const { educationLevelData } = useAllEducationLevels();
  const { discipline_data } = useAllDisciplines();
  const { disciplines } = discipline_data;

  const { education_levels } = educationLevelData;
  const navigate = useNavigate();
  const { updateProgramData } = useUpdateProgram();
  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Edit Program</h1>
        </div>
        {program && (
          <Formik
            initialValues={{
              school: program.school ? program.school._id : '',
              slug: program.slug ? program.slug : '',
              delivery_method: program.delivery_method
                ? program.delivery_method
                : '',
              name: program.name ? program.name : '',
              description: program.description ? program.description : '',
              education_level: program.education_level
                ? program.education_level._id
                : '',
              discipline: program.discipline ? program.discipline._id : '',
              min_length: program.min_length ? program.min_length : '',
              max_length: program.max_length ? program.max_length : '',
              length_breakdown: program.length_breakdown
                ? program.length_breakdown
                : '',
              commission_type: program.commission_type
                ? program.commission_type
                : '',
              commission_year: program.commission_year
                ? program.commission_year
                : '',
              commission_percentage: program.commission_percentage
                ? program.commission_percentage
                : '',

              tuition: program.tuition ? program.tuition : '',
              source: program.source ? program.source : '',
              application_fee: program.application_fee
                ? program.application_fee
                : '',
              other_fees: program.other_fees ? program.other_fees : [],
              program_intakes: program.program_intakes
                ? program.program_intakes
                : [],
            }}
            validationSchema={programValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateProgramData(program._id, values);
              navigate(`/programs/${program.slug}`);
              // navigate(`/programs`);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <ProgramForm
                    edit={true}
                    formik={formik}
                    dropdown_data={{ schools, education_levels, disciplines }}
                    program={program}
                  />
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
}

export default EditProgramComponent;
