import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addTemplate,
  getTemplates,
  getTemplate,
  editTemplate,
  deleteTemplate,
} from '../../store/actions/template_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreateTemplate = () => {
  const dispatch = useDispatch();
  const template_data = useSelector((state) => state.template);
  const addTemplateData = async (data) => {
    dispatch(addTemplate(data));
  };
  return { template_data, addTemplateData };
};

// Get Single Data
export const useSingleTemplate = (id) => {
  const dispatch = useDispatch();
  const template_data = useSelector((state) => state.template);
  useEffect(() => {
    dispatch(getTemplate(id));
  }, [id]);
  return { template_data };
};

//Edit Data
export const useUpdateTemplate = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Template);
  const updateTemplateData = async (id, data) => {
    dispatch(editTemplate(id, data));
  };
  return { updateTemplateData };
};

// Get All Data
export const useAllTemplates = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const template_data = useSelector((state) => state.template);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getTemplates({}));
    }, 1000),
    []
  );

  return { template_data, setPageNumber };
};

//Delete Data
export const useDeleteTemplate = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Template);
  const deleteTemplateFunction = async (id) => {
    dispatch(deleteTemplate(id));
  };
  return { deleteTemplateFunction };
};
