import {
  GET_STUDENTS_STARTED,
  GET_STUDENTS,
  GET_STUDENTS_ENDED,
  ADD_STUDENT_STARTED,
  ADD_STUDENT,
  ADD_STUDENT_ENDED,
  EDIT_STUDENT_STARTED,
  EDIT_STUDENT,
  EDIT_STUDENT_ENDED,
  GET_STUDENT,
  GET_STUDENT_STARTED,
  GET_STUDENT_ENDED,
} from '../types/student_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addStudent
export const addStudent = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_STUDENT_STARTED,
    });
    const { data } = await api.post(`/students/add`, formData);
    dispatch({
      type: ADD_STUDENT,
      payload: data,
    });
    dispatch({
      type: ADD_STUDENT_ENDED,
    });
    dispatch(setAlert('Student added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_STUDENT_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getStudents =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_STUDENTS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/students?&${query}`);

      dispatch({
        type: GET_STUDENTS,
        payload: data,
      });
      dispatch({
        type: GET_STUDENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENTS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const studentsFilter =
  ({ value = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_STUDENTS_STARTED,
      });
      let searchUrl = ``;
      if (value) {
        searchUrl = `/students/filter?&search[first_name]=${value}`;
      } else {
        searchUrl = `/students/filter`;
      }
      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_STUDENTS,
        payload: data,
      });
      dispatch({
        type: GET_STUDENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENTS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const getStudent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    const { data } = await api.get(`/students/${id}`);

    dispatch({
      type: GET_STUDENT,
      payload: data,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getStudentBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    const { data } = await api.get(`/students/slug/${slug}`);

    dispatch({
      type: GET_STUDENT,
      payload: data,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editStudent = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_STUDENT_STARTED,
    });
    const { data } = await api.put(`/students/${id}`, formData);
    dispatch({
      type: EDIT_STUDENT,
      payload: data,
    });
    dispatch({
      type: EDIT_STUDENT_ENDED,
    });
    dispatch(setAlert('Student Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_STUDENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftStudent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_STUDENT_STARTED,
    });
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    const { data } = await api.put(`/students/${id}/draft`);
    dispatch({
      type: EDIT_STUDENT,
      payload: data,
    });
    dispatch({
      type: GET_STUDENT,
      payload: data,
    });

    dispatch({
      type: EDIT_STUDENT_ENDED,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_STUDENT_ENDED,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateStudent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_STUDENT_STARTED,
    });
    const { data } = await api.put(`/students/${id}/active`);
    dispatch({
      type: EDIT_STUDENT,
      payload: data,
    });
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    dispatch({
      type: EDIT_STUDENT_ENDED,
    });
    dispatch({
      type: GET_STUDENT,
      payload: data,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
    dispatch(setAlert('Student Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_STUDENT_ENDED,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteStudent = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/students/${id}`);
    dispatch(setAlert('Student Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
