import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AddRequirementForm from './AddRequirementForm';
import { useCreateRequirement } from '../../shared/hooks/UseRequirement';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';

function AddRequirementsComponent({ program, school }) {
  const { addRequirementData } = useCreateRequirement();
  const navigate = useNavigate();
  const { educationLevelData } = useAllEducationLevels();
  const { education_levels } = educationLevelData;
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Requirements</h1>
        </div>

        <Formik
          initialValues={{
            english_score_required: false,
            allow_delayed_proof_of_elp: false,
            min_gpa: '',
            min_toefl_reading: '',
            min_toefl_writing: '',
            min_toefl_listening: '',
            min_toefl_speaking: '',
            min_toefl_total: '',
            min_ielts_reading: '',
            min_ielts_writing: '',
            min_ielts_listening: '',
            min_ielts_speaking: '',
            min_ielts_average: '',
            min_ielts_any_band: '',
            min_ielts_any_band_count: '',
            min_ielts_ukvi_reading: '',
            min_ielts_ukvi_writing: '',
            min_ielts_ukvi_listening: '',
            min_ielts_ukvi_speaking: '',
            min_ielts_ukvi_average: '',
            min_ielts_ukvi_any_band: '',
            min_ielts_ukvi_any_band_count: '',
            min_duolingo_score: '',
            min_pte_listening: '',
            min_pte_reading: '',
            min_pte_speaking: '',
            min_pte_writing: '',
            min_pte_overall: '',
            level: '',
            level_text: '',
            other_requirements: '',
            gre_requirements: {
              required: '',
              max_gpa_to_be_required: '',
              min_verbal: '',
              min_quantitative: '',
              min_writing: '',
              min_verbal_percentile: '',
              min_quantitative_percentile: '',
              min_writing_percentile: '',
              min_verbal_quantitative_combined: '',
              gmat_min_verbal: '',
              gmat_min_quantitative: '',
              gmat_min_writing: '',
              gmat_min_verbal_percentile: '',
              gmat_min_quantitative_percentile: '',
              gmat_min_writing_percentile: '',
              gmat_min_total: '',
              gmat_min_total_percentile: '',
            },
          }}
          validationSchema={Yup.object({
            level: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            values.program = program;
            // addAgentData(values);
            addRequirementData(values);
            navigate(`/programs`);
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <AddRequirementForm
                formik={formik}
                dropdown_options={{ education_levels: education_levels }}
              />
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddRequirementsComponent;
