import React from 'react';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import AddSlabComponent from '../../components/slabs/AddSlabComponent';

function AddSlab() {
  return (
    <>
      <div className='wrapper'>
        <Auth_header_mobile />
        <AuthHeader />
        <AuthBreadCrumb title={'Add Commission Tier'} />

        {/* page-section */}

        <section className='  personal-information ptb-20 '>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <AddSlabComponent />
              </div>
            </div>
          </div>
        </section>
        <AuthFooter />
      </div>
    </>
  );
}

export default AddSlab;
