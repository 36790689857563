import {
  GET_COUNSELLORS_STARTED,
  GET_COUNSELLORS,
  GET_COUNSELLORS_ENDED,
  ADD_COUNSELLOR_STARTED,
  ADD_COUNSELLOR,
  ADD_COUNSELLOR_ENDED,
  EDIT_COUNSELLOR_STARTED,
  EDIT_COUNSELLOR,
  EDIT_COUNSELLOR_ENDED,
  GET_COUNSELLOR,
  GET_COUNSELLOR_STARTED,
  GET_COUNSELLOR_ENDED,
} from '../types/counsellor_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addCrm_user
export const addCounsellor = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_COUNSELLOR_STARTED,
    });
    const { data } = await api.post(`/counsellors/add`, formData);
    dispatch({
      type: ADD_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: ADD_COUNSELLOR_ENDED,
    });
    dispatch(setAlert('Counsellor added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_COUNSELLOR_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getCounsellors =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_COUNSELLORS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/counsellors?&${query}`);

      dispatch({
        type: GET_COUNSELLORS,
        payload: data,
      });
      dispatch({
        type: GET_COUNSELLORS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_COUNSELLORS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getCounsellor = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNSELLOR_STARTED,
    });
    const { data } = await api.get(`/counsellors/${id}`);

    dispatch({
      type: GET_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getCrm_userBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNSELLOR_STARTED,
    });
    const { data } = await api.get(`/counsellors/slug/${slug}`);

    dispatch({
      type: GET_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editCounsellor = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNSELLOR_STARTED,
    });
    const { data } = await api.put(`/counsellors/${id}`, formData);
    dispatch({
      type: EDIT_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch(setAlert('Counsellor Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const assignAgent = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNSELLOR_STARTED,
    });
    const { data } = await api.put(`/counsellors/update-agent/${id}`, formData);
    dispatch({
      type: EDIT_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch(setAlert('Counsellor Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const updatePasswordCounsellor = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNSELLOR_STARTED,
    });
    const { data } = await api.put(
      `/counsellors/${id}/change-password`,
      formData
    );
    dispatch({
      type: EDIT_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch(setAlert('Counsellor Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const draftCounsellor = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNSELLOR_STARTED,
    });
    dispatch({
      type: GET_COUNSELLOR_STARTED,
    });
    const { data } = await api.put(`/counsellors/${id}/draft`);
    dispatch({
      type: EDIT_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: GET_COUNSELLOR,
      payload: data,
    });

    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateCounsellor = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNSELLOR_STARTED,
    });
    const { data } = await api.put(`/counsellors/${id}/active`);
    dispatch({
      type: EDIT_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: GET_COUNSELLOR_STARTED,
    });
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch({
      type: GET_COUNSELLOR,
      payload: data,
    });
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });
    dispatch(setAlert('Counsellor Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNSELLOR_ENDED,
    });
    dispatch({
      type: GET_COUNSELLOR_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteCounsellor = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/counsellors/${id}`);
    dispatch(setAlert('Counsellor Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
