import {
  GET_TEMPLATES_STARTED,
  GET_TEMPLATES,
  GET_TEMPLATES_ENDED,
  ADD_TEMPLATE_STARTED,
  ADD_TEMPLATE,
  ADD_TEMPLATE_ENDED,
  EDIT_TEMPLATE_STARTED,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_ENDED,
  GET_TEMPLATE,
  GET_TEMPLATE_STARTED,
  GET_TEMPLATE_ENDED,
} from '../types/template_types';

const initialState = {
  templates_loading: true,
  templates: null,
  template_page: null,
  template_pages: null,
  total_templates: 0,

  template: null,
  template_loading: null,

  add_template_loading: true,
  edit_template_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TEMPLATES_STARTED:
      return {
        ...state,
        templates: null,
        template_pages: null,
        template_page: null,
        total_templates: 0,
        templates_loading: true,
      };
    case GET_TEMPLATES:
      return {
        ...state,
        templates: payload.templates,
        template_pages: payload.pages,
        template_page: payload.page,
        total_templates: payload.total_templates,
      };
    case GET_TEMPLATES_ENDED:
      return {
        ...state,
        templates_loading: false,
      };

    case ADD_TEMPLATE_STARTED:
      return {
        ...state,
        template_message: null,
        add_template_loading: true,
      };
    case ADD_TEMPLATE:
      return {
        ...state,
        template_message: payload,
      };
    case ADD_TEMPLATE_ENDED:
      return {
        ...state,
        add_template_loading: false,
      };
    case GET_TEMPLATE_STARTED:
      return {
        ...state,
        template: null,
        template_loading: true,
      };
    case GET_TEMPLATE:
      return {
        ...state,
        template: payload,
      };
    case GET_TEMPLATE_ENDED:
      return {
        ...state,
        template_loading: false,
      };
    case EDIT_TEMPLATE_STARTED:
      return {
        ...state,
        template_message: null,
        edit_template_loading: true,
      };
    case EDIT_TEMPLATE:
      return {
        ...state,
        template_message: payload,
      };
    case EDIT_TEMPLATE_ENDED:
      return {
        ...state,
        edit_template_loading: false,
      };

    default:
      return state;
  }
}
