import React from 'react';
import { LoginImage } from '../../components/images';
import { Formik, Form } from 'formik';
import { TextArea, TextInput, SelectBox } from '../../components/Form/Form';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { useResetPassword } from '../../shared/hooks/UseAuth';
import { Alerts } from '../../components/common/Alerts';

export const ResetPassword = (props) => {
  const [resetUserPassword] = useResetPassword();

  const params = useParams();
  return (
    <div className='reset-password'>
      <div className='reset-flex'>
        <div className='reset-main mt-5'>
          <div className='reset-password-form'>
            <Formik
              initialValues={{
                password: '',
                confirm_password: '',
              }}
              validationSchema={Yup.object({
                password: Yup.string().required('Required'),
                confirm_password: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                values.token = params.token;
                resetUserPassword(values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <h3>Reset Password</h3>
                    <p></p>
                    <label>Enter new Password to reset </label>

                    <TextInput
                      label='Password'
                      name='password'
                      type='password'
                    />
                    <TextInput
                      label='Confirm Password'
                      name='confirm_password'
                      type='password'
                    />

                    <div className='create-button'>
                      <button className='btn primary-btn w-100' type='submit'>
                        Reset Password
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <hr></hr>
            <div className='terms'>
              <Link to={links.login}> Back to Log In </Link>
            </div>
          </div>
        </div>
        <div className='reset-image'>
          <img src={LoginImage} />
        </div>
      </div>
      <Alerts />
    </div>
  );
};
