import React from 'react';
import { LoginImage } from '../../components/images';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';

export const VerifyEmail = (props) => {
  return (
    <div className='reset-password'>
      <div className='reset-flex'>
        <div className='reset-main mt-5'>
          <div className='reset-password-form'>
            <form>
              <h3>Verify email Address</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <p>
                A link to reset your password has been sent to
                shubhampathak1996@gmail.com
              </p>

              <div className='create-button'>
                <Link to={links.login} className='btn primary-btn w-100'>
                  Back to Log In
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className='reset-image'>
          <img src={LoginImage} />
        </div>
      </div>
    </div>
  );
};
