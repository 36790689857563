export const ApplicationRequirement = [
  'Pre Application Fee Paid',
  'Pre Application Prepared',
  'Pre Submission',
  'Pre Offer',
  'Post Offer',
  'Pre UCOL/LOA',
  'Pre Tuition Fee Paid',
  'Pre Visa Applied',
  'Pre Visa Issued',
  'Pre Enrolled',
];
