import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  PasswordInput,
} from '../../components/Form/Form';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { links } from '../../domain/links.enum';
import { LoginImage } from '../../components/images';
import { Alerts } from '../../components/common/Alerts';
import {
  useLoginUser,
  useCheckAuthenticated,
} from '../../shared/hooks/UseAuth';

export const Login = (props) => {
  const [loginUser] = useLoginUser();
  const userData = useCheckAuthenticated();

  return (
    <>
      <div className="login-student">
        <div className="login-flex">
          <div className="login-main">
            <div className="login-student-form">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string().email().required('Required'),

                  password: Yup.string()
                    .required('Required')
                    .min(
                      4,
                      ({ min }) => `Password must be at least ${min} characters`
                    )
                    .max(
                      20,
                      ({ max }) => `Password must be at least ${max} characters`
                    ),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  loginUser(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <h3>Log In</h3>
                      <p>Please fill the details to login</p>

                      <TextInput label="Email" name="email" />
                      <div>
                        <PasswordInput label="Password" name="password" />
                      </div>

                      <div className="create-button">
                        <button className="btn primary-btn w-100" type="submit">
                          Login
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <hr></hr>
              <div className="terms">
                <Link to={links.forgotPassword}>Forgot Password ?</Link>
              </div>
              <div className="noaccount">
                <h5>Don’t have an account?</h5>
                <div>
                  <Link to={links.registerAsStudent}>
                    Register as a Student
                  </Link>
                </div>
                <div>
                  <Link to={links.registerAsAgent}>
                    Register as a Recruitment Partner
                  </Link>
                </div>
              </div>
              <div className="privacy-link">
                <div>
                  <Link to={links.privacyPolicy}>Privacy & Cookies Policy</Link>
                </div>
                <div>
                  <Link to={links.termsAndConditions}>Terms & Conditions</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="login-image">
            <img src={LoginImage} />
          </div>
        </div>
      </div>
      <Alerts />
    </>
  );
};
