import React from 'react';
import ProgramSearchCardComponent from './ProgramSearchCardComponent';
import Pagination from '../common/Pagination';

function AllProgramsTab({
  programs,
  total_programs,
  program_pages,
  programs_loading,
  hideStartApplication,
}) {
  return (
    <div className='similar-program-single'>
      <p> {total_programs} Records Found </p>
      <div id='similar_program' className='overview-content'>
        <div className='row mt-3'>
          {programs &&
            programs.map((item) => {
              if (item.school) {
                return (
                  <ProgramSearchCardComponent
                    program={item}
                    hideStartBtn={hideStartApplication}
                  />
                );
              }
            })}
        </div>
        <p> {total_programs} Records Found </p>
        <Pagination
          count={total_programs}
          pages={program_pages}
          loading={programs_loading}
        />
      </div>
    </div>
  );
}

export default AllProgramsTab;
