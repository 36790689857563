import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import UpdatePasswordComponentForm from '../../containers/crmUser/UpdatePasswordComponentForm';
function UpdatePasswordComponent({ navigationLink, onSubmitHandler }) {
  const navigate = useNavigate();
  return (
    <div className='update-password'>
      <h3>Update Password</h3>
      <Formik
        initialValues={{
          password: '',

          Confirm_password: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required('Required')
            .min(4, ({ min }) => `Password must be at least ${min} characters`)
            .max(
              20,
              ({ max }) => `Password must be at least ${max} characters`
            ),

          confirm_password: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('password')], 'Both password need to be the same'),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmitHandler(values);
          resetForm();
          setSubmitting(false);
          navigate(navigationLink);
        }}
      >
        {(formik) => {
          //
          return <UpdatePasswordComponentForm formik={formik} />;
        }}
      </Formik>
    </div>
  );
}

export default UpdatePasswordComponent;
