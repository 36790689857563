import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectBox, TextArea, HTMLEditor } from '../Form/Form';
import * as Yup from 'yup';
// import { countries } from '../../domain/countries';
import SchoolForm from './SchoolForm';
import { schoolValidationSchema } from './SchoolValidation';
import { Country, State, City } from 'country-state-city';
import { useCreateSchool, useUpdateSchool } from '../../shared/hooks/UseSchool';
import { useNavigate } from 'react-router-dom';
function DuplicateSchoolComponent({ school, markets }) {
  const navigate = useNavigate();
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedStateCode, setSelectedStateCode] = useState(null);
  const [loading_country, setLoading_country] = useState(true);
  const { updateSchoolData } = useUpdateSchool();
  const { addSchoolData } = useCreateSchool();

  useEffect(() => {
    if (school.country && school.state) {
      setLoading_country(true);
      const countries = Country.getAllCountries();
      const filteredData = countries.filter(
        (item) => item.name === school.country
      );
      if (filteredData && filteredData.length > 0) {
        setSelectedCountryCode(filteredData[0].isoCode);
        const states = State.getStatesOfCountry(filteredData[0].isoCode);

        if (states.length > 0) {
          const filteredState = states.filter(
            (item) => item.name === school.state
          );
          if (filteredState && filteredState.length > 0) {
            setSelectedStateCode(filteredState[0].isoCode);
          }
        }
      }
      setLoading_country(false);
    }
  }, [school.country, school.state]);

  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Duplicate School </h1>
        </div>
        {!loading_country && (
          <Formik
            initialValues={{
              about: school.about ? school.about : '',
              market: school.market ? school.market._id : '',
              address: school.address ? school.address : '',
              allow_agency_recruitment: school.allow_agency_recruitment
                ? school.allow_agency_recruitment
                : false,
              can_work_and_study: school.can_work_and_study
                ? school.can_work_and_study
                : false,
              country_code: selectedCountryCode,
              state_code: selectedStateCode,
              city: school.city ? school.city : '',
              conditional_acceptance: school.conditional_acceptance
                ? school.conditional_acceptance
                : false,
              coop_participating: school.coop_participating
                ? school.coop_participating
                : false,
              coop_participating_show: school.coop_participating_show
                ? school.coop_participating_show
                : false,
              coop_participating_text: school.coop_participating_text
                ? school.coop_participating_text
                : '',
              cost_of_living: school.cost_of_living
                ? school.cost_of_living
                : '',
              slug: school.slug ? school.slug : '',
              dial_code: school.dial_code ? school.dial_code : '',
              currency: school.currency ? school.currency : '',
              email: school.email ? school.email : '',
              facebook: school.facebook ? school.facebook : '',
              founded_in: school.founded_in ? school.founded_in : '',
              institution_type: school.institution_type
                ? school.institution_type
                : '',
              latitude: school.latitude ? school.latitude : '',
              linkedin: school.linkedin ? school.linkedin : '',
              longitude: school.longitude ? school.longitude : '',
              name: school.name ? school.name : '',
              number_of_international_students:
                school.number_of_international_students
                  ? school.number_of_international_students
                  : '',
              phone: school.phone ? school.phone : '',
              postal_code: school.postal_code ? school.postal_code : '',
              country: school && school.country ? school.country : '',
              country_code:
                school && school.country
                  ? Country.getAllCountries().filter(
                      (item) => item.name === school.country
                    )[0].isoCode
                  : '',

              state: school && school.state ? school.state : '',

              school_rank: school.school_rank ? school.school_rank : '',
              show_other_requirements_fees: school.show_other_requirements_fees
                ? school.show_other_requirements_fees
                : false,
              submission_path_note: school.submission_path_note
                ? school.submission_path_note
                : '',
              submission_through: school.submission_through
                ? school.submission_through
                : '',
              time_zone: school.time_zone ? school.time_zone : '',
              total_number_of_students: school.total_number_of_students
                ? school.total_number_of_students
                : '',
              tuition_deposit: school.tuition_deposit
                ? school.tuition_deposit
                : '',
              tuition_information: school.tuition_information
                ? school.tuition_information
                : '',
              twitter: school.twitter ? school.twitter : '',
              video_link: school.video_link ? school.video_link : '',
              website: school.website ? school.website : '',
              conditional_acceptance_text: school.conditional_acceptance_text
                ? school.conditional_acceptance_text
                : '',
              exceptional_submission_path: school.exceptional_submission_path
                ? school.exceptional_submission_path
                : '',
              school_photos: school.school_photos ? school.school_photos : [],
              logo: school.logo ? school.logo : '',
              school_features: school.school_features
                ? school.school_features
                : [{ title: '', description: '' }],
            }}
            validationSchema={schoolValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              addSchoolData(values);
              navigate(`/schools`);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <SchoolForm formik={formik} dropdown_options={{ markets }} />
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
}

export default DuplicateSchoolComponent;
