import {
  GET_APPLICATION_NOTES_STARTED,
  GET_APPLICATION_NOTES,
  GET_APPLICATION_NOTES_ENDED,
  ADD_APPLICATION_NOTE_STARTED,
  ADD_APPLICATION_NOTE,
  ADD_APPLICATION_NOTE_ENDED,
  EDIT_APPLICATION_NOTE_STARTED,
  EDIT_APPLICATION_NOTE,
  EDIT_APPLICATION_NOTE_ENDED,
  GET_APPLICATION_NOTE,
  GET_APPLICATION_NOTE_STARTED,
  GET_APPLICATION_NOTE_ENDED,
} from '../types/application_note_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addApplicationNote
export const addApplicationNote = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_APPLICATION_NOTE_STARTED,
    });
    const { data } = await api.post(`/application-notes/add`, formData);
    dispatch({
      type: ADD_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_NOTES,
      payload: data,
    });

    dispatch({
      type: ADD_APPLICATION_NOTE_ENDED,
    });
    dispatch(setAlert('Application Note added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_APPLICATION_NOTE_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getApplicationNotes =
  ({ application }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_APPLICATION_NOTES_STARTED,
      });

      const { data } = await api.get(
        `/application-notes/application/${application}`
      );

      dispatch({
        type: GET_APPLICATION_NOTES,
        payload: data,
      });
      dispatch({
        type: GET_APPLICATION_NOTES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_APPLICATION_NOTES_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getApplicationNote = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_NOTE_STARTED,
    });
    const { data } = await api.get(`/application-notes/${id}`);

    dispatch({
      type: GET_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getApplicationNoteBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_NOTE_STARTED,
    });
    const { data } = await api.get(`/application-notes/slug/${slug}`);

    dispatch({
      type: GET_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editApplicationNote = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_NOTE_STARTED,
    });
    const { data } = await api.put(`/application-notes/${id}`, formData);
    dispatch({
      type: EDIT_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: EDIT_APPLICATION_NOTE_ENDED,
    });
    dispatch(setAlert('Application Note Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_NOTE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftApplicationNote = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_NOTE_STARTED,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_STARTED,
    });
    const { data } = await api.put(`/application-notes/${id}/draft`);
    dispatch({
      type: EDIT_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_NOTE,
      payload: data,
    });

    dispatch({
      type: EDIT_APPLICATION_NOTE_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_NOTE_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateApplicationNote = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_NOTE_STARTED,
    });
    const { data } = await api.put(`/application-notes/${id}/active`);
    dispatch({
      type: EDIT_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_STARTED,
    });
    dispatch({
      type: EDIT_APPLICATION_NOTE_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_NOTE,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });
    dispatch(setAlert('Application Note Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_NOTE_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_NOTE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteApplicationNote = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/application-notes/${id}`);
    dispatch(setAlert('Application Note Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
