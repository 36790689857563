import {
  GET_PROGRAMS_STARTED,
  GET_PROGRAMS,
  GET_PROGRAMS_ENDED,
  ADD_PROGRAM_STARTED,
  ADD_PROGRAM,
  ADD_PROGRAM_ENDED,
  EDIT_PROGRAM_STARTED,
  EDIT_PROGRAM,
  EDIT_PROGRAM_ENDED,
  GET_PROGRAM,
  GET_PROGRAM_STARTED,
  GET_PROGRAM_ENDED,
} from '../types/program_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addProgram
export const addProgram = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_PROGRAM_STARTED,
    });
    const { data } = await api.post(`/programs/add`, formData);
    dispatch({
      type: ADD_PROGRAM,
      payload: data,
    });
    dispatch({
      type: ADD_PROGRAM_ENDED,
    });
    dispatch(setAlert('Program added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_PROGRAM_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};
//addProgram
export const duplicateProgram =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PROGRAM_STARTED,
      });
      const { data } = await api.post(`/programs/${id}/duplicate`, formData);
      dispatch({
        type: ADD_PROGRAM,
        payload: data,
      });
      dispatch({
        type: ADD_PROGRAM_ENDED,
      });
      dispatch(setAlert('Program added Successfully', 'success'));
    } catch (error) {
      dispatch({
        type: ADD_PROGRAM_ENDED,
      });
      // dispatch(setAlert('Failed ', 'danger'));
      dispatch(handleError(error));
    }
  };

export const getPrograms = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROGRAMS_STARTED,
    });
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/programs?&${query}`);

    dispatch({
      type: GET_PROGRAMS,
      payload: data,
    });
    dispatch({
      type: GET_PROGRAMS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_PROGRAMS_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const filterPrograms =
  ({ value = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_PROGRAMS_STARTED,
      });
      let searchUrl = ``;
      if (value) {
        searchUrl = `/programs?&search[name]=${value}`;
      } else {
        searchUrl = `/programs`;
      }
      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_PROGRAMS,
        payload: data,
      });
      dispatch({
        type: GET_PROGRAMS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_PROGRAMS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const filterProgramsBySchool =
  ({ value = '', school }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_PROGRAMS_STARTED,
      });
      let searchUrl = ``;
      if (value) {
        searchUrl = `/programs?school=${school}&search[name]=${value}`;
      } else {
        searchUrl = `/programs?school=${school}`;
      }
      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_PROGRAMS,
        payload: data,
      });
      dispatch({
        type: GET_PROGRAMS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_PROGRAMS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const getProgram = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROGRAM_STARTED,
    });
    const { data } = await api.get(`/programs/${id}`);

    dispatch({
      type: GET_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_PROGRAM_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getProgramBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROGRAM_STARTED,
    });
    const { data } = await api.get(`/programs/slug/${slug}`);

    dispatch({
      type: GET_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_PROGRAM_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editProgram = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PROGRAM_STARTED,
    });
    const { data } = await api.put(`/programs/${id}`, formData);
    dispatch({
      type: EDIT_PROGRAM,
      payload: data,
    });
    dispatch({
      type: EDIT_PROGRAM_ENDED,
    });
    dispatch(setAlert('Program Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftProgram = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PROGRAM_STARTED,
    });
    dispatch({
      type: GET_PROGRAM_STARTED,
    });
    const { data } = await api.put(`/programs/${id}/draft`);
    dispatch({
      type: EDIT_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_PROGRAM,
      payload: data,
    });

    dispatch({
      type: EDIT_PROGRAM_ENDED,
    });
    dispatch({
      type: GET_PROGRAM_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_PROGRAM_ENDED,
    });
    dispatch({
      type: GET_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateProgram = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PROGRAM_STARTED,
    });
    const { data } = await api.put(`/programs/${id}/active`);
    dispatch({
      type: EDIT_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_PROGRAM_STARTED,
    });
    dispatch({
      type: EDIT_PROGRAM_ENDED,
    });
    dispatch({
      type: GET_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_PROGRAM_ENDED,
    });
    dispatch(setAlert('Program Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_PROGRAM_ENDED,
    });
    dispatch({
      type: GET_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteProgram = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/programs/${id}`);
    dispatch(setAlert('Program Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const deleteBulkProgram = (programs) => async (dispatch) => {
  try {
    const { data } = await api.post(`/programs/bulk-delete`, {
      programs: programs,
    });
    dispatch(setAlert('Program Deleted Successfully', 'success'));
    window.location.reload();
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const bulkPublishProgram = (programs) => async (dispatch) => {
  try {
    const { data } = await api.post(`/programs/bulk-publish`, {
      programs: programs,
    });
    dispatch(setAlert('Program Published Successfully', 'success'));
    window.location.reload();
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const bulkDraftProgram = (programs) => async (dispatch) => {
  try {
    const { data } = await api.post(`/programs/bulk-draft`, {
      programs: programs,
    });
    dispatch(setAlert('Program Draft Successfully', 'success'));
    window.location.reload();
  } catch (error) {
    dispatch(handleError(error));
  }
};
