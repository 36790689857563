import React, { useEffect, useState } from 'react';
import * as qs from 'qs';
import { City, Country, State } from 'country-state-city';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import PermissionComponent from '../auth/PermissionComponent';

export const SchoolFilterComponent = ({ col }) => {
  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const navigate = useNavigate();
  const location = useLocation();
  const [countryCode, setCountryCode] = useState(null);
  const [stateCode, setStateCode] = useState(null);
  const [city, setCity] = useState(null);
  const [name, setName] = useState(
    queryParams && queryParams.name ? queryParams.name : ''
  );
  const [status, setStatus] = useState(
    queryParams && queryParams.status ? queryParams.status : ''
  );

  const handleSidebarChange = ({ name, value }) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    queryParams[name] = value;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    navigate(`${location.pathname}?${query}`);
  };

  const getSidebarValue = (item) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));

    const searchValue = queryParams[item] ? queryParams[item] : '';
    return searchValue;
  };

  useEffect(() => {
    if (window.location.search) {
      // setCountryCode(getSidebarValue('country'));
      const country_name = getSidebarValue('country');
      if (country_name) {
        const all_countries = Country.getAllCountries();
        const filteredData = all_countries.filter(
          (item) => item.name == country_name
        );
        if (filteredData && filteredData.length > 0) {
          setCountryCode(filteredData[0].isoCode);
        }
      }
      const state_name = getSidebarValue('state');
      if (state_name && countryCode) {
        const all_states = State.getStatesOfCountry(countryCode);
        const filteredData = all_states.filter(
          (item) => item.name == state_name
        );
        if (filteredData && filteredData.length > 0) {
          setStateCode(filteredData[0].isoCode);
        }
      }
      const city_name = getSidebarValue('city');
      setCity(city_name);
    }
  }, [window.location.search, countryCode, stateCode]);

  return (
    <div className={col ? `col-lg-${col}` : 'col-lg-2'}>
      <div
        className='card '
        style={{
          boxShadow: 'rgb(227 233 243) 0px 4px 22px',
          position: 'sticky',
          top: '100px',
        }}
      >
        <div
          className='card-header'
          style={{ background: '#14c6ef', color: '#fff' }}
        >
          <h5 className='card-title'> FILTER</h5>
        </div>

        <div className='card-body sidebar-component'>
          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              School Name
            </label>
          </div>
          <div>
            <input
              type='text'
              className='form-control'
              value={name ? name : ''}
              onChange={(e) => {
                setName(e.target.value);
                handleSidebarChange({
                  name: 'name',
                  value: e.target.value,
                });
              }}
            />
          </div>
          <PermissionComponent
            name={'school'}
            permission={'get_all'}
            hideOnAgent={true}
            hideOnStudent={true}
          >
            <div className='topping'>
              <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
                Status
              </label>
            </div>
            <div>
              <select
                name='status'
                id='status'
                className='form-control'
                onChange={(e) => {
                  setStatus(e.target.value);
                  handleSidebarChange({
                    name: 'status',
                    value: e.target.value,
                  });
                }}
                value={status}
              >
                <option value=''></option>
                <option value='Active'> Active </option>
                <option value='Draft'> Draft </option>
              </select>
            </div>
          </PermissionComponent>

          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              Country
            </label>
          </div>
          <div>
            <select
              className='form-control'
              onChange={(e) => {
                setCountryCode(e.target.value);
                handleSidebarChange({
                  name: 'country',
                  value: Country.getCountryByCode(e.target.value).name,
                });
              }}
              value={countryCode ? countryCode : ''}
            >
              <option value=''>-</option>
              {Country.getAllCountries() &&
                Country.getAllCountries().map((e) => {
                  return (
                    <option key={e.isoCode} value={e.isoCode}>
                      {e.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              State
            </label>
          </div>
          <div>
            {countryCode &&
            State.getStatesOfCountry(countryCode) &&
            State.getStatesOfCountry(countryCode).length > 0 ? (
              <>
                <select
                  className='form-control'
                  label='State'
                  name='state'
                  value={stateCode ? stateCode : ''}
                  onChange={(e) => {
                    setStateCode(e.target.value);

                    handleSidebarChange({
                      name: 'state',
                      value: State.getStateByCodeAndCountry(
                        e.target.value,
                        countryCode
                      ).name,
                    });
                  }}
                >
                  <option value=''>-</option>
                  {State.getStatesOfCountry(countryCode).map((e) => {
                    return (
                      <option key={e.name} value={e.isoCode}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <input
                label='Province/ State'
                className='form-control'
                name='state'
                value={stateCode}
              />
            )}
          </div>

          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              City
            </label>
          </div>
          <div>
            {countryCode &&
            stateCode &&
            City.getCitiesOfState(countryCode, stateCode) &&
            City.getCitiesOfState(countryCode, stateCode).length > 0 ? (
              <>
                <select
                  className='form-control'
                  label='City'
                  name='city'
                  onChange={(e) => {
                    setCity(e.target.value);
                    handleSidebarChange({
                      name: 'city',
                      value: e.target.value,
                    });
                  }}
                  value={city}
                >
                  <option value=''></option>
                  {City.getCitiesOfState(countryCode, stateCode).map((e) => {
                    return (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <input
                className='form-control'
                label='City'
                name='city'
                value={city}
              />
            )}
          </div>
        </div>
        <div className='card-footer'>
          <a
            style={{ color: '#14c6ef' }}
            onClick={() => {
              setName('');
              setStatus('');
              setCountryCode('');
              setStateCode('');
              setCity('');

              navigate(`${location.pathname}`);
            }}
          >
            X Clear Filter
          </a>
        </div>
      </div>
    </div>
  );
};
