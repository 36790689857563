export const GET_ACADEMIC_PARTNERS_STARTED = 'GET_ACADEMIC_PARTNERS_STARTED';
export const GET_ACADEMIC_PARTNERS = 'GET_ACADEMIC_PARTNERS';
export const GET_ACADEMIC_PARTNERS_ENDED = 'GET_ACADEMIC_PARTNERS_ENDED';
export const GET_ACADEMIC_PARTNERS_ERROR = 'GET_ACADEMIC_PARTNERS_ERROR';
export const ADD_ACADEMIC_PARTNER_STARTED = 'ADD_ACADEMIC_PARTNER_STARTED';
export const ADD_ACADEMIC_PARTNER = 'ADD_ACADEMIC_PARTNER';
export const ADD_ACADEMIC_PARTNER_ENDED = 'ADD_ACADEMIC_PARTNER_ENDED';
export const ADD_ACADEMIC_PARTNER_ERROR = 'ADD_ACADEMIC_PARTNER_ERROR';
export const EDIT_ACADEMIC_PARTNER_STARTED = 'EDIT_ACADEMIC_PARTNER_STARTED';
export const EDIT_ACADEMIC_PARTNER = 'EDIT_ACADEMIC_PARTNER';
export const EDIT_ACADEMIC_PARTNER_ENDED = 'EDIT_ACADEMIC_PARTNER_ENDED';
export const EDIT_ACADEMIC_PARTNER_ERROR = 'EDIT_ACADEMIC_PARTNER_ERROR';
export const GET_ACADEMIC_PARTNER = 'GET_ACADEMIC_PARTNER';
export const GET_ACADEMIC_PARTNER_STARTED = 'GET_ACADEMIC_PARTNER_STARTED';
export const GET_ACADEMIC_PARTNER_ENDED = 'GET_ACADEMIC_PARTNER_ENDED';
export const GET_ACADEMIC_PARTNER_ERROR = 'GET_ACADEMIC_PARTNER_ERROR';
