import {
  ADD_SCHOOL_ATTENDED_STARTED,
  ADD_SCHOOL_ATTENDED_SUCCESS,
  ADD_SCHOOL_ATTENDED_ENDED,
  ADD_SCHOOL_ATTENDED_ERROR,
} from '../types/school_attended_types';
import { api } from '../../domain/api';

// Register User
export const updateSchoolAttended = (student_id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SCHOOL_ATTENDED_STARTED,
    });
    const res = await api.post(`/school-attended/${student_id}`, data);

    dispatch({
      type: ADD_SCHOOL_ATTENDED_SUCCESS,
      payload: 'School Attended Updated',
    });
    dispatch({
      type: ADD_SCHOOL_ATTENDED_ENDED,
    });
    window.location.reload();
  } catch (err) {
    dispatch({
      type: ADD_SCHOOL_ATTENDED_ENDED,
    });
    dispatch({
      type: ADD_SCHOOL_ATTENDED_ERROR,
      payload: err,
    });
  }
};

export const updateSchoolAttendedStudent =
  (school_id, student_id, data) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_SCHOOL_ATTENDED_STARTED,
      });
      const res = await api.put(
        `/school-attended/student/${student_id}/${school_id}`,
        data
      );

      dispatch({
        type: ADD_SCHOOL_ATTENDED_SUCCESS,
        payload: 'School Attended Updated',
      });
      dispatch({
        type: ADD_SCHOOL_ATTENDED_ENDED,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: ADD_SCHOOL_ATTENDED_ENDED,
      });
      dispatch({
        type: ADD_SCHOOL_ATTENDED_ERROR,
        payload: err,
      });
    }
  };
