import React from 'react';

function AgentStats({ title, number, icon }) {
  return (
    <div className="agent-dashboard">
      <div className="agent-dashboard-flex">
        <div className="agent-dashboard-content">
          <h3>{title}</h3>
          <h4>{number}</h4>
        </div>
        <div className="agent-dashboard-icon">
          <i className={icon}></i>
        </div>
      </div>
    </div>
  );
}

export default AgentStats;
