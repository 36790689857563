import { UserRoles } from './UserRole';

export const STUDENT_PROFILE_MENU = [
  {
    name: 'Home',
    slug: '/dashboard',
    roles: [
      UserRoles.AGENT,
      UserRoles.CRM_USER,
      UserRoles.STUDENT,
      UserRoles.ACADEMIC_PARTNER,
    ],
  },
  {
    name: 'Schools & Programs',
    slug: '/schools',
    roles: [
      UserRoles.AGENT,
      UserRoles.CRM_USER,
      UserRoles.STUDENT,
      UserRoles.COUNSELLOR,
    ],
  },
  {
    name: 'Students',
    slug: '/students',
    roles: [
      UserRoles.AGENT,
      UserRoles.CRM_USER,
      UserRoles.ACADEMIC_PARTNER,
      UserRoles.COUNSELLOR,
    ],
  },
  {
    name: 'Profile',
    slug: '/general-information',
    roles: [UserRoles.STUDENT],
  },

  {
    name: 'My Applications',
    slug: '/my-applications',
    roles: [
      UserRoles.AGENT,
      UserRoles.CRM_USER,
      UserRoles.STUDENT,
      UserRoles.ACADEMIC_PARTNER,
      UserRoles.COUNSELLOR,
    ],
  },
  {
    name: 'Payments',
    slug: '/payments',
    roles: [UserRoles.AGENT, UserRoles.CRM_USER, UserRoles.STUDENT],
  },
  {
    name: 'My Profile',
    slug: '/agent-profile/view',
    roles: [UserRoles.AGENT],
  },
  {
    name: 'Counsellors',
    slug: '/settings/counsellors',
    roles: [UserRoles.AGENT],
  },
];
export const STUDENT_PROFILE_ENUM = {
  HOME: 'Home',
  ProgramSChools: 'Programs & School',
  Schools: 'Schools',
  PROFILE: 'Profile',
  AGENT_PROFILE: 'Agent Profile',
  MY_APPLICATIONS: 'My Applications',
  PAYMENTS: 'Payments',
  STUDENTS: 'Students',
};
