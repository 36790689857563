import React from 'react';
import { Footer, Header } from '../../../components/common';
import {
  Arts,
  Business,
  CentralPlatform,
  DataDriven,
  Economics,
  Engineering,
  EnglishLanguage,
  FindProgram,
  IconFrame,
  Improve,
  Law,
  MainSchool,
  Medicine,
  OneEasy,
  OneLakhImage,
  OneStop,
  Opportunity,
  Science,
} from '../../../components/images';
import Testimonial from '../component/Testimonial';
import { Link } from 'react-router-dom';
import { links } from '../../../domain/links.enum';
import SchoolComponent from '../../../components/school/SchoolComponent';
import SearchProgramAndSchools from '../../search_program_school/SearchProgramAndSchools';

function WebsiteStudents() {
  return (
    <div>
      <Header />
      <section className='ptb-50 mt-5 page-section recruiter-section-website'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='student-website-content'>
                <h2>
                  Discover your ideal study abroad program by creating an
                  account with EdChimp
                </h2>
                <p>
                  EdChimp goes beyond being just a platform. We are your trusted
                  partner, dedicated to supporting your growth and enabling you
                  to excel in your aspirations for international education.
                </p>

                <div className='explore-button'>
                  <Link to={links.students} className=' btn btn-join '>
                    Discover tailored study abroad opportunities!
                  </Link>
                </div>
                <div className='or'>
                  <h3>OR</h3>

                  <h4>Find your ideal program instantly below</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='recruiter-images'>
                <img src={MainSchool} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='searchcox-section'>
        <div className='container'>
          <div className='row'>
            <div className='students-searchbox-website'>
              <input
                type='text'
                className='date-from'
                placeholder='What would you like to study? '
              />
              <input
                type='text'
                className='date-to'
                placeholder='Where would you like to study?'
              />
              <Link to='/login' type='submit' className='button'>
                Search
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <SearchProgramAndSchools
        hideBreadCrumb={true}
        hideFooter={true}
        hideStatus={true}
        hideAction={true}
        hideStartApplication={true}
      />
      <section className='ptb-50 priority'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='student-total-number'>
                <h3>We’ve Helped</h3>
                <img src={OneLakhImage} />
                <h3>Students Find Study Abroad Success</h3>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='student-content-web'>
                <h2>Prioritize your study abroad success.</h2>
                <p>
                  Curious about how to apply to universities and colleges
                  abroad? You can achieve it, and EdChimp makes it effortless.
                  Select from over <strong>thousands </strong>of programs
                  available at more than <strong>1,000</strong> partner
                  institutions worldwide!
                </p>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-md-12'>
              <div className='steps-student'>
                <h4>
                  Allow our team of experts to lead you through the process of
                  studying abroad with just five straight-forward steps:
                </h4>
                <img src={IconFrame} />
                <div className='connect-ad mt-4'>
                  <Link to={links.contactUs} className=' btn btn-join '>
                    Connect with a Student Success Advisor
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ptb-50 commissions'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-10 mx-auto'>
              <div className='section-title text-center mb-70'>
                <h2>
                  Explore Popular Fields of <span> Study</span>
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Engineering} />
                  </div>
                  <div className='c-name'>
                    <h3>Engineering</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Science} />
                  </div>
                  <div className='c-name'>
                    <h3>Sciences</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Arts} />
                  </div>
                  <div className='c-name'>
                    <h3>Arts</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Business} />
                  </div>
                  <div className='c-name'>
                    <h3>Business</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Law} />
                  </div>
                  <div className='c-name'>
                    <h3>Law</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={EnglishLanguage} />
                  </div>
                  <div className='c-name'>
                    <h3>English Language</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Medicine} />
                  </div>
                  <div className='c-name'>
                    <h3>Medicine</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-md-3'>
              <Link to={links.searchProgramAndSchools}>
                <div className='category-flex'>
                  <div className='c-image'>
                    <img src={Economics} />
                  </div>
                  <div className='c-name'>
                    <h3>Economics</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className='freature-section-six mb-50 '>
        <div className='container'>
          <div className='row large-gap mb-30'>
            <div className='col-lg-8 mx-auto'>
              <div
                className='freature-six-left text-center wow fadeInUp delay-0-2s animated'
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className='section-title mb-30'>
                  <h2>
                    Why Choose ? <span>EdChimp</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='row ptb-50'>
            <div className='col-md-12 mx-auto'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='commission-box'>
                    <div className='commission-box-icon'>
                      <img src={Improve} />
                      <h3>Enhance Your Probability of Admission Success</h3>
                      <p>
                        Students who utilize EdChimp experience a 95% acceptance
                        rate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='commission-box'>
                    <div className='commission-box-icon'>
                      <img src={Opportunity} />
                      <h3>Increased Opportunities with Reduced Expenses</h3>
                      <p>
                        You'll save both time and money, spending only half to
                        secure an offer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='commission-box'>
                    <div className='commission-box-icon'>
                      <img src={OneStop} />
                      <h3>Your All-in-One Destination for Studying Abroad</h3>
                      <p>
                        Fast and dependable commissions ensuring you are duly
                        rewarded for your dedicated efforts.
                      </p>
                    </div>
                  </div>
                </div>
                <div class='connect-ad mt-4 text-center'>
                  <Link to={links.ourSolutions} class=' btn btn-join '>
                    Learn More About What We Offer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='freature-section-six mb-50 '>
        <div className='container'>
          <div className='row large-gap mb-30'>
            <div className='col-lg-8 mx-auto'>
              <div
                className='freature-six-left text-center wow fadeInUp delay-0-2s animated'
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className='section-title mb-30'>
                  <h2>
                    A user-friendly platform that links you with the appropriate{' '}
                    {''}
                    <span> school!</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='row ptb-50'>
            <div className='col-md-12 mx-auto'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='easy-platform-icon'>
                    <div className='main-icon'>
                      <img src={FindProgram} />
                    </div>
                    <div className='icon-content-wn'>
                      <h3>
                        Finding programs
                        <br /> more quickly
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='easy-platform-icon'>
                    <div className='main-icon'>
                      <img src={OneEasy} />
                    </div>
                    <div className='icon-content-wn'>
                      <h3>
                        An application that
                        <br /> is easy to use
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='easy-platform-icon'>
                    <div className='main-icon'>
                      <img src={CentralPlatform} />
                    </div>
                    <div className='icon-content-wn'>
                      <h3>
                        Foremost
                        <br /> Platform
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='easy-platform-icon'>
                    <div className='main-icon'>
                      <img src={DataDriven} />
                    </div>
                    <div className='icon-content-wn'>
                      <h3>
                        Data-Directed
                        <br /> Insights
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />

      <Footer />
    </div>
  );
}

export default WebsiteStudents;
