import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import PermissionTableRow from '../../components/RolesAndPermissions/PermissionTableRow';
import SingleRolesAndPermissionsComponent from '../../components/RolesAndPermissions/SingleRolesAndPermissionsComponent';
import { useSinglePermission } from '../../shared/hooks/UsePermissions';

function SingleRolesAndPermissions() {
  const params = useParams();
  const { data } = useSinglePermission(params.id);
  const { permission, permission_loading } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={permission && permission.name} />
      {permission && (
        <section className="profile-main ptb-20">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="main-sec-heading">
                  <h1>{permission.name}</h1>
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="add-student-btn">
                  <Link
                    to={`/settings/permissions/${permission.id}/edit`}
                    className="btn primary-btn-h "
                  >
                    Edit Permissions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {permission && (
        <>
          <section className="main-school-single-view ptb-20 ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <SingleRolesAndPermissionsComponent permission={permission} />
                  {/* <ViewEducationLevelsComponent education_level={education_level} /> */}
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="single-roles-table">
                    <table class="table table-bordered ">
                      <thead>
                        <tr>
                          <th scope="col">Permissions</th>
                          <th className="text-center" scope="col">
                            Create
                          </th>
                          <th className="text-center" scope="col">
                            Get All
                          </th>
                          <th className="text-center" scope="col">
                            View
                          </th>
                          <th className="text-center" scope="col">
                            Delete
                          </th>
                          <th className="text-center" scope="col">
                            Update
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <PermissionTableRow
                          label={'Programs'}
                          name="program"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Schools'}
                          name="school"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Students'}
                          name="student"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Applications'}
                          name="application"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Payments'}
                          name="payment"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Education Levels'}
                          name="education_level"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Agents'}
                          name="agents"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'CRM Users'}
                          name="crm_user"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Roles & Permissions'}
                          name="roles_permission"
                          permission={permission}
                        />
                        <PermissionTableRow
                          label={'Countries'}
                          name="country"
                          permission={permission}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <AuthFooter />
    </div>
  );
}

export default SingleRolesAndPermissions;
