import React from 'react';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import { SchoolDashboard } from '../../components/images';
import DashboardIconBoxComponent from '../../components/dashboard/DashboardIconBoxComponent';
import DashboardAgent from '../DashboardAgent/DashboardAgent';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { UserRoles } from '../../domain/UserRole';
import { useAllDashboard } from '../../shared/hooks/UseDashboard';
import DashboardStudent from './DashboardStudent';

export const DashboardHome = (props) => {
  const userData = useCheckAuthenticated();
  const { user } = userData;
  const { dashboard } = useAllDashboard();
  return (
    <div className='wrapper'>
      <Auth_header_mobile />
      <AuthHeader active={STUDENT_PROFILE_ENUM.HOME} />
      <AuthBreadCrumb title={'Dashboard'} />

      {user.role === UserRoles.CRM_USER && (
        <section className='profile-main wrapper ptb-30'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='main-sec-heading'>
                  <h1>Dashboard</h1>
                </div>
              </div>
            </div>
            <div className='row mt-20'>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-university'}
                  title={'Total Schools'}
                  value={
                    dashboard && dashboard.totalSchools
                      ? dashboard.totalSchools
                      : '-'
                  }
                  link={'/schools'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-tasks'}
                  title={'Total Programs'}
                  value={
                    dashboard && dashboard.totalPrograms
                      ? dashboard.totalPrograms
                      : '-'
                  }
                  link={'/programs'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-graduation-cap'}
                  title={'Total Students'}
                  value={
                    dashboard && dashboard.totalStudents
                      ? dashboard.totalStudents
                      : '-'
                  }
                  link={'/students'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-file-text-o'}
                  title={'Total Applications'}
                  value={
                    dashboard && dashboard.totalApplications
                      ? dashboard.totalApplications
                      : '-'
                  }
                  link={'/my-applications'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-pencil-square-o'}
                  title={'Paid Applications'}
                  value={
                    dashboard && dashboard.totalPaidApplications
                      ? dashboard.totalPaidApplications
                      : '-'
                  }
                  link={'/my-applications?exact[is_application_fee_paid]=true'}
                />
              </div>

              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-thumbs-o-up'}
                  title={'Total Visa Issued Applications'}
                  value={
                    dashboard && dashboard.totalVisaIssuedApplications
                      ? dashboard.totalVisaIssuedApplications
                      : '-'
                  }
                  link={'/my-applications/?exact[is_visa_issued]=true'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-user-o'}
                  title={'Total Agents'}
                  value={
                    dashboard && dashboard.totalAgents
                      ? dashboard.totalAgents
                      : '-'
                  }
                  link={'/settings/agents'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-user-o'}
                  title={'Active Agents'}
                  value={
                    dashboard && dashboard.totalActiveAgents
                      ? dashboard.totalActiveAgents
                      : '-'
                  }
                  link={'/settings/agents?exact[profile_status]=ACTIVE'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-user-o'}
                  title={'Pending Agents'}
                  value={
                    dashboard && dashboard.totalPendingAgents
                      ? dashboard.totalPendingAgents
                      : '-'
                  }
                  link={'/settings/agents?exact[profile_status]=PENDING'}
                />
              </div>
              <div className='col-md-3'>
                <DashboardIconBoxComponent
                  icon={'fa fa-user-o'}
                  title={'Rejected Agents'}
                  value={
                    dashboard && dashboard.totalRejectedAgents
                      ? dashboard.totalRejectedAgents
                      : '-'
                  }
                  link={'/settings/agents?exact[profile_status]=REJECTED'}
                />
              </div>
              {/*  */}
            </div>
          </div>
        </section>
      )}

      {/* Agent Dashboard */}
      {user.role === UserRoles.AGENT && <DashboardAgent user={user} />}
      {user.role === UserRoles.COUNSELLOR && (
        <DashboardAgent user={user} hide={true} />
      )}
      {user.role === UserRoles.ACADEMIC_PARTNER && (
        <DashboardAgent user={user} hide={true} />
      )}
      {user.role === UserRoles.STUDENT && (
        <DashboardStudent user={user} hide={true} />
      )}

      <AuthFooter />
    </div>
  );
};
