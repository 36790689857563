import { combineReducers } from 'redux';
import auth_reducer from './auth_reducer';
import personal_info_reducer from './personal_info_reducer';
import address_info_reducer from './address_info_reducer';
import education_reducer from './education_reducer';
import school_attended_reducer from './school_attended_reducer';
import english_testscore_reducer from './english_testscore_reducer';
import gre_gmat_reducer from './gre_gmat_reducer';
import visa_and_study_permit_reducer from './visa_and_study_permit.reducer';
import alert_reducer from './alert_reducer';
import student_reducer from './student_reducer';
import school_reducer from './school_reducer';
import education_level_reducer from './education_level_reducer';
import agent_reducer from './agent_reducer';
import permissions_reducer from './permissions_reducer';
import crm_user_reducer from './crm_user_reducer';
import academic_partner_reducer from './academic_partner_reducer';
import program_reducer from './program_reducer';
import requirement_reducer from './requirement_reducer';
import country_reducer from './country_reducer';
import application_reducer from './application_reducer';
import application_note_reducer from './application_note_reducer';
import application_requirement_reducer from './application_requirement_reducer';
import grading_reducer from './grading_reducer';
import counsellor_reducer from './counsellor_reducer';
import template_reducer from './template_reducer';
import { notification_reducer } from './notification_reducer';
import discipline_reducer from './discipline_reducer';
import slab_reducer from './slab_reducer';

export default combineReducers({
  auth_reducer: auth_reducer,
  alert_reducer: alert_reducer,
  personal_info: personal_info_reducer,
  address_info: address_info_reducer,
  education: education_reducer,
  school_attended: school_attended_reducer,
  english_testscore: english_testscore_reducer,
  gre_gmat: gre_gmat_reducer,
  visa_and_study_permit: visa_and_study_permit_reducer,
  student: student_reducer,
  school: school_reducer,
  education_level: education_level_reducer,
  agent: agent_reducer,
  permission: permissions_reducer,
  crm_user: crm_user_reducer,
  academic_partner: academic_partner_reducer,
  program: program_reducer,
  requirement: requirement_reducer,
  country: country_reducer,
  application: application_reducer,
  application_note: application_note_reducer,
  application_requirement: application_requirement_reducer,
  grading: grading_reducer,
  counsellor: counsellor_reducer,
  template: template_reducer,
  notification: notification_reducer,
  slab: slab_reducer,
  discipline: discipline_reducer,
});
