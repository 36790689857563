import React from 'react';
import AddAgentComponent from '../../components/agents/AddAgentComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import AddApplicationRequirementsComponent from '../../components/programs/AddApplicationRequirement';
import { useParams } from 'react-router-dom';

function AddApplicationRequirements() {
  const params = useParams();
  return (
    <div className="wrapper">
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Add Application Requirements'} />

      {/* page-section */}

      <section className="  personal-information ptb-20 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <AddApplicationRequirementsComponent
                program={params.program_id}
              />
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default AddApplicationRequirements;
