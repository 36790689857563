import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';
import {
  useSinglePermission,
  useUpdatePermission,
} from '../../shared/hooks/UsePermissions';

import RolesAndPermissionsForm from './RolesAndPermissionsForm';
import PermissionComponent from '../auth/PermissionComponent';

function EditRolesAndPermissionsComponent() {
  const params = useParams();
  const { data } = useSinglePermission(params.id);
  const { permission, permission_loading } = data;
  const { updatePermissionData } = useUpdatePermission();
  const navigate = useNavigate();
  return (
    <div>
      <PermissionComponent name={'roles_permission'} permission={'update'}>
        {permission && (
          <div className='personal-information-box clearfix'>
            <div className='main-sec-heading'>
              <h1>Edit Roles & Permissions</h1>
            </div>

            <Formik
              initialValues={{
                name: permission ? permission.name : '',
                program: permission
                  ? permission.program
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                school: permission
                  ? permission.school
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                student: permission
                  ? permission.student
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                application: permission
                  ? permission.application
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                payment: permission
                  ? permission.payment
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                education_level: permission
                  ? permission.education_level
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                agents: permission
                  ? permission.agents
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                      export: false,
                      assign_tier: false,
                    },
                crm_user: permission
                  ? permission.crm_user
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                roles_permission: permission
                  ? permission.roles_permission
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
                country: permission
                  ? permission.country
                  : {
                      create: false,
                      get_all: false,
                      view: false,
                      delete: false,
                      update: false,
                    },
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                updatePermissionData(permission._id, values);
                navigate('/settings/permissions');
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return <RolesAndPermissionsForm />;
              }}
            </Formik>
          </div>
        )}
      </PermissionComponent>
    </div>
  );
}

export default EditRolesAndPermissionsComponent;
