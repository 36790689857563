import React, { useState, useEffect } from 'react';
import * as qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { City, Country, State } from 'country-state-city';
import { countries } from '../../domain/countries';
import { useAllCrmUsersFilter } from '../../shared/hooks/UseCrmUser';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

function AgentFilter() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(window.location.search.replace('?', ''));

  const [dropdown_options, setDropdown_options] = useState(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [company_name, setCompany_name] = useState(
    queryParams && queryParams.company_name ? queryParams.company_name : ''
  );
  const [countryCode, setCountryCode] = useState(
    queryParams && queryParams.country ? queryParams.country : ''
  );
  const [status, setStatus] = useState(
    queryParams && queryParams.status ? queryParams.status : ''
  );
  const [email, setEmail] = useState(
    queryParams && queryParams.email ? queryParams.email : ''
  );
  const [phone, setPhone] = useState(
    queryParams && queryParams.phone ? queryParams.phone : ''
  );
  const { crm_users_data, setCRMUserSearchTerm } = useAllCrmUsersFilter();
  const { crm_users } = crm_users_data;

  const [crmUser, setCRMUser] = useState(
    queryParams && queryParams.crm_user ? queryParams.crm_user : ''
  );

  useEffect(() => {
    if (crm_users) {
      const mappedSchoolData = crm_users.map((school) => {
        return {
          label: `${school.first_name} ${school.last_name}`,
          value: school._id,
        };
      });
      setDropdown_options({ ...dropdown_options, crm_user: mappedSchoolData });
    }
  }, [crm_users]);

  const handleSidebarChange = ({ name, value }) => {
    queryParams[name] = value;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    navigate(`${location.pathname}?${query}`);
  };

  return (
    <section className='mb-2'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex'>
              <button
                className='filter-btn'
                onClick={() => setOpenFilter(!openFilter)}
              >
                <i className='fa fa-filter'></i> Filter
              </button>

              <button
                className='filter-btn'
                onClick={() => {
                  setCompany_name(null);
                  setCountryCode(null);
                  setStatus(null);
                  setEmail('');
                  setPhone('');
                  setCRMUser(null);
                  navigate(`${location.pathname}`);
                }}
              >
                <i className='fa fa-close'></i> Clear
              </button>
            </div>
          </div>
        </div>
        {openFilter && (
          <div className='row'>
            <div className='col-md-2'>
              <label> Company Name </label>
              <input
                type='text'
                className='form-control'
                onChange={(e) => {
                  setCompany_name(e.target.value);

                  handleSidebarChange({
                    name: 'company_name',
                    value: e.target.value,
                  });
                }}
                value={company_name}
              />
            </div>
            <div className='col-md-2'>
              <label> Country </label>

              <select
                className='form-control'
                onChange={(e) => {
                  setCountryCode(e.target.value);
                  handleSidebarChange({
                    name: 'country',
                    value: e.target.value,
                  });
                }}
                value={countryCode ? countryCode : ''}
              >
                <option value=''>-</option>
                {countries &&
                  countries.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
              </select>
            </div>
            <div className='col-md-2'>
              <label> Status </label>
              <select
                name='status'
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  handleSidebarChange({
                    name: 'status',
                    value: e.target.value,
                  });
                }}
                className='form-control'
              >
                <option value=''></option>
                <option value='PENDING'>PENDING</option>
                <option value='ACTIVE'>ACTIVE</option>
                <option value='REJECTED'>REJECTED</option>
              </select>
            </div>
            <div className='col-md-2'>
              <label> Email </label>
              <input
                type='text'
                className='form-control'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleSidebarChange({
                    name: 'email',
                    value: e.target.value,
                  });
                }}
              />
            </div>
            <div className='col-md-2'>
              <label> Phone </label>
              <input
                type='text'
                className='form-control'
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  handleSidebarChange({
                    name: 'phone',
                    value: e.target.value,
                  });
                }}
              />
            </div>
            <div className='col-md-2'>
              <label> CRM User </label>
              <Select
                value={
                  dropdown_options &&
                  dropdown_options.crm_user &&
                  dropdown_options.crm_user.filter(
                    (option) => option.value === crmUser
                  )
                }
                // defaultValue={school}
                isClearable={false}
                options={dropdown_options && dropdown_options['crm_user']}
                onChange={(e) => {
                  if (e) {
                    setCRMUser(e.value);
                    handleSidebarChange({
                      name: 'crm_user',
                      value: e.value,
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AgentFilter;
