import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addSlab,
  getSlabs,
  getSlab,
  editSlab,
  deleteSlab,
} from '../../store/actions/slab_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreateSlab = () => {
  const dispatch = useDispatch();
  const educationLevelData = useSelector((state) => state.slab);
  const addSlabData = async (data) => {
    dispatch(addSlab(data));
  };
  return { educationLevelData, addSlabData };
};

// Get Single Data
export const useSingleSlab = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.slab);
  useEffect(() => {
    dispatch(getSlab(id));
  }, [id]);
  return { data };
};

//Edit Data
export const useUpdateSlab = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Slab);
  const updateSlabData = async (id, data) => {
    dispatch(editSlab(id, data));
  };
  return { updateSlabData };
};

// Get All Data
export const useAllSlabs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.slab);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getSlabs({}));
    }, 1000),
    []
  );

  return { slabData: data, setPageNumber, data };
};

//Delete Data
export const useDeleteSlab = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Slab);
  const deleteSlabFunction = async (id) => {
    dispatch(deleteSlab(id));
  };
  return { deleteSlabFunction };
};
