export const GET_AGENTS_STARTED = 'GET_AGENTS_STARTED';
export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENTS_ENDED = 'GET_AGENTS_ENDED';
export const GET_AGENTS_ERROR = 'GET_AGENTS_ERROR';
export const ADD_AGENT_STARTED = 'ADD_AGENT_STARTED';
export const ADD_AGENT = 'ADD_AGENT';
export const ADD_AGENT_ENDED = 'ADD_AGENT_ENDED';
export const ADD_AGENT_ERROR = 'ADD_AGENT_ERROR';
export const EDIT_AGENT_STARTED = 'EDIT_AGENT_STARTED';
export const EDIT_AGENT = 'EDIT_AGENT';
export const EDIT_AGENT_ENDED = 'EDIT_AGENT_ENDED';
export const EDIT_AGENT_ERROR = 'EDIT_AGENT_ERROR';
export const GET_AGENT = 'GET_AGENT';
export const GET_AGENT_STARTED = 'GET_AGENT_STARTED';
export const GET_AGENT_ENDED = 'GET_AGENT_ENDED';
export const GET_AGENT_ERROR = 'GET_AGENT_ERROR';

export const GET_CURRENT_AGENT = 'GET_CURRENT_AGENT';
export const GET_CURRENT_AGENT_STARTED = 'GET_CURRENT_AGENT_STARTED';
export const GET_CURRENT_AGENT_ENDED = 'GET_CURRENT_AGENT_ENDED';
export const GET_CURRENT_AGENT_ERROR = 'GET_CURRENT_AGENT_ERROR';
