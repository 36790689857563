import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectBox, HTMLEditor } from '../Form/Form';
import * as Yup from 'yup';
import { useAllCountries } from '../../shared/hooks/UseCountry';
function SlabForm({ formik }) {
  const { country_data } = useAllCountries();
  const { countries } = country_data;
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput label='Name' name='name' />
        </div>
      </div>
      <div className='row'>
        {/* Field array for zone */}
        <FieldArray name='zone'>
          {({ insert, remove, push }) => (
            <>
              {formik.values.zone.length > 0 &&
                formik.values.zone.map((item, index) => {
                  return (
                    <>
                      <div className='col-md-4'>
                        <TextInput
                          label='Commission'
                          name={`zone[${index}].commission`}
                        />
                      </div>
                      <div className='col-md-4'>
                        <SelectBox
                          label='Country'
                          name={`zone[${index}].country`}
                        >
                          <option value=''></option>
                          {countries &&
                            countries.map((country) => {
                              return (
                                <option value={country._id}>
                                  {country.name}
                                </option>
                              );
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-4'>
                        <a onClick={() => remove(index)}>-</a>
                      </div>
                    </>
                  );
                })}
              <div>
                <a
                  className='btn btn-sm btn-primary'
                  onClick={() => push({ country: '', commission: '' })}
                >
                  {' '}
                  +{' '}
                </a>
              </div>
            </>
          )}
        </FieldArray>
      </div>
      <div className='text-center'>
        <button type='submit' className='btn primary-btn '>
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default SlabForm;
