import {
  GET_APPLICATIONS_STARTED,
  GET_APPLICATIONS,
  GET_APPLICATIONS_ENDED,
  ADD_APPLICATION_STARTED,
  ADD_APPLICATION,
  ADD_APPLICATION_ENDED,
  EDIT_APPLICATION_STARTED,
  EDIT_APPLICATION,
  EDIT_APPLICATION_ENDED,
  GET_APPLICATION,
  GET_APPLICATION_STARTED,
  GET_APPLICATION_ENDED,
  ALL_APPLICATION_PAYMENT_STARTED,
  ALL_APPLICATION_PAYMENT,
  ALL_APPLICATION_PAYMENT_ENDED,
} from '../types/application_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';
import { ApplicationStatusEnum } from '../../domain/ApplicationStatusEnum';

//addApplication
export const addApplication = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_APPLICATION_STARTED,
    });
    const { data } = await api.post(`/applications/add`, formData);
    dispatch({
      type: ADD_APPLICATION,
      payload: data,
    });
    dispatch({
      type: ADD_APPLICATION_ENDED,
    });
    dispatch(setAlert('Application added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_APPLICATION_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getApplications =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_APPLICATIONS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/applications?&${query}`);

      dispatch({
        type: GET_APPLICATIONS,
        payload: data,
      });
      dispatch({
        type: GET_APPLICATIONS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_APPLICATIONS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getApplication = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_STARTED,
    });
    const { data } = await api.get(`/applications/${id}`);

    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getApplicationBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_STARTED,
    });
    const { data } = await api.get(`/applications/slug/${slug}`);

    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editApplication = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    const { data } = await api.put(`/applications/${id}`, formData);
    dispatch({
      type: EDIT_APPLICATION,
      payload: data,
    });
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch(setAlert('Application Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const assignUserToApplication = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    const { data } = await api.put(`/applications/assign-user/${id}`, formData);
    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch(setAlert('Application Edited Successfully', 'success'));
    // window.location.reload();
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const changeStatusApplication = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    const { data } = await api.put(
      `/applications/change-status/${id}`,
      formData
    );
    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch(setAlert('Application Edited Successfully', 'success'));
    // window.location.reload();
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const closeApplication = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    const { data } = await api.put(
      `/applications/close-application/${id}`,
      formData
    );
    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch(setAlert('Application Edited Successfully', 'success'));
    // window.location.reload();
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const assignProgramInApplication =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_APPLICATION_STARTED,
      });
      const { data } = await api.put(
        `/applications/assign-program/${id}`,
        formData
      );

      dispatch(setAlert('Application Edited Successfully', 'success'));
      window.location.reload();
    } catch (error) {
      dispatch({
        type: EDIT_APPLICATION_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const changeMarkApplicationAsPaid =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_APPLICATION_STARTED,
      });
      const { data } = await api.put(`/applications/change-status/${id}`, {
        application_status: ApplicationStatusEnum.APPLICATION_FEE_PAID,
      });
      dispatch({
        type: GET_APPLICATION,
        payload: data,
      });
      dispatch({
        type: EDIT_APPLICATION_ENDED,
      });
      dispatch(setAlert('Application Edited Successfully', 'success'));
      window.location.href = `/my-applications/${id}`;
    } catch (error) {
      dispatch({
        type: EDIT_APPLICATION_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const draftApplication = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    dispatch({
      type: GET_APPLICATION_STARTED,
    });
    const { data } = await api.put(`/applications/${id}/draft`);
    dispatch({
      type: EDIT_APPLICATION,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });

    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateApplication = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    const { data } = await api.put(`/applications/${id}/active`);
    dispatch({
      type: EDIT_APPLICATION,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_STARTED,
    });
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch({
      type: GET_APPLICATION,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_ENDED,
    });
    dispatch(setAlert('Application Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteApplication = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/applications/${id}`);
    dispatch(setAlert('Application Deleted Successfully', 'success'));
    window.location.reload();
  } catch (error) {
    dispatch(handleError(error));
  }
};
// Answer Application requirement
export const answerApplicationRequirements =
  (id, formData) => async (dispatch) => {
    try {
      const { data } = await api.post(
        `/applications/answer-requirement/${id}`,
        formData
      );
      dispatch({
        type: GET_APPLICATION,
        payload: data,
      });
      dispatch(
        setAlert('Application Requirement Saved Successfully', 'success')
      );

      // window.location.reload();
    } catch (error) {
      dispatch(handleError(error));
    }
  };

// Answer Application requirement
export const answerApplicationRequirementsChangeStage =
  (id, formData) => async (dispatch) => {
    try {
      const { data } = await api.post(
        `/applications/change-answer-requirement-stage/${id}`,
        formData
      );
      dispatch({
        type: GET_APPLICATION,
        payload: data,
      });
      dispatch(
        setAlert(
          'Application Requirement Status Changed Successfully',
          'success'
        )
      );
      // window.location.reload();
    } catch (error) {
      dispatch(handleError(error));
    }
  };
// All Application Payments
export const allApplicationPayments = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_APPLICATION_PAYMENT_STARTED,
    });
    const { data } = await api.get(`/applications//all-payments`);
    dispatch({
      type: ALL_APPLICATION_PAYMENT,
      payload: data,
    });
    dispatch({
      type: ALL_APPLICATION_PAYMENT_ENDED,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

// 1: Change Amount of commission
export const changeCommissionAmountEdchimp =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_APPLICATION_STARTED,
      });
      const { data } = await api.post(
        `/applications/${id}/change-commission-amount-edchimp`,
        formData
      );

      dispatch(setAlert('Commission Amount Changed', 'success'));
      dispatch({
        type: EDIT_APPLICATION_ENDED,
      });
      window.location.reload();
    } catch (error) {
      dispatch(handleError(error));
    }
  };
export const changeCommissionAmountAgent =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_APPLICATION_STARTED,
      });
      const { data } = await api.post(
        `/applications/${id}/change-commission-amount-agent`,
        formData
      );

      dispatch(setAlert('Commission Amount Changed', 'success'));
      dispatch({
        type: EDIT_APPLICATION_ENDED,
      });
      window.location.reload();
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const markCommissionAsPaidFromCollege =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_APPLICATION_STARTED,
      });
      const { data } = await api.post(
        `/applications/${id}/mark-commission-as-paid-from-college`,
        formData
      );

      dispatch(setAlert('Commission Amount Changed', 'success'));
      dispatch({
        type: EDIT_APPLICATION_ENDED,
      });
      window.location.reload();
    } catch (error) {
      dispatch(handleError(error));
    }
  };
export const markCommissionAsPaidAgent = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_STARTED,
    });
    const { data } = await api.post(
      `/applications/${id}/mark-commission-as-paid-agent`,
      formData
    );

    dispatch(setAlert('Commission Amount Changed', 'success'));
    dispatch({
      type: EDIT_APPLICATION_ENDED,
    });
    window.location.reload();
  } catch (error) {
    dispatch(handleError(error));
  }
};

// 2: Change Receive amount commission
// 3: Mark as paid for agent
