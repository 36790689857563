import React from 'react';

import { countries } from '../../domain/countries';
import { Formik, Form } from 'formik';
import { TextInput, SelectBox, CheckBox } from '../../components/Form/Form';

function CounsellorForm({ formik, dropdown_data, hidePassword }) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput label='First Name' name='first_name' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Last Name' name='last_name' type='text' />
        </div>

        <div className='col-md-6'>
          <TextInput label='Email' name='email' type='email' />
        </div>
        {!hidePassword && (
          <>
            <div className='col-md-6'>
              <TextInput label='Password' name='password' type='password' />
            </div>
            <div className='col-md-6'>
              <TextInput
                label='Confirm Password'
                name='confirm_password'
                type='password'
              />
            </div>
          </>
        )}
      </div>
      <SelectBox
        label='Nationality'
        name='nationality'
        onChange={(e) => {
          formik.setFieldValue('nationality', e.target.value);
          const filteredCountry = countries.filter(
            (item) => item.name === e.target.value
          );
          if (filteredCountry && filteredCountry.length > 0) {
            formik.setFieldValue('country_code', filteredCountry[0].dial_code);
          }
        }}
      >
        <option value=''></option>
        {countries &&
          countries.map((e) => {
            return <option>{e.name}</option>;
          })}
      </SelectBox>
      <div className='row'>
        <div className='col-md-6'>
          <SelectBox label='Country Code' name='country_code'>
            <option value=''></option>
            {countries &&
              countries.map((e) => {
                return (
                  <option value={e.dial_code}>
                    {e.dial_code} {e.name}
                  </option>
                );
              })}
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput label='Phone' name='phone' />
        </div>
        <div className='create-button'>
          <button type='submit' className='btn primary-btn'>
            Save & Continue
          </button>
        </div>
      </div>
    </Form>
  );
}

export default CounsellorForm;
