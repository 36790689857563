import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import { AuthProfileTabs } from '../../components/auth_common/AuthProfileTabs';
import { AddressDetails } from '../../components/general_information/AddressDetails';
import { PersonalInformation } from '../../components/general_information/PersonalInforamtion';
import { VisaAndStudyPermitP } from '../../components/visa_and_study_permit/VisaAndStudyPermitP';
import { PROFILE_TABS_ENUM } from '../../domain/profiletab';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import { useGetStudentProfile } from '../../shared/hooks/UseStudent';

export const SingleStudentVisaInformation = () => {
  const { getStudentInfo, student, student_loading } = useGetStudentProfile();
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      getStudentInfo(params.id);
    }
  }, [params.id]);
  return (
    <>
      <div className='wrapper'>
        <AuthHeader active={STUDENT_PROFILE_ENUM.STUDENTS} />
        <AuthBreadCrumb title={'Students / Visa & Study Permit'} />

        {/* page-section */}
        <section className='profile-main ptb-30'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='main-sec-heading'>
                  <h1>
                    {student && student.student && student.student.first_name}{' '}
                    {student && student.student && student.student.last_name} (
                    {student && student.student && student.student.user_id})
                  </h1>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <AuthProfileTabs
                  active={PROFILE_TABS_ENUM.VISA_AND_STUDY_PERMIT}
                  isStudent={true}
                  id={params.id}
                />
              </div>
            </div>
          </div>
        </section>
        <section className='  personal-information '>
          {student && (
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <VisaAndStudyPermitP
                    student_info={student}
                    student_loading={student_loading}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
        <AuthFooter />
      </div>
    </>
  );
};
