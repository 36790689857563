import React from 'react';
import { Footer, Header } from '../../../components/common';
import {
  AcceptanceRate,
  Ishwar,
  MainRecruiter,
  NishantMaroo,
  SchoolPartnership,
  StudentHelped,
  StudentScholarship,
} from '../../../components/images';
import Testimonial from '../component/Testimonial';
import { links } from '../../../domain/links.enum';
import { Link } from 'react-router-dom';

function OurStory() {
  return (
    <div>
      <Header />
      <section className="sec-pad page-section recruiter-section-website">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="recruiter-content">
                <h2>We're Edchimp !</h2>
                <p>
                  We empower people around the world to study abroad and access
                  the best education.
                </p>
                <div className="explore-button">
                  <Link to={links.registerAsStudent} className=" btn btn-join ">
                    Learn How
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="recruiter-images">
                <img src={MainRecruiter} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-us ptb-30">
        <div className="container">
          <div className="col-lg-8 mx-auto">
            <div
              className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
              style={{ visibility: 'visible', animationName: 'fadeInUp' }}
            >
              <div className="section-title mb-30">
                <h2>
                  Our <span> Story!</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="our-story-content">
                <div className="our-story-flex">
                  <div className="nishant_content">
                    <p>
                      EdChimp - a pioneering platform established in 2023 and
                      powered by EPA Global, is on a mission to empower
                      individuals worldwide to study abroad and access the
                      finest educational opportunities. Led by visionary{' '}
                      <strong>CEO Nishant Maroo</strong>, it has revolutionised
                      the study abroad landscape by simplifying the search,
                      application, and acceptance processes through its
                      comprehensive end-to-end solution.
                    </p>
                  </div>
                  {/* <div className="nishant_image">
                    <img src={NishantMaroo} />
                    <div className="text-center">
                      <h3>Nishant Maroo</h3>
                      <span>CEO, Edchimp</span>
                    </div>
                  </div> */}
                </div>
                <div className="our-story-flex">
                  {/* <div className="nishant_image">
                    <img src={Ishwar} />
                    <div className="text-center">
                      <h3>Ishwar Chaudhary</h3>
                      <span>COO , Edchimp</span>
                    </div>
                  </div> */}
                  <div className="nishant_content">
                    <p>
                      Introducing <strong>Ishwar Chaudhary</strong>, the dynamic
                      COO of EdChimp, powered by EPA Global & NZEC Group. With a
                      passion for educational excellence and a proven track
                      record in strategic leadership, Ishwar drives EdChimp's
                      operational growth with unwavering determination.{' '}
                    </p>
                  </div>
                </div>

                <div className="mt-5">
                  <p>
                    EdChimp – an edtech platform established in 2023 and powered
                    by EPA Global and NZEC Group, is on a mission to empower
                    individuals worldwide to study abroad and access the finest
                    educational opportunities. Led by visionary founders Nishant
                    Maroo and Ishwar Singh, it seeks to revolutionise the study
                    abroad landscape by simplifying the search, application, and
                    acceptance processes through its comprehensive end-to- end
                    solution.
                  </p>
                  <p>
                    With its unwavering commitment to excellence, EdChimp acts
                    as a bridge, connecting international students, recruitment
                    partners, and esteemed academic institutions on a single
                    unified platform. This innovative approach has cemented
                    EdChimp&#39;s reputation as the premier solution provider
                    for aspiring scholars seeking a transformative educational
                    experience.
                  </p>
                  <p>
                    We have established a great network of secondary, and
                    higher-education institutions, spanning prominent academic
                    hubs such as Canada, the United States, the United Kingdom,
                    Australia, New Zealand and Europe. We foster a diverse and
                    inclusive environment through strategic partnerships with
                    esteemed institutions, enriching campuses worldwide.
                  </p>
                  <p>
                    Since its inception, EdChimp strives to be an innovative
                    online platform for international student recruitment,
                    catering to thousands of students&#39; educational needs.
                    With a deep understanding of the challenges faced by
                    aspiring scholars, EdChimp provides invaluable guidance and
                    assistance, ensuring a seamless and rewarding study abroad
                    experience.
                  </p>
                  <p>
                    The growth of EdChimp is attributed to its dedicated and
                    dynamic team, which now comprises talented individuals
                    across the globe. Headquartered in Canberra, Australia,
                    EdChimp strategically recruits in several countries,
                    including India, Philippines, Nepal, Bangladesh, Pakistan,
                    Sri Lanka, Australia, and UAE. This presence enables EdChimp
                    to provide global support, fostering meaningful connections
                    with students and partners worldwide.
                  </p>
                  <p>
                    Our success is underpinned by our unwavering commitment to
                    enhancing accessibility and inclusivity in education. By
                    leveraging cutting-edge technology and data-driven insights,
                    EdChimp empowers students to make informed decisions
                    regarding their academic future. The platform&#39;s
                    user-friendly interface simplifies the study abroad process,
                    enabling students to explore various educational
                    opportunities easily. We collaborate with a vast network of
                    recruitment partners who share a common vision of driving
                    diversity and cultural exchange on campuses. These
                    partnerships amplify the reach of EdChimp &#39;s services
                    and contribute to fostering an inclusive global community of
                    learners.
                  </p>
                  <p>
                    Our transformative impact extends beyond the boundaries of
                    traditional education. By connecting students with
                    prestigious institutions and facilitating cultural exchange,
                    we play a pivotal role in shaping the leaders of tomorrow,
                    fostering a global perspective, and nurturing lifelong
                    connections.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default OurStory;
