import React from 'react';
import { useSingleAgent } from '../../shared/hooks/UseAgent';

export default function AgentName({ agent_id }) {
  console.log('agent_id', agent_id);
  const { data } = useSingleAgent(agent_id);
  const { agent, agent_loading } = data;
  console.log('agent', agent);
  return (
    <div className='col-md-6'>
      <div className='heading-single-education'>
        <h2>Agent</h2>
        {agent && agent.agent && (
          <span>
            {' '}
            {agent.agentInfo && agent.agentInfo && agent.agentInfo.company_name
              ? agent.agentInfo.company_name
              : `${agent.agent.first_name} ${agent.agent.last_name}`}
          </span>
        )}
      </div>
    </div>
  );
}
