import { Formik } from 'formik';
import React, { useState } from 'react';
import SchoolAttendedForm from './SchoolAttendedForm';
import * as Yup from 'yup';
import {
  useUpdateSchoolAttendedInfo,
  useUpdateSchoolAttendedInfoStudent,
} from '../../shared/hooks/UseSchoolAttended';
import SingleProfileComponent from '../cards/SingleProfileComponent';
import moment from 'moment';
import { URI, getDocumentName } from '../../domain/constant';

export const SchoolAttendedCard = ({
  gradings,
  education_levels,
  countries,
  student,
  label,
  school_attended_details,
  hideEdit,
  showTranscript,
  showVerificationLink,
  showCertificate,
}) => {
  const { updateSchoolAttendedInfo } = useUpdateSchoolAttendedInfo();
  const { updateSchoolAttendedInfoStudent } =
    useUpdateSchoolAttendedInfoStudent();
  const [editing, setEditing] = useState(false);

  const levelOfEducationName = ({
    education_levels,
    school_attended_details,
  }) => {
    const filteredEducationLevel = education_levels.filter(
      (item) => item._id === school_attended_details.level_of_education
    );
    if (filteredEducationLevel && filteredEducationLevel.length > 0) {
      return filteredEducationLevel[0].name;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div>
        <div className='personal-information-box clearfix'>
          <div className='d-flex justify-content-between'>
            <div>
              <h3 className='mb-3'> {label} </h3>
            </div>
            {!hideEdit && (
              <div>
                <a
                  className='btn btn-assign'
                  onClick={() => setEditing(!editing)}
                >
                  <i className={editing ? 'fa fa-close' : 'fa fa-pencil'}></i>
                </a>
              </div>
            )}
          </div>
          {editing ? (
            <div className='mt-3'>
              <Formik
                initialValues={{
                  country_of_institution:
                    school_attended_details &&
                    school_attended_details.country_of_institution
                      ? school_attended_details.country_of_institution
                      : '',
                  name_of_institution:
                    school_attended_details &&
                    school_attended_details.name_of_institution
                      ? school_attended_details.name_of_institution
                      : '',
                  level_of_education:
                    school_attended_details &&
                    school_attended_details.level_of_education &&
                    school_attended_details.level_of_education._id
                      ? school_attended_details.level_of_education._id
                      : '',
                  primary_language_of_institution:
                    school_attended_details &&
                    school_attended_details.primary_language_of_institution
                      ? school_attended_details.primary_language_of_institution
                      : '',
                  attended_institution_from:
                    school_attended_details &&
                    school_attended_details.attended_institution_from
                      ? moment(
                          school_attended_details.attended_institution_from
                        ).format('YYYY-MM-DD')
                      : '',
                  attended_institution_to:
                    school_attended_details &&
                    school_attended_details.attended_institution_to
                      ? moment(
                          school_attended_details.attended_institution_to
                        ).format('YYYY-MM-DD')
                      : '',
                  degree_name:
                    school_attended_details &&
                    school_attended_details.degree_name
                      ? school_attended_details.degree_name
                      : '',
                  graduation_date:
                    school_attended_details &&
                    school_attended_details.graduation_date
                      ? school_attended_details.graduation_date
                      : '',
                  have_physical_certificate: false,
                  address:
                    school_attended_details && school_attended_details.address
                      ? school_attended_details.address
                      : '',
                  city:
                    school_attended_details && school_attended_details.city
                      ? school_attended_details.city
                      : '',
                  state:
                    school_attended_details && school_attended_details.state
                      ? school_attended_details.state
                      : '',
                  zipcode:
                    school_attended_details && school_attended_details.zipcode
                      ? school_attended_details.zipcode
                      : '',
                  documents:
                    school_attended_details && school_attended_details.documents
                      ? school_attended_details.documents
                      : '',
                  transcript:
                    school_attended_details &&
                    school_attended_details.transcript
                      ? school_attended_details.transcript
                      : '',
                  certificate:
                    school_attended_details &&
                    school_attended_details.certificate
                      ? school_attended_details.certificate
                      : '',
                  verification_link:
                    school_attended_details &&
                    school_attended_details.verification_link
                      ? school_attended_details.verification_link
                      : '',
                }}
                validationSchema={Yup.object({
                  country_of_institution: Yup.string().required('Required'),
                  name_of_institution: Yup.string().required('Required'),
                  level_of_education: Yup.string().required('Required'),
                  primary_language_of_institution:
                    Yup.string().required('Required'),
                  attended_institution_from: Yup.string().required('Required'),
                  degree_name: Yup.string().required('Required'),
                  // graduation_date: Yup.string().required('Required'),
                  address: Yup.string().required('Required'),
                  city: Yup.string().required('Required'),
                  state: Yup.string().required('Required'),
                  zipcode: Yup.string().required('Required'),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  if (school_attended_details) {
                    updateSchoolAttendedInfoStudent(
                      school_attended_details._id,
                      student._id,
                      values
                    );
                  } else {
                    updateSchoolAttendedInfo(student._id, values);
                  }

                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  return (
                    <SchoolAttendedForm
                      formik={formik}
                      education_levels={education_levels}
                      gradings={gradings}
                      countries={countries}
                      showTranscript={showTranscript}
                      showVerificationLink={showVerificationLink}
                      showCertificate={showCertificate}
                    />
                  );
                }}
              </Formik>
            </div>
          ) : (
            <div>
              <div>
                {school_attended_details ? (
                  <div className='row'>
                    <SingleProfileComponent
                      col={4}
                      label={'Country of Institution'}
                      value={
                        school_attended_details &&
                        school_attended_details.country_of_institution
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Name of Institution'}
                      value={
                        school_attended_details &&
                        school_attended_details.name_of_institution
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Level of Education'}
                      value={
                        school_attended_details &&
                        school_attended_details.level_of_education &&
                        school_attended_details.level_of_education.name
                          ? school_attended_details.level_of_education.name
                          : '-'
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Degree Name'}
                      value={
                        school_attended_details &&
                        school_attended_details.degree_name
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Primary Language of Institution'}
                      value={
                        school_attended_details &&
                        school_attended_details.primary_language_of_institution
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Attended Institution From'}
                      value={
                        school_attended_details.attended_institution_from
                          ? moment(
                              school_attended_details.attended_institution_from
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Attended Institution To'}
                      value={
                        school_attended_details.attended_institution_to
                          ? moment(
                              school_attended_details.attended_institution_to
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'School Address'}
                      value={
                        school_attended_details.address
                          ? school_attended_details.address
                          : ''
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'School City'}
                      value={
                        school_attended_details.city
                          ? school_attended_details.city
                          : ''
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'School State'}
                      value={
                        school_attended_details.state
                          ? school_attended_details.state
                          : ''
                      }
                    />
                    <SingleProfileComponent
                      col={4}
                      label={'Zipcode'}
                      value={
                        school_attended_details.zipcode
                          ? school_attended_details.zipcode
                          : ''
                      }
                    />
                    <div className='col-md-12'>
                      <div> Documents </div>
                      <div>
                        {school_attended_details.documents &&
                          school_attended_details.documents.map((item) => {
                            return (
                              <div>
                                <a href={`${URI}${item}`} target='_blank'>
                                  {getDocumentName(item)}
                                </a>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {showTranscript && (
                      <div className='col-md-4'>
                        <div> Transcript </div>
                        <div>
                          <div>
                            <a
                              href={`${URI}${school_attended_details.transcript}`}
                              target='_blank'
                            >
                              {getDocumentName(
                                school_attended_details.transcript
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {showCertificate && (
                      <div className='col-md-4'>
                        <div> Certificate </div>
                        <div>
                          <div>
                            <a
                              href={`${URI}${school_attended_details.certificate}`}
                              target='_blank'
                            >
                              {getDocumentName(
                                school_attended_details.certificate
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {showVerificationLink && (
                      <div className='col-md-4'>
                        <div> Verification Link </div>
                        <div>
                          <div>
                            <a
                              href={`${school_attended_details.verification_link}`}
                              target='_blank'
                            >
                              {school_attended_details.verification_link}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-md-12'>
                      <p> No Details Available </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
