import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import ViewDisciplineComponent from '../../components/discipline/ViewDisciplineComponent';
import ViewEducationLevelsComponent from '../../components/settings/ViewEducationLevelsComponent';
import { links } from '../../domain/links.enum';
// import { useSingleEducationLevel } from '../../shared/hooks/UseEducationLevel';
import { useSingleDiscipline } from '../../shared/hooks/UseDiscipline';

function ViewDiscipline() {
  const params = useParams();
  const { data } = useSingleDiscipline(params.id);
  const { discipline, discipline_loading } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={discipline && discipline.name} />
      {discipline && (
        <section className='profile-main ptb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-6'>
                <div className='main-sec-heading'>
                  <h1>{discipline.name}</h1>
                </div>
              </div>
              <div className='col-md-6 col-6'>
                <div className='add-student-btn'>
                  <Link
                    to={`/settings/disciplines/${discipline.id}/edit`}
                    className='btn primary-btn-h '
                  >
                    Edit Discipline
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {discipline && (
        <section className='main-school-single-view ptb-20 '>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <ViewDisciplineComponent discipline={discipline} />
              </div>
            </div>
          </div>
        </section>
      )}
      <AuthFooter />
    </div>
  );
}

export default ViewDiscipline;
