import React from 'react';
import ReceiptComponent from '../../components/payment/ReceiptComponent';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';

function Receipt() {
  return (
    <>
      <div>
        <Auth_header_mobile active={STUDENT_PROFILE_ENUM.PAYMENTS} />
        <AuthHeader active={STUDENT_PROFILE_ENUM.PAYMENTS} />
        <AuthBreadCrumb title={'Receipt'} />

        <section className="profile-main ptb-30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="main-sec-heading">
                  <h1>Payment Confirmation</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="students-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <ReceiptComponent />
              </div>
            </div>
          </div>
        </section>
        <AuthFooter />
      </div>
    </>
  );
}

export default Receipt;
