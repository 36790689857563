import React from 'react';

import SingleSchoolCardFilterComponent from './SingleSchoolCardFilterComponent';

function AllSchoolFilterComponent({
  schools_loading,
  schools,
  hideStartApplication,
}) {
  return (
    <>
      <div className='row'>
        {schools &&
          schools.map((school) => {
            return (
              <div className='col-md-12'>
                <SingleSchoolCardFilterComponent
                  school={school}
                  hideStartApplication={hideStartApplication}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}

export default AllSchoolFilterComponent;
