import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import CountryFormComponent from './CountryFormComponent';
import { useCreateCountry } from '../../shared/hooks/UseCountry';
function AddCountryComponent() {
  const { addCountryData } = useCreateCountry();
  const navigate = useNavigate();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Country</h1>
        </div>

        <Formik
          initialValues={{
            name: '',
            currency: '',
            cost_of_living: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            currency: Yup.string().required('Required'),
            cost_of_living: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addCountryData(values);
            navigate('/settings/countries');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <CountryFormComponent formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddCountryComponent;
