import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import Auth_header_mobile from '../auth_common/Auth_header_mobile';
import { AuthHeader } from '../auth_common/AuthHeader';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import { AuthBreadCrumb } from '../auth_common/AuthBreadCrumb';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { AuthFooter } from '../auth_common/AuthFooter';
import { api } from '../../domain/api';
import ViewButton from './ViewButton';
import { useNavigate } from 'react-router-dom';
import ImageComponent from './ImageComponent';
import {
  useAllSchools,
  useGetDropdownOptions,
  useAllSchoolsReport,
} from '../../shared/hooks/UseSchool';
import Pagination from '../common/Pagination';
import PermissionComponent from '../auth/PermissionComponent';
import FilterComponent from '../filter/FilterComponent';
import { SIDEBAR_OPTIONS, inputFields } from '../../shared/enums/schools_enum';
import SidebarFilter from '../common/SidebarFilter';
import { UseDataForExcel, UseFilter } from '../../shared/hooks/UseExcel';
import ExportComponent from '../../components/common/ExportComponent';
import SearchFilterComponent from '../common/SearchFilterComponent';
import { SchoolFilterComponent } from './SchoolFilterComponent';
import { URI } from '../../domain/constant';

function SchoolComponent({
  hideBreadCrumb,
  hideFooter,
  hideStatus,
  hideAction,
}) {
  const { data } = useAllSchools();
  const { schools, school_pages, school_page, total_schools, schools_loading } =
    data;
  const { reportData, getData, loading_report } = useAllSchoolsReport();
  const [showExportButton, setShowExportButton] = useState(false);

  const { dropdownOptions } = useGetDropdownOptions();

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (reportData && reportData.schools) {
      convertToReadable(reportData.schools, inputFields);
    }
  }, [reportData]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Schools', 'schools');
  };

  return (
    <>
      {!hideBreadCrumb && (
        <>
          <Auth_header_mobile />
          <AuthHeader active={STUDENT_PROFILE_ENUM.ProgramSChools} />
          <AuthBreadCrumb title={'Schools'} />
        </>
      )}

      <section className='ptb-20'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='all-school-flex'>
              <div className='school-and-programs '>
                {!hideAction && (
                  <ul>
                    <li to={links.schools}>
                      <Link to={links.schools} className='active-tab'>
                        <i className='fa fa-university'></i>
                        School
                      </Link>
                    </li>
                    <li>
                      <Link to={links.programs}>
                        <i className='fa fa-tasks'></i> Programs
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
              {!hideAction && (
                <div className='export-flex'>
                  <div className='add-student-btn'>
                    <PermissionComponent
                      name={'school'}
                      permission={'create'}
                      hideOnStudent={true}
                      hideOnAgent={true}
                    >
                      <Link to={links.addSchool} className='btn primary-btn-h '>
                        Add School
                      </Link>
                    </PermissionComponent>
                  </div>
                  <div className=''>
                    <PermissionComponent
                      name={'program'}
                      permission={'create'}
                      hideOnStudent={true}
                      hideOnAgent={true}
                    >
                      {showExportButton ? (
                        !loading_report ? (
                          <ExportComponent handleOnExport={handleOnExport} />
                        ) : (
                          <button className='btn btn-generate'>
                            Generating...{' '}
                          </button>
                        )
                      ) : (
                        <button
                          className='btn btn-generate'
                          onClick={() => {
                            setShowExportButton(true);
                            getData();
                          }}
                        >
                          Generate Report
                        </button>
                      )}
                    </PermissionComponent>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <PermissionComponent name={'school'} permission={'get_all'}>
        <section className='students-section'>
          <div className='container-fluid'>
            <div className='row'>
              {/* {SIDEBAR_OPTIONS && (
                <SidebarFilter
                  SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                  dropdown_options={dropdownOptions}
                  // loadOptions={loadOptions}
                />
              )} */}
              <SchoolFilterComponent />

              <div className='col-md-10'>
                <div className='myapplication-table'>
                  <table className='table table-responsive table-sm  table-bordered table-striped  '>
                    <thead>
                      <tr>
                        <th scope='col '>Name</th>
                        <th scope='col '>Logo</th>

                        <th scope='col'>Country</th>
                        <th scope='col'>State</th>
                        <th scope='col'>City</th>
                        {!hideStatus && (
                          <PermissionComponent
                            name={'school'}
                            permission={'get_all'}
                            hideOnAgent={true}
                            hideOnStudent={true}
                          >
                            <th scope='col'>Status</th>
                          </PermissionComponent>
                        )}

                        <th scope='col'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schools &&
                        schools.map((school) => {
                          return (
                            <tr key={school._id}>
                              <td>{school.name}</td>
                              <td>
                                {school.logo ? (
                                  <img
                                    src={`${URI}${school.logo}`}
                                    style={{
                                      width: '150px',
                                      height: '75px',
                                      objectFit: 'contain',
                                    }}
                                  />
                                ) : (
                                  'NA'
                                )}
                              </td>

                              <td> {school.country} </td>
                              <td>{school.state}</td>
                              <td>{school.city}</td>
                              {!hideStatus && (
                                <PermissionComponent
                                  name={'school'}
                                  permission={'get_all'}
                                  hideOnAgent={true}
                                  hideOnStudent={true}
                                >
                                  <td className=''>
                                    {school.status == 'Active' ? (
                                      <Link className='badge badge inclass'>
                                        {school.status}
                                      </Link>
                                    ) : (
                                      <Link className='badge badge-draft'>
                                        {school.status}
                                      </Link>
                                    )}
                                  </td>
                                </PermissionComponent>
                              )}

                              <td className='status-ic'>
                                <Link
                                  to={`/schools/${school._id}`}
                                  target='_blank'
                                  className='badge badge-view'
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      {schools && schools.length == 0 && (
                        <tr>
                          <td colSpan={10}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <p> {total_schools} Records Found </p>
                <Pagination
                  count={total_schools}
                  pages={school_pages}
                  loading={schools_loading}
                />
              </div>
            </div>
          </div>
        </section>
      </PermissionComponent>
      {!hideFooter && <AuthFooter />}
    </>
  );
}

export default SchoolComponent;
