import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addAcademicPartner,
  getAcademicPartners,
  getAcademicPartner,
  editAcademicPartner,
  deleteAcademicPartner,
  updatePasswordAcademicPartner,
  getAcademicPartnersFilter,
} from '../../store/actions/academic_partner_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

// Add Data
export const useCreateAcademicPartner = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.academic_partner);
  const addAcademicPartnerData = async (data) => {
    dispatch(addAcademicPartner(data));
  };
  return { data, addAcademicPartnerData };
};

// Get Single Data
export const useSingleAcademicPartner = (id) => {
  const dispatch = useDispatch();
  const academic_partner_data = useSelector((state) => state.academic_partner);
  useEffect(() => {
    dispatch(getAcademicPartner(id));
  }, [id]);
  return { academic_partner_data };
};

//Edit Data
export const useUpdateAcademicPartner = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const updateAcademicPartnerData = async (id, data) => {
    dispatch(editAcademicPartner(id, data));
  };
  return { updateAcademicPartnerData };
};

//Update CRM USER
export const useChangePasswordAcademicPartner = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const updateAcademicPartnerPasswordData = async (id, data) => {
    dispatch(updatePasswordAcademicPartner(id, data));
  };
  return { updateAcademicPartnerPasswordData };
};

// Get All Data
export const useAllAcademicPartners = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.academic_partner);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getAcademicPartners({}));
    }, 1000),
    []
  );

  return { data, setPageNumber, academic_partner_data: data };
};

// Get All Data Filters
export const useAllAcademicPartnersFilter = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const data = useSelector((state) => state.academic_partner);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      dispatch(getAcademicPartnersFilter({ value: searchTerm }));
    }
  }, [searchTerm]);
  useEffect(() => {
    dispatch(getAcademicPartnersFilter({}));
  }, []);

  return {
    data,
    setPageNumber,
    academic_partners_data: data,
    setCRMUserSearchTerm: setSearchTerm,
  };
};

//Delete Data
export const useDeleteAcademicPartner = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Crm_user);
  const deleteAcademicPartnerFunction = async (id) => {
    dispatch(deleteAcademicPartner(id));
  };
  return { deleteAcademicPartnerFunction };
};

// Get All Data
export const useAllAcademicPartnersFilters = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const data = useSelector((state) => state.academic_partner);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      dispatch(getAcademicPartnersFilter({ value: searchTerm }));
    }
  }, [searchTerm]);
  useEffect(() => {
    dispatch(getAcademicPartnersFilter({}));
  }, []);

  return {
    data: data,
    setPageNumber,
    academic_partners_data: data,
    setAcademicPartnerSearchTerm: setSearchTerm,
  };
};
