import React, { useEffect, useState } from 'react';

import { countries } from '../../domain/countries';
import { Formik, Form, Field, FieldArray } from 'formik';
import {
  TextInput,
  SelectBox,
  CheckBox,
  MultiSelect,
} from '../../components/Form/Form';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';

function GradingForm({ formik, dropdown_data, hidePassword }) {
  const countryOptions = countries.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });
  const { educationLevelData } = useAllEducationLevels();
  const { education_levels } = educationLevelData;
  const [educationLevelsOptions, setEducationLevelsOptions] = useState([]);

  useEffect(() => {
    if (education_levels) {
      const educationLevelsOptionsData = education_levels.map((item) => {
        return {
          label: item.name,
          value: item._id,
        };
      });
      setEducationLevelsOptions(educationLevelsOptionsData);
    }
  }, [education_levels]);

  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput label='Name' name='name' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Rank' name='rank' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Step' name='step' type='text' />
        </div>
        <div className='col-md-6'>
          <SelectBox name='type' label={'Type'}>
            <option value=''></option>
            <option value='letter'>letter</option>
            <option value='number'>number</option>
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput label='Max' name='max' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Min' name='min' type='text' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Step' name='step' type='text' />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <label htmlFor='Country'>Country</label>
          {countryOptions && (
            <Field
              name='countries'
              id='countries'
              placeholder='Countries'
              isMulti={true}
              component={MultiSelect}
              options={countryOptions}
            />
          )}
        </div>
        <div className='col-md-6'>
          <CheckBox label='active' name='active'>
            Active
          </CheckBox>
        </div>
        <div className='col-md-6'>
          <label htmlFor='Country'>Levels</label>
          {educationLevelsOptions && (
            <Field
              name='levels'
              id='levels'
              placeholder='Levels'
              isMulti={true}
              component={MultiSelect}
              options={educationLevelsOptions}
            />
          )}
        </div>
        <div className='col-md-12'>
          <FieldArray
            name='letter_grades'
            render={(arrayHelpers) => (
              <div>
                {formik.values.letter_grades.map((friend, index) => (
                  <div key={index}>
                    {/* <input
                          name={`todos[${index}].name`}
                          value={formik.values.todos[index].name}
                          onChange={formik.handleChange}
                        /> */}
                    <div className='d-flex justify-content-between'>
                      <div className='label-featu'>
                        Letter Grades {index + 1}
                      </div>
                      <div>
                        <a onClick={() => arrayHelpers.remove(index)}>
                          <i className='fa fa-close'></i>
                        </a>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <TextInput
                        label='Name'
                        name={`letter_grades[${index}].name`}
                      />
                    </div>
                    <div className='col-md-12'>
                      <TextInput
                        label='Description'
                        name={`letter_grades[${index}].description`}
                      />
                    </div>
                  </div>
                ))}
                <button
                  type='button'
                  className='btn primary-btn'
                  onClick={() => arrayHelpers.push({ name: '' })}
                >
                  Add Letter Grade
                </button>
              </div>
            )}
          />
        </div>

        <div className='create-button'>
          <button type='submit' className='btn primary-btn'>
            Save & Continue
          </button>
        </div>
      </div>
    </Form>
  );
}

export default GradingForm;
