import React, { useEffect, useState } from 'react';
import { useCurrentAgent } from '../../shared/hooks/UseAgent';
import PermissionComponent from '../auth/PermissionComponent';

export const ProgramCommissionAgent = ({ user, program }) => {
  const { agent_data } = useCurrentAgent();
  const { current_agent } = agent_data;
  const [commission_percentage, setCommission_percentage] = useState(null);
  useEffect(() => {
    if (
      current_agent &&
      current_agent.agentInfo &&
      current_agent.agentInfo.slab &&
      program &&
      program.school &&
      program.school.market
    ) {
      const commission_percentage = current_agent.agentInfo.slab.zone.filter(
        (zone) => zone.country === program.school.market._id
      );
      if (commission_percentage && commission_percentage.length > 0) {
        setCommission_percentage(commission_percentage[0].commission);
      }
    }
  }, [current_agent, program]);

  return (
    <div>
      <PermissionComponent
        name={'program'}
        permission={'update'}
        hideOnStudent={true}
        hideOnAcademicPartner={true}
        hideOnCounsellor={true}
      >
        {program.commission_percentage && commission_percentage ? (
          <>
            {program.commission_type &&
            program.commission_type === 'percentage' ? (
              <>
                <div className='col-md-6'></div>
                <div className='col-md-6'></div>
                <div>
                  <div className='minimum-education-card'>
                    <h5>Commission Agent</h5>
                    <h6>
                      {program.school &&
                        program.school.market &&
                        program.school.market.currency}{' '}
                      {parseFloat(
                        ((program.tuition / 100) *
                          program.commission_percentage *
                          program.commission_year *
                          commission_percentage) /
                          100
                      ).toFixed(2)}
                    </h6>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className='minimum-education-card'>
                  <h5>Commission Fix </h5>
                  <h6>
                    {' '}
                    {parseFloat(
                      (program.commission_percentage *
                        program.commission_year *
                        commission_percentage) /
                        100
                    ).toFixed(2)}
                  </h6>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {program.school && program.school.commission_percentage && (
              <>
                {program.school.commission_type &&
                program.school.commission_type === 'percentage' ? (
                  <>
                    <div className='col-md-6'></div>
                    <div className='col-md-6'></div>
                    <div>
                      <div className='minimum-education-card'>
                        <h5>Commission</h5>
                        <h6>
                          {' '}
                          {parseFloat(
                            ((program.tuition / 100) *
                              program.school.commission_percentage *
                              program.school.commission_year *
                              commission_percentage) /
                              100
                          ).toFixed(2)}{' '}
                        </h6>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className='minimum-education-card'>
                      <h5>Commission Fix </h5>
                      <h6>
                        {' '}
                        {parseFloat(
                          (program.school.commission_percentage *
                            program.school.commission_year *
                            commission_percentage) /
                            100
                        ).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </PermissionComponent>
    </div>
  );
};
