import {
  GET_PROGRAMS_STARTED,
  GET_PROGRAMS,
  GET_PROGRAMS_ENDED,
  ADD_PROGRAM_STARTED,
  ADD_PROGRAM,
  ADD_PROGRAM_ENDED,
  EDIT_PROGRAM_STARTED,
  EDIT_PROGRAM,
  EDIT_PROGRAM_ENDED,
  GET_PROGRAM,
  GET_PROGRAM_STARTED,
  GET_PROGRAM_ENDED,
} from '../types/program_types';

const initialState = {
  programs_loading: true,
  programs: null,
  program_page: null,
  program_pages: null,
  total_programs: 0,

  program: null,
  program_loading: null,
  program_requirements: null,

  add_program_loading: true,
  edit_program_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROGRAMS_STARTED:
      return {
        ...state,
        programs: null,
        program_pages: null,
        program_page: null,
        total_programs: 0,
        programs_loading: true,
      };
    case GET_PROGRAMS:
      return {
        ...state,
        programs: payload.programs,
        program_pages: payload.pages,
        program_page: payload.page,
        total_programs: payload.total_programs,
      };
    case GET_PROGRAMS_ENDED:
      return {
        ...state,
        programs_loading: false,
      };

    case ADD_PROGRAM_STARTED:
      return {
        ...state,
        program_message: null,
        add_program_loading: true,
      };
    case ADD_PROGRAM:
      return {
        ...state,
        program_message: payload,
      };
    case ADD_PROGRAM_ENDED:
      return {
        ...state,
        add_program_loading: false,
      };
    case GET_PROGRAM_STARTED:
      return {
        ...state,
        program: null,
        program_loading: true,
        program_requirements: null,
      };
    case GET_PROGRAM:
      return {
        ...state,
        program: payload.programDetails,
        program_requirements: payload.requirements,
      };
    case GET_PROGRAM_ENDED:
      return {
        ...state,
        program_loading: false,
      };
    case EDIT_PROGRAM_STARTED:
      return {
        ...state,
        program_message: null,
        edit_program_loading: true,
      };
    case EDIT_PROGRAM:
      return {
        ...state,
        program_message: payload,
      };
    case EDIT_PROGRAM_ENDED:
      return {
        ...state,
        edit_program_loading: false,
      };

    default:
      return state;
  }
}
