import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
import AssignAgentModalComponent from './AssignAgentModalComponent';
import { useBulkAssignCRMUser } from '../../shared/hooks/UseAgent';
function CompanyName(props) {
  return (
    <div>
      {props.data.agentData && props.data.agentData.agentInfo
        ? props.data.agentData.agentInfo.company_name
        : 'NA'}
    </div>
  );
}
function CountryName(props) {
  return (
    <div>
      {props.data.agentData && props.data.agentData.agentInfo
        ? props.data.agentData.agentInfo.company_country
        : 'NA'}
    </div>
  );
}
function AgentsComponent({
  agents_loading,
  agents,
  agent_page,
  agent_pages,
  total_agents,
  setPageNumber,
}) {
  const navigate = useNavigate();
  const [columnDefs] = useState([
    { field: 'first_name' },
    { field: 'last_name' },
    { field: 'Company Name', cellRenderer: CompanyName },
    { field: 'Country', cellRenderer: CountryName },
    { field: 'email' },
    { field: 'phone' },

    { field: 'country_code' },
    { field: 'user_id' },
    { field: 'profile_status' },

    {
      field: 'Actions',
      pinned: 'right',
      cellRenderer: ViewButtonByID,
      cellRendererParams: {
        clicked: function (field) {
          navigate(`/settings/agents/${field}/view`);
        },
        edit_clicked: function (field) {
          navigate(`/settings/agents/${field}/edit`);
        },
      },
    },
  ]);

  // Each Column Definition results in one Column.
  const { updateCRMUserBulk } = useBulkAssignCRMUser();

  const [checkedAgents, setCheckedAgents] = useState([]);
  const [checkAssignBulk, setCheckAssignBulk] = useState(false);
  const selectAllAgents = () => {
    if (checkedAgents.length === agents.length) {
      setCheckedAgents([]);
    } else {
      const employeeIDs = agents.map((employee) => employee._id);
      setCheckedAgents(employeeIDs);
    }
  };

  const assignAgentBtnClicked = (value) => {
    console.log(value);
    updateCRMUserBulk({ agents: checkedAgents, crm_user: value.crm_user });
    // deleteBulkData(checkedEmployees);
  };
  return (
    <PermissionComponent name={'agents'} permission={'get_all'}>
      <div>
        <p> {total_agents}Records Found </p>
        {checkedAgents && checkedAgents.length > 0 && (
          <>
            <button
              className='btn btn-danger'
              onClick={() => setCheckAssignBulk(true)}
            >
              Assign User
            </button>
          </>
        )}
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <PermissionComponent
                  name={'agents'}
                  permission={'delete'}
                  hideOnAcademicPartner={true}
                  hideOnAgent={true}
                  hideOnCounsellor={true}
                  hideOnStudent={true}
                >
                  <th scope='col '>
                    {' '}
                    <input type='checkbox' onClick={() => selectAllAgents()} />
                  </th>
                </PermissionComponent>
                <th scope='col '>First Name</th>

                <th scope='col'>Last Name</th>
                <th scope='col'>Company Name</th>
                <th scope='col'>Country</th>
                <th scope='col'>State</th>
                <th scope='col'>City</th>
                <th scope='col'>Country Code</th>
                <th scope='col'>User Id</th>
                <th scope='col'>Assigned User</th>
                <th scope='col'>Profile Status</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {agents &&
                agents.map((agent) => {
                  return (
                    <tr key={agent._id}>
                      <PermissionComponent
                        name={'agents'}
                        permission={'delete'}
                        hideOnAcademicPartner={true}
                        hideOnAgent={true}
                        hideOnCounsellor={true}
                        hideOnStudent={true}
                      >
                        <td>
                          <input
                            type='checkbox'
                            checked={checkedAgents.includes(agent._id)}
                            onClick={(e) => {
                              if (e.target.checked) {
                                setCheckedAgents([...checkedAgents, agent._id]);
                              } else {
                                setCheckedAgents(
                                  checkedAgents.filter((id) => id !== agent._id)
                                );
                              }
                            }}
                          />
                        </td>
                      </PermissionComponent>
                      <td>{agent.first_name}</td>

                      <td> {agent.last_name} </td>
                      <td>
                        {agent.agentData &&
                        agent.agentData.agentInfo &&
                        agent.agentData.agentInfo.company_name
                          ? agent.agentData.agentInfo.company_name
                          : 'NA'}
                      </td>
                      <td>
                        {agent.agentData &&
                        agent.agentData.agentInfo &&
                        agent.agentData.agentInfo.company_country
                          ? agent.agentData.agentInfo.company_country
                          : 'NA'}
                      </td>
                      <td>
                        {agent.agentData &&
                        agent.agentData.agentInfo &&
                        agent.agentData.agentInfo.company_state
                          ? agent.agentData.agentInfo.company_state
                          : 'NA'}
                      </td>
                      <td>
                        {agent.agentData &&
                        agent.agentData.agentInfo &&
                        agent.agentData.agentInfo.company_city
                          ? agent.agentData.agentInfo.company_city
                          : 'NA'}
                      </td>

                      <td> {agent.country_code} </td>
                      <td> {agent.user_id} </td>
                      <td>
                        {' '}
                        {agent.crm_user
                          ? `${agent.crm_user.first_name} ${agent.crm_user.last_name}`
                          : 'NA'}{' '}
                      </td>
                      <td>
                        {' '}
                        {agent.profile_status == 'PENDING' ? (
                          <span className='badge badge-draft'>PENDING</span>
                        ) : agent.profile_status == 'REJECTED' ? (
                          <span className='badge badge-delete'>REJECTED</span>
                        ) : (
                          <span className='badge badge inclass'>ACTIVE</span>
                        )}
                      </td>
                      <td className='status-ic'>
                        <PermissionComponent
                          name={'agents'}
                          permission={'view'}
                        >
                          <Link
                            to={`/settings/agents/${agent._id}/view`}
                            className='badge badge-view'
                          >
                            View
                          </Link>
                        </PermissionComponent>
                        <PermissionComponent
                          name={'agents'}
                          permission={'update'}
                        >
                          <Link
                            to={`/settings/agents/${agent._id}/edit`}
                            className='badge badge-edit'
                          >
                            Edit
                          </Link>
                        </PermissionComponent>
                      </td>
                    </tr>
                  );
                })}
              {agents && agents.length == 0 && (
                <tr>
                  <td colSpan={10}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={agents}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={agents}
          count={total_agents}
          pages={agent_pages}
          loading={agents_loading}
        />
      </div>
      {checkAssignBulk && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <h4 className='modal-title w-100'>Assign User</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setCheckAssignBulk(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>please select the user to assign</p>
                  <AssignAgentModalComponent
                    handleSubmitForm={assignAgentBtnClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PermissionComponent>
  );
}

export default AgentsComponent;
