export const GET_STUDENTS_STARTED = 'GET_STUDENTS_STARTED';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENTS_ENDED = 'GET_STUDENTS_ENDED';
export const GET_STUDENTS_ERROR = 'GET_STUDENTS_ERROR';
export const ADD_STUDENT_STARTED = 'ADD_STUDENT_STARTED';
export const ADD_STUDENT = 'ADD_STUDENT';
export const ADD_STUDENT_ENDED = 'ADD_STUDENT_ENDED';
export const ADD_STUDENT_ERROR = 'ADD_STUDENT_ERROR';
export const EDIT_STUDENT_STARTED = 'EDIT_STUDENT_STARTED';
export const EDIT_STUDENT = 'EDIT_STUDENT';
export const EDIT_STUDENT_ENDED = 'EDIT_STUDENT_ENDED';
export const EDIT_STUDENT_ERROR = 'EDIT_STUDENT_ERROR';
export const GET_STUDENT = 'GET_STUDENT';
export const GET_STUDENT_STARTED = 'GET_STUDENT_STARTED';
export const GET_STUDENT_ENDED = 'GET_STUDENT_ENDED';
export const GET_STUDENT_ERROR = 'GET_STUDENT_ERROR';
