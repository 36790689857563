import { Field, Form } from 'formik';
import React from 'react';
import SelectField, {
  CheckBox,
  FileUpload,
  MultiSelect,
  ReactSelect,
  SelectBox,
  SelectComponent,
  TextInput,
} from '../Form/Form';
import CountryStateCity from '../common/CountryStateCity';

function AgentProfileForm({ formik, countries }) {
  const handleChangeCheckbox = (checked, value) => {
    if (checked && formik.values.services) {
      const filterData = formik.values.services.filter(
        (item) => item === value
      );
      if (filterData && filterData.length > 0) {
      } else {
        const services_list = formik.values.services;
        services_list.push(value);
        formik.setFieldValue('services', services_list);
      }
    } else {
      const filterData = formik.values.services.filter(
        (item) => item !== value
      );
      formik.setFieldValue('services', filterData);
    }
  };

  const checkIfServiceSelected = (value) => {
    const filterData = formik.values.services.filter((item) => item === value);
    if (filterData && filterData.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const countryOptions = countries.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  return (
    <div>
      <label>Business Certificate</label>
      <div className='drop_box'>
        <FileUpload
          item='business_certificate'
          formik={formik}
          acceptFile={'.pdf'}
          value={formik.values.business_certificate}
          hideView={true}
        />
        <span>* Upload PDF only. (Max 5MB size) </span>
      </div>
      <label>Business Logo (optional)</label>
      <div className='drop_box'>
        <FileUpload
          item='business_logo'
          acceptFile={'image/*'}
          formik={formik}
          value={formik.values.business_logo}
        />
        <span>* Upload JPG/PNG images only (Max 2MB size) </span>
      </div>

      <TextInput label='Agency Name' name='company_name' />
      {/* <TextInput label="Owner's Last Name" name='owner_last_name' /> */}
      {/* <TextInput label='Name of Business' name='company_name' /> */}
      <TextInput label='Business Address' name='company_address' />
      <SelectBox name='company_country' label={'Company Country'}>
        <option value=''> </option>
        {countries &&
          countries.map((item) => {
            return <option value={item.name}> {item.name} </option>;
          })}
      </SelectBox>

      <TextInput label='Agency State' name='company_state' />
      <TextInput label='Agency City' name='company_city' />
      <TextInput label='Agency Zipcode' name='company_zipcode' />

      <SelectBox
        label='Preferred Contact Method'
        name='preferred_contact_method'
      >
        <option value=''> Select</option>
        <option value='Call/ SMS/ Email/ WhatsApp'>
          Call/ SMS/ Email/ WhatsApp{' '}
        </option>
        <option value='Skype'>Skype</option>
        <option value='WeChat'>WeChat</option>
        <option value='Telegram'>Telegram</option>
      </SelectBox>
      <SelectBox
        label='How did you find out about EdChimp?'
        name='source_to_know_about_edchimp'
      >
        <option value=''> Select</option>
        <option value='Social Media Platform'>Social Media Platform</option>
        <option value='LinkedIn'>LinkedIn</option>
        <option value='Word of Mouth'>Word of Mouth</option>
        <option value='Through a Student'>Through a Student</option>
        <option value='EdChimp Approached Me'>EdChimp Approached Me</option>
      </SelectBox>
      <TextInput
        label='  Has anyone at EdChimp referred you ? If yes, who?
              (optional)'
        name='referred_by_edchimp'
      />

      <TextInput
        label=' In which year did you begin recruiting students?'
        name='year_of_recruiting_started'
      />

      <label htmlFor=''>which countries do you recruit from?</label>
      <Field
        name='main_source_country'
        id='main_source_country'
        placeholder='which countries do you recruit from?
'
        isMulti={true}
        component={MultiSelect}
        options={countryOptions}
      />

      <div className='row'>
        <div className='col-md-12'>
          <label htmlFor=''>
            What services do you provide to your clients?
          </label>
          <CheckBox
            name='services_ielts'
            onChange={(e) =>
              handleChangeCheckbox(e.target.checked, 'IELTS/ TOEFL Coaching')
            }
            checked={checkIfServiceSelected('IELTS/ TOEFL Coaching')}
          >
            IELTS/ TOEFL Coaching
          </CheckBox>
          <CheckBox
            name='services_visa'
            onChange={(e) =>
              handleChangeCheckbox(e.target.checked, 'Visa Application')
            }
            checked={checkIfServiceSelected('Visa Application')}
          >
            Visa Application
          </CheckBox>
          <CheckBox
            name='services_sop'
            onChange={(e) =>
              handleChangeCheckbox(
                e.target.checked,
                'SoP and Interview Preparation'
              )
            }
            checked={checkIfServiceSelected('SoP and Interview Preparation')}
          >
            SoP and Interview Preparation
          </CheckBox>
          <CheckBox
            name='services_pre'
            onChange={(e) =>
              handleChangeCheckbox(
                e.target.checked,
                'Pre and Post Landing Services'
              )
            }
            checked={checkIfServiceSelected('Pre and Post Landing Services')}
          >
            Pre and Post Landing Services
          </CheckBox>
        </div>
      </div>
    </div>
  );
}

export default AgentProfileForm;
