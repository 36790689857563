import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';
import './assets/css/animate.min.css';

import 'react-tooltip/dist/react-tooltip.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './assets/css/wysiwyg.css';
import 'react-loading-skeleton/dist/skeleton.css';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { Register } from './containers/auth/Register';
import { RegisterAsAgent } from './containers/auth/RegisterAsAgent';
import { RegisterAsStudent } from './containers/auth/RegisterAsStudent';
import { Login } from './containers/auth/Login';
import { links } from './domain/links.enum';
import { ForgotPassword } from './containers/auth/ForgotPassword';
import { RecoveryEmailed } from './containers/auth/RecoveryEmailed';
import { ResetPassword } from './containers/auth/ResetPassword';
import { VerifyEmail } from './containers/auth/VerifyEmail';
import { GeneralInformation } from './containers/profile/general_info/GeneralInformation';
import { EducationHistory } from './containers/profile/education_history/EducationHistory';
import { TestScore } from './containers/profile/test_score/TestScore';
import { VisaAndStudyPermit } from './containers/profile/visa-and-study-permit/VisaAndStudyPermit';
import setAuthToken from './domain/setAuthToken';
import { lazy, useEffect } from 'react';
import { currentUser } from './store/actions/auth_action';
import ProtectedRoute from './shared/routes/PrivateRoutes';
import BeforeLoginRoute from './shared/routes/BeforeLoginRoutes';
import { DashboardHome } from './containers/dashboard/DashboadHome';
import { Students } from './containers/students/Students';
import { SingleStudent } from './containers/students/SingleStudent';
import AddStudent from './containers/students/AddStudent';
import AddSchool from './containers/school/AddSchool';
import SingleSchool from './containers/school/SingleSchool';
import Schools from './containers/school/Schools';
import Programs from './containers/programs/Programs';
import AddProgram from './containers/programs/AddProgram';
import SingleProgram from './containers/programs/SingleProgram';
import EditSchool from './containers/school/EditSchool';
import EditProgram from './containers/programs/EditProgram';
import EducationLevels from './containers/settings/EducationLevels';
import AddEducationLevel from './containers/settings/AddEducationLevel';
import EditEducationLevel from './containers/settings/EditEducationLevel';
import ViewEducationLevel from './containers/settings/ViewEducationLevel';
import Settings from './containers/settings/Settings';
import Agents from './containers/agents/Agents';
import AddAgent from './containers/agents/AddAgent';
import EditAgent from './containers/agents/EditAgent';
import ViewAgent from './containers/agents/ViewAgent';
import { SingleStudentEducationHistory } from './containers/students/SingleStudentEducationHistory';
import { SingleStudentTestScores } from './containers/students/SingleStudentTestScores';
import { SingleStudentVisaInformation } from './containers/students/SingleStudentVisaInformation';
import AllRolesAndPermissions from './containers/RolesAndPermission/AllRolesAndPermissions';
import AddRolesAndPermissions from './containers/RolesAndPermission/AddRolesAndPermissions';
import EditRolesAndPermissions from './containers/RolesAndPermission/EditRolesAndPermissions';
import SingleRolesAndPermissionsComponent from './components/RolesAndPermissions/SingleRolesAndPermissionsComponent';
import SingleRolesAndPermissions from './containers/RolesAndPermission/SingleRolesAndPermissions';
import CrmUsers from './containers/crmUser/CrmUsers';
import AddCrmUser from './containers/crmUser/AddCrmUser';
import EditCrmUser from './containers/crmUser/EditCrmUser';
import ViewCrmUser from './containers/crmUser/ViewCrmUser';

import AcademicPartners from './containers/academicPartner/AcademicPartners';
import AddAcademicPartner from './containers/academicPartner/AddAcademicPartner';
import EditAcademicPartner from './containers/academicPartner/EditAcademicPartner';
import ViewAcademicPartner from './containers/academicPartner/ViewAcademicPartner';

import AddRequirements from './containers/requirement/AddRequirements';
import EditRequirements from './containers/requirement/EditRequirement';
import Countries from './containers/country/Countries';
import AddCountry from './containers/country/AddCountry';
import ViewCountry from './containers/country/ViewCountry';
import EditCountry from './containers/country/EditCountry';
import AgentProfile from './containers/agents/AgentProfile';
import SearchProgramAndSchools from './containers/search_program_school/SearchProgramAndSchools';
import MyApplications from './containers/my_applications/MyApplications';
import SingleApplication from './containers/my_applications/SingleApplication';
import AddApplicationRequirements from './containers/programs/AddApplicationRequirements';
import Payments from './containers/payment/Payments';
import Receipt from './containers/payment/Receipt';
import ViewAgentProfile from './containers/agents/ViewAgentProfile';
import DuplicateSchool from './containers/school/DuplicateSchool';
import DuplicateProgram from './containers/programs/DuplicateProgram';
import Payout from './containers/agents/Payout';
import Gradings from './containers/gradings/Gradings';
import AddGrading from './containers/gradings/AddGrading';
import EditGrading from './containers/gradings/EditGrading';
import ViewGrading from './containers/gradings/ViewGrading';

import Recruiters from './containers/website/containers/Recruiters';
import WebsiteStudents from './containers/website/containers/WebsiteStudents';
import SchoolWebsite from './containers/website/containers/SchoolWebsite';
import TermsAndConditions from './containers/website/containers/TermsAndConditions';
import PrivacyPolicy from './containers/website/containers/PrivacyPolicy';
import OurStory from './containers/website/containers/OurStory';
import ContactUs from './containers/website/containers/ContactUs';
import OurSolutions from './containers/website/containers/OurSolutions';
import SingleBlog from './containers/website/containers/EmigrationConsultantBlog';
import AustralianSpouseBlog from './containers/website/containers/AustralianSpouseBlog';
import ReasonBlog from './containers/website/containers/ReasonBlog';
// import Careers from './containers/website/containers/Careers';
import Counsellors from './containers/counsellors/Counsellors';
import AddCounsellor from './containers/counsellors/AddCounsellor';
import EditCounsellor from './containers/counsellors/EditCounsellor';
import ViewCounsellor from './containers/counsellors/ViewCounsellor';
import Blog from './containers/website/containers/Blog';
import EditApplicationRequirements from './containers/programs/EditApplicationRequirements';
import Templates from './containers/templates/Templates';
import AddTemplate from './containers/templates/AddTemplate';
import ViewTemplate from './containers/templates/ViewTemplate';
import EditTemplate from './containers/templates/EditTemplate';
import AddSingleApplicationRequirements from './containers/my_applications/AddSingleApplicationRequirement';
import CardForm from './containers/payment/PayNow';
import { PaymentSuccess } from './containers/my_applications/PaymentSuccess';
import Notifications from './components/auth_common/Notifications';
import Slabs from './containers/slabs/Slabs';
import AddSlab from './containers/slabs/AddSlab';
import EditSlab from './containers/slabs/EditSlab';
import ViewSlab from './containers/slabs/ViewSlab';
import { AddBulkPrograms } from './containers/programs/AddBulkPrograms';
import Disciplines from './containers/discipline/Disciplines';
import AddDiscipline from './containers/discipline/AddDiscipline';
import ViewDiscipline from './containers/discipline/ViewDiscipline';
import EditDiscipline from './containers/discipline/EditDiscipline';
import { Suspense } from 'react';
import WebsiteSingleSchool from './containers/website/containers/WebsiteSingleSchool';
import WebsiteSingleProgram from './containers/website/containers/WebsiteSingleProgram';

const Careers = lazy(() => import('./containers/website/containers/Careers'));
const Home = lazy(() => import('./containers/home/Home'));

function App() {
  useEffect(() => {
    const token = localStorage.getItem('token');

    setAuthToken(token);
    store.dispatch(currentUser());
  }, []);

  return (
    <Provider store={store}>
      <Suspense fallback={<div> Loading... </div>}>
        <Routes>
          <Route path={links.home} element={<Home />} />
          <Route path={links.register} element={<Register />} />
          <Route
            path={links.registerAsAgent}
            element={
              <BeforeLoginRoute>
                <RegisterAsAgent />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.registerAsStudent}
            element={
              <BeforeLoginRoute>
                <RegisterAsStudent />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.login}
            element={
              <BeforeLoginRoute>
                <Login />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.forgotPassword}
            element={
              <BeforeLoginRoute>
                <ForgotPassword />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.recoveryEmailed}
            element={
              <BeforeLoginRoute>
                <RecoveryEmailed />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.resetPassword}
            element={
              <BeforeLoginRoute>
                <ResetPassword />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.verifyEmail}
            element={
              <BeforeLoginRoute>
                <VerifyEmail />
              </BeforeLoginRoute>
            }
          />
          <Route
            path={links.dashBoardHome}
            element={
              <ProtectedRoute>
                <DashboardHome />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.notifications}
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          {/* profile */}
          <Route
            path={links.generalInformation}
            element={
              <ProtectedRoute>
                <GeneralInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.educationHistory}
            element={
              <ProtectedRoute>
                <EducationHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.testScore}
            element={
              <ProtectedRoute>
                <TestScore />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.visaAndStudyPermit}
            element={
              <ProtectedRoute>
                <VisaAndStudyPermit />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.students}
            element={
              <ProtectedRoute>
                <Students />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudent}
            element={
              <ProtectedRoute>
                <SingleStudent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudentEducationHistory}
            element={
              <ProtectedRoute>
                <SingleStudentEducationHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudentTestScore}
            element={
              <ProtectedRoute>
                <SingleStudentTestScores />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.singleStudentVisaInfo}
            element={
              <ProtectedRoute>
                <SingleStudentVisaInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.SingleSchool}
            element={
              <ProtectedRoute>
                <SingleSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.WebsiteSingleSchool}
            element={<WebsiteSingleSchool />}
          />
          <Route
            path={links.SingleProgram}
            element={
              <ProtectedRoute>
                <SingleProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.WebsiteSingleProgram}
            element={<WebsiteSingleProgram />}
          />
          <Route
            path={links.SingleProgramID}
            element={
              <ProtectedRoute>
                <SingleProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addStudent}
            element={
              <ProtectedRoute>
                <AddStudent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.schools}
            element={
              <ProtectedRoute>
                <Schools />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addSchool}
            element={
              <ProtectedRoute>
                <AddSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.duplicateSchool}
            element={
              <ProtectedRoute>
                <DuplicateSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.educationLevel}
            element={
              <ProtectedRoute>
                <EducationLevels />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addEducationLevel}
            element={
              <ProtectedRoute>
                <AddEducationLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editEducationLevel}
            element={
              <ProtectedRoute>
                <EditEducationLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.ViewEducationLevel}
            element={
              <ProtectedRoute>
                <ViewEducationLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.slabs}
            element={
              <ProtectedRoute>
                <Slabs />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addSlab}
            element={
              <ProtectedRoute>
                <AddSlab />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editSlab}
            element={
              <ProtectedRoute>
                <EditSlab />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.ViewSlab}
            element={
              <ProtectedRoute>
                <ViewSlab />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.gradingScheme}
            element={
              <ProtectedRoute>
                <Gradings />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addGradingScheme}
            element={
              <ProtectedRoute>
                <AddGrading />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editGradingScheme}
            element={
              <ProtectedRoute>
                <EditGrading />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.ViewGradingScheme}
            element={
              <ProtectedRoute>
                <ViewGrading />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.settings}
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.agents}
            element={
              <ProtectedRoute>
                <Agents />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.agentProfile}
            element={
              <ProtectedRoute>
                <AgentProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewAgentProfile}
            element={
              <ProtectedRoute>
                <ViewAgentProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addAgent}
            element={
              <ProtectedRoute>
                <AddAgent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editAgent}
            element={
              <ProtectedRoute>
                <EditAgent />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewAgent}
            element={
              <ProtectedRoute>
                <ViewAgent />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.rolesAndPermissions}
            element={
              <ProtectedRoute>
                <AllRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addRolesAndPermissions}
            element={
              <ProtectedRoute>
                <AddRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editRolesAndPermissions}
            element={
              <ProtectedRoute>
                <EditRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewRolesAndPermissions}
            element={
              <ProtectedRoute>
                <SingleRolesAndPermissions />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.crmUsers}
            element={
              <ProtectedRoute>
                <CrmUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addCrmUser}
            element={
              <ProtectedRoute>
                <AddCrmUser />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editCrmUser}
            element={
              <ProtectedRoute>
                <EditCrmUser />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewCrmUser}
            element={
              <ProtectedRoute>
                <ViewCrmUser />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.academicPartners}
            element={
              <ProtectedRoute>
                <AcademicPartners />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addAcademicPartner}
            element={
              <ProtectedRoute>
                <AddAcademicPartner />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editAcademicPartner}
            element={
              <ProtectedRoute>
                <EditAcademicPartner />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewAcademicPartner}
            element={
              <ProtectedRoute>
                <ViewAcademicPartner />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.Counsellors}
            element={
              <ProtectedRoute>
                <Counsellors />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addCounsellor}
            element={
              <ProtectedRoute>
                <AddCounsellor />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editCounsellor}
            element={
              <ProtectedRoute>
                <EditCounsellor />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewCounsellor}
            element={
              <ProtectedRoute>
                <ViewCounsellor />
              </ProtectedRoute>
            }
          />

          <Route
            path={links.addRequirement}
            element={
              <ProtectedRoute>
                <AddRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editRequirement}
            element={
              <ProtectedRoute>
                <EditRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.programs}
            element={
              <ProtectedRoute>
                <Programs />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.BulkUploadPrograms}
            element={
              <ProtectedRoute>
                <AddBulkPrograms />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addProgram}
            element={
              <ProtectedRoute>
                <AddProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.duplicateProgram}
            element={
              <ProtectedRoute>
                <DuplicateProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editProgram}
            element={
              <ProtectedRoute>
                <EditProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editSchool}
            element={
              <ProtectedRoute>
                <EditSchool />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.country}
            element={
              <ProtectedRoute>
                <Countries />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addCountry}
            element={
              <ProtectedRoute>
                <AddCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewCountry}
            element={
              <ProtectedRoute>
                <ViewCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editCountry}
            element={
              <ProtectedRoute>
                <EditCountry />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.discipline}
            element={
              <ProtectedRoute>
                <Disciplines />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addDiscipline}
            element={
              <ProtectedRoute>
                <AddDiscipline />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewDiscipline}
            element={
              <ProtectedRoute>
                <ViewDiscipline />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editDiscipline}
            element={
              <ProtectedRoute>
                <EditDiscipline />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.templates}
            element={
              <ProtectedRoute>
                <Templates />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addTemplate}
            element={
              <ProtectedRoute>
                <AddTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewTemplate}
            element={
              <ProtectedRoute>
                <ViewTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editTemplate}
            element={
              <ProtectedRoute>
                <EditTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.searchProgramAndSchools}
            element={
              <ProtectedRoute>
                <SearchProgramAndSchools />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.searchProgramAndSchoolsByStudent}
            element={
              <ProtectedRoute>
                <SearchProgramAndSchools />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.myApplications}
            element={
              <ProtectedRoute>
                <MyApplications />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.viewApplication}
            element={
              <ProtectedRoute>
                <SingleApplication />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.paymentSuccessful}
            element={
              <ProtectedRoute>
                <PaymentSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addApplicationRequirements}
            element={
              <ProtectedRoute>
                <AddSingleApplicationRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.payments}
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.receipt}
            element={
              <ProtectedRoute>
                <Receipt />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.agentPayout}
            element={
              <ProtectedRoute>
                <Payout />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.addApplicationRequirement}
            element={
              <ProtectedRoute>
                <AddApplicationRequirements />
              </ProtectedRoute>
            }
          />
          <Route
            path={links.editApplicationRequirement}
            element={
              <ProtectedRoute>
                <EditApplicationRequirements />
              </ProtectedRoute>
            }
          />
          {/* webstie Routes */}
          <Route path={links.recruiters} element={<Recruiters />} />
          <Route path={links.schoolWebsite} element={<SchoolWebsite />} />
          <Route path={links.websiteStudent} element={<WebsiteStudents />} />
          <Route
            path={links.termsAndConditions}
            element={<TermsAndConditions />}
          />
          <Route path={links.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={links.about} element={<OurStory />} />
          <Route path={links.contactUs} element={<ContactUs />} />
          <Route path={links.ourSolutions} element={<OurSolutions />} />
          <Route path={links.singleBlog} element={<SingleBlog />} />
          <Route
            path={links.australianSpouse}
            element={<AustralianSpouseBlog />}
          />
          <Route path={links.reason} element={<ReasonBlog />} />

          <Route path={links.careers} element={<Careers />} />
          <Route path={links.blogs} element={<Blog />} />
          <Route path={'/payments/test'} element={<CardForm />} />
          {/* <Route component={PageNotFound} /> */}
        </Routes>
      </Suspense>
    </Provider>
  );
}

export default App;
