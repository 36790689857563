import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useCreatePermission } from '../../shared/hooks/UsePermissions';
import RolesAndPermissionsForm from './RolesAndPermissionsForm';
function AddRolesAndPermissionsComponent() {
  const { addPermissionData } = useCreatePermission();
  const navigate = useNavigate();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Roles And Permissions</h1>
        </div>

        <Formik
          initialValues={{
            name: '',
            program: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            school: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            student: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            application: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            payment: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            education_level: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            agents: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
              assign_tier: false,
            },
            crm_user: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            roles_permission: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
            country: {
              create: false,
              get_all: false,
              view: false,
              delete: false,
              update: false,
            },
          }}
          validationSchema={Yup.object({})}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addPermissionData(values);
            navigate('/settings/permissions');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <RolesAndPermissionsForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddRolesAndPermissionsComponent;
