import {
  GET_APPLICATION_REQUIREMENTS_STARTED,
  GET_APPLICATION_REQUIREMENTS,
  GET_APPLICATION_REQUIREMENTS_ENDED,
  ADD_APPLICATION_REQUIREMENT_STARTED,
  ADD_APPLICATION_REQUIREMENT,
  ADD_APPLICATION_REQUIREMENT_ENDED,
  EDIT_APPLICATION_REQUIREMENT_STARTED,
  EDIT_APPLICATION_REQUIREMENT,
  EDIT_APPLICATION_REQUIREMENT_ENDED,
  GET_APPLICATION_REQUIREMENT,
  GET_APPLICATION_REQUIREMENT_STARTED,
  GET_APPLICATION_REQUIREMENT_ENDED,
  GET_APPLICATION_REQUIREMENT_PROGRAM_STARTED,
  GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED,
  GET_APPLICATION_REQUIREMENT_PROGRAM,
} from '../types/application_requirement_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addApplicationRequirement
export const addApplicationRequirement =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT_STARTED,
      });
      const { data } = await api.post(
        `/application-requirements/add`,
        formData
      );
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT,
        payload: data,
      });
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT_ENDED,
      });
      dispatch(
        setAlert('ApplicationRequirement added Successfully', 'success')
      );
    } catch (error) {
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT_ENDED,
      });
      // dispatch(setAlert('Failed ', 'danger'));
      dispatch(handleError(error));
    }
  };
// Add Application requirement answer
//addApplicationRequirement
export const addApplicationRequirementAnswer =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT_STARTED,
      });
      const { data } = await api.post(
        `/applications/add-application-requirement/${id}`,
        formData
      );
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT,
        payload: data,
      });
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT_ENDED,
      });
      dispatch(
        setAlert('ApplicationRequirement added Successfully', 'success')
      );
    } catch (error) {
      dispatch({
        type: ADD_APPLICATION_REQUIREMENT_ENDED,
      });
      // dispatch(setAlert('Failed ', 'danger'));
      dispatch(handleError(error));
    }
  };

export const getApplicationRequirements =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_APPLICATION_REQUIREMENTS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/application-requirements?&${query}`);

      dispatch({
        type: GET_APPLICATION_REQUIREMENTS,
        payload: data,
      });
      dispatch({
        type: GET_APPLICATION_REQUIREMENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_APPLICATION_REQUIREMENTS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getApplicationRequirement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_STARTED,
    });
    const { data } = await api.get(`/application-requirements/${id}`);

    dispatch({
      type: GET_APPLICATION_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const getApplicationRequirementByProgram = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_PROGRAM_STARTED,
    });
    const { data } = await api.get(`/application-requirements/program/${id}`);

    dispatch({
      type: GET_APPLICATION_REQUIREMENT_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const getApplicationRequirementBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_STARTED,
    });
    const { data } = await api.get(`/application-requirements/slug/${slug}`);

    dispatch({
      type: GET_APPLICATION_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editApplicationRequirement =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_APPLICATION_REQUIREMENT_STARTED,
      });
      const { data } = await api.put(
        `/application-requirements/${id}`,
        formData
      );
      dispatch({
        type: EDIT_APPLICATION_REQUIREMENT,
        payload: data,
      });
      dispatch({
        type: EDIT_APPLICATION_REQUIREMENT_ENDED,
      });
      dispatch(
        setAlert('ApplicationRequirement Edited Successfully', 'success')
      );
    } catch (error) {
      dispatch({
        type: EDIT_APPLICATION_REQUIREMENT_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const draftApplicationRequirement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT_STARTED,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_STARTED,
    });
    const { data } = await api.put(`/application-requirements/${id}/draft`);
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT,
      payload: data,
    });

    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateApplicationRequirement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT_STARTED,
    });
    const { data } = await api.put(`/application-requirements/${id}/active`);
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_STARTED,
    });
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });
    dispatch(
      setAlert('ApplicationRequirement Published Successfully', 'success')
    );
  } catch (error) {
    dispatch({
      type: EDIT_APPLICATION_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_APPLICATION_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteApplicationRequirement = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/application-requirements/${id}`);
    dispatch(
      setAlert('ApplicationRequirement Deleted Successfully', 'success')
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};
