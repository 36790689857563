import {
  REGISTER_AS_STUDENT_STARTED,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCESSFUL,
  GET_CURRENT_USER_STARTED,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_STARTED,
  LOGIN_FAILED,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  GET_CURRENT_USER_ENDED,
} from '../types/auth_types';
import { redirect } from 'react-router-dom';
import { api } from '../../domain/api';
import setAuthToken from '../../domain/setAuthToken';
import { setAlert } from './alert_action';

import { ErrorFunction } from '../../shared/errors/ErrorFunction';

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_AS_STUDENT_STARTED,
    });
    const res = await api.post('/users/signup', formData);

    dispatch({
      type: REGISTRATION_SUCCESSFUL,
      payload: 'User Registered successfully',
    });
    setAuthToken(res.data.token);

    window.location.href = '/dashboard';
  } catch (err) {
    const serverError = err;
    ErrorFunction(serverError, dispatch);

    dispatch({
      type: REGISTRATION_FAILED,
      payload: err,
    });
  }
};

// Register As  Agent
export const registerAsAgent = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_AS_STUDENT_STARTED,
    });
    const res = await api.post('/agents/register-as-agent', formData);

    dispatch({
      type: REGISTRATION_SUCCESSFUL,
      payload: 'Agent Registered successfully',
    });
    setAuthToken(res.data.token);

    window.location.href = '/dashboard';
  } catch (err) {
    const serverError = err;
    ErrorFunction(serverError, dispatch);

    dispatch({
      type: REGISTRATION_FAILED,
      payload: err,
    });
  }
};

// Get Logged In User
// Register User
export const currentUser = () => async (dispatch) => {
  try {
    const res = await api.get('/users/current-user');

    dispatch({
      type: GET_CURRENT_USER_STARTED,
    });
    await dispatch({
      type: USER_LOADED,
      payload: res.data,
    });

    dispatch({
      type: GET_CURRENT_USER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
    // dispatch(setAlert());
  }
};

// Login User
export const login = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_STARTED,
    });
    const res = await api.post('/users/login', formData);

    dispatch({
      type: LOGIN_SUCCESSFUL,
      payload: 'User Logged In successfully',
    });

    setAuthToken(res.data.token);
    dispatch(setAlert('Login Successfully', 'success'));

    window.location.href = '/dashboard';
  } catch (err) {
    const serverError = err;
    ErrorFunction(serverError, dispatch);

    dispatch({
      type: LOGIN_FAILED,
    });
  }
};
export const forgetPassword = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/users/forget-password', formData);
    dispatch(
      setAlert(
        'Email Reset link successfully sent to your email. Please check your email.',
        'success'
      )
    );
  } catch (err) {
    const serverError = err;
    ErrorFunction(serverError, dispatch);
  }
};
export const resetPassword = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/users/reset-password', formData);
    if (res.data) {
      dispatch(setAlert('Your Password Reset Successfully', 'success'));
    }
  } catch (err) {
    const serverError = err;
    ErrorFunction(serverError, dispatch);
  }
};
export const logout = () => async (dispatch) => {
  setAuthToken(null);
  dispatch({ type: LOGOUT });
};
