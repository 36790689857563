import React from 'react';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';

import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import CounsellorsComponent from '../../components/counsellors/CousellorsComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllCounsellors } from '../../shared/hooks/UseCounsellor';
import FilterComponent from '../../components/filter/FilterComponent';
function Counsellors() {
  const { data, setPageNumber } = useAllCounsellors();
  const {
    counsellor_loading,
    counsellors,
    counsellor_page,
    counsellor_pages,
    total_counsellors,
  } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Counsellors'} />
      <PermissionComponent hideOnCounsellor={true} hideOnAgent={true}>
        <SettingNavigationComponent
          active_link={SETTING_MENU_ENUM.Counsellors}
        />
      </PermissionComponent>

      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Counsellors</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'agents'} permission={'view'}>
                <div className='add-student-btn'>
                  <Link to={links.addCounsellor} className='btn primary-btn-h '>
                    Add Counsellor
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <section className='students-section'>
        {counsellors && (
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <CounsellorsComponent
                  counsellors_loading={counsellor_loading}
                  counsellors={counsellors}
                  counsellor_page={counsellor_page}
                  counsellor_pages={counsellor_pages}
                  total_counsellors={total_counsellors}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <AuthFooter />
    </div>
  );
}

export default Counsellors;
