import {
  GET_SCHOOLS_STARTED,
  GET_SCHOOLS,
  GET_SCHOOLS_ENDED,
  ADD_SCHOOL_STARTED,
  ADD_SCHOOL,
  ADD_SCHOOL_ENDED,
  EDIT_SCHOOL_STARTED,
  EDIT_SCHOOL,
  EDIT_SCHOOL_ENDED,
  GET_SCHOOL,
  GET_SCHOOL_STARTED,
  GET_SCHOOL_ENDED,
  GET_SCHOOL_PROGRAM_STARTED,
  GET_SCHOOL_PROGRAM,
  GET_SCHOOL_PROGRAM_ENDED,
  GET_ALL_SCHOOLS_STARTED,
  GET_ALL_SCHOOLS,
} from '../types/school_types';

const initialState = {
  schools_loading: true,
  schools: null,
  school_page: null,
  schools_pages: null,
  total_schools: 0,

  all_schools_loading: false,
  all_schools: null,

  school_programs_loading: true,
  school_programs: null,
  school_programs_pages: null,
  school_programs_page: null,
  total_school_programs: 0,

  school: null,
  school_loading: null,

  add_school_loading: true,
  edit_school_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCHOOLS_STARTED:
      return {
        ...state,
        schools: null,
        school_pages: null,
        school_page: null,
        total_schools: 0,
        schools_loading: true,
      };
    case GET_SCHOOLS:
      return {
        ...state,
        schools: payload.schools,
        school_pages: payload.pages,
        school_page: payload.page,
        total_schools: payload.total_schools,
      };
    case GET_SCHOOLS_ENDED:
      return {
        ...state,
        schools_loading: false,
      };

    case GET_ALL_SCHOOLS_STARTED:
      return {
        ...state,
        all_schools_loading: true,
      };
    case GET_ALL_SCHOOLS:
      return {
        ...state,
        all_schools: payload.schools,
        all_schools_loading: false,
      };

    case GET_SCHOOL_PROGRAM_STARTED:
      return {
        ...state,
        school_programs_loading: true,
        school_programs: null,
        school_programs_pages: null,
        school_programs_page: null,
        total_school_programs: 0,
      };
    case GET_SCHOOL_PROGRAM:
      return {
        ...state,
        school_programs: payload.school_programs,
        school_programs_pages: payload.school_programs_pages,
        school_programs_page: payload.school_programs_page,
        total_school_programs: payload.total_school_programs,
      };
    case GET_SCHOOL_PROGRAM_ENDED:
      return {
        ...state,
        school_programs_loading: false,
      };

    case ADD_SCHOOL_STARTED:
      return {
        ...state,
        school_message: null,
        add_school_loading: true,
      };
    case ADD_SCHOOL:
      return {
        ...state,
        school_message: payload,
      };
    case ADD_SCHOOL_ENDED:
      return {
        ...state,
        add_school_loading: false,
      };
    case GET_SCHOOL_STARTED:
      return {
        ...state,
        school: null,
        school_loading: true,
      };
    case GET_SCHOOL:
      return {
        ...state,
        school: payload,
      };
    case GET_SCHOOL_ENDED:
      return {
        ...state,
        school_loading: false,
      };
    case EDIT_SCHOOL_STARTED:
      return {
        ...state,
        school_message: null,
        edit_school_loading: true,
      };
    case EDIT_SCHOOL:
      return {
        ...state,
        school_message: payload,
      };
    case EDIT_SCHOOL_ENDED:
      return {
        ...state,
        edit_school_loading: false,
      };

    default:
      return state;
  }
}
