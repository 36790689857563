import {
  GET_SLABS_STARTED,
  GET_SLABS,
  GET_SLABS_ENDED,
  ADD_SLAB_STARTED,
  ADD_SLAB,
  ADD_SLAB_ENDED,
  EDIT_SLAB_STARTED,
  EDIT_SLAB,
  EDIT_SLAB_ENDED,
  GET_SLAB,
  GET_SLAB_STARTED,
  GET_SLAB_ENDED,
} from '../types/slab_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addSlab
export const addSlab = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_SLAB_STARTED,
    });
    const { data } = await api.post(`/slabs/add`, formData);
    dispatch({
      type: ADD_SLAB,
      payload: data,
    });
    dispatch({
      type: ADD_SLAB_ENDED,
    });
    dispatch(setAlert('Slab added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_SLAB_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getSlabs =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_SLABS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/slabs?&${query}`);

      dispatch({
        type: GET_SLABS,
        payload: data,
      });
      dispatch({
        type: GET_SLABS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_SLABS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getSlab = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SLAB_STARTED,
    });
    const { data } = await api.get(`/slabs/${id}`);

    dispatch({
      type: GET_SLAB,
      payload: data,
    });
    dispatch({
      type: GET_SLAB_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_SLAB_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getSlabBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SLAB_STARTED,
    });
    const { data } = await api.get(`/slabs/slug/${slug}`);

    dispatch({
      type: GET_SLAB,
      payload: data,
    });
    dispatch({
      type: GET_SLAB_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_SLAB_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editSlab = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SLAB_STARTED,
    });
    const { data } = await api.put(`/slabs/${id}`, formData);
    dispatch({
      type: EDIT_SLAB,
      payload: data,
    });
    dispatch({
      type: EDIT_SLAB_ENDED,
    });
    dispatch(setAlert('Slab Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_SLAB_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const deleteSlab = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/slabs/${id}`);
    dispatch(setAlert('Slab Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
