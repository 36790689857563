import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  FileUpload,
  GalleryUpload,
} from '../../components/Form/Form';
import * as Yup from 'yup';
import { countries } from '../../domain/countries';
import { MartialStatusEnum } from '../../domain/MaritalStatusEnum';
import { GenderEnum } from '../../domain/GenderEnum';
import moment from 'moment';
import { useUpdateGeneralInfo } from '../../shared/hooks/UseGeneralInfo';
import SingleProfileComponent from '../cards/SingleProfileComponent';
import { URI, getDocumentName } from '../../domain/constant';

export const PersonalInformation = ({ student_info, hideEdit }) => {
  const { userPersonalInfo, student } = student_info;
  const { updateGeneralInfo } = useUpdateGeneralInfo();
  const [editing, setEditing] = useState(false);
  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='d-flex justify-content-between'>
          <div>
            <h2>Personal Information</h2>
          </div>
          {!hideEdit && (
            <div>
              <button
                className='btn btn-assign'
                onClick={() => setEditing(!editing)}
              >
                {' '}
                {editing ? (
                  <i className='fa fa-close'></i>
                ) : (
                  <i className='fa fa-pencil'></i>
                )}{' '}
              </button>
            </div>
          )}
        </div>
        <div className='d-flex ' style={{ gap: '10px' }}>
          {student && student.profile_pic && (
            <div>
              <img
                src={`${URI}${student?.profile_pic}`}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'contain',
                  border: '1px solid #f1f1f1',
                }}
              />
            </div>
          )}

          <div>
            <span>
              Registration Date:{' '}
              {moment(student.createdAt).format('MMM DD, YYYY')}
            </span>
            <p> Email: {student.email} </p>
          </div>
        </div>

        {editing ? (
          <Formik
            initialValues={{
              first_name: student.first_name ? student.first_name : '',
              middle_name: student.middle_name ? student.middle_name : '',
              last_name: student.last_name ? student.last_name : '',
              profile_pic: student.profile_pic ? student.profile_pic : '',
              dob:
                userPersonalInfo && userPersonalInfo.dob
                  ? moment(userPersonalInfo.dob).format('yyyy-MM-DD')
                  : '',
              first_language:
                userPersonalInfo && userPersonalInfo.first_language
                  ? userPersonalInfo.first_language
                  : '',
              country_of_citizenship:
                userPersonalInfo && userPersonalInfo.country_of_citizenship
                  ? userPersonalInfo.country_of_citizenship
                  : '',
              country_of_residence:
                userPersonalInfo && userPersonalInfo.country_of_residence
                  ? userPersonalInfo.country_of_residence
                  : '',
              passport_no:
                userPersonalInfo && userPersonalInfo.passport_no
                  ? userPersonalInfo.passport_no
                  : '',
              passport_expiry_date:
                userPersonalInfo && userPersonalInfo.passport_expiry_date
                  ? moment(userPersonalInfo.passport_expiry_date).format(
                      'yyyy-MM-DD'
                    )
                  : '',
              passport_attachment:
                userPersonalInfo && userPersonalInfo.passport_attachment
                  ? userPersonalInfo.passport_attachment
                  : '',
              marital_status:
                userPersonalInfo && userPersonalInfo.marital_status
                  ? userPersonalInfo.marital_status
                  : '',
              id_proof:
                userPersonalInfo && userPersonalInfo.id_proof
                  ? userPersonalInfo.id_proof
                  : [],
              gender:
                userPersonalInfo && userPersonalInfo.gender
                  ? userPersonalInfo.gender
                  : '',
              application_form:
                userPersonalInfo && userPersonalInfo.application_form
                  ? userPersonalInfo.application_form
                  : '',
              sop:
                userPersonalInfo && userPersonalInfo.sop
                  ? userPersonalInfo.sop
                  : '',
              sop_form:
                userPersonalInfo && userPersonalInfo.sop_form
                  ? userPersonalInfo.sop_form
                  : '',
              is_self_employed:
                userPersonalInfo && userPersonalInfo.is_self_employed
                  ? userPersonalInfo.is_self_employed
                  : false,
              self_employment_documents:
                userPersonalInfo && userPersonalInfo.self_employment_documents
                  ? userPersonalInfo.self_employment_documents
                  : {
                      business_registration: '',
                      business_itrs: '',
                      business_bank_statements: '',
                      business_photographs: '',
                    },
              is_spouse_self_employed:
                userPersonalInfo && userPersonalInfo.is_spouse_self_employed
                  ? userPersonalInfo.is_spouse_self_employed
                  : false,
              spouse_self_employment_documents:
                userPersonalInfo &&
                userPersonalInfo.spouse_self_employment_documents
                  ? userPersonalInfo.spouse_self_employment_documents
                  : {
                      business_registration: '',
                      business_itrs: '',
                      business_bank_statements: '',
                      business_photographs: '',
                    },
              have_agriculture_income:
                userPersonalInfo && userPersonalInfo.have_agriculture_income
                  ? userPersonalInfo.have_agriculture_income
                  : false,
              agriculture_documents:
                userPersonalInfo && userPersonalInfo.agriculture_documents
                  ? userPersonalInfo.agriculture_documents
                  : {
                      j_form: '',
                      teshildar_certificate: '',
                      bank_statement: [],
                      land_documents_with_translation: [],
                    },
              emergency_contact_person_name:
                userPersonalInfo &&
                userPersonalInfo.emergency_contact_person_name
                  ? userPersonalInfo.emergency_contact_person_name
                  : '',
              emergency_contact_person_phone:
                userPersonalInfo &&
                userPersonalInfo.emergency_contact_person_phone
                  ? userPersonalInfo.emergency_contact_person_phone
                  : '',
              emergency_contact_person_relationship:
                userPersonalInfo &&
                userPersonalInfo.emergency_contact_person_relationship
                  ? userPersonalInfo.emergency_contact_person_relationship
                  : '',
              spouse_name:
                userPersonalInfo && userPersonalInfo.spouse_name
                  ? userPersonalInfo.spouse_name
                  : '',
              spouse_phone:
                userPersonalInfo && userPersonalInfo.spouse_phone
                  ? userPersonalInfo.spouse_phone
                  : '',
              spouse_email:
                userPersonalInfo && userPersonalInfo.spouse_email
                  ? userPersonalInfo.spouse_email
                  : '',
              spouse_passport_no:
                userPersonalInfo && userPersonalInfo.spouse_passport_no
                  ? userPersonalInfo.spouse_passport_no
                  : '',
              spouse_passport:
                userPersonalInfo && userPersonalInfo.spouse_passport
                  ? userPersonalInfo.spouse_passport
                  : '',

              spouse_passport_expiry_date:
                userPersonalInfo && userPersonalInfo.spouse_passport_expiry_date
                  ? moment(userPersonalInfo.spouse_passport_expiry_date).format(
                      'YYY-MM-DD'
                    )
                  : '',
              date_of_marriage:
                userPersonalInfo && userPersonalInfo.date_of_marriage
                  ? moment(userPersonalInfo.date_of_marriage).format(
                      'YYYY-MM-DD'
                    )
                  : '',
              marriage_certificate:
                userPersonalInfo && userPersonalInfo.marriage_certificate
                  ? userPersonalInfo.marriage_certificate
                  : '',
              have_work_experience:
                userPersonalInfo && userPersonalInfo.have_work_experience
                  ? userPersonalInfo.have_work_experience
                  : false,

              work_experience:
                userPersonalInfo && userPersonalInfo.work_experience
                  ? userPersonalInfo.work_experience
                  : [],
              have_work_experience_spouse:
                userPersonalInfo && userPersonalInfo.have_work_experience_spouse
                  ? userPersonalInfo.have_work_experience_spouse
                  : false,
              work_experience_spouse:
                userPersonalInfo && userPersonalInfo.work_experience_spouse
                  ? userPersonalInfo.work_experience_spouse
                  : [],
            }}
            validationSchema={Yup.object({
              first_name: Yup.string().required('Required'),

              last_name: Yup.string().required('Required'),
              dob: Yup.string().required('Required'),
              first_language: Yup.string().required('Required'),
              country_of_citizenship: Yup.string().required('Required'),

              marital_status: Yup.string().required('Required'),
              gender: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateGeneralInfo(student._id, values);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row'>
                    <div className='col-md-4'>
                      <TextInput label='First Name' name='first_name' />
                    </div>
                    <div className='col-md-4'>
                      <TextInput label='Middle Name' name='middle_name' />
                    </div>
                    <div className='col-md-4'>
                      <TextInput label='Last Name' name='last_name' />
                    </div>
                    <div className='col-md-6'>
                      <TextInput type='date' label='dob' name='dob' />
                    </div>
                    <div className='col-md-6'>
                      <TextInput label='First Language' name='first_language' />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor=''>Profile Pic</label>
                      <FileUpload
                        item='profile_pic'
                        label='Profile Pic'
                        name='profile_pic'
                        formik={formik}
                        value={formik.values.profile_pic}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor=''>Passport</label>
                      <FileUpload
                        item='passport_attachment'
                        label='Passport Attachment'
                        name='passport_attachment'
                        formik={formik}
                        value={formik.values.passport_attachment}
                        hideView={true}
                      />
                    </div>
                    <div className='col-md-12'>
                      <SelectBox
                        label='Country of Citizenship'
                        name='country_of_citizenship'
                      >
                        <option value=''></option>
                        {countries &&
                          countries.map((e) => {
                            return <option key={e.code}>{e.name}</option>;
                          })}
                      </SelectBox>
                    </div>
                    <div className='col-md-12'>
                      <SelectBox
                        label='Country of Residence'
                        name='country_of_residence'
                      >
                        <option value=''></option>
                        {countries &&
                          countries.map((e) => {
                            return <option key={e.code}>{e.name}</option>;
                          })}
                      </SelectBox>
                    </div>
                    <div className='col-md-6'>
                      <TextInput label='Passport number' name='passport_no' />
                    </div>
                    <div className='col-md-6'>
                      <TextInput
                        type='date'
                        label='Passport expiry date'
                        name='passport_expiry_date'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Marital Status *</label>
                      <CheckBox
                        label='Single'
                        name='marital_status_single'
                        checked={
                          formik.values.marital_status ===
                          MartialStatusEnum.Single
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'marital_status',
                            MartialStatusEnum.Single
                          );
                        }}
                      >
                        Single
                      </CheckBox>
                      <CheckBox
                        label='Single'
                        value='Married'
                        name='marital_status_married'
                        checked={
                          formik.values.marital_status ===
                          MartialStatusEnum.Married
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'marital_status',
                            MartialStatusEnum.Married
                          );
                        }}
                      >
                        Married
                      </CheckBox>
                      {formik.errors && formik.errors.marital_status && (
                        <div className='text-danger err'>
                          {formik.errors.marital_status}
                        </div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label>Gender *</label>
                      <CheckBox
                        label='Male'
                        name='gender_male'
                        checked={formik.values.gender === GenderEnum.Male}
                        onChange={(e) => {
                          formik.setFieldValue('gender', GenderEnum.Male);
                        }}
                      >
                        Male
                      </CheckBox>
                      <CheckBox
                        label='Female'
                        name='gender_female'
                        checked={formik.values.gender === GenderEnum.Female}
                        onChange={(e) => {
                          formik.setFieldValue('gender', GenderEnum.Female);
                        }}
                      >
                        Female
                      </CheckBox>
                      {formik.errors && formik.errors.marital_status && (
                        <div className='text-danger err'>
                          {formik.errors.gender}
                        </div>
                      )}
                    </div>

                    <h4> Work Experience For User</h4>
                    <div className='row'>
                      <div className='col-md-12'>
                        <CheckBox
                          label='Single'
                          value='Married'
                          name='have_work_experience_married'
                          checked={formik.values.have_work_experience}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue(
                                'have_work_experience',
                                true
                              );
                            } else {
                              formik.setFieldValue(
                                'have_work_experience',
                                false
                              );
                            }
                          }}
                        >
                          Do you have work experience?
                        </CheckBox>
                      </div>
                      {formik.values.have_work_experience && (
                        <>
                          <div className='row'>
                            <FieldArray
                              name='work_experience'
                              render={(arrayHelpers) => (
                                <div>
                                  {formik.values.work_experience.map(
                                    (friend, index) => (
                                      <div key={index}>
                                        {/* <input
                          name={`todos[${index}].name`}
                          value={formik.values.todos[index].name}
                          onChange={formik.handleChange}
                        /> */}
                                        <div className='d-flex justify-content-between'>
                                          <div className='label-featu'>
                                            Work Experience {index + 1}
                                          </div>
                                          <div>
                                            <a
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <i className='fa fa-close'></i>
                                            </a>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col-md-6'>
                                            <TextInput
                                              label='Company Name'
                                              name={`work_experience[${index}].company_name`}
                                            />
                                          </div>
                                          <div className='col-md-6'>
                                            <SelectBox
                                              label='Country'
                                              name={`work_experience[${index}].company_country`}
                                            >
                                              <option value=''>
                                                {' '}
                                                --none--{' '}
                                              </option>
                                              {countries &&
                                                countries.map((e) => {
                                                  return (
                                                    <option key={e.code}>
                                                      {e.name}
                                                    </option>
                                                  );
                                                })}
                                            </SelectBox>
                                          </div>
                                          <div className='col-md-12'>
                                            <TextInput
                                              label='Designation'
                                              name={`work_experience[${index}].designation`}
                                            />
                                          </div>
                                          <div className='col-md-12'>
                                            <label htmlFor=''>
                                              Work Document
                                            </label>
                                            <FileUpload
                                              item={`work_experience[${index}].document`}
                                              formik={formik}
                                              value={
                                                formik.values.work_experience &&
                                                formik.values.work_experience[
                                                  index
                                                ] &&
                                                formik.values.work_experience[
                                                  index
                                                ].document
                                              }
                                              hideView={true}
                                            />
                                          </div>
                                          <div className='col-md-12'>
                                            <label htmlFor=''>
                                              Reference Letter
                                            </label>
                                            <FileUpload
                                              item={`work_experience[${index}].employment_reference_letter`}
                                              formik={formik}
                                              value={
                                                formik.values.work_experience &&
                                                formik.values.work_experience[
                                                  index
                                                ] &&
                                                formik.values.work_experience[
                                                  index
                                                ].employment_reference_letter
                                              }
                                              hideView={true}
                                            />
                                          </div>
                                          <div className='col-md-12'>
                                            <label htmlFor=''>Pay Slips</label>
                                            <GalleryUpload
                                              item={`work_experience[${index}].pay_slips`}
                                              formik={formik}
                                              value={
                                                formik.values.work_experience &&
                                                formik.values.work_experience[
                                                  index
                                                ] &&
                                                formik.values.work_experience[
                                                  index
                                                ].pay_slips
                                              }
                                              hideView={true}
                                            />
                                          </div>
                                          <div className='col-md-12'>
                                            <label htmlFor=''>
                                              Bank Statements
                                            </label>
                                            <GalleryUpload
                                              item={`work_experience[${index}].bank_statements`}
                                              formik={formik}
                                              value={
                                                formik.values.work_experience &&
                                                formik.values.work_experience[
                                                  index
                                                ] &&
                                                formik.values.work_experience[
                                                  index
                                                ].bank_statements
                                              }
                                              hideView={true}
                                            />
                                          </div>
                                          <div className='col-md-12'>
                                            <label htmlFor=''>itrs</label>
                                            <GalleryUpload
                                              item={`work_experience[${index}].itrs`}
                                              formik={formik}
                                              value={
                                                formik.values.work_experience &&
                                                formik.values.work_experience[
                                                  index
                                                ] &&
                                                formik.values.work_experience[
                                                  index
                                                ].itrs
                                              }
                                              hideView={true}
                                            />
                                          </div>
                                          <div className='col-md-6'>
                                            <TextInput
                                              label='Working From date'
                                              name={`work_experience[${index}].working_from`}
                                              type='date'
                                              value={
                                                formik.values.work_experience[
                                                  index
                                                ] &&
                                                formik.values.work_experience[
                                                  index
                                                ].working_from
                                                  ? moment(
                                                      formik.values
                                                        .work_experience[index]
                                                        .working_from
                                                    ).format('YYYY-MM-DD')
                                                  : ''
                                              }
                                            />
                                          </div>
                                          {!formik.values.work_experience[index]
                                            .currently_working && (
                                            <div className='col-md-6'>
                                              <TextInput
                                                label='Working till date'
                                                name={`work_experience[${index}].working_to`}
                                                type='date'
                                                value={
                                                  formik.values.work_experience[
                                                    index
                                                  ] &&
                                                  formik.values.work_experience[
                                                    index
                                                  ].working_to
                                                    ? moment(
                                                        formik.values
                                                          .work_experience[
                                                          index
                                                        ].working_to
                                                      ).format('YYYY-MM-DD')
                                                    : ''
                                                }
                                              />
                                            </div>
                                          )}

                                          <div className='col-md-12'>
                                            <CheckBox
                                              label='Single'
                                              value='Married'
                                              name='currently_working_working_experience'
                                              checked={
                                                formik.values.work_experience[
                                                  index
                                                ].currently_working
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  formik.setFieldValue(
                                                    `work_experience[${index}].currently_working`,
                                                    true
                                                  );
                                                } else {
                                                  formik.setFieldValue(
                                                    `work_experience[${index}].currently_working`,
                                                    false
                                                  );
                                                }
                                              }}
                                            >
                                              Currently Working?
                                            </CheckBox>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type='button'
                                    className='btn primary-btn'
                                    onClick={() =>
                                      arrayHelpers.push({ name: '' })
                                    }
                                  >
                                    Add Work Experience
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        </>
                      )}

                      <div className='col-md-12'>
                        <CheckBox
                          label='Single'
                          value='Married'
                          name='is_self_employed'
                          checked={formik.values.is_self_employed}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue('is_self_employed', true);
                            } else {
                              formik.setFieldValue('is_self_employed', false);
                            }
                          }}
                        >
                          Are you self employed?
                        </CheckBox>
                      </div>
                      {formik.values.is_self_employed && (
                        <div className='row'>
                          <div className='col-md-6'>
                            <label htmlFor=''>Business Registration</label>
                            <FileUpload
                              item='self_employment_documents.business_registration'
                              formik={formik}
                              value={
                                formik.values.self_employment_documents
                                  .business_registration
                              }
                              hideView={true}
                            />
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor=''>Business ITRS</label>
                            <GalleryUpload
                              item='self_employment_documents.business_itrs'
                              formik={formik}
                              value={
                                formik.values.self_employment_documents
                                  .business_itrs
                              }
                              hideView={true}
                            />
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor=''>Business Bank Accounts</label>
                            <GalleryUpload
                              item='self_employment_documents.business_bank_statements'
                              formik={formik}
                              value={
                                formik.values.self_employment_documents
                                  .business_bank_statements
                              }
                              hideView={true}
                            />
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor=''>Business Photographs</label>
                            <GalleryUpload
                              item='self_employment_documents.business_photographs'
                              formik={formik}
                              value={
                                formik.values.self_employment_documents
                                  .business_photographs
                              }
                              hideView={true}
                            />
                          </div>
                          <hr />
                        </div>
                      )}
                    </div>

                    <h4> Emergency Contact Information </h4>
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextInput
                          label='Contact Person name'
                          name='emergency_contact_person_name'
                          type='text'
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Contact Person Phone'
                          name='emergency_contact_person_phone'
                          type='text'
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Relationship'
                          name='emergency_contact_person_relationship'
                          type='text'
                        />
                      </div>
                    </div>

                    {formik.values.marital_status &&
                      formik.values.marital_status ===
                        MartialStatusEnum.Married && (
                        <>
                          <h4> Spouse Details </h4>

                          <div className='row'>
                            <div className='col-md-6'>
                              <TextInput
                                label='Spouse Name'
                                name='spouse_name'
                                type='text'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Spouse Phone'
                                name='spouse_phone'
                                type='text'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Spouse Email'
                                name='spouse_email'
                                type='text'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Spouse Passport  No'
                                name='spouse_passport_no'
                                type='text'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Spouse Passport  Expiry Date'
                                name='spouse_passport_expiry_date'
                                type='date'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Date of Marriage'
                                name='date_of_marriage'
                                type='date'
                              />
                            </div>
                            <div className='col-md-6'>
                              <label htmlFor=''>Marriage Certificate</label>
                              <FileUpload
                                item='marriage_certificate'
                                formik={formik}
                                value={formik.values.marriage_certificate}
                                hideView={true}
                              />
                            </div>
                            <div className='col-md-6'>
                              <label htmlFor=''>Spouse Passport</label>
                              <FileUpload
                                item='spouse_passport'
                                formik={formik}
                                value={formik.values.spouse_passport}
                                hideView={true}
                              />
                            </div>
                            <div className='col-md-6'>
                              <label htmlFor=''>Spouse Academics</label>
                              <GalleryUpload
                                item='spouse_academics'
                                formik={formik}
                                value={formik.values.spouse_academics}
                                hideView={true}
                              />
                            </div>
                          </div>

                          <h4> Work Experience For Spouse</h4>
                          <div className='row'>
                            <div className='col-md-12'>
                              <CheckBox
                                label='Single'
                                value='Married'
                                name='have_work_experience_spouse'
                                checked={
                                  formik.values.have_work_experience_spouse
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    formik.setFieldValue(
                                      'have_work_experience_spouse',
                                      true
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      'have_work_experience_spouse',
                                      false
                                    );
                                  }
                                }}
                              >
                                Does Spouse have work experience?
                              </CheckBox>
                            </div>
                            {formik.values.have_work_experience_spouse && (
                              <>
                                <div className='row'>
                                  <FieldArray
                                    name='work_experience_spouse'
                                    render={(arrayHelpers) => (
                                      <div>
                                        {formik.values.work_experience_spouse.map(
                                          (friend, index) => (
                                            <div key={index}>
                                              {/* <input
                                name={`todos[${index}].name`}
                                value={formik.values.todos[index].name}
                                onChange={formik.handleChange}
                              /> */}
                                              <div className='d-flex justify-content-between'>
                                                <div className='label-featu'>
                                                  Work Experience {index + 1}
                                                </div>
                                                <div>
                                                  <a
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                  >
                                                    <i className='fa fa-close'></i>
                                                  </a>
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-6'>
                                                  <TextInput
                                                    label='Company Name'
                                                    name={`work_experience_spouse[${index}].company_name`}
                                                  />
                                                </div>
                                                <div className='col-md-6'>
                                                  <SelectBox
                                                    label='Country'
                                                    name={`work_experience_spouse[${index}].company_country`}
                                                  >
                                                    <option value=''>
                                                      {' '}
                                                      --none--{' '}
                                                    </option>
                                                    {countries &&
                                                      countries.map((e) => {
                                                        return (
                                                          <option key={e.code}>
                                                            {e.name}
                                                          </option>
                                                        );
                                                      })}
                                                  </SelectBox>
                                                </div>
                                                <div className='col-md-12'>
                                                  <TextInput
                                                    label='Designation'
                                                    name={`work_experience_spouse[${index}].designation`}
                                                  />
                                                </div>
                                                <div className='col-md-12'>
                                                  <label htmlFor=''>
                                                    Work Document
                                                  </label>
                                                  <FileUpload
                                                    item={`work_experience_spouse[${index}].document`}
                                                    formik={formik}
                                                    value={
                                                      formik.values
                                                        .work_experience_spouse &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ] &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ].document
                                                    }
                                                    hideView={true}
                                                  />
                                                </div>
                                                <div className='col-md-12'>
                                                  <label htmlFor=''>
                                                    Reference Letter
                                                  </label>
                                                  <FileUpload
                                                    item={`work_experience_spouse[${index}].employment_reference_letter`}
                                                    formik={formik}
                                                    value={
                                                      formik.values
                                                        .work_experience_spouse &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ] &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ]
                                                        .employment_reference_letter
                                                    }
                                                    hideView={true}
                                                  />
                                                </div>
                                                <div className='col-md-12'>
                                                  <label htmlFor=''>
                                                    Pay Slips
                                                  </label>
                                                  <GalleryUpload
                                                    item={`work_experience_spouse[${index}].pay_slips`}
                                                    formik={formik}
                                                    value={
                                                      formik.values
                                                        .work_experience_spouse &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ] &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ].pay_slips
                                                    }
                                                    hideView={true}
                                                  />
                                                </div>
                                                <div className='col-md-12'>
                                                  <label htmlFor=''>
                                                    Bank Statements
                                                  </label>
                                                  <GalleryUpload
                                                    item={`work_experience_spouse[${index}].bank_statements`}
                                                    formik={formik}
                                                    value={
                                                      formik.values
                                                        .work_experience_spouse &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ] &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ].bank_statements
                                                    }
                                                    hideView={true}
                                                  />
                                                </div>
                                                <div className='col-md-12'>
                                                  <label htmlFor=''>ITRS</label>
                                                  <GalleryUpload
                                                    item={`work_experience_spouse[${index}].itrs`}
                                                    formik={formik}
                                                    value={
                                                      formik.values
                                                        .work_experience_spouse &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ] &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ].itrs
                                                    }
                                                    hideView={true}
                                                  />
                                                </div>
                                                <div className='col-md-6'>
                                                  <TextInput
                                                    label='Working From date'
                                                    name={`work_experience_spouse[${index}].working_from`}
                                                    type='date'
                                                    value={
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ] &&
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ].working_from
                                                        ? moment(
                                                            formik.values
                                                              .work_experience_spouse[
                                                              index
                                                            ].working_from
                                                          ).format('YYYY-MM-DD')
                                                        : ''
                                                    }
                                                  />
                                                </div>
                                                {!formik.values
                                                  .work_experience_spouse[index]
                                                  .currently_working && (
                                                  <div className='col-md-6'>
                                                    <TextInput
                                                      label='Working till date'
                                                      name={`work_experience_spouse[${index}].working_to`}
                                                      type='date'
                                                      value={
                                                        formik.values
                                                          .work_experience_spouse[
                                                          index
                                                        ] &&
                                                        formik.values
                                                          .work_experience_spouse[
                                                          index
                                                        ].working_to
                                                          ? moment(
                                                              formik.values
                                                                .work_experience_spouse[
                                                                index
                                                              ].working_to
                                                            ).format(
                                                              'YYYY-MM-DD'
                                                            )
                                                          : ''
                                                      }
                                                    />
                                                  </div>
                                                )}

                                                <div className='col-md-12'>
                                                  <CheckBox
                                                    label='Single'
                                                    value='Married'
                                                    name='currently_working_spose_working_experience'
                                                    checked={
                                                      formik.values
                                                        .work_experience_spouse[
                                                        index
                                                      ].currently_working
                                                    }
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        formik.setFieldValue(
                                                          `work_experience_spouse[${index}].currently_working`,
                                                          true
                                                        );
                                                      } else {
                                                        formik.setFieldValue(
                                                          `work_experience_spouse[${index}].currently_working`,
                                                          false
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    Currently Working?
                                                  </CheckBox>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        <button
                                          type='button'
                                          className='btn primary-btn'
                                          onClick={() =>
                                            arrayHelpers.push({ name: '' })
                                          }
                                        >
                                          Add Work Experience
                                        </button>
                                      </div>
                                    )}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              <CheckBox
                                label='Single'
                                value='Married'
                                name='is_spouse_self_employed'
                                checked={formik.values.is_spouse_self_employed}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    formik.setFieldValue(
                                      'is_spouse_self_employed',
                                      true
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      'is_spouse_self_employed',
                                      false
                                    );
                                  }
                                }}
                              >
                                Is Spouse self employed?
                              </CheckBox>
                            </div>
                            {formik.values.is_spouse_self_employed && (
                              <div className='row'>
                                <div className='col-md-6'>
                                  <label htmlFor=''>
                                    Business Registration
                                  </label>
                                  <FileUpload
                                    item='spouse_self_employment_documents.business_registration'
                                    formik={formik}
                                    value={
                                      formik.values
                                        .spouse_self_employment_documents
                                        .business_registration
                                    }
                                    hideView={true}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <label htmlFor=''>Business ITRS</label>
                                  <GalleryUpload
                                    item='spouse_self_employment_documents.business_itrs'
                                    formik={formik}
                                    value={
                                      formik.values
                                        .spouse_self_employment_documents
                                        .business_itrs
                                    }
                                    hideView={true}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <label htmlFor=''>
                                    Business Bank Accounts
                                  </label>
                                  <GalleryUpload
                                    item='spouse_self_employment_documents.business_bank_statements'
                                    formik={formik}
                                    value={
                                      formik.values
                                        .spouse_self_employment_documents
                                        .business_bank_statements
                                    }
                                    hideView={true}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <label htmlFor=''>Business Photographs</label>
                                  <GalleryUpload
                                    item='spouse_self_employment_documents.business_photographs'
                                    formik={formik}
                                    value={
                                      formik.values
                                        .spouse_self_employment_documents
                                        .business_photographs
                                    }
                                    hideView={true}
                                  />
                                </div>
                                <hr />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <CheckBox
                        label='Single'
                        value='Married'
                        name='have_agriculture_income'
                        checked={formik.values.have_agriculture_income}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.setFieldValue(
                              'have_agriculture_income',
                              true
                            );
                          } else {
                            formik.setFieldValue(
                              'have_agriculture_income',
                              false
                            );
                          }
                        }}
                      >
                        Have Agriculture Income?
                      </CheckBox>
                    </div>
                  </div>
                  {formik.values.have_agriculture_income && (
                    <div className='row'>
                      <div className='col-md-6'>
                        <label htmlFor=''>J Form</label>
                        <FileUpload
                          item='agriculture_documents.j_form'
                          formik={formik}
                          value={formik.values.agriculture_documents.j_form}
                          hideView={true}
                        />
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor=''>Tehsildar Certificate</label>
                        <FileUpload
                          item='agriculture_documents.teshildar_certificate'
                          formik={formik}
                          value={
                            formik.values.agriculture_documents
                              .teshildar_certificate
                          }
                          hideView={true}
                        />
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor=''>Labels</label>
                        <GalleryUpload
                          item='agriculture_documents.bank_statement'
                          formik={formik}
                          value={
                            formik.values.agriculture_documents.bank_statement
                          }
                          hideView={true}
                        />
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor=''>
                          Land Documents with translations
                        </label>
                        <GalleryUpload
                          label='Land Documents with translations'
                          item='agriculture_documents.land_documents_with_translation'
                          formik={formik}
                          value={
                            formik.values.agriculture_documents
                              .land_documents_with_translation
                          }
                          hideView={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className='save-button'>
                    <button type='submit' className='btn primary-btn w-100'>
                      Save & Continue
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            <div className='row'>
              <SingleProfileComponent
                col={4}
                label={'First Name'}
                value={student.first_name}
              />
              <SingleProfileComponent
                col={4}
                label={'Middle Name'}
                value={student.middle_name}
              />
              <SingleProfileComponent
                col={4}
                label={'Last Name'}
                value={student.last_name}
              />
              <SingleProfileComponent
                col={6}
                label={'Date of Birth'}
                value={
                  userPersonalInfo &&
                  userPersonalInfo.dob &&
                  moment(userPersonalInfo.dob).format('DD-MMM-YYYY')
                }
              />
              <SingleProfileComponent
                col={6}
                label={'First Language'}
                value={userPersonalInfo && userPersonalInfo.first_language}
              />
              <SingleProfileComponent
                col={6}
                label={'Country of  citizenship'}
                value={
                  userPersonalInfo && userPersonalInfo.country_of_citizenship
                }
              />
              <SingleProfileComponent
                col={6}
                label={'Country of Residence'}
                value={
                  userPersonalInfo && userPersonalInfo.country_of_residence
                }
              />
              <SingleProfileComponent
                col={6}
                label={'Passport No'}
                value={userPersonalInfo && userPersonalInfo.passport_no}
              />
              <SingleProfileComponent
                col={6}
                label={'Passport Expiry Date'}
                value={
                  userPersonalInfo &&
                  userPersonalInfo.passport_expiry_date &&
                  moment(userPersonalInfo.passport_expiry_date).format(
                    'DD-MMM-YYYY'
                  )
                }
              />
              <div className='col-md-6'>
                <div className='single-info-card'>
                  <div className='single-info-label'>Passport</div>
                  <div className='single-info-value'>
                    {userPersonalInfo &&
                    userPersonalInfo.passport_attachment ? (
                      <a
                        href={`${URI}${userPersonalInfo.passport_attachment}`}
                        target='_blank'
                      >
                        {getDocumentName(userPersonalInfo.passport_attachment)}
                      </a>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              <SingleProfileComponent
                col={6}
                label={'Marital Status'}
                value={userPersonalInfo && userPersonalInfo.marital_status}
              />
              <SingleProfileComponent
                col={6}
                label={'Gender'}
                value={userPersonalInfo && userPersonalInfo.gender}
              />

              {userPersonalInfo && userPersonalInfo.have_work_experience ? (
                <div>
                  <h4> Work Experience </h4>
                  {userPersonalInfo &&
                    userPersonalInfo.work_experience &&
                    userPersonalInfo.work_experience.map((item, index) => {
                      return (
                        <>
                          <h6> Work Experience {index + 1} </h6>
                          <div className='row'>
                            <SingleProfileComponent
                              col={6}
                              label={'Company Name'}
                              value={item.company_name}
                            />
                            <SingleProfileComponent
                              col={6}
                              label={'Work Location'}
                              value={item.company_country}
                            />
                            <div className='col-md-6'>
                              <div className='single-info-card'>
                                <div className='single-info-label'>
                                  Document
                                </div>
                                <div className='single-info-value'>
                                  {item.document ? (
                                    <a
                                      href={`${URI}${item.document}`}
                                      target='_blank'
                                    >
                                      {getDocumentName(item.document)}
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='single-info-card'>
                                <div className='single-info-label'>
                                  Reference Letter
                                </div>
                                <div className='single-info-value'>
                                  {item.employment_reference_letter ? (
                                    <a
                                      href={`${URI}${item.employment_reference_letter}`}
                                      target='_blank'
                                    >
                                      {getDocumentName(
                                        item.employment_reference_letter
                                      )}
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className='col-md-6'>
                              <div className='single-info-card'>
                                <div className='single-info-label'>
                                  Pay Slips
                                </div>
                                <div className='single-info-value'>
                                  {item.pay_slips
                                    ? item.pay_slips.map((doc) => (
                                        <div>
                                          <a
                                            href={`${URI}${doc}`}
                                            target='_blank'
                                          >
                                            {getDocumentName(doc)}
                                          </a>
                                        </div>
                                      ))
                                    : ''}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='single-info-card'>
                                <div className='single-info-label'>
                                  Bank Statements
                                </div>
                                <div className='single-info-value'>
                                  {item.bank_statements
                                    ? item.bank_statements.map((doc) => (
                                        <div>
                                          <a
                                            href={`${URI}${doc}`}
                                            target='_blank'
                                          >
                                            {getDocumentName(doc)}
                                          </a>
                                        </div>
                                      ))
                                    : ''}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='single-info-card'>
                                <div className='single-info-label'>ITRS</div>
                                <div className='single-info-value'>
                                  {item.itrs
                                    ? item.itrs.map((doc) => (
                                        <div>
                                          <a
                                            href={`${URI}${doc}`}
                                            target='_blank'
                                          >
                                            {getDocumentName(doc)}
                                          </a>
                                        </div>
                                      ))
                                    : ''}
                                </div>
                              </div>
                            </div>

                            <SingleProfileComponent
                              col={4}
                              label={'Designation'}
                              value={item.designation}
                            />
                            <SingleProfileComponent
                              col={4}
                              label={'Working From'}
                              value={
                                item.working_from &&
                                moment(item.working_from).format('DD-MM-YYYY')
                              }
                            />
                            {item.currently_working ? (
                              <SingleProfileComponent
                                col={4}
                                label={'Currently Working'}
                                value={'YES'}
                              />
                            ) : (
                              <SingleProfileComponent
                                col={4}
                                label={'Working Till Date'}
                                value={
                                  item.working_to &&
                                  moment(item.working_to).format('DD-MM-YYYY')
                                }
                              />
                            )}
                            <hr />
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : (
                <SingleProfileComponent
                  col={6}
                  label={'Have Work Experience?'}
                  value={'NO'}
                />
              )}

              {userPersonalInfo && userPersonalInfo.is_self_employed && (
                <div className='row'>
                  <div>
                    {' '}
                    <h4> Business Details </h4>{' '}
                  </div>
                  <div className='col-md-6'>
                    <div className='single-info-card'>
                      <div className='single-info-label'>
                        Business Registration
                      </div>
                      <div className='single-info-value'>
                        {userPersonalInfo.self_employment_documents
                          .business_registration ? (
                          <a
                            href={`${URI}${userPersonalInfo.self_employment_documents.business_registration}`}
                            target='_blank'
                          >
                            {getDocumentName(
                              userPersonalInfo.self_employment_documents
                                .business_registration
                            )}
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-info-card'>
                      <div className='single-info-label'>Business ITRS</div>
                      <div className='single-info-value'>
                        {userPersonalInfo.self_employment_documents
                          .business_itrs
                          ? userPersonalInfo.self_employment_documents.business_itrs.map(
                              (item) => (
                                <a href={`${URI}${item}`} target='_blank'>
                                  {getDocumentName(item)}
                                </a>
                              )
                            )
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-info-card'>
                      <div className='single-info-label'>
                        Business Bank Accounts
                      </div>
                      <div className='single-info-value'>
                        {userPersonalInfo.self_employment_documents
                          .business_bank_statements
                          ? userPersonalInfo.self_employment_documents.business_bank_statements.map(
                              (item) => (
                                <a href={`${URI}${item}`} target='_blank'>
                                  {getDocumentName(item)}
                                </a>
                              )
                            )
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-info-card'>
                      <div className='single-info-label'>Business Photos</div>
                      <div className='single-info-value'>
                        {userPersonalInfo.self_employment_documents
                          .business_photographs
                          ? userPersonalInfo.self_employment_documents.business_photographs.map(
                              (item) => (
                                <a href={`${URI}${item}`} target='_blank'>
                                  {getDocumentName(item)}
                                </a>
                              )
                            )
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <h4> Emergency Contact Details </h4>
              <SingleProfileComponent
                col={6}
                label={'Emergency Contact Person Name'}
                value={
                  userPersonalInfo &&
                  userPersonalInfo.emergency_contact_person_name
                }
              />
              <SingleProfileComponent
                col={6}
                label={'Emergency Contact Person Phone'}
                value={
                  userPersonalInfo &&
                  userPersonalInfo.emergency_contact_person_phone
                }
              />
              <SingleProfileComponent
                col={6}
                label={'Emergency Contact Person Relationship'}
                value={
                  userPersonalInfo &&
                  userPersonalInfo.emergency_contact_person_relationship
                }
              />
              {userPersonalInfo &&
                userPersonalInfo.marital_status ===
                  MartialStatusEnum.Married && (
                  <div>
                    <h4> Spouse Details </h4>
                    <div className='row'>
                      <SingleProfileComponent
                        col={6}
                        label={'Spouse Name'}
                        value={userPersonalInfo && userPersonalInfo.spouse_name}
                      />
                      <SingleProfileComponent
                        col={6}
                        label={'Spouse Phone'}
                        value={
                          userPersonalInfo && userPersonalInfo.spouse_phone
                        }
                      />
                      <SingleProfileComponent
                        col={6}
                        label={'Spouse Email'}
                        value={
                          userPersonalInfo && userPersonalInfo.spouse_email
                        }
                      />
                      <SingleProfileComponent
                        col={6}
                        label={'Spouse Passport no'}
                        value={
                          userPersonalInfo &&
                          userPersonalInfo.spouse_passport_no
                        }
                      />
                      <SingleProfileComponent
                        col={6}
                        label={'Spouse Passport Expiry Date'}
                        value={
                          userPersonalInfo &&
                          userPersonalInfo.spouse_passport_expiry_date
                            ? moment(
                                userPersonalInfo.spouse_passport_expiry_date
                              ).format('YYYY-MM-DD')
                            : ''
                        }
                      />
                      <SingleProfileComponent
                        col={6}
                        label={'Date of marriage'}
                        value={
                          userPersonalInfo && userPersonalInfo.date_of_marriage
                            ? moment(userPersonalInfo.date_of_marriage).format(
                                'YYYY-MM-DD'
                              )
                            : ''
                        }
                      />

                      <div className='col-md-6'>
                        <div className='single-info-card'>
                          <div className='single-info-label'>
                            Marriage Certificate
                          </div>
                          <div className='single-info-value'>
                            {userPersonalInfo &&
                            userPersonalInfo.marriage_certificate ? (
                              <a
                                href={`${URI}${userPersonalInfo.marriage_certificate}`}
                                target='_blank'
                              >
                                {' '}
                                View Certificate{' '}
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {userPersonalInfo &&
                    userPersonalInfo.have_work_experience_spouse ? (
                      <div>
                        <h4> Spouse Work Experience </h4>
                        {userPersonalInfo &&
                          userPersonalInfo.work_experience_spouse &&
                          userPersonalInfo.work_experience_spouse.map(
                            (item, index) => {
                              return (
                                <>
                                  <h6> Spouse Work Experience {index + 1} </h6>
                                  <div className='row'>
                                    <SingleProfileComponent
                                      col={6}
                                      label={'Company Name'}
                                      value={item.company_name}
                                    />
                                    <SingleProfileComponent
                                      col={6}
                                      label={'Work Location'}
                                      value={item.company_country}
                                    />
                                    <div className='col-md-6'>
                                      <div className='single-info-card'>
                                        <div className='single-info-label'>
                                          Document
                                        </div>
                                        <div className='single-info-value'>
                                          {item.document ? (
                                            <a
                                              href={`${URI}${item.document}`}
                                              target='_blank'
                                            >
                                              {getDocumentName(item.document)}
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-md-6'>
                                      <div className='single-info-card'>
                                        <div className='single-info-label'>
                                          Reference Letter
                                        </div>
                                        <div className='single-info-value'>
                                          {item.employment_reference_letter ? (
                                            <a
                                              href={`${URI}${item.employment_reference_letter}`}
                                              target='_blank'
                                            >
                                              {getDocumentName(
                                                item.employment_reference_letter
                                              )}
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className='col-md-6'>
                                      <div className='single-info-card'>
                                        <div className='single-info-label'>
                                          Pay Slips
                                        </div>
                                        <div className='single-info-value'>
                                          {item.pay_slips
                                            ? item.pay_slips.map((doc) => (
                                                <div>
                                                  <a
                                                    href={`${URI}${doc}`}
                                                    target='_blank'
                                                  >
                                                    {getDocumentName(doc)}
                                                  </a>
                                                </div>
                                              ))
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-md-6'>
                                      <div className='single-info-card'>
                                        <div className='single-info-label'>
                                          Bank Statements
                                        </div>
                                        <div className='single-info-value'>
                                          {item.bank_statements
                                            ? item.bank_statements.map(
                                                (doc) => (
                                                  <div>
                                                    <a
                                                      href={`${URI}${doc}`}
                                                      target='_blank'
                                                    >
                                                      {getDocumentName(doc)}
                                                    </a>
                                                  </div>
                                                )
                                              )
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-md-6'>
                                      <div className='single-info-card'>
                                        <div className='single-info-label'>
                                          ITRS
                                        </div>
                                        <div className='single-info-value'>
                                          {item.itrs
                                            ? item.itrs.map((doc) => (
                                                <div>
                                                  <a
                                                    href={`${URI}${doc}`}
                                                    target='_blank'
                                                  >
                                                    {getDocumentName(doc)}
                                                  </a>
                                                </div>
                                              ))
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                    <SingleProfileComponent
                                      col={4}
                                      label={'Designation'}
                                      value={item.designation}
                                    />
                                    <SingleProfileComponent
                                      col={4}
                                      label={'Working From'}
                                      value={
                                        item.working_from &&
                                        moment(item.working_from).format(
                                          'DD-MM-YYYY'
                                        )
                                      }
                                    />
                                    {item.currently_working ? (
                                      <SingleProfileComponent
                                        col={4}
                                        label={'Currently Working'}
                                        value={'YES'}
                                      />
                                    ) : (
                                      <SingleProfileComponent
                                        col={4}
                                        label={'Working Till Date'}
                                        value={
                                          item.working_to &&
                                          moment(item.working_to).format(
                                            'DD-MM-YYYY'
                                          )
                                        }
                                      />
                                    )}
                                    <hr />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    ) : (
                      <SingleProfileComponent
                        col={6}
                        label={'Have Work Experience?'}
                        value={'NO'}
                      />
                    )}
                    {userPersonalInfo &&
                      userPersonalInfo.is_spouse_self_employed && (
                        <div className='row'>
                          <div>
                            {' '}
                            <h4>Spouse Business Details </h4>{' '}
                          </div>
                          <div className='col-md-6'>
                            <div className='single-info-card'>
                              <div className='single-info-label'>
                                Business Registration
                              </div>
                              <div className='single-info-value'>
                                {userPersonalInfo
                                  .spouse_self_employment_documents
                                  .business_registration ? (
                                  <a
                                    href={`${URI}${userPersonalInfo.spouse_self_employment_documents.business_registration}`}
                                    target='_blank'
                                  >
                                    {getDocumentName(
                                      userPersonalInfo
                                        .spouse_self_employment_documents
                                        .business_registration
                                    )}
                                  </a>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='single-info-card'>
                              <div className='single-info-label'>
                                Business ITRS
                              </div>
                              <div className='single-info-value'>
                                {userPersonalInfo
                                  .spouse_self_employment_documents
                                  .business_itrs
                                  ? userPersonalInfo.spouse_self_employment_documents.business_itrs.map(
                                      (item) => (
                                        <a
                                          href={`${URI}${item}`}
                                          target='_blank'
                                        >
                                          {getDocumentName(item)}
                                        </a>
                                      )
                                    )
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='single-info-card'>
                              <div className='single-info-label'>
                                Business Bank Accounts
                              </div>
                              <div className='single-info-value'>
                                {userPersonalInfo
                                  .spouse_self_employment_documents
                                  .business_bank_statements
                                  ? userPersonalInfo.spouse_self_employment_documents.business_bank_statements.map(
                                      (item) => (
                                        <a
                                          href={`${URI}${item}`}
                                          target='_blank'
                                        >
                                          {getDocumentName(item)}
                                        </a>
                                      )
                                    )
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='single-info-card'>
                              <div className='single-info-label'>
                                Business Photos
                              </div>
                              <div className='single-info-value'>
                                {userPersonalInfo
                                  .spouse_self_employment_documents
                                  .business_photographs
                                  ? userPersonalInfo.spouse_self_employment_documents.business_photographs.map(
                                      (item) => (
                                        <a
                                          href={`${URI}${item}`}
                                          target='_blank'
                                        >
                                          {getDocumentName(item)}
                                        </a>
                                      )
                                    )
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}
            </div>
            <hr />
            <div>
              {userPersonalInfo &&
                userPersonalInfo.have_agriculture_income &&
                userPersonalInfo.agriculture_documents && (
                  <div className='row'>
                    <h4> Agriculture Documents </h4>
                    <div className='col-md-6'>
                      <div> J Form </div>
                      {userPersonalInfo.agriculture_documents.j_form ? (
                        <a
                          href={`${URI}${userPersonalInfo.agriculture_documents.j_form}`}
                          target='_blank'
                        >
                          {getDocumentName(
                            userPersonalInfo.agriculture_documents.j_form
                          )}
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='col-md-6'>
                      <div> Tehsildar Certificate </div>
                      {userPersonalInfo.agriculture_documents
                        .teshildar_certificate ? (
                        <a
                          href={`${URI}${userPersonalInfo.agriculture_documents.teshildar_certificate}`}
                          target='_blank'
                        >
                          {getDocumentName(
                            userPersonalInfo.agriculture_documents
                              .teshildar_certificate
                          )}
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='col-md-6'>
                      <div> Bank Statements </div>
                      {userPersonalInfo.agriculture_documents.bank_statement
                        ? userPersonalInfo.agriculture_documents.bank_statement.map(
                            (item) => (
                              <a href={`${URI}${item}`} target='_blank'>
                                {getDocumentName(item)}
                              </a>
                            )
                          )
                        : ''}
                    </div>
                    <div className='col-md-6'>
                      <div> Land Documents with translations </div>
                      {userPersonalInfo.agriculture_documents
                        .land_documents_with_translation
                        ? userPersonalInfo.agriculture_documents.land_documents_with_translation.map(
                            (item) => (
                              <a href={`${URI}${item}`} target='_blank'>
                                {getDocumentName(item)}
                              </a>
                            )
                          )
                        : ''}
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
