import {
  ADD_ENGLISH_TESTSCORE_STARTED,
  ADD_ENGLISH_TESTSCORE_SUCCESS,
  ADD_ENGLISH_TESTSCORE_ENDED,
  ADD_ENGLISH_TESTSCORE_ERROR,
} from '../types/english_testscore_types';
import { api } from '../../domain/api';
import {
  GET_STUDENT,
  GET_STUDENT_ENDED,
  GET_STUDENT_STARTED,
} from '../types/student_types';
import { setAlert } from './alert_action';

// Register User
export const updateEnglishTestScoreInfo = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_ENGLISH_TESTSCORE_STARTED,
    });
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    const res = await api.post(`/english-scores/${id}`, data);

    dispatch({
      type: ADD_ENGLISH_TESTSCORE_SUCCESS,
      payload: 'English Test Score Updated',
    });
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
    dispatch(setAlert('English Test Information Updated', 'success'));
    dispatch({
      type: ADD_ENGLISH_TESTSCORE_ENDED,
    });
  } catch (err) {
    dispatch({
      type: ADD_ENGLISH_TESTSCORE_ENDED,
    });
    dispatch({
      type: ADD_ENGLISH_TESTSCORE_ERROR,
      payload: err,
    });
  }
};
