import {
  GET_COUNSELLORS_STARTED,
  GET_COUNSELLORS,
  GET_COUNSELLORS_ENDED,
  ADD_COUNSELLOR_STARTED,
  ADD_COUNSELLOR,
  ADD_COUNSELLOR_ENDED,
  EDIT_COUNSELLOR_STARTED,
  EDIT_COUNSELLOR,
  EDIT_COUNSELLOR_ENDED,
  GET_COUNSELLOR,
  GET_COUNSELLOR_STARTED,
  GET_COUNSELLOR_ENDED,
} from '../types/counsellor_types';

const initialState = {
  counsellors_loading: true,
  counsellors: null,
  counsellor_page: null,
  counsellors_pages: null,
  total_counsellors: 0,

  counsellor: null,
  counsellor_loading: null,

  add_counsellor_loading: true,
  edit_counsellor_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COUNSELLORS_STARTED:
      return {
        ...state,
        counsellors: null,
        counsellor_pages: null,
        counsellor_page: null,
        total_counsellors: 0,
        counsellors_loading: true,
      };
    case GET_COUNSELLORS:
      return {
        ...state,
        counsellors: payload.counsellors,
        counsellor_pages: payload.pages,
        counsellor_page: payload.page,
        total_counsellors: payload.total_counsellors,
      };
    case GET_COUNSELLORS_ENDED:
      return {
        ...state,
        counsellors_loading: false,
      };

    case ADD_COUNSELLOR_STARTED:
      return {
        ...state,
        counsellor_message: null,
        add_counsellor_loading: true,
      };
    case ADD_COUNSELLOR:
      return {
        ...state,
        counsellor_message: payload,
      };
    case ADD_COUNSELLOR_ENDED:
      return {
        ...state,
        add_counsellor_loading: false,
      };
    case GET_COUNSELLOR_STARTED:
      return {
        ...state,
        counsellor: null,
        counsellor_loading: true,
      };
    case GET_COUNSELLOR:
      return {
        ...state,
        counsellor: payload,
      };
    case GET_COUNSELLOR_ENDED:
      return {
        ...state,
        counsellor_loading: false,
      };
    case EDIT_COUNSELLOR_STARTED:
      return {
        ...state,
        counsellor_message: null,
        edit_counsellor_loading: true,
      };
    case EDIT_COUNSELLOR:
      return {
        ...state,
        counsellor_message: payload,
      };
    case EDIT_COUNSELLOR_ENDED:
      return {
        ...state,
        edit_counsellor_loading: false,
      };

    default:
      return state;
  }
}
