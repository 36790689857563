import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import { useSingleAgent, useUpdateAgent } from '../../shared/hooks/UseAgent';
import AgentFormComponent from './AgentFormComponent';
import AllAgentFormComponent from './AllAgentFormComponent';
import AgentProfileForm from './AgentProfileForm';
import BankFormComponent from './BankFormComponent';
import { countries } from '../../domain/countries';

function AllEditAgentComponent({
  agent,
  redirectLink,
  bank_account_details,
  agent_info,
}) {
  const params = useParams();

  const { updateAgentData } = useUpdateAgent();
  const navigate = useNavigate();
  return (
    <div>
      {agent && (
        <div className='personal-information-box clearfix'>
          <div className='main-sec-heading'>
            <h1>Edit Agent Details</h1>
          </div>

          <Formik
            initialValues={{
              first_name: agent && agent.agent ? agent.agent.first_name : '',
              last_name: agent && agent.agent ? agent.agent.last_name : '',
              phone: agent && agent.agent ? agent.agent.phone : '',
              email: agent && agent.agent ? agent.agent.email : '',

              nationality: agent && agent.agent ? agent.agent.nationality : '',
              country_code:
                agent && agent.agent ? agent.agent.country_code : '',
              owner_first_name:
                agent_info && agent_info.owner_first_name
                  ? agent_info.owner_first_name
                  : '',
              owner_last_name:
                agent_info && agent_info.owner_last_name
                  ? agent_info.owner_last_name
                  : '',
              company_name:
                agent_info && agent_info.company_name
                  ? agent_info.company_name
                  : '',
              company_address:
                agent_info && agent_info.company_address
                  ? agent_info.company_address
                  : '',
              company_country:
                agent_info && agent_info.company_country
                  ? agent_info.company_country
                  : '',
              company_state:
                agent_info && agent_info.company_state
                  ? agent_info.company_state
                  : '',
              company_city:
                agent_info && agent_info.company_city
                  ? agent_info.company_city
                  : '',
              company_zipcode:
                agent_info && agent_info.company_zipcode
                  ? agent_info.company_zipcode
                  : '',
              preferred_contact_method:
                agent_info && agent_info.preferred_contact_method
                  ? agent_info.preferred_contact_method
                  : '',
              source_to_know_about_edchimp:
                agent_info && agent_info.source_to_know_about_edchimp
                  ? agent_info.source_to_know_about_edchimp
                  : '',
              referred_by_edchimp:
                agent_info && agent_info.referred_by_edchimp
                  ? agent_info.referred_by_edchimp
                  : '',
              year_of_recruiting_started:
                agent_info && agent_info.year_of_recruiting_started
                  ? agent_info.year_of_recruiting_started
                  : '',
              main_source_country:
                agent_info && agent_info.main_source_country
                  ? agent_info.main_source_country
                  : [],
              business_certificate:
                agent_info && agent_info.business_certificate
                  ? agent_info.business_certificate
                  : '',
              business_logo:
                agent_info && agent_info.business_logo
                  ? agent_info.business_logo
                  : '',
              services:
                agent_info && agent_info.services ? agent_info.services : [],
              bank_holder_name: bank_account_details
                ? bank_account_details.bank_holder_name
                : '',
              account_no: bank_account_details
                ? bank_account_details.account_no
                : '',

              ifsc_code: bank_account_details
                ? bank_account_details.ifsc_code
                : '',
              bank_name: bank_account_details
                ? bank_account_details.bank_name
                : '',
              bank_address: bank_account_details
                ? bank_account_details.bank_address
                : '',
              swift_code: bank_account_details
                ? bank_account_details.swift_code
                : '',
            }}
            validationSchema={Yup.object({
              first_name: Yup.string().required('Required'),
              last_name: Yup.string().required('Required'),
              phone: Yup.number()
                .typeError('should be a number.')
                .required('Required'),
              email: Yup.string().email().required('Required'),
              nationality: Yup.string().required('Required'),
              country_code: Yup.string().required('Required'),
              company_name: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateAgentData(agent.agent._id, values);
              navigate(redirectLink ? redirectLink : '/settings/agents');
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <AllAgentFormComponent hidePassword={true} />
                  <AgentProfileForm formik={formik} countries={countries} />
                  <BankFormComponent />
                  <div className='create-button'>
                    <button type='submit' className='btn primary-btn'>
                      Save & Continue
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default AllEditAgentComponent;
