import React from 'react';
import { LoginImage } from '../../components/images';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { TextInput } from '../../components/Form/Form';
import * as Yup from 'yup';

import { links } from '../../domain/links.enum';
import { useForgetPassword } from '../../shared/hooks/UseAuth';
import { Alerts } from '../../components/common/Alerts';

export const ForgotPassword = (props) => {
  const [forgetPassword] = useForgetPassword();
  return (
    <div className='reset-password'>
      <div className='reset-flex'>
        <div className='reset-main mt-5'>
          <div className='reset-password-form'>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string().email().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await forgetPassword(values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <h3>Reset Password</h3>

                    <label>
                      Enter your email address to reset your password
                    </label>

                    <TextInput label='Email' name='email' />

                    <div className='create-button'>
                      <button className='btn primary-btn w-100' type='submit'>
                        Reset Via Email
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <hr></hr>
            <div className='terms'>
              <Link to={links.login}> Back to Log In </Link>
            </div>
          </div>
        </div>
        <div className='reset-image'>
          <img src={LoginImage} />
        </div>
      </div>
      <Alerts />
    </div>
  );
};
