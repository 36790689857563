import { useDispatch } from 'react-redux';
import { updateAddressInfo } from '../../store/actions/address_info_action';

export const useUpdateAddressInfo = () => {
  const dispatch = useDispatch();

  const updateAddressInformation = async (id, values) => {
    dispatch(updateAddressInfo(id, values));
  };

  return {
    updateAddressInformation,
  };
};
