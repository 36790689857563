import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
function AddCounsellorComponent({
  counsellors_loading,
  counsellors,
  counsellor_page,
  counsellor_pages,
  total_counsellors,
  setPageNumber,
}) {
  const navigate = useNavigate();
  const [columnDefs] = useState([
    { field: 'first_name' },
    { field: 'last_name' },
    { field: 'email' },
    { field: 'phone' },
    { field: 'country_code' },
    { field: 'user_id' },
    { field: 'is_verified' },
    { field: 'is_blocked' },

    {
      field: 'Actions',
      pinned: 'right',
      cellRenderer: ViewButtonByID,
      cellRendererParams: {
        clicked: function (field) {
          navigate(`/settings/counsellor/${field}/view`);
        },
        edit_clicked: function (field) {
          navigate(`/settings/counsellor/${field}/edit`);
        },
      },
    },
  ]);
  return (
    <PermissionComponent name={'agents'} permission={'get_all'}>
      <div>
        <p> {total_counsellors}Records Found </p>
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <th scope='col '>First Name</th>
                <th scope='col '>Last Name</th>
                <th scope='col '>Email</th>
                <th scope='col '>Phone</th>
                <th scope='col '>Country Code</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {counsellors &&
                counsellors.map((counsellor) => {
                  return (
                    <tr key={counsellor._id}>
                      <td>{counsellor.first_name}</td>
                      <td>{counsellor.last_name}</td>
                      <td>{counsellor.email}</td>
                      <td>{counsellor.phone}</td>
                      <td>{counsellor.country_code}</td>

                      <td className='status-ic'>
                        <Link
                          to={`/settings/counsellors/${counsellor._id}/view`}
                          className='badge badge-view'
                        >
                          View
                        </Link>
                        <Link
                          to={`/settings/counsellors/${counsellor._id}/edit`}
                          className='badge badge-edit'
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {counsellors && counsellors.length == 0 && (
                <tr>
                  <td colSpan={10}> {''}No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={counsellors}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={counsellors}
          count={total_counsellors}
          pages={counsellor_pages}
          loading={counsellors_loading}
        />
      </div>
    </PermissionComponent>
  );
}

export default AddCounsellorComponent;
