import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  GalleryUpload,
} from '../../components/Form/Form';
import * as Yup from 'yup';
import { countries } from '../../domain/countries';
import { useUpdateGreGmatInfo } from '../../shared/hooks/UseGreGmat';
import moment from 'moment';
import SingleProfileComponent from '../cards/SingleProfileComponent';
import { URI, getDocumentName } from '../../domain/constant';

export const GreGmatScore = ({ student_info, hideEdit }) => {
  const { userGmatScore, userGreScore, student } = student_info;
  const { updateGreGmatInformation } = useUpdateGreGmatInfo();
  const [editing, setEditing] = useState(false);
  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='d-flex justify-content-between'>
          <h2>GRE or GMAT Scores</h2>
          {!hideEdit && (
            <div>
              <button
                className='btn btn-assign'
                onClick={() => setEditing(!editing)}
              >
                {editing ? (
                  <i className='fa fa-close'></i>
                ) : (
                  <i className='fa fa-pencil'></i>
                )}
              </button>
            </div>
          )}
        </div>
        {editing ? (
          <Formik
            initialValues={{
              is_gre_selected:
                userGreScore && userGreScore.have_given_test ? true : false,
              gre_documents:
                userGreScore && userGreScore.documents
                  ? userGreScore.documents
                  : [],
              gmat_documents:
                userGmatScore && userGmatScore.documents
                  ? userGmatScore.documents
                  : [],

              gre_verbal_score:
                userGreScore && userGreScore.verbal_score
                  ? userGreScore.verbal_score
                  : '',
              gre_verbal_rank:
                userGreScore && userGreScore.verbal_rank
                  ? userGreScore.verbal_rank
                  : '',
              gre_quantitative_score:
                userGreScore && userGreScore.quantitative_score
                  ? userGreScore.quantitative_score
                  : '',
              gre_quantitative_rank:
                userGreScore && userGreScore.quantitative_rank
                  ? userGreScore.quantitative_rank
                  : '',
              gre_awa_score:
                userGreScore && userGreScore.awa_score
                  ? userGreScore.awa_score
                  : '',
              gre_awa_rank:
                userGreScore && userGreScore.awa_rank
                  ? userGreScore.awa_rank
                  : '',
              gre_date_of_exam:
                userGreScore && userGreScore.date_of_exam
                  ? moment(userGreScore.date_of_exam).format('YYYY-MM-DD')
                  : '',
              gre_expiry_date:
                userGreScore && userGreScore.expiry_date
                  ? moment(userGreScore.expiry_date).format('YYYY-MM-DD')
                  : '',

              is_gmat_selected:
                userGmatScore && userGmatScore.have_given_test ? true : false,
              gmat_total_scores:
                userGmatScore && userGmatScore.total_score
                  ? userGmatScore.total_score
                  : '',
              gmat_total_rank:
                userGmatScore && userGmatScore.total_rank
                  ? userGmatScore.total_rank
                  : '',
              gmat_verbal_score:
                userGmatScore && userGmatScore.verbal_score
                  ? userGmatScore.verbal_score
                  : '',
              gmat_verbal_rank:
                userGmatScore && userGmatScore.verbal_rank
                  ? userGmatScore.verbal_rank
                  : '',
              gmat_quantitative_score:
                userGmatScore && userGmatScore.quantitative_score
                  ? userGmatScore.quantitative_score
                  : '',
              gmat_quantitative_rank:
                userGmatScore && userGmatScore.quantitative_rank
                  ? userGmatScore.quantitative_rank
                  : '',
              gmat_awa_score:
                userGmatScore && userGmatScore.awa_score
                  ? userGmatScore.awa_score
                  : '',
              gmat_awa_rank:
                userGmatScore && userGmatScore.awa_score
                  ? userGmatScore.awa_score
                  : '',
              gmat_date_of_exam:
                userGmatScore && userGmatScore.date_of_exam
                  ? moment(userGmatScore.date_of_exam).format('YYYY-MM-DD')
                  : '',
              gmat_expiry_date:
                userGmatScore && userGmatScore.expiry_date
                  ? moment(userGmatScore.expiry_date).format('YYYY-MM-DD')
                  : '',
            }}
            validationSchema={Yup.object({
              gre_verbal_score: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gre_verbal_rank: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gre_quantitative_score: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gre_quantitative_rank: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gre_awa_score: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gre_awa_rank: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gre_date_of_exam: Yup.string().when('is_gre_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_verbal_score: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_verbal_rank: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_quantitative_score: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_quantitative_rank: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_awa_score: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_awa_rank: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_date_of_exam: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_total_scores: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              gmat_total_rank: Yup.string().when('is_gmat_selected', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateGreGmatInformation(student._id, values);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          checked={formik.values.is_gmat_selected}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'is_gmat_selected',
                              !formik.values.is_gmat_selected
                            );
                          }}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='gmat_score'
                        >
                          I have GMAT exam scores
                        </label>
                      </div>
                      {formik.values.is_gmat_selected && (
                        <>
                          <div className='give-exam'>
                            <div className='row'>
                              <div className='main-label'>Total</div>
                              <div className='col-md-6'>
                                <TextInput
                                  type='number'
                                  label='Score'
                                  name='gmat_total_scores'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Rank'
                                  type='number'
                                  name='gmat_total_rank'
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='main-label'>Verbal</div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Score'
                                  type='number'
                                  name='gmat_verbal_score'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Rank'
                                  type='number'
                                  name='gmat_verbal_rank'
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='main-label'>Quantitative</div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Score'
                                  type='number'
                                  name='gmat_quantitative_score'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Rank'
                                  type='number'
                                  name='gmat_quantitative_rank'
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='main-label'>AWA</div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Score'
                                  type='number'
                                  name='gmat_awa_score'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Rank'
                                  type='number'
                                  name='gmat_awa_rank'
                                />
                              </div>
                              <div className='col-md-12'>
                                <TextInput
                                  label='Date of exam'
                                  name='gmat_date_of_exam'
                                  type='date'
                                />
                              </div>
                              <div className='col-md-12'>
                                <TextInput
                                  label='Expiry Date'
                                  name='gmat_expiry_date'
                                  type='date'
                                />
                              </div>
                              <div className='col-md-12'>
                                <GalleryUpload
                                  title='Upload Documents'
                                  item='gmat_documents'
                                  formik={formik}
                                  value={formik.values.gmat_documents}
                                  hideView={true}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className='col-md-12'>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id='gre_score'
                          checked={formik.values.is_gre_selected}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'total_score',
                              'I have GMAT exam scores'
                            );
                            formik.setFieldValue(
                              'is_gre_selected',
                              !formik.values.is_gre_selected
                            );
                          }}
                        />
                        <label className='form-check-label' htmlFor='gre_score'>
                          I have GRE exam scores
                        </label>
                      </div>
                      {formik.values.is_gre_selected && (
                        <div className='give-exam'>
                          <div className='row'>
                            <div className='main-label'>Verbal</div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Score'
                                type='number'
                                name='gre_verbal_score'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Rank'
                                type='number'
                                name='gre_verbal_rank'
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='main-label'>Quantitative</div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Score'
                                type='number'
                                name='gre_quantitative_score'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Rank'
                                type='number'
                                name='gre_quantitative_rank'
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='main-label'>AWA</div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Score'
                                type='number'
                                name='gre_awa_score'
                              />
                            </div>
                            <div className='col-md-6'>
                              <TextInput
                                label='Rank'
                                type='number'
                                name='gre_awa_rank'
                              />
                            </div>
                            <div className='col-md-12'>
                              <TextInput
                                label='Date of exam'
                                name='gre_date_of_exam'
                                type='date'
                              />
                            </div>
                            <div className='col-md-12'>
                              <TextInput
                                label='Expiry Date'
                                name='gre_expiry_date'
                                type='date'
                              />
                            </div>
                            <div className='col-md-12'>
                              <GalleryUpload
                                title='Upload Documents'
                                item='gre_documents'
                                formik={formik}
                                value={formik.values.gre_documents}
                                hideView={true}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='save-button'>
                    <button className='btn primary-btn w-100'>
                      Save & Continue
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            <h4> GMAT Scores </h4>
            <div className='row'>
              {userGmatScore && userGmatScore.have_given_test ? (
                <>
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 10,
                    }}
                  >
                    Total{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGmatScore && userGmatScore.total_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGmatScore && userGmatScore.total_rank}
                  />
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    Verbal{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGmatScore && userGmatScore.verbal_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGmatScore && userGmatScore.verbal_rank}
                  />
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    Quantitative{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGmatScore && userGmatScore.quantitative_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGmatScore && userGmatScore.quantitative_rank}
                  />
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    AWA{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGmatScore && userGmatScore.awa_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGmatScore && userGmatScore.awa_rank}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Date Of Exam'}
                    value={
                      userGmatScore &&
                      moment(userGreScore.date_of_exam).format('YYYY-MM-DD')
                    }
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Expiry Date'}
                    value={
                      userGmatScore &&
                      moment(userGreScore.expiry_date).format('YYYY-MM-DD')
                    }
                  />
                  <div className='col-md-12'>
                    <div> Documents </div>
                    {userGmatScore &&
                      userGmatScore.documents &&
                      userGmatScore.documents.map((item) => {
                        return (
                          <div>
                            <a href={`${URI}${item}`} target='_blank'>
                              {getDocumentName(item)}
                            </a>
                          </div>
                        );
                      })}
                  </div>
                  <hr />
                </>
              ) : (
                <div> GMAT Not Given </div>
              )}
            </div>
            <h4> GRE Scores </h4>
            <div className='row'>
              {userGreScore && userGreScore.have_given_test ? (
                <>
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    Verbal{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGreScore && userGreScore.verbal_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGreScore && userGreScore.verbal_rank}
                  />
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    Quantitative{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGreScore && userGreScore.quantitative_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGreScore && userGreScore.quantitative_rank}
                  />
                  <h6
                    style={{
                      borderBottom: '1px solid #f7f7f7',
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    AWA{' '}
                  </h6>
                  <SingleProfileComponent
                    col={6}
                    label={'Scores'}
                    value={userGreScore && userGreScore.awa_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Rank'}
                    value={userGreScore && userGreScore.awa_rank}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Date Of Exam'}
                    value={
                      userGreScore &&
                      moment(userGreScore.date_of_exam).format('YYYY-MM-DD')
                    }
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Expiry Date'}
                    value={
                      userGreScore &&
                      moment(userGreScore.expiry_date).format('YYYY-MM-DD')
                    }
                  />
                  <div className='col-md-12'>
                    <div> Documents </div>
                    {userGreScore &&
                      userGreScore.documents &&
                      userGreScore.documents.map((item) => {
                        return (
                          <div>
                            <a href={`${URI}${item}`} target='_blank'>
                              {getDocumentName(item)}
                            </a>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div> Gre Not Given </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
