import React from 'react';
import { URI } from '../../domain/constant';
import { DummySchool, SchoolLogo } from '../images';
import { Link } from 'react-router-dom';
function SingleSchoolCardFilterComponent({ school, hideStartApplication }) {
  return (
    <div className='application-full-requirement'>
      <div className='main-application-content-flex'>
        <div className='dummy-image'>
          <img src={school.logo ? `${URI}${school.logo}` : DummySchool} />
        </div>
        <div class='similar-card-content'>
          {school.name && <h5>{school.name}</h5>}
          {school.postal_code && (
            <div class='location-map'>
              <i class='fa fa-map-marker'></i>
              <span>
                {school.address} {school.city} {school.state} {school.country}{' '}
              </span>
              {school.postal_code}
            </div>
          )}

          <div class='similar-card-deadline-flex'>
            {school.founded_in && (
              <div>
                <h5>Founded</h5>
                <h6>{school.founded_in}</h6>
              </div>
            )}
            {school.school_id && (
              <div>
                <h5>School ID</h5>
                <h6>{school.school_id}</h6>
              </div>
            )}
            {school.institution_type && (
              <div>
                <h5>Institution type</h5>
                <h6>{school.institution_type}</h6>
              </div>
            )}
            {school.status && (
              <div>
                <h5>Status</h5>
                <h6>{school.status}</h6>
              </div>
            )}
          </div>
          <div>
            <Link
              to={`${hideStartApplication ? '/website' : ''}/schools/${
                school._id
              }`}
              className='btn primary-btn w-50  '
              target={'_blank'}
            >
              School Details <i className='fa fa-angle-right'></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleSchoolCardFilterComponent;
