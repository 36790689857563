export const URI = process.env.REACT_APP_URI;
export const UPLOAD_CONST = '';

export const getDocumentName = (filePath) => {
  if (filePath && filePath.length > 0) {
    let parts = filePath.split('/');
    let filename = parts[parts.length - 1];
    let finalName = filename.split('-').slice(1).join('-');
    return finalName;
  } else {
    return filePath;
  }
};
