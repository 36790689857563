import {
  GET_PERMISSIONS_STARTED,
  GET_PERMISSIONS,
  GET_PERMISSIONS_ENDED,
  ADD_PERMISSION_STARTED,
  ADD_PERMISSION,
  ADD_PERMISSION_ENDED,
  EDIT_PERMISSION_STARTED,
  EDIT_PERMISSION,
  EDIT_PERMISSION_ENDED,
  GET_PERMISSION,
  GET_PERMISSION_STARTED,
  GET_PERMISSION_ENDED,
} from '../types/permission_types';

const initialState = {
  permissions_loading: true,
  permissions: null,
  permission_page: null,
  permission_pages: null,
  total_permissions: 0,

  permission: null,
  permission_loading: null,

  add_permission_loading: true,
  edit_permission_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PERMISSIONS_STARTED:
      return {
        ...state,
        permissions: null,
        permission_pages: null,
        permission_page: null,
        total_permissions: 0,
        permissions_loading: true,
      };
    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: payload.permissions,
        permission_pages: payload.pages,
        permission_page: payload.page,
        total_permissions: payload.total_permissions,
      };
    case GET_PERMISSIONS_ENDED:
      return {
        ...state,
        permissions_loading: false,
      };

    case ADD_PERMISSION_STARTED:
      return {
        ...state,
        permission_message: null,
        add_permission_loading: true,
      };
    case ADD_PERMISSION:
      return {
        ...state,
        permission_message: payload,
      };
    case ADD_PERMISSION_ENDED:
      return {
        ...state,
        add_permission_loading: false,
      };
    case GET_PERMISSION_STARTED:
      return {
        ...state,
        permission: null,
        permission_loading: true,
      };
    case GET_PERMISSION:
      return {
        ...state,
        permission: payload,
      };
    case GET_PERMISSION_ENDED:
      return {
        ...state,
        permission_loading: false,
      };
    case EDIT_PERMISSION_STARTED:
      return {
        ...state,
        permission_message: null,
        edit_permission_loading: true,
      };
    case EDIT_PERMISSION:
      return {
        ...state,
        permission_message: payload,
      };
    case EDIT_PERMISSION_ENDED:
      return {
        ...state,
        edit_permission_loading: false,
      };

    default:
      return state;
  }
}
