import React, { useState } from 'react';
import Tooltip from '../Tooltip';
import {
  MissingApplication,
  NotApprovedApplication,
  PrepaymentApprovedApplication,
  ReviewApplication,
} from '../images';
function OthersSubmission() {
  const [show, setShow] = useState(true);
  const [seemore, setSeemore] = useState(false);
  return (
    <div className="box-show-and-hide">
      <div className="pre-payment-box">
        <div className="pre-payment-heading-flex">
          <div className="pre-payment-heading">
            <h4>Others</h4>
            <p>Last requirement completed on Jan. 31, 2023</p>
          </div>
          <div className="review-status">
            <div className="box-flex">
              <div id="prepayment-missing" className="box-main-box color1">
                <img src={MissingApplication} /> 0
              </div>
              <div id="prepayment-reviewing" className="box-main-box color2">
                <img src={ReviewApplication} />0
              </div>
              <div id="prepayment-notapproved" className="box-main-box color3">
                <img src={NotApprovedApplication} />0
              </div>
              <div id="prepayment-completed" className="box-main-box color4">
                <img src={PrepaymentApprovedApplication} />0
              </div>

              <div
                onClick={() => {
                  setShow(!show);
                }}
              >
                <i className={show ? 'fa fa-minus' : 'fa fa-plus'}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pre-payment-card-required"
        style={{ display: show ? 'block' : 'none' }}
      >
        <div
          className={
            seemore
              ? 'icon-with-flex-pre-payment  '
              : 'icon-with-flex-pre-payment dro-height '
          }
        >
          <div className="text-center nostatus">
            <i className="fa fa-ban fa-lg"></i>
            <span className="badge ">no status</span>
          </div>
          <div className="pre-payment-heading">
            <span class="badge optional">Optional</span>
            <h3>Electronic Score Submission Disclaimer</h3>
            <h5>
              Please be advised this application may require official test
              scores to be sent electronically to the school.
            </h5>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
          </div>
          <div
            className="see-more-button"
            onClick={() => {
              setSeemore(!seemore);
            }}
          >
            <button className="badge see-more">
              {seemore ? 'show Less' : 'show More'}
              <i
                className={seemore ? 'fa fa-angle-up' : 'fa fa-angle-down'}
              ></i>
            </button>
          </div>
        </div>
      </div>
      <Tooltip field={'others'} />
    </div>
  );
}

export default OthersSubmission;
