import React from 'react';
import Slider from 'react-slick';
import { Author1, TestimonialUserImage } from '../../../components/images';

function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,

    dotsClass: 'slick-dots slick-dots-black',
    adaptiveHeight: true,
    slidesToShow: 3,

    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonials mb-5  ">
        <div className="container">
          <div className="testimonials-four-iamge pt-130 rpt-100 pb-75">
            <img src={TestimonialUserImage} alt="Testimonial" />
          </div>
          <div className="row">
            <div className="main-slider">
              <Slider {...settings}>
                <div className="">
                  <div className="testimonial-box">
                    <div className="testimonial-image-flex">
                      {/* <div className="author-image">
                        <img src={Author1} />
                      </div> */}
                      <div className="author-name">
                        <h3>Nishant Tripathi</h3>
                        {/* <span>Website Developer</span> */}
                      </div>
                    </div>
                    <div className="author-content">
                      <p>
                        I highly recommend Visa Application Service Provider to
                        anyone seeking an easy and hassle-free visa application
                        experience. Their commitment to providing a seamless
                        process, combined with their excellent customer support,
                        sets them apart.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonial-box">
                    <div className="testimonial-image-flex">
                      {/* <div className="author-image">
                        <img src={Author1} />
                      </div> */}
                      <div className="author-name">
                        <h3>Abida Javed</h3>
                        {/* <span>Website Developer</span> */}
                      </div>
                    </div>
                    <div className="author-content">
                      <p>
                        "Studying abroad has always been a dream of mine, but
                        navigating the visa process seemed like a daunting task.
                        Thankfully, I came across a visa consultancy service
                        that made the entire process incredibly smooth and
                        stress-free.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonial-box">
                    <div className="testimonial-image-flex">
                      {/* <div className="author-image">
                        <img src={Author1} />
                      </div> */}
                      <div className="author-name">
                        <h3>Jaspreet Singh</h3>
                        {/* <span>Website Developer</span> */}
                      </div>
                    </div>
                    <div className="author-content">
                      <p>
                        I would like to express my deepest appreciation to the
                        visa application team for their exceptional assistance
                        in securing my study visa. They not only provided expert
                        guidance but also went the extra mile to ensure that my
                        application stood out.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonial-box">
                    <div className="testimonial-image-flex">
                      {/* <div className="author-image">
                        <img src={Author1} />
                      </div> */}
                      <div className="author-name">
                        <h3>Shubham Pathak</h3>
                        {/* <span>Website Developer</span> */}
                      </div>
                    </div>
                    <div className="author-content">
                      <p>
                        "I cannot express enough gratitude towards the visa
                        service I used for my study abroad journey. From the
                        moment I contacted them, they provided me with detailed
                        information about the visa requirements, the application
                        procedure, and the necessary documents. They
                        meticulously reviewed my application to ensure
                        everything was in order, leaving no room for mistakes or
                        delays.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
