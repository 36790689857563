import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addApplication,
  getApplications,
  getApplication,
  editApplication,
  deleteApplication,
  assignUserToApplication,
  changeStatusApplication,
  answerApplicationRequirements,
  answerApplicationRequirementsChangeStage,
  changeMarkApplicationAsPaid,
  allApplicationPayments,
  markCommissionAsPaidAgent,
  markCommissionAsPaidFromCollege,
  changeCommissionAmountAgent,
  changeCommissionAmountEdchimp,
  closeApplication,
  assignProgramInApplication,
} from '../../store/actions/application_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { addApplicationRequirementAnswer } from '../../store/actions/application_requirement_action';
import { api } from '../../domain/api';
// Add Data
export const useCreateApplication = () => {
  const dispatch = useDispatch();
  const application_data = useSelector((state) => state.application);
  const addApplicationData = async (data) => {
    dispatch(addApplication(data));
  };
  return { application_data, addApplicationData };
};

// All Application Payments
export const useAllApplicationPayments = () => {
  const dispatch = useDispatch();
  const application_data = useSelector((state) => state.application);
  const { all_application_payments_loading, all_application_payments } =
    application_data;
  const allApplicationPaymentHandler = async () => {
    dispatch(allApplicationPayments());
  };
  return {
    allApplicationPaymentHandler,
    all_application_payments_loading,
    all_application_payments,
  };
};

// Add Data
export const useCreateApplicationRequirement = () => {
  const dispatch = useDispatch();
  const application_data = useSelector((state) => state.application);
  const addApplicationRequirementFunction = async (id, data) => {
    dispatch(addApplicationRequirementAnswer(id, data));
  };
  return { application_data, addApplicationRequirementFunction };
};

// Get Single Data
export const useSingleApplication = (id) => {
  const dispatch = useDispatch();
  const application_data = useSelector((state) => state.application);
  useEffect(() => {
    dispatch(getApplication(id));
  }, [id]);
  return { application_data };
};

//Edit Data
export const useUpdateApplication = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const updateApplicationData = async (id, data) => {
    dispatch(editApplication(id, data));
  };
  return { updateApplicationData };
};
export const useAssignUserToApplication = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const updateApplicationData = async (id, data) => {
    dispatch(assignUserToApplication(id, data));
  };
  return { updateApplicationData };
};
export const useChangeStatusApplication = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const updateApplicationStatusData = async (id, data) => {
    dispatch(changeStatusApplication(id, data));
  };
  return { updateApplicationStatusData };
};
export const useCloseApplication = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const closeApplicationHandler = async (id, data) => {
    dispatch(closeApplication(id, data));
  };
  return { closeApplicationHandler };
};
export const useAssignProgramInApplication = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const assignProgramInApplicationHandler = async (id, data) => {
    dispatch(assignProgramInApplication(id, data));
  };
  return { assignProgramInApplicationHandler };
};
export const useMarkApplicationAsPaid = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const updateApplicationStatusData = async (id, data) => {
    dispatch(changeMarkApplicationAsPaid(id, data));
  };
  return { updateApplicationStatusData };
};

export const useAllApplicationsReport = () => {
  const [loading_report, setLoading_report] = useState(false);
  const [reportData, setReportData] = useState(null);
  const getData = async () => {
    setLoading_report(true);
    const { data } = await api.get('/applications/export-applications');
    setReportData(data);
    setLoading_report(false);
  };
  return {
    reportData,
    getData,
    loading_report,
  };
};

// Get All Data
export const useAllApplications = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const application_data = useSelector((state) => state.application);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getApplications({}));
    }, 1000),
    []
  );

  return { application_data, setPageNumber };
};

//Delete Data
export const useDeleteApplication = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const deleteApplicationFunction = async (id) => {
    dispatch(deleteApplication(id));
  };
  return { deleteApplicationFunction };
};

//Answer Requirement
export const useAnswerApplicationRequirement = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const answerApplicationRequirementSubmit = async (id, data) => {
    dispatch(answerApplicationRequirements(id, data));
  };
  return { answerApplicationRequirementSubmit };
};

//Answer Stage Changes
export const useChangeStageAnswerApplicationRequirement = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Application);
  const changeStageApplicationRequirementSubmit = async (id, data) => {
    dispatch(answerApplicationRequirementsChangeStage(id, data));
  };
  return { changeStageApplicationRequirementSubmit };
};

// ChangeCommissionAmountEdchimp,
export const useChangeCommissionAmountEdchimp = () => {
  const dispatch = useDispatch();
  const changeCommissionAmountEdchimpFunction = async (id, data) => {
    dispatch(changeCommissionAmountEdchimp(id, data));
  };
  return { changeCommissionAmountEdchimpFunction };
};
// ChangeCommissionAmountAgent,
export const useChangeCommissionAmountAgent = () => {
  const dispatch = useDispatch();
  const changeCommissionAmountAgentFunction = async (id, data) => {
    dispatch(changeCommissionAmountAgent(id, data));
  };
  return { changeCommissionAmountAgentFunction };
};
// MarkCommissionAsPaidFromCollege,
export const useMarkCommissionAsPaidFromCollege = () => {
  const dispatch = useDispatch();
  const markCommissionAsPaidFromCollegeFunction = async (id, data) => {
    dispatch(markCommissionAsPaidFromCollege(id, data));
  };
  return { markCommissionAsPaidFromCollegeFunction };
};
// MarkCommissionAsPaidAgent,
export const useMarkCommissionAsPaidAgent = () => {
  const dispatch = useDispatch();
  const markCommissionAsPaidAgentFunction = async (id, data) => {
    dispatch(markCommissionAsPaidAgent(id, data));
  };
  return { markCommissionAsPaidAgentFunction };
};
