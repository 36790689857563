import { api } from '../../domain/api';

export const UseBulkUpload = () => {
  const handleSubmitForm = async (formData) => {
    try {
      const { data } = await api.post('/bulks/add', formData);
      return data;
    } catch (error) {
      console.log('Errr', error);
    }
  };
  return { handleSubmitForm };
};

export const UseAllBulkUpload = () => {
  const handleAllSubmitForm = async (formData) => {
    try {
      const { data } = await api.post('/bulks/add-all', formData);
      return data;
    } catch (error) {
      console.log('Errr', error);
    }
  };
  return { handleAllSubmitForm };
};
