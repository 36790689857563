import React from 'react';

import AgentStats from '../../components/AgentDashboard/AgentStats';
import RecentalyOpenedProgram from '../../components/AgentDashboard/RecentalyOpenedProgram';
import OutstandingTask from '../../components/AgentDashboard/OutstandingTask';
import { useCurrentAgent } from '../../shared/hooks/UseAgent';
import { Link } from 'react-router-dom';
import DashboardIconBoxComponent from '../../components/dashboard/DashboardIconBoxComponent';
import { useAllDashboard } from '../../shared/hooks/UseDashboard';
import { URI } from '../../domain/constant';
import { getInitialName } from '../../shared/helpers/getIntialValue';

function DashboardStudent({ user, hide }) {
  const { dashboard } = useAllDashboard();
  return (
    <>
      <section className='profile-main ptb-50'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='main-sec-heading'>
                <h1>
                  Welcome {user?.first_name} {user?.last_name}
                </h1>
              </div>
            </div>
          </div>
          <div className='row mt-50'>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-file-text-o'}
                title={'Total Applications'}
                value={
                  dashboard && dashboard.totalApplications
                    ? dashboard.totalApplications
                    : '-'
                }
                link={'/my-applications'}
              />
            </div>

            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-thumbs-o-up'}
                title={'Total Visa Issued Applications'}
                value={
                  dashboard && dashboard.totalVisaIssuedApplications
                    ? dashboard.totalVisaIssuedApplications
                    : '-'
                }
                link={'/my-applications?exact[is_application_fee_paid]=false'}
              />
            </div>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-pencil-square-o'}
                title={'Paid Applications'}
                value={
                  dashboard && dashboard.totalVisaIssuedApplications
                    ? dashboard.totalPaidApplications
                    : '-'
                }
                link={'/my-applications?exact[is_application_fee_paid]=true'}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DashboardStudent;
