import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import {
  forgetPassword,
  login,
  logout,
  register,
  registerAsAgent,
  resetPassword,
} from '../../store/actions/auth_action';
import { RootState } from '../../store/store';

export const useRegisterUser = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth_reducer);
  const registerUser = async (data) => {
    dispatch(register(data));
  };
  return [registerUser];
};
export const useRegisterAsAgent = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth_reducer);
  const registerAgent = async (data) => {
    dispatch(registerAsAgent(data));
  };
  return { registerAgent };
};

export const useForgetPassword = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  const forgetUserPassword = async (data) => {
    await dispatch(forgetPassword(data));
  };
  return [forgetUserPassword];
};
export const useResetPassword = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  const resetUserPassword = async (data) => {
    await dispatch(resetPassword(data));
  };
  return [resetUserPassword];
};

export const useCheckAuthenticated = () => {
  const data = useSelector((state) => state.auth_reducer);
  return data;
};

export const useLoginUser = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth_reducer);
  const loginUser = async (data) => {
    dispatch(login(data));
  };
  return [loginUser];
};

export const useLogout = () => {
  const dispatch = useDispatch();

  const logoutUser = async () => {
    dispatch(logout());
  };
  return { logoutUser };
};
