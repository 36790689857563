import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import { BreadCrumb, Footer, Header } from '../../../components/common';
import { TextInput } from '../../../components/Form/Form';

function ContactUs() {
  return (
    <div>
      <Header />
      <section className='contact-us ptb-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='contact-section'>
                <h2>Contact US</h2>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-md-3'>
              <div className='contact-box'>
                <div className='emil-box'>
                  <div className='email-circle'>
                    <i className='fa fa-envelope'></i>
                  </div>
                  <h5 className='mb-2' style={{ color: '#3b3b3b' }}>
                    Email
                  </h5>
                  <hr></hr>
                  <div>
                    <a href='mailto:connect@edchimp.com'>connect@edchimp.com</a>
                    <p>Please email us with your inquiries.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='contact-box'>
                <div className='emil-box'>
                  <div className='email-circle'>
                    <i className='fa fa-map-marker'></i>
                  </div>
                  <div>
                    <h5 className='mb-2' style={{ color: '#3b3b3b' }}>
                      Australia (HO)
                    </h5>
                    <hr></hr>
                    <a
                      href='https://goo.gl/maps/hsRx1tvrcQw63uYB9'
                      target='blank'
                    >
                      {' '}
                      Level 1, 76 Emu Bank, Belconnen ACT 2617 Australia
                    </a>
                    <p className='num'>
                      Call Us:<a href='tel:+61488048870'>{''}+61 488048870 </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='contact-box'>
                <div className='emil-box'>
                  <div className='email-circle'>
                    <i className='fa fa-map-marker'></i>
                  </div>
                  <div>
                    <h5 className='mb-2' style={{ color: '#3b3b3b' }}>
                      UAE
                    </h5>
                    <hr></hr>
                    <a
                      href='https://goo.gl/maps/zKrX6FdzmHHLKfaSA'
                      target='blank'
                    >
                      Sharjah Media City, Sharjah, UAE
                    </a>
                    <p className='num'>
                      Call Us:
                      <a href='tel:+971563608269 '>{''}+971 563608269 </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='contact-box'>
                <div className='emil-box'>
                  <div className='email-circle'>
                    <i className='fa fa-map-marker'></i>
                  </div>
                  <div>
                    <h5 className='mb-2' style={{ color: '#3b3b3b' }}>
                      India
                    </h5>
                    <hr></hr>
                    <a
                      href='https://goo.gl/maps/4MaL8AamAXiDrFmm8'
                      target='blank'
                    >
                      {' '}
                      Level 1, Suite F, Everest House, 46C, Chowringee Rd,
                      Kankaria Estates, Elgin, Kolkata, West Bengal 700071
                    </a>
                    <p className='num'>
                      Call Us:
                      <a href='tel:+919729072188 '>{''}+91-972-907-2188 </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='social-style-one footer-s'>
              <a
                href='https://www.facebook.com/EdChimp-119829547717279'
                target='_blank'
              >
                <i className='fa fa-facebook-f' />
              </a>
              <a
                href='https://instagram.com/edchimp_?igshid=MzRlODBiNWFlZA=='
                target='_blank'
              >
                <i className='fa fa-instagram' />
              </a>
              <a href='https://www.youtube.com/@edchimp' target='_blank'>
                <i className='fa fa-youtube' />
              </a>
              <a href='mailto:connect@edchimp.com' target='_blank'>
                <i class='fa fa-envelope'></i>
              </a>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-md-6'>
              <div className='contact-form'>
                <h2>Contact Us</h2>
                <p>
                  Complete the form for your query, and our team will be in
                  touch shortly. Thank you for reaching out.
                </p>
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required('Required'),
                    email: Yup.string().email().required('Required'),
                    phone: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    // loginUser(values);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className='form-group'>
                          <TextInput label='Name' name='name' />
                        </div>
                        <div className='form-group'>
                          <TextInput label='Email' name='email' />
                        </div>
                        <div className='form-group'>
                          <TextInput label='Phone' name='phone' />
                        </div>
                        <div className='form-group'>
                          <TextInput label='Message' name='message' />
                        </div>

                        <div className='create-button'>
                          <button
                            className='btn primary-btn w-100'
                            type='submit'
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='map'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3258.6603697454198!2d149.07482439999998!3d-35.239823699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b17ad6ecfec9667%3A0xc1bfad04284f4b59!2sTen%20Pin%20Bowl%2C%201%20Emu%20Bank%2C%20Belconnen%20ACT%202617%2C%20Australia!5e0!3m2!1sen!2sin!4v1692167455334!5m2!1sen!2sin'
                  width='100%'
                  height={500}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ContactUs;
