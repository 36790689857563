import React from 'react';
import SchoolGallery from './SchoolGallery';

function ZoomGalleryComponent({ school, showFullGallery, setShowFullGallery }) {
  return (
    <div className='view-photos'>
      <div
        className='close-ban'
        onClick={() => {
          setShowFullGallery(false);
        }}
      >
        <i className='fa fa-close'></i>
      </div>

      <SchoolGallery
        school={school}
        customSettings={{ slidesToScroll: 1, slidesToShow: 1 }}
      />
    </div>
  );
}

export default ZoomGalleryComponent;
