import { useDispatch } from 'react-redux';
import { updateEducationInfo } from '../../store/actions/education_action';

export const useUpdateEducationInfo = (student_id, data) => {
  const dispatch = useDispatch();

  const updateEducation = async (id, values) => {
    dispatch(updateEducationInfo(id, values));
  };

  return {
    updateEducation,
  };
};
