import { setAlert } from '../store/actions/alert_action';
import { logout } from '../store/actions/auth_action';
export const handleError = (err) => async (dispatch) => {
  if (err.response && err.response.data) {
    const errors = err.response.data.message;
    if (errors === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch(setAlert(errors, 'danger'));
  }

  if (
    err.response &&
    err.response.data &&
    err.response.data.errors.length > 0
  ) {
    err.response.data.errors.map((item) => {
      dispatch(setAlert(item.message, 'danger'));
    });
  }
};
