import {
  GET_SLABS_STARTED,
  GET_SLABS,
  GET_SLABS_ENDED,
  ADD_SLAB_STARTED,
  ADD_SLAB,
  ADD_SLAB_ENDED,
  EDIT_SLAB_STARTED,
  EDIT_SLAB,
  EDIT_SLAB_ENDED,
  GET_SLAB,
  GET_SLAB_STARTED,
  GET_SLAB_ENDED,
} from '../types/slab_types';

const initialState = {
  slabs_loading: true,
  slabs: null,
  slab_page: null,
  slabs_pages: null,
  total_slabs: 0,

  slab: null,
  slab_loading: null,

  add_slab_loading: true,
  edit_slab_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SLABS_STARTED:
      return {
        ...state,
        slabs: null,
        slab_pages: null,
        slab_page: null,
        total_slabs: 0,
        slabs_loading: true,
      };
    case GET_SLABS:
      return {
        ...state,
        slabs: payload.slabs,
        slab_pages: payload.pages,
        slab_page: payload.page,
        total_slabs: payload.total_slabs,
      };
    case GET_SLABS_ENDED:
      return {
        ...state,
        slabs_loading: false,
      };

    case ADD_SLAB_STARTED:
      return {
        ...state,
        slab_message: null,
        add_slab_loading: true,
      };
    case ADD_SLAB:
      return {
        ...state,
        slab_message: payload,
      };
    case ADD_SLAB_ENDED:
      return {
        ...state,
        add_slab_loading: false,
      };
    case GET_SLAB_STARTED:
      return {
        ...state,
        slab: null,
        slab_loading: true,
      };
    case GET_SLAB:
      return {
        ...state,
        slab: payload,
      };
    case GET_SLAB_ENDED:
      return {
        ...state,
        slab_loading: false,
      };
    case EDIT_SLAB_STARTED:
      return {
        ...state,
        slab_message: null,
        edit_slab_loading: true,
      };
    case EDIT_SLAB:
      return {
        ...state,
        slab_message: payload,
      };
    case EDIT_SLAB_ENDED:
      return {
        ...state,
        edit_slab_loading: false,
      };

    default:
      return state;
  }
}
