import React from 'react';

import { Formik, Form } from 'formik';
import {
  TextInput,
  SelectBox,
  CheckBox,
  TextArea,
  HTMLEditor,
} from '../Form/Form';

function DisciplineFormComponent({ formik, dropdown_options }) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput label='Name' name='name' type='text' />
        </div>
        <div className='col-md-6'>
          <SelectBox label='Parent' name='parent'>
            <option value=''></option>
            {dropdown_options &&
              dropdown_options
                .filter((item) => !item.parent)
                .map((item) => {
                  return <option value={item._id}> {item.name} </option>;
                })}
          </SelectBox>
        </div>
      </div>

      <div className='create-button'>
        <button type='submit' className='btn primary-btn'>
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default DisciplineFormComponent;
