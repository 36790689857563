import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addApplicationRequirement,
  getApplicationRequirements,
  getApplicationRequirement,
  editApplicationRequirement,
  deleteApplicationRequirement,
  getApplicationRequirementByProgram,
} from '../../store/actions/application_requirement_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreateApplicationRequirement = () => {
  const dispatch = useDispatch();
  const application_requirement_data = useSelector(
    (state) => state.application_requirement
  );
  const addApplicationRequirementData = async (data) => {
    dispatch(addApplicationRequirement(data));
  };
  return { application_requirement_data, addApplicationRequirementData };
};

// Get Single Data
export const useSingleApplicationRequirement = (id) => {
  const dispatch = useDispatch();
  const application_requirement_data = useSelector(
    (state) => state.application_requirement
  );
  useEffect(() => {
    dispatch(getApplicationRequirement(id));
  }, [id]);
  return { application_requirement_data };
};

// Get Single Data
export const useApplicationRequirementsByProgram = (id) => {
  const dispatch = useDispatch();
  const application_requirement_data = useSelector(
    (state) => state.application_requirement
  );
  useEffect(() => {
    dispatch(getApplicationRequirementByProgram(id));
  }, [id]);
  return { application_requirement_data };
};

//Edit Data
export const useUpdateApplicationRequirement = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.ApplicationRequirement);
  const updateApplicationRequirementData = async (id, data) => {
    dispatch(editApplicationRequirement(id, data));
  };
  return { updateApplicationRequirementData };
};

// Get All Data
export const useAllApplicationRequirements = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const application_requirement_data = useSelector(
    (state) => state.application_requirement
  );
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getApplicationRequirements({}));
    }, 1000),
    []
  );

  return { application_requirement_data, setPageNumber };
};

//Delete Data
export const useDeleteApplicationRequirement = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.ApplicationRequirement);
  const deleteApplicationRequirementFunction = async (id) => {
    dispatch(deleteApplicationRequirement(id));
  };
  return { deleteApplicationRequirementFunction };
};
