import {
  GET_ACADEMIC_PARTNERS_STARTED,
  GET_ACADEMIC_PARTNERS,
  GET_ACADEMIC_PARTNERS_ENDED,
  ADD_ACADEMIC_PARTNER_STARTED,
  ADD_ACADEMIC_PARTNER,
  ADD_ACADEMIC_PARTNER_ENDED,
  EDIT_ACADEMIC_PARTNER_STARTED,
  EDIT_ACADEMIC_PARTNER,
  EDIT_ACADEMIC_PARTNER_ENDED,
  GET_ACADEMIC_PARTNER,
  GET_ACADEMIC_PARTNER_STARTED,
  GET_ACADEMIC_PARTNER_ENDED,
} from '../types/academic_partner_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addCrm_user
export const addAcademicPartner = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ACADEMIC_PARTNER_STARTED,
    });
    const { data } = await api.post(`/academic-partners/add`, formData);
    dispatch({
      type: ADD_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: ADD_ACADEMIC_PARTNER_ENDED,
    });
    dispatch(setAlert('Crm_user added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_ACADEMIC_PARTNER_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getAcademicPartners =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ACADEMIC_PARTNERS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/academic-partners?&${query}`);

      dispatch({
        type: GET_ACADEMIC_PARTNERS,
        payload: data,
      });
      dispatch({
        type: GET_ACADEMIC_PARTNERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ACADEMIC_PARTNERS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
// Get CRM User Filter
export const getAcademicPartnersFilter =
  ({ value = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ACADEMIC_PARTNERS_STARTED,
      });
      let searchUrl = ``;
      if (value) {
        searchUrl = `/academic-partners?&search[first_name]=${value}`;
      } else {
        searchUrl = `/academic-partners`;
      }
      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_ACADEMIC_PARTNERS,
        payload: data,
      });
      dispatch({
        type: GET_ACADEMIC_PARTNERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ACADEMIC_PARTNERS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const getAcademicPartner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACADEMIC_PARTNER_STARTED,
    });
    const { data } = await api.get(`/academic-partners/${id}`);

    dispatch({
      type: GET_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getCrm_userBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACADEMIC_PARTNER_STARTED,
    });
    const { data } = await api.get(`/academic-partners/slug/${slug}`);

    dispatch({
      type: GET_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editAcademicPartner = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_STARTED,
    });
    const { data } = await api.put(`/academic-partners/${id}`, formData);
    dispatch({
      type: EDIT_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_ENDED,
    });
    dispatch(setAlert('Crm_user Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const updatePasswordAcademicPartner =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_ACADEMIC_PARTNER_STARTED,
      });
      const { data } = await api.put(
        `/academic-partners/${id}/change-password`,
        formData
      );
      dispatch({
        type: EDIT_ACADEMIC_PARTNER,
        payload: data,
      });
      dispatch({
        type: EDIT_ACADEMIC_PARTNER_ENDED,
      });
      dispatch(setAlert('Crm_user Edited Successfully', 'success'));
    } catch (error) {
      dispatch({
        type: EDIT_ACADEMIC_PARTNER_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const draftAcademicPartner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_STARTED,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_STARTED,
    });
    const { data } = await api.put(`/academic-partners/${id}/draft`);
    dispatch({
      type: EDIT_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER,
      payload: data,
    });

    dispatch({
      type: EDIT_ACADEMIC_PARTNER_ENDED,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_ENDED,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateAcademicPartner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_STARTED,
    });
    const { data } = await api.put(`/academic-partners/${id}/active`);
    dispatch({
      type: EDIT_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_STARTED,
    });
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_ENDED,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER,
      payload: data,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });
    dispatch(setAlert('Crm_user Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_ACADEMIC_PARTNER_ENDED,
    });
    dispatch({
      type: GET_ACADEMIC_PARTNER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteAcademicPartner = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/academic-partners/${id}`);
    dispatch(setAlert('User Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
