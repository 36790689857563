import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SelectBox } from '../Form/Form';
import { Link } from 'react-router-dom';
import EligibilityFormComponent from './EligibilityFormComponent';

import SchoolEligibilityFormComponent from './SchoolsEligibilityFormComponent';
import ProgramsEligibilityFormComponent from './ProgramsEligibilityFormComponent';
import { links } from '../../domain/links.enum';
import SimilarProgramComponent from '../programs/SimilarProgramComponent';
import AllSchoolFilterComponent from './AllSchoolFilterComponent';
import AllProgramsTab from './AllProgramsTab';
import SidebarFilter from '../common/SidebarFilter';
import { SIDEBAR_OPTIONS } from '../../shared/enums/schools_enum';
import SearchFilterComponent from '../common/SearchFilterComponent';
function SearchProgramAndSchoolComponent({
  programs,
  programs_loading,
  schools,
  schools_loading,
  program_pages,
  total_programs,
  hideStartApplication,
}) {
  const [toggle, setToggle] = useState('PROGRAM');
  const [eligibility, setEligibility] = useState(true);
  const [schoolFilter, setSchoolFilter] = useState(true);
  const [programFilter, setProgramFilter] = useState(true);

  const options = [
    { value: 'I do not have this', label: 'I do not have this' },
    { value: 'USA F1 Visa', label: 'USA F1 Visa' },
    {
      value: 'UK Student Visa (Tier 4) or Short Term Study Visa ',
      label: 'UK Student Visa (Tier 4) or Short Term Study Visa',
    },
  ];
  return (
    <>
      <section className='search_program_school'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='eligibility-box'>
                <div className='search-box'>
                  {/* <form className='form-search' method='get' action='#'>
                    <input
                      type='search'
                      name='search'
                      placeholder='What would you like to study'
                    />
                    <button type='submit'>Search</button>
                  </form> */}
                </div>
                {/* eligibility */}
                {/* <div className='eligibility-form'>
                  <div
                    className='eligibility-flex'
                    onClick={() => setEligibility(!eligibility)}
                  >
                    <div>
                      <h5>Eligibility</h5>
                    </div>
                    <div>
                      <i
                        className={
                          eligibility
                            ? 'fa fa-chevron-down'
                            : 'fa fa-chevron-up'
                        }
                      ></i>
                    </div>
                  </div>
                  <div
                    className=''
                    style={{ display: eligibility ? 'block' : 'none' }}
                  >
                    <Formik
                      initialValues={{
                        first_name: '',
                        last_name: '',
                        phone: '',
                        email: '',
                        password: '',
                        nationality: '',
                        country_code: '',
                        Confirm_password: '',
                      }}
                      validationSchema={Yup.object({
                        first_name: Yup.string().required('Required'),
                        last_name: Yup.string().required('Required'),
                        phone: Yup.number()
                          .typeError('should be a number.')
                          .required('Required'),
                        email: Yup.string().email().required('Required'),
                        password: Yup.string()
                          .required('Required')
                          .min(
                            4,
                            ({ min }) =>
                              `Password must be at least ${min} characters`
                          )
                          .max(
                            20,
                            ({ max }) =>
                              `Password must be at least ${max} characters`
                          ),
                        nationality: Yup.string().required('Required'),
                        country_code: Yup.string().required('Required'),
                        confirm_password: Yup.string()
                          .required('Required')
                          .oneOf(
                            [Yup.ref('password')],
                            'Both password need to be the same'
                          ),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        //   addAgentData(values);
                        //   navigate('/settings/agents');
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        
                        return <EligibilityFormComponent formik={formik} />;
                      }}
                    </Formik>
                  </div>
                </div> */}
                {/* school filters */}

                <div className='eligibility-form'>
                  <div
                    className='eligibility-flex'
                    onClick={() => {
                      setSchoolFilter(!schoolFilter);
                    }}
                  ></div>
                  <div
                    className=''
                    style={{ display: schoolFilter ? 'block' : 'none' }}
                  >
                    {SIDEBAR_OPTIONS && (
                      <SearchFilterComponent
                        SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                        // dropdown_options={dropdownOptions}
                        // loadOptions={loadOptions}
                        col={'12'}
                        title={'School Filter'}
                      />
                    )}
                  </div>
                </div>
                {/* Program filters */}
              </div>
            </div>
            <div className='col-md-7'>
              <div className='search-program-and-school-box'>
                <div className='search-box'>
                  {/* <form className='form-search' method='get' action='#'>
                    <input
                      type='search'
                      name='search'
                      placeholder='Where? e.g. school name or location'
                    />
                    <button type='submit'>Search</button>
                  </form> */}
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='school-and-programs '>
                      <ul>
                        <li onClick={() => setToggle('PROGRAM')}>
                          <a className={toggle === 'PROGRAM' && 'active-tab'}>
                            <i className='fa fa-tasks'></i> Programs
                          </a>
                        </li>
                        <li onClick={() => setToggle('SCHOOL')}>
                          <a className={toggle === 'SCHOOL' && 'active-tab'}>
                            <i className='fa fa-university'></i>
                            School
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    {toggle === 'PROGRAM' && (
                      <AllProgramsTab
                        programs={programs}
                        programs_loading={programs_loading}
                        program_pages={program_pages}
                        total_programs={total_programs}
                        hideStartApplication={hideStartApplication}
                      />
                    )}
                    {toggle === 'SCHOOL' && (
                      <AllSchoolFilterComponent
                        schools={schools}
                        schools_loading={schools_loading}
                        hideStartApplication={hideStartApplication}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchProgramAndSchoolComponent;
