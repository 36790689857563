import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import * as qs from 'qs';
import AsyncSelect from 'react-select/async';
import { City, Country, State } from 'country-state-city';
import {
  useAllEducationLevels,
  useFilterEducationLevels,
} from '../../shared/hooks/UseEducationLevel';
import { TutionFees } from '../images';
import {
  useAllSchoolsFilters,
  useAllSchoolsFiltersContainer,
} from '../../shared/hooks/UseSchool';
import {
  useAllDisciplines,
  useFilterDisciplines,
} from '../../shared/hooks/UseDiscipline';
import Select from 'react-select';
function SearchFilterComponent({ col, title }) {
  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFields, setSelectedFields] = useState({});
  const [countryCode, setCountryCode] = useState(null);
  const [country_name, setCountry_name] = useState(
    queryParams && queryParams.country ? queryParams.country : ''
  );
  const [stateCode, setStateCode] = useState(null);
  const [state_name, setStateName] = useState(
    queryParams && queryParams.state ? queryParams.state : ''
  );
  const [city, setCity] = useState(
    queryParams && queryParams.city ? queryParams.city : ''
  );
  const [programLevel, setProgramLevel] = useState(
    queryParams && queryParams.education_level
      ? queryParams.education_level
      : ''
  );
  const [intake, setIntake] = useState(null);
  const [tuitionFee, setTuitionFee] = useState(null);
  const [school, setSchool] = useState('');
  const [program_name, setProgram_name] = useState('');
  const [discipline, setDiscipline] = useState(
    queryParams && queryParams.discipline ? queryParams.discipline : ''
  );
  const [sub_discipline, setSubDiscipline] = useState(
    queryParams && queryParams.sub_discipline ? queryParams.sub_discipline : ''
  );
  const [applicationFee, setApplicationFee] = useState(null);
  const { education_levels } = useFilterEducationLevels();
  const { schools } = useAllSchoolsFilters();
  const { disciplines } = useFilterDisciplines();
  const [dropdown_options, setDropdown_options] = useState(null);

  const handleSidebarChange = ({ name, value }) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    queryParams[name] = value;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    navigate(`${location.pathname}?${query}`);
  };

  useEffect(() => {
    if (schools) {
      let finalSchoolOptions = [];

      if (country_name || state_name || city) {
        if (country_name && state_name && city) {
          const filteredData = schools.filter(
            (school) =>
              school.country === country_name &&
              school.state === state_name &&
              school.city === city
          );
          finalSchoolOptions = filteredData.map((school) => {
            return {
              label: `${school.name} (${school.city})`,
              value: school._id,
            };
          });
        } else {
          if (country_name && state_name) {
            const filteredData = schools.filter(
              (school) =>
                school.country === country_name && school.state === state_name
            );
            finalSchoolOptions = filteredData.map((school) => {
              return {
                label: `${school.name} (${school.city})`,
                value: school._id,
              };
            });
          } else {
            if (country_name) {
              const filteredData = schools.filter(
                (school) => school.country === country_name
              );
              finalSchoolOptions = filteredData.map((school) => {
                return {
                  label: `${school.name} (${school.city})`,
                  value: school._id,
                };
              });
            }
          }
        }
      } else {
        finalSchoolOptions = schools.map((school) => {
          return {
            label: `${school.name} (${school.city})`,
            value: school._id,
          };
        });
      }

      setDropdown_options({ ...dropdown_options, school: finalSchoolOptions });
    }
  }, [JSON.stringify(schools), country_name, state_name, city]);
  useEffect(() => {
    if (window.location.search) {
      window.scrollTo(0, 0);
    }
  }, [window.location.search]);

  const getSidebarValue = (item) => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // return urlParams.get(name);
    const queryParams = qs.parse(window.location.search.replace('?', ''));

    const searchValue = queryParams[item] ? queryParams[item] : '';
    return searchValue;
  };
  useEffect(() => {
    if (window.location.search) {
      // setCountryCode(getSidebarValue('country'));
      const country_name = getSidebarValue('country');
      setCountry_name(country_name);
      if (country_name) {
        const all_countries = Country.getAllCountries();
        const filteredData = all_countries.filter(
          (item) => item.name == country_name
        );
        if (filteredData && filteredData.length > 0) {
          setCountryCode(filteredData[0].isoCode);
        }
      }
      const state_name = getSidebarValue('state');
      setStateName(state_name);
      if (state_name && countryCode) {
        const all_states = State.getStatesOfCountry(countryCode);
        const filteredData = all_states.filter(
          (item) => item.name == state_name
        );
        if (filteredData && filteredData.length > 0) {
          setStateCode(filteredData[0].isoCode);
        }
      }
      const city_name = getSidebarValue('city');
      setCity(city_name);

      const school_name = getSidebarValue('school');
      setSchool(school_name);

      const discipline_name = getSidebarValue('discipline');
      setDiscipline(discipline_name);
      const education_level_name = getSidebarValue('education_level');
      setProgramLevel(education_level_name);
      const tuition_fee_val = getSidebarValue('tuition');
      setTuitionFee(tuition_fee_val ? tuition_fee_val : null);
      const application_fee_val = getSidebarValue('application_fee');
      setApplicationFee(application_fee_val ? application_fee_val : null);
      const program_name_val = getSidebarValue('name');
      setProgram_name(program_name_val ? program_name_val : null);
    }
  }, [window.location.search, countryCode, stateCode]);

  return (
    <div className={col ? `col-lg-${col}` : 'col-lg-2'}>
      <div className='card '>
        <div
          className='card-header'
          style={{ background: '#14c6ef', color: '#fff' }}
        >
          <h5 className='card-title'>{title}</h5>
        </div>
        <div className='card-body sidebar-component'>
          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              Country
            </label>
          </div>
          <div>
            <select
              className='form-control'
              onChange={(e) => {
                setCountryCode(e.target.value);
                handleSidebarChange({
                  name: 'country',
                  value: Country.getCountryByCode(e.target.value).name,
                });
              }}
              value={countryCode ? countryCode : ''}
            >
              <option value=''>-</option>
              {Country.getAllCountries() &&
                Country.getAllCountries().map((e) => {
                  return (
                    <option key={e.isoCode} value={e.isoCode}>
                      {e.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              State
            </label>
          </div>
          <div>
            {countryCode &&
            State.getStatesOfCountry(countryCode) &&
            State.getStatesOfCountry(countryCode).length > 0 ? (
              <>
                <select
                  className='form-control'
                  label='State'
                  name='state'
                  value={stateCode ? stateCode : ''}
                  onChange={(e) => {
                    setStateCode(e.target.value);

                    handleSidebarChange({
                      name: 'state',
                      value: State.getStateByCodeAndCountry(
                        e.target.value,
                        countryCode
                      ).name,
                    });
                  }}
                >
                  <option value=''>-</option>
                  {State.getStatesOfCountry(countryCode).map((e) => {
                    return (
                      <option key={e.name} value={e.isoCode}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <input
                label='Province/ State'
                className='form-control'
                name='state'
              />
            )}
          </div>

          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              City
            </label>
          </div>
          <div>
            {countryCode &&
            stateCode &&
            City.getCitiesOfState(countryCode, stateCode) &&
            City.getCitiesOfState(countryCode, stateCode).length > 0 ? (
              <>
                <select
                  className='form-control'
                  label='City'
                  name='city'
                  onChange={(e) => {
                    setCity(e.target.value);
                    handleSidebarChange({
                      name: 'city',
                      value: e.target.value,
                    });
                  }}
                  value={city}
                >
                  <option value=''></option>
                  {City.getCitiesOfState(countryCode, stateCode).map((e) => {
                    return (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <input className='form-control' label='City' name='city' />
            )}
          </div>
        </div>
        {/* <div className='card-footer'>
          <a
            style={{ color: '#14c6ef' }}
            onClick={() => {
              setCity(null);
              setStateCode(null);
              setCountryCode(null);
              navigate(`${location.pathname}`);
            }}
          >
            X Clear Filter
          </a>
        </div> */}
      </div>
      <div className='card mt-5'>
        <div
          className='card-header'
          style={{ background: '#14c6ef', color: '#fff' }}
        >
          <h5 className='card-title'>Program</h5>
        </div>
        <div className='card-body sidebar-component'>
          <label> Program Name </label>
          <input
            className='form-control'
            label='program_name'
            value={program_name}
            onChange={(e) => {
              if (e) {
                setProgram_name(e.target.value);
                handleSidebarChange({
                  name: 'name',
                  value: e.target.value,
                });
              }
            }}
          />
          <label> School </label>
          <Select
            value={
              dropdown_options &&
              dropdown_options.school &&
              dropdown_options.school.filter(
                (option) => option.value === school
              )
            }
            // defaultValue={school}
            isClearable={false}
            options={dropdown_options && dropdown_options['school']}
            onChange={(e) => {
              if (e) {
                setSchool(e.value);
                handleSidebarChange({
                  name: 'school',
                  value: e.value,
                });
              }
            }}
          />
          <label> Discipline </label>

          <select
            name='discipline'
            value={discipline}
            onChange={(e) => {
              if (e) {
                setDiscipline(e.target.value);
                handleSidebarChange({
                  name: 'discipline',
                  value: e.target.value,
                });
              }
            }}
            className='form-control'
          >
            <option value=''></option>
            {disciplines &&
              disciplines
                .filter((item) => !item.parent)
                .map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
          </select>
          <label> Sub Discipline </label>

          <select
            name='sub_discipline'
            onChange={(e) => {
              if (e) {
                setSubDiscipline(e.target.value);
                handleSidebarChange({
                  name: 'sub_discipline',
                  value: e.target.value,
                });
              }
            }}
            className='form-control'
            value={sub_discipline}
          >
            <option value=''></option>
            {disciplines &&
              disciplines
                .filter((item) => item.parent && item.parent._id == discipline)
                .map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
          </select>

          <div className='topping'>
            <label style={{ paddingLeft: '5px' }} htmlFor={'country'}>
              Program Level
            </label>
          </div>
          <div>
            <select
              className='form-control'
              onChange={(e) => {
                setProgramLevel(e.target.value);
                handleSidebarChange({
                  name: 'education_level',
                  value: e.target.value,
                });
              }}
              value={programLevel ? programLevel : ''}
            >
              <option value=''>-</option>
              {education_levels &&
                education_levels.map((item) => {
                  return <option value={item._id}> {item.name} </option>;
                })}
            </select>
          </div>
          <div>
            <label>
              All amounts are listed in the currency charged by the school. For
              best results, please specify a country of the school.
            </label>
            <div className='form-group'>
              <div>
                <label htmlFor='customRange2' className='form-label'>
                  Tuition Fee
                </label>
                <h5> {tuitionFee}</h5>
                <input
                  type='range'
                  name='tuition_fee'
                  className='form-range'
                  value={tuitionFee}
                  min={100}
                  max={10000}
                  id='customRange2'
                  onChange={(e) => {
                    setTuitionFee(e.target.value);
                    handleSidebarChange({
                      name: 'tuition',
                      value: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className='form-group mt-3'>
              <div>
                <label htmlFor='customRange2' className='form-label'>
                  Application Fee
                </label>
                <h5> {applicationFee} </h5>
                <input
                  type='range'
                  value={applicationFee}
                  name='application_fee'
                  className='form-range'
                  min={0}
                  max={1000}
                  id='customRange2'
                  onChange={(e) => {
                    setApplicationFee(e.target.value);
                    handleSidebarChange({
                      name: 'application_fee',
                      value: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <a
            style={{ color: '#14c6ef' }}
            onClick={() => {
              setCity(null);
              setStateCode(null);
              setCountryCode(null);
              setProgramLevel(null);
              setApplicationFee(null);
              setTuitionFee(null);
              setSchool(null);
              setDiscipline(null);
              navigate(`${location.pathname}`);
            }}
          >
            X Clear Filter
          </a>
        </div>
      </div>
    </div>
  );
}

export default SearchFilterComponent;
