import {
  GET_CRM_USERS_STARTED,
  GET_CRM_USERS,
  GET_CRM_USERS_ENDED,
  ADD_CRM_USER_STARTED,
  ADD_CRM_USER,
  ADD_CRM_USER_ENDED,
  EDIT_CRM_USER_STARTED,
  EDIT_CRM_USER,
  EDIT_CRM_USER_ENDED,
  GET_CRM_USER,
  GET_CRM_USER_STARTED,
  GET_CRM_USER_ENDED,
} from '../types/crm_user_types';

const initialState = {
  crm_users_loading: true,
  crm_users: null,
  crm_user_page: null,
  crm_users_pages: null,
  total_crm_users: 0,

  crm_user: null,
  crm_user_loading: null,

  add_crm_user_loading: true,
  edit_crm_user_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CRM_USERS_STARTED:
      return {
        ...state,
        crm_users: null,
        crm_user_pages: null,
        crm_user_page: null,
        total_crm_users: 0,
        crm_users_loading: true,
      };
    case GET_CRM_USERS:
      return {
        ...state,
        crm_users: payload.crm_users,
        crm_user_pages: payload.pages,
        crm_user_page: payload.page,
        total_crm_users: payload.total_crm_users,
      };
    case GET_CRM_USERS_ENDED:
      return {
        ...state,
        crm_users_loading: false,
      };

    case ADD_CRM_USER_STARTED:
      return {
        ...state,
        crm_user_message: null,
        add_crm_user_loading: true,
      };
    case ADD_CRM_USER:
      return {
        ...state,
        crm_user_message: payload,
      };
    case ADD_CRM_USER_ENDED:
      return {
        ...state,
        add_crm_user_loading: false,
      };
    case GET_CRM_USER_STARTED:
      return {
        ...state,
        crm_user: null,
        crm_user_loading: true,
      };
    case GET_CRM_USER:
      return {
        ...state,
        crm_user: payload,
      };
    case GET_CRM_USER_ENDED:
      return {
        ...state,
        crm_user_loading: false,
      };
    case EDIT_CRM_USER_STARTED:
      return {
        ...state,
        crm_user_message: null,
        edit_crm_user_loading: true,
      };
    case EDIT_CRM_USER:
      return {
        ...state,
        crm_user_message: payload,
      };
    case EDIT_CRM_USER_ENDED:
      return {
        ...state,
        edit_crm_user_loading: false,
      };

    default:
      return state;
  }
}
