import React from 'react';
import SingleSchool from '../../school/SingleSchool';
import { Footer, Header } from '../../../components/common';

export default function WebsiteSingleSchool() {
  return (
    <div>
      <Header />
      <div style={{ marginTop: '70px' }}>
        <SingleSchool
          hideBreadCrumb={true}
          hideFooter={true}
          hideStatus={true}
          hideAction={true}
          hideStartApplication={true}
        />
        <Footer />
      </div>
    </div>
  );
}
