import React from 'react';

function ViewAgentComponent({ agent }) {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>First Name</h2>
          <span>{agent.first_name}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Last Name</h2>
          <span>{agent.last_name}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Email</h2>
          <span>{agent.email}</span>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Nationality</h2>
          <span>{agent.nationality}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Country Code</h2>
          <span>{agent.country_code}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Phone</h2>
          <span>{agent.phone}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Assigned Professional Assistant</h2>
          <span>
            {agent.crm_user
              ? `${agent.crm_user.first_name} ${agent.crm_user.last_name}`
              : '-'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ViewAgentComponent;
