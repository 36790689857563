import {
  GET_CRM_USERS_STARTED,
  GET_CRM_USERS,
  GET_CRM_USERS_ENDED,
  ADD_CRM_USER_STARTED,
  ADD_CRM_USER,
  ADD_CRM_USER_ENDED,
  EDIT_CRM_USER_STARTED,
  EDIT_CRM_USER,
  EDIT_CRM_USER_ENDED,
  GET_CRM_USER,
  GET_CRM_USER_STARTED,
  GET_CRM_USER_ENDED,
} from '../types/crm_user_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addCrm_user
export const addCrmUser = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CRM_USER_STARTED,
    });
    const { data } = await api.post(`/crm-users/add`, formData);
    dispatch({
      type: ADD_CRM_USER,
      payload: data,
    });
    dispatch({
      type: ADD_CRM_USER_ENDED,
    });
    dispatch(setAlert('Crm_user added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_CRM_USER_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getCrmUsers =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CRM_USERS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/crm-users?&${query}`);

      dispatch({
        type: GET_CRM_USERS,
        payload: data,
      });
      dispatch({
        type: GET_CRM_USERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CRM_USERS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
// Get CRM User Filter
export const getCrmUsersFilter =
  ({ value = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CRM_USERS_STARTED,
      });
      let searchUrl = ``;
      if (value) {
        searchUrl = `/crm-users/filter`;
      } else {
        searchUrl = `/crm-users/filter`;
      }
      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_CRM_USERS,
        payload: data,
      });
      dispatch({
        type: GET_CRM_USERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CRM_USERS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const getCrmUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CRM_USER_STARTED,
    });
    const { data } = await api.get(`/crm-users/${id}`);

    dispatch({
      type: GET_CRM_USER,
      payload: data,
    });
    dispatch({
      type: GET_CRM_USER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CRM_USER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getCrm_userBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CRM_USER_STARTED,
    });
    const { data } = await api.get(`/crm-users/slug/${slug}`);

    dispatch({
      type: GET_CRM_USER,
      payload: data,
    });
    dispatch({
      type: GET_CRM_USER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CRM_USER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editCrmUser = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CRM_USER_STARTED,
    });
    const { data } = await api.put(`/crm-users/${id}`, formData);
    dispatch({
      type: EDIT_CRM_USER,
      payload: data,
    });
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });
    dispatch(setAlert('Crm_user Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const updatePasswordCrmUser = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CRM_USER_STARTED,
    });
    const { data } = await api.put(
      `/crm-users/${id}/change-password`,
      formData
    );
    dispatch({
      type: EDIT_CRM_USER,
      payload: data,
    });
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });
    dispatch(setAlert('Crm_user Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const draftCrmUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CRM_USER_STARTED,
    });
    dispatch({
      type: GET_CRM_USER_STARTED,
    });
    const { data } = await api.put(`/crm-users/${id}/draft`);
    dispatch({
      type: EDIT_CRM_USER,
      payload: data,
    });
    dispatch({
      type: GET_CRM_USER,
      payload: data,
    });

    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });
    dispatch({
      type: GET_CRM_USER_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });
    dispatch({
      type: GET_CRM_USER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateCrmUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CRM_USER_STARTED,
    });
    const { data } = await api.put(`/crm-users/${id}/active`);
    dispatch({
      type: EDIT_CRM_USER,
      payload: data,
    });
    dispatch({
      type: GET_CRM_USER_STARTED,
    });
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });
    dispatch({
      type: GET_CRM_USER,
      payload: data,
    });
    dispatch({
      type: GET_CRM_USER_ENDED,
    });
    dispatch(setAlert('Crm_user Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_CRM_USER_ENDED,
    });
    dispatch({
      type: GET_CRM_USER_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteCrmUser = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/crm-users/${id}`);
    dispatch(setAlert('User Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
