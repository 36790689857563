import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';
import {
  useSingleCounsellor,
  useUpdateCounsellor,
} from '../../shared/hooks/UseCounsellor';

import CounsellorForm from './CounsellorForm';
import { useAllPermissions } from '../../shared/hooks/UsePermissions';
import PermissionComponent from '../auth/PermissionComponent';
function EditCounsellorComponent() {
  const params = useParams();
  const { counsellor_data } = useSingleCounsellor(params.id);
  const { counsellor, counsellor_loading } = counsellor_data;
  const { updateCounsellorData } = useUpdateCounsellor();
  const navigate = useNavigate();
  const { data } = useAllPermissions();
  const { permissions } = data;
  return (
    <div>
      <PermissionComponent name={'agents'} permission={'update'}>
        {counsellor && (
          <div className='personal-information-box clearfix'>
            <div className='main-sec-heading'>
              <h1>Edit Counsellor</h1>
            </div>

            <Formik
              initialValues={{
                first_name: counsellor ? counsellor.first_name : '',
                last_name: counsellor ? counsellor.last_name : '',
                phone: counsellor ? counsellor.phone : '',
                email: counsellor ? counsellor.email : '',
                permission: counsellor.permission
                  ? counsellor.permission._id
                  : '',
                nationality: counsellor ? counsellor.nationality : '',
                country_code: counsellor ? counsellor.country_code : '',
              }}
              validationSchema={Yup.object({
                first_name: Yup.string().required('Required'),
                last_name: Yup.string().required('Required'),
                phone: Yup.number()
                  .typeError('should be a number.')
                  .required('Required'),
                email: Yup.string().email().required('Required'),

                nationality: Yup.string().required('Required'),
                country_code: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                updateCounsellorData(counsellor._id, values);
                navigate('/settings/counsellors');
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <CounsellorForm
                    dropdown_data={{ permissions: permissions }}
                    hidePassword={true}
                  />
                );
              }}
            </Formik>
          </div>
        )}
      </PermissionComponent>
    </div>
  );
}

export default EditCounsellorComponent;
