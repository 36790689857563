import {
  ADD_EDUCATION_STARTED,
  ADD_EDUCATION_SUCCESS,
  ADD_EDUCATION_ENDED,
  ADD_EDUCATION_ERROR,
} from '../types/education_types';

const initialState = {
  add_education_loading: false,
  add_education_errors: null,
  add_education_success: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_EDUCATION_STARTED:
      return {
        ...state,
        add_education_loading: true,
        add_education_errors: null,
        add_education_success: null,
      };
    case ADD_EDUCATION_ENDED:
      return {
        ...state,
        add_education_loading: false,
      };
    case ADD_EDUCATION_ERROR:
      return {
        ...state,
        add_education_errors: payload,
      };
    case ADD_EDUCATION_STARTED:
      return {
        ...state,
        add_education_success: payload,
      };

    default:
      return state;
  }
}
