import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addProgram,
  getPrograms,
  getProgram,
  editProgram,
  deleteProgram,
  getProgramBySlug,
  draftProgram,
  activateProgram,
  duplicateProgram,
  filterPrograms,
  filterProgramsBySchool,
  deleteBulkProgram,
  bulkDraftProgram,
  bulkPublishProgram,
} from '../../store/actions/program_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { useAllSchools } from '../../shared/hooks/UseSchool';
import { useAllCountries } from '../../shared/hooks/UseCountry';
import { api } from '../../domain/api';
import * as qs from 'qs';

// Add Data
export const useCreateProgram = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.program);
  const addProgramData = async (data) => {
    dispatch(addProgram(data));
  };
  return { data, addProgramData };
};
// Add Data
export const useDuplicateProgram = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.program);
  const duplicateProgramData = async (data) => {
    dispatch(duplicateProgram(id, data));
  };
  return { data, duplicateProgramData };
};

// Get Single Data
export const useSingleProgram = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.program);
  useEffect(() => {
    dispatch(getProgram(id));
  }, [id]);
  return { data };
};
export const useSingleProgramBySlug = (slug) => {
  const dispatch = useDispatch();
  const program_data = useSelector((state) => state.program);
  useEffect(() => {
    dispatch(getProgramBySlug(slug));
  }, []);
  return { program_data };
};

//Change Publish Status
export const useChangeStatusProgram = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.School);
  const draftProgramData = async (id, data) => {
    dispatch(draftProgram(id));
  };
  const activateProgramData = async (id) => {
    dispatch(activateProgram(id));
  };
  return { draftProgramData, activateProgramData };
};

//Edit Data
export const useUpdateProgram = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Program);
  const updateProgramData = async (id, data) => {
    dispatch(editProgram(id, data));
  };
  return { updateProgramData };
};

// Get All Data
export const useAllPrograms = () => {
  const location = useLocation();
  const [data, setData] = useState({
    programs_loading: true,
    programs: null,
    program_page: null,
    program_pages: null,
    total_programs: 0,
  });
  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      getProgramData();
    }, 1000),
    []
  );

  const getProgramData = async () => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/programs?&${query}`);
    setData(data);
  };

  return { data, programs_data: data, reloadPrograms: allQuery };
};

// Program Report
export const useAllProgramsReport = () => {
  const [loading_report, setLoading_report] = useState(false);
  const [reportData, setReportData] = useState(null);
  const getData = async () => {
    setLoading_report(true);
    const { data } = await api.get('/programs/program-reports');
    setReportData(data);
    setLoading_report(false);
  };
  return {
    reportData,
    getData,
    loading_report,
  };
};

// useprogram-filter hook
export const useAllProgramsFilters = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const data = useSelector((state) => state.program);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      dispatch(filterPrograms({ value: searchTerm }));
    }
  }, [searchTerm]);
  useEffect(() => {
    dispatch(filterPrograms({}));
  }, []);

  return {
    data,
    setPageNumber,
    programs_data: data,
    setProgramSearchTerm: setSearchTerm,
  };
};

// useprogram-filter hook
export const useAllProgramsBySchoolFilters = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const [school, setSchool] = useState(null);
  const data = useSelector((state) => state.program);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0 && school) {
      dispatch(filterProgramsBySchool({ value: searchTerm, school }));
    }
  }, [searchTerm, school]);
  useEffect(() => {
    if (school) {
      dispatch(filterProgramsBySchool({ school }));
    }
  }, [school]);

  return {
    data,
    setPageNumber,
    programs_data: data,
    setProgramSearchTerm: setSearchTerm,
    selectedSchool: school,
    setSelectedSchool: setSchool,
  };
};

//Delete Data
export const useDeleteProgram = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Program);
  const deleteProgramFunction = async (id) => {
    dispatch(deleteProgram(id));
  };
  return { deleteProgramFunction };
};

export const useGetDropdownOptions = () => {
  const { schools_data } = useAllSchools();
  const { country_data } = useAllCountries();
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (schools_data.schools) {
      const newData = schools_data.schools.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, school: newData });
    }
  }, [schools_data.schools]);
  useEffect(() => {
    if (country_data.countries) {
      const newData = country_data.countries.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, country: newData });
    }
  }, [country_data.countries]);

  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "school") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};

export const useDeleteBulkProgram = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const deleteBulkData = async (data) => {
    await dispatch(deleteBulkProgram(data));
  };
  return { deleteBulkData };
};

export const useDraftBulkProgram = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const draftBulkData = async (data) => {
    await dispatch(bulkDraftProgram(data));
  };
  return { draftBulkData };
};

export const usePublishBulkProgram = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const publishBulkData = async (data) => {
    await dispatch(bulkPublishProgram(data));
  };
  return { publishBulkData };
};
