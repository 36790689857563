import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { AgGridReact } from 'ag-grid-react';
import Pagination from '../common/Pagination';
import { useNavigate } from 'react-router-dom';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
import FilterComponent from '../filter/FilterComponent';
import { SIDEBAR_OPTIONS } from '../../shared/enums/students_enum';
import SidebarFilter from '../common/SidebarFilter';
import ButtonByID from './ButtonByID';
import moment from 'moment';
import { useAllAgents, useAllAgentsFilters } from '../../shared/hooks/UseAgent';

function AllStudentComponent({
  students_loading,
  students,
  student_page,
  student_pages,
  total_students,
}) {
  const navigate = useNavigate();
  const { data, setAgentSearchTerm } = useAllAgentsFilters();
  const { agents } = data;
  const [dropdownOptions, setDropdownOptions] = useState(null);

  useEffect(() => {
    if (agents) {
      const mappedAgent = agents.map((item) => {
        return {
          label:
            item.agentData &&
            item.agentData.agentInfo &&
            item.agentData.agentInfo.company_name
              ? item.agentData.agentInfo.company_name
              : `${item.first_name} ${item.last_name}`,
          value: item._id,
        };
      });
      setDropdownOptions({ agent: mappedAgent });
    }
  }, [agents]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'agent') {
      setAgentSearchTerm(inputValue);
      callback(dropdownOptions.agent);
    }
  };
  return (
    <div>
      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Students</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent
                name={'student'}
                permission={'create'}
                hideOnAcademicPartner={true}
              >
                <div className='add-student-btn'>
                  <Link to={links.addStudent} className='btn primary-btn-h '>
                    Add Student
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <PermissionComponent name={'student'} permission={'get_all'}>
        <section className='students-section'>
          <div className='container-fluid'>
            <div className='row'>
              {SIDEBAR_OPTIONS && (
                <SidebarFilter
                  SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                  dropdown_options={dropdownOptions}
                  loadOptions={loadOptions}
                />
              )}
              <div className='col-md-10'>
                <p> {total_students} Records Found </p>
                <div className='myapplication-table'>
                  <table className='table table-responsive table-sm  table-bordered table-striped  '>
                    <thead>
                      <tr>
                        <th scope='col '>First Name</th>

                        <th scope='col'>Last Name</th>
                        <th scope='col'>Agent</th>
                        <th scope='col'>Country Code</th>
                        <th scope='col'>Phone</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>User ID</th>
                        <PermissionComponent
                          name={'student'}
                          permission={'get_all'}
                          hideOnAcademicPartner={true}
                        >
                          <th scope='col'>Actions</th>
                        </PermissionComponent>
                      </tr>
                    </thead>
                    <tbody>
                      {students &&
                        students.map((student) => {
                          return (
                            <tr key={student._id}>
                              <td>{student.first_name}</td>

                              <td> {student.last_name} </td>
                              <td>
                                {student &&
                                student.agentData &&
                                student.agentData.agentInfo &&
                                student.agentData.agentInfo.company_name
                                  ? student.agentData.agentInfo.company_name
                                  : student.agent
                                  ? `${student.agent.first_name} ${student.agent.last_name}`
                                  : 'NA'}
                              </td>
                              <td>{student.country_code}</td>
                              <td>{student.phone}</td>
                              <td>{student.email}</td>
                              <td>{student.user_id}</td>
                              <PermissionComponent
                                name={'student'}
                                permission={'get_all'}
                                hideOnAcademicPartner={true}
                              >
                                <td className='status-ic'>
                                  <Link
                                    to={`/students/${student._id}/general-information`}
                                    className='badge badge-view'
                                  >
                                    View
                                  </Link>
                                </td>
                              </PermissionComponent>
                            </tr>
                          );
                        })}
                      {students && students.length == 0 && (
                        <tr>
                          <td colSpan={10}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <div
                  className="ag-theme-alpine pb-50"
                  style={{ width: '100%', height: 500 }}
                >
                  <AgGridReact
                    // Ref for accessing Grid's API
                    rowData={students} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    // Default Column Properties
                    animateRows={true}
                    // pagination={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                    // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                  />
                </div> */}
                <Pagination
                  count={total_students}
                  pages={student_pages}
                  loading={students_loading}
                />
              </div>
            </div>
          </div>
        </section>
      </PermissionComponent>
    </div>
  );
}

export default AllStudentComponent;
