import React, { useState } from 'react';

import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import FilterForm from './FilterForm';
import * as qs from 'qs';

function FilterComponent({ filterData }) {
  const location = useLocation();
  const [filter, setFilter] = useState(false);
  const navigate = useNavigate();

  const handleSidebarChange = ({ name, value, type = 'search', condition }) => {
    const queryParams = qs.parse(location.search.replace('?', ''));
    if (type == 'conditional') {
      if (queryParams[type]) {
        if (queryParams[type][name]) {
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        } else {
          queryParams[type][name] = {};
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        }
      } else {
        queryParams[type] = {};
        queryParams[type][name] = {};
        if (value && value.length > 0) {
          queryParams[type][name][condition ? condition : '$gte'] = value;
        } else {
          delete queryParams[type][name][condition ? condition : '$gte'];
        }
      }
    } else {
      if (queryParams[type]) {
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      } else {
        queryParams[type] = {};
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      }
    }
    return queryParams;
  };

  return (
    <>
      <section className='ptb-10'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='filter-button' onClick={() => setFilter(!filter)}>
                <button className='filter-btn'>
                  <i className='fa fa-filter'></i> Filters
                </button>
              </div>
              <div
                className='filer-main-bo'
                style={{ display: filter ? 'block' : 'none' }}
              >
                <Formik
                  initialValues={{
                    filter_data: [
                      {
                        search_field: '',
                        search_type: '',
                        search_value: '',
                      },
                    ],
                  }}
                  validationSchema={Yup.object({
                    filter_data: Yup.array(
                      Yup.object().shape({
                        search_field: Yup.string().required('Required'),
                        search_type: Yup.string().required('Required'),
                        search_value: Yup.string().required('Required'),
                      })
                    ),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const dataToFilter = values.filter_data;
                    const finalFilterAttrs = [];
                    dataToFilter.map((item) => {
                      const searchParams = handleSidebarChange({
                        name: item.search_field,
                        type: item.search_type,
                        value: item.search_value,
                      });
                      finalFilterAttrs.push(searchParams);
                    });
                    //   addAgentData(values);
                    // navigate('/settings/agents');
                    // resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    return (
                      <FilterForm formik={formik} filterData={filterData} />
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FilterComponent;
