export const GET_APPLICATION_REQUIREMENTS_STARTED =
  'GET_APPLICATION_REQUIREMENTS_STARTED';
export const GET_APPLICATION_REQUIREMENTS = 'GET_APPLICATION_REQUIREMENTS';
export const GET_APPLICATION_REQUIREMENTS_ENDED =
  'GET_APPLICATION_REQUIREMENTS_ENDED';
export const GET_APPLICATION_REQUIREMENTS_ERROR =
  'GET_APPLICATION_REQUIREMENTS_ERROR';
export const ADD_APPLICATION_REQUIREMENT_STARTED =
  'ADD_APPLICATION_REQUIREMENT_STARTED';
export const ADD_APPLICATION_REQUIREMENT = 'ADD_APPLICATION_REQUIREMENT';
export const ADD_APPLICATION_REQUIREMENT_ENDED =
  'ADD_APPLICATION_REQUIREMENT_ENDED';
export const ADD_APPLICATION_REQUIREMENT_ERROR =
  'ADD_APPLICATION_REQUIREMENT_ERROR';
export const EDIT_APPLICATION_REQUIREMENT_STARTED =
  'EDIT_APPLICATION_REQUIREMENT_STARTED';
export const EDIT_APPLICATION_REQUIREMENT = 'EDIT_APPLICATION_REQUIREMENT';
export const EDIT_APPLICATION_REQUIREMENT_ENDED =
  'EDIT_APPLICATION_REQUIREMENT_ENDED';
export const EDIT_APPLICATION_REQUIREMENT_ERROR =
  'EDIT_APPLICATION_REQUIREMENT_ERROR';
export const GET_APPLICATION_REQUIREMENT = 'GET_APPLICATION_REQUIREMENT';
export const GET_APPLICATION_REQUIREMENT_STARTED =
  'GET_APPLICATION_REQUIREMENT_STARTED';
export const GET_APPLICATION_REQUIREMENT_ENDED =
  'GET_APPLICATION_REQUIREMENT_ENDED';
export const GET_APPLICATION_REQUIREMENT_ERROR =
  'GET_APPLICATION_REQUIREMENT_ERROR';

export const GET_APPLICATION_REQUIREMENT_PROGRAM_STARTED =
  'GET_APPLICATION_REQUIREMENT_PROGRAM_STARTED';
export const GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED =
  'GET_APPLICATION_REQUIREMENT_PROGRAM_ENDED';
export const GET_APPLICATION_REQUIREMENT_PROGRAM =
  'GET_APPLICATION_REQUIREMENT_PROGRAM';
