import React, { useState, useEffect } from 'react';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import {
  useAllNotifications,
  useUpdateNotification,
  useAllTotalNotifications,
} from '../../shared/hooks/UseNotification';
import * as qs from 'qs';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import moment from 'moment';

function Notifications({ match }) {
  const { all_notification_data } = useAllTotalNotifications();
  const {
    all_total_notifications,
    all_notifications,
    all_pages,
    all_page,
    all_notifications_loading,
  } = all_notification_data;

  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [notificationParam, setNotificationParam] = useState(null);

  // const [notificatiton_data, reloadNotificationData] =
  //   useSelectAllNotification();
  const [updateData] = useUpdateNotification();

  useEffect(() => {
    if (notificationParam) {
      updateData(notificationParam, { is_read: true });
      // reloadNotificationData();
    }
  }, [notificationParam]);

  useEffect(() => {
    if (queryParams.notification) {
      setNotificationParam(queryParams.notification);
    }
  }, [queryParams && queryParams.notification]);

  return (
    <div className='Wrapper'>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Notifications'} />
      <section className='notification-table-sec ptb-50'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-sec-heading'>
                <h1>All Notifications</h1>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <table class='table  table-responsive table-sm  table-bordered p-3'>
                <thead class='thead-Primary p-3'>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Notification</th>
                    <th scope='col'>Date</th>

                    <th scope='col'>View</th>
                  </tr>
                </thead>
                <tbody>
                  {all_notifications &&
                    all_notifications.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            background: item.is_read ? '#fff' : '#f1f1f1',
                          }}
                        >
                          <th scope='row'>{index + 1}</th>
                          <td>{item.name}</td>
                          <td>
                            {item.createdAt &&
                              moment(item.createdAt).format('DD-MMM-YYYY')}
                          </td>

                          <td>
                            <div className='edit-notifications'>
                              <Link to={`${item.url}?notification=${item._id}`}>
                                <i class='fa fa-eye' aria-hidden='true'></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <p> {all_total_notifications} Records Found </p>
              <Pagination
                count={all_total_notifications}
                pages={all_pages}
                loading={all_notifications_loading}
              />
            </div>
          </div>
        </div>
      </section>

      <AuthFooter />
    </div>
  );
}

export default Notifications;
