import React from 'react';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import AllRolesAndPermissionsComponent from '../../components/RolesAndPermissions/AllRolesAndPermissionsComponent';
import { links } from '../../domain/links.enum';
import { useAllPermissions } from '../../shared/hooks/UsePermissions';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import PermissionComponent from '../../components/auth/PermissionComponent';
import FilterComponent from '../../components/filter/FilterComponent';

function AllRolesAndPermissions() {
  const { data, setPageNumber } = useAllPermissions();
  const {
    permissions_loading,
    permissions,
    permission_page,
    permission_pages,
    total_permissions,
  } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Roles & Permissions'} />
      <SettingNavigationComponent
        active_link={SETTING_MENU_ENUM.ROLES_AND_PERMISSIONS}
      />
      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Roles & Permissions</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent
                name={'roles_permission'}
                permission={'create'}
              >
                <div className='add-student-btn'>
                  <Link
                    to={links.addRolesAndPermissions}
                    className='btn primary-btn-h '
                  >
                    Add Role
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <section className='students-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <AllRolesAndPermissionsComponent
                permissions_loading={permissions_loading}
                permissions={permissions}
                permission_page={permission_page}
                permission_pages={permission_pages}
                total_permissions={total_permissions}
              />
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default AllRolesAndPermissions;
