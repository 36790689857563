import React from 'react';
import { Link } from 'react-router-dom';

import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import DisciplinesComponent from '../../components/discipline/DisciplinesComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllDisciplines } from '../../shared/hooks/UseDiscipline';
import FilterComponent from '../../components/filter/FilterComponent';
function Disciplines() {
  const { discipline_data, setPageNumber } = useAllDisciplines();
  const {
    disciplines_loading,
    disciplines,
    discipline_page,
    discipline_pages,
    total_disciplines,
  } = discipline_data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Disciplines'} />
      <SettingNavigationComponent active_link={SETTING_MENU_ENUM.DISCIPLINE} />
      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Disciplines</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'country'} permission={'create'}>
                <div className='add-student-btn'>
                  <Link to={links.addDiscipline} className='btn primary-btn-h '>
                    Add Discipline
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>

      <section className='students-section'>
        {disciplines && (
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <DisciplinesComponent
                  disciplines_loading={disciplines_loading}
                  disciplines={disciplines}
                  discipline_page={discipline_page}
                  discipline_pages={discipline_pages}
                  total_disciplines={total_disciplines}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <AuthFooter />
    </div>
  );
}

export default Disciplines;
