import React from 'react';

function ViewSlabComponent({ slab }) {
  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <div className='heading-single-education'>
            <h2>Name</h2>
            <span>{slab.name}</span>
          </div>
        </div>
      </div>

      {slab.zone &&
        slab.zone.map((zone) => {
          return (
            <div className='row'>
              <div className='col-md-6'>
                <div className='heading-single-education'>
                  <h2>Commission</h2>
                  <span>{zone.commission}%</span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='heading-single-education'>
                  <h2>Country</h2>
                  <span>{zone.country && zone.country.name} </span>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default ViewSlabComponent;
