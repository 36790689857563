import * as Yup from 'yup';
export const schoolValidationSchema = Yup.object({
  about: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  market: Yup.string().required('Required'),
  // cost_of_living: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  dial_code: Yup.string().required('Required'),
  // currency: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  postal_code: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  time_zone: Yup.string().required('Required'),
  time_zone: Yup.string().required('Required'),
  school_features: Yup.array(
    Yup.object().shape({
      title: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    })
  ),
});
