export const GET_APPLICATIONS_STARTED = 'GET_APPLICATIONS_STARTED';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_ENDED = 'GET_APPLICATIONS_ENDED';
export const GET_APPLICATIONS_ERROR = 'GET_APPLICATIONS_ERROR';
export const ADD_APPLICATION_STARTED = 'ADD_APPLICATION_STARTED';
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const ADD_APPLICATION_ENDED = 'ADD_APPLICATION_ENDED';
export const ADD_APPLICATION_ERROR = 'ADD_APPLICATION_ERROR';
export const EDIT_APPLICATION_STARTED = 'EDIT_APPLICATION_STARTED';
export const EDIT_APPLICATION = 'EDIT_APPLICATION';
export const EDIT_APPLICATION_ENDED = 'EDIT_APPLICATION_ENDED';
export const EDIT_APPLICATION_ERROR = 'EDIT_APPLICATION_ERROR';
export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_STARTED = 'GET_APPLICATION_STARTED';
export const GET_APPLICATION_ENDED = 'GET_APPLICATION_ENDED';
export const GET_APPLICATION_ERROR = 'GET_APPLICATION_ERROR';

export const ALL_APPLICATION_PAYMENT_STARTED =
  'ALL_APPLICATION_PAYMENT_STARTED';
export const ALL_APPLICATION_PAYMENT = 'ALL_APPLICATION_PAYMENT';
export const ALL_APPLICATION_PAYMENT_ENDED = 'ALL_APPLICATION_PAYMENT_ENDED';
export const ALL_APPLICATION_PAYMENT_ERROR = 'ALL_APPLICATION_PAYMENT_ERROR';
