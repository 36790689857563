import React, { useState } from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import PaymentFilterForm from './PaymentFilterForm';
import PaymentTable from './PaymentTable';
import { WalletImage } from '../images';
import moment from 'moment';
import PaymentTableComponent from './PaymentTableComponent';

function PaymentsComponent({ all_application_payments }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('applications_total');

  return (
    <>
      <section className='wallet-section ptb-20'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6'>
              <p>Total Overall Commission Edchimp</p>
              <div className='d-flex gap-2 flex-warp'>
                {all_application_payments &&
                  all_application_payments.totalOverallCommissionEdchimp &&
                  all_application_payments.totalOverallCommissionEdchimp.map(
                    (item) => {
                      if (item._id) {
                        return (
                          <div className='wallet flex-1'>
                            <h4> {item._id}</h4>
                            <div className='wallet-image-flex'>
                              {/* <div>
                                <img src={WalletImage} />
                              </div> */}
                              <div>
                                <h2>
                                  {item.total}
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </div>
            </div>
            <div className='col-md-6'>
              <p>Total Paid Commission Edchimp</p>
              <div className='d-flex gap-2 flex-warp'>
                {all_application_payments &&
                  all_application_payments.totalPaidCommissionEdchimp &&
                  all_application_payments.totalPaidCommissionEdchimp.map(
                    (item) => {
                      if (item._id) {
                        return (
                          <div className='wallet flex-1'>
                            <h4> {item._id}</h4>
                            <div className='wallet-image-flex'>
                              {/* <div>
                                <img src={WalletImage} />
                              </div> */}
                              <div>
                                <h2>
                                  {item.total}
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </div>
            </div>
            <div className='col-md-6'>
              <p>Total UnPaid Commission Edchimp</p>
              <div className='d-flex gap-2 flex-warp'>
                {all_application_payments &&
                  all_application_payments.totalUnPaidCommissionEdchimp &&
                  all_application_payments.totalUnPaidCommissionEdchimp.map(
                    (item) => {
                      if (item._id) {
                        return (
                          <div className='wallet flex-1'>
                            <h4> {item._id}</h4>
                            <div className='wallet-image-flex'>
                              {/* <div>
                                <img src={WalletImage} />
                              </div> */}
                              <div>
                                <h2>
                                  {item.total}
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </div>
            </div>

            <div className='col-md-6'>
              <p>Total Paid Commission Agent</p>
              <div className='d-flex gap-2 flex-warp'>
                {all_application_payments &&
                  all_application_payments.totalPaidCommissionAgent &&
                  all_application_payments.totalPaidCommissionAgent.map(
                    (item) => {
                      if (item._id) {
                        return (
                          <div className='wallet flex-1'>
                            <h4> {item._id}</h4>
                            <div className='wallet-image-flex'>
                              {/* <div>
                                <img src={WalletImage} />
                              </div> */}
                              <div>
                                <h2>
                                  {item.total}
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </div>
            </div>
            <div className='col-md-6'>
              <p>Total UnPaid Commission Agent</p>
              <div className='d-flex gap-2 flex-warp'>
                {all_application_payments &&
                  all_application_payments.totalUnPaidCommissionAgent &&
                  all_application_payments.totalUnPaidCommissionAgent.map(
                    (item) => {
                      if (item._id) {
                        return (
                          <div className='wallet flex-1'>
                            <h4> {item._id}</h4>
                            <div className='wallet-image-flex'>
                              {/* <div>
                                <img src={WalletImage} />
                              </div> */}
                              <div>
                                <h2>
                                  {item.total}
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </div>
            </div>
            <div className='col-md-6'>
              <p>Total Overall Commission Agent</p>
              <div className='d-flex gap-2 flex-warp'>
                {all_application_payments &&
                  all_application_payments.totalOverallCommissionAgent &&
                  all_application_payments.totalOverallCommissionAgent.map(
                    (item) => {
                      if (item._id) {
                        return (
                          <div className='wallet flex-1'>
                            <h4> {item._id}</h4>
                            <div className='wallet-image-flex'>
                              {/* <div>
                                <img src={WalletImage} />
                              </div> */}
                              <div>
                                <h2>
                                  {item.total}
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex gap-2'>
              <div>
                <a onClick={() => setActiveTab('applications_total')}>
                  {' '}
                  applications_total{' '}
                </a>
              </div>
              <div>
                <a onClick={() => setActiveTab('applications_agents_paid')}>
                  {' '}
                  applications_agents_paid{' '}
                </a>
              </div>
              <div>
                <a onClick={() => setActiveTab('applications_agents_unpaid')}>
                  {' '}
                  applications_agents_unpaid{' '}
                </a>
              </div>
              <div>
                <a
                  onClick={() =>
                    setActiveTab('applications_total_paid_from_school')
                  }
                >
                  {' '}
                  applications_total_paid_from_school{' '}
                </a>
              </div>
              <div>
                <a
                  onClick={() =>
                    setActiveTab('applications_total_unpaid_from_school')
                  }
                >
                  {' '}
                  applications_total_unpaid_from_school{' '}
                </a>
              </div>
              <div>
                <a
                  onClick={() =>
                    setActiveTab(
                      'applications_total_paid_from_school_agent_not_paid'
                    )
                  }
                >
                  {' '}
                  applications_total_paid_from_school_agent_not_paid{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="order-histroy-filter">
        <div className="container-fluid">
          <div className="row">
            <div className="filter-flex">
              <Formik
                initialValues={{
                  first_name: '',
                }}
                validationSchema={Yup.object({})}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  // addAgentData(values);
                  navigate('/settings/agents');
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  
                  return <PaymentFilterForm formik={formik} />;
                }}
              </Formik>
            </div>
          </div>
        </div>
      </section> */}
      {activeTab === 'applications_total' && (
        <PaymentTableComponent
          activeTab={activeTab}
          title={'All Payments'}
          data={
            all_application_payments &&
            all_application_payments.applications_total
          }
        />
      )}

      {activeTab === 'applications_agents_paid' && (
        <PaymentTableComponent
          title={'applications_agents_paid'}
          data={
            all_application_payments &&
            all_application_payments.applications_agents_paid
          }
        />
      )}
      {activeTab === 'applications_agents_unpaid' && (
        <PaymentTableComponent
          title={'applications_agents_unpaid'}
          data={
            all_application_payments &&
            all_application_payments.applications_agents_unpaid
          }
        />
      )}
      {activeTab === 'applications_total_paid_from_school' && (
        <PaymentTableComponent
          title={'applications_total_paid_from_school'}
          data={
            all_application_payments &&
            all_application_payments.applications_total_paid_from_school
          }
        />
      )}
      {activeTab === 'applications_total_unpaid_from_school' && (
        <PaymentTableComponent
          title={'applications_total_unpaid_from_school'}
          data={
            all_application_payments &&
            all_application_payments.applications_total_unpaid_from_school
          }
        />
      )}
      {activeTab === 'applications_total_paid_from_school_agent_not_paid' && (
        <PaymentTableComponent
          title={'applications_total_paid_from_school_agent_not_paid'}
          data={
            all_application_payments &&
            all_application_payments.applications_total_paid_from_school_agent_not_paid
          }
        />
      )}
    </>
  );
}

export default PaymentsComponent;
