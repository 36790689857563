import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addEducationLevel,
  getEducationLevels,
  getEducationLevel,
  editEducationLevel,
  deleteEducationLevel,
  filterEducationLevels,
} from '../../store/actions/education_level_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { api } from '../../domain/api';
// Add Data
export const useCreateEducationLevel = () => {
  const dispatch = useDispatch();
  const educationLevelData = useSelector((state) => state.education_level);
  const addEducationLevelData = async (data) => {
    dispatch(addEducationLevel(data));
  };
  return { educationLevelData, addEducationLevelData };
};

// Get Single Data
export const useSingleEducationLevel = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.education_level);
  useEffect(() => {
    dispatch(getEducationLevel(id));
  }, [id]);
  return { data };
};

//Edit Data
export const useUpdateEducationLevel = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.EducationLevel);
  const updateEducationLevelData = async (id, data) => {
    dispatch(editEducationLevel(id, data));
  };
  return { updateEducationLevelData };
};

// Get All Data
export const useAllEducationLevels = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.education_level);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getEducationLevels({}));
    }, 1000),
    []
  );

  return { educationLevelData: data, setPageNumber, data };
};

export const useFilterEducationLevels = () => {
  const [all_data, setAllData] = useState([]);
  const getAllData = async () => {
    try {
      if (all_data.length === 0) {
        const { data } = await api.get('/education-levels/filter');
        setAllData(data.education_levels);
      }
    } catch (error) {
      setAllData([]);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return {
    education_levels: all_data,
  };
};

//Delete Data
export const useDeleteEducationLevel = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.EducationLevel);
  const deleteEducationLevelFunction = async (id) => {
    dispatch(deleteEducationLevel(id));
  };
  return { deleteEducationLevelFunction };
};
