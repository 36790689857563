import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addApplicationNote,
  getApplicationNotes,
  getApplicationNote,
  editApplicationNote,
  deleteApplicationNote,
} from '../../store/actions/application_note_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreateApplicationNote = () => {
  const dispatch = useDispatch();
  const application_note_data = useSelector((state) => state.application_note);
  const addApplicationNoteData = async (data) => {
    dispatch(addApplicationNote(data));
  };
  return { application_note_data, addApplicationNoteData };
};

// Get Single Data
export const useSingleApplicationNote = (id) => {
  const dispatch = useDispatch();
  const application_note_data = useSelector((state) => state.application_note);
  useEffect(() => {
    dispatch(getApplicationNote(id));
  }, [id]);
  return { application_note_data };
};

//Edit Data
export const useUpdateApplicationNote = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.ApplicationNote);
  const updateApplicationNoteData = async (id, data) => {
    dispatch(editApplicationNote(id, data));
  };
  return { updateApplicationNoteData };
};

// Get All Data
export const useAllApplicationNotes = (application) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const application_note_data = useSelector((state) => state.application_note);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getApplicationNotes({ application }));
    }, 1000),
    []
  );

  return { application_note_data, setPageNumber };
};

//Delete Data
export const useDeleteApplicationNote = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.ApplicationNote);
  const deleteApplicationNoteFunction = async (id) => {
    dispatch(deleteApplicationNote(id));
  };
  return { deleteApplicationNoteFunction };
};
