import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import CountriesComponent from '../../components/country/CountriesComponent';
import { SchoolLogo } from '../../components/images';
import PaidApplications from '../../components/my_applications/PaidApplications';
import UnpaidApplicationsComponent from '../../components/my_applications/UnpaidApplicationsComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllCountries } from '../../shared/hooks/UseCountry';
import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import PaymentsComponent from '../../components/payment/PaymentsComponent';
import { useAllApplicationPayments } from '../../shared/hooks/UseApplication';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../domain/UserRole';
import PaymentsComponentAgent from '../../components/payment/PaymentComponentAgent';
function Payments() {
  const {
    allApplicationPaymentHandler,
    all_application_payments_loading,
    all_application_payments,
  } = useAllApplicationPayments();
  useEffect(() => {
    allApplicationPaymentHandler();
  }, []);

  const data = useSelector((state) => state.auth_reducer);
  const { user } = data;
  return (
    <div>
      <Auth_header_mobile active={STUDENT_PROFILE_ENUM.PAYMENTS} />
      <AuthHeader active={STUDENT_PROFILE_ENUM.PAYMENTS} />
      <AuthBreadCrumb title={'Payments'} />

      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Payments</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='students-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              {user && user.role === UserRoles.CRM_USER && (
                <PaymentsComponent
                  all_application_payments={all_application_payments}
                />
              )}
              {user && user.role === UserRoles.AGENT && (
                <PaymentsComponentAgent
                  all_application_payments={all_application_payments}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default Payments;
