import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addGrading,
  getGradings,
  getGrading,
  editGrading,
  deleteGrading,
} from '../../store/actions/grading_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreateGrading = () => {
  const dispatch = useDispatch();
  const grading_data = useSelector((state) => state.grading);
  const addGradingData = async (data) => {
    dispatch(addGrading(data));
  };
  return { grading_data, addGradingData };
};

// Get Single Data
export const useSingleGrading = (id) => {
  const dispatch = useDispatch();
  const grading_data = useSelector((state) => state.grading);
  useEffect(() => {
    dispatch(getGrading(id));
  }, [id]);
  return { grading_data };
};

//Edit Data
export const useUpdateGrading = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Grading);
  const updateGradingData = async (id, data) => {
    dispatch(editGrading(id, data));
  };
  return { updateGradingData };
};

// Get All Data
export const useAllGradings = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const grading_data = useSelector((state) => state.grading);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getGradings({}));
    }, 1000),
    []
  );

  return { grading_data, setPageNumber };
};

//Delete Data
export const useDeleteGrading = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Grading);
  const deleteGradingFunction = async (id) => {
    dispatch(deleteGrading(id));
  };
  return { deleteGradingFunction };
};
