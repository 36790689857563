import {
  GET_SCHOOLS_STARTED,
  GET_SCHOOLS,
  GET_SCHOOLS_ENDED,
  ADD_SCHOOL_STARTED,
  ADD_SCHOOL,
  ADD_SCHOOL_ENDED,
  EDIT_SCHOOL_STARTED,
  EDIT_SCHOOL,
  EDIT_SCHOOL_ENDED,
  GET_SCHOOL,
  GET_SCHOOL_STARTED,
  GET_SCHOOL_ENDED,
  GET_SCHOOL_PROGRAM_STARTED,
  GET_SCHOOL_PROGRAM,
  GET_SCHOOL_PROGRAM_ENDED,
  GET_ALL_SCHOOLS_STARTED,
  GET_ALL_SCHOOLS,
} from '../types/school_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';
import store from '../store';

//addSchool
export const addSchool = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_SCHOOL_STARTED,
    });
    const { data } = await api.post(`/schools/add`, formData);
    dispatch({
      type: ADD_SCHOOL,
      payload: data,
    });
    dispatch({
      type: ADD_SCHOOL_ENDED,
    });
    dispatch(setAlert('School added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_SCHOOL_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getSchools =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_SCHOOLS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/schools?&${query}`);

      dispatch({
        type: GET_SCHOOLS,
        payload: data,
      });
      dispatch({
        type: GET_SCHOOLS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOOLS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const filterSchools =
  ({ value = '' }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_SCHOOLS_STARTED,
      });
      let searchUrl = `/schools/filter`;

      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_ALL_SCHOOLS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOOLS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getSchool = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SCHOOL_STARTED,
    });
    const { data } = await api.get(`/schools/${id}`);

    dispatch({
      type: GET_SCHOOL,
      payload: data,
    });
    dispatch({
      type: GET_SCHOOL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHOOL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getSchoolPrograms = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SCHOOL_PROGRAM_STARTED,
    });
    const { data } = await api.get(`/schools/${id}/programs`);

    dispatch({
      type: GET_SCHOOL_PROGRAM,
      payload: data,
    });
    dispatch({
      type: GET_SCHOOL_PROGRAM_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHOOL_PROGRAM_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getSchoolBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SCHOOL_STARTED,
    });
    const { data } = await api.get(`/schools/slug/${slug}`);

    dispatch({
      type: GET_SCHOOL,
      payload: data,
    });
    dispatch({
      type: GET_SCHOOL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHOOL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editSchool = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SCHOOL_STARTED,
    });
    const { data } = await api.put(`/schools/${id}`, formData);
    dispatch({
      type: EDIT_SCHOOL,
      payload: data,
    });
    dispatch({
      type: EDIT_SCHOOL_ENDED,
    });
    dispatch(setAlert('School Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_SCHOOL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftSchool = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SCHOOL_STARTED,
    });
    dispatch({
      type: GET_SCHOOL_STARTED,
    });
    const { data } = await api.put(`/schools/${id}/draft`);
    dispatch({
      type: EDIT_SCHOOL,
      payload: data,
    });
    dispatch({
      type: GET_SCHOOL,
      payload: data,
    });

    dispatch({
      type: EDIT_SCHOOL_ENDED,
    });
    dispatch({
      type: GET_SCHOOL_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_SCHOOL_ENDED,
    });
    dispatch({
      type: GET_SCHOOL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateSchool = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SCHOOL_STARTED,
    });
    const { data } = await api.put(`/schools/${id}/active`);
    dispatch({
      type: EDIT_SCHOOL,
      payload: data,
    });
    dispatch({
      type: GET_SCHOOL_STARTED,
    });
    dispatch({
      type: EDIT_SCHOOL_ENDED,
    });
    dispatch({
      type: GET_SCHOOL,
      payload: data,
    });
    dispatch({
      type: GET_SCHOOL_ENDED,
    });
    dispatch(setAlert('School Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_SCHOOL_ENDED,
    });
    dispatch({
      type: GET_SCHOOL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteSchool = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/schools/${id}`);
    dispatch(setAlert('School Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
