export const inputFields = {
  first_name: {
    type: 'string',
    required: true,
    title: 'Name',
  },
  last_name: {
    type: 'string',
    required: true,
    title: 'Name',
  },
  state: {
    type: 'string',
    required: true,
    title: 'Name',
  },
  city: {
    type: 'string',
    required: true,
    title: 'Name',
  },
};

export const SIDEBAR_OPTIONS = [
  {
    id: 'first_name',
    field: 'first_name',
    label: 'First Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'phone',
    field: 'phone',
    label: 'Phone',
    type: 'string',
    search_type: 'search',
    inputType: 'number',
    condition: '',
  },

  {
    id: 'email',
    field: 'email',
    label: 'Email',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },

  {
    id: 'student_id',
    field: 'user_id',
    label: 'Student ID',
    type: 'string',
    search_type: 'exact',
    inputType: 'number',
    condition: '',
  },
  {
    id: 'agent',
    field: 'agent',
    label: 'Agent',
    type: 'related',
    search_type: 'exact',
    inputType: 'select',
    condition: '',
  },
];
