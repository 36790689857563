import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addRequirement,
  getRequirements,
  getRequirement,
  editRequirement,
  deleteRequirement,
  getRequirementBySlug,
} from '../../store/actions/requirement_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

// Add Data
export const useCreateRequirement = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.requirement);
  const addRequirementData = async (data) => {
    dispatch(addRequirement(data));
  };
  return { data, addRequirementData };
};

// Get Single Data
export const useSingleRequirement = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.requirement);
  useEffect(() => {
    dispatch(getRequirement(id));
  }, [id]);
  return { data };
};
export const useSingleRequirementBySlug = (slug) => {
  const dispatch = useDispatch();
  const requirement_data = useSelector((state) => state.requirement);
  useEffect(() => {
    dispatch(getRequirementBySlug(slug));
  }, []);
  return { requirement_data };
};

//Edit Data
export const useUpdateRequirement = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Requirement);
  const updateRequirementData = async (id, data) => {
    dispatch(editRequirement(id, data));
  };
  return { updateRequirementData };
};

// Get All Data
export const useAllRequirements = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const requirements_data = useSelector((state) => state.requirement);

  const getRequirementsData = (program) => {
    dispatch(getRequirements({ program }));
  };

  return { requirements_data, getRequirementsData };
};

//Delete Data
export const useDeleteRequirement = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Requirement);
  const deleteRequirementFunction = async (id) => {
    dispatch(deleteRequirement(id));
  };
  return { deleteRequirementFunction };
};
