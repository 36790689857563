import React from 'react';
import { useParams } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import EditSchoolComponent from '../../components/school/EditSchoolComponent';
import { useSingleSchoolBySlug } from '../../shared/hooks/UseSchool';
import { useAllCountries } from '../../shared/hooks/UseCountry';
import DuplicateSchoolComponent from '../../components/school/DuplicateSchoolComponent';
function DuplicateSchool() {
  const params = useParams();

  const { school_data } = useSingleSchoolBySlug(params.slug);
  const { school_loading, school } = school_data;
  const { country_data } = useAllCountries();
  const { countries, countries_loading } = country_data;
  return (
    <div>
      <div className='wrapper'>
        <Auth_header_mobile />
        <AuthHeader />
        {school_loading ? (
          <div>Loading...</div>
        ) : (
          school && (
            <>
              <AuthBreadCrumb title={school.name} />
              <PermissionComponent name={'school'} permission={'update'}>
                <section className='  personal-information ptb-20 '>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <DuplicateSchoolComponent
                          school={school}
                          markets={countries}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </PermissionComponent>
            </>
          )
        )}

        <AuthFooter />
      </div>
    </div>
  );
}

export default DuplicateSchool;
