import React from 'react';

function ViewButtonByID(props) {
  const btnClickedHandler = () => {
    props.clicked(props.data._id);
  };
  const editBtnClickedHandler = () => {
    props.edit_clicked(props.data._id);
  };
  return (
    <div>
      <button className='active-view' onClick={btnClickedHandler}>
        <i className='fa fa-eye' />
        View
      </button>
      <button className='active-view' onClick={editBtnClickedHandler}>
        <i className='fa fa-edit' />
        Edit
      </button>
    </div>
  );
}

export default ViewButtonByID;
