import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  GalleryUpload,
} from '../../components/Form/Form';
import * as Yup from 'yup';

import { EnglishTestScoreEnum } from '../../domain/englishtestscore.enum';
import { useUpdateEnglishTestInfo } from '../../shared/hooks/UseEnglishTestScore';
import moment from 'moment';
import SingleProfileComponent from '../cards/SingleProfileComponent';
import { URI, getDocumentName } from '../../domain/constant';

export const EnglishTestScore = ({ student_info, hideEdit }) => {
  const { userEnglishTest, student } = student_info;
  const { updateEnglishTestInformation } = useUpdateEnglishTestInfo();
  const updateTestScores = (formik) => {
    formik.setFieldValue('total_scores', '');
    formik.setFieldValue('reading_score', '');
    formik.setFieldValue('writing_score', '');
    formik.setFieldValue('listening_score', '');
    formik.setFieldValue('speaking_score', '');
    formik.setFieldValue('date_of_exam', '');
  };
  const [editing, setEditing] = useState(false);
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='d-flex justify-content-between'>
          <h2>English Test Scores</h2>
          {!hideEdit && (
            <div>
              <button
                className='btn btn-assign'
                onClick={() => setEditing(!editing)}
              >
                {editing ? (
                  <i className='fa fa-close'></i>
                ) : (
                  <i className='fa fa-pencil'></i>
                )}
              </button>
            </div>
          )}
        </div>
        {editing ? (
          <Formik
            initialValues={{
              test_name:
                userEnglishTest && userEnglishTest.test_name
                  ? userEnglishTest.test_name
                  : '',
              have_given_test:
                userEnglishTest && userEnglishTest.have_given_test
                  ? userEnglishTest.have_given_test
                  : false,
              total_scores:
                userEnglishTest && userEnglishTest.total_scores
                  ? userEnglishTest.total_scores
                  : '',
              reading_score:
                userEnglishTest && userEnglishTest.reading_score
                  ? userEnglishTest.reading_score
                  : '',
              writing_score:
                userEnglishTest && userEnglishTest.writing_score
                  ? userEnglishTest.writing_score
                  : '',
              listening_score:
                userEnglishTest && userEnglishTest.listening_score
                  ? userEnglishTest.listening_score
                  : '',
              speaking_score:
                userEnglishTest && userEnglishTest.speaking_score
                  ? userEnglishTest.speaking_score
                  : '',
              date_of_exam:
                userEnglishTest && userEnglishTest.date_of_exam
                  ? moment(userEnglishTest.date_of_exam).format('yyyy-MM-DD')
                  : '',
              documents:
                userEnglishTest && userEnglishTest.documents
                  ? userEnglishTest.documents
                  : '',
              expiry_date:
                userEnglishTest && userEnglishTest.expiry_date
                  ? moment(userEnglishTest.expiry_date).format('yyyy-MM-DD')
                  : '',
            }}
            validationSchema={Yup.object({
              test_name: Yup.string().when(['have_given_test'], {
                is: (have_given_test) => {
                  if (have_given_test) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              total_scores: Yup.number().when(['test_name'], {
                is: (test_name) => {
                  if (test_name === EnglishTestScoreEnum.PTE) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.Duolingo) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              reading_score: Yup.number().when(['test_name'], {
                is: (test_name) => {
                  if (test_name === EnglishTestScoreEnum.IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.UKVI_IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.TOEFL) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.PTE) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              writing_score: Yup.number().when(['test_name'], {
                is: (test_name) => {
                  if (test_name === EnglishTestScoreEnum.IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.UKVI_IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.TOEFL) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.PTE) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              listening_score: Yup.number().when(['test_name'], {
                is: (test_name) => {
                  if (test_name === EnglishTestScoreEnum.IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.UKVI_IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.TOEFL) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.PTE) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              speaking_score: Yup.number().when(['test_name'], {
                is: (test_name) => {
                  if (test_name === EnglishTestScoreEnum.IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.UKVI_IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.TOEFL) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.PTE) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              date_of_exam: Yup.date().when(['test_name'], {
                is: (test_name) => {
                  if (test_name === EnglishTestScoreEnum.IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.UKVI_IELTS) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.TOEFL) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.PTE) {
                    return true;
                  }
                  if (test_name === EnglishTestScoreEnum.Duolingo) {
                    return true;
                  }
                },
                then: (schema) => schema.required('Required'),
              }),
              // reading_score: Yup.string().required('Required'),
              // writing_score: Yup.string().required('Required'),
              // listening_score: Yup.string().required('Required'),
              // speaking_score: Yup.string().required('Required'),
              // date_of_exam: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateEnglishTestInformation(student._id, values);
              resetForm();

              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <CheckBox
                        label='Test Name'
                        name='test_name_toefl'
                        checked={
                          formik.values.have_given_test &&
                          formik.values.test_name === EnglishTestScoreEnum.TOEFL
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'test_name',
                            EnglishTestScoreEnum.TOEFL
                          );
                          formik.setFieldValue('have_given_test', true);
                          updateTestScores(formik);
                        }}
                      >
                        <label htmlFor='test_name_toefl'>TOEFL</label>
                      </CheckBox>
                    </div>
                    {formik.values.have_given_test &&
                      formik.values.test_name ===
                        EnglishTestScoreEnum.TOEFL && (
                        <>
                          <div className='give-exam'>
                            <div className='row'>
                              <div className='main-label'>Your Score</div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Reading'
                                  type='number'
                                  name='reading_score'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Listening'
                                  type='number'
                                  name='listening_score'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Writing'
                                  type='number'
                                  name='writing_score'
                                />
                              </div>
                              <div className='col-md-6'>
                                <TextInput
                                  label='Speaking'
                                  type='number'
                                  name='speaking_score'
                                />
                              </div>
                              <div className='col-md-12'>
                                <TextInput
                                  label='Date of exam'
                                  name='date_of_exam'
                                  type='date'
                                />
                              </div>
                              <div className='col-md-12'>
                                <TextInput
                                  label='Expiry Date'
                                  name='expiry_date'
                                  type='date'
                                />
                              </div>
                              <div className='col-md-12'>
                                <GalleryUpload
                                  title='Upload Documents'
                                  item='documents'
                                  formik={formik}
                                  value={formik.values.documents}
                                  hideView={true}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    <div className='col-md-12'>
                      <CheckBox
                        label='Yes'
                        name='test_name'
                        id='test_name_ielts'
                        checked={
                          formik.values.have_given_test &&
                          formik.values.test_name === EnglishTestScoreEnum.IELTS
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'test_name',
                            EnglishTestScoreEnum.IELTS
                          );
                          formik.setFieldValue('have_given_test', true);
                          updateTestScores(formik);
                        }}
                      >
                        <label htmlFor='test_name_ielts'>IELTS</label>
                      </CheckBox>
                      {formik.values.have_given_test &&
                        formik.values.test_name ===
                          EnglishTestScoreEnum.IELTS && (
                          <>
                            <div className='give-exam'>
                              <div className='row'>
                                <div className='main-label'>Your Score</div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Reading'
                                    name='reading_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Listening'
                                    name='listening_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Writing'
                                    name='writing_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Speaking'
                                    name='speaking_score'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Date of exam'
                                    name='date_of_exam'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Expiry Date'
                                    name='expiry_date'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <GalleryUpload
                                    title='Upload Documents'
                                    item='documents'
                                    formik={formik}
                                    value={formik.values.documents}
                                    hideView={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    <div className='col-md-12'>
                      <CheckBox
                        label='Yes'
                        name='test_name'
                        id='test_name_UKVI_IELTS'
                        checked={
                          formik.values.have_given_test &&
                          formik.values.test_name ===
                            EnglishTestScoreEnum.UKVI_IELTS
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'test_name',
                            EnglishTestScoreEnum.UKVI_IELTS
                          );
                          formik.setFieldValue('have_given_test', true);
                          updateTestScores(formik);
                        }}
                      >
                        <label htmlFor='test_name_ielts'>UKVI IELTS</label>
                      </CheckBox>
                      {formik.values.have_given_test &&
                        formik.values.test_name ===
                          EnglishTestScoreEnum.UKVI_IELTS && (
                          <>
                            <div className='give-exam'>
                              <div className='row'>
                                <div className='main-label'>Your Score</div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Reading'
                                    name='reading_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Listening'
                                    name='listening_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Writing'
                                    name='writing_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Speaking'
                                    name='speaking_score'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Date of exam'
                                    name='date_of_exam'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Expiry Date'
                                    name='expiry_date'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <GalleryUpload
                                    title='Upload Documents'
                                    item='documents'
                                    formik={formik}
                                    value={formik.values.documents}
                                    hideView={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    <div className='col-md-12'>
                      <CheckBox
                        label='Yes'
                        name='test_name'
                        id='test-name-PTE'
                        checked={
                          formik.values.have_given_test &&
                          formik.values.test_name === EnglishTestScoreEnum.PTE
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'test_name',
                            EnglishTestScoreEnum.PTE
                          );
                          formik.setFieldValue('have_given_test', true);
                          updateTestScores(formik);
                        }}
                      >
                        <label htmlFor='test-name-PTE'>PTE</label>
                      </CheckBox>
                      {formik.values.have_given_test &&
                        formik.values.test_name ===
                          EnglishTestScoreEnum.PTE && (
                          <>
                            <div className='give-exam'>
                              <div className='row'>
                                <div className='main-label'>Your Score</div>
                                <div className='col-md-12'>
                                  <TextInput
                                    type='number'
                                    label='Total Score'
                                    name='total_scores'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Reading'
                                    name='reading_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Listening'
                                    name='listening_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Writing'
                                    name='writing_score'
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <TextInput
                                    type='number'
                                    label='Speaking'
                                    name='speaking_score'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Date of exam'
                                    name='date_of_exam'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Expiry Date'
                                    name='expiry_date'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <GalleryUpload
                                    title='Upload Documents'
                                    item='documents'
                                    formik={formik}
                                    value={formik.values.documents}
                                    hideView={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    <div className='col-md-12'>
                      <CheckBox
                        label='Yes'
                        name='test_name'
                        id='test_name_Duolingo'
                        checked={
                          formik.values.have_given_test &&
                          formik.values.test_name ===
                            EnglishTestScoreEnum.Duolingo
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            'test_name',
                            EnglishTestScoreEnum.Duolingo
                          );
                          formik.setFieldValue('have_given_test', true);
                          updateTestScores(formik);
                        }}
                      >
                        <label htmlFor='test_name_Duolingo'>Duolingo</label>
                      </CheckBox>
                      {formik.values.have_given_test &&
                        formik.values.test_name ===
                          EnglishTestScoreEnum.Duolingo && (
                          <>
                            <div className='give-exam'>
                              <div className='row'>
                                <div className='main-label'>Your Score</div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Total Score'
                                    type='number'
                                    name='total_scores'
                                  />
                                </div>

                                <div className='col-md-12'>
                                  <TextInput
                                    label='Date of exam'
                                    name='date_of_exam'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <TextInput
                                    label='Expiry Date'
                                    name='expiry_date'
                                    type='date'
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <GalleryUpload
                                    title='Upload Documents'
                                    item='documents'
                                    formik={formik}
                                    value={formik.values.documents}
                                    hideView={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    <div className='col-md-12'>
                      <CheckBox
                        label='Yes'
                        name='have_given_test_check'
                        checked={!formik.values.have_given_test}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.setFieldValue('test_name', '');
                          }
                          formik.setFieldValue('have_given_test', false);
                        }}
                      >
                        <label htmlFor='have_given_test_check'>
                          I don't have this
                        </label>
                      </CheckBox>
                    </div>
                  </div>
                  <div className='save-button'>
                    <button className='btn primary-btn w-100'>
                      Save & Continue
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            <div className='row'>
              <h6 className='mb-3'>
                {userEnglishTest && userEnglishTest.test_name}
              </h6>
              {userEnglishTest && userEnglishTest.have_given_test ? (
                <>
                  <SingleProfileComponent
                    col={6}
                    label={'Reading Score'}
                    value={userEnglishTest && userEnglishTest.reading_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Listening Score'}
                    value={userEnglishTest && userEnglishTest.listening_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Writing Score'}
                    value={userEnglishTest && userEnglishTest.writing_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Speaking Score'}
                    value={userEnglishTest && userEnglishTest.speaking_score}
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Date Of Exam'}
                    value={
                      userEnglishTest &&
                      userEnglishTest.date_of_exam &&
                      moment(userEnglishTest.date_of_exam).format('yyyy-MM-DD')
                    }
                  />
                  <SingleProfileComponent
                    col={6}
                    label={'Expiry Date'}
                    value={
                      userEnglishTest &&
                      userEnglishTest.expiry_date &&
                      moment(userEnglishTest.expiry_date).format('yyyy-MM-DD')
                    }
                  />
                  <div className='col-md-12'>
                    <div> Documents </div>
                    {userEnglishTest &&
                      userEnglishTest.documents &&
                      userEnglishTest.documents.map((item) => {
                        return (
                          <a href={`${URI}${item}`} target='_blank'>
                            {' '}
                            {getDocumentName(item)}{' '}
                          </a>
                        );
                      })}
                  </div>
                </>
              ) : (
                <h5>You don't have given any test</h5>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
