import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

import AgentBankInfo from '../../components/agents/AgentBankInfo';
import AgentProfileComponent from '../../components/agents/AgentProfileComponent';
import ViewAgentComponent from '../../components/agents/ViewAgentComponent';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import UpdatePasswordComponent from '../../components/common/UpdatePasswordComponent';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAllCrmUsers } from '../../shared/hooks/UseCrmUser';
import { useAllSlabs } from '../../shared/hooks/UseSlab';
import {
  useSingleAgent,
  useChangePasswordAgent,
  useUpdateAgent,
  useUpdateAgentStatus,
  useAssignCRMUser,
  useDeleteAgent,
  useChangeCommissionTier,
} from '../../shared/hooks/UseAgent';
import { useUpdateNotification } from '../../shared/hooks/UseNotification';
import * as qs from 'qs';

function ViewAgent() {
  const navigate = useNavigate();
  const { slabData } = useAllSlabs();
  const { slabs } = slabData;
  const [checkDeleteStudent, setCheckDeleteStudent] = useState(false);
  const { changeCommissionTierFunction } = useChangeCommissionTier();
  const params = useParams();
  const { data } = useSingleAgent(params.id);
  const { agent, agent_loading } = data;
  const { updateAgentPasswordData } = useChangePasswordAgent();
  const { updateAgentStatusData } = useUpdateAgentStatus();
  const { deleteAgentFunction } = useDeleteAgent();
  const { updateCRMUser } = useAssignCRMUser();
  const { crm_user_data } = useAllCrmUsers();
  const { crm_users } = crm_user_data;
  const [assignApplicationModalOpen, setAssignApplicationModalOpen] =
    useState(false);

  const [showChangeTier, setShowChangeTier] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '450px',
    },
  };
  const deleteStudentBtnClicked = async () => {
    await deleteAgentFunction(params.id);
    navigate('/settings/agents');
  };

  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [notificationParam, setNotificationParam] = useState(null);

  // const [notificatiton_data, reloadNotificationData] =
  //   useSelectAllNotification();
  const [updateData] = useUpdateNotification();

  useEffect(() => {
    if (notificationParam) {
      updateData(notificationParam, { is_read: true });
      // reloadNotificationData();
    }
  }, [notificationParam]);

  useEffect(() => {
    if (queryParams.notification) {
      setNotificationParam(queryParams.notification);
    }
  }, [queryParams && queryParams.notification]);

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={agent && agent.agent && agent.agent.first_name} />
      <PermissionComponent name={'agents'} permission={'view'}>
        {agent && (
          <section className='profile-main ptb-20'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='heading-with-flex'>
                    <div className='main-sec-heading'>
                      <h1>{agent && agent.agent && agent.agent.first_name} </h1>
                      <span
                        className={
                          agent.agent &&
                          agent.agent.profile_status &&
                          agent.agent.profile_status === 'PENDING'
                            ? 'badge warning'
                            : agent.agent.profile_status === 'ACTIVE'
                            ? 'badge success'
                            : 'badge danger'
                        }
                      >
                        {agent.agent.profile_status}
                      </span>{' '}
                    </div>
                    {agent.agent.profile_status &&
                      agent.agent.profile_status === 'PENDING' && (
                        <div className='approve-reject'>
                          <button
                            class='btn btn-approve'
                            onClick={() =>
                              updateAgentStatusData(params.id, {
                                profile_status: 'ACTIVE',
                              })
                            }
                          >
                            <i class='fa fa-check'></i>Approve
                          </button>

                          <button
                            className='btn btn-rejected'
                            onClick={() =>
                              updateAgentStatusData(params.id, {
                                profile_status: 'REJECTED',
                              })
                            }
                          >
                            <i class='fa fa-close'></i>Reject
                          </button>
                        </div>
                      )}

                    <button
                      className='btn btn-rejected'
                      onClick={() => setCheckDeleteStudent(true)}
                    >
                      <i class='fa fa-close'></i>Delete
                    </button>
                  </div>
                </div>
                <div className='col-md-6 col-6'>
                  <div className='d-flex'>
                    <div className='add-student-btn'>
                      {agent.agent && (
                        <button
                          className='btn btn-success'
                          onClick={() => setAssignApplicationModalOpen(true)}
                        >
                          Assign User
                        </button>
                      )}
                    </div>
                    <PermissionComponent
                      name={'agents'}
                      permission={'assign_tier'}
                    >
                      <div className='add-student-btn'>
                        {agent.agent && (
                          <button
                            onClick={() => setShowChangeTier(true)}
                            className='btn primary-btn-h '
                          >
                            Change Agent Tier
                          </button>
                        )}
                      </div>
                    </PermissionComponent>
                    <div className='add-student-btn'>
                      {agent.agent && (
                        <Link
                          to={`/settings/agents/${agent.agent.id}/edit`}
                          className='btn primary-btn-h '
                        >
                          Edit Agent
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {agent && (
          <section className='main-school-single-view ptb-20 '>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <ViewAgentComponent agent={agent.agent} />
                  <h3> Other Information </h3>
                  <br />
                  <AgentProfileComponent agent={agent.agentInfo} />
                  <h3> Bank Account Information </h3>
                  <br />
                  {}
                  <AgentBankInfo
                    agent={
                      agent.agentInfo && agent.agentInfo.bank_account_details
                    }
                  />
                  {/* <ViewEducationLevelsComponent education_level={education_level} /> */}
                </div>
                <div className='col-md-4'>
                  {agent.agent && (
                    <UpdatePasswordComponent
                      navigationLink={'/settings/agents/'}
                      onSubmitHandler={(values) =>
                        updateAgentPasswordData(agent.agent._id, values)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </PermissionComponent>
      <AuthFooter />
      <Modal
        style={customStyles}
        isOpen={assignApplicationModalOpen}
        onRequestClose={() => setAssignApplicationModalOpen(false)}
        contentLabel='Assign User'
      >
        <h2>Assign User</h2>
        <Formik
          initialValues={{
            crm_user: '',
          }}
          validationSchema={Yup.object({
            crm_user: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            updateCRMUser(agent.agent._id, values);
            resetForm();
            setSubmitting(false);
            setAssignApplicationModalOpen(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='User'> Select User </label>
                    <select
                      name='crm_user'
                      onChange={formik.handleChange}
                      value={formik.values.crm_user}
                      className='form-control'
                      id='User'
                    >
                      <option value=''></option>
                      {crm_users &&
                        crm_users.map((item) => {
                          return (
                            <option value={item._id}>
                              {' '}
                              {item.first_name} {item.last_name}
                            </option>
                          );
                        })}
                    </select>
                    {formik.errors && formik.errors.crm_user && (
                      <p className='text-danger'> {formik.errors.crm_user} </p>
                    )}
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-success btn-sm'
                      onClick={formik.handleSubmit}
                      type='submit'
                    >
                      <i className='fa fa-save'></i>
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <hr />
        <div className='d-flex justify-content-end'>
          <a onClick={() => setAssignApplicationModalOpen(false)}>X</a>
        </div>
      </Modal>
      {checkDeleteStudent && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setCheckDeleteStudent(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p
                    className='text-danger text-bold'
                    style={{ fontSize: '20px' }}
                  >
                    Once you click on the "Delete" button,all Agent data (Agent
                    Information) will be deleted permanently.This action of
                    yours is not reversible.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setCheckDeleteStudent(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteStudentBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showChangeTier && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <h4 className='modal-title w-100'>Change Agent Tier</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setShowChangeTier(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <Formik
                    initialValues={{
                      slab: '',
                    }}
                    validationSchema={Yup.object({
                      slab: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      changeCommissionTierFunction(agent.agent._id, values);
                      resetForm();
                      setSubmitting(false);
                      setAssignApplicationModalOpen(false);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-12'>
                              <label htmlFor='User'>
                                {' '}
                                Select Commission Tier{' '}
                              </label>
                              <select
                                name='slab'
                                onChange={formik.handleChange}
                                value={formik.values.slab}
                                className='form-control'
                                id='User'
                              >
                                <option value=''></option>
                                {slabs &&
                                  slabs.map((item) => {
                                    return (
                                      <option value={item._id}>
                                        {' '}
                                        {item.name}{' '}
                                      </option>
                                    );
                                  })}
                              </select>
                              {formik.errors && formik.errors.slab && (
                                <p className='text-danger'>
                                  {' '}
                                  {formik.errors.slab}{' '}
                                </p>
                              )}
                            </div>
                            <div className=''>
                              <button
                                className='btn btn-success btn-sm'
                                onClick={formik.handleSubmit}
                                type='submit'
                              >
                                <i className='fa fa-save'></i>
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewAgent;
