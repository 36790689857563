import React from 'react';
import { Footer, Header } from '../../../components/common';

function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="condition-page">
                <h2>Privacy and Cookies Policy</h2>
                <h4>Please read carefully before using the portal.</h4>
                <p>
                  Welcome to the website “Www.edchimp.com” which is owned by
                  “EdChimp” These terms and conditions cover every individual
                  visiting this website and it is assumed that by accessing and
                  using www.edchimp.com, you accept this “Terms of Use” in full
                  and have gone through and consent univocally and irrevocably
                  to the same. If you disagree with these conditions you must
                  not use this website.
                </p>

                <ol>
                  <li>
                    <b> Website Overview:</b>
                  </li>
                </ol>
                <p>
                  EdChimp is the manager, moderator and operator of the website
                  www.edchimp.com and all of its versions including mobile and
                  applications. Www.edchimp.com is a search engine for the
                  courses or colleges/institutions and assists students by
                  providing information on the admission process details of the
                  courses, colleges/institutions of their interest. EdChimp
                  guides students through the application process and acquaints
                  them with all the available study options. EdChimp does not
                  take applications/registrations on behalf of any college or
                  institution irrespective of whether they are partner college
                  or other listed college. By applying to any course or
                  college/institution on the website, the student is deemed to
                  have applied for the assistance of EdChimp team.
                </p>
                <ol start={2}>
                  <li>
                    <b> Eligibility:</b>
                  </li>
                </ol>
                <p>
                  The website is not designed to attract the users below 13
                  years and thus the company does not intend to keep data of the
                  users below the specified age. By assessing or using the
                  portal, the user is deemed to have warranted and represented
                  that he/she fulfils the aforesaid requirement of minimum. Upon
                  receipt of any information contrary to the aforesaid, the user
                  and its information shall be deleted.
                </p>
                <ol start={3}>
                  <li>
                    <b> Creating a user account on www.edchimp.com:</b>
                  </li>
                </ol>
                <p>
                  The website intents to protect private information provided by
                  the user while creating a user account as per the terms and
                  conditions specified herein. By creating an account, the user
                  agrees to the terms required to protect the confidentiality of
                  username and password for the account and shall be solely
                  liable for any activity conducted using the user account. The
                  user also agrees to accept all risk and responsibilities for
                  activity done under the username. User also agrees to receive
                  SMS and Emails containing the information of the college and
                  course he/she applies in and also of related colleges and
                  courses.
                </p>
                <ol start={4}>
                  <li>
                    <b> Privacy Policy:</b>
                  </li>
                </ol>
                <p>
                  The privacy policy is applicable only for EdChimp and not for
                  the websites linked through it, which shall be governed by its
                  respective privacy policies and/or terms of use. The website
                  utilises cookies and other tracking technologies. Some cookies
                  and other technologies may serve to recall Personal
                  Information previously indicated by a Web user. Most browsers
                  allow you to control cookies, including whether or not to
                  accept them and how to remove them. By providing us your
                  information in the ways stated hereinbelow, you agree with the
                  privacy policy and give us consent for usage of information by
                  us in the manner stated herein.
                </p>
                <ol start={5}>
                  <li>
                    <b> Information Collected by the Website:</b>
                  </li>
                </ol>
                <p>
                  Means of data collection on EdChimp are- Contact Forms (The
                  contact forms on the website are made to simplify the process
                  of data presentation by collecting the user interests,
                  educational qualification and age, etc), and Login/Signup (By
                  creating a user account visitor agrees the terms of conditions
                  of the website. The data collected may consist of personal or
                  non-personal information. We may store, collect, use and
                  process your Information India subject to compliance under
                  applicable laws. The website collects user information to
                  provide suggestions tailored as per the students' profile and
                  keep them informed about the ongoing application processes
                </p>
                <ol start={6}>
                  <li>
                    <b>
                      {' '}
                      Opt-in Mails and Other ways of data usage by the Company:
                    </b>
                  </li>
                </ol>
                <p>
                  The company can use data collected through the website in
                  various ways such as- a) Opt-in Mails- Company sends
                  customized mails on the users opting for newsletters or
                  notification on admission process and entrance tests. Users
                  can anytime unsubscribe from the service b) Feedbacks: The
                  Company may contact its visitors or users for feedback on any
                  new feature or services. If not interested, users can drop a
                  mail on privacy@www.edchimp.com c) Geographical Data: we use
                  geographical or demographical data to provide location wise
                  customized data. D) Cookies and IP data are used to provide
                  most relevant results through search. The website also stores
                  data other than the content which provides information on
                  personal identity in the form of IP and cookies. It is
                  henceforth considered under the category of
                  non-personal-identifiable data which also includes search
                  history, queries submitted, education interests, date and
                  time, domain, advertisement response. The website can also use
                  a conversion pixel (a 1px. X 1px. image) to track the user
                  behavior on our leads collection form as it is an intact part
                  of our Business. We can use the data collected through our
                  channel in the analysis of trends and can also be forwarded to
                  the educational service providers depending upon the usage
                  history. The data so collected can be used in the following
                  manner and ways:
                </p>
                <p>
                  Email distribution/SMS/Telephone as described in the
                  application form and for administrative purposes (such as to
                  inform you and apprise of you of the information or any change
                  herein for the college/course of your interested or related
                  course/college). In accordance with instructions on the site,
                  you may opt not to receive any such communication in the
                  future.
                </p>
                <p>
                  Display banner (and similar) advertising to you in connection
                  with the site that is more targeted to you specifically. In
                  doing this type of targeting, EdChimp or its ad server,
                  connect you with the targeting criteria.
                </p>
                <p>
                  By using the website and/or registering yourself with us you
                  authorize us to contact you via email or phone call or SMS and
                  offer you our services, imparting product knowledge, offer
                  promotional offers running on website & offers offered by
                  third parties, for which reasons, personally identifiable
                  information may be collected. And irrespective of the fact if
                  also you have registered yourself under DND or DNC or NCPR
                  service, you still authorize us to give you a call from
                  EdChimp for the above mentioned purposes till 365 days of your
                  registration with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
