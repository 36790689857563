import React from 'react';
import { Form } from 'formik';

import { TextInput, HTMLEditor, GalleryUpload, TextArea } from '../Form/Form';
function NotesForm({ formik }) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-12'>
          <TextInput label='Enter Note Title Here' name='title' />
        </div>
        <div className='mb-3 rich'>
          {/* <TextArea label={'Notes'} name='notes' /> */}
          <label htmlFor='noes'>Notes</label>
          <HTMLEditor
            item='notes'
            formik={formik}
            col={12}
            value={formik.values.notes}
            hideCode={true}
          />
        </div>
        <div className='mb-3 rich'>
          <GalleryUpload
            item='media'
            formik={formik}
            col={12}
            hideView={true}
            value={formik.values.media}
          />
        </div>

        <div className='save-button'>
          <button type='submit' className='btn primary-btn w-100'>
            Create
          </button>
        </div>
      </div>
    </Form>
  );
}

export default NotesForm;
