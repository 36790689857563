import React from 'react';

import slugify from 'react-slugify';
import { Formik, Form, FieldArray } from 'formik';
import {
  TextInput,
  SelectBox,
  TextArea,
  HTMLEditor,
  FileUpload,
  GalleryUpload,
} from '../Form/Form';
import CountryStateCity from '../common/CountryStateCity';
import { Country, State, City } from 'country-state-city';
function SchoolForm({ formik, dropdown_options }) {
  const { markets } = dropdown_options;
  const handleMarketChange = (value) => {};
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          {/* <div className='file-upload'>
                <label>School Logo Upload</label>
                <div className='input-group'>
                  <div className='custom-file'>
                    <input
                      type='file'
                      className='custom-file-input'
                      id='inputGroupFile04'
                    />
                  </div>
                </div>
              </div> */}
          <FileUpload
            title={'School Logo Upload'}
            item='logo'
            formik={formik}
            value={formik.values.logo}
          />
        </div>
        <div className='col-md-6'>
          <GalleryUpload
            title={'School Photos'}
            item='school_photos'
            formik={formik}
            value={formik.values.school_photos}
          />
          {/* <div className='file-upload'>
                <label>School Photos</label>
                <div className='input-group'>
                  <div className='custom-file'>
                    <input
                      type='file'
                      className='custom-file-input'
                      id='inputGroupFile04'
                    />
                  </div>
                </div>
              </div> */}
        </div>

        <div className='col-md-4'>
          <TextInput
            label='School Name'
            name='name'
            onChange={(e) => {
              formik.setFieldValue('name', e.target.value);
              formik.setFieldValue('slug', slugify(e.target.value));
            }}
          />
        </div>

        <div className='col-md-4'>
          <TextInput label='School Email' name='email' />
        </div>

        <div className='col-md-4'>
          <TextInput
            label='School Phone'
            name='phone'
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === '' || regex.test(e.target.value)) {
                formik.setFieldValue('phone', e.target.value);
              }
            }}
          />
        </div>
        <div className='col-md-4'>
          <SelectBox label='Currency Country' name='market'>
            <option value=''></option>
            {markets &&
              markets.map((item) => {
                return <option value={item._id}>{item.name}</option>;
              })}
          </SelectBox>
        </div>

        <div className='mb-3 rich'>
          <HTMLEditor
            title='About School'
            item='about'
            formik={formik}
            col={12}
            value={formik.values.about}
          />
        </div>

        <CountryStateCity col={4} formik={formik} />
        <div className='col-md-6'>
          <TextInput
            label='Dial Code'
            name='dial_code'
            value={formik.values.dial_code}
            disabled
          />
        </div>

        <div className='col-md-6'>
          <SelectBox name='time_zone' label={'Time Code'}>
            <option value=''></option>

            {formik.values.country_code &&
              Country.getCountryByCode(formik.values.country_code).timezones &&
              Country.getCountryByCode(
                formik.values.country_code
              ).timezones.map((item) => {
                return (
                  <option value={`${item.gmtOffsetName} ${item.tzName}`}>
                    {item.gmtOffsetName} {item.tzName}
                  </option>
                );
              })}
          </SelectBox>
        </div>
        <div className='col-md-12'>
          <TextArea
            name='address'
            rows='3'
            cols='10'
            type='text'
            label='School Address '
          />
        </div>

        <div className='col-md-6'>
          <TextInput label='School Pin Code' name='postal_code' />
        </div>
        <div className='col-md-6'>
          <TextInput label='School Founded Year' name='founded_in' />
        </div>
        <div className='col-md-4'>
          <TextInput type='number' label='Latitude' name='latitude' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Longitude' name='longitude' />
        </div>

        <div className='col-md-6'>
          <TextInput label='Video Link' name='video_link' type='url' />
        </div>
        <div className='col-md-6'>
          <TextInput label='Website Link' name='website' type='url' />
        </div>
        <div className='col-md-4'>
          <TextInput
            type='number'
            label='Tuition Fees'
            name='tuition_deposit'
          />
        </div>
        <div className='col-md-4'>
          <SelectBox label='Institution Type' name='institution_type'>
            <option value=''>Select</option>
            <option value='Private'>Private</option>
            <option value='Government'>Government</option>
            <option value='Others'>Others</option>
          </SelectBox>
        </div>
        <div className='col-md-4'>
          <TextInput label='Gap' name='gap' />
        </div>

        {/* <div className='col-md-4'>
          <TextInput
            label='Total Number of Students'
            name='total_number_of_students'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Average Graduate'
            name='average_graduate'
            type='number'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Average Undergraduate'
            name='average_undergraduate'
            type='number'
          />
        </div> */}
        <div className='col-md-4'>
          <TextInput label='Source' name='source' type='text' />
        </div>
        {/* <div className='col-md-6'>
          <TextInput label='Cost Of Living' name='cost_of_living' />
        </div> */}
        {/* <div className='col-md-6'>
          <TextInput label='Currency' name='currency' />
        </div> */}
        <div className='col-md-6'>
          <SelectBox label='Commission Type' name='commission_type'>
            <option value=''></option>
            <option value='percentage'>Percentage</option>
            <option value='fixed'>Fixed</option>
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Commission Year'
            name='commission_year'
            type='number'
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Commission'
            name='commission_percentage'
            type='number'
          />
        </div>
        <div className='col-md-6'>
          <TextArea label='Scholarship' name='scholarship' />
        </div>

        <div className='label-heading'>
          <h5>Social Links</h5>
          <div className='col-md-12'>
            <TextInput label='Facebook Link' name='facebook' />
          </div>
          <div className='col-md-12'>
            <TextInput label='Twitter Link' name='twitter' />
          </div>
          <div className='col-md-12'>
            <TextInput label='LinkedIn Link' name='linkedin' />
          </div>
        </div>

        <div className='label-heading'>
          <h5>Features</h5>
          <FieldArray
            name='school_features'
            render={(arrayHelpers) => (
              <div>
                {formik.values.school_features.map((friend, index) => (
                  <div key={index}>
                    {/* <input
                          name={`todos[${index}].name`}
                          value={formik.values.todos[index].name}
                          onChange={formik.handleChange}
                        /> */}
                    <div className='d-flex justify-content-between'>
                      <div className='label-featu'>Features {index + 1}</div>
                      <div>
                        <a onClick={() => arrayHelpers.remove(index)}>
                          <i className='fa fa-close'></i>
                        </a>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <TextInput
                        label='Title'
                        name={`school_features[${index}].title`}
                      />
                    </div>
                    <div className='col-md-12'>
                      <TextInput
                        label='Description'
                        name={`school_features[${index}].description`}
                      />
                    </div>
                  </div>
                ))}
                <button
                  type='button'
                  className='btn primary-btn'
                  onClick={() => arrayHelpers.push({ name: '' })}
                >
                  Add Feature
                </button>
              </div>
            )}
          />
        </div>
      </div>

      <div className='save-button'>
        <button type='submit' className='btn primary-btn w-100'>
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default SchoolForm;
