import React from 'react';

import { CheckBox } from '../../components/Form/Form';
function ProgramsRolesComponents({ formik }) {
  return (
    <div className='permissions-box'>
      <div className='col-md-12'>
        <div className='check-all-flex'>
          <div>
            <label className='big'>Programs</label>
          </div>
          <div>
            <CheckBox
              name='program.check_all'
              onChange={(e) => {
                if (e.target.checked) {
                  formik.setFieldValue('program', {
                    create: true,
                    get_all: true,
                    view: true,
                    delete: true,
                    update: true,
                    check_all: true,
                    export: true,
                  });
                } else {
                  formik.setFieldValue('program', {
                    create: false,
                    get_all: false,
                    view: false,
                    delete: false,
                    update: false,
                    check_all: false,
                    export: false,
                  });
                }
              }}
            >
              Check All
            </CheckBox>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <CheckBox
              name='program.create'
              // checked={formik.values.create === formik.values.create}
              // onChange={(e) => {
              //   formik.setFieldValue();
              // }}
            >
              Create
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox name='program.get_all'>Get All</CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox name='program.view'>View</CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox name='program.delete'>Delete</CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox name='program.update'>Update</CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox name='program.export'>Export</CheckBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramsRolesComponents;
