import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { useAppSelector } from "../../state/hooks";
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { useToggleNotification } from '../../shared/hooks/UseNotification';

const ProtectedRoute = ({ children }) => {
  const user = useCheckAuthenticated();
  console.count('User Authenticated');
  const { toggleNotificationData, show_notification } = useToggleNotification();
  const location = useLocation();
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        toggleNotificationData(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  return user.user_loading ? (
    <div> Loading...</div>
  ) : !user.isAuthenticated ? (
    <Navigate to={'/login'} state={{ from: location }} replace />
  ) : (
    <div
      style={{ zIndex: 0, position: 'relative' }}
      // onClick={() => toggleNotificationData(false)}
    >
      {children}
    </div>
  );
};

export default ProtectedRoute;
