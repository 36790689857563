import React from 'react';

function ViewProgramBtn(props) {
  const btnClickedHandler = () => {
    props.clicked(props.data.slug, props.data.school && props.data.school.id);
  };
  return (
    <div>
      <button className='active-view' onClick={btnClickedHandler}>
        <i className='fa fa-eye' />
        View
      </button>
    </div>
  );
}

export default ViewProgramBtn;
