import React from 'react';

function SingleProfileComponent({ label, value, col }) {
  return (
    <div className={col ? `col-md-${col}` : 'col-md-12'}>
      <div className='single-info-card'>
        <div className='single-info-label'> {label} </div>
        <div className='single-info-value'>{value ? value : '-'}</div>
      </div>
    </div>
  );
}

export default SingleProfileComponent;
