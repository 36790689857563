import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addPermission,
  getPermissions,
  getPermission,
  editPermission,
  deletePermission,
} from '../../store/actions/permission_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreatePermission = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.permission);
  const addPermissionData = async (data) => {
    dispatch(addPermission(data));
  };
  return { data, addPermissionData };
};

// Get Single Data
export const useSinglePermission = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.permission);
  useEffect(() => {
    dispatch(getPermission(id));
  }, [id]);
  return { data };
};

//Edit Data
export const useUpdatePermission = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Permission);
  const updatePermissionData = async (id, data) => {
    dispatch(editPermission(id, data));
  };
  return { updatePermissionData };
};

// Get All Data
export const useAllPermissions = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.permission);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getPermissions({}));
    }, 1000),
    []
  );

  return { data, setPageNumber };
};

//Delete Data
export const useDeletePermission = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Permission);
  const deletePermissionFunction = async (id) => {
    dispatch(deletePermission(id));
  };
  return { deletePermissionFunction };
};
