import React from 'react';
import { Link } from 'react-router-dom';

import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import CountriesComponent from '../../components/country/CountriesComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllCountries } from '../../shared/hooks/UseCountry';
import FilterComponent from '../../components/filter/FilterComponent';
function Countries() {
  const { country_data, setPageNumber } = useAllCountries();
  const {
    countries_loading,
    countries,
    country_page,
    country_pages,
    total_countries,
  } = country_data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Countries'} />
      <SettingNavigationComponent active_link={SETTING_MENU_ENUM.COUNTRY} />
      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Countries</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'country'} permission={'create'}>
                <div className='add-student-btn'>
                  <Link to={links.addCountry} className='btn primary-btn-h '>
                    Add Country
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <section className='students-section'>
        {countries && (
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <CountriesComponent
                  countries_loading={countries_loading}
                  countries={countries}
                  country_page={country_page}
                  country_pages={country_pages}
                  total_countries={total_countries}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <AuthFooter />
    </div>
  );
}

export default Countries;
