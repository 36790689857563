import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SingleApplicationComponent from '../../components/my_applications/SingleApplicationComponent';
import {
  useSingleApplication,
  useCreateApplicationRequirement,
} from '../../shared/hooks/UseApplication';
import AddApplicationRequirementsComponent from '../../components/programs/AddApplicationRequirement';
import { Formik } from 'formik';
import AddApplicationRequirementForm from '../../components/programs/AddApplicationRequirementForm';
import * as Yup from 'yup';
import { useAllTemplates } from '../../shared/hooks/UseTemplate';

function AddSingleApplicationRequirements() {
  const params = useParams();
  const { application_data } = useSingleApplication(params.id);
  const { addApplicationRequirementFunction } =
    useCreateApplicationRequirement();
  const { application } = application_data;
  const navigate = useNavigate();
  const { template_data, setPageNumber } = useAllTemplates();
  const {
    templates_loading,
    templates,
    template_page,
    template_pages,
    total_templates,
  } = template_data;

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const saveTemplate = async () => {
    const filterData = templates.filter(
      (item) => item._id === selectedTemplate
    );
    await addApplicationRequirementFunction(params.id, filterData[0]);
    navigate(`/my-applications/${params.id}`);
  };

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb
        title={
          application &&
          application.applicationDetails &&
          `Application ID ${application.applicationDetails.application_id}`
        }
      />

      <section className='  personal-information ptb-20 '>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div>
                <div className='personal-information-box clearfix'>
                  <div className='main-sec-heading'>
                    <h1>Add Application Requirements</h1>
                  </div>

                  <div className='card mb-3'>
                    <div className='card-body'>
                      <div> Select Predefined requirement templates </div>
                      <div>
                        <select
                          onChange={(e) => setSelectedTemplate(e.target.value)}
                          className='form-control'
                        >
                          {templates &&
                            templates.map((template) => {
                              return (
                                <option value={template._id}>
                                  {template.title}
                                </option>
                              );
                            })}
                        </select>
                        <button
                          onClick={() => saveTemplate()}
                          className='btn btn-primary'
                        >
                          {' '}
                          Save{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div> Create a new requirement </div>
                  <Formik
                    initialValues={{
                      requirement_stage: '',
                      title: '',
                    }}
                    validationSchema={Yup.object({
                      requirement_stage: Yup.string().required('Required'),
                      title: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      // values.program = program;
                      // addAgentData(values);
                      // values.program = program;
                      addApplicationRequirementFunction(params.id, values);
                      navigate(`/my-applications/${params.id}`);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      return <AddApplicationRequirementForm formik={formik} />;
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default AddSingleApplicationRequirements;
