import React from 'react';

function ViewDisciplineComponent({ discipline }) {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Name</h2>
          <span>{discipline.name}</span>
        </div>
      </div>
    </div>
  );
}

export default ViewDisciplineComponent;
