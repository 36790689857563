import React from 'react';
import { Footer, Header } from '../../../components/common';

function TermsAndConditions() {
  return (
    <div>
      <Header />
      <section className='ptb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='condition-page'>
                <h2>Terms and Conditions – Edchimp Platform Users</h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Welcome to{' '}
                    <strong>
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                    </strong>{' '}
                    Please read these terms and conditions (the “Terms and
                    Conditions”) and privacy policy accessible via the following
                    link https://www.edchimp.com/privacy-policy (the “Privacy
                    Policy”) before using the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform (as defined herein), which, for the avoidance of
                    doubt, includes the
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>{' '}
                    website located at www.edchimp.com and any other websites or
                    links owned and/or operated by{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    (collectively, the “Website”). These Terms and Conditions
                    govern your use, either on your own behalf or on behalf of
                    the entity, School or organization that you may represent,
                    of the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform (as defined herein).&nbsp;
                  </span>
                </p>
                <p />
                <p>
                  <span style={{ fontWeight: 400 }}>
                    By accessing the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform, clicking “Accept”, or otherwise signing an order
                    form and/or agreement that incorporates these Terms and
                    Conditions by reference, acknowledge you are entering into
                    an agreement with
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Inc.{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    “us”, “we”, “our”, together with you, the “Parties” and each
                    of you or us, a “Party”), under which you, on your own
                    behalf or on behalf of the entity, School or organization
                    that you may represent, agree to be be bound by, and comply
                    with, these Terms and Conditions, including, but not limited
                    to, as applicable to the user (as defined herein), any
                    Recruitment Partner/Counsellor Services Agreement (as
                    defined herein),{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Partner Agreement&nbsp; (as defined herein), or other
                    agreement between you and us, whether verbal or in writing,
                    as well as the Privacy Policy and other documents referenced
                    herein (collectively, the “Agreement”), as may be updated
                    from time to time. If you do not agree to be bound by these
                    Terms and Conditions, or other referenced agreements or
                    documentation, you must cease to access or use the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform.
                  </span>
                </p>
                <p />
                <p>
                  <span style={{ fontWeight: 400 }}>
                    In the event of conflict between these Terms and Conditions
                    and any Recruitment Partner/Counsellor Services Agreement or
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Partner Agreement, the Recruitment Partner/Counsellor
                    Services Agreement or{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Partner Agreement shall take precedence to the extent of any
                    conflict. In the event of conflict between these Terms and
                    Conditions and any other agreement or understanding between
                    you and us, these Terms and Conditions shall take precedence
                    to the extent of any conflict.
                  </span>
                </p>
                <p />
                <p>
                  <span style={{ fontWeight: 400 }}>
                    By agreeing to this Agreement, you represent that you have
                    reached the age of majority in your jurisdiction, you have
                    the capacity to enter into binding obligations, and all
                    information you supply to us is true, accurate, current and
                    complete. If you are using the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform on behalf of another person or entity, you
                    represent that you have the authority to bind such person or
                    entity to these Terms and Conditions.
                  </span>
                </p>
                <p />
                <p>
                  <b>
                    If you have any questions or concerns about the terms of
                    this Agreement, please contact us at connect@edchimp.com.
                  </b>
                </p>
                <div>
                  <ol start={1}>
                    <li>
                      <b> Definitions</b>
                    </li>
                  </ol>
                  <ul>
                    <div className=' '></div>
                    <h5> In these Terms and Conditions:</h5>
                    <li>
                      “Access Information” has the meaning set out in Section 4.
                    </li>
                    <li>
                      “Agreement” has the meaning set out in the first paragraph
                      of these Terms and Conditions.
                    </li>
                    <li>
                      “Applicable Law” means any domestic or foreign law, rule,
                      statute, subordinate legislation, regulation, by-law,
                      order, ordinance, protocol, code, guideline, treaty,
                      policy, notice, direction or judicial, arbitral,
                      administrative, ministerial or departmental judgment,
                      award, decree, treaty, directive, or other requirement or
                      guideline published or in force at any time during the
                      term of this Agreement which applies to or is otherwise
                      intended to govern or regulate any person (including any
                      Party), property, transaction, activity, event or other
                      matter, including any rule, order, judgment, directive or
                      other requirement or guideline issued by any Governmental
                      or Regulatory Authority.
                    </li>
                  </ul>

                  <p />

                  <p />
                  <ol start={2}>
                    <li>
                      <b> Application of these Terms and Conditions</b>
                    </li>
                  </ol>
                  <p>
                    These Terms and Conditions apply to your access to and use
                    of the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform and the Services offered through the
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform. Subject to your compliance with these Terms and
                    Conditions, we will use commercially reasonable efforts to
                    make the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform available for your use in accordance with any
                    documentation or guidelines we make available to you.
                  </p>
                  <p>
                    Please note that while these Terms and Conditions apply to
                    all users of the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform, Section 21 applies specifically to Student users,
                    while Section 22 applies specifically to Recruitment Partner
                    or Counsellor users and Section 23 applies specifically to{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Partner users.
                  </p>
                  <ol start={3}>
                    <li>
                      <b>
                        {' '}
                        Changes to these Terms and Conditions and the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform
                      </b>
                    </li>
                  </ol>
                  <p>
                    Except where prohibited by Applicable Law, we may update
                    these Terms and Conditions at any time by posting a new
                    version to the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform. You are responsible for monitoring the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform for any such changes and for reviewing such
                    changes. Your continued access to or use of the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform after any changes to these Terms and Conditions
                    indicates your acceptance of such changes.
                  </p>
                  <ul>
                    <h5>
                      {' '}
                      Similarly, we reserve the right to change, suspend or
                      terminate the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform at any time, including the availability of any
                      Content, without notice, including:
                    </h5>
                    <li>(i) for scheduled maintenance;</li>
                    <li>
                      (ii) if you violate any provision of these Terms and
                      Conditions; or
                    </li>
                    <li>(iii) to address any emergency security concerns.</li>
                  </ul>

                  <ol start={4}>
                    <li>
                      <b> Registration and Account Information</b>
                    </li>
                  </ol>
                  <p>
                    To access or use the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform, you may either be required to register for a user
                    account using the
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform or be provided with an access key or be authorized
                    by the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform in a customized way as set out in a mutual written
                    agreement. You agree to provide accurate, current and
                    complete information in such registration and to update your
                    information as necessary. We reserve the right to suspend or
                    terminate your account or access or authorization if we have
                    reason to believe your information is inaccurate, outdated
                    or incomplete and may, in our sole discretion, accept or
                    reject your registration, request to access, or
                    authorization for the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform, and suspend or terminate your account for any
                    reason. You are responsible for maintaining the
                    confidentiality of your password, account and access key, as
                    applicable (“Access Information”), and we are not
                    responsible for any actions taken by any other person or
                    entity using your Access Information.
                  </p>

                  <ol start={5}>
                    <li>
                      <b>
                        {' '}
                        License to Use the{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform{' '}
                      </b>
                    </li>
                  </ol>
                  <p>
                    Subject to all terms and conditions in the Agreement and any
                    restrictions contained in the Content, we hereby grant to
                    you a personal, non-exclusive, revocable, non-transferable
                    license to access the{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    Platform solely for the purposes of accessing or using the
                    Services and Content available to you.
                  </p>
                  <ol start={6}>
                    <li>
                      <b>Your Data</b>
                    </li>
                  </ol>
                  <p>
                    By visiting or using{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    ’s Platform or Services, you understand that your data may
                    be collected for various purposes and you understand that
                    any information you share with{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>{' '}
                    will be processed in accordance with{' '}
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>
                    ’s Privacy Policy.
                  </p>
                  <p>
                    <strong>
                      <strong>EdChimp </strong>
                    </strong>{' '}
                    reserves the right at all times to disclose any Personal
                    Information as it deems necessary to satisfy any Applicable
                    Law, legal process or requirements of a Governmental or
                    Regulatory Authority.
                  </p>
                  <ul>
                    <h5>
                      {' '}
                      When you share information with{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                    </h5>
                    <li>
                      Your information may be collected by{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>{' '}
                      in various ways depending upon how you use{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      ’s Platform and Services.
                    </li>
                    <li>
                      Any time{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>{' '}
                      requests to collect, use, disclose, store or destroy your
                      Personal Information,{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>{' '}
                      will provide an explanation of why we need that
                      information from you, and collect consent prior to
                      collecting your Personal Information. The purposes for
                      which your Personal Information will be collected are
                      covered in this Agreement and in{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      ’s Privacy Policy.
                    </li>
                    <li>
                      When{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>{' '}
                      collects information about you from a third party,{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>{' '}
                      relies upon the third party to provide you with clear
                      notice of how your data will be used by
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      , and collect all relevant consent and permission prior to
                      collecting your information and sharing it with
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      .
                    </li>
                  </ul>
                  <ul>
                    <li aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        When{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>{' '}
                        collects information about you from a third party,{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>{' '}
                        relies upon the third party to provide you with clear
                        notice of how your data will be used by
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        , and collect all relevant consent and permission prior
                        to collecting your information and sharing it with
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        .
                      </span>
                    </li>
                  </ul>
                  <p />
                  <p>
                    <br />
                    <br />
                  </p>
                  <ol start={7}>
                    <li>
                      <b>
                        {' '}
                        Restrictions and Limitations on Your Use of the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform
                      </b>
                    </li>
                  </ol>
                  <p />
                  <p>
                    <b>Usage Policies</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform will be subject to the usage policies as
                        provided to you by us from time to time, including in
                        electronic form by posting on the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform. Such policies may include limitations on data
                        storage space, and equipment and/or software
                        requirements. You are solely responsible for compliance
                        with such policies.
                      </span>
                    </li>
                  </ul>
                  <p />
                  <p>
                    <b>Equipment</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        You are solely responsible for the selection,
                        implementation, installation, maintenance and
                        performance of any and all equipment, software and
                        services used in conjunction with using the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform (except for our computer systems and networks),
                        including your choice and use of your Internet Service
                        Provider.
                      </span>
                    </li>
                  </ul>
                  <p />
                  <p>
                    <b>Data Integrity and Back-up</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        You acknowledge and agree that we cannot guarantee data
                        integrity, and that it is solely your responsibility to
                        back-up any of your data that you use in conjunction
                        with the{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform.
                      </span>
                    </li>
                  </ul>
                  <p />
                  <p>
                    <b>Not for Time Sensitive Applications</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform is not developed or licensed for use in any
                        inherently dangerous, time-sensitive, or mission
                        critical manner.
                      </span>
                    </li>
                  </ul>
                  <p />
                  <ol start={8}>
                    <li>
                      <b>
                        {' '}
                        Right to Edit and Remove Your Data; Suspension or
                        Termination; Investigations
                      </b>
                    </li>
                  </ol>
                  <p />
                  <p>
                    <b>Right to Edit and Remove Your Data</b>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Notwithstanding anything to the contrary herein, we
                      reserve the right to review your data stored in files or
                      programs on our servers to verify your compliance with
                      this Agreement. We have the right to edit or remove any of
                      your data that, in our sole discretion, we believe may be
                      unlawful, obscene, abusive, or otherwise objectionable.
                    </span>
                  </p>
                  <p />
                  <p>
                    <b>Suspension or Termination</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        We may impose limits on the{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform or terminate or restrict your access to parts
                        or all of the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform without liability. Where feasible, we will
                        provide you with advance notice of such changes,
                        limitations or discontinuance; however, you acknowledge
                        that such notice may not be feasible in all
                        circumstances, and that we will have no liability
                        whatsoever for its failure to provide such notice to
                        you.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        We reserve the right to revoke your access to the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform for any abusive conduct or fraudulent use of
                        the{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform and to cease the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform temporarily or permanently, if your use of the{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform constitutes, in our sole discretion, a threat
                        to us or any third party’s computer systems, networks,
                        files, materials or other data, or a breach of this
                        Agreement.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Without limiting other remedies, we may limit your
                        activity, issue a warning, temporarily suspend,
                        indefinitely suspend or terminate your account and
                        refuse to provide{' '}
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform to you if: (a) you breach this Agreement or the
                        documents it incorporates by reference; (b) we are
                        unable to verify or authenticate any information you
                        provide; or (c) we believe that your actions may cause
                        financial loss or legal liability for you, other users
                        or us. The above-described actions are not our exclusive
                        remedies and we may take any other legal, equitable or
                        technical action it deems appropriate in the
                        circumstances. We will not have any liability to you or
                        any third party in relation to the termination of this
                        Agreement for any reason whatsoever.
                      </span>
                    </li>
                  </ul>
                  <p />
                  <p>
                    <b>Investigations</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        We reserve the right to investigate suspected violations
                        of this Agreement or review Your Data in order to verify
                        your compliance with this Agreement.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        You agree to cooperate with us and provide information
                        that we request in order to assist us and/or any
                        Governmental or Regulatory Authority in any
                        investigation or determination of whether there has been
                        a breach of this Agreement or Applicable Law.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        You will cooperate with: (A) any Governmental or
                        Regulatory Authorities in the investigation of suspected
                        criminal violations; (B) as a Student user,
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Partners investigating academic fraud or other
                        misdemeanours, relating to your Application or
                        otherwise; and (C) system administrators at Internet
                        service providers, networks or computing facilities, and
                        other content providers, in order to enable us to
                        enforce these Terms and Conditions.
                      </span>
                    </li>
                  </ul>
                  <p />
                  <ol start={9}>
                    <li>
                      <b> Your Responsibilities</b>
                    </li>
                  </ol>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      When using the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform, you agree that you will: (i) promptly notify us
                      if you become aware of or reasonably suspect any illegal
                      or unauthorized activity or a security breach involving
                      your account; (ii) use reasonable efforts to prevent
                      unauthorized access to or use of the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform; and (iii) comply with all Applicable Laws and
                      regulations, including all intellectual property, data,
                      privacy and export control laws; and (iv) promptly notify
                      us of any security breaches/incidents in institutional
                      systems related directly or indirectly to the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform.
                    </span>
                  </p>
                  <p />
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      In addition, you agree to provide us with feedback related
                      to your use of the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform, including promptly and accurately reporting to
                      us any actual or suspected errors, problems, bugs or
                      difficulties with the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform, along with any other information reasonably
                      requested by us to aid in resolving such errors, problems,
                      bugs or difficulties with the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform (such information, “Feedback”). You agree that we
                      may use such Feedback, at our discretion, for purposes
                      related to improving the
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform or our other products and Services.&nbsp;
                    </span>
                  </p>
                  <p />
                  <ol start={10}>
                    <li>
                      <b>
                        {' '}
                        Submissions regarding the Functionality of the
                        <strong>
                          <strong>EdChimp </strong>
                        </strong>
                        Platform
                      </b>
                    </li>
                  </ol>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Any suggestions, bug reports or other communications
                      respecting the functionality of the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform that you transmit to us by any means (each, a
                      “Submission”), are considered non-confidential and may be
                      disseminated or used by us or any third party without
                      compensation or liability to you for any purpose
                      whatsoever, whether for inclusion as part of the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform or otherwise.
                    </span>
                  </p>
                  <p />
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      You hereby grant us, our affiliates and successors a
                      perpetual, worldwide, non-exclusive, royalty-free,
                      sublicensable and transferable license to use, copy,
                      distribute, transmit, modify, develop, prepare derivative
                      works of any Submission on, through or in connection with
                      the Service, including for promoting, improving and
                      developing the{' '}
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform. This provision does not apply to Personal
                      Information that is subject to our Privacy Policy.
                    </span>
                  </p>
                  <p>
                    <br />
                    <br />
                  </p>
                  <ol start={11}>
                    <li>
                      <b> Term and Termination</b>
                    </li>
                  </ol>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The term of these Terms and Conditions will commence on
                      the earlier of the date you: (1) accepted these Terms and
                      Conditions; or (2) the date you first access or use the
                      <strong>
                        <strong>EdChimp </strong>
                      </strong>
                      Platform and will continue until terminated by either
                      Party in accordance with these Terms and Conditions.&nbsp;
                      We may terminate these Terms and Conditions for
                      convenience, at any time, by providing written notice to
                      you.{' '}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
