import React from 'react';

function SingleRolesAndPermissionsComponent({ permission }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="heading-single-education">
          <h2> Name</h2>
          <span>{permission.name}</span>
        </div>
      </div>
    </div>
  );
}

export default SingleRolesAndPermissionsComponent;
