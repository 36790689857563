import React from 'react';
import {
  Calandar,
  CostLiving,
  Fees,
  TutionFees,
} from '../../components/images';
import PermissionComponent from '../auth/PermissionComponent';
function CostAndDuration({ school }) {
  return (
    <div className='sidebar-card'>
      <h5>Cost and Duration</h5>
      <div className='card-flex'>
        {/* <div className='cost-flex'>
          <div className='icon-cost'>
            <img src={Fees} />
          </div>
          <div className='fees-content'>
            <h5>$200.00 CAD</h5>
            <h6>Application fee</h6>
          </div>
        </div> */}
        {school.average_graduate && (
          <div className='cost-flex'>
            <div className='icon-cost'>
              <img src={Calandar} />
            </div>
            <div className='fees-content'>
              <h5>
                {school.average_graduate
                  ? school.average_graduate
                  : 'No Data Available'}
              </h5>
              <h6>Average graduate program</h6>
            </div>
          </div>
        )}
        {school.average_undergraduate && (
          <div className='cost-flex'>
            <div className='icon-cost'>
              <img src={Calandar} />
            </div>
            <div className='fees-content'>
              <h5>
                {' '}
                {school.average_undergraduate
                  ? school.average_undergraduate
                  : 'No Data Available'}
              </h5>
              <h6>Average undergraduate program</h6>
            </div>
          </div>
        )}
        {school.market && (
          <div className='cost-flex'>
            <div className='icon-cost'>
              <img src={CostLiving} />
            </div>
            <div className='fees-content'>
              <h5>
                {/* {school.market && school.market.currency}{' '}
                {school.market && school.market.cost_of_living
                  ? school.market.cost_of_living
                  : 'Not Available'}{' '}
                / Year */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      school.market && school.market.cost_of_living
                        ? school.market.cost_of_living
                        : 'Not Available',
                  }}
                ></div>
              </h5>
              <h6>Cost of living</h6>
            </div>
          </div>
        )}
        <PermissionComponent
          name={'school'}
          permission={'view'}
          hideOnAcademicPartner={true}
          hideOnAgent={true}
          hideOnCounsellor={true}
          hideOnStudent={true}
        >
          {school.commission_percentage && (
            <div className='cost-flex'>
              <div className='icon-cost'>
                <img src={TutionFees} />
              </div>
              <div className='fees-content'>
                <h5>
                  {school.commission_percentage}
                  {school.commission_type === 'percentage' ? '%' : ''} * (
                  {school.commission_year}){' '}
                </h5>
                <h6>Commission</h6>
              </div>
            </div>
          )}
        </PermissionComponent>

        {school.tuition_deposit && (
          <div className='cost-flex'>
            <div className='icon-cost'>
              <img src={TutionFees} />
            </div>
            <div className='fees-content'>
              <h5>
                {' '}
                {school.market && school.market.currency}{' '}
                {school.tuition_deposit} / Year
              </h5>
              <h6>Tuition</h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CostAndDuration;
