import React from 'react';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import EditSlabComponent from '../../components/slabs/EditSlabComponent';
function EditSlab() {
  return (
    <div className='wrapper'>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Edit Commission Tier'} />

      {/* page-section */}

      <section className='personal-information ptb-20 '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <EditSlabComponent />
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default EditSlab;
