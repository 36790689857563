import React from 'react';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';

function SettingNavigationComponent({ active_link }) {
  return (
    <div>
      <section className='ptb-20'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='school-and-programs '>
                <ul>
                  <li>
                    <Link
                      to={links.educationLevel}
                      className={
                        active_link === SETTING_MENU_ENUM.EDUCATION_LEVEL &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-university'></i>
                      Education Levels
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.agents}
                      className={
                        active_link === SETTING_MENU_ENUM.AGENTS && 'active-tab'
                      }
                    >
                      <i className='fa fa-user'></i> Agents
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.crmUsers}
                      className={
                        active_link === SETTING_MENU_ENUM.CRM_USERS &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-users'></i> CRM Users
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.academicPartners}
                      className={
                        active_link === SETTING_MENU_ENUM.ACADEMIC_PARTNERS &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-users'></i> Academic Partners
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.country}
                      className={
                        active_link === SETTING_MENU_ENUM.COUNTRY &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-flag'></i> Country
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.discipline}
                      className={
                        active_link === SETTING_MENU_ENUM.DISCIPLINE &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-flag'></i> Discipline
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.rolesAndPermissions}
                      className={
                        active_link ===
                          SETTING_MENU_ENUM.ROLES_AND_PERMISSIONS &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-lock'></i> Roles & Permissions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.gradingScheme}
                      className={
                        active_link === SETTING_MENU_ENUM.GRADING &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-lock'></i> Grading Schemes
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.Counsellors}
                      className={
                        active_link === SETTING_MENU_ENUM.Counsellors &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-lock'></i> Counsellors
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.templates}
                      className={
                        active_link === SETTING_MENU_ENUM.Templates &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-lock'></i> Templates
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.slabs}
                      className={
                        active_link === SETTING_MENU_ENUM.COMMISSION_TIERS &&
                        'active-tab'
                      }
                    >
                      <i className='fa fa-lock'></i> Commission Tiers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SettingNavigationComponent;
