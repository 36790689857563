import React from 'react';

import { countries } from '../../domain/countries';

import { Footer, Header } from '../../components/common';

import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  PasswordInput,
} from '../../components/Form/Form';
import * as Yup from 'yup';

import { links } from '../../domain/links.enum';
import { useRegisterAsAgent } from '../../shared/hooks/UseAuth';
import { Alerts } from '../../components/common/Alerts';
export const RegisterAsAgent = (props) => {
  const { registerAgent } = useRegisterAsAgent();
  return (
    <div>
      <Header />
      <div className='register-agent ptb-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 mx-auto'>
              <div className='register-agent-form'>
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    password: '',
                    nationality: '',
                    country_code: '',
                    Confirm_password: '',
                  }}
                  validationSchema={Yup.object({
                    first_name: Yup.string().required('Required'),
                    last_name: Yup.string().required('Required'),
                    phone: Yup.number()
                      .typeError('should be a number.')
                      .required('Required'),
                    email: Yup.string().email().required('Required'),
                    password: Yup.string()
                      .required('Required')
                      .min(
                        4,
                        ({ min }) =>
                          `Password must be at least ${min} characters`
                      )
                      .max(
                        20,
                        ({ max }) =>
                          `Password must be at least ${max} characters`
                      ),
                    nationality: Yup.string().required('Required'),
                    country_code: Yup.string().required('Required'),
                    confirm_password: Yup.string()
                      .required('Required')
                      .oneOf(
                        [Yup.ref('password')],
                        'Both password need to be the same'
                      ),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    values.email = values.email.toLowerCase();
                    setSubmitting(true);
                    registerAgent(values);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <h3>Register As a Agent</h3>
                        <p>Please fill the details to register as Agent</p>
                        <TextInput
                          label='First Name'
                          name='first_name'
                          type='text'
                        />
                        <TextInput
                          label='Last Name'
                          name='last_name'
                          type='text'
                        />

                        <TextInput label='Email' name='email' type='email' />
                        <div>
                          <PasswordInput label='Password' name='password' />
                        </div>
                        <div>
                          <PasswordInput
                            label='Confirm Password'
                            name='confirm_password'
                            type='password'
                          />
                        </div>

                        <SelectBox
                          label='Nationality'
                          name='nationality'
                          onChange={(e) => {
                            formik.setFieldValue('nationality', e.target.value);
                            const filteredCountry = countries.filter(
                              (item) => item.name === e.target.value
                            );
                            if (filteredCountry && filteredCountry.length > 0) {
                              formik.setFieldValue(
                                'country_code',
                                filteredCountry[0].dial_code
                              );
                            }
                          }}
                        >
                          <option value=''></option>
                          {countries &&
                            countries.map((e) => {
                              return <option>{e.name}</option>;
                            })}
                        </SelectBox>
                        <div className='row'>
                          <div className='col-md-6'>
                            <SelectBox label='Country Code' name='country_code'>
                              <option value=''></option>
                              {countries &&
                                countries.map((e) => {
                                  return (
                                    <option value={e.dial_code}>
                                      {e.dial_code} {e.name}
                                    </option>
                                  );
                                })}
                            </SelectBox>
                          </div>
                          <div className='col-md-6'>
                            <TextInput
                              label='Phone'
                              name='phone'
                              onChange={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (
                                  e.target.value === '' ||
                                  regex.test(e.target.value)
                                ) {
                                  formik.setFieldValue('phone', e.target.value);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className='create-button'>
                          <button type='submit' className='btn primary-btn'>
                            Register As a Agent
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                {/* <Formik
                  initialValues={{
                    owner_first_name: '',
                    owner_last_name: '',
                    phone: '',
                    email: '',
                    preferred_contact_method: '',
                    how_did_you_find_out_about_edchimp: '',
                    has_anyone_at_edchimp_referred_you: '',
                    in_which_year_did_you_begin_recruiting_students: '',
                    main_source_country_of_your_students: '',
                    what_services_do_you_provide_to_your_clients: '',
                  }}
                  validationSchema={Yup.object({
                    owner_first_name: Yup.string().required('Required'),

                    phone: Yup.number()
                      .typeError('should be a number.')
                      .required('Required'),
                    email: Yup.string().email().required('Required'),
                    password: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    
                    return (
                      <Form>
                        <label>Business Certificate</label>
                        <div className="drop_box">
                          <h4>Drag and drop, or</h4>

                          <input type="file" hidden id="fileID" />
                          <button className=" btn primary-btn ">
                            Choose File
                          </button>
                        </div>
                        <label>Business Logo (optional)</label>
                        <div className="drop_box">
                          <h4>Drag and drop, or</h4>

                          <input type="file" hidden id="fileID" />
                          <button className=" btn primary-btn ">
                            Choose File
                          </button>
                        </div>

                        <TextInput
                          label="Owner's First Name"
                          name="owner_first_name"
                        />
                        <TextInput
                          label="Owner's Last Name"
                          name="owner_last_name"
                        />
                        <TextInput
                          label="Phone"
                          name="phone"
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (
                              e.target.value === '' ||
                              regex.test(e.target.value)
                            ) {
                              formik.setFieldValue('phone', e.target.value);
                            }
                          }}
                        />
                        <TextInput label="Email" name="email" />
                        <SelectBox
                          label="Preferred Contact Method"
                          name="preferred_contact_method"
                        >
                          <option value=""> Select</option>
                          <option value="call_sms_email">
                            Call/ SMS/ Email{' '}
                          </option>
                          <option value="skype">Skype</option>
                          <option value="whatsapp">Whatsapp</option>
                          <option value="weChat">WeChat</option>
                          <option value="telegram">Telegram</option>
                        </SelectBox>
                        <SelectBox
                          label="How did you find out about EdChimp?"
                          name="how_did_you_find_out_about_edchimp"
                        >
                          <option value=""> Select</option>
                          <option value="social_media_platform">
                            Social Media Platform
                          </option>
                          <option value="linkedIn">LinkedIn</option>
                          <option value="word_of_mouth">Word of Mouth</option>
                          <option value="through_a_student">
                            Through a Student
                          </option>
                          <option value="edChimp_approached_me">
                            EdChimp Approached Me
                          </option>
                        </SelectBox>
                        <TextInput
                          label="  Has anyone at EdChimp referred you ? If yes, who?
                      (optional)"
                          name="has_anyone_at_edchimp_referred_you"
                        />

                        <TextInput
                          label=" In which year did you begin recruiting students?"
                          name="in_which_year_did_you_begin_recruiting_students"
                        />

                        <SelectBox
                          label="Please specify the main source country of your students"
                          name="main_source_country_of_your_students"
                        >
                          <option value=""> Select Country </option>
                          {countries &&
                            countries.map((e) => {
                              return (
                                <option>
                                  {e.flag} {e.dial_code} {e.name}
                                </option>
                              );
                            })}
                        </SelectBox>

                        <SelectBox
                          label="What services do you provide to your clients?"
                          name="what_services_do_you_provide_to_your_clients"
                        >
                          <option value="">
                            {' '}
                            What services do you provide to your clients?{' '}
                          </option>
                          <option value="ielts_toefl_coaching">
                            IELTS/ TOEFL Coaching
                          </option>
                          <option value="visa_application">
                            Visa Application
                          </option>
                          <option value="sop_and_interview_preparation">
                            SoP and Interview Preparation
                          </option>
                          <option value="pre_and_post_landing_services">
                            Pre and Post Landing Services
                          </option>
                        </SelectBox>
                        <div className="agent-register-main-button">
                          <button
                            type="submit"
                            className="btn primary-btn w-100"
                          >
                            Next
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alerts />
      <Footer />
    </div>
  );
};
