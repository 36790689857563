import React from 'react';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../domain/UserRole';
function PermissionComponent({
  children,
  name,
  permission,
  hideOnStudent,
  hideOnAgent,
  hideOnAcademicPartner,
  hideOnCounsellor,
}) {
  const data = useSelector((state) => state.auth_reducer);
  const { user } = data;
  if (hideOnStudent && user?.role === UserRoles.STUDENT) {
    return <></>;
  }
  if (hideOnAgent && user?.role === UserRoles.AGENT) {
    return <></>;
  }
  if (hideOnAgent && user?.role === UserRoles.COUNSELLOR) {
    return <></>;
  }
  if (hideOnCounsellor && user?.role === UserRoles.COUNSELLOR) {
    return <></>;
  }
  if (hideOnAcademicPartner && user?.role === UserRoles.ACADEMIC_PARTNER) {
    return <></>;
  }

  if (user?.role === UserRoles?.CRM_USER) {
    if (
      user.permission &&
      user.permission[name] &&
      user.permission[name][permission]
    ) {
      {
        return <>{children}</>;
      }
    } else {
      <></>;
    }
  } else {
    return <>{children}</>;
  }
}

export default PermissionComponent;
