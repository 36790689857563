export const links = {
  home: '/',
  register: '/register',
  registerAsStudent: '/register-as-student',
  registerAsAgent: '/register-as-agent',
  login: '/login',
  forgotPassword: '/forgot-password',
  recoveryEmailed: '/recovery-emailed',
  resetPassword: '/reset-password/:token',
  verifyEmail: '/email-verification',

  // profile
  generalInformation: '/general-information',
  educationHistory: '/education-history',
  testScore: '/test-score',
  visaAndStudyPermit: '/visa-and-study-permit',
  dashBoardHome: '/dashboard',
  notifications: '/notifications',
  students: '/students',
  singleStudent: '/students/:id/general-information',
  singleStudentEducationHistory: '/students/:id/education-history',
  singleStudentTestScore: '/students/:id/test-score',
  singleStudentVisaInfo: '/students/:id/visa-and-study-permit',
  SingleSchool: '/schools/:slug',
  WebsiteSingleSchool: '/website/schools/:slug',
  SingleProgram: '/schools/:school_id/programs/:slug',
  WebsiteSingleProgram: '/website/schools/:school_id/programs/:slug',
  SingleProgramID: '/programs/:slug',
  BulkUploadPrograms: '/programs/bulk-upload',
  addStudent: '/add-student',
  addSchool: '/add-school',
  schools: '/schools',
  addProgramFromSchool: '/schools/:id/add-program',
  addProgram: '/add-program',
  editSchool: '/schools/:slug/edit',
  duplicateSchool: '/schools/:slug/duplicate',
  editProgram: '/programs/:slug/edit',
  duplicateProgram: '/programs/:slug/duplicate',
  programs: '/programs',
  settings: '/settings',
  // settings
  educationLevel: '/settings/education-levels',
  addEducationLevel: '/settings/education-levels/add',
  editEducationLevel: '/settings/education-levels/:id/edit',
  ViewEducationLevel: '/settings/education-levels/:id/view',
  // settings
  slabs: '/settings/slabs',
  addSlab: '/settings/slabs/add',
  editSlab: '/settings/slabs/:id/edit',
  ViewSlab: '/settings/slabs/:id/view',
  // settings
  gradingScheme: '/settings/grading-schemes',
  addGradingScheme: '/settings/grading-schemes/add',
  editGradingScheme: '/settings/grading-schemes/:id/edit',
  ViewGradingScheme: '/settings/grading-schemes/:id/view',
  // agents
  agents: '/settings/agents',
  addAgent: '/settings/agents/add',
  editAgent: '/settings/agents/:id/edit',
  viewAgent: '/settings/agents/:id/view',
  agentProfile: '/agent-profile',
  viewAgentProfile: '/agent-profile/view',
  agentPayout: '/agent/payout',
  // roles and Permissions
  rolesAndPermissions: '/settings/permissions',
  addRolesAndPermissions: '/settings/permissions/add',
  editRolesAndPermissions: '/settings/permissions/:id/edit',
  viewRolesAndPermissions: '/settings/permissions/:id/view',
  // crm Users
  crmUsers: '/settings/crm-users',
  addCrmUser: '/settings/crm-users/add',
  editCrmUser: '/settings/crm-users/:id/edit',
  viewCrmUser: '/settings/crm-users/:id/view',
  // Academic Partners
  academicPartners: '/settings/academic-partners',
  addAcademicPartner: '/settings/academic-partners/add',
  editAcademicPartner: '/settings/academic-partners/:id/edit',
  viewAcademicPartner: '/settings/academic-partners/:id/view',
  // Counsellor
  Counsellors: '/settings/counsellors',
  addCounsellor: '/settings/counsellors/add',
  editCounsellor: '/settings/counsellors/:id/edit',
  viewCounsellor: '/settings/counsellors/:id/view',
  // requirements
  addRequirement: '/schools/:school_id/programs/:program_id/add-requirements',
  editRequirement:
    '/schools/:school_id/programs/:program_id/:requirement_id/edit-requirements',
  // countries
  country: '/settings/countries',
  addCountry: '/settings/country/add',
  editCountry: '/settings/countries/:id/edit',
  viewCountry: '/settings/countries/:id/view',
  // countries
  discipline: '/settings/disciplines',
  addDiscipline: '/settings/discipline/add',
  editDiscipline: '/settings/disciplines/:id/edit',
  viewDiscipline: '/settings/disciplines/:id/view',
  // templates
  templates: '/settings/templates',
  addTemplate: '/settings/templates/add',
  editTemplate: '/settings/templates/:id/edit',
  viewTemplate: '/settings/templates/:id/view',
  // search program and schools
  searchProgramAndSchools: '/search-programs-and-schools',
  searchProgramAndSchoolsByStudent: '/search-programs-and-schools/:student',
  // my applications
  myApplications: '/my-applications',
  viewApplication: '/my-applications/:id',
  paymentSuccessful: '/my-applications/payment/:id',
  addApplicationRequirements: '/my-applications/:id/add-requirement',
  // add application requirements
  addApplicationRequirement:
    '/schools/:school_id/programs/:program_id/add-application-requirements',
  editApplicationRequirement:
    '/schools/:school_id/programs/:program_id/:requirement_id/edit-application-requirements',
  // payments
  payments: 'payments',
  receipt: '/orders/:id/receipt',
  // website Pages
  recruiters: '/services/recruiters',
  websiteStudent: '/services/students',
  schoolWebsite: '/services/schools',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  about: '/about',
  contactUs: '/contact-us',
  ourSolutions: '/our-solutions',
  singleBlog:
    '/blog/Why-should-you-seek-the-help-of-an-immigration-consultant?',
  australianSpouse:
    '/blog/What-documents-do-I-need-for-my-Australian-spouse-visa?',
  reason: '/blog/8-reasons-to-choose-Australia-for-higher-studies',
  careers: '/edchimp/careers',
  blogs: '/our-blogs',
};
