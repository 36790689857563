import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
function Tooltip({ field }) {
  return (
    <>
      <ReactTooltip
        anchorId={`${field}-missing`}
        place="bottom"
        variant="dark"
        content="Missing"
      />
      <ReactTooltip
        anchorId={`${field}-reviewing`}
        place="bottom"
        variant="dark"
        content="Reviewing"
      />
      <ReactTooltip
        anchorId={`${field}-notapproved`}
        place="bottom"
        variant="dark"
        content="Not Approved"
      />
      <ReactTooltip
        anchorId={`${field}-completed`}
        place="bottom"
        variant="dark"
        content="Completed"
      />
    </>
  );
}

export default Tooltip;
