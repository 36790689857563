import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AgentBankInfo from '../../components/agents/AgentBankInfo';
import AgentProfileComponent from '../../components/agents/AgentProfileComponent';
import ViewAgentComponent from '../../components/agents/ViewAgentComponent';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import UpdatePasswordComponent from '../../components/common/UpdatePasswordComponent';
import {
  useSingleAgent,
  useChangePasswordAgent,
  useUpdateAgent,
  useUpdateAgentStatus,
  useCurrentAgent,
} from '../../shared/hooks/UseAgent';

function ViewAgentProfile({ match }) {
  const { agent_data } = useCurrentAgent();

  const { current_agent_loading, current_agent } = agent_data;

  const { updateAgentPasswordData } = useChangePasswordAgent();

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb
        title={
          current_agent && current_agent.agent && current_agent.agent.first_name
        }
      />
      {current_agent && (
        <section className='profile-main ptb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-6'>
                <div className='heading-with-flex'>
                  <div className='main-sec-heading'>
                    <h1>
                      {current_agent &&
                        current_agent.agent &&
                        current_agent.agent.first_name}{' '}
                    </h1>
                    <span
                      className={
                        current_agent.agent &&
                        current_agent.agent.profile_status &&
                        current_agent.agent.profile_status === 'PENDING'
                          ? 'badge warning'
                          : current_agent.agent.profile_status === 'ACTIVE'
                          ? 'badge success'
                          : 'badge danger'
                      }
                    >
                      {current_agent.agent.profile_status}
                    </span>{' '}
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-6'>
                <div className='add-student-btn'>
                  {current_agent.agent && (
                    <Link to={`/agent-profile`} className='btn primary-btn-h '>
                      Edit Profile
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {current_agent && (
        <section className='main-school-single-view ptb-20 '>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8'>
                <ViewAgentComponent agent={current_agent.agent} />
                <h3> Other Information </h3>
                <br />
                <AgentProfileComponent agent={current_agent.agentInfo} />
                <h3> Bank Account Information </h3>
                <br />
                {}
                <AgentBankInfo
                  agent={
                    current_agent.agentInfo &&
                    current_agent.agentInfo.bank_account_details
                  }
                />
                {/* <ViewEducationLevelsComponent education_level={education_level} /> */}
              </div>
              <div className='col-md-4'>
                {current_agent.agent && (
                  <UpdatePasswordComponent
                    navigationLink={'/agent-profile/view'}
                    onSubmitHandler={(values) =>
                      updateAgentPasswordData(current_agent.agent._id, values)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <AuthFooter />
    </div>
  );
}

export default ViewAgentProfile;
