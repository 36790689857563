import React from 'react';
// import renderHTML from 'react-render-html';

function SchoolOverview({ school }) {
  return (
    <div id='overview' className='overview-content'>
      <h3>About Us</h3>
      <div>
        {school.about && (
          <div dangerouslySetInnerHTML={{ __html: school.about }}></div>
        )}
      </div>
      {school?.scholarship && (
        <div className='mt-3'>
          <h3>Scholarship</h3>
          <p> {school.scholarship} </p>
        </div>
      )}
      <div></div>
    </div>
  );
}

export default SchoolOverview;
