import React from 'react';

function InstitutionDetails({ school }) {
  return (
    <div className='sidebar-card'>
      <h5>Institution Details</h5>
      <div className='card-flex'>
        <div className='main-flex-card'>
          <div className='founded'>
            <h5>Founded</h5>
          </div>
          <div className='year-b'>
            <h5>{school.founded_in}</h5>
          </div>
        </div>
        <div className='main-flex-card'>
          <div className='founded'>
            <h5>School ID</h5>
          </div>
          <div className='year-b'>
            <h5>{school.school_id}</h5>
          </div>
        </div>

        <div className='main-flex-card'>
          <div className='founded'>
            <h5>Institution type</h5>
          </div>
          <div className='year-b'>
            <h5>{school.institution_type ? school.institution_type : 'NA'}</h5>
          </div>
        </div>
        <div className='main-flex-card'>
          <div className='founded'>
            <h5>Status</h5>
          </div>
          <div className='year-b'>
            <h5>{school.status}</h5>
          </div>
        </div>
        {school?.gap && (
          <div className='main-flex-card'>
            <div className='founded'>
              <h5>Gap Year</h5>
            </div>
            <div className='year-b'>
              <h5>{school.gap}</h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InstitutionDetails;
