import React from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import PermissionComponent from '../auth/PermissionComponent';

function AdmissionRequiremntsComponent({
  requirements,
  program,
  hideBreadCrumb,
}) {
  return (
    <div>
      <div id='admission_requirements' className='overview-content'>
        <div className='admission-requirement-flex-b'>
          <div>
            <h3>Admission Requirements</h3>
          </div>
          {!hideBreadCrumb && (
            <PermissionComponent
              name={'program'}
              permission={'update'}
              hideOnStudent={true}
              hideOnAgent={true}
            >
              <div>
                <div className='apply-button'>
                  <Link
                    to={`/schools/${program.school._id}/programs/${program._id}/${requirements._id}/edit-requirements`}
                    className='btn btn primary-btn-h w-100 '
                  >
                    Edit Requirement
                  </Link>
                </div>
              </div>
            </PermissionComponent>
          )}
        </div>
        <div className='row mt-3'>
          {requirements.level_text && requirements.level_text.length > 0 && (
            <div className='col-md-6'>
              <div className='minimum-education-card'>
                <h5>Minimum Level of Education Completed</h5>
                <h6>{requirements.level_text}</h6>
              </div>
            </div>
          )}
          {requirements.min_gpa && requirements.min_gpa.length > 0 && (
            <div className='col-md-6'>
              <div className='minimum-education-card'>
                <h5>
                  Minimum {requirements.gpa_type === 'Percentage' ? '%' : 'GPA'}
                </h5>
                <h6>
                  {requirements.min_gpa}{' '}
                  {requirements.gpa_type &&
                  requirements.gpa_type === 'Percentage'
                    ? '%'
                    : 'GPA'}{' '}
                </h6>
              </div>
            </div>
          )}
          {requirements.min_toefl_reading &&
            requirements.min_toefl_reading.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min TOEFL Reading</h5>
                  <h6>{requirements.min_toefl_reading}</h6>
                </div>
              </div>
            )}
          {requirements.min_toefl_writing &&
            requirements.min_toefl_writing.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min TOEFL Writing</h5>
                  <h6>{requirements.min_toefl_writing}</h6>
                </div>
              </div>
            )}
          {requirements.min_toefl_listening &&
            requirements.min_toefl_listening.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min TOEFL Listening</h5>
                  <h6>{requirements.min_toefl_listening}</h6>
                </div>
              </div>
            )}
          {requirements.min_toefl_speaking &&
            requirements.min_toefl_speaking.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min TOEFL Speaking</h5>
                  <h6>{requirements.min_toefl_speaking}</h6>
                </div>
              </div>
            )}
          {requirements.min_toefl_total &&
            requirements.min_toefl_total.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min TOEFL Total</h5>
                  <h6>{requirements.min_toefl_total}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_reading &&
            requirements.min_ielts_reading.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Reading</h5>
                  <h6>{requirements.min_ielts_reading}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_writing &&
            requirements.min_ielts_writing.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Writing</h5>
                  <h6>{requirements.min_ielts_writing}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_listening &&
            requirements.min_ielts_listening.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Listening</h5>
                  <h6>{requirements.min_ielts_listening}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_speaking &&
            requirements.min_ielts_speaking.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Speaking</h5>
                  <h6>{requirements.min_ielts_speaking}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_average &&
            requirements.min_ielts_average.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Average</h5>
                  <h6>{requirements.min_ielts_average}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_any_band &&
            requirements.min_ielts_any_band.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Any Band</h5>
                  <h6>{requirements.min_ielts_any_band}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_any_band_count &&
            requirements.min_ielts_any_band_count.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min IELTS Any Band</h5>
                  <h6>{requirements.min_ielts_any_band_count}</h6>
                </div>
              </div>
            )}
          {/* uk */}
          {requirements.min_ielts_ukvi_reading &&
            requirements.min_ielts_ukvi_reading.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Reading</h5>
                  <h6>{requirements.min_ielts_ukvi_reading}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_ukvi_writing &&
            requirements.min_ielts_ukvi_writing.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Writing</h5>
                  <h6>{requirements.min_ielts_ukvi_writing}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_ukvi_listening &&
            requirements.min_ielts_ukvi_listening.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Listening</h5>
                  <h6>{requirements.min_ielts_ukvi_listening}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_ukvi_speaking &&
            requirements.min_ielts_ukvi_speaking.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Speaking</h5>
                  <h6>{requirements.min_ielts_ukvi_speaking}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_ukvi_average &&
            requirements.min_ielts_ukvi_average.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Average</h5>
                  <h6>{requirements.min_ielts_ukvi_average}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_ukvi_any_band &&
            requirements.min_ielts_ukvi_any_band.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Any Band</h5>
                  <h6>{requirements.min_ielts_ukvi_any_band}</h6>
                </div>
              </div>
            )}
          {requirements.min_ielts_ukvi_any_band_count &&
            requirements.min_ielts_ukvi_any_band_count.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min UKVI IELTS Any Band Count</h5>
                  <h6>{requirements.min_ielts_ukvi_any_band_count}</h6>
                </div>
              </div>
            )}
          {/* enduk */}
          {requirements.min_duolingo_score &&
            requirements.min_duolingo_score.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min Duolingo Score</h5>
                  <h6>{requirements.min_duolingo_score}</h6>
                </div>
              </div>
            )}
          {requirements.min_pte_listening &&
            requirements.min_pte_listening.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min PTE Listening</h5>
                  <h6>{requirements.min_pte_listening}</h6>
                </div>
              </div>
            )}
          {requirements.min_pte_reading &&
            requirements.min_pte_reading.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min PTE Reading</h5>
                  <h6>{requirements.min_pte_reading}</h6>
                </div>
              </div>
            )}
          {requirements.min_pte_speaking &&
            requirements.min_pte_speaking.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min PTE Speaking</h5>
                  <h6>{requirements.min_pte_speaking}</h6>
                </div>
              </div>
            )}
          {requirements.min_pte_writing &&
            requirements.min_pte_writing.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min PTE Writing</h5>
                  <h6>{requirements.min_pte_writing}</h6>
                </div>
              </div>
            )}
          {requirements.min_pte_overall &&
            requirements.min_pte_overall.length > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Min PTE Overall</h5>
                  <h6>{requirements.min_pte_overall}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.required && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Requirement</h5>
                  <h6>{requirements.gre_requirements.required}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.max_gpa_to_be_required &&
            requirements.gre_requirements.max_gpa_to_be_required > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Max GPA</h5>
                  <h6>
                    {requirements.gre_requirements.max_gpa_to_be_required}
                  </h6>
                </div>
              </div>
            )}

          {requirements.gre_requirements &&
            requirements.gre_requirements.min_verbal &&
            requirements.gre_requirements.min_verbal > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Min Verbal</h5>
                  <h6>{requirements.gre_requirements.min_verbal}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.min_quantitative &&
            requirements.gre_requirements.min_quantitative > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Min Quantitative</h5>
                  <h6>{requirements.gre_requirements.min_quantitative}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.min_writing &&
            requirements.gre_requirements.min_writing > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Min Writing</h5>
                  <h6>{requirements.gre_requirements.min_writing}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.min_verbal_percentile &&
            requirements.gre_requirements.min_verbal_percentile > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Min Verbal Percentile</h5>
                  <h6>{requirements.gre_requirements.min_verbal_percentile}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.min_quantitative_percentile &&
            requirements.gre_requirements.min_quantitative_percentile > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>Gre min quantitative percentile</h5>
                  <h6>
                    {requirements.gre_requirements.min_quantitative_percentile}
                  </h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.min_writing_percentile &&
            requirements.gre_requirements.min_writing_percentile > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Min Writing Percentile</h5>
                  <h6>
                    {requirements.gre_requirements.min_writing_percentile}
                  </h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.min_verbal_quantitative_combined &&
            requirements.gre_requirements.min_verbal_quantitative_combined >
              0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GRE Min Verbal quantitative combined </h5>
                  <h6>
                    {
                      requirements.gre_requirements
                        .min_verbal_quantitative_combined
                    }
                  </h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_verbal &&
            requirements.gre_requirements.gmat_min_verbal > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Verbal</h5>
                  <h6>{requirements.gre_requirements.gmat_min_verbal}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_quantitative &&
            requirements.gre_requirements.gmat_min_quantitative > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Quantitative</h5>
                  <h6>{requirements.gre_requirements.gmat_min_quantitative}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_writing &&
            requirements.gre_requirements.gmat_min_writing > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Writing</h5>
                  <h6>{requirements.gre_requirements.gmat_min_writing}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_verbal_percentile &&
            requirements.gre_requirements.gmat_min_verbal_percentile > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Verbal Percentile</h5>
                  <h6>
                    {requirements.gre_requirements.gmat_min_verbal_percentile}
                  </h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_quantitative_percentile &&
            requirements.gre_requirements.gmat_min_quantitative_percentile >
              0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Quantitative Percentile</h5>
                  <h6>
                    {
                      requirements.gre_requirements
                        .gmat_min_quantitative_percentile
                    }
                  </h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_writing_percentile &&
            requirements.gre_requirements.gmat_min_writing_percentile > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Writing Percentile</h5>
                  <h6>
                    {requirements.gre_requirements.gmat_min_writing_percentile}
                  </h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_total &&
            requirements.gre_requirements.gmat_min_total > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Total</h5>
                  <h6>{requirements.gre_requirements.gmat_min_total}</h6>
                </div>
              </div>
            )}
          {requirements.gre_requirements &&
            requirements.gre_requirements.gmat_min_total_percentile &&
            requirements.gre_requirements.gmat_min_total_percentile > 0 && (
              <div className='col-md-6'>
                <div className='minimum-education-card'>
                  <h5>GMAT Min Total Percentile</h5>
                  <h6>
                    {requirements.gre_requirements.gmat_min_total_percentile}
                  </h6>
                </div>
              </div>
            )}
          {requirements.other_requirements && (
            <div className='col-md-6'>
              <div className='minimum-education-card'>
                <h5>Other Requirements</h5>
                <h6>{requirements.other_requirements}</h6>
              </div>
            </div>
          )}
        </div>
        {/* <div className='row mt-3'>
          <div className='col-md-4'>
            <div className='minimum-education-card'>
              <h5>IELTS</h5>
              <h6>6.0</h6>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='minimum-education-card'>
              <h5>TOEFL</h5>
              <h6>79.0</h6>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='minimum-education-card'>
              <h5>PTE</h5>
              <h6>55.0</h6>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AdmissionRequiremntsComponent;
