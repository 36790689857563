import React from 'react';
import { URI } from '../../domain/constant';
import PermissionComponent from '../auth/PermissionComponent';

function AgentProfileComponent({ agent }) {
  return (
    <>
      {agent && (
        <div className='row'>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Business Certificate</h2>
              <span>
                {' '}
                {agent.business_certificate ? (
                  <a
                    href={`${URI}${agent.business_certificate}`}
                    target={'_blank'}
                  >
                    {' '}
                    View{' '}
                  </a>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Business Logo</h2>
              <span>
                {' '}
                {agent.business_logo ? (
                  <a href={`${URI}${agent.business_logo}`} target={'_blank'}>
                    <img
                      src={`${URI}${agent.business_logo}`}
                      style={{ height: '70px' }}
                    />
                  </a>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
          <PermissionComponent
            name={'agents'}
            permission={'assign_tier'}
            hideOnAgent={true}
            hideOnStudent={true}
          >
            <div className='col-md-6'>
              <div className='heading-single-education'>
                <h2>Commission Tier</h2>
                <span>{agent.slab ? agent.slab.name : '-'}</span>
              </div>
            </div>
          </PermissionComponent>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Agency Name</h2>
              <span>{agent.company_name ? agent.company_name : '-'}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Agency Address</h2>
              <span>{agent.company_address ? agent.company_address : '-'}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Agency City</h2>
              <span>{agent.company_city ? agent.company_city : '-'}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Agency State</h2>
              <span>{agent.company_state ? agent.company_state : '-'}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Agency Zipcode</h2>
              <span>{agent.company_zipcode ? agent.company_zipcode : '-'}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Agency Country</h2>
              <span>{agent.company_country ? agent.company_country : '-'}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Preferred Contact Method</h2>
              <span>
                {agent.preferred_contact_method
                  ? agent.preferred_contact_method
                  : '-'}
              </span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Referred By</h2>
              <span>
                {agent.referred_by_edchimp ? agent.referred_by_edchimp : '-'}
              </span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Year of beginning Recruitment</h2>
              <span>{agent.year_of_recruiting_started}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education badge-section'>
              <h2>Country for Student</h2>
              {agent.main_source_country &&
                agent.main_source_country.map((item) => {
                  return <span className='badge success m-1'>{item}</span>;
                })}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education badge-section'>
              <h2>Services</h2>
              {agent.services &&
                agent.services.map((item) => {
                  return <span className='badge success m-1'>{item}</span>;
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AgentProfileComponent;
