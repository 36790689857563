import React, { useEffect } from 'react';
import ProgramComponent from '../../components/programs/ProgramComponent';

function Programs() {
  return (
    <>
      <ProgramComponent />
    </>
  );
}

export default Programs;
