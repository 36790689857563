import React from 'react';

import AgentStats from '../../components/AgentDashboard/AgentStats';
import RecentalyOpenedProgram from '../../components/AgentDashboard/RecentalyOpenedProgram';
import OutstandingTask from '../../components/AgentDashboard/OutstandingTask';
import { useCurrentAgent } from '../../shared/hooks/UseAgent';
import { Link } from 'react-router-dom';
import DashboardIconBoxComponent from '../../components/dashboard/DashboardIconBoxComponent';
import { useAllDashboard } from '../../shared/hooks/UseDashboard';
import { URI } from '../../domain/constant';
import { getInitialName } from '../../shared/helpers/getIntialValue';

function DashboardAgent({ user, hide }) {
  const { agent_data } = useCurrentAgent();

  const { current_agent_loading, current_agent } = agent_data;
  const { dashboard } = useAllDashboard();
  return (
    <>
      <section className='profile-main ptb-50'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='main-sec-heading'>
                <h1>
                  Welcome{' '}
                  {current_agent &&
                    current_agent.agent &&
                    current_agent.agent.first_name}{' '}
                  {current_agent &&
                    current_agent.agent &&
                    current_agent.agent.last_name}
                </h1>
              </div>
            </div>
            {!hide && (
              <div className='col-md-6'>
                <div class='add-student-btn'>
                  {current_agent && current_agent.agentInfo ? (
                    <a class='btn primary-btn-h ' href='/add-student'>
                      Add Student
                    </a>
                  ) : (
                    <Link class='btn primary-btn-h ' to={'/agent-profile'}>
                      Complete Profile
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='row mt-50'>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-university'}
                title={'Total Schools'}
                value={
                  dashboard && dashboard.totalSchools
                    ? dashboard.totalSchools
                    : '-'
                }
                link={'/schools'}
              />
            </div>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-tasks'}
                title={'Total Programs'}
                value={
                  dashboard && dashboard.totalPrograms
                    ? dashboard.totalPrograms
                    : '-'
                }
                link={'/programs'}
              />
            </div>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-file-text-o'}
                title={'Total Applications'}
                value={
                  dashboard && dashboard.totalApplications
                    ? dashboard.totalApplications
                    : '-'
                }
                link={'/my-applications'}
              />
            </div>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-graduation-cap'}
                title={'Total Students'}
                value={
                  dashboard && dashboard.totalStudents
                    ? dashboard.totalStudents
                    : '-'
                }
                link={'/students'}
              />
            </div>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-thumbs-o-up'}
                title={'Total Visa Issued Applications'}
                value={
                  dashboard && dashboard.totalVisaIssuedApplications
                    ? dashboard.totalVisaIssuedApplications
                    : '-'
                }
                link={'/my-applications?exact[is_application_fee_paid]=false'}
              />
            </div>
            <div className='col-md-3'>
              <DashboardIconBoxComponent
                icon={'fa fa-pencil-square-o'}
                title={'Paid Applications'}
                value={
                  dashboard && dashboard.totalPaidApplications
                    ? dashboard.totalPaidApplications
                    : '-'
                }
                link={'/my-applications?exact[is_application_fee_paid]=true'}
              />
            </div>
            <div className='col-md-6'>
              <div>
                {current_agent && current_agent.crm_user && (
                  <div className='facilitator-name mb-3'>
                    <div className='facilitator-card-header '>
                      Facilitator Details
                    </div>
                    <div className='facilitator-flex'>
                      {current_agent && current_agent.crm_user && (
                        <>
                          <div className='user-circle-1 user-n-fle'>
                            {current_agent.crm_user &&
                            current_agent.crm_user.profile_pic ? (
                              <div className='crm-user-profile-pic'>
                                <img
                                  src={`${URI}${current_agent.crm_user.profile_pic}`}
                                  style={{
                                    width: '70px',
                                    height: '70px',
                                    borderRadius: '50%',
                                  }}
                                />
                              </div>
                            ) : (
                              <>
                                {getInitialName(
                                  `${current_agent.crm_user.first_name} ${current_agent.crm_user.last_name}`
                                )}
                              </>
                            )}
                          </div>
                          <div className='faci-main-flex'>
                            <div className='name-flex'>
                              <div className='valu-f'>Name:</div>
                              <div className='valu-m'>{`${current_agent.crm_user.first_name}  ${current_agent.crm_user.last_name}`}</div>
                            </div>
                            <div className='name-flex'>
                              <div className='valu-f'>Email:</div>
                              <div className='valu-m'>
                                {current_agent.crm_user.email}
                              </div>
                            </div>
                            <div className='name-flex'>
                              <div className='valu-f'>Phone:</div>
                              <div className='valu-m'>
                                {current_agent.crm_user.country_code}{' '}
                                {current_agent.crm_user.phone}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DashboardAgent;
