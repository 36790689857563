import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  FileUpload,
} from '../../components/Form/Form';
import * as Yup from 'yup';

import { UseVisaStudyPermit } from '../../shared/hooks/UseVisaStudyPermit';
import { ValidStudyVisaPermitEnum } from '../../domain/validStudyVisaPermit';
import SingleProfileComponent from '../cards/SingleProfileComponent';
import { URI } from '../../domain/constant';

export const VisaAndStudyPermitP = ({ student_info, hideEdit }) => {
  const { userVisaInfo, student } = student_info;
  const { updateVisaInfo } = UseVisaStudyPermit();
  const [editing, setEditing] = useState(false);
  return (
    <>
      <div className='personal-information-box  clearfix'>
        <div className='d-flex justify-content-between'>
          <h2>Visa & Study Permit</h2>
          {!hideEdit && (
            <div>
              <button
                className='btn btn-assign'
                onClick={() => setEditing(!editing)}
              >
                {' '}
                {editing ? (
                  <i className='fa fa-close'></i>
                ) : (
                  <i className='fa fa-pencil'></i>
                )}{' '}
              </button>
            </div>
          )}
        </div>

        {editing ? (
          <Formik
            initialValues={{
              is_visa_refused: userVisaInfo
                ? userVisaInfo.is_visa_refused
                : false,
              visa_status_note: userVisaInfo
                ? userVisaInfo.visa_status_note
                : '',
              have_study_permit: userVisaInfo
                ? userVisaInfo.have_study_permit
                : false,
              // have_visa_permit: false,
              visa_refusal_attachment: '',
              current_visa: userVisaInfo ? userVisaInfo.current_visa : [],
            }}
            validationSchema={Yup.object().shape({
              is_visa_refused: Yup.boolean().oneOf([true, false]),
              have_study_permit: Yup.boolean().oneOf([true, false]),
              visa_status_note: Yup.string().when('is_visa_refused', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              visa_refusal_attachment: Yup.string().when('is_visa_refused', {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
              current_visa: Yup.array().when('have_study_permit', {
                is: true,
                then: (schema) => schema.min(1, 'Select at least 1'),
              }),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateVisaInfo(student._id, values);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form className='visa'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label>
                        Have you been refused a visa from Canada, the USA, the
                        United Kingdom, New Zealand, Australia or Ireland? *
                      </label>
                      <div className='row'>
                        <div className='col-md-6'>
                          <CheckBox
                            label='Yes'
                            name='visa_refused_affirmative'
                            checked={formik.values.is_visa_refused}
                            onChange={(e) => {
                              formik.setFieldValue('is_visa_refused', true);
                            }}
                          >
                            Yes
                          </CheckBox>
                        </div>
                        <div className='col-md-6'>
                          <CheckBox
                            label='No'
                            name='visa_refused_negative'
                            checked={!formik.values.is_visa_refused}
                            onChange={(e) => {
                              formik.setFieldValue('is_visa_refused', false);
                              formik.setFieldValue(
                                'visa_refusal_attachment',
                                null
                              );
                            }}
                          >
                            No
                          </CheckBox>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <label>
                        Which valid study permits or visas do you have?
                      </label>
                      <div className='row'>
                        {formik.values.have_study_permit && (
                          <>
                            <div className='col-md-12 mb-2'>
                              <CheckBox
                                label='Visa Name'
                                name='current_visa'
                                id='canadian'
                                value={
                                  ValidStudyVisaPermitEnum.CANADIAN_STUDY_PERMIT_VISITOR_VISA
                                }
                              >
                                <label htmlFor='canadian'>
                                  Canadian Study Permit/ Visitor Visa
                                </label>
                              </CheckBox>
                            </div>
                            <div className='col-md-12 mb-2'>
                              <CheckBox
                                label='Visa Name'
                                name='current_visa'
                                id='usa_f1'
                                value={ValidStudyVisaPermitEnum.USA_F1_VISA}
                              >
                                <label htmlFor='usa_f1'>USA F1 Visa</label>
                              </CheckBox>
                            </div>
                            <div className='col-md-12 mb-2'>
                              <CheckBox
                                label='Visa Name'
                                name='current_visa'
                                id='Australian'
                                value={
                                  ValidStudyVisaPermitEnum.AUSTRALIA_STUDY_VISA
                                }
                              >
                                <label htmlFor='Australian'>
                                  Australian Study Visa
                                </label>
                              </CheckBox>
                            </div>
                            <div className='col-md-12 mb-2'>
                              <CheckBox
                                label='Visa Name'
                                name='current_visa'
                                id='UK'
                                value={
                                  ValidStudyVisaPermitEnum.UK_TIER_4STUDENT_SHORT_TERM_STUDY_VISA
                                }
                              >
                                <label htmlFor='UK'>
                                  UK Tier 4 Student/ Short Term Study Visa
                                </label>
                              </CheckBox>
                            </div>
                            <div className='col-md-12 mb-2'>
                              <CheckBox
                                label='Visa Name'
                                name='current_visa'
                                id='Irish'
                                value={ValidStudyVisaPermitEnum.IRISH_STAMP_2}
                              >
                                <label htmlFor='Irish'>Irish Stamp 2</label>
                              </CheckBox>
                            </div>
                          </>
                        )}

                        <div className='col-md-12 mb-2'>
                          <CheckBox
                            label='Yes'
                            name='have_study_visa_permit_check'
                            checked={!formik.values.have_study_permit}
                            onChange={(e) => {
                              if (!formik.values.have_study_permit) {
                                formik.setFieldValue('current_visa', []);
                              }
                              formik.setFieldValue(
                                'have_study_permit',
                                !formik.values.have_study_permit
                              );
                            }}
                          >
                            <label htmlFor='have_study_visa_permit_check'>
                              I don't have this
                            </label>
                          </CheckBox>
                        </div>
                        <div className='col-md-12'>
                          <TextArea
                            label='Please provide more information about your current study permit/visa and any past refusals, if any'
                            name='visa_status_note'
                          />
                        </div>
                        {formik.values.is_visa_refused && (
                          <div className='col-md-12'>
                            <FileUpload
                              title={'Document'}
                              item='visa_refusal_attachment'
                              formik={formik}
                              value={formik.values.visa_refusal_attachment}
                            />
                            {formik.errors &&
                              formik.errors.visa_refusal_attachment && (
                                <p className='text-danger'> Required </p>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='save-button'>
                    <button type='submit' className='btn primary-btn w-100'>
                      Save & Continue
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            <div className='row'>
              <SingleProfileComponent
                col={12}
                label={
                  ' Have you been refused a visa from Canada, the USA, the United Kingdom, New Zealand, Australia or Ireland? *'
                }
                value={
                  userVisaInfo && userVisaInfo.is_visa_refused ? 'YES' : 'NO'
                }
              />
              <SingleProfileComponent
                col={12}
                label={' Which valid study permits or visas do you have?'}
                value={
                  userVisaInfo && userVisaInfo.have_study_permit ? 'YES' : 'NO'
                }
              />
              {userVisaInfo && userVisaInfo.have_study_permit && (
                <SingleProfileComponent
                  col={12}
                  label={'Current Visa'}
                  value={
                    userVisaInfo && userVisaInfo.current_visa
                      ? userVisaInfo.current_visa.map((item) => {
                          return <div> {item} </div>;
                        })
                      : ''
                  }
                />
              )}

              <SingleProfileComponent
                col={12}
                label={'Notes'}
                value={userVisaInfo && userVisaInfo.visa_status_note}
              />
              {userVisaInfo && userVisaInfo.visa_refusal_attachment && (
                <div className={'col-md-12'}>
                  <div className='single-info-card'>
                    <div className='single-info-label'> Attachment </div>
                    <div className='single-info-value'>
                      <a
                        target='_blank'
                        href={`${URI}${userVisaInfo.visa_refusal_attachment}`}
                      >
                        {' '}
                        View{' '}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
