import React from 'react';

function ProgramOverview({ program }) {
  return (
    <div id='overview' className='overview-content'>
      <h3>About Program</h3>
      {program.description && (
        <div dangerouslySetInnerHTML={{ __html: program.description }}></div>
      )}
    </div>
  );
}

export default ProgramOverview;
