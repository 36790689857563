export const GET_PERMISSIONS_STARTED = 'GET_PERMISSIONS_STARTED';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_ENDED = 'GET_PERMISSIONS_ENDED';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';
export const ADD_PERMISSION_STARTED = 'ADD_PERMISSION_STARTED';
export const ADD_PERMISSION = 'ADD_PERMISSION';
export const ADD_PERMISSION_ENDED = 'ADD_PERMISSION_ENDED';
export const ADD_PERMISSION_ERROR = 'ADD_PERMISSION_ERROR';
export const EDIT_PERMISSION_STARTED = 'EDIT_PERMISSION_STARTED';
export const EDIT_PERMISSION = 'EDIT_PERMISSION';
export const EDIT_PERMISSION_ENDED = 'EDIT_PERMISSION_ENDED';
export const EDIT_PERMISSION_ERROR = 'EDIT_PERMISSION_ERROR';
export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_STARTED = 'GET_PERMISSION_STARTED';
export const GET_PERMISSION_ENDED = 'GET_PERMISSION_ENDED';
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR';
