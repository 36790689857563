import {
  ADD_VISA_AND_STUDY_PERMIT_STARTED,
  ADD_VISA_AND_STUDY_PERMIT_SUCCESS,
  ADD_VISA_AND_STUDY_PERMIT_ENDED,
  ADD_VISA_AND_STUDY_PERMIT_ERROR,
} from '../types/Visa_and_study_permit.type';
import { api } from '../../domain/api';
import {
  GET_STUDENT,
  GET_STUDENT_ENDED,
  GET_STUDENT_STARTED,
} from '../types/student_types';
import { setAlert } from './alert_action';

// Register User
export const addVisaAndServicePermitInfo =
  (student_id, data) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_VISA_AND_STUDY_PERMIT_STARTED,
      });
      dispatch({
        type: GET_STUDENT_STARTED,
      });

      const res = await api.post(`/visa-permit/${student_id}`, data);

      dispatch({
        type: ADD_VISA_AND_STUDY_PERMIT_SUCCESS,
        payload: 'Visa and Study Permit Information Updated',
      });
      dispatch({
        type: GET_STUDENT,
        payload: res.data,
      });
      dispatch({
        type: GET_STUDENT_ENDED,
      });
      dispatch(setAlert('Visa Info Updated', 'success'));
      dispatch({
        type: ADD_VISA_AND_STUDY_PERMIT_ENDED,
      });
    } catch (err) {
      dispatch({
        type: ADD_VISA_AND_STUDY_PERMIT_ENDED,
      });
      dispatch({
        type: ADD_VISA_AND_STUDY_PERMIT_ERROR,
        payload: err,
      });
    }
  };
