import {
  GET_APPLICATIONS_STARTED,
  GET_APPLICATIONS,
  GET_APPLICATIONS_ENDED,
  ADD_APPLICATION_STARTED,
  ADD_APPLICATION,
  ADD_APPLICATION_ENDED,
  EDIT_APPLICATION_STARTED,
  EDIT_APPLICATION,
  EDIT_APPLICATION_ENDED,
  GET_APPLICATION,
  GET_APPLICATION_STARTED,
  GET_APPLICATION_ENDED,
  ALL_APPLICATION_PAYMENT_STARTED,
  ALL_APPLICATION_PAYMENT,
  ALL_APPLICATION_PAYMENT_ENDED,
  ALL_APPLICATION_PAYMENT_ERROR,
} from '../types/application_types';

const initialState = {
  applications_loading: true,
  applications: null,
  application_page: null,
  application_pages: null,
  total_applications: 0,

  application: null,
  application_loading: null,

  add_application_loading: true,
  edit_application_loading: true,

  all_application_payments_loading: true,
  all_application_payments: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_APPLICATIONS_STARTED:
      return {
        ...state,
        applications: null,
        application_pages: null,
        application_page: null,
        total_applications: 0,
        applications_loading: true,
      };
    case GET_APPLICATIONS:
      return {
        ...state,
        applications: payload.applications,
        application_pages: payload.pages,
        application_page: payload.page,
        total_applications: payload.total_applications,
      };
    case GET_APPLICATIONS_ENDED:
      return {
        ...state,
        applications_loading: false,
      };

    case ADD_APPLICATION_STARTED:
      return {
        ...state,
        application_message: null,
        add_application_loading: true,
      };
    case ADD_APPLICATION:
      return {
        ...state,
        application_message: payload,
      };
    case ADD_APPLICATION_ENDED:
      return {
        ...state,
        add_application_loading: false,
      };
    case GET_APPLICATION_STARTED:
      return {
        ...state,
        application: null,
        application_loading: true,
      };
    case GET_APPLICATION:
      return {
        ...state,
        application: payload,
      };
    case GET_APPLICATION_ENDED:
      return {
        ...state,
        application_loading: false,
      };
    case EDIT_APPLICATION_STARTED:
      return {
        ...state,
        application_message: null,
        edit_application_loading: true,
      };
    case EDIT_APPLICATION:
      return {
        ...state,
        application_message: payload,
      };
    case EDIT_APPLICATION_ENDED:
      return {
        ...state,
        edit_application_loading: false,
      };
    case ALL_APPLICATION_PAYMENT_STARTED:
      return {
        ...state,
        all_application_payments_loading: true,
      };
    case ALL_APPLICATION_PAYMENT:
      return {
        ...state,
        all_application_payments: payload,
      };
    case ALL_APPLICATION_PAYMENT_ENDED:
      return {
        ...state,
        all_application_payments_loading: false,
      };

    default:
      return state;
  }
}
