import {
  GET_EDUCATION_LEVELS_STARTED,
  GET_EDUCATION_LEVELS,
  GET_EDUCATION_LEVELS_ENDED,
  ADD_EDUCATION_LEVEL_STARTED,
  ADD_EDUCATION_LEVEL,
  ADD_EDUCATION_LEVEL_ENDED,
  EDIT_EDUCATION_LEVEL_STARTED,
  EDIT_EDUCATION_LEVEL,
  EDIT_EDUCATION_LEVEL_ENDED,
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVEL_STARTED,
  GET_EDUCATION_LEVEL_ENDED,
} from '../types/education_level_types';

const initialState = {
  education_levels_loading: true,
  education_levels: null,
  education_level_page: null,
  education_levels_pages: null,
  total_education_levels: 0,

  education_level: null,
  education_level_loading: null,

  add_education_level_loading: true,
  edit_education_level_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EDUCATION_LEVELS_STARTED:
      return {
        ...state,
        education_levels: null,
        education_level_pages: null,
        education_level_page: null,
        total_education_levels: 0,
        education_levels_loading: true,
      };
    case GET_EDUCATION_LEVELS:
      return {
        ...state,
        education_levels: payload.education_levels,
        education_level_pages: payload.pages,
        education_level_page: payload.page,
        total_education_levels: payload.total_education_levels,
      };
    case GET_EDUCATION_LEVELS_ENDED:
      return {
        ...state,
        education_levels_loading: false,
      };

    case ADD_EDUCATION_LEVEL_STARTED:
      return {
        ...state,
        education_level_message: null,
        add_education_level_loading: true,
      };
    case ADD_EDUCATION_LEVEL:
      return {
        ...state,
        education_level_message: payload,
      };
    case ADD_EDUCATION_LEVEL_ENDED:
      return {
        ...state,
        add_education_level_loading: false,
      };
    case GET_EDUCATION_LEVEL_STARTED:
      return {
        ...state,
        education_level: null,
        education_level_loading: true,
      };
    case GET_EDUCATION_LEVEL:
      return {
        ...state,
        education_level: payload,
      };
    case GET_EDUCATION_LEVEL_ENDED:
      return {
        ...state,
        education_level_loading: false,
      };
    case EDIT_EDUCATION_LEVEL_STARTED:
      return {
        ...state,
        education_level_message: null,
        edit_education_level_loading: true,
      };
    case EDIT_EDUCATION_LEVEL:
      return {
        ...state,
        education_level_message: payload,
      };
    case EDIT_EDUCATION_LEVEL_ENDED:
      return {
        ...state,
        edit_education_level_loading: false,
      };

    default:
      return state;
  }
}
