import React from 'react';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllSlabs } from '../../shared/hooks/UseSlab';
import SlabComponent from '../../components/slabs/SlabComponent';

function Slabs() {
  const { data, setPageNumber } = useAllSlabs();
  const { slabs_loading, slabs, slab_page, slabs_pages, total_slabs } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Commission Tiers'} />
      <section className='profile-main ptb-30'>
        <SettingNavigationComponent
          active_link={SETTING_MENU_ENUM.COMMISSION_TIERS}
        />
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1> Commission Tiers </h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent
                name={'education_level'}
                permission={'create'}
              >
                <div className='add-student-btn'>
                  <Link to={links.addSlab} className='btn primary-btn-h '>
                    Add Slab
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <section className='students-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <SlabComponent
                slabs_loading={slabs_loading}
                slabs={slabs}
                slab_page={slab_page}
                slabs_pages={slabs_pages}
                total_slabs={total_slabs}
                setPageNumber={setPageNumber}
              />
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default Slabs;
