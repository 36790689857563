export const GET_REQUIREMENTS_STARTED = 'GET_REQUIREMENTS_STARTED';
export const GET_REQUIREMENTS = 'GET_REQUIREMENTS';
export const GET_REQUIREMENTS_ENDED = 'GET_REQUIREMENTS_ENDED';
export const GET_REQUIREMENTS_ERROR = 'GET_REQUIREMENTS_ERROR';
export const ADD_REQUIREMENT_STARTED = 'ADD_REQUIREMENT_STARTED';
export const ADD_REQUIREMENT = 'ADD_REQUIREMENT';
export const ADD_REQUIREMENT_ENDED = 'ADD_REQUIREMENT_ENDED';
export const ADD_REQUIREMENT_ERROR = 'ADD_REQUIREMENT_ERROR';
export const EDIT_REQUIREMENT_STARTED = 'EDIT_REQUIREMENT_STARTED';
export const EDIT_REQUIREMENT = 'EDIT_REQUIREMENT';
export const EDIT_REQUIREMENT_ENDED = 'EDIT_REQUIREMENT_ENDED';
export const EDIT_REQUIREMENT_ERROR = 'EDIT_REQUIREMENT_ERROR';
export const GET_REQUIREMENT = 'GET_REQUIREMENT';
export const GET_REQUIREMENT_STARTED = 'GET_REQUIREMENT_STARTED';
export const GET_REQUIREMENT_ENDED = 'GET_REQUIREMENT_ENDED';
export const GET_REQUIREMENT_ERROR = 'GET_REQUIREMENT_ERROR';
