import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';

import {
  useSingleCountry,
  useUpdateCountry,
} from '../../shared/hooks/UseCountry';
import CountryFormComponent from './CountryFormComponent';

function EditCountryComponent() {
  const params = useParams();
  const { data } = useSingleCountry(params.id);
  const { country, country_loading } = data;
  const { updateCountryData } = useUpdateCountry();
  const navigate = useNavigate();
  return (
    <div>
      {country && (
        <div className='personal-information-box clearfix'>
          <div className='main-sec-heading'>
            <h1>Edit Country</h1>
          </div>

          <Formik
            initialValues={{
              name: country ? country.name : '',
              currency: country ? country.currency : '',
              cost_of_living: country ? country.cost_of_living : '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              currency: Yup.string().required('Required'),
              cost_of_living: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateCountryData(country._id, values);
              navigate('/settings/countries');
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return <CountryFormComponent formik={formik} />;
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default EditCountryComponent;
