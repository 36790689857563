import React from 'react';
import AddAgentComponent from '../../components/agents/AddAgentComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import AddRequirementsComponent from '../../components/requirements/AddRequirementsComponent';
import { useParams } from 'react-router-dom';

function AddRequirements() {
  const params = useParams();
  // const { school_data } = useSingleSchoolBySlug(params.slug);
  return (
    <>
      <div className='wrapper'>
        <Auth_header_mobile />
        <AuthHeader />
        <AuthBreadCrumb title={'Add Requirements'} />

        {/* page-section */}

        <section className='  personal-information ptb-20 '>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <AddRequirementsComponent
                  program={params.program_id}
                  school={params.school_id}
                />
              </div>
            </div>
          </div>
        </section>
        <AuthFooter />
      </div>
    </>
  );
}

export default AddRequirements;
