export const ApplicationStatusEnum = {
  APPLIED: 'Applied',
  APPLICATION_FEE_PAID: 'Application Fee Paid',
  APPLICATION_PREPARED: 'Application Prepared',
  SUBMISSION: 'Submission',
  OFFER: 'Offer',
  POST_OFFER: 'Post Offer',
  UCOL_LOA: 'UCOL/LOA',
  TUITION_FEE_PAID: 'Tuition Fee Paid',
  IS_VISA_APPLIED: 'Visa Applied',
  VISA_ISSUED: 'Visa Issued',
  VISA_REJECTED: 'Visa Rejected',
  ENROLLED: 'Enrolled',
};

export const ApplicationStatusSequence = [
  ApplicationStatusEnum.APPLIED,
  ApplicationStatusEnum.APPLICATION_FEE_PAID,
  ApplicationStatusEnum.APPLICATION_PREPARED,
  ApplicationStatusEnum.OFFER,
  ApplicationStatusEnum.POST_OFFER,
  ApplicationStatusEnum.UCOL_LOA,
  ApplicationStatusEnum.TUITION_FEE_PAID,
  ApplicationStatusEnum.IS_VISA_APPLIED,
  ApplicationStatusEnum.VISA_ISSUED,
  ApplicationStatusEnum.VISA_REJECTED,
  ApplicationStatusEnum.ENROLLED,
];
