import React from 'react';

import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import AddApplicationRequirementForm from './AddApplicationRequirementForm';
import {
  useCreateApplicationRequirement,
  useSingleApplicationRequirement,
  useUpdateApplicationRequirement,
} from '../../shared/hooks/UseApplicationRequirement';

function EditApplicationRequirementsComponent({ program, school }) {
  const params = useParams();
  const navigate = useNavigate();
  const { updateApplicationRequirementData } =
    useUpdateApplicationRequirement();
  const { application_requirement_data } = useSingleApplicationRequirement(
    params.requirement_id
  );

  const { application_requirement } = application_requirement_data;
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Edit Application Requirements</h1>
        </div>
        {application_requirement && (
          <Formik
            initialValues={{
              requirement_stage: application_requirement.requirement_stage
                ? application_requirement.requirement_stage
                : '',
              title: application_requirement.title
                ? application_requirement.title
                : '',

              is_required: application_requirement.is_required
                ? application_requirement.is_required
                : false,

              content: application_requirement.content
                ? application_requirement.content
                : '',
              requirement_type: application_requirement.requirement_type
                ? application_requirement.requirement_type
                : '',
              document: application_requirement.document
                ? application_requirement.document
                : '',
              questions: application_requirement.questions
                ? application_requirement.questions
                : '',
            }}
            validationSchema={Yup.object({
              requirement_stage: Yup.string().required('Required'),
              title: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              // values.program = program;
              // addAgentData(values);
              values.program = program;
              updateApplicationRequirementData(
                application_requirement._id,
                values
              );
              navigate(`/programs`);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return <AddApplicationRequirementForm formik={formik} />;
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default EditApplicationRequirementsComponent;
