import React from 'react';

import { CheckBox } from '../../components/Form/Form';
function ApplicationsRolesComponents({ formik }) {
  return (
    <div className='permissions-box'>
      <div className='col-md-12'>
        <div className='check-all-flex'>
          <div>
            <label className='big'>Applications</label>
          </div>
          <div>
            <CheckBox
              name='application.check_all'
              onChange={(e) => {
                if (e.target.checked) {
                  formik.setFieldValue('application', {
                    create: true,
                    get_all: true,
                    view: true,
                    delete: true,
                    update: true,
                    check_all: true,
                  });
                } else {
                  formik.setFieldValue('application', {
                    create: false,
                    get_all: false,
                    view: false,
                    delete: false,
                    update: false,
                    check_all: false,
                  });
                }
              }}
            >
              Check All
            </CheckBox>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <CheckBox label='Single' name='application.create'>
              Create
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='application.get_all'>
              Get All
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='application.view'>
              View
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='application.delete'>
              Delete
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='application.update'>
              Update
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox
              label='View Edchimp Prospect Commission'
              name='application.view_edchimp_prospect_commission'
            >
              View Edchimp Prospect Commission
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox
              label='View Edchimp Commission'
              name='application.view_edchimp_commission'
            >
              View Edchimp Commission
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox
              label='Update Commission'
              name='application.update_commission'
            >
              Update Commission
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox
              label='View Agent Commission'
              name='application.view_agent_commission'
            >
              View Agent Commission
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox
              label='View Agent Prospect Commission'
              name='application.view_agent_prospect_commission'
            >
              View Agent Prospect Commission
            </CheckBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationsRolesComponents;
