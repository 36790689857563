import React from 'react';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import EditProgramComponent from '../../components/programs/EditProgramComponent';
import DuplicateProgramComponent from '../../components/programs/DuplicateProgramComponent';

function DuplicateProgram() {
  return (
    <div>
      <div className='wrapper'>
        <Auth_header_mobile />
        <AuthHeader />

        <AuthBreadCrumb title={'Edit Programs'} />
        <PermissionComponent name={'program'} permission={'update'}>
          <section className='  personal-information ptb-20 '>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <DuplicateProgramComponent />
                </div>
              </div>
            </div>
          </section>
        </PermissionComponent>
        <AuthFooter />
      </div>
    </div>
  );
}

export default DuplicateProgram;
