export const inputFields = {
  school: {
    type: 'related',
    field: 'name',
    required: true,
    title: 'School',
  },
  program_id: {
    type: 'string',
    required: true,
    title: 'Program id',
  },
  school: {
    type: 'related',
    field: 'name',
    required: true,
    title: 'School',
  },
};
export const newfieldsToExport = {
  name: {
    type: 'string',
    required: true,
    title: 'Program Name',
  },
  program_id: {
    type: 'string',
    required: true,
    title: 'Program id',
  },
  application_fee: {
    type: 'string',
    required: true,
    title: 'application_fee',
  },
  source: {
    type: 'string',
    required: true,
    title: 'application_fee',
  },
  description: {
    type: 'string',
    required: true,
    title: 'description',
  },
  delivery_method: {
    type: 'string',
    required: true,
    title: 'delivery_method',
  },
  length_breakdown: {
    type: 'string',
    required: true,
    title: 'length_breakdown',
  },
  education_level: {
    type: 'related',
    field: 'name',
    required: true,
    title: 'education_level',
  },
  country: {
    type: 'string',
    required: true,
    title: 'country',
  },
  city: {
    type: 'string',
    required: true,
    title: 'city',
  },
  state: {
    type: 'string',
    required: true,
    title: 'state',
  },
  max_length: {
    type: 'string',
    required: true,
    title: 'max_length',
  },
  min_length: {
    type: 'string',
    required: true,
    title: 'min_length',
  },
  other_fees: {
    type: 'string',
    required: true,
    title: 'other_fees',
  },
  tuition: {
    type: 'string',
    required: true,
    title: 'tuition',
  },
  program_intakes: {
    type: 'string',
    required: true,
    title: 'program_intakes',
  },
  commission_percentage: {
    type: 'string',
    required: true,
    title: 'commission_percentage',
  },
  // education_level: '',
  english_score_required: {
    type: 'string',
    required: true,
    title: 'english_score_required',
  },
  allow_delayed_proof_of_elp: {
    type: 'string',
    required: true,
    title: 'allow_delayed_proof_of_elp',
  },
  min_gpa: {
    type: 'related',
    field: 'requirement.min_gpa',
    required: true,
    title: 'min_gpa',
  },
  min_toefl_reading: {
    type: 'string',
    required: true,
    title: 'min_toefl_reading',
  },
  min_toefl_writing: {
    type: 'string',
    required: true,
    title: 'min_toefl_writing',
  },
  min_toefl_listening: {
    type: 'string',
    required: true,
    title: 'min_toefl_listening',
  },
  min_toefl_speaking: {
    type: 'string',
    required: true,
    title: 'min_toefl_speaking',
  },
  min_toefl_total: {
    type: 'string',
    required: true,
    title: 'min_toefl_total',
  },
  min_ielts_reading: {
    type: 'string',
    required: true,
    title: 'min_ielts_reading',
  },
  min_ielts_writing: {
    type: 'string',
    required: true,
    title: 'min_ielts_writing',
  },
  min_ielts_listening: {
    type: 'string',
    required: true,
    title: 'min_ielts_listening',
  },
  min_ielts_speaking: {
    type: 'string',
    required: true,
    title: 'min_ielts_speaking',
  },
  min_ielts_average: {
    type: 'string',
    required: true,
    title: 'min_ielts_average',
  },
  min_ielts_any_band: {
    type: 'string',
    required: true,
    title: 'min_ielts_any_band',
  },
  min_ielts_any_band_count: {
    type: 'string',
    required: true,
    title: 'min_ielts_any_band_count',
  },
  min_duolingo_score: {
    type: 'string',
    required: true,
    title: 'min_duolingo_score',
  },
  min_pte_listening: {
    type: 'string',
    required: true,
    title: 'min_pte_listening',
  },
  min_pte_reading: {
    type: 'string',
    required: true,
    title: 'min_pte_reading',
  },
  min_pte_speaking: {
    type: 'string',
    required: true,
    title: 'min_pte_speaking',
  },
  min_pte_writing: {
    type: 'string',
    required: true,
    title: 'min_pte_writing',
  },
  min_pte_overall: {
    type: 'string',
    required: true,
    title: 'min_pte_overall',
  },

  other_requirements: {
    type: 'string',
    required: true,
    title: 'other_requirements',
  },
  is_gre_requirements: {
    type: 'string',
    required: true,
    title: 'is_gre_requirements',
  },
  max_gpa_to_be_required: {
    type: 'string',
    required: true,
    title: 'max_gpa_to_be_required',
  },
  min_verbal: {
    type: 'string',
    required: true,
    title: 'min_verbal',
  },
  min_quantitative: {
    type: 'string',
    required: true,
    title: 'min_quantitative',
  },
  min_writing: {
    type: 'string',
    required: true,
    title: 'min_writing',
  },

  min_verbal_percentile: {
    type: 'string',
    required: true,
    title: 'min_verbal_percentile',
  },
  min_quantitative_percentile: {
    type: 'string',
    required: true,
    title: 'min_quantitative_percentile',
  },
  min_writing_percentile: {
    type: 'string',
    required: true,
    title: 'min_writing_percentile',
  },
  min_verbal_quantitative_combined: {
    type: 'string',
    required: true,
    title: 'min_verbal_quantitative_combined',
  },
  gmat_min_verbal: {
    type: 'string',
    required: true,
    title: 'gmat_min_verbal',
  },
  gmat_min_quantitative: {
    type: 'string',
    required: true,
    title: 'gmat_min_quantitative',
  },
  gmat_min_writing: {
    type: 'string',
    required: true,
    title: 'gmat_min_writing',
  },
  gmat_min_verbal_percentile: {
    type: 'string',
    required: true,
    title: 'gmat_min_verbal_percentile',
  },
  gmat_min_quantitative_percentile: {
    type: 'string',
    required: true,
    title: 'gmat_min_quantitative_percentile',
  },
  gmat_min_writing_percentile: {
    type: 'string',
    required: true,
    title: 'gmat_min_writing_percentile',
  },
  gmat_min_total: {
    type: 'string',
    required: true,
    title: 'gmat_min_total',
  },
  gmat_min_total_percentile: {
    type: 'string',
    required: true,
    title: 'gmat_min_total_percentile',
  },
};

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
    type: 'select',
    search_type: 'exact',
    options: ['Active', 'Draft'],
    inputType: 'text',
    condition: '',
  },
  {
    id: 'program_id',
    field: 'program_id',
    label: 'Program ID',
    type: 'string',
    search_type: 'exact',
    inputType: 'number',
    condition: '',
  },

  {
    id: 'country',
    field: 'country',
    label: 'Country',
    type: 'related',
    search_type: 'exact',
    inputType: 'select',
    condition: '',
  },
  {
    id: 'state_id',
    field: 'state',
    label: 'State',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'city_id',
    field: 'city',
    label: 'City',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'school',
    field: 'school',
    label: 'School',
    type: 'related',
    search_type: 'exact',
    inputType: 'number',
    condition: '',
  },
];
