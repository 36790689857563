export const GET_COUNTRIES_STARTED = 'GET_COUNTRIES_STARTED';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_ENDED = 'GET_COUNTRIES_ENDED';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
export const ADD_COUNTRY_STARTED = 'ADD_COUNTRY_STARTED';
export const ADD_COUNTRY = 'ADD_COUNTRY';
export const ADD_COUNTRY_ENDED = 'ADD_COUNTRY_ENDED';
export const ADD_COUNTRY_ERROR = 'ADD_COUNTRY_ERROR';
export const EDIT_COUNTRY_STARTED = 'EDIT_COUNTRY_STARTED';
export const EDIT_COUNTRY = 'EDIT_COUNTRY';
export const EDIT_COUNTRY_ENDED = 'EDIT_COUNTRY_ENDED';
export const EDIT_COUNTRY_ERROR = 'EDIT_COUNTRY_ERROR';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_STARTED = 'GET_COUNTRY_STARTED';
export const GET_COUNTRY_ENDED = 'GET_COUNTRY_ENDED';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';
