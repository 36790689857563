import {
  REGISTER_AS_STUDENT_STARTED,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCESSFUL,
  GET_CURRENT_USER_STARTED,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_STARTED,
  LOGIN_FAILED,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  GET_CURRENT_USER_ENDED,
} from '../types/auth_types';

const initialState = {
  registration_loading: false,
  registration_errors: null,
  registration_message: null,
  isAuthenticated: false,
  user: null,
  user_loading: true,
  login_loading: false,
  login_errors: null,
  login_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_AS_STUDENT_STARTED:
      return {
        ...state,
        registration_loading: true,
        registration_errors: null,
        registration_message: null,
      };

    case REGISTRATION_FAILED:
      return {
        ...state,
        registration_errors: payload,
        registration_loading: false,
      };
    case REGISTRATION_SUCCESSFUL:
      return {
        ...state,
        registration_message: payload,
        registration_loading: false,
      };
    case LOGIN_STARTED:
      return {
        ...state,
        login_loading: true,
        login_errors: null,
        login_message: null,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        login_errors: payload,
        login_loading: false,
      };
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        login_message: payload,
        login_loading: false,
      };

    case GET_CURRENT_USER_STARTED:
      return {
        ...state,
        user_loading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };
    case GET_CURRENT_USER_ENDED:
      return {
        ...state,
        user_loading: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user_loading: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user_loading: false,
        user: null,
      };

    default:
      return state;
  }
}
