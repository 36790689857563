import React from 'react';
import { Footer, Header } from '../../../components/common';
import {
  AcceptanceRate,
  MainRecruiter,
  SchoolPartnership,
  StudentHelped,
  StudentScholarship,
} from '../../../components/images';
import Testimonial from '../component/Testimonial';

function OurSolutions() {
  return (
    <div>
      <Header />
      <section className="sec-pad page-section recruiter-section-website">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="recruiter-content">
                <h2>
                  Unlock Efficiency, Unlock Savings, and Unlock Business Growth!
                </h2>
                <p>
                  Explore a world of opportunities to optimize your educational
                  journey and maximize your financial benefits. Discover the
                  finest programs and promotions tailored to your needs,
                  ensuring both cost savings and peace of mind throughout your
                  learning experience. With EdChimp, you can access these
                  exclusive programs at your fingertips, empowering you to
                  enhance efficiency, save money, and unlock new avenues for
                  business growth.
                </p>
                <p>
                  Leap into a brighter educational future with EdChimp today.
                </p>
                <div className="explore-button">
                  <a href="#empower" className=" btn btn-join ">
                    Lets Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="recruiter-images">
                <img src={MainRecruiter} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="empower" className="ptb-50 commissions">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-title text-center mb-70">
                <h2>
                  Empowering Your Journey with New <span>Programs</span>!
                </h2>
                <p>
                  Discover exclusive programs at EdChimp that save you time and
                  money. We're always here to support you and your students
                  along the way.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src="/static/media/Best_Commissions.53c1a39df9b02cb8778e.png" />
                  <h3>International Student GIC Program</h3>
                  <p>
                    Enhance your application process with our International
                    Student GIC Program. Simplify your journey to success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src="/static/media/Perks_&_Rewards.120446169b1aaca8d642.png" />

                  <h3>
                    Student <br /> Loans
                  </h3>

                  <p>
                    EdChimp offers Student Loans to ensure financial support
                    doesn't hinder your students' educational aspirations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src="/static/media/Access_to_1,750+_Schools.d517e6d3d1b184ec007c.png" />
                  <h3>Tuition Payments Made Easy</h3>
                  <p>
                    Accelerate your application with EdChimp's quick &
                    dependable fee payment solutions through Flywire, ensuring a
                    seamless experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="commission-box">
                <div className="commission-box-icon">
                  <img src="/static/media/Access_to_1,750+_Schools.d517e6d3d1b184ec007c.png" />
                  <h3>Maximize Savings on English Tests</h3>
                  <p>
                    Access TOEFL & PTE, renowned English language exams, at
                    unbeatable prices exclusively with EdChimp. Save more on
                    your language proficiency journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default OurSolutions;
