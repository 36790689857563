import {
  GET_EDUCATION_LEVELS_STARTED,
  GET_EDUCATION_LEVELS,
  GET_EDUCATION_LEVELS_ENDED,
  ADD_EDUCATION_LEVEL_STARTED,
  ADD_EDUCATION_LEVEL,
  ADD_EDUCATION_LEVEL_ENDED,
  EDIT_EDUCATION_LEVEL_STARTED,
  EDIT_EDUCATION_LEVEL,
  EDIT_EDUCATION_LEVEL_ENDED,
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVEL_STARTED,
  GET_EDUCATION_LEVEL_ENDED,
} from '../types/education_level_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addEducationLevel
export const addEducationLevel = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_EDUCATION_LEVEL_STARTED,
    });
    const { data } = await api.post(`/education-levels/add`, formData);
    dispatch({
      type: ADD_EDUCATION_LEVEL,
      payload: data,
    });
    dispatch({
      type: ADD_EDUCATION_LEVEL_ENDED,
    });
    dispatch(setAlert('Education Level added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_EDUCATION_LEVEL_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getEducationLevels =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_EDUCATION_LEVELS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/education-levels?&${query}`);

      dispatch({
        type: GET_EDUCATION_LEVELS,
        payload: data,
      });
      dispatch({
        type: GET_EDUCATION_LEVELS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_EDUCATION_LEVELS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const filterEducationLevels =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_EDUCATION_LEVELS_STARTED,
      });

      const { data } = await api.get(`/education-levels/filter`);

      dispatch({
        type: GET_EDUCATION_LEVELS,
        payload: data,
      });
      dispatch({
        type: GET_EDUCATION_LEVELS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_EDUCATION_LEVELS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getEducationLevel = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EDUCATION_LEVEL_STARTED,
    });
    const { data } = await api.get(`/education-levels/${id}`);

    dispatch({
      type: GET_EDUCATION_LEVEL,
      payload: data,
    });
    dispatch({
      type: GET_EDUCATION_LEVEL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_EDUCATION_LEVEL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getEducationLevelBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EDUCATION_LEVEL_STARTED,
    });
    const { data } = await api.get(`/education-levels/slug/${slug}`);

    dispatch({
      type: GET_EDUCATION_LEVEL,
      payload: data,
    });
    dispatch({
      type: GET_EDUCATION_LEVEL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_EDUCATION_LEVEL_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editEducationLevel = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_EDUCATION_LEVEL_STARTED,
    });
    const { data } = await api.put(`/education-levels/${id}`, formData);
    dispatch({
      type: EDIT_EDUCATION_LEVEL,
      payload: data,
    });
    dispatch({
      type: EDIT_EDUCATION_LEVEL_ENDED,
    });
    dispatch(setAlert('Education Level Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_EDUCATION_LEVEL_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const deleteEducationLevel = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/education-levels/${id}`);
    dispatch(setAlert('Education Level Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
