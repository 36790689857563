import {
  GET_COUNTRIES_STARTED,
  GET_COUNTRIES,
  GET_COUNTRIES_ENDED,
  ADD_COUNTRY_STARTED,
  ADD_COUNTRY,
  ADD_COUNTRY_ENDED,
  EDIT_COUNTRY_STARTED,
  EDIT_COUNTRY,
  EDIT_COUNTRY_ENDED,
  GET_COUNTRY,
  GET_COUNTRY_STARTED,
  GET_COUNTRY_ENDED,
} from '../types/country_types.js';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addCountry
export const addCountry = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_COUNTRY_STARTED,
    });
    const { data } = await api.post(`/countries/add`, formData);
    dispatch({
      type: ADD_COUNTRY,
      payload: data,
    });
    dispatch({
      type: ADD_COUNTRY_ENDED,
    });
    dispatch(setAlert('Country added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_COUNTRY_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getCountries =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_COUNTRIES_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/countries?&${query}`);

      dispatch({
        type: GET_COUNTRIES,
        payload: data,
      });
      dispatch({
        type: GET_COUNTRIES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_COUNTRIES_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getCountry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRY_STARTED,
    });
    const { data } = await api.get(`/countries/${id}`);

    dispatch({
      type: GET_COUNTRY,
      payload: data,
    });
    dispatch({
      type: GET_COUNTRY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNTRY_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getCountryBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRY_STARTED,
    });
    const { data } = await api.get(`/countries/slug/${slug}`);

    dispatch({
      type: GET_COUNTRY,
      payload: data,
    });
    dispatch({
      type: GET_COUNTRY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNTRY_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editCountry = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNTRY_STARTED,
    });
    const { data } = await api.put(`/countries/${id}`, formData);
    dispatch({
      type: EDIT_COUNTRY,
      payload: data,
    });
    dispatch({
      type: EDIT_COUNTRY_ENDED,
    });
    dispatch(setAlert('Country Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNTRY_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftCountry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNTRY_STARTED,
    });
    dispatch({
      type: GET_COUNTRY_STARTED,
    });
    const { data } = await api.put(`/countries/${id}/draft`);
    dispatch({
      type: EDIT_COUNTRY,
      payload: data,
    });
    dispatch({
      type: GET_COUNTRY,
      payload: data,
    });

    dispatch({
      type: EDIT_COUNTRY_ENDED,
    });
    dispatch({
      type: GET_COUNTRY_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNTRY_ENDED,
    });
    dispatch({
      type: GET_COUNTRY_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateCountry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COUNTRY_STARTED,
    });
    const { data } = await api.put(`/countries/${id}/active`);
    dispatch({
      type: EDIT_COUNTRY,
      payload: data,
    });
    dispatch({
      type: GET_COUNTRY_STARTED,
    });
    dispatch({
      type: EDIT_COUNTRY_ENDED,
    });
    dispatch({
      type: GET_COUNTRY,
      payload: data,
    });
    dispatch({
      type: GET_COUNTRY_ENDED,
    });
    dispatch(setAlert('Country Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_COUNTRY_ENDED,
    });
    dispatch({
      type: GET_COUNTRY_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteCountry = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/countries/${id}`);
    dispatch(setAlert('Country Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
