import React, { useEffect, useState } from 'react';

import slugify from 'react-slugify';
import { Formik, Form, FieldArray } from 'formik';
import {
  TextInput,
  SelectBox,
  TextArea,
  HTMLEditor,
  FileUpload,
  GalleryUpload,
} from '../Form/Form';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

function ProgramForm({
  formik,
  dropdown_data,
  setSchoolSearchTerm,
  edit,
  program,
}) {
  const [dropdownOption, setdropdownOption] = useState(null);
  useEffect(() => {
    if (dropdown_data && dropdown_data.schools) {
      const mappedData = dropdown_data.schools.map((school) => {
        return {
          label: `${school.name} (${school.city})`,
          value: school.id,
        };
      });
      setdropdownOption({ ...dropdownOption, school: mappedData });
    }
  }, [dropdown_data]);

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <TextInput
            label='Name Of Program'
            name='name'
            onChange={(e) => {
              formik.setFieldValue('name', e.target.value);
              formik.setFieldValue('slug', slugify(e.target.value));
            }}
          />
        </div>
        <div className='col-md-6'>
          <Select
            value={
              dropdownOption &&
              dropdownOption.school &&
              dropdownOption.school.filter(
                (option) => option.value === formik.values.school
              )
            }
            // defaultValue={school}
            isClearable={false}
            options={dropdownOption && dropdownOption['school']}
            defaultValue={
              edit && {
                value: program && program.school ? program.school._id : '',
                label:
                  program && program.school
                    ? `${program.school.name} (${program.school.city})`
                    : '',
              }
            }
            onChange={(e) => {
              if (e) {
                formik.setFieldValue('school', e.value);
              }
            }}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 999 }) }}
          />
          {/* <SelectBox label='School / University' name='school'>
            <option value=''></option>
            {dropdown_data &&
              dropdown_data.schools &&
              dropdown_data.schools.map((item) => {
                return (
                  <option
                    value={item.id}
                  >{`${item.name} (${item.city})`}</option>
                );
              })}
          </SelectBox> */}
        </div>
        <div className='col-md-6'>
          <SelectBox label='Delivery Method' name='delivery_method'>
            <option value=''></option>
            <option value='in_class'>In Class</option>
            <option value='online'>Online</option>
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput label='Source' name='source' />
        </div>

        <div className='mb-3 rich'>
          <HTMLEditor
            title='About Program'
            item='description'
            formik={formik}
            col={12}
            value={formik.values.description}
          />
        </div>

        <div className='col-md-12'>
          <SelectBox label='Program Level' name='education_level'>
            <option value=''></option>
            {dropdown_data &&
              dropdown_data.education_levels &&
              dropdown_data.education_levels.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <SelectBox
            label='Discipline'
            name='discipline'
            onChange={(e) => {
              formik.setFieldValue('discipline', e.target.value);
              formik.setFieldValue('sub_discipline', null);
            }}
          >
            <option value=''></option>
            {dropdown_data &&
              dropdown_data.disciplines &&
              dropdown_data.disciplines
                .filter((item) => !item.parent)
                .map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <SelectBox label='Sub Discipline' name='sub_discipline'>
            <option value=''></option>
            {dropdown_data &&
              dropdown_data.disciplines &&
              dropdown_data.disciplines
                .filter(
                  (item) =>
                    item.parent && item.parent._id == formik.values.discipline
                )
                .map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
          </SelectBox>
        </div>

        <div className='col-md-6'>
          <TextInput label='Length Breakdown' name='length_breakdown' />
        </div>

        <div className='col-md-6'>
          <SelectBox label='Commission Type' name='commission_type'>
            <option value=''></option>
            <option value='percentage'>Percentage</option>
            <option value='fixed'>Fixed</option>
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Commission Year'
            name='commission_year'
            type='number'
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Commission'
            name='commission_percentage'
            type='number'
          />
        </div>

        <div className='col-md-6'>
          <TextInput
            type='number'
            label='Tuition Fee of Program'
            name='tuition'
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            type='number'
            label='Application Fee of Program'
            name='application_fee'
          />
        </div>
        <div className='label-heading'>
          <h5>Other Fees</h5>
          <FieldArray
            name='other_fees'
            render={(arrayHelpers) => (
              <div>
                {formik.values.other_fees.map((friend, index) => (
                  <div key={index}>
                    {/* <input
                                name={`todos[${index}].name`}
                                value={formik.values.todos[index].name}
                                onChange={formik.handleChange}
                              /> */}
                    <div className='d-flex justify-content-between'>
                      <div className='label-featu'>Other Fees {index + 1}</div>
                      <div className='closebtn'>
                        <a onClick={() => arrayHelpers.remove(index)}>
                          <i className='fa fa-close'></i>
                        </a>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <TextInput
                        label='Title'
                        name={`other_fees[${index}].title`}
                      />
                    </div>

                    <div className='col-md-12'>
                      <TextInput
                        label='Fees'
                        name={`other_fees[${index}].fees`}
                      />
                    </div>
                  </div>
                ))}
                <button
                  type='button'
                  className='btn primary-btn'
                  onClick={() => arrayHelpers.push({ title: '', fees: '' })}
                >
                  Add Fee
                </button>
              </div>
            )}
          />
        </div>
        <div className='label-heading'>
          <h5>Program Intakes</h5>
          <FieldArray
            name='program_intakes'
            render={(arrayHelpers) => (
              <div>
                {formik.values.program_intakes.map((friend, index) => (
                  <div key={index}>
                    {/* <input
                                name={`todos[${index}].name`}
                                value={formik.values.todos[index].name}
                                onChange={formik.handleChange}
                              /> */}
                    <div className='d-flex justify-content-between'>
                      <div className='label-featu'>
                        Program Intake {index + 1}
                      </div>
                      <div className='closebtn'>
                        <a onClick={() => arrayHelpers.remove(index)}>
                          <i className='fa fa-close'></i>
                        </a>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <SelectBox
                          label='status'
                          name={`program_intakes[${index}].status`}
                        >
                          <option value=''></option>
                          <option value='Open'>Open</option>
                          <option value='Likely Open'>Likely Open</option>
                          <option value='Closed'>Closed</option>
                        </SelectBox>
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Intake Title'
                          type='text'
                          name={`program_intakes[${index}].intake_name`}
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Open Date'
                          type='date'
                          name={`program_intakes[${index}].open_date`}
                          value={moment(
                            formik.values.program_intakes[index].open_date
                          ).format('YYYY-MM-DD')}
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Submission Date'
                          type='date'
                          name={`program_intakes[${index}].submission_deadline`}
                          value={moment(
                            formik.values.program_intakes[index]
                              .submission_deadline
                          ).format('YYYY-MM-DD')}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type='button'
                  className='btn primary-btn'
                  onClick={() => arrayHelpers.push({ name: '' })}
                >
                  Add Program Intake
                </button>
              </div>
            )}
          />
        </div>
      </div>

      <div className='save-button'>
        <button type='submit' className='btn primary-btn w-100'>
          Save & Continue
        </button>
      </div>
    </>
  );
}

export default ProgramForm;
