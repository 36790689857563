import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import TemplateForm from './TemplateForm';
import { useCreateTemplate } from '../../shared/hooks/UseTemplate';

function AddTemplateComponent({ program, school }) {
  const navigate = useNavigate();
  const { addTemplateData } = useCreateTemplate();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Application Requirements</h1>
        </div>

        <Formik
          initialValues={{
            requirement_stage: '',
            title: '',
          }}
          validationSchema={Yup.object({
            requirement_stage: Yup.string().required('Required'),
            title: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            // values.program = program;
            // addAgentData(values);

            addTemplateData(values);
            navigate(`/settings/templates`);
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <TemplateForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddTemplateComponent;
