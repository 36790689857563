import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Pagination from '../common/Pagination';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
function GradingsComponent({
  gradings_loading,
  gradings,
  grading_page,
  grading_pages,
  total_gradings,
  setPageNumber,
}) {
  const navigate = useNavigate();
  const [columnDefs] = useState([
    { field: 'name' },

    {
      field: 'Actions',
      pinned: 'right',
      cellRenderer: ViewButtonByID,
      cellRendererParams: {
        clicked: function (field) {
          navigate(`/settings/grading-schemes/${field}/view`);
        },
        edit_clicked: function (field) {
          navigate(`/settings/grading-schemes/${field}/edit`);
        },
      },
    },
  ]);
  return (
    <PermissionComponent name={'crm_user'} permission={'get_all'}>
      <div>
        <p> {total_gradings}Records Found </p>
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <th scope='col '>Name</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {gradings &&
                gradings.map((grading) => {
                  return (
                    <tr key={grading._id}>
                      <td>{grading.name}</td>

                      <td className='status-ic'>
                        <Link
                          to={`/settings/grading-schemes/${grading._id}/view`}
                          className='badge badge-view'
                        >
                          View
                        </Link>
                        <Link
                          to={`/settings/grading-schemes/${grading._id}/edit`}
                          className='badge badge-edit'
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {gradings && gradings.length == 0 && (
                <tr>
                  <td colSpan={10}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={gradings}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={gradings}
          count={total_gradings}
          pages={grading_pages}
          loading={gradings_loading}
        />
      </div>
    </PermissionComponent>
  );
}

export default GradingsComponent;
