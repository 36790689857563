import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';
import {
  useSingleCrmUser,
  useUpdateCrmUser,
} from '../../shared/hooks/UseCrmUser';

import CrmUserForm from './CrmUserForm';
import { useAllPermissions } from '../../shared/hooks/UsePermissions';
import PermissionComponent from '../auth/PermissionComponent';
function EditCrmUserComponent() {
  const params = useParams();
  const { crm_user_data } = useSingleCrmUser(params.id);
  const { crm_user, crm_user_loading } = crm_user_data;
  const { updateCrmUserData } = useUpdateCrmUser();
  const navigate = useNavigate();
  const { data } = useAllPermissions();
  const { permissions } = data;
  return (
    <div>
      {
        // <PermissionComponent name={'crm_user'} permission={'update'}>
      }
      {crm_user && (
        <div className='personal-information-box clearfix'>
          <div className='main-sec-heading'>
            <h1>Edit CRM User</h1>
          </div>

          <Formik
            initialValues={{
              first_name: crm_user ? crm_user.first_name : '',
              last_name: crm_user ? crm_user.last_name : '',
              phone: crm_user ? crm_user.phone : '',
              email: crm_user ? crm_user.email : '',
              permission: crm_user.permission ? crm_user.permission._id : '',
              nationality: crm_user ? crm_user.nationality : '',
              country_code: crm_user ? crm_user.country_code : '',
            }}
            validationSchema={Yup.object({
              first_name: Yup.string().required('Required'),
              last_name: Yup.string().required('Required'),
              phone: Yup.number()
                .typeError('should be a number.')
                .required('Required'),
              email: Yup.string().email().required('Required'),

              nationality: Yup.string().required('Required'),
              country_code: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateCrmUserData(crm_user._id, values);
              navigate('/settings/crm-users');
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <CrmUserForm
                  dropdown_data={{ permissions: permissions }}
                  hidePassword={true}
                  formik={formik}
                />
              );
            }}
          </Formik>
        </div>
      )}

      {
        // </PermissionComponent>
      }
    </div>
  );
}

export default EditCrmUserComponent;
