import {
  GET_TEMPLATES_STARTED,
  GET_TEMPLATES,
  GET_TEMPLATES_ENDED,
  ADD_TEMPLATE_STARTED,
  ADD_TEMPLATE,
  ADD_TEMPLATE_ENDED,
  EDIT_TEMPLATE_STARTED,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_ENDED,
  GET_TEMPLATE,
  GET_TEMPLATE_STARTED,
  GET_TEMPLATE_ENDED,
} from '../types/template_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addTemplate
export const addTemplate = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TEMPLATE_STARTED,
    });
    const { data } = await api.post(`/templates/add`, formData);
    dispatch({
      type: ADD_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: ADD_TEMPLATE_ENDED,
    });
    dispatch(setAlert('Template added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_TEMPLATE_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getTemplates =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_TEMPLATES_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/templates?&${query}`);

      dispatch({
        type: GET_TEMPLATES,
        payload: data,
      });
      dispatch({
        type: GET_TEMPLATES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_TEMPLATES_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getTemplate = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEMPLATE_STARTED,
    });
    const { data } = await api.get(`/templates/${id}`);

    dispatch({
      type: GET_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getTemplateBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEMPLATE_STARTED,
    });
    const { data } = await api.get(`/templates/slug/${slug}`);

    dispatch({
      type: GET_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editTemplate = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_TEMPLATE_STARTED,
    });
    const { data } = await api.put(`/templates/${id}`, formData);
    dispatch({
      type: EDIT_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: EDIT_TEMPLATE_ENDED,
    });
    dispatch(setAlert('Template Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_TEMPLATE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftTemplate = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_TEMPLATE_STARTED,
    });
    dispatch({
      type: GET_TEMPLATE_STARTED,
    });
    const { data } = await api.put(`/templates/${id}/draft`);
    dispatch({
      type: EDIT_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: GET_TEMPLATE,
      payload: data,
    });

    dispatch({
      type: EDIT_TEMPLATE_ENDED,
    });
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_TEMPLATE_ENDED,
    });
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateTemplate = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_TEMPLATE_STARTED,
    });
    const { data } = await api.put(`/templates/${id}/active`);
    dispatch({
      type: EDIT_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: GET_TEMPLATE_STARTED,
    });
    dispatch({
      type: EDIT_TEMPLATE_ENDED,
    });
    dispatch({
      type: GET_TEMPLATE,
      payload: data,
    });
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });
    dispatch(setAlert('Template Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_TEMPLATE_ENDED,
    });
    dispatch({
      type: GET_TEMPLATE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteTemplate = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/templates/${id}`);
    dispatch(setAlert('Template Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
