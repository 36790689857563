import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';

import {
  useAllDisciplines,
  useSingleDiscipline,
  useUpdateDiscipline,
} from '../../shared/hooks/UseDiscipline';
import DisciplineFormComponent from './DisciplineFormComponent';

function EditDisciplineComponent() {
  const params = useParams();
  const { data } = useSingleDiscipline(params.id);
  const { discipline, discipline_loading } = data;
  const { updateDisciplineData } = useUpdateDiscipline();
  const navigate = useNavigate();
  const { discipline_data } = useAllDisciplines();
  const { disciplines } = discipline_data;
  return (
    <div>
      {discipline && (
        <div className='personal-information-box clearfix'>
          <div className='main-sec-heading'>
            <h1>Edit Discipline</h1>
          </div>

          <Formik
            initialValues={{
              name: discipline ? discipline.name : '',
              parent:
                discipline && discipline.parent ? discipline.parent._id : '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateDisciplineData(discipline._id, values);
              navigate('/settings/disciplines');
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <DisciplineFormComponent
                  dropdown_options={disciplines}
                  formik={formik}
                />
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default EditDisciplineComponent;
