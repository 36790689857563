import React from 'react';

function TrackApplication_component({ application }) {
  return (
    <div className='application-progress-flex'>
      <div>
        <div id='tracking-pre' />
        <div id='tracking'>
          <div className='tracking-list'>
            <div
              className={
                application.is_applied
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Applied</div>
            </div>
            <div
              className={
                application.is_application_fee_paid
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Pay Application Fee</div>
            </div>
            <div
              className={
                application.is_application_prepared
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Prepare Application</div>
            </div>
            <div
              className={
                application.is_application_submitted
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Submission</div>
            </div>
            <div
              className={
                application.is_application_in_offer
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Offer</div>
            </div>
            <div
              className={
                application.is_application_in_post_offer
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Post-Offer Requirements</div>
            </div>
            <div
              className={
                application.is_ucol_loa
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>UCOL/LOA</div>
            </div>
            <div
              className={
                application.is_tuition_fee_paid
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Tuition Fee Paid</div>
            </div>
            <div
              className={
                application.is_visa_applied
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Visa Applied</div>
            </div>
            {application.is_visa_rejected ? (
              <div
                className={
                  application.is_visa_issued
                    ? 'tracking-item'
                    : 'tracking-item-pending'
                }
              >
                <div
                  className='tracking-icon status-intransit'
                  style={{ color: 'red' }}
                >
                  <svg
                    className='svg-inline--fa fa-circle fa-w-16'
                    aria-hidden='true'
                    data-prefix='fas'
                    data-icon='circle'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    data-fa-i2svg
                  >
                    <path
                      fill='currentColor'
                      d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                    />
                  </svg>
                </div>

                <div className='tracking-content'>Visa Rejected</div>
              </div>
            ) : (
              <div
                className={
                  application.is_visa_issued
                    ? 'tracking-item'
                    : 'tracking-item-pending'
                }
              >
                <div className='tracking-icon status-intransit'>
                  <svg
                    className='svg-inline--fa fa-circle fa-w-16'
                    aria-hidden='true'
                    data-prefix='fas'
                    data-icon='circle'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    data-fa-i2svg
                  >
                    <path
                      fill='currentColor'
                      d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                    />
                  </svg>
                </div>

                <div className='tracking-content'>Visa Issued</div>
              </div>
            )}

            <div
              className={
                application.is_enrolled
                  ? 'tracking-item'
                  : 'tracking-item-pending'
              }
            >
              <div className='tracking-icon status-intransit'>
                <svg
                  className='svg-inline--fa fa-circle fa-w-16'
                  aria-hidden='true'
                  data-prefix='fas'
                  data-icon='circle'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                  data-fa-i2svg
                >
                  <path
                    fill='currentColor'
                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
                  />
                </svg>
              </div>

              <div className='tracking-content'>Enrolled</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackApplication_component;
