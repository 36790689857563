export const GET_PROGRAMS_STARTED = 'GET_PROGRAMS_STARTED';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAMS_ENDED = 'GET_PROGRAMS_ENDED';
export const GET_PROGRAMS_ERROR = 'GET_PROGRAMS_ERROR';
export const ADD_PROGRAM_STARTED = 'ADD_PROGRAM_STARTED';
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const ADD_PROGRAM_ENDED = 'ADD_PROGRAM_ENDED';
export const ADD_PROGRAM_ERROR = 'ADD_PROGRAM_ERROR';
export const EDIT_PROGRAM_STARTED = 'EDIT_PROGRAM_STARTED';
export const EDIT_PROGRAM = 'EDIT_PROGRAM';
export const EDIT_PROGRAM_ENDED = 'EDIT_PROGRAM_ENDED';
export const EDIT_PROGRAM_ERROR = 'EDIT_PROGRAM_ERROR';
export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_PROGRAM_STARTED = 'GET_PROGRAM_STARTED';
export const GET_PROGRAM_ENDED = 'GET_PROGRAM_ENDED';
export const GET_PROGRAM_ERROR = 'GET_PROGRAM_ERROR';
