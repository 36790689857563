import { values } from 'lodash';
import React from 'react';

import { CheckBox } from '../../components/Form/Form';
function StudentsRolesComponents({ formik }) {
  return (
    <div className='permissions-box'>
      <div className='col-md-12'>
        <div className='check-all-flex'>
          <div>
            <label className='big'>Students</label>
          </div>
          <div>
            <CheckBox
              name='student.check_all'
              onChange={(e) => {
                if (e.target.checked) {
                  formik.setFieldValue('student', {
                    create: true,
                    get_all: true,
                    view: true,
                    delete: true,
                    update: true,
                    check_all: true,
                    export: true,
                  });
                } else {
                  formik.setFieldValue('student', {
                    create: false,
                    get_all: false,
                    view: false,
                    delete: false,
                    update: false,
                    export: false,
                    check_all: false,
                  });
                }
              }}
            >
              Check All
            </CheckBox>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <CheckBox label='Single' name='student.create'>
              Create
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='student.get_all'>
              Get All
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='student.view'>
              View
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='student.delete'>
              Delete
            </CheckBox>
          </div>
          <div className='col-md-4'>
            <CheckBox label='Single' name='student.update'>
              Update
            </CheckBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsRolesComponents;
