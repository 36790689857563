import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectBox, HTMLEditor } from '../Form/Form';
import * as Yup from 'yup';
function EducationLevelForm() {
  return (
    <Form>
      <div className="row">
        <div className="col-md-6">
          <TextInput label="Family" name="family" />
        </div>
        <div className="col-md-6">
          <TextInput label="Name" name="name" />
        </div>
        <div className="col-md-6">
          <TextInput label="Rank" name="rank" type="number" />
        </div>
        <div className="col-md-6">
          <TextInput label="Type" name="type" />
        </div>
      </div>
      <div className="text-center">
        <button type="submit" className="btn primary-btn ">
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default EducationLevelForm;
