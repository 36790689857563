import React, { useEffect } from 'react';
import { useMarkApplicationAsPaid } from '../../shared/hooks/UseApplication';
import * as qs from 'qs';
import { useParams } from 'react-router-dom';
export const PaymentSuccess = () => {
  const params = useParams();
  const application = params.id;
  const { updateApplicationStatusData } = useMarkApplicationAsPaid();

  useEffect(() => {
    if (application) {
      updateApplicationStatusData(application);
    }
  }, [application]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <h1 className='text-center'> Processing Payment </h1>
        </div>
      </div>
    </div>
  );
};
