import {
  GET_REQUIREMENTS_STARTED,
  GET_REQUIREMENTS,
  GET_REQUIREMENTS_ENDED,
  ADD_REQUIREMENT_STARTED,
  ADD_REQUIREMENT,
  ADD_REQUIREMENT_ENDED,
  EDIT_REQUIREMENT_STARTED,
  EDIT_REQUIREMENT,
  EDIT_REQUIREMENT_ENDED,
  GET_REQUIREMENT,
  GET_REQUIREMENT_STARTED,
  GET_REQUIREMENT_ENDED,
} from '../types/requirement_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addRequirement
export const addRequirement = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_REQUIREMENT_STARTED,
    });
    const { data } = await api.post(`/requirements/add`, formData);
    dispatch({
      type: ADD_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: ADD_REQUIREMENT_ENDED,
    });
    dispatch(setAlert('Requirement added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_REQUIREMENT_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getRequirements =
  ({ program }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_REQUIREMENTS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/requirements?program=${program}&${query}`
      );

      dispatch({
        type: GET_REQUIREMENTS,
        payload: data,
      });
      dispatch({
        type: GET_REQUIREMENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_REQUIREMENTS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getRequirement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REQUIREMENT_STARTED,
    });
    const { data } = await api.get(`/requirements/${id}`);

    dispatch({
      type: GET_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getRequirementBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REQUIREMENT_STARTED,
    });
    const { data } = await api.get(`/requirements/slug/${slug}`);

    dispatch({
      type: GET_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editRequirement = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_REQUIREMENT_STARTED,
    });
    const { data } = await api.put(`/requirements/${id}`, formData);
    dispatch({
      type: EDIT_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: EDIT_REQUIREMENT_ENDED,
    });
    dispatch(setAlert('Requirement Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftRequirement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_REQUIREMENT_STARTED,
    });
    dispatch({
      type: GET_REQUIREMENT_STARTED,
    });
    const { data } = await api.put(`/requirements/${id}/draft`);
    dispatch({
      type: EDIT_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_REQUIREMENT,
      payload: data,
    });

    dispatch({
      type: EDIT_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateRequirement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_REQUIREMENT_STARTED,
    });
    const { data } = await api.put(`/requirements/${id}/active`);
    dispatch({
      type: EDIT_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_REQUIREMENT_STARTED,
    });
    dispatch({
      type: EDIT_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_REQUIREMENT,
      payload: data,
    });
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });
    dispatch(setAlert('Requirement Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_REQUIREMENT_ENDED,
    });
    dispatch({
      type: GET_REQUIREMENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteRequirement = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/requirements/${id}`);
    dispatch(setAlert('Requirement Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
