import React from 'react';
import { URI } from '../../domain/constant';

function ViewCrmUserComponent({ crm_user }) {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>First Name</h2>
          <span>{crm_user.first_name}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Last Name</h2>
          <span>{crm_user.last_name}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Email</h2>
          <span>{crm_user.email}</span>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Nationality</h2>
          <span>{crm_user.nationality}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Country Code</h2>
          <span>{crm_user.country_code}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Phone</h2>
          <span>{crm_user.phone}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Profile Pic</h2>
          <span>
            {crm_user.profile_pic ? (
              <img
                src={`${URI}${crm_user.profile_pic}`}
                style={{ width: '150px', height: '150px', borderRadius: '50%' }}
              />
            ) : (
              '-'
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ViewCrmUserComponent;
