import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import {
  addStudent,
  deleteStudent,
  getStudent,
  getStudents,
  studentsFilter,
} from '../../store/actions/student_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { filterSchools } from '../../store/actions/school_action';
export const useGetStudentProfile = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.student);
  const getStudentInfo = async (id) => {
    dispatch(getStudent(id));
  };
  return {
    getStudentInfo,
    student_loading: data.student_loading,
    student: data.student,
  };
};

// Get All Data
export const useAllStudents = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.student);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getStudents({}));
    }, 1000),
    []
  );

  return { data, setPageNumber };
};

// Get All Data
export const useAllStudentsFilters = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const data = useSelector((state) => state.student);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(studentsFilter({}));
  }, []);

  return {
    data,
    setPageNumber,
    students_data: data,
    setStudentSearchTerm: setSearchTerm,
  };
};

// Add Data
export const useCreateStudent = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.student);
  const addStudentData = async (data) => {
    dispatch(addStudent(data));
  };
  return { data, addStudentData };
};

// Delete School
export const useDeleteStudent = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.student);
  const deleteStudentFunction = async (id) => {
    dispatch(deleteStudent(id));
  };
  return { deleteStudentFunction };
};
