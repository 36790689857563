import React, { useEffect } from 'react';
import { AuthBreadCrumb } from '../../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../../components/auth_common/AuthHeader';
import { AuthProfileTabs } from '../../../components/auth_common/AuthProfileTabs';
import { EducationSummary } from '../../../components/education_history/EducationSummary';
import { PROFILE_TABS_ENUM } from '../../../domain/profiletab';
import { useGetStudentProfile } from '../../../shared/hooks/UseStudent';
import { useCheckAuthenticated } from '../../../shared/hooks/UseAuth';
import Skeleton from 'react-loading-skeleton';
import Auth_header_mobile from '../../../components/auth_common/Auth_header_mobile';
import { SchoolAttended } from '../../../components/education_history/SchoolAttended';

export const EducationHistory = (props) => {
  const { getStudentInfo, student_loading, student } = useGetStudentProfile();
  const userData = useCheckAuthenticated();

  const user_id = userData.user ? userData.user.id : null;
  useEffect(() => {
    if (user_id) {
      getStudentInfo(user_id);
    }
  }, [user_id]);
  return (
    <>
      <div className='wrapper'>
        <Auth_header_mobile />
        <AuthHeader />
        <AuthBreadCrumb title={'Education History'} />
        <section className='profile-main ptb-30'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='main-sec-heading'>
                  <h1>My Profile</h1>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <AuthProfileTabs active={PROFILE_TABS_ENUM.EDUCATION_HISTORY} />
              </div>
            </div>
          </div>
        </section>
        {student_loading ? (
          <>
            <section className='personal-information'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='personal-information-box clearfix'>
                      <Skeleton height={24} />
                      <hr />
                      <Skeleton count={4} height={44} />

                      <div className='text-center'>
                        <Skeleton height={55} width={150} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='personal-information-box clearfix'>
                      <Skeleton height={24} />
                      <hr />
                      <Skeleton count={4} height={44} />

                      <div className='text-center'>
                        <Skeleton height={55} width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className='personal-information '>
            {student && (
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <EducationSummary
                      student_info={student}
                      student_loading={student_loading}
                    />
                  </div>
                  <div className='col-md-12'>
                    <SchoolAttended
                      student_info={student}
                      student_loading={student_loading}
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
          // useless
        )}
        <AuthFooter />
      </div>
    </>
  );
};
