export const GET_GRADINGS_STARTED = 'GET_GRADINGS_STARTED';
export const GET_GRADINGS = 'GET_GRADINGS';
export const GET_GRADINGS_ENDED = 'GET_GRADINGS_ENDED';
export const GET_GRADINGS_ERROR = 'GET_GRADINGS_ERROR';
export const ADD_GRADING_STARTED = 'ADD_GRADING_STARTED';
export const ADD_GRADING = 'ADD_GRADING';
export const ADD_GRADING_ENDED = 'ADD_GRADING_ENDED';
export const ADD_GRADING_ERROR = 'ADD_GRADING_ERROR';
export const EDIT_GRADING_STARTED = 'EDIT_GRADING_STARTED';
export const EDIT_GRADING = 'EDIT_GRADING';
export const EDIT_GRADING_ENDED = 'EDIT_GRADING_ENDED';
export const EDIT_GRADING_ERROR = 'EDIT_GRADING_ERROR';
export const GET_GRADING = 'GET_GRADING';
export const GET_GRADING_STARTED = 'GET_GRADING_STARTED';
export const GET_GRADING_ENDED = 'GET_GRADING_ENDED';
export const GET_GRADING_ERROR = 'GET_GRADING_ERROR';
