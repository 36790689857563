import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';

import UnpaidApplicationsComponent from '../../components/my_applications/UnpaidApplicationsComponent';

import { STUDENT_PROFILE_ENUM } from '../../domain/student_profile_menu';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { useAllProgramsReport } from '../../shared/hooks/UseProgram';
import { UseDataForApplications, UseFilter } from '../../shared/hooks/UseExcel';
import ExportComponent from '../../components/common/ExportComponent';
import { useAllApplicationsReport } from '../../shared/hooks/UseApplication';
function MyApplications() {
  const auth_data = useCheckAuthenticated();
  const { user } = auth_data;
  const [showExportButton, setShowExportButton] = useState(false);
  const { reportData, getData, loading_report } = useAllApplicationsReport();

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForApplications();

  useEffect(() => {
    if (reportData && reportData.applications) {
      convertToReadable(reportData.applications);
    }
  }, [reportData]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Applications', 'applications');
  };

  return (
    <div>
      <Auth_header_mobile active={STUDENT_PROFILE_ENUM.MY_APPLICATIONS} />
      <AuthHeader active={STUDENT_PROFILE_ENUM.MY_APPLICATIONS} />
      <AuthBreadCrumb title={'MyApplications'} />

      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <div class='all-school-flex'>
                <div className='main-sec-heading'>
                  <div>
                    <h1> My Applications </h1>
                  </div>
                </div>
                <div class='export-flex gap-2' style={{ gap: '2px' }}>
                  <PermissionComponent
                    name={'application'}
                    permission={'get_all'}
                  >
                    {user && user.role === 'CRM_USER' && (
                      <div>
                        <Link
                          className='btn primary-btn-h '
                          to={`/my-applications?crm_user=${user._id}`}
                        >
                          {' '}
                          My Applications{' '}
                        </Link>
                      </div>
                    )}
                  </PermissionComponent>
                  <div className=''>
                    <PermissionComponent
                      name={'program'}
                      permission={'create'}
                      hideOnStudent={true}
                      hideOnAgent={true}
                    >
                      {showExportButton ? (
                        !loading_report ? (
                          <ExportComponent handleOnExport={handleOnExport} />
                        ) : (
                          <button className='btn btn-generate'>
                            Generating...{' '}
                          </button>
                        )
                      ) : (
                        <button
                          className='btn btn-generate'
                          onClick={() => {
                            setShowExportButton(true);
                            getData();
                          }}
                        >
                          Generate Report
                        </button>
                      )}
                    </PermissionComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='students-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <UnpaidApplicationsComponent />
              {/* <PaidApplications /> */}
            </div>
          </div>
        </div>
      </section>

      <AuthFooter />
    </div>
  );
}

export default MyApplications;
