import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { useNavigate } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { useSingleGrading } from '../../shared/hooks/UseGrading';

import ViewGradingComponent from '../../components/gradings/ViewGradingComponent';

function ViewGrading() {
  const params = useParams();
  const navigate = useNavigate();
  const { grading_data } = useSingleGrading(params.id);
  const { grading } = grading_data;

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={grading && grading.name} />
      <PermissionComponent name={'crm_user'} permission={'view'}>
        {grading && (
          <section className='profile-main ptb-20'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='main-sec-heading'>
                    <h1>{grading.name}</h1>
                  </div>
                </div>
                <div className='col-md-6 col-6'>
                  <div className='add-student-btn'>
                    <Link
                      to={`/settings/grading-schemes/${grading.id}/edit`}
                      className='btn primary-btn-h '
                    >
                      Edit Grading
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {grading && (
          <section className='main-school-single-view ptb-20 '>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <ViewGradingComponent grading={grading} />
                </div>
              </div>
            </div>
          </section>
        )}
      </PermissionComponent>
      <AuthFooter />
    </div>
  );
}

export default ViewGrading;
