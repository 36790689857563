import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  TextInput,
  SelectBox,
  TextArea,
  HTMLEditor,
  FileUpload,
  GalleryUpload,
} from '../Form/Form';
import * as Yup from 'yup';
import CountryStateCity from '../common/CountryStateCity';
import { Country, State, City } from 'country-state-city';
import slugify from 'react-slugify';
import SchoolForm from './SchoolForm';
import { schoolValidationSchema } from './SchoolValidation';

function AddSchoolComponent({ handleSubmitData, markets }) {
  const navigate = useNavigate();

  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add School</h1>
        </div>

        <Formik
          initialValues={{
            about: '',
            scholarship: '',
            address: '',
            allow_agency_recruitment: false,
            can_work_and_study: false,
            city: '',
            conditional_acceptance: false,
            coop_participating: false,
            coop_participating_show: false,
            coop_participating_text: '',
            cost_of_living: '',
            slug: '',
            dial_code: '',
            email: '',
            facebook: '',
            founded_in: '',
            institution_type: '',
            latitude: '',
            linkedin: '',
            longitude: '',
            name: '',
            number_of_international_students: '',
            phone: '',
            postal_code: '',
            state: '',
            school_rank: '',
            show_other_requirements_fees: false,
            submission_path_note: '',
            submission_through: '',
            time_zone: '',
            total_number_of_students: '',
            tuition_deposit: '',
            tuition_information: '',
            twitter: '',
            video_link: '',
            source: '',
            website: '',
            conditional_acceptance_text: '',
            exceptional_submission_path: '',
            school_photos: [],
            logo: '',
            commission_type: '',
            commission_year: '',
            commission_percentage: '',
            school_features: [{ title: '', description: '' }],
          }}
          validationSchema={schoolValidationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleSubmitData(values);

            navigate('/schools');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <SchoolForm formik={formik} dropdown_options={{ markets }} />
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default AddSchoolComponent;
