import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import FilterComponent from '../../components/filter/FilterComponent';
import PermissionComponent from '../auth/PermissionComponent';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
function PayoutComponent() {
  const navigate = useNavigate();
  const [columnDefs, setColumnDefs] = useState([
    { field: 'date', filter: true },

    {
      field: 'status',
      cellStyle: { background: '#fff5e8', color: '#f99520' },
      filter: true,
    },

    { field: 'charges', filter: true },
    { field: 'refunds', filter: true },
    { field: 'fees', filter: true },
    { field: 'total', filter: true },
    {
      field: 'Actions',
      pinned: 'right',

      cellRendererParams: {
        clicked: function (field) {},
      },
    },
  ]);
  const [rowData] = useState([
    {
      date: '23 Apr 2023',
      status: 'hello',
      charges: 35000,
      refunds: 35000,
      fees: 35000,
      total: 35000,
    },
  ]);
  return (
    <>
      <section className='profile-main ptb-30'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Payouts</h1>
              </div>
              <div className='badge-export'>
                <a href='' className='badge bg-export'>
                  <i className='fa fa-upload'></i> Export
                </a>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'agents'} permission={'create'}>
                <div className='add-student-btn'>
                  <Link to={links.addAgent} className='btn primary-btn-h '>
                    Transactions
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      <section className='payout-main-section ptb-30'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 mx-auto'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='payout-numbers-box'>
                    <div className='payment-date-flex'>
                      <div className='payout-when'>
                        <p>Next Payout</p>
                      </div>
                      <div className='payout-date'>
                        <p>Apr 2 ,2023</p>
                      </div>
                    </div>
                    <div className='payment-date-flex bor'>
                      <div className='payout-amount'>
                        <h2>$4,962.34</h2>
                      </div>
                      <div className='ispaid'>
                        <a href='' className='badge success'>
                          Success
                        </a>
                      </div>
                    </div>
                    <div className='view-transactions'>
                      <a href='#'>View Transactions</a>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='payout-numbers-box'>
                    <div className='payment-date-flex'>
                      <div className='payout-when'>
                        <p>Next Payout</p>
                      </div>
                      <div className='payout-date'>
                        <p>Apr 2 ,2023</p>
                      </div>
                    </div>
                    <div className='payment-date-flex bor'>
                      <div className='payout-amount'>
                        <h2>$4,962.34</h2>
                      </div>
                      <div className='ispaid'>
                        <a href='' className='badge pending'>
                          Pending
                        </a>
                      </div>
                    </div>
                    <div className='view-transactions'>
                      <a href='#'>View Transactions</a>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='payout-numbers-box'>
                    <div className='payment-date-flex'>
                      <div className='payout-when'>
                        <p>Balance</p>
                      </div>
                      <div className='payout-date'>
                        <p>Apr 2 ,2023</p>
                      </div>
                    </div>
                    <div className='payment-date-flex bor'>
                      <div className='payout-amount'>
                        <h2>$4,962.34</h2>
                      </div>
                      <div className='ispaid'>
                        <a href='' className='badge success'>
                          Paid
                        </a>
                      </div>
                    </div>
                    <div className='view-transactions'>
                      <a href='#'>View Transactions</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='transactions ptb-30'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-sec-heading'>
                <h1>All Payouts</h1>
              </div>
              <FilterComponent />
              <section className='students-section'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div
                        className='ag-theme-alpine pb-50'
                        style={{ width: '100%', height: 500 }}
                      >
                        <AgGridReact
                          rowData={rowData}
                          columnDefs={columnDefs}
                        ></AgGridReact>
                      </div>
                      <Pagination />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PayoutComponent;
