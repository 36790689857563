import React, { useState } from 'react';

function FeaturesAccordianComponent({ title, content }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      <div className="main-accordian">
        <div
          className="accordian-container"
          onClick={() => setIsActive(!isActive)}
        >
          <div>
            <h5>{title}</h5>
          </div>
          <div>
            {isActive ? (
              <i className="fa fa-chevron-down"></i>
            ) : (
              <i className="fa fa-chevron-right"></i>
            )}
          </div>
        </div>
        {isActive && (
          <div className="accordion-content">
            <p>{content}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeaturesAccordianComponent;
