import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useCreateAcademicPartner } from '../../shared/hooks/UseAcademicPartner';
import AcademicPartnerForm from './AcademicPartnerForm';
import { useAllPermissions } from '../../shared/hooks/UsePermissions';
function AddAcademicPartnerComponent() {
  const { addAcademicPartnerData } = useCreateAcademicPartner();
  const navigate = useNavigate();
  const { data } = useAllPermissions();
  const { permissions } = data;

  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Academic Partner</h1>
        </div>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            profile_pic: '',
            phone: '',
            email: '',
            password: '',
            nationality: '',
            country_code: '',
            Confirm_password: '',
          }}
          validationSchema={Yup.object({
            first_name: Yup.string().required('Required'),
            phone: Yup.number()
              .typeError('should be a number.')
              .required('Required'),
            email: Yup.string().email().required('Required'),
            password: Yup.string()
              .required('Required')
              .min(
                4,
                ({ min }) => `Password must be at least ${min} characters`
              )
              .max(
                20,
                ({ max }) => `Password must be at least ${max} characters`
              ),
            nationality: Yup.string().required('Required'),
            country_code: Yup.string().required('Required'),
            confirm_password: Yup.string()
              .required('Required')
              .oneOf(
                [Yup.ref('password')],
                'Both password need to be the same'
              ),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addAcademicPartnerData(values);
            navigate('/settings/academic-partners');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <AcademicPartnerForm
                formik={formik}
                dropdown_data={{ permissions: permissions }}
              />
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddAcademicPartnerComponent;
