import React, { useState, useEffect } from 'react';

import UnpaidApplicationsCard from './UnpaidApplicationsCard';
import {
  useAllApplications,
  useDeleteApplication,
  useAssignProgramInApplication,
} from '../../shared/hooks/UseApplication';
import Pagination from '../common/Pagination';
import { Link } from 'react-router-dom';
import PermissionComponent from '../auth/PermissionComponent';
import ApplicationFilter from './ApplicationFilter';
import moment from 'moment';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../Form/Form';
import AsyncSelect from 'react-select/async';
import { useAllProgramsBySchoolFilters } from '../../shared/hooks/UseProgram';

function UnpaidApplicationsComponent() {
  const { application_data } = useAllApplications();
  const { assignProgramInApplicationHandler } = useAssignProgramInApplication();
  const {
    applications,
    applications_loading,
    total_applications,
    application_pages,
  } = application_data;
  const { deleteApplicationFunction } = useDeleteApplication();
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [checkDeleteApplication, setCheckDeleteApplication] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const deleteApplicationBtnClicked = async () => {
    await deleteApplicationFunction(applicationToDelete);

    // navigate('/my-applications');
  };

  const [showAssignProgramModal, setShowAssignProgramModal] = useState(false);

  const assignProgramHandler = (school) => {
    setSelectedSchool(school);
    setShowAssignProgramModal(true);
    // navigate(`/schools/${school}/programs`);
  };
  const {
    programs_data,
    setProgramSearchTerm,
    selectedSchool,
    setSelectedSchool,
  } = useAllProgramsBySchoolFilters();
  const { programs } = programs_data;

  const [dropdown_options, setDropdown_options] = useState(null);

  useEffect(() => {
    if (programs) {
      const mappedProgramData = programs.map((program) => {
        return {
          label: program.name,
          value: program._id,
        };
      });
      setDropdown_options({ ...dropdown_options, program: mappedProgramData });
    }
  }, [programs]);

  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'program') {
      setProgramSearchTerm(inputValue);
      callback(dropdown_options.program);
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '450px',
    },
  };

  return (
    <div className='applications-main'>
      <PermissionComponent
        name={'application'}
        permission={'view'}
        hideOnAcademicPartner={true}
        hideOnAgent={true}
        hideOnStudent={true}
      >
        <ApplicationFilter />
      </PermissionComponent>

      {/* <div className="unpaid-applications">
        <h6>Unpaid Applications</h6>
      </div> */}

      <div className='myapplication-table'>
        <table className='table table-responsive  table-bordered  table-hover '>
          <thead>
            <tr>
              <th scope='col'>Status</th>
              <th scope='col'>App #</th>
              <th scope='col'>School Name</th>
              <th scope='col'>Student Name</th>
              <th scope='col'>Country</th>
              <th scope='col'>Applied Country</th>
              {/* <th scope='col'>Program Name</th> */}
              {/* <th scope="col">Intake</th> */}
              <th scope='col'>Agent Name</th>
              <th scope='col'>Assistant</th>
              <th scope='col'>Fee</th>
              <th scope='col'>CreatedAt</th>
              <PermissionComponent
                name={'application'}
                permission={'view'}
                hideOnAcademicPartner={true}
              >
                <th scope='col'>Status</th>
              </PermissionComponent>
            </tr>
          </thead>
          <tbody>
            {!applications_loading ? (
              <>
                {applications && applications.length > 0 ? (
                  applications.map((application) => {
                    return (
                      <tr>
                        <th scope='row'>
                          <div class='items item-flex'>
                            {/* <i class='fa fa-hourglass-o'></i> */}
                            <p class='pending-pay'>
                              {application.is_application_closed
                                ? 'Closed'
                                : application.application_current_status}
                            </p>
                          </div>
                        </th>
                        <td> {application.application_id} </td>
                        <td>
                          {application.school && (
                            <Link to={`/schools/${application.school._id}`}>
                              {' '}
                              {application.school &&
                                application.school.name}{' '}
                            </Link>
                          )}
                        </td>
                        <td>
                          {application.student && (
                            <Link
                              to={`/students/${application.student._id}/general-information`}
                            >
                              {application.student.first_name}{' '}
                              {application.student.last_name}
                            </Link>
                          )}
                        </td>
                        <td>
                          {application.country && <>{application.country}</>}
                        </td>
                        <td>
                          {application.student && (
                            <>{application.student.nationality}</>
                          )}
                        </td>
                        {/* <td>
                          {application.program && application.school ? (
                            <Link
                              to={`/schools/${application.school._id}/programs/${application.program._id}`}
                            >
                              {application.program.name
                                ? application.program.name
                                : 'NA'}{' '}
                            </Link>
                          ) : (
                            <div>
                              {' '}
                              <button
                                className='btn btn-sm'
                                onClick={() => {
                                  assignProgramHandler(
                                    application?.school?._id
                                  );
                                  setSelectedApplication(application._id);
                                }}
                              >
                                {' '}
                                <i className='fa fa-edit'></i>{' '}
                              </button>{' '}
                            </div>
                          )}
                        </td> */}
                        {/* <td>
                          <div class="form-group ">
                            <label for="Application ">Academic</label>
                            <select
                              name="application-fee"
                              class="form-control input-lg"
                              tabindex="17"
                            >
                              <option value="options">2024-Jan</option>
                            </select>
                          </div>
                        </td> */}
                        <td>
                          {' '}
                          {application.agentData &&
                          application.agentData.agentInfo
                            ? application.agentData.agentInfo.company_name
                            : 'NA'}{' '}
                        </td>
                        <td>
                          {application.crm_user &&
                          application.crm_user.first_name
                            ? `${application.crm_user.first_name} ${application.crm_user.last_name}`
                            : 'NA'}
                        </td>
                        <td>
                          {' '}
                          {application.application_fee
                            ? application.application_fee
                            : '0'}{' '}
                          {application.program &&
                            application.program.school &&
                            application.program.school.market &&
                            application.program.school.market.currency}
                        </td>
                        <td>
                          {application && application.createdAt
                            ? moment(application.createdAt).format(
                                'DD-MM-YYYY hh:mm A'
                              )
                            : 'NA'}
                        </td>
                        <PermissionComponent
                          name={'application'}
                          permission={'view'}
                          hideOnAcademicPartner={true}
                        >
                          <td className='status-ic'>
                            <Link
                              to={`/my-applications/${application._id}`}
                              className='badge badge-view'
                              target='_blank'
                            >
                              View
                            </Link>
                            <PermissionComponent
                              name={'application'}
                              permission={'delete'}
                              hideOnAgent={true}
                              hideOnStudent={true}
                              hideOnAcademicPartner={true}
                            >
                              <a
                                onClick={() => {
                                  setApplicationToDelete(application._id);
                                  setCheckDeleteApplication(true);
                                }}
                                className='badge badge-delete'
                              >
                                Delete
                              </a>
                            </PermissionComponent>
                          </td>
                        </PermissionComponent>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>No Data Found</td>
                  </tr>
                )}
              </>
            ) : (
              <> Loading... </>
            )}
          </tbody>
        </table>
      </div>

      {/* <div className="grid-box">
        <div className="grid-container">
          <div className="items">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
            </div>
          </div>
          <div className="items">
            <p>Status</p>
          </div>
          <div className="items">
            <p>App #</p>
          </div>
          <div className="items">
            <p>School</p>
          </div>
          <div className='items'>
            <p>Program</p>
          </div>

          <div className="items">
            <p>Start Date</p>
          </div>
          <div className="items">
            <p>Fees</p>
          </div>
        </div>
      </div>
      {!applications_loading ? (
        <>
          {applications && applications.length > 0 ? (
            applications.map((application) => {
              return (
                <>
                  <UnpaidApplicationsCard application={application} />
                </>
              );
            })
          ) : (
            <div className="mb-5 text-center">No Record Found</div>
          )}
          <Pagination
            count={total_applications}
            pages={application_pages}
            loading={applications_loading}
          />
        </>
      ) : (
        <> Loading... </>
      )} */}
      <Pagination
        count={total_applications}
        pages={application_pages}
        loading={applications_loading}
      />
      {checkDeleteApplication && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setCheckDeleteApplication(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>
                    Do you really want to delete this record? This process
                    cannot be undone.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setCheckDeleteApplication(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteApplicationBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAssignProgramModal && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <button
                    type='button'
                    className='close'
                    onClick={() => {
                      setSelectedSchool(null);
                      setSelectedApplication(null);
                      setShowAssignProgramModal(false);
                    }}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <Formik
                    initialValues={{
                      program: '',
                    }}
                    validationSchema={Yup.object({
                      program: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      // application.applicationDetails
                      // .paid_from_college
                      // updateApplicationStatusData(application._id, values);
                      assignProgramInApplicationHandler(
                        selectedApplication,
                        values
                      );
                      setSelectedSchool(null);
                      setSelectedApplication(null);
                      setShowAssignProgramModal(false);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-12'>
                              <label htmlFor='User'> Select Program </label>

                              <AsyncSelect
                                loadOptions={(inputValue, callback) =>
                                  loadOptions(inputValue, callback, 'program')
                                }
                                isClearable={true}
                                defaultOptions={
                                  dropdown_options &&
                                  dropdown_options['program']
                                }
                                onChange={(e) => {
                                  if (e) {
                                    formik.setFieldValue('program', e.value);
                                    // setProgram(e.value);
                                    // handleSidebarChange({
                                    //   name: 'program',
                                    //   value: e.value,
                                    // });
                                  }
                                }}
                              />
                            </div>

                            <div className='modal-footer justify-content-center'>
                              <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={() => {
                                  setSelectedSchool(null);
                                  setSelectedApplication(null);
                                  setShowAssignProgramModal(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button type='submit' className='btn btn-success'>
                                Save
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <Modal
        style={customStyles}
        isOpen={showAssignProgramModal}
        onRequestClose={() => setShowAssignProgramModal(false)}
        contentLabel='Change Status'
      >
        <h2> Enter Agent Commission Payout </h2>
        <Formik
          initialValues={{
            commission_prospect_agent: '',
          }}
          validationSchema={Yup.object({
            commission_prospect_agent: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            // application.applicationDetails
            // .paid_from_college
            // updateApplicationStatusData(application._id, values);

            setShowAssignProgramModal(false);
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            // 
            return (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='User'> Commission </label>
                    <TextInput name='commission_prospect_agent' type='number' />
                  </div>

                  <div className=''>
                    <button className='btn btn-success btn-sm' type='submit'>
                      <i className='fa fa-save'></i>
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <hr />
        <div className='d-flex justify-content-end'>
          <a onClick={() => setShowAssignProgramModal(false)}>X</a>
        </div>
      </Modal> */}
    </div>
  );
}

export default UnpaidApplicationsComponent;
