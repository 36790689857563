import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AddRequirementForm from './AddRequirementForm';
import {
  useSingleRequirement,
  useUpdateRequirement,
} from '../../shared/hooks/UseRequirement';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';

function EditRequirementsComponent({ program, school, requirement_id }) {
  const { data } = useSingleRequirement(requirement_id);
  const { updateRequirementData } = useUpdateRequirement();
  const { requirement } = data;
  const navigate = useNavigate();
  const { educationLevelData } = useAllEducationLevels();
  const { education_levels } = educationLevelData;
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Edit Requirements</h1>
        </div>
        {requirement && (
          <Formik
            initialValues={{
              english_score_required: requirement.english_score_required
                ? requirement.english_score_required
                : false,
              gpa_type: requirement.gpa_type
                ? requirement.gpa_type
                : 'Percentage',
              allow_delayed_proof_of_elp: requirement.allow_delayed_proof_of_elp
                ? requirement.allow_delayed_proof_of_elp
                : false,
              min_gpa: requirement.min_gpa ? requirement.min_gpa : '',
              min_toefl_reading: requirement.min_toefl_reading
                ? requirement.min_toefl_reading
                : '',
              min_toefl_writing: requirement.min_toefl_writing
                ? requirement.min_toefl_writing
                : '',
              min_toefl_listening: requirement.min_toefl_listening
                ? requirement.min_toefl_listening
                : '',
              min_toefl_speaking: requirement.min_toefl_speaking
                ? requirement.min_toefl_speaking
                : '',
              min_toefl_total: requirement.min_toefl_total
                ? requirement.min_toefl_total
                : '',
              min_ielts_reading: requirement.min_ielts_reading
                ? requirement.min_ielts_reading
                : '',
              min_ielts_writing: requirement.min_ielts_writing
                ? requirement.min_ielts_writing
                : '',
              min_ielts_listening: requirement.min_ielts_listening
                ? requirement.min_ielts_listening
                : '',
              min_ielts_speaking: requirement.min_ielts_speaking
                ? requirement.min_ielts_speaking
                : '',
              min_ielts_average: requirement.min_ielts_average
                ? requirement.min_ielts_average
                : '',
              min_ielts_any_band: requirement.min_ielts_any_band
                ? requirement.min_ielts_any_band
                : '',
              min_ielts_any_band_count: requirement.min_ielts_any_band_count
                ? requirement.min_ielts_any_band_count
                : '',
              min_duolingo_score: requirement.min_duolingo_score
                ? requirement.min_duolingo_score
                : '',
              min_pte_listening: requirement.min_pte_listening
                ? requirement.min_pte_listening
                : '',
              min_pte_reading: requirement.min_pte_reading
                ? requirement.min_pte_reading
                : '',
              min_pte_speaking: requirement.min_pte_speaking
                ? requirement.min_pte_speaking
                : '',
              min_pte_writing: requirement.min_pte_writing
                ? requirement.min_pte_writing
                : '',
              min_pte_overall: requirement.min_pte_overall
                ? requirement.min_pte_overall
                : '',
              level: requirement.level ? requirement.level : '',
              level_text: requirement.level_text ? requirement.level_text : '',
              other_requirements: requirement.other_requirements
                ? requirement.other_requirements
                : '',
              gre_requirements: requirement.gre_requirements
                ? requirement.gre_requirements
                : {
                    required: '',
                    max_gpa_to_be_required: '',
                    min_verbal: '',
                    min_quantitative: '',
                    min_writing: '',
                    min_verbal_percentile: '',
                    min_quantitative_percentile: '',
                    min_writing_percentile: '',
                    min_verbal_quantitative_combined: '',
                    gmat_min_verbal: '',
                    gmat_min_quantitative: '',
                    gmat_min_writing: '',
                    gmat_min_verbal_percentile: '',
                    gmat_min_quantitative_percentile: '',
                    gmat_min_writing_percentile: '',
                    gmat_min_total: '',
                    gmat_min_total_percentile: '',
                  },
            }}
            validationSchema={Yup.object({
              level: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              values.program = program;
              updateRequirementData(requirement_id, values);

              navigate(`/schools/${school}}/programs/${program}`);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <AddRequirementForm
                  formik={formik}
                  dropdown_options={{ education_levels: education_levels }}
                />
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default EditRequirementsComponent;
