import React from 'react';

import { countries } from '../../domain/countries';
import { Formik, Form } from 'formik';
import { TextInput, SelectBox, CheckBox } from '../../components/Form/Form';

function AddRequirementForm({ formik, dropdown_options }) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <CheckBox name='english_score_required'>
            English Score Required
          </CheckBox>
        </div>
        <div className='col-md-6'>
          <CheckBox name='allow_delayed_proof_of_elp'>
            Allow Delayed Proof of Elp
          </CheckBox>
        </div>

        <div className='col-md-4'>
          <TextInput label='Min GPA' name='min_gpa' />
        </div>
        <div className='col-md-4'>
          <SelectBox label='GPA type' name='gpa_type'>
            <option value=''></option>
            <option value='Percentage'>Percentage</option>
            <option value='GPA'>GPA</option>
          </SelectBox>
        </div>
        <div className='col-md-4'>
          <TextInput label='Min TOEFL Reading' name='min_toefl_reading' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min TOEFL Writing' name='min_toefl_writing' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min TOEFL Listening' name='min_toefl_listening' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min TOEFL Speaking' name='min_toefl_speaking' />
        </div>

        <div className='col-md-4'>
          <TextInput label='Min TOEFL Total' name='min_toefl_total' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min IELTS Reading' name='min_ielts_reading' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min IELTS Writing' name='min_ielts_writing' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min IELTS Listening' name='min_ielts_listening' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min IELTS Speaking' name='min_ielts_speaking' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min IELTS Average' name='min_ielts_average' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min IELTS Any Band' name='min_ielts_any_band' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min IELTS Any Band Count'
            name='min_ielts_any_band_count'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min UKVI IELTS Reading'
            name='min_ielts_ukvi_reading'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min UKVI IELTS Writing'
            name='min_ielts_ukvi_writing'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min UKVI IELTS Listening'
            name='min_ielts_ukvi_listening'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min UKVI IELTS Speaking'
            name='min_ielts_ukvi_speaking'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min UKVI IELTS Average'
            name='min_ielts_ukvi_average'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Min UKVI IELTS Any Band'
            name='min_ielts_ukvi_any_band'
          />
        </div>

        <div className='col-md-4'>
          <TextInput label='Min DUOLINGO Score' name='min_duolingo_score' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min PTE Listening' name='min_pte_listening' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min PTE Reading' name='min_pte_reading' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min PTE Speaking' name='min_pte_speaking' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min PTE Writing' name='min_pte_writing' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Min PTE Overall' name='min_pte_overall' />
        </div>
        <div className='col-md-4'>
          <SelectBox label='Level' name='level'>
            <option value=''></option>
            {dropdown_options &&
              dropdown_options.education_levels &&
              dropdown_options.education_levels.map((item) => {
                return <option value={item.name}>{item.name} </option>;
              })}
          </SelectBox>
        </div>

        {/* <div className='col-md-4'>
          <TextInput label='Level Text' name='level_text' />
        </div> */}
        <div className='col-md-4'>
          <TextInput label='Other Requirements' name='other_requirements' />
        </div>
        <div className='col-md-12'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              checked={
                formik.values.gre_requirements &&
                formik.values.gre_requirements.required === 'YES'
              }
              onChange={(e) => {
                if (e.target.checked) {
                  formik.setFieldValue('gre_requirements.required', 'YES');
                } else {
                  formik.setFieldValue('gre_requirements.required', 'NO');
                }
              }}
            />
            <label className='form-check-label' htmlFor='gmat_score'>
              GRE Requirements
            </label>
          </div>
          {formik.values.gre_requirements &&
            formik.values.gre_requirements.required === 'YES' && (
              <div className='give-exam'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='main-label'>Max GPA Required</div>

                    <TextInput
                      label='Max GPA Required'
                      name='gre_requirements.max_gpa_to_be_required'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>Min Verbal</div>

                    <TextInput
                      label='Min Verbal'
                      name='gre_requirements.min_verbal'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>Min Quantitative</div>

                    <TextInput
                      label='Min Quantitative'
                      name='gre_requirements.min_quantitative'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>Min Writing</div>

                    <TextInput
                      label='Min Writing'
                      name='gre_requirements.min_writing'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>Min Verbal Percentile</div>

                    <TextInput
                      label='Min Verbal Percentile'
                      name='gre_requirements.min_verbal_percentile'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>
                      Min Quantitative Percentile
                    </div>

                    <TextInput
                      label='Min Quantitative Percentile'
                      name='gre_requirements.min_quantitative_percentile'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>Min Writing Percentile</div>

                    <TextInput
                      label='Min Writing Percentile'
                      name='gre_requirements.min_writing_percentile'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>
                      Min Verbal Quantitative Percentile
                    </div>

                    <TextInput
                      label='Min Verbal Quantitative Percentile'
                      name='gre_requirements.min_verbal_quantitative_combined'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>GMAT Min Verbal</div>

                    <TextInput
                      label='GMAT Min Verbal'
                      name='gre_requirements.gmat_min_verbal'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>GMAT Min Quantitative</div>

                    <TextInput
                      label='GMAT Min Quantitative'
                      name='gre_requirements.gmat_min_quantitative'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>GMAT Min Writing</div>

                    <TextInput
                      label='GMAT Min Writing'
                      name='gre_requirements.gmat_min_writing'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>GMAT Min Verbal Percentile</div>

                    <TextInput
                      label='GMAT Min Verbal Percentile'
                      name='gre_requirements.gmat_min_verbal_percentile'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>
                      GMAT Min Quantitative Percentile
                    </div>

                    <TextInput
                      label='GMAT Min Quantitative Percentile'
                      name='gre_requirements.gmat_min_quantitative_percentile'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>
                      GMAT Min Writing Percentile
                    </div>

                    <TextInput
                      label='GMAT Min Writing Percentile'
                      name='gre_requirements.gmat_min_writing_percentile'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>GMAT Min Total</div>

                    <TextInput
                      label='GMAT Min Total'
                      name='gre_requirements.gmat_min_total'
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='main-label'>GMAT Min total Percentile</div>

                    <TextInput
                      label='GMAT Min total Percentile'
                      name='gre_requirements.gmat_min_total_percentile'
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className='create-button'>
        <button type='submit' className='btn primary-btn'>
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default AddRequirementForm;
