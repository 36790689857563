import {
  ADD_ENGLISH_TESTSCORE_STARTED,
  ADD_ENGLISH_TESTSCORE_SUCCESS,
  ADD_ENGLISH_TESTSCORE_ENDED,
  ADD_ENGLISH_TESTSCORE_ERROR,
} from '../types/english_testscore_types';

const initialState = {
  add_english_testscore_loading: false,
  add_english_testscore_errors: null,
  add_english_testscore_success: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ENGLISH_TESTSCORE_STARTED:
      return {
        ...state,
        add_english_testscore_loading: true,
        add_english_testscore_errors: null,
        add_english_testscore_success: null,
      };
    case ADD_ENGLISH_TESTSCORE_ENDED:
      return {
        ...state,
        add_english_testscore_loading: false,
      };
    case ADD_ENGLISH_TESTSCORE_ERROR:
      return {
        ...state,
        add_english_testscore_errors: payload,
      };
    case ADD_ENGLISH_TESTSCORE_SUCCESS:
      return {
        ...state,
        add_english_testscore_success: payload,
      };

    default:
      return state;
  }
}
