import React from 'react';

function PermissionTableRow({ name, permission, label }) {
  return (
    <tr>
      <th scope='row'>{label}</th>
      <td
        className={
          permission && permission[name] && permission[name]['create']
            ? 'success'
            : 'close'
        }
      >
        <i
          className={
            permission && permission[name] && permission[name]['create']
              ? 'fa fa-check-circle'
              : 'fa fa-times-circle'
          }
        ></i>
      </td>
      <td
        className={
          permission && permission[name] && permission[name]['get_all']
            ? 'success'
            : 'close'
        }
      >
        <i
          className={
            permission && permission[name] && permission[name]['get_all']
              ? 'fa fa-check-circle'
              : 'fa fa-times-circle'
          }
        ></i>
      </td>
      <td
        className={
          permission && permission[name] && permission[name]['view']
            ? 'success'
            : 'close'
        }
      >
        <i
          className={
            permission && permission[name] && permission[name]['view']
              ? 'fa fa-check-circle'
              : 'fa fa-times-circle'
          }
        ></i>
      </td>
      <td
        className={
          permission && permission[name] && permission[name]['delete']
            ? 'success'
            : 'close'
        }
      >
        <i
          className={
            permission && permission[name] && permission[name]['delete']
              ? 'fa fa-check-circle'
              : 'fa fa-times-circle'
          }
        ></i>
      </td>
      <td
        className={
          permission && permission[name] && permission[name]['update']
            ? 'success'
            : 'close'
        }
      >
        <i
          className={
            permission && permission[name] && permission[name]['update']
              ? 'fa fa-check-circle'
              : 'fa fa-times-circle'
          }
        ></i>
      </td>
    </tr>
  );
}

export default PermissionTableRow;
