import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { useNavigate } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import ViewCounsellorComponent from '../../components/counsellors/ViewCounsellorComponent';
import {
  useSingleCounsellor,
  useChangePasswordCounsellor,
  useAssignAgent,
} from '../../shared/hooks/UseCounsellor';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import UpdatePasswordComponent from '../../components/common/UpdatePasswordComponent';
import { useAllAgentsFilters } from '../../shared/hooks/UseAgent';

function ViewCounsellor() {
  const params = useParams();
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const { data, setAgentSearchTerm } = useAllAgentsFilters();
  const { agents } = data;
  const { assignAgentToCounsellor } = useAssignAgent();
  const { counsellor_data } = useSingleCounsellor(params.id);
  const { counsellor, counsellor_loading } = counsellor_data;
  const [assignAgentModalOpen, setAssignAgentModalOpen] = useState(false);

  const { updateCounsellorPasswordData } = useChangePasswordCounsellor();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '450px',
    },
  };
  useEffect(() => {
    if (agents) {
      const mappedAgent = agents.map((item) => {
        return {
          label:
            item.agentData &&
            item.agentData.agentInfo &&
            item.agentData.agentInfo.company_name
              ? item.agentData.agentInfo.company_name
              : `${item.first_name} ${item.last_name}`,
          value: item._id,
        };
      });
      setDropdownOptions({ agent: mappedAgent });
    }
  }, [agents]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'agent') {
      setAgentSearchTerm(inputValue);
      callback(dropdownOptions.agent);
    }
  };
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={counsellor && counsellor.first_name} />
      <PermissionComponent name={'agents'} permission={'view'}>
        {counsellor && (
          <section className='profile-main ptb-20'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='main-sec-heading'>
                    <h1>{counsellor.first_name}</h1>
                  </div>
                </div>
                <div className='col-md-6 col-6'>
                  <div className='d-flex '>
                    <div className='add-student-btn'>
                      <button
                        onClick={() => setAssignAgentModalOpen(true)}
                        className='btn primary-btn-h '
                      >
                        Assign Agent
                      </button>
                    </div>
                    <div className='add-student-btn'>
                      <Link
                        to={`/settings/counsellors/${counsellor.id}/edit`}
                        className='btn primary-btn-h '
                      >
                        Edit Counsellor
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {counsellor && (
          <section className='main-school-single-view ptb-20 '>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <ViewCounsellorComponent counsellor={counsellor} />
                </div>
                <div className='col-md-4'>
                  <UpdatePasswordComponent
                    navigationLink={'/settings/crm-users/'}
                    onSubmitHandler={(values) =>
                      updateCounsellorPasswordData(counsellor._id, values)
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </PermissionComponent>
      <AuthFooter />
      <Modal
        style={customStyles}
        isOpen={assignAgentModalOpen}
        onRequestClose={() => setAssignAgentModalOpen(false)}
        contentLabel='Assign Agent'
      >
        <h2>Assign Agent</h2>
        <Formik
          initialValues={{
            agent: '',
          }}
          validationSchema={Yup.object({
            agent: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            assignAgentToCounsellor(counsellor?._id, values);
            resetForm();
            setSubmitting(false);
            setAssignAgentModalOpen(false);
            navigate(`/settings/counsellors`);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='User'> Select Agent </label>
                    <select
                      name='agent'
                      onChange={formik.handleChange}
                      value={formik.values.agent}
                      className='form-control'
                      id='User'
                    >
                      <option value=''></option>
                      {dropdownOptions &&
                        dropdownOptions.agent.map((item) => {
                          return (
                            <option value={item.value}> {item.label} </option>
                          );
                        })}
                    </select>
                    {formik.errors && formik.errors.agent && (
                      <p className='text-danger'> {formik.errors.agent} </p>
                    )}
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-success btn-sm'
                      onClick={formik.handleSubmit}
                      type='submit'
                    >
                      <i className='fa fa-save'></i>
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <hr />
        <div className='d-flex justify-content-end'>
          <a onClick={() => setAssignAgentModalOpen(false)}>X</a>
        </div>
      </Modal>
    </div>
  );
}

export default ViewCounsellor;
