import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotifications,
  editNotification,
  getAllNotifications,
  editNotificationBulk,
  toggleNotification,
  getNotificationsAll,
} from '../../store/actions/notification_action';
import _debounce from 'lodash/debounce';
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useToggleNotification = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notification);
  const toggleNotificationData = (data) => {
    dispatch(toggleNotification(data));
  };
  return { toggleNotificationData, show_notification: data.show_notification };
};

export const useAllNotifications = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notification);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(
      getNotifications({
        pageNumber,
      })
    );
  }, []);

  const reloadNotifications = () => {
    dispatch(
      getNotifications({
        pageNumber,
      })
    );
  };

  return { notification_data: data, reloadNotifications };
};

export const useAllTotalNotifications = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notification);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(
      getNotificationsAll({
        pageNumber,
      })
    );
  }, []);

  const reloadNotificationsAll = () => {
    dispatch(
      getNotificationsAll({
        pageNumber,
      })
    );
  };

  return { all_notification_data: data, reloadNotificationsAll };
};

export const useSelectAllNotification = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.notification);
  useEffect(() => {
    dispatch(getAllNotifications({}));
  }, []);
  const reloadData = () => {
    dispatch(getAllNotifications({}));
  };
  return [data, reloadData];
};

export const useUpdateNotification = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.notification);
  const updateData = async (id, data) => {
    await dispatch(editNotification(id, data));
  };
  return [updateData];
};
export const useUpdateNotificationBulk = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.notification);
  const updateNotificationBulk = async (id, data) => {
    await dispatch(editNotificationBulk(id, data));
  };
  return { updateNotificationBulk };
};
