import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Pagination from '../common/Pagination';
import { Link } from 'react-router-dom';
import ViewButton from '../school/ViewButton';
import { useNavigate } from 'react-router-dom';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
function EducationLevelComponent({
  education_levels_loading,
  education_levels,
  education_level_page,
  education_level_pages,
  total_education_levels,
  setPageNumber,
}) {
  const navigate = useNavigate();
  const [columnDefs] = useState([
    { field: 'family' },
    { field: 'name' },
    { field: 'rank' },
    { field: 'type' },
    {
      field: 'Actions',
      pinned: 'right',
      cellRenderer: ViewButtonByID,
      cellRendererParams: {
        clicked: function (field) {
          navigate(`/settings/education-levels/${field}/view`);
        },
        edit_clicked: function (field) {
          navigate(`/settings/education-levels/${field}/edit`);
        },
      },
    },
  ]);
  return (
    <PermissionComponent name={'education_level'} permission={'get_all'}>
      <div>
        <p> {total_education_levels} Records Found </p>
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <th scope='col '>Family</th>

                <th scope='col'>Name</th>
                <th scope='col'>Rank</th>
                <th scope='col'>Type</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {education_levels &&
                education_levels.map((education_levels) => {
                  return (
                    <tr key={education_levels._id}>
                      <td>{education_levels.family}</td>

                      <td> {education_levels.name} </td>
                      <td>{education_levels.rank}</td>
                      <td>{education_levels.type}</td>

                      <td className='status-ic'>
                        <Link
                          to={`/settings/education-levels/${education_levels._id}/view`}
                          className='badge badge-view'
                        >
                          View
                        </Link>
                        <Link
                          to={`/settings/education-levels/${education_levels._id}/edit`}
                          className='badge badge-draft'
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {education_levels && education_levels.length == 0 && (
                <tr>
                  <td colSpan={10}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div
          className="ag-theme-alpine pb-50"
          style={{ width: '100%', height: 500, margin: '0 auto' }}
        >
          <AgGridReact
            rowData={education_levels}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={education_levels}
          count={total_education_levels}
          pages={education_level_pages}
          loading={education_levels_loading}
        />
      </div>
    </PermissionComponent>
  );
}

export default EducationLevelComponent;
