export const GET_CRM_USERS_STARTED = 'GET_CRM_USERS_STARTED';
export const GET_CRM_USERS = 'GET_CRM_USERS';
export const GET_CRM_USERS_ENDED = 'GET_CRM_USERS_ENDED';
export const GET_CRM_USERS_ERROR = 'GET_CRM_USERS_ERROR';
export const ADD_CRM_USER_STARTED = 'ADD_CRM_USER_STARTED';
export const ADD_CRM_USER = 'ADD_CRM_USER';
export const ADD_CRM_USER_ENDED = 'ADD_CRM_USER_ENDED';
export const ADD_CRM_USER_ERROR = 'ADD_CRM_USER_ERROR';
export const EDIT_CRM_USER_STARTED = 'EDIT_CRM_USER_STARTED';
export const EDIT_CRM_USER = 'EDIT_CRM_USER';
export const EDIT_CRM_USER_ENDED = 'EDIT_CRM_USER_ENDED';
export const EDIT_CRM_USER_ERROR = 'EDIT_CRM_USER_ERROR';
export const GET_CRM_USER = 'GET_CRM_USER';
export const GET_CRM_USER_STARTED = 'GET_CRM_USER_STARTED';
export const GET_CRM_USER_ENDED = 'GET_CRM_USER_ENDED';
export const GET_CRM_USER_ERROR = 'GET_CRM_USER_ERROR';
