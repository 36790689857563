import React, { useState } from 'react';
import { DummyUser, LogoTemplate } from '../images';
import moment from 'moment';
import { SchoolAttended } from '../education_history/SchoolAttended';
import { EducationSummary } from '../education_history/EducationSummary';
import { PersonalInformation } from '../general_information/PersonalInforamtion';
import { AddressDetails } from '../general_information/AddressDetails';
import { EnglishTestScore } from '../test_score/EnglishTestScore';
import { GreGmatScore } from '../test_score/GreGmatScore';
import { VisaAndStudyPermitP } from '../visa_and_study_permit/VisaAndStudyPermitP';
import { URI } from '../../domain/constant';
function SingleApplicationStudentInformationComponent({
  student,
  application,
  agent,
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className='box-show-and-hide'>
        <div
          className='main-heading-container'
          onClick={() => {
            setShow(!show);
          }}
        >
          <div className='school-details-box-flex'>
            <div>
              <h3>Student Information</h3>
            </div>
            <div className=''>
              <i
                className={show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
              ></i>
            </div>
          </div>
        </div>
        <div
          className='application-full-requirement'
          style={{ display: show ? 'block' : 'none' }}
        >
          <div className='main-application-content-flex'>
            <div className='dummy-image for-student'>
              {student && student.student && student.student.profile_pic ? (
                <img src={`${URI}${student.student.profile_pic}`} />
              ) : (
                <img src={DummyUser} />
              )}
            </div>
            <div class='similar-card-content'>
              <h5>
                {student && student.student.first_name}
                {student && student.student.middle_name}{' '}
                {student && student.student.last_name}
              </h5>
              <div className='badge-id-pay'>
                <span className='badge primary mr-2'>
                  ID: {student && student.student.user_id}
                </span>
                <span className='badge pending mr-2'>
                  Payment:{' '}
                  {application.is_application_fee_paid ? 'PAID' : 'PENDING'}
                </span>

                <span className=''>
                  {application.program &&
                    application.program.school &&
                    application.program.school.market &&
                    application.program.school.market.currency}
                  {application.application_fee}{' '}
                </span>
              </div>
              {/* <div class='location-map'>
                <i class='fa fa-map-marker'></i>
                <span>
                  {student &&
                    student.userAddress &&
                    `${student.userAddress.address} ${student.userAddress.city} ${student.userAddress.state} ${student.userAddress.country}  ${student.userAddress.country} ${student.userAddress.zipcode} `}
                </span>
              </div>
              <div class="similar-card-deadline-flex">
                <div>
                  <h5>Nationality</h5>
                  <h6>{student && student.student.nationality}</h6>
                </div>

                {student && student.userEducationInfo && (
                  <>
                    <div>
                      <h5>Education: </h5>
                      <span class="badge inclass">
                        {' '}
                        {student.userEducationInfo.highest_level_of_education &&
                          student.userEducationInfo.highest_level_of_education
                            .name}{' '}
                      </span>
                    </div>
                    <div>
                      <h5>Country of Education : </h5>
                      <h6> {student.userEducationInfo.country_of_education}</h6>
                    </div>
                    <div>
                      <h5>Grade : </h5>
                      <h6>{student.userEducationInfo.grade_average}</h6>
                    </div>
                  </>
                )}
              </div> */}
              <div className='col-md-12'>
                <PersonalInformation
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>
              <div className='col-md-12'>
                <AddressDetails
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>
              <div className='col-md-12'>
                <EducationSummary
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>

              <div className='col-md-12'>
                <SchoolAttended
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>

              <div className='col-md-12'>
                <EnglishTestScore
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>
              <div className='col-md-12'>
                <GreGmatScore
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>
              <div className='col-md-12'>
                <VisaAndStudyPermitP
                  student_info={student}
                  student_loading={false}
                  hideEdit
                />
              </div>

              {/* {student &&
                student.userSchoolAttended &&
                student.userSchoolAttended.map((item) => {
                  return (
                    <div class="similar-card-deadline-flex">
                      <div>
                        <h5>School Name  </h5>
                        <h6>{item.name_of_institution}</h6>
                      </div>

                      <div>
                        <h5>Attended From  </h5>
                        <h6>
                          {item.attended_institution_from &&
                            moment(item.attended_institution_from).format(
                              'MMM-DD, YYYY'
                            )}
                        </h6>
                      </div>
                      <div>
                        <h5>Attended To  </h5>
                        <h6>
                          {item.attended_institution_to &&
                            moment(item.attended_institution_to).format(
                              'MMM-DD, YYYY'
                            )}
                        </h6>
                      </div>
                      <div>
                        <h5>Address  </h5>
                        <h6>
                          {item.address} {item.city} {item.state} {item.country}{' '}
                          {item.zipcode}
                        </h6>
                      </div>
                      <div>
                        <h5>Level  </h5>
                        <h6> {item.degree_name} </h6>
                      </div>
                      <div>
                        <h5>Degree Name  </h5>
                        <h6>{item.degree_name}</h6>
                      </div>
                      <div>
                        <h5>Graduated?  </h5>
                        <h6>
                          {item.graduated_from_institution ? 'YES' : 'NO'}
                        </h6>
                      </div>

                      <div>
                        <h5>Graduation Date  </h5>
                        <h6>
                          {item.graduation_date &&
                            moment(item.graduation_date).format('MMM DD,YYYY')}
                        </h6>
                      </div>
                      <div>
                        <h5>Language  </h5>
                        <h6>{item.primary_language_of_institution}</h6>
                      </div>
                    </div>
                  );
                })} */}
              <hr />
              <div class='similar-card-deadline-flex'>
                <div>
                  <h5>Student Representative:  </h5>
                  <h6>
                    {' '}
                    {application.crm_user
                      ? application.crm_user.first_name &&
                        `${application.crm_user.first_name} ${application.crm_user.last_name}`
                      : '-'}{' '}
                  </h6>
                </div>

                {agent && agent.agentInfo && (
                  <div>
                    <h5>Recruitment Partner:  </h5>
                    <h6> {agent.agentInfo.company_name} </h6>
                  </div>
                )}
                {application && application.counsellor && (
                  <div>
                    <h5>Counsellor  </h5>
                    <h6>
                      {' '}
                      {application.counsellor.first_name}{' '}
                      {application.counsellor.last_name}{' '}
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleApplicationStudentInformationComponent;
