import React, { useState, useEffect } from 'react';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useAllSchoolsFilters } from '../../shared/hooks/UseSchool';
import { FileUpload } from '../../components/Form/Form';
import {
  UseAllBulkUpload,
  UseBulkUpload,
} from '../../shared/hooks/UseBulkUpload';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

export const AddBulkPrograms = () => {
  const { schools } = useAllSchoolsFilters();

  const [dropdownOption, setdropdownOption] = useState(null);
  const { handleSubmitForm } = UseBulkUpload();
  const { handleAllSubmitForm } = UseAllBulkUpload();
  const [results, setResults] = useState(null);
  const [allResults, setAllResults] = useState(null);

  useEffect(() => {
    if (schools) {
      const mappedData = schools.map((school) => {
        return {
          label: `${school.name} (${school.city})`,
          value: school.id,
        };
      });
      setdropdownOption({ ...dropdownOption, school: mappedData });
    }
  }, [schools]);
  console.log('results', results, allResults);

  return (
    <div className='wrapper'>
      <div>Bulk Upload</div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Bulk Add Program'} />
      <div className='container m-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-body'>
                <Formik
                  initialValues={{
                    file: '',
                    completed: false,
                    school: '',
                  }}
                  validationSchema={Yup.object({
                    file: Yup.string().required('Required'),
                    school: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const data = await handleSubmitForm(values);
                    setResults(data);
                    // navigate('/programs');
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className='row'>
                          <div className='col-md-6'>
                            <FileUpload
                              title={'CSV File'}
                              item='file'
                              formik={formik}
                              value={formik.values.file}
                              hideView={true}
                            />
                          </div>
                          <div className='col-md-6'>
                            <label> School </label>
                            <Select
                              value={
                                dropdownOption &&
                                dropdownOption.school &&
                                dropdownOption.school.filter(
                                  (option) =>
                                    option.value === formik.values.school
                                )
                              }
                              // defaultValue={school}
                              isClearable={false}
                              options={
                                dropdownOption && dropdownOption['school']
                              }
                              onChange={(e) => {
                                if (e) {
                                  formik.setFieldValue('school', e.value);
                                }
                              }}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 999,
                                }),
                              }}
                            />
                          </div>
                          <div className='col-md-12'>
                            <button type='submit' className='btn btn-success'>
                              {' '}
                              Save{' '}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          {results && results.failed_data && results.failed_data.length > 0 && (
            <div className='col-md-12 mt-5'>
              <div className='card'>
                <div className='card-header'> Errors </div>
                <div className='card-body'>
                  {results.failed_data.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className='row'>
                          <div className='col-md-4'>
                            <strong> Program Name:</strong>{' '}
                            {data?.item?.program_name}
                          </div>
                          <div className='col-md-8'>
                            <div className='d-flex ' style={{ gap: '4px' }}>
                              {data?.errors?.map((error) => {
                                return (
                                  <div
                                    className='bg-danger mb-4 px-2 text-white'
                                    key={error}
                                  >
                                    {' '}
                                    {error}{' '}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {results &&
            results.failed_data &&
            results.failed_data.length === 0 && (
              <div className='col-md-12 mt-5'>
                <div className='card'>
                  <div className='card-header'>
                    <h3 className='text-success'>Data Successfully Imported</h3>
                  </div>
                </div>
              </div>
            )}

          <div className='col-md-12 mt-5'>
            <div className='card'>
              <div className='card-body'>
                <h3> Bulk Upload all data </h3>
                <hr />
                <Formik
                  initialValues={{
                    file: '',
                    completed: false,
                  }}
                  validationSchema={Yup.object({
                    file: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const data = await handleAllSubmitForm(values);
                    setAllResults(data);
                    // navigate('/programs');
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className='row'>
                          <div className='col-md-6'>
                            <FileUpload
                              title={'CSV File'}
                              item='file'
                              formik={formik}
                              value={formik.values.file}
                              hideView={true}
                            />
                          </div>
                          <div className='col-md-12'>
                            <button type='submit' className='btn btn-success'>
                              {' '}
                              Save{' '}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          {allResults &&
            allResults.failed_data &&
            allResults.failed_data.length > 0 && (
              <div className='col-md-12 mt-5'>
                <div className='card'>
                  <div className='card-header'> Errors </div>
                  <div className='card-body'>
                    {allResults.failed_data.map((data, index) => {
                      return (
                        <div key={index}>
                          <div className='row'>
                            <div className='col-md-4'>
                              <strong> Program Name:</strong>{' '}
                              {data?.item?.program_name}
                            </div>
                            <div className='col-md-8'>
                              <div className='d-flex ' style={{ gap: '4px' }}>
                                {data?.errors?.map((error) => {
                                  return (
                                    <div
                                      className='bg-danger mb-4 px-2 text-white'
                                      key={error}
                                    >
                                      {' '}
                                      {error}{' '}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          {allResults &&
            allResults.failed_data &&
            allResults.failed_data.length === 0 && (
              <div className='col-md-12 mt-5'>
                <div className='card'>
                  <div className='card-header'>
                    <h3 className='text-success'>Data Successfully Imported</h3>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      <AuthFooter />
    </div>
  );
};
