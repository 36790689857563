import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import EducationLevelForm from './EducationLevelForm';
import { useCreateEducationLevel } from '../../shared/hooks/UseEducationLevel';

function AddEducationLevelComponent() {
  const { addEducationLevelData } = useCreateEducationLevel();
  const navigate = useNavigate();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Education Level</h1>
        </div>

        <Formik
          initialValues={{
            family: '',
            name: '',
            rank: '',
            type: '',
          }}
          validationSchema={Yup.object({
            family: Yup.string().required('Required'),

            name: Yup.string().required('Required'),
            rank: Yup.string().required('Required'),
            type: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addEducationLevelData(values);
            navigate('/settings/education-levels');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <EducationLevelForm />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddEducationLevelComponent;
