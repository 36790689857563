import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addCountry,
  getCountries,
  getCountry,
  editCountry,
  deleteCountry,
} from '../../store/actions/country_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
// Add Data
export const useCreateCountry = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.country);
  const addCountryData = async (data) => {
    dispatch(addCountry(data));
  };
  return { data, addCountryData };
};

// Get Single Data
export const useSingleCountry = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.country);
  useEffect(() => {
    dispatch(getCountry(id));
  }, [id]);
  return { data };
};

//Edit Data
export const useUpdateCountry = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Country);
  const updateCountryData = async (id, data) => {
    dispatch(editCountry(id, data));
  };
  return { updateCountryData };
};

// Get All Data
export const useAllCountries = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.country);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getCountries({}));
    }, 1000),
    []
  );

  return { country_data: data, setPageNumber };
};

//Delete Data
export const useDeleteCountry = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Country);
  const deleteCountryFunction = async (id) => {
    dispatch(deleteCountry(id));
  };
  return { deleteCountryFunction };
};
