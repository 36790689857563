export const SETTING_MENU = [
  {
    name: 'Education Levels',
    slug: '/education-levels',
  },
  {
    name: 'Agents',
    slug: '/agents',
  },
  {
    name: 'CRM USers',
    slug: '/crm-users',
  },
  {
    name: 'Academic Partners',
    slug: '/academic-partners',
  },
  {
    name: 'Role And Permissions',
    slug: '/roles-and-permissions',
  },
  {
    name: 'Country',
    slug: '/countries',
  },
  {
    name: 'Discipline',
    slug: '/disciplines',
  },
  {
    name: 'Grading Schemes',
    slug: '/grading-schemes',
  },
  {
    name: 'Counsellors',
    slug: '/counsellors',
  },
  {
    name: 'Template',
    slug: '/templates',
  },
  {
    name: 'Commission Tiers',
    slug: '/slabs',
  },
];
export const SETTING_MENU_ENUM = {
  EDUCATION_LEVEL: 'Education Levels',
  AGENTS: 'Agents',
  CRM_USERS: 'CRM USers',
  ACADEMIC_PARTNERS: 'Academic Partners',
  ROLES_AND_PERMISSIONS: 'Role And Permissions',
  COUNTRY: 'countries',
  DISCIPLINE: 'disciplines',
  GRADING: 'grading-schemes',
  Counsellors: 'counsellors',
  Templates: 'templates',
  COMMISSION_TIERS: 'Commission Tiers',
};
