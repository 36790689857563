import {
  GET_STUDENTS_STARTED,
  GET_STUDENTS,
  GET_STUDENTS_ENDED,
  ADD_STUDENT_STARTED,
  ADD_STUDENT,
  ADD_STUDENT_ENDED,
  EDIT_STUDENT_STARTED,
  EDIT_STUDENT,
  EDIT_STUDENT_ENDED,
  GET_STUDENT,
  GET_STUDENT_STARTED,
  GET_STUDENT_ENDED,
} from '../types/student_types';

const initialState = {
  students_loading: true,
  students: null,
  student_page: null,
  students_pages: null,
  total_students: 0,

  student: null,
  student_loading: null,

  add_student_loading: true,
  edit_student_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STUDENTS_STARTED:
      return {
        ...state,
        students: null,
        student_pages: null,
        student_page: null,
        total_students: 0,
        students_loading: true,
      };
    case GET_STUDENTS:
      return {
        ...state,
        students: payload.students,
        student_pages: payload.pages,
        student_page: payload.page,
        total_students: payload.total_students,
      };
    case GET_STUDENTS_ENDED:
      return {
        ...state,
        students_loading: false,
      };

    case ADD_STUDENT_STARTED:
      return {
        ...state,
        student_message: null,
        add_student_loading: true,
      };
    case ADD_STUDENT:
      return {
        ...state,
        student_message: payload,
      };
    case ADD_STUDENT_ENDED:
      return {
        ...state,
        add_student_loading: false,
      };
    case GET_STUDENT_STARTED:
      return {
        ...state,
        student: null,
        student_loading: true,
      };
    case GET_STUDENT:
      return {
        ...state,
        student: payload,
      };
    case GET_STUDENT_ENDED:
      return {
        ...state,
        student_loading: false,
      };
    case EDIT_STUDENT_STARTED:
      return {
        ...state,
        student_message: null,
        edit_student_loading: true,
      };
    case EDIT_STUDENT:
      return {
        ...state,
        student_message: payload,
      };
    case EDIT_STUDENT_ENDED:
      return {
        ...state,
        edit_student_loading: false,
      };

    default:
      return state;
  }
}
