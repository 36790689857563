import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
function OutstandingTask() {
  const [rowData] = useState([
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxster', price: 72000 },
  ]);
  const [columnDefs] = useState([
    { field: 'Requirements' },
    { field: ' Students' },
    { field: 'Staff Assigned' },
    { field: 'Deadline' },
  ]);
  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowSelection="multiple"
        animateRows={true}
      ></AgGridReact>
    </div>
  );
}

export default OutstandingTask;
