import { useDispatch } from 'react-redux';
import { addVisaAndServicePermitInfo } from '../../store/actions/visa_and_study_action';

export const UseVisaStudyPermit = () => {
  const dispatch = useDispatch();

  const updateVisaInfo = async (id, values) => {
    dispatch(addVisaAndServicePermitInfo(id, values));
  };

  return {
    updateVisaInfo,
  };
};
