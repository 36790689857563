import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
function DisciplinesComponent({
  disciplines_loading,
  disciplines,
  discipline_page,
  discipline_pages,
  total_disciplines,
  setPageNumber,
}) {
  const navigate = useNavigate();

  return (
    <PermissionComponent name={'country'} permission={'get_all'}>
      <div>
        <p> {total_disciplines}Records Found </p>
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <th scope='col '>Name</th>
                <th scope='col '>Parent</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {disciplines &&
                disciplines.map((discipline) => {
                  return (
                    <tr key={discipline._id}>
                      <td>{discipline.name}</td>
                      <td>{discipline.parent ? discipline.parent.name : ''}</td>

                      <td className='status-ic'>
                        <Link
                          to={`/settings/disciplines/${discipline._id}/view`}
                          className='badge badge-view'
                        >
                          View
                        </Link>
                        <Link
                          to={`/settings/disciplines/${discipline._id}/edit`}
                          className='badge badge-edit'
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {disciplines && disciplines.length == 0 && (
                <tr>
                  <td colSpan={10}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={disciplines}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={disciplines}
          count={total_disciplines}
          pages={discipline_pages}
          loading={disciplines_loading}
        />
      </div>
    </PermissionComponent>
  );
}

export default DisciplinesComponent;
