import {
  GET_REQUIREMENTS_STARTED,
  GET_REQUIREMENTS,
  GET_REQUIREMENTS_ENDED,
  ADD_REQUIREMENT_STARTED,
  ADD_REQUIREMENT,
  ADD_REQUIREMENT_ENDED,
  EDIT_REQUIREMENT_STARTED,
  EDIT_REQUIREMENT,
  EDIT_REQUIREMENT_ENDED,
  GET_REQUIREMENT,
  GET_REQUIREMENT_STARTED,
  GET_REQUIREMENT_ENDED,
} from '../types/requirement_types';

const initialState = {
  requirements_loading: true,
  requirements: null,
  requirement_page: null,
  requirement_pages: null,
  total_requirements: 0,

  requirement: null,
  requirement_loading: null,

  add_requirement_loading: true,
  edit_requirement_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REQUIREMENTS_STARTED:
      return {
        ...state,
        requirements: null,
        requirement_pages: null,
        requirement_page: null,
        total_requirements: 0,
        requirements_loading: true,
      };
    case GET_REQUIREMENTS:
      return {
        ...state,
        requirements: payload.requirements,
        requirement_pages: payload.pages,
        requirement_page: payload.page,
        total_requirements: payload.total_requirements,
      };
    case GET_REQUIREMENTS_ENDED:
      return {
        ...state,
        requirements_loading: false,
      };

    case ADD_REQUIREMENT_STARTED:
      return {
        ...state,
        requirement_message: null,
        add_requirement_loading: true,
      };
    case ADD_REQUIREMENT:
      return {
        ...state,
        requirement_message: payload,
      };
    case ADD_REQUIREMENT_ENDED:
      return {
        ...state,
        add_requirement_loading: false,
      };
    case GET_REQUIREMENT_STARTED:
      return {
        ...state,
        requirement: null,
        requirement_loading: true,
      };
    case GET_REQUIREMENT:
      return {
        ...state,
        requirement: payload,
      };
    case GET_REQUIREMENT_ENDED:
      return {
        ...state,
        requirement_loading: false,
      };
    case EDIT_REQUIREMENT_STARTED:
      return {
        ...state,
        requirement_message: null,
        edit_requirement_loading: true,
      };
    case EDIT_REQUIREMENT:
      return {
        ...state,
        requirement_message: payload,
      };
    case EDIT_REQUIREMENT_ENDED:
      return {
        ...state,
        edit_requirement_loading: false,
      };

    default:
      return state;
  }
}
