import {
  ADD_PERSONAL_INFORMATION_STARTED,
  ADD_PERSONAL_INFORMATION_SUCCESS,
  ADD_PERSONAL_INFORMATION_ENDED,
  ADD_PERSONAL_INFORMATION_ERROR,
} from '../types/personal_info_types';
import { api } from '../../domain/api';
import {
  GET_STUDENT,
  GET_STUDENT_ENDED,
  GET_STUDENT_STARTED,
} from '../types/student_types';
import { setAlert } from './alert_action';
// Register User
export const updatePersonalInfo = (student_id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PERSONAL_INFORMATION_STARTED,
    });
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    const res = await api.post(`/personal-information/${student_id}`, data);

    dispatch({
      type: ADD_PERSONAL_INFORMATION_SUCCESS,
      payload: 'General Information Updated',
    });
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
    dispatch(setAlert('Personal Information Updated', 'success'));
    dispatch({
      type: ADD_PERSONAL_INFORMATION_ENDED,
    });
  } catch (err) {
    dispatch({
      type: ADD_PERSONAL_INFORMATION_ENDED,
    });
    dispatch({
      type: ADD_PERSONAL_INFORMATION_ERROR,
      payload: err,
    });
  }
};
