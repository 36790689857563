import {
  ADD_GRE_STARTED,
  ADD_GRE_SUCCESS,
  ADD_GRE_ENDED,
  ADD_GRE_ERROR,
} from '../types/gre_types';
import { api } from '../../domain/api';
import {
  GET_STUDENT_STARTED,
  GET_STUDENT,
  GET_STUDENT_ENDED,
} from '../types/student_types';
import { setAlert } from './alert_action';

// Register User
export const updateGreGmatInfo = (student_id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_GRE_STARTED,
    });
    dispatch({
      type: GET_STUDENT_STARTED,
    });
    const res = await api.post(`/gre-gmat-scores/${student_id}`, data);

    dispatch({
      type: ADD_GRE_SUCCESS,
      payload: 'Gre Information Updated',
    });
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
    dispatch({
      type: ADD_GRE_ENDED,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
    dispatch(setAlert('GRE GMAT Info Updated', 'success'));
  } catch (err) {
    dispatch({
      type: ADD_GRE_ENDED,
    });
    dispatch({
      type: GET_STUDENT_ENDED,
    });
    dispatch({
      type: ADD_GRE_ERROR,
      payload: err,
    });
  }
};
