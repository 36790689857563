import { useDispatch } from 'react-redux';

import { updateEnglishTestScoreInfo } from '../../store/actions/english_testscore_action';

export const useUpdateEnglishTestInfo = () => {
  const dispatch = useDispatch();

  const updateEnglishTestInformation = async (id, values) => {
    dispatch(updateEnglishTestScoreInfo(id, values));
  };

  return {
    updateEnglishTestInformation,
  };
};
