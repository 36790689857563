import React from 'react';
import { URI } from '../../domain/constant';

function AgentBankInfo({ agent }) {
  return (
    <>
      {agent && (
        <div className='row'>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Account No</h2>
              <span> {agent.account_no}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Bank Account Holder</h2>
              <span>{agent.bank_holder_name}</span>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>IFSC Code</h2>
              <span>{agent.ifsc_code}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Bank Name</h2>
              <span>{agent.bank_name}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Swift Code</h2>
              <span>{agent.swift_code}</span>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='heading-single-education'>
              <h2>Bank Address</h2>
              {agent.bank_address}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AgentBankInfo;
