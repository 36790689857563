import React from 'react';
import { Footer, Header } from '../../../components/common';
import SingleProgram from '../../programs/SingleProgram';

export default function WebsiteSingleProgram() {
  return (
    <div>
      <Header />
      <div style={{ marginTop: '70px' }}>
        <SingleProgram
          hideBreadCrumb={true}
          hideFooter={true}
          hideStatus={true}
          hideAction={true}
          hideStartApplication={true}
          hideCommission={false}
        />
      </div>
      <Footer />
    </div>
  );
}
