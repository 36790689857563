import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { Logo } from '../images';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { getInitialName } from '../../shared/helpers/getIntialValue';
import { URI } from '../../domain/constant';

export const Header = (props) => {
  const [mobileMenu, setmobileMenu] = useState(false);
  const user_data = useCheckAuthenticated();
  const { user } = user_data;
  console.log('user', user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/*start mobile header  */}
      {/* end mobile header */}
      <header className='main-header'>
        <section className='nav-section'>
          <div className='section-navbar'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <nav className='navbar'>
                    <div className='header-logo'>
                      <Link to={links.home}>
                        <img src={Logo} />
                      </Link>
                    </div>

                    <button
                      id='mobile-menu'
                      onClick={() => {
                        setmobileMenu(!mobileMenu);
                      }}
                    >
                      <i
                        className={mobileMenu ? 'fa fa-close' : 'fa fa-bars'}
                      ></i>
                    </button>

                    <div className='navbar-content '>
                      <ul>
                        <li>
                          <Link to={links.websiteStudent}>Students</Link>
                        </li>
                        <li>
                          <Link to={links.recruiters}>
                            Recruitment Partners
                          </Link>
                        </li>
                        <li>
                          <Link to={links.schoolWebsite}>Schools</Link>
                        </li>
                        <li>
                          <Link to={links.blogs}>Blog</Link>
                        </li>
                      </ul>
                    </div>
                    {user ? (
                      <div className='user-circle'>
                        {' '}
                        {user.profile_pic ? (
                          <Link to={'/dashboard'}>
                            <img
                              src={`${URI}${user.profile_pic}`}
                              style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                              }}
                            />
                          </Link>
                        ) : (
                          <Link to={'/dashboard'} className='text-white'>
                            {getInitialName(
                              user && `${user.first_name} ${user.last_name}`
                            )}
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div className='login-button'>
                        <div>
                          <Link to={links.login} className=' btn primary-btn '>
                            Login
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={links.register}
                            className=' btn secondary-btn '
                          >
                            Register
                          </Link>
                        </div>
                      </div>
                    )}
                  </nav>
                </div>
                <div
                  className='mobile-menu-open only-mobile'
                  style={{ display: mobileMenu ? 'block' : 'none' }}
                >
                  <ul>
                    <li>
                      <Link to={links.websiteStudent}>Students</Link>
                    </li>
                    <li>
                      <Link to={links.recruiters}>Recruitment Partners</Link>
                    </li>
                    <li>
                      <Link to={links.schoolWebsite}>Schools</Link>
                    </li>
                  </ul>
                  {user ? (
                    <>
                      {' '}
                      {user.profile_pic ? (
                        <>
                          <img
                            src={`${URI}${user.profile_pic}`}
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {getInitialName(
                            user && `${user.first_name} ${user.last_name}`
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <div className='logi-but'>
                      <Link to={links.login} className=' btn primary-btn '>
                        Login
                      </Link>
                      <Link to={links.register} className='btn secondary-btn  '>
                        Register
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
    </>
  );
};
