import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectBox, HTMLEditor } from '../Form/Form';
import * as Yup from 'yup';
import ProgramForm from './ProgramForm';
import { useCreateProgram } from '../../shared/hooks/UseProgram';
import { useAllSchoolsFilters } from '../../shared/hooks/UseSchool';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';
import { useNavigate } from 'react-router-dom';
import { programValidationSchema } from './ProgramValidation';
import { useAllDisciplines } from '../../shared/hooks/UseDiscipline';

function AddProgramComponent() {
  const { addProgramData } = useCreateProgram();
  const { schools } = useAllSchoolsFilters();
  const { educationLevelData } = useAllEducationLevels();
  const { discipline_data } = useAllDisciplines();
  const { education_levels } = educationLevelData;
  const { disciplines } = discipline_data;
  const navigate = useNavigate();

  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Program</h1>
        </div>

        <Formik
          initialValues={{
            school: '',
            name: '',
            about: '',
            level: '',
            min_length: '',
            max_length: '',
            commission_type: '',
            commission_year: '',
            commission_percentage: '',
            tuition: '',
            application: '',
            other_fees: [],
            program_intakes: [],
          }}
          validationSchema={programValidationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addProgramData(values);
            navigate('/programs');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log('Formik', formik);
            return (
              <Form>
                <ProgramForm
                  formik={formik}
                  dropdown_data={{ schools, education_levels, disciplines }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddProgramComponent;
