import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SingleApplicationApplicationRequirementsComponent from './SingleApplicationApplicationRequirementsComponent';
import SingleApplicationNotesComponent from './SingleApplicationNotesComponent';
import { URI } from '../../domain/constant';

import SingleApplicationProgramInformationComponent from './SingleApplicationProgramInformationComponent';
import SingleApplicationSchoolInformationComponent from './SingleApplicationSchoolInformationComponent';
import SingleApplicationStudentInformationComponent from './SingleApplicationStudentInformationComponent';
import StudentRecordTimeline from './StudentRecordTimeline';
import TrackApplication_component from './TrackApplication_component';
import {
  useAssignUserToApplication,
  useChangeStatusApplication,
  useMarkCommissionAsPaidFromCollege,
  useCloseApplication,
} from '../../shared/hooks/UseApplication';
import Modal from 'react-modal';
import { useAllCrmUsers } from '../../shared/hooks/UseCrmUser';
import { useAgent } from '../../shared/hooks/UseAgent';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PermissionComponent from '../auth/PermissionComponent';
import { ApplicationStatusEnum } from '../../domain/ApplicationStatusEnum';
import { getInitialName } from '../../shared/helpers/getIntialValue';
import { TextInput } from '../Form/Form';

function SingleApplicationComponent({
  application,
  student,
  applicationRequirements,
  agent,
}) {
  const [toggle, setToggle] = useState('APPLICATION_REQUIREMENT');
  const { updateApplicationData } = useAssignUserToApplication();
  const { updateApplicationStatusData } = useChangeStatusApplication();
  const { closeApplicationHandler } = useCloseApplication();

  const { crm_user_data } = useAllCrmUsers();
  const { crm_users } = crm_user_data;

  const [assignApplicationModalOpen, setAssignApplicationModalOpen] =
    useState(false);
  const [applicationStatusModalOpen, setApplicationStatusModalOpen] =
    useState(false);
  const [showCommissionPayment, setShowCommissionPayment] = useState(false);
  const [showCloseApplicationModal, setShowCloseApplicationModal] =
    useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '450px',
    },
  };
  const { markCommissionAsPaidFromCollegeFunction } =
    useMarkCommissionAsPaidFromCollege();

  return (
    <>
      {application && (
        <>
          <section>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-3'>
                  {application.is_application_closed && (
                    <div className='card bg-danger mb-2'>
                      <div className='card-header'>
                        <div className='text-white'> Closed </div>
                      </div>
                      <div className='card-body'>
                        <div className='text-white'>
                          {' '}
                          {application.close_application_notes}{' '}
                        </div>
                      </div>
                    </div>
                  )}
                  <TrackApplication_component application={application} />
                </div>
                <div className='col-md-9'>
                  <PermissionComponent
                    name={'application'}
                    permission={'view'}
                    hideOnAgent={true}
                    hideOnStudent={true}
                  >
                    <div className='ass-flex'>
                      <div>
                        <button
                          className='btn btn-assign'
                          onClick={() => setAssignApplicationModalOpen(true)}
                        >
                          Assign User{' '}
                        </button>
                      </div>
                      <div>
                        <button
                          className='btn btn-assign'
                          onClick={() => setApplicationStatusModalOpen(true)}
                        >
                          Change Status
                        </button>
                      </div>
                      <div>
                        {application.application_current_status ===
                          ApplicationStatusEnum.ENROLLED && (
                          <button
                            className='btn btn-assign'
                            onClick={() => setShowCommissionPayment(true)}
                          >
                            Update Commission
                          </button>
                        )}
                      </div>
                      {!application.is_application_closed && (
                        <div>
                          <button
                            className='btn btn-assign'
                            onClick={() => setShowCloseApplicationModal(true)}
                          >
                            Close Application
                          </button>
                        </div>
                      )}
                    </div>
                  </PermissionComponent>
                  <div>
                    {application && application.crm_user && (
                      <div className='facilitator-name mb-3'>
                        <div className='facilitator-card-header '>
                          Facilitator Details
                        </div>
                        <div className='facilitator-flex'>
                          {application && application.crm_user && (
                            <>
                              <div className='user-circle-1 user-n-fle'>
                                {application.crm_user &&
                                application.crm_user.profile_pic ? (
                                  <div className='crm-user-profile-pic'>
                                    <img
                                      src={`${URI}${application.crm_user.profile_pic}`}
                                      style={{
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {getInitialName(
                                      `${application.crm_user.first_name} ${application.crm_user.last_name}`
                                    )}
                                  </>
                                )}
                              </div>
                              <div className='faci-main-flex'>
                                <div className='name-flex'>
                                  <div className='valu-f'>Name:</div>
                                  <div className='valu-m'>{`${application.crm_user.first_name}  ${application.crm_user.last_name}`}</div>
                                </div>
                                <div className='name-flex'>
                                  <div className='valu-f'>Email:</div>
                                  <div className='valu-m'>
                                    {application.crm_user.email}
                                  </div>
                                </div>
                                <div className='name-flex'>
                                  <div className='valu-f'>Phone:</div>
                                  <div className='valu-m'>
                                    {application.crm_user.country_code}{' '}
                                    {application.crm_user.phone}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {application.program && (
                    <>
                      <SingleApplicationSchoolInformationComponent
                        school={
                          application.program && application.program.school
                        }
                      />
                      {/* program */}
                      <SingleApplicationProgramInformationComponent
                        program={application.program}
                        application={application}
                      />
                      {/* student */}
                      <SingleApplicationStudentInformationComponent
                        student={student}
                        application={application}
                        agent={agent}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className='single-application-records ptb-50'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='school-and-programs '>
                    <ul>
                      <li
                        onClick={() => {
                          setToggle('APPLICATION_REQUIREMENT');
                        }}
                      >
                        <Link
                          className={
                            toggle === 'APPLICATION_REQUIREMENT' && 'active-tab'
                          }
                        >
                          <i className='fa fa-wpforms'></i>
                          Applicant Requirements
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setToggle('STUDENT_RECORD');
                        }}
                      >
                        <Link
                          className={
                            toggle === 'STUDENT_RECORD' && 'active-tab'
                          }
                        >
                          <i className='fa fa-database'></i> Student Records
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setToggle('NOTES');
                        }}
                      >
                        <Link className={toggle === 'NOTES' && 'active-tab'}>
                          <i className='fa fa-sticky-note-o'></i>Status
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* pre-payment */}
                  {toggle === 'APPLICATION_REQUIREMENT' && (
                    <SingleApplicationApplicationRequirementsComponent
                      student={student}
                      application={application}
                      applicationRequirements={applicationRequirements}
                    />
                  )}
                  {toggle === 'STUDENT_RECORD' && (
                    <StudentRecordTimeline
                      student={student}
                      application={application}
                    />
                  )}
                  {toggle === 'NOTES' && (
                    <SingleApplicationNotesComponent
                      application={application}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <Modal
            style={customStyles}
            isOpen={assignApplicationModalOpen}
            onRequestClose={() => setAssignApplicationModalOpen(false)}
            contentLabel='Assign User'
          >
            <h2>Assign User</h2>
            <Formik
              initialValues={{
                crm_user: '',
              }}
              validationSchema={Yup.object({
                crm_user: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                updateApplicationData(application._id, values);
                setAssignApplicationModalOpen(false);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='User'> Select User </label>
                        <select
                          name='crm_user'
                          className='form-control'
                          id='User'
                          onChange={formik.handleChange}
                          value={formik.values.crm_user}
                        >
                          <option value=''></option>
                          {crm_users &&
                            crm_users.map((item) => {
                              return (
                                <option value={item._id}>
                                  {item.first_name} {item.last_name}
                                </option>
                              );
                            })}
                        </select>
                        {formik.errors && formik.errors.crm_user && (
                          <p className='text-danger'>
                            {formik.errors.crm_user}{' '}
                          </p>
                        )}
                      </div>
                      <div className=''>
                        <button
                          className='btn btn-success btn-sm'
                          type='submit'
                        >
                          <i className='fa fa-save'></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <hr />
            <div className='d-flex justify-content-end'>
              <a onClick={() => setAssignApplicationModalOpen(false)}>X</a>
            </div>
          </Modal>
          {/* Change status  */}
          <Modal
            style={customStyles}
            isOpen={applicationStatusModalOpen}
            onRequestClose={() => setApplicationStatusModalOpen(false)}
            contentLabel='Change Status'
          >
            <h2>Change Status</h2>
            <Formik
              initialValues={{
                application_status: '',
              }}
              validationSchema={Yup.object({
                application_status: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                updateApplicationStatusData(application._id, values);
                setApplicationStatusModalOpen(false);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='User'> Select Status </label>
                        <select
                          name='application_status'
                          className='form-control'
                          id='User'
                          onChange={formik.handleChange}
                          value={formik.values.application_status}
                        >
                          <option value=''></option>
                          {application.application_current_status ===
                            ApplicationStatusEnum.APPLIED && (
                            <option
                              value={ApplicationStatusEnum.APPLICATION_FEE_PAID}
                            >
                              {ApplicationStatusEnum.APPLICATION_FEE_PAID}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.APPLICATION_FEE_PAID && (
                            <option
                              value={ApplicationStatusEnum.APPLICATION_PREPARED}
                            >
                              {ApplicationStatusEnum.APPLICATION_PREPARED}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.APPLICATION_PREPARED && (
                            <option value={ApplicationStatusEnum.SUBMISSION}>
                              {ApplicationStatusEnum.SUBMISSION}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.SUBMISSION && (
                            <option value={ApplicationStatusEnum.OFFER}>
                              {ApplicationStatusEnum.OFFER}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.OFFER && (
                            <option value={ApplicationStatusEnum.POST_OFFER}>
                              {ApplicationStatusEnum.POST_OFFER}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.POST_OFFER && (
                            <option value={ApplicationStatusEnum.UCOL_LOA}>
                              {ApplicationStatusEnum.UCOL_LOA}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.UCOL_LOA && (
                            <option
                              value={ApplicationStatusEnum.TUITION_FEE_PAID}
                            >
                              {ApplicationStatusEnum.TUITION_FEE_PAID}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.TUITION_FEE_PAID && (
                            <option
                              value={ApplicationStatusEnum.IS_VISA_APPLIED}
                            >
                              {ApplicationStatusEnum.IS_VISA_APPLIED}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.IS_VISA_APPLIED && (
                            <>
                              <option value={ApplicationStatusEnum.VISA_ISSUED}>
                                {ApplicationStatusEnum.VISA_ISSUED}
                              </option>
                              <option
                                value={ApplicationStatusEnum.VISA_REJECTED}
                              >
                                {ApplicationStatusEnum.VISA_REJECTED}
                              </option>
                            </>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.VISA_ISSUED && (
                            <option value={ApplicationStatusEnum.ENROLLED}>
                              {ApplicationStatusEnum.ENROLLED}
                            </option>
                          )}
                          {application.application_current_status ===
                            ApplicationStatusEnum.VISA_REJECTED && (
                            <option
                              value={ApplicationStatusEnum.IS_VISA_APPLIED}
                            >
                              {ApplicationStatusEnum.IS_VISA_APPLIED}
                            </option>
                          )}
                        </select>
                        {formik.errors && formik.errors.application_status && (
                          <p className='text-danger'>
                            {formik.errors.application_status}{' '}
                          </p>
                        )}
                      </div>
                      <div className=''>
                        <button
                          className='btn btn-success btn-sm'
                          type='submit'
                        >
                          <i className='fa fa-save'></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <hr />
            <div className='d-flex justify-content-end'>
              <a onClick={() => setApplicationStatusModalOpen(false)}>X</a>
            </div>
          </Modal>
          {/* Close Application */}
          <Modal
            style={customStyles}
            isOpen={showCloseApplicationModal}
            onRequestClose={() => setShowCloseApplicationModal(false)}
            contentLabel='Change Status'
          >
            <h2>Change Status</h2>
            <Formik
              initialValues={{
                is_application_closed: true,
                close_application_notes: '',
              }}
              validationSchema={Yup.object({
                close_application_notes: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                closeApplicationHandler(application._id, values);
                setShowCloseApplicationModal(false);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='User'> Notes </label>
                        <TextInput name='close_application_notes' />
                      </div>
                      <div className=''>
                        <button
                          className='btn btn-success btn-sm'
                          type='submit'
                        >
                          <i className='fa fa-save'></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <hr />
            <div className='d-flex justify-content-end'>
              <a onClick={() => setShowCloseApplicationModal(false)}>X</a>
            </div>
          </Modal>

          <Modal
            style={customStyles}
            isOpen={showCommissionPayment}
            onRequestClose={() => setShowCommissionPayment(false)}
            contentLabel='Change Status'
          >
            <h2> Enter Commission Received </h2>
            <Formik
              initialValues={{
                commission_prospect_edchimp: '',
                commission_received_notes: '',
                commission_paid_date: '',
              }}
              validationSchema={Yup.object({
                commission_prospect_edchimp: Yup.string().required('Required'),
                commission_paid_date: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                // application.applicationDetails
                // .paid_from_college
                // updateApplicationStatusData(application._id, values);
                markCommissionAsPaidFromCollegeFunction(
                  application._id,
                  values
                );
                setShowCommissionPayment(false);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                //
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='User'> Commission </label>
                        <TextInput
                          name='commission_prospect_edchimp'
                          type='number'
                        />
                      </div>
                      <div className='col-md-12'>
                        <label htmlFor='User'> Notes </label>
                        <TextInput name='commission_received_notes' />
                      </div>
                      <div className='col-md-12'>
                        <label htmlFor='User'> Payment Date </label>
                        <TextInput name='commission_paid_date' type='date' />
                      </div>
                      <div className=''>
                        <button
                          className='btn btn-success btn-sm'
                          type='submit'
                        >
                          <i className='fa fa-save'></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <hr />
            <div className='d-flex justify-content-end'>
              <a onClick={() => setShowCommissionPayment(false)}>X</a>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default SingleApplicationComponent;
