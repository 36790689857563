import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { countries } from '../../domain/countries';
import { links } from '../../domain/links.enum';
import { Formik, Form } from 'formik';
import {
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from '../../components/Form/Form';
import * as Yup from 'yup';
import { LoginImage } from '../../components/images';
import {
  useCheckAuthenticated,
  useRegisterUser,
} from '../../shared/hooks/UseAuth';
import { Alerts } from '../../components/common/Alerts';
import AsyncSelect from 'react-select/async';
import { useAllAcademicPartnersFilters } from '../../shared/hooks/UseAcademicPartner';

export const RegisterAsStudent = (props) => {
  const [registerUser] = useRegisterUser();
  const user = useCheckAuthenticated();
  const { academic_partners_data, setAcademicPartnerSearchTerm } =
    useAllAcademicPartnersFilters();
  const [dropdownOption, setdropdownOption] = useState(null);

  useEffect(() => {
    if (academic_partners_data && academic_partners_data.academic_partners) {
      const mappedData = academic_partners_data.academic_partners.map(
        (school) => {
          return {
            label: `${school.first_name} `,
            value: school.id,
          };
        }
      );
      setdropdownOption({ ...dropdownOption, academic_partners: mappedData });
    }
  }, [academic_partners_data]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'academic_partner') {
      setAcademicPartnerSearchTerm(inputValue);
      const mappedData = academic_partners_data.academic_partners.map(
        (school) => {
          return {
            label: `${school.first_name} `,
            value: school.id,
          };
        }
      );
      callback(mappedData);
    }
  };
  return (
    <>
      <div className='login-student'>
        <div className='login-flex'>
          <div className='register-main'>
            <div className='login-student-form'>
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  phone: '',
                  email: '',
                  password: '',
                  nationality: '',
                  country_code: '',
                  Confirm_password: '',
                }}
                validationSchema={Yup.object({
                  first_name: Yup.string().required('Required'),
                  last_name: Yup.string().required('Required'),
                  phone: Yup.number()
                    .typeError('should be a number.')
                    .required('Required'),
                  email: Yup.string().email().required('Required'),
                  password: Yup.string()
                    .required('Required')
                    .min(
                      4,
                      ({ min }) => `Password must be at least ${min} characters`
                    )
                    .max(
                      20,
                      ({ max }) => `Password must be at least ${max} characters`
                    ),
                  nationality: Yup.string().required('Required'),
                  country_code: Yup.string().required('Required'),
                  confirm_password: Yup.string()
                    .required('Required')
                    .oneOf(
                      [Yup.ref('password')],
                      'Both password need to be the same'
                    ),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  values.email = values.email.toLowerCase();
                  setSubmitting(true);
                  registerUser(values);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <h3>Register As a Student</h3>
                      <p>Please fill the details to register as Student</p>
                      <TextInput
                        label='First Name'
                        name='first_name'
                        type='text'
                      />
                      <TextInput
                        label='Last Name'
                        name='last_name'
                        type='text'
                      />

                      <TextInput label='Email' name='email' type='email' />
                      <div>
                        <PasswordInput label='Password' name='password' />
                      </div>
                      <div>
                        <PasswordInput
                          label='Confirm'
                          name='confirm_password'
                        />
                      </div>

                      <SelectBox
                        label='Nationality'
                        name='nationality'
                        onChange={(e) => {
                          formik.setFieldValue('nationality', e.target.value);
                          const filteredCountry = countries.filter(
                            (item) => item.name === e.target.value
                          );
                          if (filteredCountry && filteredCountry.length > 0) {
                            formik.setFieldValue(
                              'country_code',
                              filteredCountry[0].dial_code
                            );
                          }
                        }}
                      >
                        <option value=''></option>
                        {countries &&
                          countries.map((e) => {
                            return <option>{e.name}</option>;
                          })}
                      </SelectBox>
                      <div className='row'>
                        <div className='col-md-6'>
                          <SelectBox label='Country Code' name='country_code'>
                            <option value=''></option>
                            {countries &&
                              countries.map((e) => {
                                return (
                                  <option value={e.dial_code}>
                                    {e.dial_code} {e.name}
                                  </option>
                                );
                              })}
                          </SelectBox>
                        </div>
                        <div className='col-md-6'>
                          <TextInput
                            label='Phone'
                            name='phone'
                            onChange={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (
                                e.target.value === '' ||
                                regex.test(e.target.value)
                              ) {
                                formik.setFieldValue('phone', e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className='col-md-12'>
                          <CheckBox
                            label='Yes'
                            name='recommended_by_school'
                            onChange={(e) => {
                              if (e.target.checked) {
                                formik.setFieldValue(
                                  'recommended_by_school',
                                  true
                                );
                              } else {
                                formik.setFieldValue(
                                  'recommended_by_school',
                                  false
                                );
                              }
                            }}
                          >
                            <label htmlFor='recommended_by_school'>
                              Are you recommended by school?
                            </label>
                          </CheckBox>
                        </div>
                        {formik.values.recommended_by_school && (
                          <div className='col-md-12'>
                            <label htmlFor=''> Select School </label>
                            <AsyncSelect
                              loadOptions={(inputValue, callback) =>
                                loadOptions(
                                  inputValue,
                                  callback,
                                  'academic_partner'
                                )
                              }
                              isClearable={true}
                              defaultOptions={
                                dropdownOption &&
                                dropdownOption['academic_partners']
                              }
                              onChange={(e) => {
                                if (e) {
                                  formik.setFieldValue(
                                    'academic_partner',
                                    e.value
                                  );
                                }
                              }}
                            />
                          </div>
                        )}

                        <div className='col-md-12 mb-2'>
                          <CheckBox
                            label='Yes'
                            name='graduated_from_same_institution'
                            value='Yes'
                          >
                            <label htmlFor='graduated_from_same_institution'>
                              I have read and agree to the
                              <Link
                                to={links.termsAndConditions}
                                className='link'
                                href='#'
                              >
                                Terms and Conditions
                              </Link>
                              and the{' '}
                              <Link className='link' to={links.privacyPolicy}>
                                {' '}
                                Privacy and Cookies Policy*
                              </Link>
                            </label>
                          </CheckBox>
                        </div>
                      </div>
                      <div className='create-button'>
                        <button type='submit' className='btn primary-btn'>
                          Create Account
                        </button>
                      </div>
                      <hr></hr>
                      <div className='terms'>
                        <p>
                          Already have an account?{' '}
                          <Link to={links.login}>Login</Link>
                        </p>
                        <p>
                          *If you are a minor in your jurisdiction of residence,
                          you must have your parent or legal guardian read and
                          agree to the above terms.
                        </p>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <hr></hr>
            </div>
          </div>
          <div className='login-image'>
            <img src={LoginImage} />
          </div>
        </div>
      </div>
      <Alerts />
    </>
  );
};
