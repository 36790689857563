import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useCreateCrmUser } from '../../shared/hooks/UseCrmUser';

import { useAllPermissions } from '../../shared/hooks/UsePermissions';
import GradingForm from './GradingForm';
import { useCreateGrading } from '../../shared/hooks/UseGrading';
function AddGradingComponent() {
  // const { addCrmUserData } = useCreateCrmUser();
  const navigate = useNavigate();
  const { addGradingData } = useCreateGrading();

  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Grading Scheme</h1>
        </div>

        <Formik
          initialValues={{
            name: '',
            countries: [],
            letter_grades: [],
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addGradingData(values);
            navigate('/settings/grading-schemes');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            //
            return <GradingForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddGradingComponent;
