import React from 'react';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';

import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllTemplates } from '../../shared/hooks/UseTemplate';
import FilterComponent from '../../components/filter/FilterComponent';
import TemplateComponent from '../../components/templates/TemplateComponent';
function Templates() {
  const { template_data, setPageNumber } = useAllTemplates();
  const {
    templates_loading,
    templates,
    template_page,
    template_pages,
    total_templates,
  } = template_data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Templates'} />
      <SettingNavigationComponent active_link={SETTING_MENU_ENUM.Templates} />
      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Templates</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'crm_user'} permission={'view'}>
                <div className='add-student-btn'>
                  <Link to={links.addTemplate} className='btn primary-btn-h '>
                    Add Template
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <section className='students-section'>
        {templates && (
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <TemplateComponent
                  templates_loading={templates_loading}
                  templates={templates}
                  template_page={template_page}
                  template_pages={template_pages}
                  total_templates={total_templates}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <AuthFooter />
    </div>
  );
}

export default Templates;
