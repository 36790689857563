import React from 'react';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';

import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import CrmUsersComponent from '../../components/crmUsers/CrmUsersComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllCrmUsers } from '../../shared/hooks/UseCrmUser';
import FilterComponent from '../../components/filter/FilterComponent';
function CrmUsers() {
  const { data, setPageNumber } = useAllCrmUsers();
  const {
    crm_user_loading,
    crm_users,
    crm_user_page,
    crm_user_pages,
    total_crm_users,
  } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'CRM User'} />
      <SettingNavigationComponent active_link={SETTING_MENU_ENUM.CRM_USERS} />
      <section className='profile-main ptb-20'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>CRM Users</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'crm_user'} permission={'view'}>
                <div className='add-student-btn'>
                  <Link to={links.addCrmUser} className='btn primary-btn-h '>
                    Add CRM User
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <PermissionComponent
        name={'crm_user'}
        permission={'get_all'}
        hideOnAgent={true}
        hideOnStudent={true}
      >
        <section className='students-section'>
          {crm_users && (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <CrmUsersComponent
                    crm_users_loading={crm_user_loading}
                    crm_users={crm_users}
                    crm_user_page={crm_user_page}
                    crm_user_pages={crm_user_pages}
                    total_crm_users={total_crm_users}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </PermissionComponent>
      <AuthFooter />
    </div>
  );
}

export default CrmUsers;
