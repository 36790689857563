export const GET_DISCIPLINES_STARTED = 'GET_DISCIPLINES_STARTED';
export const GET_DISCIPLINES = 'GET_DISCIPLINES';
export const GET_DISCIPLINES_ENDED = 'GET_DISCIPLINES_ENDED';
export const GET_DISCIPLINES_ERROR = 'GET_DISCIPLINES_ERROR';
export const ADD_DISCIPLINE_STARTED = 'ADD_DISCIPLINE_STARTED';
export const ADD_DISCIPLINE = 'ADD_DISCIPLINE';
export const ADD_DISCIPLINE_ENDED = 'ADD_DISCIPLINE_ENDED';
export const ADD_DISCIPLINE_ERROR = 'ADD_DISCIPLINE_ERROR';
export const EDIT_DISCIPLINE_STARTED = 'EDIT_DISCIPLINE_STARTED';
export const EDIT_DISCIPLINE = 'EDIT_DISCIPLINE';
export const EDIT_DISCIPLINE_ENDED = 'EDIT_DISCIPLINE_ENDED';
export const EDIT_DISCIPLINE_ERROR = 'EDIT_DISCIPLINE_ERROR';
export const GET_DISCIPLINE = 'GET_DISCIPLINE';
export const GET_DISCIPLINE_STARTED = 'GET_DISCIPLINE_STARTED';
export const GET_DISCIPLINE_ENDED = 'GET_DISCIPLINE_ENDED';
export const GET_DISCIPLINE_ERROR = 'GET_DISCIPLINE_ERROR';
