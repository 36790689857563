import React, { useState } from 'react';
import { DummySchool, DummyUser, LogoTemplate } from '../images';
import { URI } from '../../domain/constant';
import { Link } from 'react-router-dom';
function SingleApplicationSchoolInformationComponent({ school }) {
  const [show, setshow] = useState(true);
  return (
    <>
      <div className="box-show-and-hide">
        <div className="main-heading-container" onClick={() => setshow(!show)}>
          <div className="school-details-box-flex">
            <div>
              <h3>School Information</h3>
            </div>
            <div className="">
              <i
                className={show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
              ></i>
            </div>
          </div>
        </div>
        <div
          className="application-full-requirement"
          style={{ display: show ? 'block' : 'none' }}
        >
          <div className="main-application-content-flex">
            <div className="dummy-image">
              <img src={school.logo ? `${URI}${school.logo}` : DummySchool} />
            </div>
            <div class="similar-card-content">
              <Link to={`/schools/${school._id}`}>
                <h5>{school.name}</h5>
              </Link>
              <div class="location-map">
                <i class="fa fa-map-marker"></i>
                <span>
                  {school.address} {school.city} {school.state} {school.country}
                </span>
                {school.postal_code}
              </div>
              <div class="similar-card-deadline-flex">
                <div>
                  <h5>Founded</h5>
                  <h6>{school.founded_in}</h6>
                </div>
                <div>
                  <h5>School ID</h5>
                  <h6>{school.school_id}</h6>
                </div>
                <div>
                  <h5>Institution type</h5>
                  <h6>{school.institution_type}</h6>
                </div>
                <div>
                  <h5>Status</h5>
                  <h6>{school.status}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleApplicationSchoolInformationComponent;
