import { AxiosError } from 'axios';
import { setAlert } from '../../store/actions/alert_action';
// type ErrorObject = { message?: string; field?: string };
// type ServerError = { errors: ErrorObject[] };
export const ErrorFunction = (serverError, dispatch) => {
  if (serverError && serverError.response) {
    const errors = serverError.response.data;
    errors.errors.map((item) => {
      if (item.message) {
        dispatch(setAlert(item.message, 'danger'));
      }
    });
  }
};
