export const inputFields = {
  first_name: {
    type: 'string',
    required: true,
    title: 'First Name',
  },
  last_name: {
    type: 'string',
    required: true,
    title: 'Last Name',
  },
  company_name: {
    type: 'string',
    required: true,
    title: 'company_name',
  },
  country: {
    type: 'string',
    required: true,
    title: 'country',
  },
  email: {
    type: 'string',
    required: true,
    title: 'Email',
  },
  phone: {
    type: 'string',
    required: true,
    title: 'Phone',
  },
  country_code: {
    type: 'string',
    required: true,
    title: 'Country Code',
  },
  user_id: {
    type: 'string',
    required: true,
    title: 'User ID',
  },
  assigned_user: {
    type: 'string',
    required: true,
    title: 'Assigned User',
  },
  profile_status: {
    type: 'string',
    required: true,
    title: 'Profile Status',
  },
};
