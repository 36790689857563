import React from 'react';

import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import AddCountryComponent from '../../components/country/AddCountryComponent';

function AddCountry() {
  return (
    <>
      <div className="wrapper">
        <Auth_header_mobile />
        <AuthHeader />
        <AuthBreadCrumb title={'Add Country'} />

        {/* page-section */}

        <section className=" personal-information ptb-20 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <AddCountryComponent />
              </div>
            </div>
          </div>
        </section>
        <AuthFooter />
      </div>
    </>
  );
}

export default AddCountry;
