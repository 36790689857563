import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { useNavigate } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import {
  useSingleTemplate,
  useDeleteTemplate,
} from '../../shared/hooks/UseTemplate';

import ViewGradingComponent from '../../components/gradings/ViewGradingComponent';
import ViewTemplateComponent from '../../components/templates/ViewTemplateComponent';

function ViewTemplate() {
  const params = useParams();
  const navigate = useNavigate();
  const { template_data } = useSingleTemplate(params.id);
  const { deleteTemplateFunction } = useDeleteTemplate();
  const { template } = template_data;

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={template && template.title} />
      <PermissionComponent name={'crm_user'} permission={'view'}>
        {template && (
          <section className='profile-main ptb-20'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <div className='main-sec-heading'>
                    <h1>{template.title}</h1>
                  </div>
                </div>
                <div className='col-md-6 col-6'>
                  <div className='d-flex'>
                    {/* <div className='add-student-btn'>
                      <button
                        className='btn btn-danger '
                        onClick={() => deleteTemplateFunction(template.id)}
                      >
                        Delete
                      </button>
                    </div> */}
                    <div className='add-student-btn'>
                      <Link
                        to={`/settings/templates/${template.id}/edit`}
                        className='btn primary-btn-h '
                      >
                        Edit Template
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {template && (
          <section className='main-school-single-view ptb-20 '>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <ViewTemplateComponent template={template} />
                </div>
              </div>
            </div>
          </section>
        )}
      </PermissionComponent>
      <AuthFooter />
    </div>
  );
}

export default ViewTemplate;
