import {
  ADD_SCHOOL_ATTENDED_STARTED,
  ADD_SCHOOL_ATTENDED_SUCCESS,
  ADD_SCHOOL_ATTENDED_ENDED,
  ADD_SCHOOL_ATTENDED_ERROR,
} from '../types/school_attended_types';

const initialState = {
  add_school_attended_loading: false,
  add_school_attended_errors: null,
  add_school_attended_success: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_SCHOOL_ATTENDED_STARTED:
      return {
        ...state,
        add_school_attended_loading: true,
        add_school_attended_errors: null,
        add_school_attended_success: null,
      };
    case ADD_SCHOOL_ATTENDED_ENDED:
      return {
        ...state,
        add_school_attended_loading: false,
      };
    case ADD_SCHOOL_ATTENDED_ERROR:
      return {
        ...state,
        add_school_attended_errors: payload,
      };
    case ADD_SCHOOL_ATTENDED_SUCCESS:
      return {
        ...state,
        add_school_attended_success: payload,
      };

    default:
      return state;
  }
}
