import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import AdmissionRequiremntsComponent from '../../components/programs/AdmissionRequiremntsComponent';
import OtherFees from '../../components/SingleProgram/OtherFees';
import ProgramIntakesComponent from '../../components/SingleProgram/ProgramIntakesComponent';
import ProgramOverview from '../../components/SingleProgram/ProgramOverview';
import SingleProgramKeypointsComponent from '../../components/SingleProgram/SingleProgramKeypointsComponent';
import SchoolGallery from '../../components/SingleSchool/SchoolGallery';
import SchoolHeader from '../../components/SingleSchool/SchoolHeader';
import ZoomGalleryComponent from '../../components/SingleSchool/ZoomGalleryComponent';
import { links } from '../../domain/links.enum';
import {
  useSingleProgramBySlug,
  useChangeStatusProgram,
  useDeleteProgram,
} from '../../shared/hooks/UseProgram';
import { useAllRequirements } from '../../shared/hooks/UseRequirement';
import ApplicationRequirementsComponent from '../../components/programs/ApplicationRequirementsComponent';
import { useCurrentAgent } from '../../shared/hooks/UseAgent';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { ProgramCommissionAgent } from '../../components/programs/ProgramCommissionAgent';
import { UserRoles } from '../../domain/UserRole';

function SingleProgram({ hideBreadCrumb, hideFooter }) {
  const navigate = useNavigate();
  const user_data = useCheckAuthenticated();
  const params = useParams();
  const [showFullGallery, setShowFullGallery] = useState(false);
  const { program_data } = useSingleProgramBySlug(params.slug);
  const { program, program_loading, program_requirements } = program_data;
  const { requirements_data, getRequirementsData } = useAllRequirements();
  const { requirements } = requirements_data;

  const { draftProgramData, activateProgramData } = useChangeStatusProgram();

  useEffect(() => {
    if (program && program._id) {
      getRequirementsData(program._id);
    }
  }, [program]);
  const { deleteProgramFunction } = useDeleteProgram();

  const [checkDeleteSchool, setCheckDeleteSchool] = useState(false);

  const deleteProgramBtnClicked = async () => {
    await deleteProgramFunction(program.id);
    navigate('/programs');
  };
  const { user } = user_data;

  return (
    <div className='wrapper'>
      {!hideBreadCrumb && (
        <>
          <Auth_header_mobile />
          <AuthHeader />
        </>
      )}

      <PermissionComponent name={'program'} permission={'view'}>
        {program && (
          <>
            {!hideBreadCrumb && (
              <AuthBreadCrumb title={program && program.name} />
            )}

            {program.school && (
              <>
                <SchoolHeader
                  school={program.school}
                  program={program}
                  hideBreadCrumb={true}
                />
                <SchoolGallery
                  school={program.school}
                  showFullGallery={showFullGallery}
                  setShowFullGallery={setShowFullGallery}
                />
                {showFullGallery && (
                  <ZoomGalleryComponent
                    school={program.school}
                    showFullGallery={showFullGallery}
                    setShowFullGallery={setShowFullGallery}
                  />
                )}
              </>
            )}

            <section className='main-school-single-view ptb-50 '>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12'>
                    {program && (
                      <div className='col-md-12'>
                        <div className='program-main-heading-single'>
                          <h2>{program.name}</h2>
                        </div>
                        {!hideBreadCrumb && (
                          <div className='add-student-btn2'>
                            <PermissionComponent
                              name={'program'}
                              permission={'update'}
                              hideOnStudent={true}
                              hideOnAgent={true}
                            >
                              {program.status === 'Active' ? (
                                <a
                                  className='badge badge-draft'
                                  onClick={() => draftProgramData(program._id)}
                                >
                                  {' '}
                                  Move to Draft{' '}
                                </a>
                              ) : (
                                <a
                                  className='badge badge-publish'
                                  onClick={() =>
                                    activateProgramData(program._id)
                                  }
                                >
                                  {' '}
                                  Publish{' '}
                                </a>
                              )}
                            </PermissionComponent>
                            <PermissionComponent
                              name={'program'}
                              permission={'create'}
                              hideOnStudent={true}
                              hideOnAgent={true}
                            >
                              <Link
                                to={`/programs/${program.slug}/duplicate`}
                                className='badge badge-edit'
                              >
                                Duplicate Program
                              </Link>
                            </PermissionComponent>
                            <PermissionComponent
                              name={'program'}
                              permission={'update'}
                              hideOnStudent={true}
                              hideOnAgent={true}
                            >
                              <Link
                                to={`/programs/${program.slug}/edit`}
                                className='badge badge-edit'
                              >
                                Edit Program
                              </Link>
                            </PermissionComponent>
                            <PermissionComponent
                              name={'program'}
                              permission={'delete'}
                              hideOnStudent={true}
                              hideOnAgent={true}
                            >
                              <a
                                className='badge badge-trash'
                                onClick={() => setCheckDeleteSchool(true)}
                              >
                                <i className='fa fa-trash'></i>{' '}
                              </a>
                            </PermissionComponent>
                          </div>
                        )}

                        <hr />
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='single-school-tabs'>
                      <ul>
                        <li className='active'>
                          <a href=''>Overview</a>
                        </li>
                        <li>
                          <a href='#admission_requirements'>
                            Admission Requirements
                          </a>
                        </li>
                        {/* <li>
                          <a href='#similar_program'>Similar Programs</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-8'>
                    <div className='single-school-about'>
                      <ProgramOverview program={program} />
                    </div>
                    {!hideBreadCrumb && (
                      <PermissionComponent
                        name={'program'}
                        permission={'update'}
                        hideOnStudent={true}
                        hideOnAgent={true}
                        hideOnAcademicPartner={true}
                      >
                        {!program_requirements && (
                          <div className='add-requirement-box'>
                            <div class='apply-button'>
                              {program.school && (
                                <Link
                                  to={`/schools/${program.school._id}/programs/${program._id}/add-requirements`}
                                  class='btn btn primary-btn-h w-100 '
                                >
                                  Add Requirements
                                </Link>
                              )}
                            </div>
                          </div>
                        )}
                      </PermissionComponent>
                    )}
                    {!hideBreadCrumb && (
                      <PermissionComponent
                        name={'program'}
                        permission={'update'}
                        hideOnStudent={true}
                        hideOnAgent={true}
                        hideOnAcademicPartner={true}
                      >
                        <div className='add-requirement-box'>
                          <div class='apply-button'>
                            {program.school && (
                              <Link
                                to={`/schools/${program.school._id}/programs/${program._id}/add-application-requirements`}
                                class='btn btn primary-btn-h w-100 '
                              >
                                Add Application Requirements
                              </Link>
                            )}
                          </div>
                        </div>
                        {program && (
                          <ApplicationRequirementsComponent program={program} />
                        )}
                      </PermissionComponent>
                    )}

                    <div className='admission-requirements'>
                      {program_requirements && (
                        <AdmissionRequiremntsComponent
                          requirements={program_requirements}
                          program={program}
                          hideBreadCrumb={hideBreadCrumb}
                        />
                      )}
                    </div>
                    {/* <div className='similar-program'>
                    <SimilarProgramComponent />
                  </div> */}
                  </div>
                  <div className='col-md-4'>
                    {/* <div className='apply-button'>
                      <button className='btn btn primary-btn-h w-100 '>
                        Apply Now
                      </button>
                    </div> */}
                    <SingleProgramKeypointsComponent program={program} />
                    <OtherFees program={program} />
                    <ProgramIntakesComponent program={program} />
                    {
                      <>
                        <PermissionComponent
                          name={'program'}
                          permission={'update'}
                          hideOnStudent={true}
                          hideOnAgent={true}
                          hideOnAcademicPartner={true}
                          hideOnCounsellor={true}
                        >
                          {program.commission_percentage && (
                            <>
                              {program.commission_type &&
                              program.commission_type === 'percentage' ? (
                                <>
                                  <div className='col-md-6'></div>
                                  <div className='col-md-6'></div>
                                  <div>
                                    <div className='minimum-education-card'>
                                      <h5>Commission</h5>
                                      <h6>
                                        {program.school &&
                                          program.school.market &&
                                          program.school.market.currency}{' '}
                                        {parseFloat(
                                          (program.tuition / 100) *
                                            program.commission_percentage
                                        ).toFixed(2)}{' '}
                                      </h6>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div className='minimum-education-card'>
                                    <h5>Commission </h5>
                                    <h6>
                                      {' '}
                                      {program.school &&
                                        program.school.market &&
                                        program.school.market.currency}{' '}
                                      {program.commission_percentage}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </PermissionComponent>
                        <PermissionComponent
                          name={'program'}
                          permission={'update'}
                          hideOnStudent={true}
                          hideOnAgent={true}
                          hideOnAcademicPartner={true}
                          hideOnCounsellor={true}
                        >
                          {program.commission_percentage ? (
                            <>
                              {program.commission_type &&
                              program.commission_type === 'percentage' ? (
                                <>
                                  <div className='col-md-6'></div>
                                  <div className='col-md-6'></div>
                                  <div>
                                    <div className='minimum-education-card'>
                                      <h5> Total Commission</h5>
                                      <h6>
                                        {program.school &&
                                          program.school.market &&
                                          program.school.market.currency}{' '}
                                        {parseFloat(
                                          (program.tuition / 100) *
                                            program.commission_percentage *
                                            program.commission_year
                                        ).toFixed(2)}{' '}
                                      </h6>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div className='minimum-education-card'>
                                    <h5> Total Commission </h5>
                                    <h6>
                                      {program.school &&
                                        program.school.market &&
                                        program.school.market.currency}{' '}
                                      {program.commission_percentage *
                                        program.commission_year}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {program.school &&
                                program.school.commission_percentage && (
                                  <>
                                    {program.school.commission_type &&
                                    program.school.commission_type ===
                                      'percentage' ? (
                                      <>
                                        <div className='col-md-6'></div>
                                        <div className='col-md-6'></div>
                                        <div>
                                          <div className='minimum-education-card'>
                                            <h5>Total Commission</h5>
                                            <h6>
                                              {program.school &&
                                                program.school.market &&
                                                program.school.market
                                                  .currency}{' '}
                                              {parseFloat(
                                                (program.tuition / 100) *
                                                  program.school
                                                    .commission_percentage *
                                                  program.school.commission_year
                                              ).toFixed(2)}{' '}
                                            </h6>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div>
                                        <div className='minimum-education-card'>
                                          <h5>Total Commission </h5>
                                          <h6>
                                            {program.school &&
                                              program.school.market &&
                                              program.school.market
                                                .currency}{' '}
                                            {program.school
                                              .commission_percentage *
                                              program.school.commission_year}
                                          </h6>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </PermissionComponent>
                        {user && user.role === UserRoles.AGENT && (
                          <ProgramCommissionAgent
                            user={user}
                            program={program}
                          />
                        )}
                      </>
                    }
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </PermissionComponent>
      {checkDeleteSchool && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setCheckDeleteSchool(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>
                    Do you really want to delete this record? This process
                    cannot be undone.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setCheckDeleteSchool(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteProgramBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hideFooter && <AuthFooter />}
    </div>
  );
}

export default SingleProgram;
