import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  addDiscipline,
  getDisciplines,
  getDiscipline,
  editDiscipline,
  deleteDiscipline,
  filterDisciplines,
} from '../../store/actions/discipline_action';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { api } from '../../domain/api';
// Add Data
export const useCreateDiscipline = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.discipline);
  const addDisciplineData = async (data) => {
    dispatch(addDiscipline(data));
  };
  return { data, addDisciplineData };
};

// Get Single Data
export const useSingleDiscipline = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.discipline);
  useEffect(() => {
    dispatch(getDiscipline(id));
  }, [id]);
  return { data };
};

//Edit Data
export const useUpdateDiscipline = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Discipline);
  const updateDisciplineData = async (id, data) => {
    dispatch(editDiscipline(id, data));
  };
  return { updateDisciplineData };
};

// Get All Data
export const useAllDisciplines = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.discipline);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getDisciplines({}));
    }, 1000),
    []
  );

  return { discipline_data: data, setPageNumber };
};

export const useFilterDisciplines = () => {
  const [all_data, setAllData] = useState([]);
  const getAllData = async () => {
    try {
      if (all_data.length === 0) {
        const { data } = await api.get('/disciplines/filter');
        setAllData(data.disciplines);
      }
    } catch (error) {
      setAllData([]);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return {
    disciplines: all_data,
  };
};

//Delete Data
export const useDeleteDiscipline = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.Discipline);
  const deleteDisciplineFunction = async (id) => {
    dispatch(deleteDiscipline(id));
  };
  return { deleteDisciplineFunction };
};
