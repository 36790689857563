import React from 'react';
import IntakesAccordian, { faqs } from '../programs/IntakesAccordian';

function ProgramIntakesComponent({ program }) {
  return (
    <div>
      <div className='single-school-sidebar program-intakes'>
        <div className='program-heading-intakes-flex'>
          <div>
            <h4>Program Intakes</h4>
          </div>
        </div>
        {program &&
          program.program_intakes.map((intake) => (
            <IntakesAccordian intake={intake} />
          ))}
      </div>
    </div>
  );
}

export default ProgramIntakesComponent;
