import React from 'react';
import { Link } from 'react-router-dom';

function DashboardIconBoxComponent({ title, icon, value, link }) {
  return (
    <div className='dashboard-box '>
      <Link to={link}>
        <>
          <div className='dashboard-icon'>
            <i className={icon} />
          </div>
          <div className='dashboard-heading'>
            <h3>{title}</h3>
            <h5>{value}</h5>
          </div>
        </>
      </Link>
    </div>
  );
}

export default DashboardIconBoxComponent;
