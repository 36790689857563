import moment from 'moment';
import { useState } from 'react';
import * as XLSX from 'xlsx';

export const UseFilter = () => {
  const exportXLSXData = (data, sheet_name, export_name) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, sheet_name);
    XLSX.writeFile(wb, `${export_name}.xlsx`);
  };
  return [exportXLSXData];
};

export const UseDataForExcel = () => {
  const [exportData, setExportData] = useState([]);
  const convertToReadable = (rawData, inputFields) => {
    if (inputFields) {
      let freshData = [];
      const newFreshData = rawData.map((data, index) => {
        let newItem = {};
        newItem['SR No'] = index + 1;
        Object.keys(inputFields) &&
          Object.keys(inputFields).map((item, index) => {
            if (inputFields[item] && inputFields[item].type === 'string') {
              newItem[inputFields[item].title] =
                inputFields[item].inputType == 'date'
                  ? data[item] && moment(data[item]).format('DD-MMM-YYYY')
                  : inputFields[item].inputType == 'datetime-local'
                  ? moment(data[item]).format('DD-MMM-YYYY hh:mm:ss A')
                  : data[item]
                  ? data[item]
                  : '';
            }
            if (inputFields[item] && inputFields[item].type === 'text') {
              newItem[item] = data[item] ? data[item] : '';
            }
            if (inputFields[item] && inputFields[item].type === 'select') {
              newItem[item] = data[item] ? data[item] : '';
            }
            if (inputFields[item] && inputFields[item].type === 'related') {
              newItem[item] = data[item]
                ? !inputFields[item].static
                  ? data[item][inputFields[item].field]
                  : data[item]
                : '';
            }
            if (inputFields[item] && inputFields[item].type === 'checkbox') {
              newItem[item] = data[item] ? 'YES' : 'NO';
            }
          });

        newItem['Created By'] =
          data.createdBy && data.createdBy.first_name
            ? data.createdBy.first_name + ' ' + data.createdBy.last_name
            : '';

        newItem['Updated By'] =
          data.updated_by && data.updated_by.name ? data.updated_by.name : '';

        newItem['Created At'] = data.createdAt
          ? moment(data.createdAt).local().format('DD-MM-YYYY hh:mm:ss A')
          : '';

        newItem['Updated At'] = data.updatedAt
          ? moment(data.updatedAt).local().format('DD-MM-YYYY hh:mm:ss A')
          : '';

        return newItem;
      });
      setExportData(newFreshData);
    }
  };
  return [convertToReadable, exportData];
};

export const UseDataForExcelPrograms = () => {
  const [exportData, setExportData] = useState([]);
  const convertToReadable = (rawData, inputFields) => {
    if (inputFields) {
      let freshData = [];
      const newFreshData = rawData.map((data, index) => {
        let newItem = {};
        newItem['SR No'] = index + 1;
        newItem['program_name'] = data.name;
        newItem['id'] = data._id;
        newItem['program_id'] = data.program_id;
        newItem['school'] = data.school && data.school.name;
        newItem['application_fee'] = data.application_fee;
        newItem['source'] = data.source;
        newItem['description'] = data.description;
        newItem['delivery_method'] = data.delivery_method;
        newItem['length_breakdown'] = data.length_breakdown;
        newItem['level'] = data.level;
        newItem['discipline'] = data.discipline ? data.discipline?.name : '';
        newItem['sub_discipline'] = data.sub_discipline
          ? data.sub_discipline?.name
          : '';
        newItem['country'] = data.country;
        newItem['city'] = data.city;
        newItem['state'] = data.state;
        newItem['max_length'] = data.max_length;
        newItem['min_length'] = data.min_length;

        newItem['other_fees'] = data.other_fees;
        newItem['program_rank'] = data.program_rank;
        newItem['tuition'] = data.tuition;
        newItem['level_text'] = data.level_text;
        newItem['intake_1_title'] =
          data.program_intakes &&
          data.program_intakes[0] &&
          data.program_intakes[0].intake_name
            ? data.program_intakes[0].intake_name
            : '';
        newItem['intake_2_title'] =
          data.program_intakes &&
          data.program_intakes[1] &&
          data.program_intakes[1].intake_name
            ? data.program_intakes[1].intake_name
            : '';
        newItem['intake_3_title'] =
          data.program_intakes &&
          data.program_intakes[2] &&
          data.program_intakes[2].intake_name
            ? data.program_intakes[2].intake_name
            : '';
        newItem['intake_4_title'] =
          data.program_intakes &&
          data.program_intakes[3] &&
          data.program_intakes[3].intake_name
            ? data.program_intakes[3].intake_name
            : '';
        newItem['commission_percentage'] = data.commission_percentage;
        newItem['commission_type'] =
          data.commission_percentage && data.commission_type;
        newItem['commission_year'] =
          data.commission_percentage && data.commission_year;
        newItem['education_level'] =
          data.education_level && data.education_level.name;
        newItem['english_score_required'] =
          data.requirements && data.requirements.english_score_required;
        newItem['allow_delayed_proof_of_elp'] =
          data.requirements && data.requirements.allow_delayed_proof_of_elp;
        newItem['min_gpa'] = data.requirements && data.requirements.min_gpa;
        newItem['min_toefl_reading'] =
          data.requirements && data.requirements.min_toefl_reading;
        newItem['min_toefl_writing'] =
          data.requirements && data.requirements.min_toefl_writing;
        newItem['min_toefl_listening'] =
          data.requirements && data.requirements.min_toefl_listening;
        newItem['min_toefl_speaking'] =
          data.requirements && data.requirements.min_toefl_speaking;
        newItem['min_toefl_total'] =
          data.requirements && data.requirements.min_toefl_total;
        newItem['min_ielts_reading'] =
          data.requirements && data.requirements.min_ielts_reading;
        newItem['min_ielts_writing'] =
          data.requirements && data.requirements.min_ielts_writing;
        newItem['min_ielts_listening'] =
          data.requirements && data.requirements.min_ielts_listening;
        newItem['min_ielts_speaking'] =
          data.requirements && data.requirements.min_ielts_speaking;
        newItem['min_ielts_average'] =
          data.requirements && data.requirements.min_ielts_average;
        newItem['min_ielts_any_band'] =
          data.requirements && data.requirements.min_ielts_any_band;
        newItem['min_ielts_any_band_count'] =
          data.requirements && data.requirements.min_ielts_any_band_count;
        newItem['min_ielts_ukvi_reading'] =
          data.requirements && data.requirements.min_ielts_ukvi_reading;
        newItem['min_ielts_ukvi_writing'] =
          data.requirements && data.requirements.min_ielts_ukvi_writing;
        newItem['min_ielts_ukvi_listening'] =
          data.requirements && data.requirements.min_ielts_ukvi_listening;
        newItem['min_ielts_ukvi_speaking'] =
          data.requirements && data.requirements.min_ielts_ukvi_speaking;
        newItem['min_ielts_ukvi_average'] =
          data.requirements && data.requirements.min_ielts_ukvi_average;
        newItem['min_ielts_ukvi_any_band'] =
          data.requirements && data.requirements.min_ielts_ukvi_any_band;
        newItem['min_ielts_ukvi_any_band_count'] =
          data.requirements && data.requirements.min_ielts_ukvi_any_band_count;
        newItem['min_duolingo_score'] =
          data.requirements && data.requirements.min_duolingo_score;
        newItem['min_pte_listening'] =
          data.requirements && data.requirements.min_pte_listening;
        newItem['min_pte_reading'] =
          data.requirements && data.requirements.min_pte_reading;
        newItem['min_pte_speaking'] =
          data.requirements && data.requirements.min_pte_speaking;
        newItem['min_pte_writing'] =
          data.requirements && data.requirements.min_pte_writing;
        newItem['min_pte_overall'] =
          data.requirements && data.requirements.min_pte_overall;
        newItem['level'] = data.requirements && data.requirements.level;
        newItem['level_text'] =
          data.requirements && data.requirements.level_text;
        newItem['other_requirements'] =
          data.requirements && data.requirements.other_requirements;
        newItem['is_gre_requirements'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.is_gre_requirements;
        newItem['max_gpa_to_be_required'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.max_gpa_to_be_required;
        newItem['min_verbal'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_verbal;
        newItem['min_quantitative'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_quantitative;
        newItem['min_writing'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_writing;
        newItem['min_verbal_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_verbal_percentile;
        newItem['min_quantitative_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_quantitative_percentile;
        newItem['min_writing_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_writing_percentile;
        newItem['min_verbal_quantitative_combined'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.min_verbal_quantitative_combined;
        newItem['gmat_min_verbal'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_verbal;
        newItem['gmat_min_quantitative'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_quantitative;
        newItem['gmat_min_writing'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_writing;
        newItem['gmat_min_verbal_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_verbal_percentile;
        newItem['gmat_min_quantitative_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_quantitative_percentile;
        newItem['gmat_min_writing_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_writing_percentile;
        newItem['gmat_min_total'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_total;
        newItem['gmat_min_total_percentile'] =
          data.requirements &&
          data.requirements.gre_requirements &&
          data.requirements.gre_requirements.gmat_min_total_percentile;

        newItem['Created By'] =
          data.createdBy && data.createdBy.first_name
            ? data.createdBy.first_name + ' ' + data.createdBy.last_name
            : '';

        newItem['Updated By'] =
          data.updated_by && data.updated_by.name ? data.updated_by.name : '';

        newItem['Created At'] = data.createdAt
          ? moment(data.createdAt).local().format('DD-MM-YYYY hh:mm:ss A')
          : '';

        newItem['Updated At'] = data.updatedAt
          ? moment(data.updatedAt).local().format('DD-MM-YYYY hh:mm:ss A')
          : '';

        return newItem;
      });
      setExportData(newFreshData);
    }
  };
  return [convertToReadable, exportData];
};

export const UseDataForExcelAgents = () => {
  const [exportData, setExportData] = useState([]);
  const convertToReadable = (rawData, inputFields) => {
    if (inputFields) {
      let freshData = [];
      const newFreshData = rawData.map((data, index) => {
        let newItem = {};
        newItem['SR No'] = index + 1;
        newItem['first_name'] = data.first_name;
        newItem['last_name'] = data.last_name;
        newItem['company_name'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.company_name
            ? data.agentData.agentInfo.company_name
            : 'NA';

        newItem['country'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.company_country
            ? data.agentData.agentInfo.company_country
            : 'NA';
        newItem['email'] = data.email;
        newItem['phone'] = data.phone;
        newItem['country_code'] = data.country_code;
        newItem['user_id'] = data.user_id;
        newItem['assigned_user'] = data.crm_user
          ? `${data.crm_user.first_name} ${data.crm_user.last_name}`
          : 'NA';
        newItem['profile_status'] = data.profile_status;
        newItem['address'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.company_address
            ? data.agentData.agentInfo.company_address
            : 'NA';
        newItem['city'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.company_city
            ? data.agentData.agentInfo.company_city
            : 'NA';
        newItem['state'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.company_state
            ? data.agentData.agentInfo.company_state
            : 'NA';
        newItem['referred_by'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.referred_by_edchimp
            ? data.agentData.agentInfo.referred_by_edchimp
            : 'NA';
        newItem['zipcode'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.company_zipcode
            ? data.agentData.agentInfo.company_zipcode
            : 'NA';
        newItem['bank_account_no'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.bank_account_details &&
          data.agentData.agentInfo.bank_account_details.account_no
            ? data.agentData.agentInfo.bank_account_details.account_no
            : 'NA';
        newItem['bank_name'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.bank_account_details &&
          data.agentData.agentInfo.bank_account_details.bank_name
            ? data.agentData.agentInfo.bank_account_details.bank_name
            : 'NA';
        newItem['ifsc_code'] =
          data.agentData &&
          data.agentData.agentInfo &&
          data.agentData.agentInfo.bank_account_details &&
          data.agentData.agentInfo.bank_account_details.ifsc_code
            ? data.agentData.agentInfo.bank_account_details.ifsc_code
            : 'NA';

        newItem['Created At'] = data.createdAt
          ? moment(data.createdAt).local().format('DD-MM-YYYY hh:mm:ss A')
          : '';

        newItem['Updated At'] = data.updatedAt
          ? moment(data.updatedAt).local().format('DD-MM-YYYY hh:mm:ss A')
          : '';

        return newItem;
      });
      setExportData(newFreshData);
    }
  };
  return [convertToReadable, exportData];
};

export const UseDataForApplications = () => {
  const [exportData, setExportData] = useState([]);
  const convertToReadable = (rawData) => {
    const newFreshData = rawData.map((data, index) => {
      let newItem = {};
      newItem['SR No'] = index + 1;
      newItem['Application Receive date'] = data.createdAt
        ? moment(data.createdAt).local().format('DD-MM-YYYY hh:mm:ss A')
        : '';
      newItem['Application Status'] = data.is_application_closed
        ? 'Closed'
        : data.application_current_status;
      newItem['Application ID'] = data.application_id;
      newItem['School Name'] = data.school && data.school.name;
      newItem['Course name'] = data.program && data.program.name;
      newItem['Applied Country'] = data.country;
      newItem['Intake'] = data.intake;
      newItem['Student Name'] =
        data.student &&
        `${data.student.first_name} 
      ${data.student.last_name}`;
      newItem['Student Country'] = data.student && data.student.nationality;
      newItem['Student City'] = data.student.nationality;
      newItem['Student Contact number'] =
        data.student && `${data.student.country_code}${data.student.phone}`;
      newItem['Student Email'] = data.student && data.student.email;
      newItem['Marketing Manager'] =
        data.agentData &&
        data.agentData.crm_user &&
        `${data.agentData.crm_user.first_name} ${data.agentData.crm_user.last_name}`;
      newItem['Agency Name'] =
        data.agentData && data.agentData.agentInfo
          ? data.agentData.agentInfo.company_name
          : 'NA';
      newItem['Agent Country'] = data.agent && data.agent.nationality;

      newItem['Agent City'] =
        data.agentData &&
        data.agentData.agentInfo &&
        data.agentData.agentInfo.company_city;
      newItem['Agent Counsellor'] =
        data.counsellor &&
        `${data.counsellor.first_name} ${data.counsellor.last_name}`;
      newItem['Facilitator'] =
        data.crm_user &&
        `${data.crm_user.first_name} ${data.crm_user.last_name}`;
      newItem['Last Action Date'] = moment(data.updatedAt)
        .local()
        .format('DD-MM-YYYY hh:mm:ss A');

      return newItem;
    });
    setExportData(newFreshData);
  };
  return [convertToReadable, exportData];
};
