export const GET_COUNSELLORS_STARTED = 'GET_COUNSELLORS_STARTED';
export const GET_COUNSELLORS = 'GET_COUNSELLORS';
export const GET_COUNSELLORS_ENDED = 'GET_COUNSELLORS_ENDED';
export const GET_COUNSELLORS_ERROR = 'GET_COUNSELLORS_ERROR';
export const ADD_COUNSELLOR_STARTED = 'ADD_COUNSELLOR_STARTED';
export const ADD_COUNSELLOR = 'ADD_COUNSELLOR';
export const ADD_COUNSELLOR_ENDED = 'ADD_COUNSELLOR_ENDED';
export const ADD_COUNSELLOR_ERROR = 'ADD_COUNSELLOR_ERROR';
export const EDIT_COUNSELLOR_STARTED = 'EDIT_COUNSELLOR_STARTED';
export const EDIT_COUNSELLOR = 'EDIT_COUNSELLOR';
export const EDIT_COUNSELLOR_ENDED = 'EDIT_COUNSELLOR_ENDED';
export const EDIT_COUNSELLOR_ERROR = 'EDIT_COUNSELLOR_ERROR';
export const GET_COUNSELLOR = 'GET_COUNSELLOR';
export const GET_COUNSELLOR_STARTED = 'GET_COUNSELLOR_STARTED';
export const GET_COUNSELLOR_ENDED = 'GET_COUNSELLOR_ENDED';
export const GET_COUNSELLOR_ERROR = 'GET_COUNSELLOR_ERROR';
