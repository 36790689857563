import React from 'react';

function BlogComponent({ title, id, date, image }) {
  return (
    <div
      className='blog-item style-two middle-image wow fadeInUp delay-0-2s animated'
      style={{ visibility: 'visible', animationName: 'fadeInUp' }}
    >
      <h4>
        <a href={`/blog/${id}`}>{title}</a>
      </h4>
      <ul className='blog-meta'>
        <li>
          <i className='fa fa-user' /> <a href='blog.html'>By EdChimp</a>
        </li>
        {/* <li>
                    <i className="fa fa-comments" />{' '}
                    <a href="blog.html">Comments (5)</a>
                  </li> */}
      </ul>
      <div className='blog-image'>
        <img src={image} alt='Blog' />
        <span className='date'>{date}</span>
      </div>
      <a href={`/blog/${id}`} className='read-more'>
        Read more <i className='fa fa-arrow-right' />
      </a>
    </div>
  );
}

export default BlogComponent;
