import { Form } from 'formik';
import React from 'react';
import {
  SelectBox,
  TextInput,
  CheckBox,
  GalleryUpload,
  FileUpload,
} from '../Form/Form';

function SchoolAttendedForm({
  gradings,
  education_levels,
  formik,
  countries,
  showTranscript,
  showVerificationLink,
  showCertificate,
}) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <SelectBox
            label='Country Of Institution'
            name='country_of_institution'
          >
            <option value=''></option>
            {countries &&
              countries.map((e) => {
                return <option>{e.name}</option>;
              })}
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Name Of Institution'
            name='name_of_institution'
          ></TextInput>
        </div>
        {/* <div className='col-md-6'>
          <SelectBox label='Grading System' name='grading_scheme'>
            <option value=''></option>
            {gradings &&
              gradings.map((item) => {
                return <option value={item._id}> {item.name} </option>;
              })}
          </SelectBox>
        </div> */}
        <div className='col-md-6'>
          <SelectBox label='Level Of Education' name='level_of_education'>
            <option value=''></option>
            {education_levels &&
              education_levels.map((item) => {
                return <option value={item._id}>{item.name}</option>;
              })}
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Primary Language of Institution'
            name='primary_language_of_institution'
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Attended Institution From'
            name='attended_institution_from'
            type='date'
          />
        </div>
        <div className='col-md-6'>
          <TextInput
            label='Attended Institution To'
            name='attended_institution_to'
            type='date'
          />
        </div>
        <div className='col-md-12'>
          <GalleryUpload
            title='Upload Documents'
            item='documents'
            formik={formik}
            value={formik.values.documents}
            hideView={true}
          />
        </div>
        {showTranscript && (
          <div className='col-md-12'>
            <FileUpload
              title='Transcript'
              item='transcript'
              formik={formik}
              value={formik.values.transcript}
              hideView={true}
            />
          </div>
        )}
        {showCertificate && (
          <div className='col-md-12'>
            <FileUpload
              title='Certificate'
              item='certificate'
              formik={formik}
              value={formik.values.certificate}
              hideView={true}
            />
          </div>
        )}

        <div className='col-md-12'>
          <TextInput label='Degree Name' name='degree_name' />
        </div>
        {showVerificationLink && (
          <div className='col-md-12'>
            <TextInput
              label='Verification Link'
              type='url'
              name='verification_link'
            />
          </div>
        )}

        <div className='middle-form'>
          <h5>School Address</h5>
        </div>

        <h5></h5>
        <div className='col-md-12'>
          <TextInput label='Address' name='address' />
        </div>
        <div className='col-md-4'>
          <TextInput label='City' name='city' />
        </div>
        <div className='col-md-4'>
          <TextInput label='State' name='state' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Zip code' name='zipcode' />
        </div>
        <div className='school-attend-flex'>
          <div>
            <button type='submit' className='btn primary-btn'>
              Save{' '}
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default SchoolAttendedForm;
