export const REGISTER_AS_STUDENT_STARTED = 'REGISTER_AS_STUDENT_STARTED';
export const REGISTER_AS_STUDENT_ENDED = 'REGISTER_AS_STUDENT_ENDED';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL';

export const GET_CURRENT_USER_STARTED = 'GET_CURRENT_USER_STARTED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_CURRENT_USER_ENDED = 'GET_CURRENT_USER_ENDED';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_ENDED = 'LOGIN_ENDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';

export const LOGOUT = 'LOGOUT';
