import React from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from '../Form/Form';
import * as Yup from 'yup';
import { countries } from '../../domain/countries';
import { MartialStatusEnum } from '../../domain/MaritalStatusEnum';
import { GenderEnum } from '../../domain/GenderEnum';
import { useCreateStudent } from '../../shared/hooks/UseStudent';
import { useNavigate } from 'react-router-dom';

export const AddStudentComponent = (props) => {
  const navigate = useNavigate();
  const { addStudentData } = useCreateStudent();
  return (
    <>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Student</h1>
        </div>

        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            password: '',
            nationality: '',
          }}
          validationSchema={Yup.object({
            first_name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            phone: Yup.number()
              .typeError('should be a number.')
              .required('Required'),
            email: Yup.string().email().required('Required'),

            nationality: Yup.string().required('Required'),
            country_code: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            values.email = values.email.toLowerCase();
            setSubmitting(true);
            await addStudentData(values);
            resetForm();
            navigate('/students');
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <TextInput label='First Name' name='first_name' type='text' />
                <TextInput label='Last Name' name='last_name' type='text' />
                <TextInput label='Email' name='email' type='email' />

                <SelectBox
                  label='Nationality'
                  name='nationality'
                  onChange={(e) => {
                    formik.setFieldValue('nationality', e.target.value);
                    const filteredCountry = countries.filter(
                      (item) => item.name === e.target.value
                    );
                    if (filteredCountry && filteredCountry.length > 0) {
                      formik.setFieldValue(
                        'country_code',
                        filteredCountry[0].dial_code
                      );
                    }
                  }}
                >
                  <option value=''></option>
                  {countries &&
                    countries.map((e) => {
                      return <option>{e.name}</option>;
                    })}
                </SelectBox>
                <div className='row'>
                  <div className='col-md-6'>
                    <SelectBox label='Country Code' name='country_code'>
                      <option value=''></option>
                      {countries &&
                        countries.map((e) => {
                          return (
                            <option value={e.dial_code}>
                              {e.dial_code} {e.name}
                            </option>
                          );
                        })}
                    </SelectBox>
                  </div>
                  <div className='col-md-6'>
                    <TextInput
                      label='Phone'
                      name='phone'
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === '' ||
                          regex.test(e.target.value)
                        ) {
                          formik.setFieldValue('phone', e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='save-button'>
                  <button type='submit' className='btn primary-btn w-100'>
                    Save & Continue
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
