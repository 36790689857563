import React from 'react';

import { Form } from 'formik';
import { TextInput } from '../../components/Form/Form';
import ProgramsRolesComponents from './ProgramsRolesComponents';
import SchoolsRolesComponents from './SchoolsRolesComponent';
import StudentsRolesComponents from './StudentsRolesComponent';
import ApplicationsRolesComponents from './ApplicationsRolesComponent';
import PaymentsRolesComponents from './PaymentsRolesComponent';
import EducationLevelRolesComponents from './EducationLevelRolesComponent';
import AgentsRolesComponents from './Agents';
import CrmUserRolesComponents from './CrmUserRolesComponent';
import CountryRolesComponents from './CountryRolesComponent';
import RolesPermissionsComponents from './RolesPermissionsComponents';

function RolesAndPermissionsForm({ formik }) {
  return (
    <Form>
      <div className="row">
        <div className="col-md-12">
          <TextInput label="Name" name="name" type="text" />
        </div>
        <div className="col-md-12">
          <div className="main-sec-heading bor">
            <h1>Permissions</h1>
          </div>
        </div>
        <ProgramsRolesComponents formik={formik} />
        <SchoolsRolesComponents formik={formik} />
        <StudentsRolesComponents formik={formik} />
        <ApplicationsRolesComponents formik={formik} />
        <PaymentsRolesComponents formik={formik} />
        <EducationLevelRolesComponents formik={formik} />
        <AgentsRolesComponents formik={formik} />
        <CrmUserRolesComponents formik={formik} />
        <RolesPermissionsComponents formik={formik} />
        <CountryRolesComponents formik={formik} />
      </div>

      <div className="row">
        <div className="create-button">
          <button type="submit" className="btn primary-btn">
            Save & Continue
          </button>
        </div>
      </div>
    </Form>
  );
}

export default RolesAndPermissionsForm;
