import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { links } from '../../domain/links.enum';
import { useSingleSlab } from '../../shared/hooks/UseSlab';
import ViewSlabComponent from '../../components/slabs/ViewSlabComponent';

function ViewSlab() {
  const params = useParams();
  const { data } = useSingleSlab(params.id);
  const { slab, slab_loading } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={slab && slab.name} />
      {slab && (
        <section className='profile-main ptb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-6'>
                <div className='main-sec-heading'>
                  <h1>{slab.name}</h1>
                </div>
              </div>
              <div className='col-md-6 col-6'>
                <div className='add-student-btn'>
                  <Link
                    to={`/settings/slabs/${slab.id}/edit`}
                    className='btn primary-btn-h '
                  >
                    Edit Commission Structure
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {slab && (
        <section className='main-school-single-view ptb-20 '>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <ViewSlabComponent slab={slab} />
              </div>
            </div>
          </div>
        </section>
      )}
      <AuthFooter />
    </div>
  );
}

export default ViewSlab;
