import React from 'react';
import { FooterLogo } from '../images';
import Counter from '../../containers/website/component/Counter';
import { links } from '../../domain/links.enum';
import { Link } from 'react-router-dom';

export const Footer = (props) => {
  return (
    <>
      {/* <Counter /> */}
      <footer className='website-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-sm-4'>
              <div className='footer-widget about-widget'>
                <h5 className='footer-title'>About Us</h5>
                <div className='footer-logo'>
                  <img src={FooterLogo} />
                </div>
                <p>
                  EdChimp a pioneering platform established in 2023 and powered
                  by EPA Global & NZEC Group
                </p>
                <h5 className='pt-5 mb-2'>Follow Us</h5>
                <div className='social-style-one'>
                  <a
                    href='https://instagram.com/edchimp_?igshid=MzRlODBiNWFlZA=='
                    target='_blank'
                  >
                    <i className='fa fa-instagram' />
                  </a>
                  <a href='https://www.youtube.com/@edchimp' target='_blank'>
                    <i className='fa fa-youtube' />
                  </a>
                  <a href='mailto:connect@edchimp.com' target='_blank'>
                    <i className='fa fa-envelope' />
                  </a>
                </div>
              </div>
            </div>
            {/* useless */}

            {/*end useless  */}
            <div className='col-lg-3 col-sm-4'>
              <div class='footer-widget menu-widget'>
                <h5 class='footer-title'>Important Links</h5>
                <ul>
                  <li>
                    <Link to={links.schools}>Schools</Link>
                  </li>
                  <li>
                    <Link to={links.registerAsAgent}>Recruiters</Link>
                  </li>
                  <li>
                    <Link to={links.searchProgramAndSchools}>
                      Discover Programs
                    </Link>
                  </li>
                  <li>
                    <Link to={links.searchProgramAndSchools}>
                      Discover Schools
                    </Link>
                  </li>
                  <li>
                    <Link to={links.register}>Register</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-sm-4'>
              <div class='footer-widget menu-widget'>
                <h5 class='footer-title'>Main Links</h5>
                <ul>
                  <li>
                    <Link to={links.about}>Our Story</Link>
                  </li>
                  <li>
                    <Link to={links.careers}>Careers</Link>
                  </li>
                  <li>
                    <Link to={links.blogs}>Blog</Link>
                  </li>
                  {/* <li>
                    <a href='#'>Press</a>
                  </li> */}

                  <li>
                    <Link to={links.ourSolutions}>Our Solutions</Link>
                  </li>
                  <li>
                    <Link to={links.contactUs}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-sm-4'>
              <div class='footer-widget contact-info-widget'>
                <h5 class='footer-title'>Get In Touch</h5>
                <ul>
                  {/* <li>
                    <i className='fa fa-map-marker' /> 123, Main Street Canada
                    USA
                  </li> */}
                  <li>
                    <i className='fa fa-envelope' />
                    <a href='mailto:connect@edchimp.com'>connect@edchimp.com</a>
                  </li>
                  <li>
                    <i className='fa fa-phone' /> Australia (HO):
                    <a href='tel:+61488048870'> &nbsp;+61488048870</a>
                  </li>
                  <li>
                    <i className='fa fa-phone' />
                    UAE:
                    <a href='tel:+971563608269'> &nbsp;+971 563608269</a>
                  </li>
                  <li>
                    <i className='fa fa-phone' />
                    India:
                    <a href='tel:+919729072188'>&nbsp;+91-972-907-2188</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='copy-right-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                <div className='copyright-content'>
                  <p>Copyright © 2023-2024 Edchimp. All Right Reserved.</p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='copyright-content'>
                  <div className='privacy-links'>
                    <ul>
                      <li>
                        <Link to={links.privacyPolicy}>
                          Privacy & Cookies Policy
                        </Link>
                      </li>
                      <li>
                        <Link to={links.termsAndConditions}>
                          Terms & Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
