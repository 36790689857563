import React from 'react';
import { useSingleSchoolPrograms } from '../../shared/hooks/UseSchool';
import ProgramSearchCardComponent from '../search_program_schools/ProgramSearchCardComponent';

function SchoolPrograms({ school }) {
  const { school_programs_data } = useSingleSchoolPrograms(school);
  const { school_programs } = school_programs_data;
  return (
    <div id='programs' className='location-content'>
      <h3>Programs</h3>
      <div className='similar-program-single'>
        <div id='similar_program' className='overview-content'>
          <div className='row mt-3'>
            {school_programs &&
              school_programs.map((item) => {
                return (
                  <ProgramSearchCardComponent
                    program={item}
                    hideStartBtn={true}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolPrograms;
