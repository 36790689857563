import { useDispatch } from 'react-redux';
import { updateGreGmatInfo } from '../../store/actions/gre_gmat_action';

export const useUpdateGreGmatInfo = () => {
  const dispatch = useDispatch();

  const updateGreGmatInformation = async (id, values) => {
    dispatch(updateGreGmatInfo(id, values));
  };

  return {
    updateGreGmatInformation,
  };
};
