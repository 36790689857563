import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
} from '../../../components/Form/Form';
import * as Yup from 'yup';
import { countries } from '../../../domain/countries';
import { AuthHeader } from '../../../components/auth_common/AuthHeader';
import { AuthBreadCrumb } from '../../../components/auth_common/AuthBreadCrumb';
import { PersonalInformation } from '../../../components/general_information/PersonalInforamtion';
import { AddressDetails } from '../../../components/general_information/AddressDetails';
import { AuthFooter } from '../../../components/auth_common/AuthFooter';
import { AuthProfileTabs } from '../../../components/auth_common/AuthProfileTabs';
import { PROFILE_TABS_ENUM } from '../../../domain/profiletab';
import { STUDENT_PROFILE_ENUM } from '../../../domain/student_profile_menu';
import { useGetStudentProfile } from '../../../shared/hooks/UseStudent';
import { useCheckAuthenticated } from '../../../shared/hooks/UseAuth';
import Skeleton from 'react-loading-skeleton';
import Auth_header_mobile from '../../../components/auth_common/Auth_header_mobile';

export const GeneralInformation = ({ isActive }) => {
  const { getStudentInfo, student_loading, student } = useGetStudentProfile();
  const userData = useCheckAuthenticated();

  const user_id = userData.user ? userData.user.id : null;
  useEffect(() => {
    if (user_id) {
      getStudentInfo(user_id);
    }
  }, [user_id]);

  return (
    <div className="wrapper">
      <Auth_header_mobile />
      <AuthHeader active={STUDENT_PROFILE_ENUM.PROFILE} />
      <AuthBreadCrumb title={'My Profile'} />

      {/* page-section */}
      <section className="profile-main ptb-30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-sec-heading">
                <h1>My Profile</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <AuthProfileTabs active={PROFILE_TABS_ENUM.GENERAL_INFORMATION} />
            </div>
          </div>
        </div>
      </section>
      {student_loading ? (
        <>
          <section className="  personal-information ">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="personal-information-box clearfix">
                    <Skeleton height={24} />
                    <hr />
                    <Skeleton count={4} height={44} />

                    <div className="text-center">
                      <Skeleton height={55} width={150} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="personal-information-box clearfix">
                    <Skeleton height={24} />
                    <hr />
                    <Skeleton count={4} height={44} />

                    <div className="text-center">
                      <Skeleton height={55} width={150} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="  personal-information ">
          {student && (
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <PersonalInformation
                    student_info={student}
                    student_loading={student_loading}
                  />
                </div>
                <div className="col-md-6">
                  <AddressDetails
                    student_info={student}
                    student_loading={student_loading}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      )}

      <AuthFooter />
    </div>
  );
};
