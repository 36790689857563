import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function PaymentTable({ application }) {
  const [boxshow, setBoxshow] = useState(false);
  return (
    <>
      <div className='content-payment'>
        <div className='row'>
          <div className='col-2'>
            <h2>2023-03-27</h2>
          </div>
          <div className='col-7'>
            <h2>
              Postgraduate Diploma - Respiratory Medicine, University of
              Birmingham - Edgbaston
            </h2>
          </div>
          <div className='col-2'>
            <h2>£0.00 GBP</h2>
          </div>
          <div className='col-md-1' onClick={() => setBoxshow(!boxshow)}>
            <i className={boxshow ? 'fa fa-angle-down' : 'fa fa-angle-right'} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div
            className='box-open'
            style={{ display: boxshow ? 'block' : 'none' }}
          >
            <div className='box-display-flex order-main'>
              <div className=''>
                <h6>ORDER DETAILS</h6>
              </div>
              <div className='order-details'>
                <h6>ORDER ID: 5169D03E-824E-45EB-907D-0452C25BF32C</h6>
              </div>
            </div>
            <div className='box-display-flex'>
              <div className=''>
                <h6>
                  Postgraduate Diploma - Respiratory Medicine, University of
                  Birmingham - Edgbaston
                </h6>
              </div>
              <div className=''>
                <p>£0.00 GBP</p>
              </div>
            </div>
            <div className='box-display-flex'>
              <div className=''>
                <h6>Discount of 5%</h6>
              </div>
              <div className=''>
                <p>(£0.00 GBP)</p>
              </div>
            </div>
            <div className='view-receipt'>
              <p>
                View your full receipt{' '}
                <Link to='/orders/:id/receipt'>Click Here</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentTable;
