import {
  GET_GRADINGS_STARTED,
  GET_GRADINGS,
  GET_GRADINGS_ENDED,
  ADD_GRADING_STARTED,
  ADD_GRADING,
  ADD_GRADING_ENDED,
  EDIT_GRADING_STARTED,
  EDIT_GRADING,
  EDIT_GRADING_ENDED,
  GET_GRADING,
  GET_GRADING_STARTED,
  GET_GRADING_ENDED,
} from '../types/grading_types';

const initialState = {
  gradings_loading: true,
  gradings: null,
  grading_page: null,
  grading_pages: null,
  total_gradings: 0,

  grading: null,
  grading_loading: null,

  add_grading_loading: true,
  edit_grading_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GRADINGS_STARTED:
      return {
        ...state,
        gradings: null,
        grading_pages: null,
        grading_page: null,
        total_gradings: 0,
        gradings_loading: true,
      };
    case GET_GRADINGS:
      return {
        ...state,
        gradings: payload.gradings,
        grading_pages: payload.pages,
        grading_page: payload.page,
        total_gradings: payload.total_gradings,
      };
    case GET_GRADINGS_ENDED:
      return {
        ...state,
        gradings_loading: false,
      };

    case ADD_GRADING_STARTED:
      return {
        ...state,
        grading_message: null,
        add_grading_loading: true,
      };
    case ADD_GRADING:
      return {
        ...state,
        grading_message: payload,
      };
    case ADD_GRADING_ENDED:
      return {
        ...state,
        add_grading_loading: false,
      };
    case GET_GRADING_STARTED:
      return {
        ...state,
        grading: null,
        grading_loading: true,
      };
    case GET_GRADING:
      return {
        ...state,
        grading: payload,
      };
    case GET_GRADING_ENDED:
      return {
        ...state,
        grading_loading: false,
      };
    case EDIT_GRADING_STARTED:
      return {
        ...state,
        grading_message: null,
        edit_grading_loading: true,
      };
    case EDIT_GRADING:
      return {
        ...state,
        grading_message: payload,
      };
    case EDIT_GRADING_ENDED:
      return {
        ...state,
        edit_grading_loading: false,
      };

    default:
      return state;
  }
}
