import { api } from '../../domain/api';
import {
  GET_NOTIFICATIONS_STATED,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ENDED,
  EDIT_NOTIFICATION_STATED,
  EDIT_NOTIFICATION,
  EDIT_NOTIFICATION_ENDED,
  GET_ALL_NOTIFICATIONS_STATED,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_ENDED,
  TOGGLE_NOTIFICATION,
} from '../types/notification_type';

import { handleError } from '../../shared/handleError';
import * as qs from 'qs';

export const toggleNotification = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_NOTIFICATION,
    payload: data,
  });
};
export const getNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTIFICATIONS_STATED,
    });

    const { data } = await api.get(`/notifications`);

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: data,
    });
    dispatch({
      type: GET_NOTIFICATIONS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATIONS_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const getNotificationsAll = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_NOTIFICATIONS_STATED,
    });
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    const { data } = await api.get(`/notifications/all?&${query}`);

    dispatch({
      type: GET_ALL_NOTIFICATIONS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_NOTIFICATIONS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_NOTIFICATIONS_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const editNotification = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_NOTIFICATION_STATED,
    });
    const { data } = await api.put(`/notifications/${id}`, formData);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: data,
    });
    dispatch({
      type: EDIT_NOTIFICATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_NOTIFICATION_ENDED,
    });
    // dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editNotificationBulk = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_NOTIFICATION_STATED,
    });
    const { data } = await api.put(`/notifications/bulk-update`, formData);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: {
        notifications: null,
        pages: 1,
        page: 1,
        total_notifications: 0,
      },
    });
    dispatch({
      type: EDIT_NOTIFICATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_NOTIFICATION_ENDED,
    });
    // dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllNotifications =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_NOTIFICATIONS_STATED,
      });
      const { data } = await api.get(
        `/notifications/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_NOTIFICATIONS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_NOTIFICATIONS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
