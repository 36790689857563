import React from 'react';
import { Footer, Header } from '../../../components/common';
import {
  AcceptanceRate,
  AccessSchool,
  ApplicantMatching,
  ApplyForSchool,
  ApplyingForVisa,
  ApprovedNetwork,
  BestCommissions,
  CentralPlatform,
  DataDriven,
  DataInsights,
  DataInsightsSchool,
  DocumentVerification,
  EducationRecruiter,
  EventsAndWeb,
  EventsWebinars,
  FindProgram,
  FlexibleDelivery,
  Grow,
  IncreaseStudent,
  Industry,
  IndustryEducationSchool,
  JourneyBegins,
  LessAdministration,
  LocalSupport,
  LocalSupportSchools,
  MainRecruiter,
  MoreSchool,
  OfferAdmission,
  OneEasy,
  OnePlatform,
  PromotionalChannels,
  PuttingStudent,
  QualityApplication,
  ReceiveQuality,
  RecruitmentPartnerWebsite,
  Reward,
  SchoolMainImage,
  SchoolPartnership,
  StudentEligibility,
  StudentGetAccepted,
  StudentHelped,
  StudentHelpedSchool,
  StudentScholarship,
  StudentSource,
  Trusted,
  Unmatched,
  VettedOffer,
  WorkWith,
} from '../../../components/images';
import Testimonial from '../component/Testimonial';
import Counter from '../component/Counter';
import { links } from '../../../domain/links.enum';
import { Link } from 'react-router-dom';

function SchoolWebsite() {
  return (
    <div>
      <Header />
      <section className="sec-pad page-section recruiter-section-website">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="recruiter-content">
                <h2>
                  Gain access to global recruitment markets for qualified
                  international students
                </h2>
                <p>
                  Benefit from a strong global presence and attract highly
                  skilled students to your educational programs.
                </p>
                <div className="explore-button">
                  <Link to={links.contactUs} className=" btn btn-join ">
                    Work With Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="recruiter-images">
                <img src={SchoolMainImage} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="growth ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={QualityApplication}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Quality Applications & Enrolment</h3>
                  <p>
                    EdChimp ensures high conversion and retention rates as our
                    students are well informed about programs, institutes and
                    costs before applying. We also ensure correctly verified
                    documents and completed applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={Unmatched}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Unparalleled Diversity</h3>
                  <p>
                    EdChimp students come from a broad range of countries,
                    showcasing unparalleled diversity
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="recruit-icon-box">
                <div className="main-icon-b">
                  <img src={LessAdministration}></img>
                </div>

                <div className="pointer-headings">
                  <h3>Innovativeness</h3>
                  <p>
                    EdChimp enables partner schools to dedicate 25% more time to
                    meaningful tasks by reducing administrative burdens and
                    prioritizing educational activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* useless */}
      <section>
        <div className="container">
          <div className="row"></div>
        </div>
      </section>

      <section className="freature-section-six mb-50 ">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-8 mx-auto">
              <div
                className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className="section-title mb-30">
                  <h2>
                    Broadening Our <span>International</span> Reach
                    {''}
                  </h2>
                  <p>
                    Captivate the top students from around the world with
                    EdChimp!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ptb-50">
            <div className="col-md-12 mx-auto">
              <div className="row">
                <div className="col-md-4">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={IncreaseStudent} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Enhance Student
                        <br /> Diversity
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={ReceiveQuality} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Receive Quality
                        <br /> Applications
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={ApprovedNetwork} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Approved Recruiter
                        <br /> Network
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={DocumentVerification} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Document
                        <br /> Verification
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={PromotionalChannels} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Promotional
                        <br /> Channels
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={ApplicantMatching} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>
                        Applicant
                        <br /> Matching
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="explore-button text-center mt-5">
              <Link to={links.contactUs} class=" btn btn-join ">
                Let's Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="freature-section-six mb-50 ">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-8 mx-auto">
              <div
                className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className="section-title mb-30">
                  <h2>
                    How It <span>Works?</span>
                    {''}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row ptb-50">
            <div className="col-md-12 mx-auto">
              <div className="row">
                <div className="col-md-4">
                  <div className="commission-box">
                    <div className="commission-box-icon">
                      <img src={StudentEligibility} />
                      <h4>Step 1</h4>
                      <h3>Students Verify Their Eligibility</h3>
                      <p>
                        {' '}
                        Students fill in a short survey and get matched to
                        programs and schools.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="commission-box">
                    <div className="commission-box-icon">
                      <img src={ApplyForSchool} />
                      <h4>Step 2</h4>
                      <h3>Students Apply to Schools</h3>
                      <p>
                        Students choose a school and program, fill-up their
                        profile, pay their fees, and send in the proper
                        documentation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="commission-box">
                    <div className="commission-box-icon">
                      <img src={StudentGetAccepted} />
                      <h4>Step 3</h4>
                      <h3>Students Get Accepted</h3>
                      <p>
                        The application is evaluated by the school and a letter
                        of acceptance is released.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mx-auto">
                  <div className="commission-box">
                    <div className="commission-box-icon">
                      <img src={ApplyingForVisa} />
                      <h4>Step 4</h4>
                      <h3>Student Applies for Visa</h3>
                      <p>
                        EdChimp’s experts advise the student through the visa
                        application procedure.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mx-auto">
                  <div className="commission-box">
                    <div className="commission-box-icon">
                      <img src={JourneyBegins} />
                      <h4>Step 5</h4>
                      <h3>Student Journey Begins</h3>
                      <p>
                        The student sets forward with bags packed ready to begin
                        their adventure on your campus.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="freature-section-six mb-50 ">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-8 mx-auto">
              <div
                className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className="section-title mb-30">
                  <h2>
                    A user-friendly platform designed to provide high-quality
                    <span>applications and beyond.</span>
                    {''}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row ptb-50">
            <div className="col-md-12 mx-auto">
              <div className="row">
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={VettedOffer} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>Ready-to-Deploy Applications</h3>
                      <p>
                        Minimize your application processing workload and
                        eliminate the need for constant follow-ups.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={PuttingStudent} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>Prioritizing Students</h3>
                      <p>
                        Established by students and dedicated to their needs, we
                        prioritize every step of the student journey.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={FlexibleDelivery} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>Customized Delivery</h3>
                      <p>
                        We adapt to your processes and requirements, eliminating
                        the need for integration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="easy-platform-icon">
                    <div className="main-icon">
                      <img src={WorkWith} />
                    </div>
                    <div className="icon-content-wn">
                      <h3>Collaboration Tailored to You</h3>
                      <p>
                        Our goal is to optimize your time and resources. We
                        collaborate with you to ensure efficient and effective
                        recruitment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="ptb-50 international-education sch-w">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-10 mx-auto">
              <div
                className="freature-six-left text-center wow fadeInUp delay-0-2s animated"
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <div className="section-title mb-30">
                  <h2>
                    Reliable and <span>Trustworthy </span> Insights
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={LocalSupportSchools} />
                    </div>
                    <div className="local-support-content">
                      <h3>Support at the local level</h3>
                      <p>
                        Our team of international recruitment experts is
                        available to provide you with support throughout the
                        entire process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={IndustryEducationSchool} />
                    </div>
                    <div className="local-support-content">
                      <h3>Education in the industry sector</h3>
                      <p>
                        We enhance recruitment partner education by offering
                        guided online courses that bolster the caliber of
                        applications you receive, thereby expanding your
                        industry expertise.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={EventsWebinars} />
                    </div>
                    <div className="local-support-content">
                      <h3>Events and Webinars</h3>
                      <p>
                        Stay informed about the latest trends and regulations
                        with our ongoing schedule of online webinars, training
                        sessions, and events.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-support-box">
                    <div className="local-support-image">
                      <img src={DataInsightsSchool} />
                    </div>
                    <div className="local-support-content">
                      <h3>Data and Insights</h3>
                      <p>
                        Unlock industry-leading data and insights to effectively
                        strategize, grow, and accomplish your objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
}

export default SchoolWebsite;
