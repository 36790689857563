import React from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
} from '../../components/Form/Form';
import * as Yup from 'yup';
import { countries } from '../../domain/countries';
import {
  useUpdateSchoolAttendedInfo,
  useUpdateSchoolAttendedInfoStudent,
} from '../../shared/hooks/UseSchoolAttended';
import { useAllEducationLevels } from '../../shared/hooks/UseEducationLevel';
import { useAllGradings } from '../../shared/hooks/UseGrading';
import SchoolAttendedForm from './SchoolAttendedForm';
import moment from 'moment';
import { SchoolAttendedCard } from './SchoolAttendedCard';
export const SchoolAttended = ({ student_info, hideEdit }) => {
  const { userSchoolAttended, student, userEducationInfo } = student_info;
  const { updateSchoolAttendedInfo } = useUpdateSchoolAttendedInfo();
  const { updateSchoolAttendedInfoStudent } =
    useUpdateSchoolAttendedInfoStudent();
  const { educationLevelData } = useAllEducationLevels();
  const { education_levels } = educationLevelData;
  const { grading_data } = useAllGradings();
  const { gradings } = grading_data;

  const getEducationLevels = ({ rank, education_levels }) => {
    const filteredEducationLevels = education_levels.filter(
      (item) => item.rank == rank
    );
    return filteredEducationLevels;
  };

  const getSchoolAttendedDetails = ({ userSchoolAttended, rank }) => {
    const filteredSchoolAttended = userSchoolAttended.filter(
      (item) => item.level_of_education && item.level_of_education.rank == rank
    );

    if (filteredSchoolAttended && filteredSchoolAttended.length > 0) {
      return filteredSchoolAttended[0];
    } else {
      return null;
    }
  };

  return (
    <>
      <h2>School Attended</h2>
      {/* {userEducationInfo.highest_level_of_education } */}
      {education_levels &&
        userEducationInfo &&
        userEducationInfo.highest_level_of_education &&
        userEducationInfo.highest_level_of_education.rank === 20 && (
          <div>
            <SchoolAttendedCard
              hideEdit={hideEdit}
              label='Doctoral Degree (Phd, M.D., ...)'
              countries={countries}
              gradings={gradings}
              education_levels={
                education_levels &&
                getEducationLevels({
                  rank: 20,
                  education_levels: education_levels,
                })
              }
              student={student}
              school_attended_details={getSchoolAttendedDetails({
                userSchoolAttended,
                rank: 20,
                education_levels,
              })}
            />
          </div>
        )}
      {education_levels &&
        userEducationInfo &&
        userEducationInfo.highest_level_of_education &&
        userEducationInfo.highest_level_of_education.rank >= 19 && (
          <div>
            <SchoolAttendedCard
              hideEdit={hideEdit}
              label='Post Graduate'
              countries={countries}
              gradings={gradings}
              education_levels={
                education_levels &&
                getEducationLevels({
                  rank: 19,
                  education_levels: education_levels,
                })
              }
              student={student}
              school_attended_details={getSchoolAttendedDetails({
                userSchoolAttended,
                rank: 19,
                education_levels,
              })}
              showTranscript={true}
              showVerificationLink={true}
              showCertificate={true}
            />
          </div>
        )}
      {education_levels &&
        userEducationInfo &&
        userEducationInfo.highest_level_of_education &&
        userEducationInfo.highest_level_of_education.rank >= 13 && (
          <div>
            <SchoolAttendedCard
              hideEdit={hideEdit}
              label='Under Graduate'
              countries={countries}
              gradings={gradings}
              education_levels={
                education_levels &&
                getEducationLevels({
                  rank: 13,
                  education_levels: education_levels,
                })
              }
              student={student}
              school_attended_details={getSchoolAttendedDetails({
                userSchoolAttended,
                rank: 13,
                education_levels,
              })}
              showTranscript={true}
              showVerificationLink={true}
              showCertificate={true}
            />
          </div>
        )}
      {education_levels &&
        userEducationInfo &&
        userEducationInfo.highest_level_of_education &&
        userEducationInfo.highest_level_of_education.rank >= 12 && (
          <div>
            <SchoolAttendedCard
              hideEdit={hideEdit}
              label='12th'
              countries={countries}
              gradings={gradings}
              education_levels={
                education_levels &&
                getEducationLevels({
                  rank: 12,
                  education_levels: education_levels,
                })
              }
              student={student}
              school_attended_details={getSchoolAttendedDetails({
                userSchoolAttended,
                rank: 12,
                education_levels,
              })}
              showTranscript={true}
              showVerificationLink={false}
              showCertificate={false}
            />
          </div>
        )}

      {education_levels &&
        userEducationInfo &&
        userEducationInfo.highest_level_of_education &&
        userEducationInfo.highest_level_of_education.rank >= 10 && (
          <div>
            <SchoolAttendedCard
              hideEdit={hideEdit}
              label='10th'
              countries={countries}
              gradings={gradings}
              education_levels={
                education_levels &&
                getEducationLevels({
                  rank: 10,
                  education_levels: education_levels,
                })
              }
              student={student}
              school_attended_details={getSchoolAttendedDetails({
                userSchoolAttended,
                rank: 10,
                education_levels,
              })}
              showTranscript={true}
              showVerificationLink={false}
              showCertificate={false}
            />
          </div>
        )}
    </>
  );
};
