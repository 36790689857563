import moment from 'moment';
import React from 'react';

function StudentRecordTimeline({ application, student }) {
  return (
    <div className='student-record'>
      <div className=' mt-4'>
        <div className='card-body'>
          <ul className='timeline'>
            {application.is_applied && (
              <li className='active'>
                <h6>Application Opened</h6>
                {/* <p className='mb-0 text-muted'>
                  As you progress through the application process, you will see
                  documents provided by Edchimp here. These could come in the
                  form of questions, documents, payments, invoices, and
                  application statuses.
                </p> */}
                <p className=' badge success'>
                  {' '}
                  {application.applied_date &&
                    moment(application.applied_date).format('DD-MMM-YYYY')}{' '}
                </p>
              </li>
            )}
            {application.is_application_fee_paid && (
              <li className='active'>
                <h6>Application Fee Paid</h6>
                <p className=' badge success'>
                  {' '}
                  {application.fee_paid_date &&
                    moment(application.fee_paid_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_application_prepared && (
              <li className='active'>
                <h6>Application Prepared</h6>
                <p className=' badge success'>
                  {' '}
                  {application.application_prepared_date &&
                    moment(application.application_prepared_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_application_submitted && (
              <li className='active'>
                <h6>Application Submitted</h6>
                <p className=' badge success'>
                  {' '}
                  {application.application_submitted_date &&
                    moment(application.application_submitted_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_application_in_offer && (
              <li className='active'>
                <h6>Application In Offer</h6>
                <p className=' badge success'>
                  {' '}
                  {application.application_in_offer_date &&
                    moment(application.application_in_offer_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_application_in_post_offer && (
              <li className='active'>
                <h6>Application Post Decision</h6>
                <p className=' badge success'>
                  {' '}
                  {application.application_in_post_offer_date &&
                    moment(application.application_in_post_offer_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_ucol_loa && (
              <li className='active'>
                <h6>UCOL/LOA</h6>
                <p className=' badge success'>
                  {' '}
                  {application.ucol_loa_date &&
                    moment(application.ucol_loa_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_tuition_fee_paid && (
              <li className='active'>
                <h6>Tuition Fee Paid</h6>
                <p className=' badge success'>
                  {' '}
                  {application.tuition_fee_paid_date &&
                    moment(application.tuition_fee_paid_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_visa_applied && (
              <li className='active'>
                <h6>Visa Applied</h6>
                <p className=' badge success'>
                  {' '}
                  {application.visa_applied_date &&
                    moment(application.visa_applied_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_visa_issued && (
              <li className='active'>
                <h6>Visa Issued</h6>
                <p className=' badge success'>
                  {' '}
                  {application.visa_issued_date &&
                    moment(application.visa_issued_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
            {application.is_enrolled && (
              <li className='active'>
                <h6>Enrolled</h6>
                <p className=' badge success'>
                  {' '}
                  {application.enrolled_date &&
                    moment(application.enrolled_date).format(
                      'DD-MMM-YYYY'
                    )}{' '}
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default StudentRecordTimeline;
