import React from 'react';
import { useParams } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import EditEducationLevelComponent from '../../components/settings/EditEducationLevelComponent';
import { useSingleEducationLevel } from '../../shared/hooks/UseEducationLevel';
function EditEducationLevel() {
  const params = useParams();
  const { data } = useSingleEducationLevel(params.id);
  const { EditEducationLevel, edi } = data;
  return (
    <div className='wrapper'>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Edit Education Level'} />

      {/* page-section */}

      <section className='personal-information ptb-20 '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <EditEducationLevelComponent />
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </div>
  );
}

export default EditEducationLevel;
