import {
  GET_ACADEMIC_PARTNERS_STARTED,
  GET_ACADEMIC_PARTNERS,
  GET_ACADEMIC_PARTNERS_ENDED,
  ADD_ACADEMIC_PARTNER_STARTED,
  ADD_ACADEMIC_PARTNER,
  ADD_ACADEMIC_PARTNER_ENDED,
  EDIT_ACADEMIC_PARTNER_STARTED,
  EDIT_ACADEMIC_PARTNER,
  EDIT_ACADEMIC_PARTNER_ENDED,
  GET_ACADEMIC_PARTNER,
  GET_ACADEMIC_PARTNER_STARTED,
  GET_ACADEMIC_PARTNER_ENDED,
} from '../types/academic_partner_types';

const initialState = {
  academic_partners_loading: true,
  academic_partners: null,
  academic_partner_page: null,
  academic_partners_pages: null,
  total_academic_partners: 0,

  academic_partner: null,
  academic_partner_loading: null,

  add_academic_partner_loading: true,
  edit_academic_partner_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACADEMIC_PARTNERS_STARTED:
      return {
        ...state,
        academic_partners: null,
        academic_partner_pages: null,
        academic_partner_page: null,
        total_academic_partners: 0,
        academic_partners_loading: true,
      };
    case GET_ACADEMIC_PARTNERS:
      return {
        ...state,
        academic_partners: payload.academic_partners,
        academic_partner_pages: payload.pages,
        academic_partner_page: payload.page,
        total_academic_partners: payload.total_academic_partners,
      };
    case GET_ACADEMIC_PARTNERS_ENDED:
      return {
        ...state,
        academic_partners_loading: false,
      };

    case ADD_ACADEMIC_PARTNER_STARTED:
      return {
        ...state,
        academic_partner_message: null,
        add_academic_partner_loading: true,
      };
    case ADD_ACADEMIC_PARTNER:
      return {
        ...state,
        academic_partner_message: payload,
      };
    case ADD_ACADEMIC_PARTNER_ENDED:
      return {
        ...state,
        add_academic_partner_loading: false,
      };
    case GET_ACADEMIC_PARTNER_STARTED:
      return {
        ...state,
        academic_partner: null,
        academic_partner_loading: true,
      };
    case GET_ACADEMIC_PARTNER:
      return {
        ...state,
        academic_partner: payload,
      };
    case GET_ACADEMIC_PARTNER_ENDED:
      return {
        ...state,
        academic_partner_loading: false,
      };
    case EDIT_ACADEMIC_PARTNER_STARTED:
      return {
        ...state,
        academic_partner_message: null,
        edit_academic_partner_loading: true,
      };
    case EDIT_ACADEMIC_PARTNER:
      return {
        ...state,
        academic_partner_message: payload,
      };
    case EDIT_ACADEMIC_PARTNER_ENDED:
      return {
        ...state,
        edit_academic_partner_loading: false,
      };

    default:
      return state;
  }
}
