import React from 'react';

import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectBox, CheckBox } from '../../components/Form/Form';

function FilterForm({ formik, filterData, dropdownOptions }) {
  return (
    <Form>
      <div className='row'>
        <FieldArray
          name='filter_data'
          render={(arrayHelpers) => (
            <div>
              {formik.values.filter_data.map((friend, index) => (
                <div key={index}>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='icon-with-flex-filter'>
                        <div
                          className='icon-box-filters'
                          onClick={() => arrayHelpers.remove()}
                        >
                          <i className='fa fa-minus'></i>
                        </div>
                        <div className='flex-item2'>
                          <SelectBox
                            label='Field'
                            name={`filter_data[${index}].search_field`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `filter_data[${index}].search_field`,
                                e.target.value
                              );
                              const existingData = filterData.filter(
                                (item) => item.field === e.target.value
                              );
                              formik.setFieldValue(
                                `filter_data[${index}].search_component`,
                                existingData[0]
                              );
                            }}
                          >
                            <option value=''></option>
                            {filterData &&
                              filterData.map((item) => {
                                return (
                                  <option value={item.field}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </SelectBox>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='icon-with-flex-filter'>
                        <div className='flex-item2'>
                          <SelectBox
                            label='Code'
                            name={`filter_data[${index}].search_type`}
                          >
                            <option value=''></option>
                            {formik.values.filter_data &&
                              formik.values.filter_data[index] &&
                              formik.values.filter_data[index]
                                .search_component &&
                              formik.values.filter_data[index].search_component
                                .searchType === 'exact' && (
                                <option value='exact'> Equal To </option>
                              )}
                            {formik.values.filter_data &&
                              formik.values.filter_data[index] &&
                              formik.values.filter_data[index]
                                .search_component &&
                              formik.values.filter_data[index].search_component
                                .searchType === 'search' && (
                                <option value='search'> Search </option>
                              )}
                            {formik.values.filter_data &&
                              formik.values.filter_data[index] &&
                              formik.values.filter_data[index]
                                .search_component &&
                              formik.values.filter_data[index].search_component
                                .searchType === 'conditional' && (
                                <>
                                  <option value='eq'> Equal To </option>
                                  <option value='neq'> Not Equal To </option>
                                  <option value='neq'> Greater Than </option>
                                  <option value='neq'> Less Than </option>
                                  <option value='neq'>
                                    Greater Than Equal to {`(>=)`}
                                  </option>
                                  <option value='neq'>
                                    Less Than Equal to {`(<=)`}
                                  </option>
                                </>
                              )}
                          </SelectBox>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='icon-with-flex-filter'>
                        <div className='flex-item2'>
                          {formik.values.filter_data &&
                            formik.values.filter_data[index] &&
                            formik.values.filter_data[index].search_component &&
                            formik.values.filter_data[index].search_component
                              .type === 'string' && (
                              <TextInput
                                label='Search'
                                type={
                                  formik.values.filter_data[index]
                                    .search_component.inputType
                                }
                                name={`filter_data[${index}].search_value`}
                              />
                            )}
                          {formik.values.filter_data &&
                            formik.values.filter_data[index] &&
                            formik.values.filter_data[index].search_component &&
                            formik.values.filter_data[index].search_component
                              .type === 'boolean' && (
                              <SelectBox
                                label='Search'
                                name={`filter_data[${index}].search_value`}
                              >
                                <option value=''></option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </SelectBox>
                            )}
                          {formik.values.filter_data &&
                            formik.values.filter_data[index] &&
                            formik.values.filter_data[index].search_component &&
                            formik.values.filter_data[index].search_component
                              .type === 'select' && (
                              <SelectBox
                                label='Search'
                                name={`filter_data[${index}].search_value`}
                              >
                                <option value=''></option>
                                {formik.values.filter_data[index]
                                  .search_component.options &&
                                  formik.values.filter_data[
                                    index
                                  ].search_component.options.map((item) => {
                                    return (
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                              </SelectBox>
                            )}
                          {formik.values.filter_data &&
                            formik.values.filter_data[index] &&
                            formik.values.filter_data[index].search_component &&
                            formik.values.filter_data[index].search_component
                              .type === 'related' && (
                              <SelectBox
                                label='Search'
                                name={`filter_data[${index}].search_value`}
                              >
                                <option value=''></option>
                                {dropdownOptions &&
                                  formik.values.filter_data[index]
                                    .search_component.field &&
                                  dropdownOptions[
                                    formik.values.filter_data[index]
                                      .search_component.field
                                  ] &&
                                  dropdownOptions[
                                    formik.values.filter_data[index]
                                      .search_component.field
                                  ].map((item) => {
                                    return (
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                              </SelectBox>
                            )}
                        </div>
                        <div
                          className='icon-box-filters ml'
                          onClick={() =>
                            arrayHelpers.push({
                              search_field: '',
                              search_type: '',
                              search_value: '',
                            })
                          }
                        >
                          <i className='fa fa-plus'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        />
      </div>
    </Form>
  );
}

export default FilterForm;
