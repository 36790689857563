import React from 'react';
import { useAlert, useCloseAlert } from '../../shared/hooks/UseAlert';

export const Alerts = (props) => {
  const data = useAlert();
  const { closeAlertFunction } = useCloseAlert();

  return (
    <>
      {data.map((item) => {
        return (
          <div className='toast show active'>
            <div className='toast-content'>
              <i
                className={`fa fa-solid fa-check check bg-${item.alertType}`}
              />
              <div className='message'>
                <span className='text text-1 text-danger'>
                  {item.alertType === 'success' ? 'Success' : 'Error'}
                </span>
                <span className='text text-2'>{item.msg}</span>
              </div>
            </div>
            <i
              className={`fa fa-close  close text-${item.alertType}`}
              onClick={() => closeAlertFunction(item.id)}
            />

            <div className={`progress-${item.alertType} active `} />
          </div>
        );
      })}
    </>
  );
};
