export const GET_TEMPLATES_STARTED = 'GET_TEMPLATES_STARTED';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_ENDED = 'GET_TEMPLATES_ENDED';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';
export const ADD_TEMPLATE_STARTED = 'ADD_TEMPLATE_STARTED';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const ADD_TEMPLATE_ENDED = 'ADD_TEMPLATE_ENDED';
export const ADD_TEMPLATE_ERROR = 'ADD_TEMPLATE_ERROR';
export const EDIT_TEMPLATE_STARTED = 'EDIT_TEMPLATE_STARTED';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
export const EDIT_TEMPLATE_ENDED = 'EDIT_TEMPLATE_ENDED';
export const EDIT_TEMPLATE_ERROR = 'EDIT_TEMPLATE_ERROR';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_TEMPLATE_STARTED = 'GET_TEMPLATE_STARTED';
export const GET_TEMPLATE_ENDED = 'GET_TEMPLATE_ENDED';
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR';
