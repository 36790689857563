import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import DisciplineFormComponent from './DisciplineFormComponent';
import {
  useCreateDiscipline,
  useAllDisciplines,
} from '../../shared/hooks/UseDiscipline';
function AddDisciplineComponent() {
  const { addDisciplineData } = useCreateDiscipline();
  const navigate = useNavigate();

  const { discipline_data } = useAllDisciplines();
  const { disciplines } = discipline_data;

  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Discipline</h1>
        </div>

        <Formik
          initialValues={{
            name: '',
            parent: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addDisciplineData(values);
            navigate('/settings/disciplines');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <DisciplineFormComponent
                dropdown_options={disciplines}
                formik={formik}
              />
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddDisciplineComponent;
