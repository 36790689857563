import React, { useEffect, useState } from 'react';
import * as qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { City, Country, State } from 'country-state-city';
import { countries } from '../../domain/countries';
import { ApplicationStatusEnum } from '../../domain/ApplicationStatusEnum';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useAllSchoolsFilters } from '../../shared/hooks/UseSchool';
import { useAllAgentsFilters } from '../../shared/hooks/UseAgent';
import { useAllProgramsFilters } from '../../shared/hooks/UseProgram';
import { useAllStudentsFilters } from '../../shared/hooks/UseStudent';
import { useAllCrmUsersFilter } from '../../shared/hooks/UseCrmUser';
import { SelectBox } from '../Form/Form';
function ApplicationFilter() {
  const navigate = useNavigate();
  const location = useLocation();
  const { schools } = useAllSchoolsFilters();
  const { agents_data, setAgentSearchTerm } = useAllAgentsFilters();
  const { agents } = agents_data;
  const { programs_data, setProgramSearchTerm } = useAllProgramsFilters();
  const { programs } = programs_data;
  const { students_data, setStudentSearchTerm } = useAllStudentsFilters();
  const { students } = students_data;
  const { crm_users_data, setCRMUserSearchTerm } = useAllCrmUsersFilter();
  const { crm_users } = crm_users_data;
  const [dropdown_options, setDropdown_options] = useState(null);
  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [openFilter, setOpenFilter] = useState(false);

  const [status, setStatus] = useState(
    queryParams && queryParams.status ? queryParams.status : ''
  );
  const [student, setStudent] = useState(
    queryParams && queryParams.student ? queryParams.student : ''
  );
  const [agent, setAgent] = useState(
    queryParams && queryParams.agent ? queryParams.agent : ''
  );
  const [school, setSchool] = useState(
    queryParams && queryParams.school ? queryParams.school : ''
  );
  const [program, setProgram] = useState(
    queryParams && queryParams.program ? queryParams.program : ''
  );
  const [applicationId, setApplicationID] = useState(
    queryParams && queryParams.application ? queryParams.application : ''
  );
  const [isClosedApplication, setIsClosedApplication] = useState(
    queryParams && queryParams.isClosedApplication
      ? queryParams.isClosedApplication
      : ''
  );
  const [crmUser, setCRMUser] = useState(
    queryParams && queryParams.crm_user ? queryParams.crm_user : ''
  );

  const [country, setCountry] = useState(
    queryParams && queryParams.country ? queryParams.country : ''
  );
  const [country_applied_from, setCountry_applied_from] = useState(
    queryParams && queryParams.country_applied_from
      ? queryParams.country_applied_from
      : ''
  );

  const handleSidebarChange = ({ name, value }) => {
    queryParams[name] = value;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    navigate(`${location.pathname}?${query}`);
  };

  useEffect(() => {
    if (schools) {
      const mappedSchoolData = schools.map((school) => {
        return {
          label: school.name,
          value: school._id,
        };
      });
      setDropdown_options((prevState) => ({
        ...prevState,
        school: mappedSchoolData,
      }));
    }
    if (crm_users) {
      const mappedSchoolData = crm_users.map((school) => {
        return {
          label: `${school.first_name} ${school.last_name}`,
          value: school._id,
        };
      });
      setDropdown_options((prevState) => ({
        ...prevState,
        crm_user: mappedSchoolData,
      }));
    }
    if (students) {
      const mappedProgramData = students.map((student) => {
        return {
          label: `${student.first_name} ${student.last_name} (${student.user_id})`,
          value: student._id,
        };
      });

      setDropdown_options((prevState) => ({
        ...prevState,
        student: mappedProgramData,
      }));
    }
    if (agents) {
      const mappedSchoolData = agents.map((agent) => {
        return {
          label:
            agent && agent.agentData && agent.agentData.agentInfo
              ? agent.agentData.agentInfo.company_name
              : agent.first_name,
          value: agent._id,
        };
      });
      setDropdown_options((prevState) => ({
        ...prevState,
        agent: mappedSchoolData,
      }));
    }
  }, [schools, crm_users, students, agents]);

  return (
    <section className='mb-2'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex'>
              <button
                className='filter-btn'
                onClick={() => setOpenFilter(!openFilter)}
              >
                <i className='fa fa-filter'></i> Filter
              </button>

              <button
                className='filter-btn'
                onClick={() => {
                  setIsClosedApplication(false);
                  setSchool(null);
                  setAgent(null);
                  setCRMUser(null);
                  setStudent(null);
                  setStatus(null);
                  setCountry(null);
                  setCountry_applied_from(null);

                  navigate(`${location.pathname}`);
                }}
              >
                <i className='fa fa-close'></i> Clear
              </button>
            </div>
          </div>
        </div>
        {openFilter && (
          <div className='row'>
            <div className='col-md-2'>
              <label> Status </label>
              <select
                name='status'
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  handleSidebarChange({
                    name: 'status',
                    value: e.target.value,
                  });
                }}
                className='form-control'
              >
                <option value=''></option>
                <option value={ApplicationStatusEnum.APPLIED}>
                  {ApplicationStatusEnum.APPLIED}
                </option>
                <option value={ApplicationStatusEnum.APPLICATION_FEE_PAID}>
                  {ApplicationStatusEnum.APPLICATION_FEE_PAID}
                </option>
                <option value={ApplicationStatusEnum.APPLICATION_PREPARED}>
                  {ApplicationStatusEnum.APPLICATION_PREPARED}
                </option>
                <option value={ApplicationStatusEnum.SUBMISSION}>
                  {ApplicationStatusEnum.SUBMISSION}
                </option>
                <option value={ApplicationStatusEnum.OFFER}>
                  {ApplicationStatusEnum.OFFER}
                </option>
                <option value={ApplicationStatusEnum.POST_OFFER}>
                  {ApplicationStatusEnum.POST_OFFER}
                </option>
                <option value={ApplicationStatusEnum.UCOL_LOA}>
                  {ApplicationStatusEnum.UCOL_LOA}
                </option>
                <option value={ApplicationStatusEnum.TUITION_FEE_PAID}>
                  {ApplicationStatusEnum.TUITION_FEE_PAID}
                </option>
                <option value={ApplicationStatusEnum.IS_VISA_APPLIED}>
                  {ApplicationStatusEnum.IS_VISA_APPLIED}
                </option>
                <option value={ApplicationStatusEnum.VISA_ISSUED}>
                  {ApplicationStatusEnum.VISA_ISSUED}
                </option>
                <option value={ApplicationStatusEnum.ENROLLED}>
                  {ApplicationStatusEnum.ENROLLED}
                </option>
              </select>
            </div>
            <div className='col-md-2'>
              <div className='d-flex justify-content-between'>
                <div>
                  <label> School </label>
                </div>
                <div>
                  <div
                    onClick={() => setSchool('')}
                    className='p-2'
                    style={{ cursor: 'pointer' }}
                  >
                    <i className='fa fa-close'></i>
                  </div>
                </div>
              </div>
              <Select
                value={
                  dropdown_options &&
                  dropdown_options.school &&
                  dropdown_options.school.filter(
                    (option) => option.value === school
                  )
                }
                options={dropdown_options && dropdown_options['school']}
                onChange={(e) => {
                  if (e) {
                    setSchool(e.value);
                    handleSidebarChange({
                      name: 'school',
                      value: e.value,
                    });
                  }
                }}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            <div className='col-md-2'>
              <label> Student </label>

              <Select
                value={
                  dropdown_options &&
                  dropdown_options.student &&
                  dropdown_options.student.filter(
                    (option) => option.value === student
                  )
                }
                options={dropdown_options && dropdown_options['student']}
                onChange={(e) => {
                  if (e) {
                    setStudent(e.value);
                    handleSidebarChange({
                      name: 'student',
                      value: e.value,
                    });
                  }
                }}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>

            <div className='col-md-2'>
              <label> Country </label>
              {/* <AsyncSelect
                loadOptions={(inputValue, callback) =>
                  loadOptions(inputValue, callback, 'program')
                }
                isClearable={true}
                defaultOptions={dropdown_options && dropdown_options['program']}
                onChange={(e) => {
                  if (e) {
                    setProgram(e.value);
                    handleSidebarChange({
                      name: 'program',
                      value: e.value,
                    });
                  }
                }}
              /> */}
              <select
                name='country'
                onChange={(e) => {
                  setCountry(e.value);
                  handleSidebarChange({
                    name: 'country',
                    value: e.target.value,
                  });
                }}
                value={country}
                className='form-control'
              >
                <option value=''></option>
                {countries &&
                  countries.map((e) => {
                    return <option value={e.name}>{e.name}</option>;
                  })}
              </select>
            </div>
            <div className='col-md-2'>
              <label> Country Applied from </label>

              <select
                name='country_applied_from'
                onChange={(e) => {
                  setCountry_applied_from(e.value);
                  handleSidebarChange({
                    name: 'country_applied_from',
                    value: e.target.value,
                  });
                }}
                className='form-control'
                value={country_applied_from}
              >
                <option value=''></option>
                {countries &&
                  countries.map((e) => {
                    return <option value={e.name}>{e.name}</option>;
                  })}
              </select>
            </div>
            <div className='col-md-2'>
              <label> Agent </label>
              <Select
                value={
                  dropdown_options &&
                  dropdown_options.agent &&
                  dropdown_options.agent.filter(
                    (option) => option.value === agent
                  )
                }
                options={dropdown_options && dropdown_options['agent']}
                onChange={(e) => {
                  if (e) {
                    setAgent(e.value);
                    handleSidebarChange({
                      name: 'agent',
                      value: e.value,
                    });
                  }
                }}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            <div className='col-md-2'>
              <label> CRM User </label>
              <Select
                value={
                  dropdown_options &&
                  dropdown_options.crm_user &&
                  dropdown_options.crm_user.filter(
                    (option) => option.value === crmUser
                  )
                }
                options={dropdown_options && dropdown_options['crm_user']}
                onChange={(e) => {
                  if (e) {
                    setCRMUser(e.value);
                    handleSidebarChange({
                      name: 'crm_user',
                      value: e.value,
                    });
                  }
                }}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            <div className='col-md-2'>
              <label> Application ID </label>
              <input
                type='text'
                className='form-control'
                value={applicationId}
                onChange={(e) => {
                  setApplicationID(e.target.value);
                  handleSidebarChange({
                    name: 'application',
                    value: e.target.value,
                  });
                }}
              />
            </div>
            <div className='col-md-2'>
              <label> Closed Application </label>
              <select
                className='form-control'
                value={isClosedApplication}
                onChange={(e) => {
                  setIsClosedApplication(e.target.value);
                  handleSidebarChange({
                    name: 'isClosedApplication',
                    value: e.target.value,
                  });
                }}
              >
                <option>Select </option>
                <option value={true}>YES</option>
                <option value={false}> NO </option>
              </select>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ApplicationFilter;
