import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import ViewEducationLevelsComponent from '../../components/settings/ViewEducationLevelsComponent';
import { links } from '../../domain/links.enum';
import { useSingleEducationLevel } from '../../shared/hooks/UseEducationLevel';

function ViewEducationLevel() {
  const params = useParams();
  const { data } = useSingleEducationLevel(params.id);
  const { education_level, education_level_loading } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={education_level && education_level.name} />
      {education_level && (
        <section className="profile-main ptb-20">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="main-sec-heading">
                  <h1>{education_level.name}</h1>
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="add-student-btn">
                  <Link
                    to={`/settings/education-levels/${education_level.id}/edit`}
                    className="btn primary-btn-h "
                  >
                    Edit Education Level
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {education_level && (
        <section className="main-school-single-view ptb-20 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ViewEducationLevelsComponent
                  education_level={education_level}
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <AuthFooter />
    </div>
  );
}

export default ViewEducationLevel;
