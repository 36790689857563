import React from 'react';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { links } from '../../domain/links.enum';

function Settings() {
  return (
    <>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Settings'} />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="school-and-programs ">
                <ul>
                  <li to={links.educationLevel}>
                    <Link to={links.educationLevel} className="active-tab">
                      <i className="fa fa-university"></i>
                      Education Levels
                    </Link>
                  </li>
                  <li>
                    <Link to={links.agents}>
                      <i className="fa fa-user"></i> Agents
                    </Link>
                  </li>
                  <li>
                    <Link to={links.programs}>
                      <i className="fa fa-users"></i> CRM Users
                    </Link>
                  </li>
                  <li>
                    <Link to={links.programs}>
                      <i className="fa fa-lock"></i> Roles & Permissions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AuthFooter />
    </>
  );
}

export default Settings;
