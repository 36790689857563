import {
  GET_DISCIPLINES_STARTED,
  GET_DISCIPLINES,
  GET_DISCIPLINES_ENDED,
  ADD_DISCIPLINE_STARTED,
  ADD_DISCIPLINE,
  ADD_DISCIPLINE_ENDED,
  EDIT_DISCIPLINE_STARTED,
  EDIT_DISCIPLINE,
  EDIT_DISCIPLINE_ENDED,
  GET_DISCIPLINE,
  GET_DISCIPLINE_STARTED,
  GET_DISCIPLINE_ENDED,
} from '../types/discipline_types.js';
import * as qs from 'qs';
import { api } from '../../domain/api.js';

import { handleError } from '../../shared/handleError.js';
import { setAlert } from './alert_action.js';

//addDiscipline
export const addDiscipline = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_DISCIPLINE_STARTED,
    });
    const { data } = await api.post(`/disciplines/add`, formData);
    dispatch({
      type: ADD_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: ADD_DISCIPLINE_ENDED,
    });
    dispatch(setAlert('Discipline added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_DISCIPLINE_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getDisciplines =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_DISCIPLINES_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/disciplines?&${query}`);

      dispatch({
        type: GET_DISCIPLINES,
        payload: data,
      });
      dispatch({
        type: GET_DISCIPLINES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_DISCIPLINES_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const filterDisciplines =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_DISCIPLINES_STARTED,
      });

      const { data } = await api.get(`/disciplines/filter`);

      dispatch({
        type: GET_DISCIPLINES,
        payload: data,
      });
      dispatch({
        type: GET_DISCIPLINES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_DISCIPLINES_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const getDiscipline = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DISCIPLINE_STARTED,
    });
    const { data } = await api.get(`/disciplines/${id}`);

    dispatch({
      type: GET_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getDisciplineBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DISCIPLINE_STARTED,
    });
    const { data } = await api.get(`/disciplines/slug/${slug}`);

    dispatch({
      type: GET_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editDiscipline = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_DISCIPLINE_STARTED,
    });
    const { data } = await api.put(`/disciplines/${id}`, formData);
    dispatch({
      type: EDIT_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: EDIT_DISCIPLINE_ENDED,
    });
    dispatch(setAlert('Discipline Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_DISCIPLINE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftDiscipline = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_DISCIPLINE_STARTED,
    });
    dispatch({
      type: GET_DISCIPLINE_STARTED,
    });
    const { data } = await api.put(`/disciplines/${id}/draft`);
    dispatch({
      type: EDIT_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: GET_DISCIPLINE,
      payload: data,
    });

    dispatch({
      type: EDIT_DISCIPLINE_ENDED,
    });
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_DISCIPLINE_ENDED,
    });
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateDiscipline = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_DISCIPLINE_STARTED,
    });
    const { data } = await api.put(`/disciplines/${id}/active`);
    dispatch({
      type: EDIT_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: GET_DISCIPLINE_STARTED,
    });
    dispatch({
      type: EDIT_DISCIPLINE_ENDED,
    });
    dispatch({
      type: GET_DISCIPLINE,
      payload: data,
    });
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });
    dispatch(setAlert('Discipline Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_DISCIPLINE_ENDED,
    });
    dispatch({
      type: GET_DISCIPLINE_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteDiscipline = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/disciplines/${id}`);
    dispatch(setAlert('Discipline Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
