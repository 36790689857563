import React from 'react';

import { Formik, Form, FieldArray } from 'formik';
import {
  TextInput,
  SelectBox,
  HTMLEditor,
  CheckBox,
  FileUpload,
  GalleryUpload,
} from '../../components/Form/Form';
import { ApplicationRequirement } from '../../domain/applicationRequirementEnum';

function AddApplicationRequirementForm({ formik }) {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-6'>
          <SelectBox label='Requirement Stage' name='requirement_stage'>
            <option value=''>Select</option>
            {ApplicationRequirement &&
              ApplicationRequirement.map((e) => {
                return <option value={e}>{e}</option>;
              })}
          </SelectBox>
        </div>
        <div className='col-md-6'>
          <CheckBox name='is_required'>IsRequired</CheckBox>
        </div>
        <div className='col-md-12'>
          <TextInput label='Title' name='title' />
        </div>
        <div className='mb-3 rich'>
          <HTMLEditor
            title='Content'
            item='content'
            formik={formik}
            col={12}
            value={formik.values.content}
          />
        </div>
        <div className='col-md-12'>
          <SelectBox label='Requirement type' name='requirement_type'>
            <option value=''>Select</option>
            <option value='document_upload'>Document Upload</option>
            <option value='document_download_reupload'>
              Document Download & ReUpload
            </option>
            <option value='questionnaire'>Questionnaire</option>
          </SelectBox>
        </div>
        {formik.values.requirement_type === 'document_download_reupload' && (
          <div className='col-md-12'>
            <label>Document Upload</label>
            <div className='drop_box'>
              <GalleryUpload
                title={'Document'}
                item='document'
                formik={formik}
                value={formik.values.document}
                hideView={true}
              />
            </div>
          </div>
        )}
        {formik.values.requirement_type === 'questionnaire' && (
          <>
            <div className='col-md-12'>
              <div className='label-heading'>
                <h5>Question</h5>
                <FieldArray
                  name='questions'
                  render={(arrayHelpers) => (
                    <div>
                      {formik.values.questions &&
                        formik.values.questions.map((friend, index) => (
                          <div key={index}>
                            {/* <input
                        name={`todos[${index}].name`}
                        value={formik.values.todos[index].name}
                        onChange={formik.handleChange}
                      /> */}
                            <div className='d-flex justify-content-between'>
                              <div className='label-featu'>
                                Questions {index + 1}
                              </div>
                              <div>
                                <a onClick={() => arrayHelpers.remove(index)}>
                                  <i className='fa fa-close'></i>
                                </a>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <TextInput
                                label='Title'
                                name={`questions[${index}].title`}
                              />
                            </div>
                            <div className='col-md-12'>
                              <SelectBox
                                label='Question type'
                                name={`questions[${index}].question_type`}
                              >
                                <option value=''>Select</option>
                                <option value='simple'>Simple</option>
                                <option value='big_answer'>Big Answer</option>
                                <option value='multiple_choice'>
                                  Multiple Choice
                                </option>
                                <option value='yes_no'>Yes Or No</option>
                              </SelectBox>
                            </div>
                            {formik.values.questions[index].question_type ===
                              'multiple_choice' && (
                              <FieldArray
                                name={`questions[${index}].multiple_choice`}
                                render={(questionHelpers) => (
                                  <div>
                                    {formik.values.questions &&
                                      formik.values.questions[index] &&
                                      formik.values.questions[index]
                                        .multiple_choice &&
                                      formik.values.questions[
                                        index
                                      ].multiple_choice.map(
                                        (friend, index2) => (
                                          <div key={index2}>
                                            <div className='d-flex justify-content-between'>
                                              <div className='label-featu'>
                                                Answer {index2 + 1}
                                              </div>
                                              <div>
                                                <a
                                                  onClick={() =>
                                                    questionHelpers.remove(
                                                      index2
                                                    )
                                                  }
                                                >
                                                  <i className='fa fa-close'></i>
                                                </a>
                                              </div>
                                            </div>
                                            <div className='col-md-12'>
                                              <TextInput
                                                label='Title'
                                                name={`questions[${index}].multiple_choice[${index2}].answer_choice`}
                                              />
                                            </div>
                                          </div>
                                        )
                                      )}
                                    <div
                                      style={{
                                        border: '1px solid #f1f1f1',
                                        marginBottom: '10px',
                                      }}
                                    >
                                      <button
                                        type='button'
                                        className='btn primary-btn'
                                        onClick={() =>
                                          questionHelpers.push({
                                            answer_choice: '',
                                          })
                                        }
                                      >
                                        Add Option
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            )}
                          </div>
                        ))}
                      <div style={{ border: '1px solid #f1f1f1' }}>
                        <button
                          type='button'
                          className='btn primary-btn'
                          onClick={() => arrayHelpers.push({ name: '' })}
                        >
                          Add Question
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            {formik.values.question_type === 'multiple_choice' && (
              <>
                <div className='col-md-12'>
                  <TextInput label='Option1' name='option1' />
                </div>
                <div className='col-md-12'>
                  <TextInput label='Option2' name='option2' />
                </div>
                <div className='col-md-12'>
                  <TextInput label='Option3' name='option3' />
                </div>
                <div className='col-md-12'>
                  <TextInput label='Option4' name='option4' />
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className='create-button'>
        <button type='submit' className='btn primary-btn'>
          Save & Continue
        </button>
      </div>
    </Form>
  );
}

export default AddApplicationRequirementForm;
