import React from 'react';
import { AuthProfileTabs } from '../../components/auth_common/AuthProfileTabs';

import SchoolComponent from '../../components/school/SchoolComponent';

function Schools() {
  return (
    <>
      <SchoolComponent />
    </>
  );
}

export default Schools;
