import { useDispatch } from 'react-redux';
import { addVisaAndServicePermitInfo } from '../../store/actions/visa_and_study_action';
import { updatePersonalInfo } from '../../store/actions/personal_info_action';

export const useUpdateGeneralInfo = () => {
  const dispatch = useDispatch();

  const updateGeneralInfo = async (id, values) => {
    dispatch(updatePersonalInfo(id, values));
  };

  return {
    updateGeneralInfo,
  };
};
