import React from 'react';

function ViewEducationLevelsComponent({ education_level }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="heading-single-education">
          <h2>Name</h2>
          <span>{education_level.name}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="heading-single-education">
          <h2>Family</h2>
          <span>{education_level.family}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="heading-single-education">
          <h2>Rank</h2>
          <span>{education_level.rank}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="heading-single-education">
          <h2>Type</h2>
          <span>{education_level.type}</span>
        </div>
      </div>
    </div>
    // <table className="table">
    //   <thead className="thead-dark">
    //     <tr>
    //       <th scope="col">Family</th>
    //       <th scope="col">Last</th>
    //     </tr>
    //     <tr>
    //       <th scope="col">Name</th>
    //       <th scope="col">{education_level.name}</th>
    //     </tr>
    //     <tr>
    //       <th scope="col">Rank</th>
    //       <th scope="col">120th</th>
    //     </tr>
    //     <tr>
    //       <th scope="col">Type</th>
    //       <th scope="col">Last</th>
    //     </tr>
    //   </thead>
    // </table>
  );
}

export default ViewEducationLevelsComponent;
