import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { URI } from '../../domain/constant';
import { links } from '../../domain/links.enum';
import {
  useChangeStatusSchool,
  useDeleteSchool,
} from '../../shared/hooks/UseSchool';
import { useNavigate } from 'react-router-dom';
import PermissionComponent from '../auth/PermissionComponent';
import { DummySchool } from '../images';

function SchoolHeader({ school, program, hideBreadCrumb }) {
  const navigate = useNavigate();
  const { deleteSchoolFunction } = useDeleteSchool();
  const { draftSchoolData, activateSchoolData } = useChangeStatusSchool();
  const [checkDeleteSchool, setCheckDeleteSchool] = useState(false);
  const deleteSchoolBtnClicked = async () => {
    await deleteSchoolFunction(school.id);
    navigate('/schools');
  };
  return (
    <section className='single_school_profile ptb-20 '>
      <div className='container-fluid'>
        <div className='row'>
          {!hideBreadCrumb && (
            <div className='col-md-12'>
              <div className='add-student-btn2'>
                <PermissionComponent
                  name={'school'}
                  permission={'update'}
                  hideOnStudent={true}
                  hideOnAgent={true}
                >
                  {school.status === 'Active' ? (
                    <a
                      className='badge badge-draft'
                      onClick={() => draftSchoolData(school._id)}
                    >
                      Move to Draft
                    </a>
                  ) : (
                    <a
                      className='badge badge-publish'
                      onClick={() => activateSchoolData(school._id)}
                    >
                      Publish
                    </a>
                  )}
                </PermissionComponent>
                <PermissionComponent
                  name={'school'}
                  permission={'create'}
                  hideOnStudent={true}
                  hideOnAgent={true}
                >
                  <Link
                    to={`/schools/${school.id}/duplicate`}
                    className='badge badge-edit'
                  >
                    Duplicate School
                  </Link>
                </PermissionComponent>
                <PermissionComponent
                  name={'school'}
                  permission={'update'}
                  hideOnStudent={true}
                  hideOnAgent={true}
                >
                  <Link
                    to={`/schools/${school.id}/edit`}
                    className='badge badge-edit'
                  >
                    Edit School
                  </Link>
                </PermissionComponent>
                <PermissionComponent
                  name={'school'}
                  permission={'delete'}
                  hideOnStudent={true}
                  hideOnAgent={true}
                >
                  <a
                    className='badge badge-trash'
                    onClick={() => setCheckDeleteSchool(true)}
                  >
                    <i className='fa fa-trash'></i>
                  </a>
                </PermissionComponent>
              </div>
            </div>
          )}

          <div className='col-md-12'>
            <div className='update-flex'>
              <div className='school-profile-name-flex'>
                <div className='school-user-circle'>
                  <img
                    src={school.logo ? `${URI}${school.logo}` : DummySchool}
                  />
                </div>
                <div className='school-information'>
                  <h2>{school.name}</h2>
                  <ul>
                    <li>
                      <i className='fa fa-map-marker'></i>
                      <a>
                        {school.address}, {school.city}, {school.state}
                        {school.postal_code}
                      </a>
                    </li>
                    <li>
                      <i className='fa fa-phone'></i>
                      <a>
                        +{school.dial_code} {school.phone}
                      </a>
                    </li>
                    <li>
                      <i className='fa fa-envelope-o'></i>
                      <a>{school.email}</a>
                    </li>
                    {school.facebook && (
                      <li>
                        <a href={school.facebook} target='_blank'>
                          <i className='fa fa-facebook'></i>
                        </a>
                      </li>
                    )}
                    {school.twitter && (
                      <li>
                        <a href={school.twitter} target='_blank'>
                          <i className='fa fa-twitter'></i>
                        </a>
                      </li>
                    )}
                    {school.linkedin && (
                      <li>
                        <a href={school.linkedin}>
                          <i className='fa fa-linkedin'></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {checkDeleteSchool && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setCheckDeleteSchool(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>
                    Do you really want to delete this record? This process
                    cannot be undone.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setCheckDeleteSchool(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteSchoolBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default SchoolHeader;
