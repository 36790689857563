import React from 'react';
import { Link } from 'react-router-dom';

import PermissionComponent from '../../components/auth/PermissionComponent';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';

import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';

import PayoutComponent from '../../components/agents/PayoutComponent';

function Payout() {
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Payout'} />

      <PayoutComponent />
      <AuthFooter />
    </div>
  );
}

export default Payout;
