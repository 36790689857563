import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import * as qs from 'qs';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
function SidebarFilter({
  SIDEBAR_OPTIONS,
  dropdown_options,
  loadOptions,
  col,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFields, setSelectedFields] = useState({});
  const handleSidebarChange = ({ name, value, type = 'search', condition }) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (type == 'conditional') {
      if (queryParams[type]) {
        if (queryParams[type][name]) {
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        } else {
          queryParams[type][name] = {};
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        }
      } else {
        queryParams[type] = {};
        queryParams[type][name] = {};
        if (value && value.length > 0) {
          queryParams[type][name][condition ? condition : '$gte'] = value;
        } else {
          delete queryParams[type][name][condition ? condition : '$gte'];
        }
      }
    } else {
      if (queryParams[type]) {
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      } else {
        queryParams[type] = {};
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      }
    }

    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    navigate(`${location.pathname}?${query}`);
  };

  const removeSidebarParams = (item) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (item.search_type) {
      queryParams[item.search_type] &&
        queryParams[item.search_type][item.field] &&
        delete queryParams[item.search_type][item.field];
    } else {
      queryParams['search'] &&
        queryParams['search'][item.field] &&
        delete queryParams['search'][item.field];
    }
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    navigate(`${location.pathname}?${query}`);
  };

  const getSidebarValue = (item) => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // return urlParams.get(name);
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (item.search_type) {
      const searchValue =
        item.search_type == 'conditional'
          ? queryParams[item.search_type] &&
            queryParams[item.search_type][item.field] &&
            queryParams[item.search_type][item.field][item.condition]
          : queryParams[item.search_type]
          ? queryParams[item.search_type][item.field]
          : '';
      return searchValue;
    }
  };

  return (
    <div className={col ? `col-lg-${col}` : 'col-lg-2'}>
      <div
        className='card '
        style={{
          boxShadow: 'rgb(227 233 243) 0px 4px 22px',
          position: 'sticky',
          top: '100px',
        }}
      >
        <div
          className='card-header'
          style={{ background: '#14c6ef', color: '#fff' }}
        >
          <h5 className='card-title'>
            {' '}
            FILTER
            {/* <button
              onClick={() => {
                setSelectedFields({});
                navigate(`${location.pathname}`);
              }}
              style={{
                float: 'right',
                background: '#b02a37',
                padding: '5px 5px',
                color: '#fff',
                fontSize: '14px',
              }}
            >
              X
            </button> */}
          </h5>
        </div>
        <div className='card-body sidebar-component'>
          {SIDEBAR_OPTIONS &&
            SIDEBAR_OPTIONS.map((item) => {
              return (
                <div>
                  <div className='topping'>
                    {/* <input
                      type='checkbox'
                      id={item.id}
                      checked={selectedFields && selectedFields[item.id]}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const newFields = selectedFields;
                          newFields[item.id] = true;

                          setSelectedFields({
                            ...selectedFields,
                            [item.id]: true,
                          });
                        } else {
                          const newFields = selectedFields;
                          newFields[item.id] = false;
                          setSelectedFields({
                            ...selectedFields,
                            [item.id]: false,
                          });
                          removeSidebarParams(item);
                        }
                      }}
                    /> */}
                    <label style={{ paddingLeft: '5px' }} htmlFor={item.id}>
                      {item.label}
                    </label>
                  </div>
                  {item.type == 'string' && (
                    <div>
                      <input
                        type={item.inputType ? item.inputType : 'text'}
                        className='form-control'
                        onChange={(e) => {
                          handleSidebarChange({
                            name: item.field,
                            value: e.target.value,
                            type: item.search_type
                              ? item.search_type
                              : 'search',
                            condition: item.condition,
                          });
                        }}
                        value={
                          getSidebarValue(item) ? getSidebarValue(item) : ''
                        }
                      />
                    </div>
                  )}
                  {item.type == 'select' && (
                    <div>
                      <select
                        className='form-control'
                        onChange={(e) => {
                          handleSidebarChange({
                            name: item.field,
                            value: e.target.value,
                            type: 'exact',
                            condition: item.condition,
                          });
                        }}
                        value={
                          getSidebarValue(item) ? getSidebarValue(item) : ''
                        }
                      >
                        <option value=''>-</option>
                        {item.options &&
                          item.options.map((option) => {
                            return (
                              <option value={option} key={`${item}-option`}>
                                {option}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {item.type == 'related' && (
                    <div>
                      <Select
                        value={
                          dropdown_options &&
                          dropdown_options[item.field] &&
                          dropdown_options[item.field].filter(
                            (option) => option.value === getSidebarValue(item)
                          )
                        }
                        // defaultValue={school}
                        isClearable={false}
                        options={
                          dropdown_options && dropdown_options[item.field]
                        }
                        onChange={(e) => {
                          if (e) {
                            handleSidebarChange({
                              name: item.field,
                              value: e.value,
                              type: 'exact',
                              condition: item.condition,
                            });
                          }
                        }}
                      />
                      {/* <select
                          className="form-control"
                          onChange={(e) => {
                            handleSidebarChange({
                              name: item.field,
                              value: e.target.value,
                              type: "exact",
                              condition: item.condition,
                            });
                          }}
                          value={
                            getSidebarValue(item) ? getSidebarValue(item) : ""
                          }
                        >
                          <option value=""></option>

                          {dropdown_options &&
                            dropdown_options[item.field] &&
                            dropdown_options[item.field].map((option) => {
                              return (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              );
                            })}
                        </select> */}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className='card-footer'>
          <a
            style={{ color: '#14c6ef' }}
            onClick={() => {
              setSelectedFields({});
              navigate(`${location.pathname}`);
            }}
          >
            X Clear Filter
          </a>
        </div>
      </div>
    </div>
  );
}

export default SidebarFilter;
