import React, { useEffect, useState } from 'react';
import Tooltip from '../Tooltip';
import moment from 'moment';
import {
  MissingApplication,
  NotApprovedApplication,
  PrepaymentApprovedApplication,
  ReviewApplication,
  missingApplication,
} from '../images';
import { URI, getDocumentName } from '../../domain/constant';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FileUpload, GalleryUpload } from '../Form/Form';
import {
  useAnswerApplicationRequirement,
  useChangeStageAnswerApplicationRequirement,
} from '../../shared/hooks/UseApplication';
import { ApplicationAnswerStage } from '../../domain/ApplicationStagesEnum';
import PermissionComponent from '../auth/PermissionComponent';
import { ApplicationStatusSequence } from '../../domain/ApplicationStatusEnum';
import { ApplicationRequirement } from '../../domain/applicationRequirementEnum';

function PrePaymentCard({ application, applicationRequirements }) {
  const { answerApplicationRequirementSubmit } =
    useAnswerApplicationRequirement();
  const { changeStageApplicationRequirementSubmit } =
    useChangeStageAnswerApplicationRequirement();
  const [show, setShow] = useState(true);
  const [seemore, setSeemore] = useState(false);
  const saveDocument = (requirement) => {};

  return (
    <div className='box-show-and-hide'>
      {/* Implement Application requirement stage   */}

      {applicationRequirements &&
        applicationRequirements.map((item) => {
          const CURRENT_STATUS = ApplicationStatusSequence.indexOf(
            application.application_current_status
          );
          // if (item.requirement_stage === 'Pre-Payment') {
          const REQUIREMENT_STAGE = ApplicationRequirement.indexOf(
            item.requirement_stage
          );
          console.log(
            'CURRENT_STATUS',
            CURRENT_STATUS,
            REQUIREMENT_STAGE,
            'REQUIREMENT_STAGE'
          );

          if (REQUIREMENT_STAGE <= CURRENT_STATUS) {
            return (
              <div
                className='pre-payment-card-required'
                style={{ display: show ? 'block' : 'none' }}
              >
                <div className='pre-payment-heading'>
                  <h4> {item.requirement_stage} </h4>
                  <p>{moment(item.createdAt).format('DD-MMM-YYYY')}</p>
                </div>
                <div
                  className={
                    seemore
                      ? 'icon-with-flex-pre-payment  '
                      : 'icon-with-flex-pre-payment dro-height '
                  }
                >
                  {item.is_completed ? (
                    <div>
                      <div className='text-center compl'>
                        <i className='fa fa-check'></i>
                        <span className='badge '>Filled</span>
                      </div>
                      <div>
                        {item.answer_stage ===
                          ApplicationAnswerStage.ANSWERED && (
                          <>
                            <span className='text-warning'>
                              Waiting for Approval
                            </span>
                            <PermissionComponent
                              name={'application'}
                              permission={'update'}
                              hideOnAgent
                              hideOnStudent
                            >
                              <div>
                                <a
                                  className='btn btn-sm btn-success'
                                  onClick={() =>
                                    changeStageApplicationRequirementSubmit(
                                      item._id,
                                      { answer_stage: 'APPROVED' }
                                    )
                                  }
                                >
                                  <i className='fa fa-check'> </i> Approve
                                </a>
                                <a
                                  className='btn btn-sm btn-danger ml-2'
                                  onClick={() =>
                                    changeStageApplicationRequirementSubmit(
                                      item._id,
                                      { answer_stage: 'REJECTED' }
                                    )
                                  }
                                >
                                  <i className='fa fa-close'> </i> Reject
                                </a>
                              </div>
                            </PermissionComponent>
                          </>
                        )}
                        {item.answer_stage ===
                          ApplicationAnswerStage.REJECTED && (
                          <>
                            <span className='text-danger'>Rejected</span>
                          </>
                        )}
                        {item.answer_stage ===
                          ApplicationAnswerStage.RE_ANSWERED && (
                          <>
                            <span className='text-danger'>
                              Waiting for Re Approval
                            </span>
                            <PermissionComponent
                              name={'application'}
                              permission={'update'}
                              hideOnAgent
                              hideOnStudent
                            >
                              <div>
                                <a className='btn btn-sm btn-success'>
                                  <i className='fa fa-check'> </i> Approve
                                </a>
                                <a className='btn btn-sm btn-danger ml-2'>
                                  <i className='fa fa-close'> </i> Reject
                                </a>
                              </div>
                            </PermissionComponent>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className='text-center missing'>
                        <i className='fa fa-exclamation-triangle fa-lg'></i>
                        <span className='badge '>Pending</span>
                      </div>
                      {item.answer_stage ===
                        ApplicationAnswerStage.ANSWERED && (
                        <>
                          <span className='text-warning'>
                            Waiting for Approval
                          </span>
                        </>
                      )}
                      {item.answer_stage ===
                        ApplicationAnswerStage.REJECTED && (
                        <span className='text-danger'>Rejected</span>
                      )}
                      {item.answer_stage ===
                        ApplicationAnswerStage.RE_ANSWERED && (
                        <>
                          <span className='text-danger'>
                            Waiting for Re Approval
                          </span>
                        </>
                      )}
                    </div>
                  )}

                  <div className='pre-payment-heading'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        {item.is_required && (
                          <span class={'badge danger'}>Required</span>
                        )}

                        <h3>{item.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                        {item.is_completed ? (
                          <div>
                            {item.requirement_type &&
                              item.requirement_type === 'document_upload' && (
                                <div>
                                  Document:{' '}
                                  {item.user_document_file &&
                                    item.user_document_file.map((doc) => {
                                      return (
                                        <div>
                                          <a
                                            target='_blank'
                                            href={`${URI}${doc}`}
                                          >
                                            {getDocumentName(doc)}
                                          </a>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            {item.requirement_type &&
                              item.requirement_type ===
                                'document_download_reupload' && (
                                <>
                                  <div>
                                    Document:{' '}
                                    {item.user_document_file &&
                                      item.user_document_file.map((doc) => {
                                        return (
                                          <div>
                                            <a
                                              target='_blank'
                                              href={`${URI}${doc}`}
                                            >
                                              {getDocumentName(doc)}
                                            </a>
                                          </div>
                                        );
                                      })}
                                    {/* <a
                                    target='_blank'
                                    href={`${URI}${item.user_document_file}`}
                                  >
                                    {getDocumentName(item.user_document_file)}
                                  </a> */}
                                  </div>
                                  {item.document && (
                                    <>
                                      {item.document.map((doc) => {
                                        return (
                                          <a
                                            href={`${URI}${doc}`}
                                            target='_blank'
                                            className='btn btn-sm btn-primary'
                                          >
                                            Download {getDocumentName(doc)}
                                          </a>
                                        );
                                      })}
                                    </>
                                  )}
                                </>
                              )}
                            {item.requirement_type &&
                              item.requirement_type === 'questionnaire' && (
                                <>
                                  <div>Filled Questionnaire</div>
                                  {item.questions &&
                                    item.questions.map((question) => {
                                      return (
                                        <div>
                                          <div className='question-card'>
                                            {' '}
                                            {question.title}{' '}
                                          </div>
                                          <div className='answer-card'>
                                            {' '}
                                            {question.answer}{' '}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </>
                              )}
                          </div>
                        ) : (
                          <div>
                            {item.requirement_type &&
                              item.requirement_type === 'document_upload' && (
                                <>
                                  <Formik
                                    initialValues={{
                                      user_document_file: [],
                                    }}
                                    validationSchema={Yup.object({
                                      user_document_file:
                                        Yup.array().required('Required'),
                                    })}
                                    onSubmit={async (
                                      values,
                                      { setSubmitting, resetForm }
                                    ) => {
                                      setSubmitting(true);
                                      answerApplicationRequirementSubmit(
                                        item._id,
                                        {
                                          user_document_file:
                                            values.user_document_file,
                                        }
                                      );
                                      resetForm();
                                      setSubmitting(false);
                                    }}
                                  >
                                    {(formik) => {
                                      //
                                      return (
                                        <Form>
                                          <div className='row'>
                                            <GalleryUpload
                                              hideView={true}
                                              title={'Document File'}
                                              item='user_document_file'
                                              formik={formik}
                                              value={
                                                formik.values.user_document_file
                                              }
                                            />
                                            {formik.errors &&
                                              formik.errors
                                                .user_document_file && (
                                                <p className='text-danger'>
                                                  {
                                                    formik.errors
                                                      .user_document_file
                                                  }
                                                </p>
                                              )}
                                            <div className='question-card'>
                                              <button
                                                type='submit'
                                                className='btn btn-sm btn-primary'
                                              >
                                                Upload Document
                                              </button>
                                            </div>
                                          </div>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                </>
                              )}
                            {item.requirement_type &&
                              item.requirement_type ===
                                'document_download_reupload' && (
                                <>
                                  <Formik
                                    initialValues={{
                                      user_document_file: [],
                                    }}
                                    validationSchema={Yup.object({
                                      user_document_file:
                                        Yup.array().required('Required'),
                                    })}
                                    onSubmit={async (
                                      values,
                                      { setSubmitting, resetForm }
                                    ) => {
                                      setSubmitting(true);
                                      answerApplicationRequirementSubmit(
                                        item._id,
                                        {
                                          user_document_file:
                                            values.user_document_file,
                                        }
                                      );
                                      resetForm();
                                      setSubmitting(false);
                                    }}
                                  >
                                    {(formik) => {
                                      //
                                      return (
                                        <Form>
                                          <div className='row'>
                                            <GalleryUpload
                                              title={'Document File'}
                                              item='user_document_file'
                                              formik={formik}
                                              hideView={true}
                                              value={
                                                formik.values.user_document_file
                                              }
                                            />
                                            {formik.errors &&
                                              formik.errors
                                                .user_document_file && (
                                                <p className='text-danger'>
                                                  {
                                                    formik.errors
                                                      .user_document_file
                                                  }
                                                </p>
                                              )}
                                            <div className='question-card'>
                                              <button
                                                type='submit'
                                                className='btn btn-sm btn-primary'
                                              >
                                                Upload Document
                                              </button>
                                            </div>
                                            <div className='question-card'>
                                              {item.document &&
                                                item.document.map((doc, i) => {
                                                  return (
                                                    <div>
                                                      <a
                                                        href={`${URI}${doc}`}
                                                        target='_blank'
                                                      >
                                                        Download Document{' '}
                                                        {i + 1}
                                                      </a>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                  <div></div>
                                </>
                              )}
                            {item.requirement_type &&
                              item.requirement_type === 'questionnaire' && (
                                <div>
                                  <Formik
                                    initialValues={{
                                      questions: [],
                                    }}
                                    validationSchema={Yup.object({
                                      // name: Yup.string().required("Required"),
                                    })}
                                    onSubmit={async (
                                      values,
                                      { setSubmitting, resetForm }
                                    ) => {
                                      setSubmitting(true);
                                      answerApplicationRequirementSubmit(
                                        item._id,
                                        {
                                          questions: values.questions,
                                        }
                                      );
                                      resetForm();
                                      setSubmitting(false);
                                    }}
                                  >
                                    {(formik) => {
                                      return (
                                        <Form>
                                          <div className='row'>
                                            {item.questions &&
                                              item.questions.map(
                                                (question, index) => {
                                                  if (
                                                    question.question_type ===
                                                    'simple'
                                                  ) {
                                                    return (
                                                      <div>
                                                        <label>
                                                          {' '}
                                                          {question.title}{' '}
                                                        </label>
                                                        <input
                                                          type='text'
                                                          className='form-control'
                                                          name={`questions[${index}].answer`}
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          value={
                                                            formik.values
                                                              .questions &&
                                                            formik.values
                                                              .questions[
                                                              index
                                                            ] &&
                                                            formik.values
                                                              .questions[index]
                                                              .answer
                                                          }
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                  if (
                                                    question.question_type ===
                                                    'big_answer'
                                                  ) {
                                                    return (
                                                      <div>
                                                        <label>
                                                          {' '}
                                                          {question.title}{' '}
                                                        </label>
                                                        <textarea
                                                          type='text'
                                                          className='form-control'
                                                          name={`questions[${index}].answer`}
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          value={
                                                            formik.values
                                                              .questions &&
                                                            formik.values
                                                              .questions[
                                                              index
                                                            ] &&
                                                            formik.values
                                                              .questions[index]
                                                              .answer
                                                          }
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                  if (
                                                    question.question_type ===
                                                    'yes_no'
                                                  ) {
                                                    return (
                                                      <div>
                                                        <label>
                                                          {' '}
                                                          {question.title}{' '}
                                                        </label>
                                                        <select
                                                          className='form-control'
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          name={`questions[${index}].answer`}
                                                          value={
                                                            formik.values
                                                              .questions &&
                                                            formik.values
                                                              .questions[
                                                              index
                                                            ] &&
                                                            formik.values
                                                              .questions[index]
                                                              .answer
                                                          }
                                                        >
                                                          <option value='Yes'>
                                                            Yes
                                                          </option>
                                                          <option value='No'>
                                                            No
                                                          </option>
                                                        </select>
                                                      </div>
                                                    );
                                                  }
                                                  if (
                                                    question.question_type ===
                                                    'yes_no'
                                                  ) {
                                                    return (
                                                      <div>
                                                        <label>
                                                          {' '}
                                                          {question.title}{' '}
                                                        </label>
                                                        <select
                                                          className='form-control'
                                                          onChange={
                                                            formik.handleChange
                                                          }
                                                          name={`questions[${index}].answer`}
                                                          value={
                                                            formik.values
                                                              .questions &&
                                                            formik.values
                                                              .questions[
                                                              index
                                                            ] &&
                                                            formik.values
                                                              .questions[index]
                                                              .answer
                                                          }
                                                        >
                                                          <option value=''></option>
                                                          {question.multiple_choice &&
                                                            question.multiple_choice.map(
                                                              (choice) => {
                                                                return (
                                                                  <option
                                                                    value={
                                                                      choice.answer_choice
                                                                    }
                                                                  >
                                                                    {
                                                                      choice.answer_choice
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                      </div>
                                                    );
                                                  }
                                                  return <div></div>;
                                                }
                                              )}
                                          </div>
                                          <div className='question-card'>
                                            <button
                                              type='submit'
                                              className='btn btn-sm btn-primary'
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                      <div
                        className='see-more-button'
                        onClick={() => {
                          setSeemore(!seemore);
                        }}
                      >
                        <button className='badge see-more'>
                          {seemore ? 'show Less' : 'show More'}
                          <i
                            className={
                              seemore ? 'fa fa-angle-up' : 'fa fa-angle-down'
                            }
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          // }
        })}

      <Tooltip field={'prepayment'} />
    </div>
  );
}

export default PrePaymentCard;
