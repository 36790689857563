import React from 'react';
import PermissionComponent from '../../components/auth/PermissionComponent';

import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';

import AddSchoolComponent from '../../components/school/AddSchoolComponent';
import { useCreateSchool } from '../../shared/hooks/UseSchool';
import { useAllCountries } from '../../shared/hooks/UseCountry';

function AddSchool() {
  const { addSchoolData } = useCreateSchool();
  const { country_data } = useAllCountries();
  const { countries, countries_loading } = country_data;
  return (
    <>
      <div className="wrapper">
        <Auth_header_mobile />
        <AuthHeader />
        <AuthBreadCrumb title={'Add School'} />

        {/* page-section */}
        <PermissionComponent name={'school'} permission={'create'}>
          <section className="  personal-information ptb-20 ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <AddSchoolComponent
                    handleSubmitData={addSchoolData}
                    markets={countries}
                  />
                </div>
              </div>
            </div>
          </section>
        </PermissionComponent>
        <AuthFooter />
      </div>
    </>
  );
}

export default AddSchool;
