import {
  GET_DISCIPLINES_STARTED,
  GET_DISCIPLINES,
  GET_DISCIPLINES_ENDED,
  ADD_DISCIPLINE_STARTED,
  ADD_DISCIPLINE,
  ADD_DISCIPLINE_ENDED,
  EDIT_DISCIPLINE_STARTED,
  EDIT_DISCIPLINE,
  EDIT_DISCIPLINE_ENDED,
  GET_DISCIPLINE,
  GET_DISCIPLINE_STARTED,
  GET_DISCIPLINE_ENDED,
} from '../types/discipline_types';

const initialState = {
  disciplines_loading: true,
  disciplines: null,
  discipline_page: null,
  disciplines_pages: null,
  total_disciplines: 0,

  discipline: null,
  discipline_loading: null,

  add_discipline_loading: true,
  edit_discipline_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DISCIPLINES_STARTED:
      return {
        ...state,
        disciplines: null,
        discipline_pages: null,
        discipline_page: null,
        total_disciplines: 0,
        disciplines_loading: true,
      };
    case GET_DISCIPLINES:
      return {
        ...state,
        disciplines: payload.disciplines,
        discipline_pages: payload.pages,
        discipline_page: payload.page,
        total_disciplines: payload.total_disciplines,
      };
    case GET_DISCIPLINES_ENDED:
      return {
        ...state,
        disciplines_loading: false,
      };

    case ADD_DISCIPLINE_STARTED:
      return {
        ...state,
        discipline_message: null,
        add_discipline_loading: true,
      };
    case ADD_DISCIPLINE:
      return {
        ...state,
        discipline_message: payload,
      };
    case ADD_DISCIPLINE_ENDED:
      return {
        ...state,
        add_discipline_loading: false,
      };
    case GET_DISCIPLINE_STARTED:
      return {
        ...state,
        discipline: null,
        discipline_loading: true,
      };
    case GET_DISCIPLINE:
      return {
        ...state,
        discipline: payload,
      };
    case GET_DISCIPLINE_ENDED:
      return {
        ...state,
        discipline_loading: false,
      };
    case EDIT_DISCIPLINE_STARTED:
      return {
        ...state,
        discipline_message: null,
        edit_discipline_loading: true,
      };
    case EDIT_DISCIPLINE:
      return {
        ...state,
        discipline_message: payload,
      };
    case EDIT_DISCIPLINE_ENDED:
      return {
        ...state,
        edit_discipline_loading: false,
      };

    default:
      return state;
  }
}
