export const GET_EDUCATION_LEVELS_STARTED = 'GET_EDUCATION_LEVELS_STARTED';
export const GET_EDUCATION_LEVELS = 'GET_EDUCATION_LEVELS';
export const GET_EDUCATION_LEVELS_ENDED = 'GET_EDUCATION_LEVELS_ENDED';
export const GET_EDUCATION_LEVELS_ERROR = 'GET_EDUCATION_LEVELS_ERROR';
export const ADD_EDUCATION_LEVEL_STARTED = 'ADD_EDUCATION_LEVEL_STARTED';
export const ADD_EDUCATION_LEVEL = 'ADD_EDUCATION_LEVEL';
export const ADD_EDUCATION_LEVEL_ENDED = 'ADD_EDUCATION_LEVEL_ENDED';
export const ADD_EDUCATION_LEVEL_ERROR = 'ADD_EDUCATION_LEVEL_ERROR';
export const EDIT_EDUCATION_LEVEL_STARTED = 'EDIT_EDUCATION_LEVEL_STARTED';
export const EDIT_EDUCATION_LEVEL = 'EDIT_EDUCATION_LEVEL';
export const EDIT_EDUCATION_LEVEL_ENDED = 'EDIT_EDUCATION_LEVEL_ENDED';
export const EDIT_EDUCATION_LEVEL_ERROR = 'EDIT_EDUCATION_LEVEL_ERROR';
export const GET_EDUCATION_LEVEL = 'GET_EDUCATION_LEVEL';
export const GET_EDUCATION_LEVEL_STARTED = 'GET_EDUCATION_LEVEL_STARTED';
export const GET_EDUCATION_LEVEL_ENDED = 'GET_EDUCATION_LEVEL_ENDED';
export const GET_EDUCATION_LEVEL_ERROR = 'GET_EDUCATION_LEVEL_ERROR';
