import React from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import SlabForm from './SlabForm';
import { useCreateSlab } from '../../shared/hooks/UseSlab';

function AddSlabComponent() {
  const { addSlabData } = useCreateSlab();
  const navigate = useNavigate();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Commission Tier</h1>
        </div>

        <Formik
          initialValues={{
            name: '',
            zone: [
              {
                country: '',
                commission: '',
              },
            ],
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            addSlabData(values);
            navigate('/settings/slabs');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <SlabForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddSlabComponent;
