import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import ViewButtonByID from '../common/ViewBtnByID';
import PermissionComponent from '../auth/PermissionComponent';
function AcademicPartnersComponent({
  academic_partners_loading,
  academic_partners,
  academic_partner_page,
  academic_partner_pages,
  total_academic_partners,
  setPageNumber,
}) {
  const navigate = useNavigate();
  const [columnDefs] = useState([
    { field: 'first_name' },
    { field: 'last_name' },
    { field: 'email' },
    { field: 'phone' },
    { field: 'country_code' },
    { field: 'user_id' },
    { field: 'is_verified' },
    { field: 'is_blocked' },

    {
      field: 'Actions',
      pinned: 'right',
      cellRenderer: ViewButtonByID,
      cellRendererParams: {
        clicked: function (field) {
          navigate(`/settings/crm-users/${field}/view`);
        },
        edit_clicked: function (field) {
          navigate(`/settings/crm-users/${field}/edit`);
        },
      },
    },
  ]);
  return (
    <PermissionComponent name={'crm_user'} permission={'get_all'}>
      <div>
        <p> {total_academic_partners}Records Found </p>
        <div className='myapplication-table'>
          <table className='table table-responsive table-sm  table-bordered table-striped  '>
            <thead>
              <tr>
                <th scope='col '>First Name</th>

                <th scope='col'>Last Name</th>
                <th scope='col'>Email</th>
                <th scope='col'>Phone</th>
                <th scope='col'>Country Code</th>
                <th scope='col'>User Id</th>

                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {academic_partners &&
                academic_partners.map((academic_partner) => {
                  return (
                    <tr key={academic_partner._id}>
                      <td>{academic_partner.first_name}</td>

                      <td> {academic_partner.last_name} </td>
                      <td>{academic_partner.email}</td>
                      <td>{academic_partner.phone}</td>
                      <td> {academic_partner.country_code} </td>
                      <td> {academic_partner.user_id} </td>

                      <td className='status-ic'>
                        <Link
                          to={`/settings/academic-partners/${academic_partner._id}/view`}
                          className='badge badge-view'
                        >
                          View
                        </Link>
                        <Link
                          to={`/settings/academic-partners/${academic_partner._id}/edit`}
                          className='badge badge-edit'
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              {academic_partners && academic_partners.length == 0 && (
                <tr>
                  <td colSpan={10}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={academic_partners}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
          />
        </div> */}
        <Pagination
          data={academic_partners}
          count={total_academic_partners}
          pages={academic_partner_pages}
          loading={academic_partners_loading}
        />
      </div>
    </PermissionComponent>
  );
}

export default AcademicPartnersComponent;
