import {
  GET_GRADINGS_STARTED,
  GET_GRADINGS,
  GET_GRADINGS_ENDED,
  ADD_GRADING_STARTED,
  ADD_GRADING,
  ADD_GRADING_ENDED,
  EDIT_GRADING_STARTED,
  EDIT_GRADING,
  EDIT_GRADING_ENDED,
  GET_GRADING,
  GET_GRADING_STARTED,
  GET_GRADING_ENDED,
} from '../types/grading_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addGrading
export const addGrading = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_GRADING_STARTED,
    });
    const { data } = await api.post(`/gradings/add`, formData);
    dispatch({
      type: ADD_GRADING,
      payload: data,
    });
    dispatch({
      type: ADD_GRADING_ENDED,
    });
    dispatch(setAlert('Grading added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_GRADING_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getGradings =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_GRADINGS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/gradings?&${query}`);

      dispatch({
        type: GET_GRADINGS,
        payload: data,
      });
      dispatch({
        type: GET_GRADINGS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_GRADINGS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getGrading = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GRADING_STARTED,
    });
    const { data } = await api.get(`/gradings/${id}`);

    dispatch({
      type: GET_GRADING,
      payload: data,
    });
    dispatch({
      type: GET_GRADING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_GRADING_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getGradingBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GRADING_STARTED,
    });
    const { data } = await api.get(`/gradings/slug/${slug}`);

    dispatch({
      type: GET_GRADING,
      payload: data,
    });
    dispatch({
      type: GET_GRADING_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_GRADING_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editGrading = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_GRADING_STARTED,
    });
    const { data } = await api.put(`/gradings/${id}`, formData);
    dispatch({
      type: EDIT_GRADING,
      payload: data,
    });
    dispatch({
      type: EDIT_GRADING_ENDED,
    });
    dispatch(setAlert('Grading Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_GRADING_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftGrading = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_GRADING_STARTED,
    });
    dispatch({
      type: GET_GRADING_STARTED,
    });
    const { data } = await api.put(`/gradings/${id}/draft`);
    dispatch({
      type: EDIT_GRADING,
      payload: data,
    });
    dispatch({
      type: GET_GRADING,
      payload: data,
    });

    dispatch({
      type: EDIT_GRADING_ENDED,
    });
    dispatch({
      type: GET_GRADING_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_GRADING_ENDED,
    });
    dispatch({
      type: GET_GRADING_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateGrading = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_GRADING_STARTED,
    });
    const { data } = await api.put(`/gradings/${id}/active`);
    dispatch({
      type: EDIT_GRADING,
      payload: data,
    });
    dispatch({
      type: GET_GRADING_STARTED,
    });
    dispatch({
      type: EDIT_GRADING_ENDED,
    });
    dispatch({
      type: GET_GRADING,
      payload: data,
    });
    dispatch({
      type: GET_GRADING_ENDED,
    });
    dispatch(setAlert('Grading Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_GRADING_ENDED,
    });
    dispatch({
      type: GET_GRADING_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteGrading = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/gradings/${id}`);
    dispatch(setAlert('Grading Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
