import React from 'react';
import AgentName from './AgentName';

function ViewCounsellorComponent({ counsellor }) {
  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>First Name</h2>
          <span>{counsellor.first_name}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Last Name</h2>
          <span>{counsellor.last_name}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Email</h2>
          <span>{counsellor.email}</span>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Nationality</h2>
          <span>{counsellor.nationality}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Country Code</h2>
          <span>{counsellor.country_code}</span>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='heading-single-education'>
          <h2>Phone</h2>
          <span>{counsellor.phone}</span>
        </div>
      </div>
      {counsellor.agent && <AgentName agent_id={counsellor.agent} />}
    </div>
  );
}

export default ViewCounsellorComponent;
