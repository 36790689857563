import React, { useState } from 'react';
import Tooltip from '../Tooltip';
import moment from 'moment';
import {
  MissingApplication,
  NotApprovedApplication,
  PrepaymentApprovedApplication,
  ReviewApplication,
} from '../images';

function PostArrivalRequirementCard({ application }) {
  const [show, setShow] = useState(true);
  const [seemore, setSeemore] = useState(false);
  return (
    <div className='box-show-and-hide'>
      <div className='pre-payment-box'>
        <div className='pre-payment-heading-flex'>
          <div className='pre-payment-heading'>
            <h4>Post Arrival Requirement</h4>
            <p>
              Last requirement completed on{' '}
              {application &&
                moment(application.createdAt).format('MMM-DD-YYYY')}
            </p>
          </div>
          <div className='review-status'>
            <div className='box-flex'>
              <div id='prepayment-missing' className='box-main-box color1'>
                <img src={MissingApplication} /> 0
              </div>
              <div id='prepayment-reviewing' className='box-main-box color2'>
                <img src={ReviewApplication} />0
              </div>
              <div id='prepayment-notapproved' className='box-main-box color3'>
                <img src={NotApprovedApplication} />0
              </div>
              <div id='prepayment-completed' className='box-main-box color4'>
                <img src={PrepaymentApprovedApplication} />0
              </div>

              <div
                onClick={() => {
                  setShow(!show);
                }}
              >
                <i className={show ? 'fa fa-minus' : 'fa fa-plus'}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='pre-payment-card-required'
        style={{ display: show ? 'block' : 'none' }}
      >
        <div
          className={
            seemore
              ? 'icon-with-flex-pre-payment  '
              : 'icon-with-flex-pre-payment dro-height '
          }
        >
          <div className='text-center compl'>
            <i className='fa fa-check'></i>
            <span className='badge '>Completed</span>
          </div>
          <div className='pre-payment-heading'>
            <span class='badge danger'>Required</span>
            <h3>Electronic Score Submission Disclaimer</h3>
            <h5>
              Please be advised this application may require official test
              scores to be sent electronically to the school.
            </h5>
            <p>
              Our Team will provide the applicant with further instruction
              throughout the process if this is applicable.
            </p>
          </div>
          <div
            className='see-more-button'
            onClick={() => {
              setSeemore(!seemore);
            }}
          >
            <button className='badge see-more'>
              {seemore ? 'show Less' : 'show More'}
              <i
                className={seemore ? 'fa fa-angle-up' : 'fa fa-angle-down'}
              ></i>
            </button>
          </div>
        </div>
      </div>
      <Tooltip field={'prepayment'} />
    </div>
  );
}

export default PostArrivalRequirementCard;
