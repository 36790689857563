import {
  GET_PERMISSIONS_STARTED,
  GET_PERMISSIONS,
  GET_PERMISSIONS_ENDED,
  ADD_PERMISSION_STARTED,
  ADD_PERMISSION,
  ADD_PERMISSION_ENDED,
  EDIT_PERMISSION_STARTED,
  EDIT_PERMISSION,
  EDIT_PERMISSION_ENDED,
  GET_PERMISSION,
  GET_PERMISSION_STARTED,
  GET_PERMISSION_ENDED,
} from '../types/permission_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addPermission
export const addPermission = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_PERMISSION_STARTED,
    });
    const { data } = await api.post(`/permissions/add`, formData);
    dispatch({
      type: ADD_PERMISSION,
      payload: data,
    });
    dispatch({
      type: ADD_PERMISSION_ENDED,
    });
    dispatch(setAlert('Permission added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_PERMISSION_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getPermissions =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_PERMISSIONS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/permissions?&${query}`);

      dispatch({
        type: GET_PERMISSIONS,
        payload: data,
      });
      dispatch({
        type: GET_PERMISSIONS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_PERMISSIONS_ENDED,
      });

      dispatch(handleError(error));
    }
  };
export const getPermission = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PERMISSION_STARTED,
    });
    const { data } = await api.get(`/permissions/${id}`);

    dispatch({
      type: GET_PERMISSION,
      payload: data,
    });
    dispatch({
      type: GET_PERMISSION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_PERMISSION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getPermissionBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PERMISSION_STARTED,
    });
    const { data } = await api.get(`/permissions/slug/${slug}`);

    dispatch({
      type: GET_PERMISSION,
      payload: data,
    });
    dispatch({
      type: GET_PERMISSION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_PERMISSION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editPermission = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PERMISSION_STARTED,
    });
    const { data } = await api.put(`/permissions/${id}`, formData);
    dispatch({
      type: EDIT_PERMISSION,
      payload: data,
    });
    dispatch({
      type: EDIT_PERMISSION_ENDED,
    });
    dispatch(setAlert('Permission Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_PERMISSION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const draftPermission = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PERMISSION_STARTED,
    });
    dispatch({
      type: GET_PERMISSION_STARTED,
    });
    const { data } = await api.put(`/permissions/${id}/draft`);
    dispatch({
      type: EDIT_PERMISSION,
      payload: data,
    });
    dispatch({
      type: GET_PERMISSION,
      payload: data,
    });

    dispatch({
      type: EDIT_PERMISSION_ENDED,
    });
    dispatch({
      type: GET_PERMISSION_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_PERMISSION_ENDED,
    });
    dispatch({
      type: GET_PERMISSION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activatePermission = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PERMISSION_STARTED,
    });
    const { data } = await api.put(`/permissions/${id}/active`);
    dispatch({
      type: EDIT_PERMISSION,
      payload: data,
    });
    dispatch({
      type: GET_PERMISSION_STARTED,
    });
    dispatch({
      type: EDIT_PERMISSION_ENDED,
    });
    dispatch({
      type: GET_PERMISSION,
      payload: data,
    });
    dispatch({
      type: GET_PERMISSION_ENDED,
    });
    dispatch(setAlert('Permission Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_PERMISSION_ENDED,
    });
    dispatch({
      type: GET_PERMISSION_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deletePermission = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/permissions/${id}`);
    dispatch(setAlert('Permission Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
