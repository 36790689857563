import * as Yup from 'yup';
export const programValidationSchema = Yup.object({
  school: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  delivery_method: Yup.string().required('Required'),
  tuition: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  education_level: Yup.string().required('Required'),
  program_intakes: Yup.array(
    Yup.object().shape({
      status: Yup.string().required('Required'),
      intake_name: Yup.string().required('Required'),
    })
  ),
  other_fees: Yup.array(
    Yup.object().shape({
      title: Yup.string().required('Required'),
      fees: Yup.string().required('Required'),
    })
  ),
});
