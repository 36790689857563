export const PROFILE_TABS = [
  {
    name: 'General Information',
    slug: '/general-information',
  },
  {
    name: 'Education History',
    slug: '/education-history',
  },
  {
    name: 'Test Score',
    slug: '/test-score',
  },
  {
    name: 'Visa & Study Permit',
    slug: '/visa-and-study-permit',
  },
];

export const PROFILE_TABS_ENUM = {
  GENERAL_INFORMATION: 'General Information',
  EDUCATION_HISTORY: 'Education History',
  TEST_SCORE: 'Test Score',
  VISA_AND_STUDY_PERMIT: 'Visa & Study Permit',
};
