import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
import { STUDENT_PROFILE_MENU } from '../../domain/student_profile_menu';
import { useLogout } from '../../shared/hooks/UseAuth';
import { useNavigate } from 'react-router-dom';
import { Logo, Chimp } from '../images';
import Auth_header_mobile from './Auth_header_mobile';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { getInitialName } from '../../shared/helpers/getIntialValue';
import { useCurrentAgent } from '../../shared/hooks/UseAgent';
import { URI } from '../../domain/constant';
import {
  useAllNotifications,
  useUpdateNotificationBulk,
  useToggleNotification,
} from '../../shared/hooks/UseNotification';
import { UserRoles } from '../../domain/UserRole';

export const AuthHeader = React.memo(({ active }) => {
  const navigate = useNavigate();
  const { updateNotificationBulk } = useUpdateNotificationBulk();
  const user_data = useCheckAuthenticated();

  const { user } = user_data;
  const [showMenu, setshowMenu] = useState(false);
  const { logoutUser } = useLogout();
  const { agent_data } = useCurrentAgent();
  const { current_agent } = agent_data;
  const { toggleNotificationData, show_notification } = useToggleNotification();
  const { notification_data } = useAllNotifications();
  const { notifications, total_notifications } = notification_data;
  const [notification, setNotification] = useState(null);
  return (
    <>
      <header className='profile_header'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-2'>
              <div className='profile-logo'>
                <Link to={links.dashBoardHome}>
                  {current_agent && current_agent.agentInfo ? (
                    <div className='d-flex'>
                      <img src={Chimp} style={{ height: '50px' }} />
                      {user &&
                        (user.role === 'AGENT' ||
                          user.role === UserRoles.COUNSELLOR) &&
                        current_agent &&
                        current_agent.agentInfo && (
                          <img
                            style={{ height: '50px' }}
                            src={`${URI}${current_agent.agentInfo.business_logo}`}
                          />
                        )}
                    </div>
                  ) : (
                    <img src={Logo} />
                  )}
                </Link>
              </div>
            </div>
            <div className='col-md-10'>
              <div className='pull-right'>
                <div className='nav'>
                  <div className='profile-menu'>
                    <ul>
                      {STUDENT_PROFILE_MENU &&
                        STUDENT_PROFILE_MENU.map((e) => {
                          if (e.roles.includes(user.role)) {
                            return (
                              <li key={e.slug}>
                                <Link
                                  to={e.slug}
                                  className={active === e.name ? 'active' : ''}
                                >
                                  {e.name}
                                </Link>
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>

                  <div className='notification-icon'>
                    <ul style={{ display: 'flex' }}>
                      <li
                        onClick={() => {
                          toggleNotificationData(!show_notification);
                        }}
                      >
                        <a href='#'>
                          <i className='fa fa-bell'></i>
                          <span className='total-no'>
                            {' '}
                            {total_notifications}{' '}
                          </span>
                          <div
                            className='total-notificaton'
                            style={{
                              display: show_notification ? 'block' : 'none',
                            }}
                          >
                            {show_notification && (
                              <div className='total-notification-dropdown'>
                                {notifications &&
                                  notifications.map((item) => {
                                    return (
                                      <div>
                                        <Link
                                          to={`${item.url}?notification=${item._id}`}
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '0px',
                                          }}
                                        >
                                          <p>{item.name}</p>
                                        </Link>
                                      </div>
                                    );
                                  })}
                                <div className='show-flex'>
                                  <div>
                                    <Link
                                      className='notification-link '
                                      to={`/notifications`}
                                    >
                                      <div className='status-ic'>
                                        <button className='badge badge-view'>
                                          Show All
                                        </button>
                                      </div>
                                    </Link>
                                  </div>
                                  <div>
                                    <button
                                      className='badge badge-open'
                                      onClick={() => updateNotificationBulk()}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* {total_notifications} */}
                          </div>
                        </a>
                      </li>
                      <li onClick={() => setshowMenu(!showMenu)}>
                        <div className='user-circle'>
                          {user.profile_pic ? (
                            <>
                              <img
                                src={`${URI}${user.profile_pic}`}
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '50%',
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {getInitialName(
                                user && `${user.first_name} ${user.last_name}`
                              )}
                            </>
                          )}
                        </div>
                        {showMenu && (
                          <ul className='dropuser'>
                            {user && user.role === 'CRM_USER' && (
                              <li
                                className='sub-item'
                                onClick={() => {
                                  navigate('/settings/education-levels');
                                }}
                              >
                                <Link to='/settings/education-levels'>
                                  <i className='fa fa-cog'></i> Settings
                                </Link>
                              </li>
                            )}

                            <li
                              className='sub-item'
                              onClick={() => logoutUser()}
                            >
                              <a>
                                <i className='fa fa-sign-out'></i> Logout
                              </a>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
});
// useless
