import {
  ADD_EDUCATION_STARTED,
  ADD_EDUCATION_SUCCESS,
  ADD_EDUCATION_ENDED,
  ADD_EDUCATION_ERROR,
} from '../types/education_types';
import { api } from '../../domain/api';

// Register User
export const updateEducationInfo = (student_id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_EDUCATION_STARTED,
    });
    const res = await api.post(`/education-information/${student_id}`, data);
    window.location.reload();
    dispatch({
      type: ADD_EDUCATION_SUCCESS,
      payload: 'Education Updated',
    });
    dispatch({
      type: ADD_EDUCATION_ENDED,
    });
  } catch (err) {
    dispatch({
      type: ADD_EDUCATION_ENDED,
    });
    dispatch({
      type: ADD_EDUCATION_ERROR,
      payload: err,
    });
  }
};
