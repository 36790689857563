import React from 'react';
import { Link } from 'react-router-dom';
import PermissionComponent from '../../components/auth/PermissionComponent';

import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SettingNavigationComponent from '../../components/auth_common/SettingNavigationComponent';
import AcademicPartnersComponent from '../../components/academicPartners/AcademicPartnersComponent';

import { links } from '../../domain/links.enum';
import { SETTING_MENU_ENUM } from '../../domain/setting_menu';
import { useAllAcademicPartners } from '../../shared/hooks/UseAcademicPartner';
import FilterComponent from '../../components/filter/FilterComponent';
function AcademicPartners() {
  const { data, setPageNumber } = useAllAcademicPartners();
  const {
    academic_partner_loading,
    academic_partners,
    academic_partner_page,
    academic_partner_pages,
    total_academic_partners,
  } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb title={'Academic Partner'} />
      <SettingNavigationComponent
        active_link={SETTING_MENU_ENUM.ACADEMIC_PARTNERS}
      />
      <section className='profile-main ptb-20'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-6'>
              <div className='main-sec-heading'>
                <h1>Academic Partners</h1>
              </div>
            </div>
            <div className='col-md-6 col-6'>
              <PermissionComponent name={'crm_user'} permission={'view'}>
                <div className='add-student-btn'>
                  <Link
                    to={links.addAcademicPartner}
                    className='btn primary-btn-h '
                  >
                    Add Academic Partner
                  </Link>
                </div>
              </PermissionComponent>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}
      <PermissionComponent
        name={'crm_user'}
        permission={'get_all'}
        hideOnAgent={true}
        hideOnStudent={true}
      >
        <section className='students-section'>
          {academic_partners && (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <AcademicPartnersComponent
                    academic_partners_loading={academic_partner_loading}
                    academic_partners={academic_partners}
                    academic_partner_page={academic_partner_page}
                    academic_partner_pages={academic_partner_pages}
                    total_academic_partners={total_academic_partners}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </PermissionComponent>
      <AuthFooter />
    </div>
  );
}

export default AcademicPartners;
