import {
  ADD_GRE_STARTED,
  ADD_GRE_SUCCESS,
  ADD_GRE_ENDED,
  ADD_GRE_ERROR,
} from '../types/gre_types';

const initialState = {
  add_gre_loading: false,
  add_gre_errors: null,
  add_gre_success: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_GRE_STARTED:
      return {
        ...state,
        add_gre_loading: true,
        add_gre_errors: null,
        add_gre_success: null,
      };
    case ADD_GRE_ENDED:
      return {
        ...state,
        add_gre_loading: false,
      };
    case ADD_GRE_ERROR:
      return {
        ...state,
        add_gre_errors: payload,
      };
    case ADD_GRE_SUCCESS:
      return {
        ...state,
        add_gre_success: payload,
      };

    default:
      return state;
  }
}
