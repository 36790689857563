import {
  GET_AGENTS_STARTED,
  GET_AGENTS,
  GET_AGENTS_ENDED,
  ADD_AGENT_STARTED,
  ADD_AGENT,
  ADD_AGENT_ENDED,
  EDIT_AGENT_STARTED,
  EDIT_AGENT,
  EDIT_AGENT_ENDED,
  GET_AGENT,
  GET_AGENT_STARTED,
  GET_AGENT_ENDED,
  GET_CURRENT_AGENT,
  GET_CURRENT_AGENT_STARTED,
  GET_CURRENT_AGENT_ENDED,
} from '../types/agent_types';

const initialState = {
  agents_loading: true,
  agents: null,
  agent_page: null,
  agents_pages: null,
  total_agents: 0,

  agent: null,
  agent_loading: null,

  current_agent: null,
  current_agent_loading: null,

  add_agent_loading: true,
  edit_agent_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AGENTS_STARTED:
      return {
        ...state,
        agents: null,
        agent_pages: null,
        agent_page: null,
        total_agents: 0,
        agents_loading: true,
      };
    case GET_AGENTS:
      return {
        ...state,
        agents: payload.agents,
        agent_pages: payload.pages,
        agent_page: payload.page,
        total_agents: payload.total_agents,
      };
    case GET_AGENTS_ENDED:
      return {
        ...state,
        agents_loading: false,
      };

    case ADD_AGENT_STARTED:
      return {
        ...state,
        agent_message: null,
        add_agent_loading: true,
      };
    case ADD_AGENT:
      return {
        ...state,
        agent_message: payload,
      };
    case ADD_AGENT_ENDED:
      return {
        ...state,
        add_agent_loading: false,
      };
    case GET_AGENT_STARTED:
      return {
        ...state,
        agent: null,
        agent_loading: true,
      };
    case GET_AGENT:
      return {
        ...state,
        agent: payload,
      };
    case GET_AGENT_ENDED:
      return {
        ...state,
        agent_loading: false,
      };
    case GET_CURRENT_AGENT_STARTED:
      return {
        ...state,
        current_agent: null,
        current_agent_loading: true,
      };
    case GET_CURRENT_AGENT:
      return {
        ...state,
        current_agent: payload,
      };
    case GET_CURRENT_AGENT_ENDED:
      return {
        ...state,
        current_agent_loading: false,
      };

    case EDIT_AGENT_STARTED:
      return {
        ...state,
        agent_message: null,
        edit_agent_loading: true,
      };
    case EDIT_AGENT:
      return {
        ...state,
        agent_message: payload,
      };
    case EDIT_AGENT_ENDED:
      return {
        ...state,
        edit_agent_loading: false,
      };

    default:
      return state;
  }
}
