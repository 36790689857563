import React from 'react';

import { countries } from '../../domain/countries';
import { Formik, Form } from 'formik';
import { TextInput, SelectBox, CheckBox } from '../../components/Form/Form';

function UpdatePasswordComponentForm({ formik }) {
  return (
    <Form>
      <div className="row">
        <div className="col-md-12 ">
          <TextInput label="Password" name="password" type="password" />
        </div>
        <div className="col-md-12">
          <TextInput
            label="Confirm Password"
            name="confirm_password"
            type="password"
          />
        </div>
      </div>

      <div className="row">
        <div className="create-button">
          <button type="submit" className="btn primary-btn">
            Update Password
          </button>
        </div>
      </div>
    </Form>
  );
}

export default UpdatePasswordComponentForm;
