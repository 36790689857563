import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';

import CostAndDuration from '../../components/SingleSchool/CostAndDuration';
import InstitutionDetails from '../../components/SingleSchool/InstitutionDetails';

import SchoolFeature from '../../components/SingleSchool/SchoolFeature';
import SchoolGallery from '../../components/SingleSchool/SchoolGallery';
import SchoolHeader from '../../components/SingleSchool/SchoolHeader';
import SchoolLocation from '../../components/SingleSchool/SchoolLocation';
import SchoolOverview from '../../components/SingleSchool/SchoolOverview';

import ZoomGalleryComponent from '../../components/SingleSchool/ZoomGalleryComponent';
import { useSingleSchoolBySlug } from '../../shared/hooks/UseSchool';
import { useAllRequirements } from '../../shared/hooks/UseRequirement';
import PermissionComponent from '../../components/auth/PermissionComponent';
import SchoolPrograms from '../../components/SingleSchool/SchoolPrograms';
function SingleSchool({
  hideBreadCrumb,
  hideFooter,
  hideStatus,
  hideAction,
  hideStartApplication,
}) {
  const params = useParams();

  const [showFullGallery, setShowFullGallery] = useState(false);
  const { school_data } = useSingleSchoolBySlug(params.slug);
  const { school_loading, school } = school_data;

  return (
    <div className='wrapper'>
      {!hideBreadCrumb && (
        <>
          <Auth_header_mobile />
          <AuthHeader />
        </>
      )}

      {school_loading ? (
        <div>Loading...</div>
      ) : (
        school && (
          <>
            <PermissionComponent name={'school'} permission={'view'}>
              {!hideBreadCrumb && <AuthBreadCrumb title={school.name} />}
              <SchoolHeader school={school} hideBreadCrumb={hideBreadCrumb} />
              <SchoolGallery
                school={school}
                showFullGallery={showFullGallery}
                setShowFullGallery={setShowFullGallery}
              />
              {showFullGallery && (
                <ZoomGalleryComponent
                  school={school}
                  showFullGallery={showFullGallery}
                  setShowFullGallery={setShowFullGallery}
                />
              )}
              <section className='main-school-single-view ptb-50 '>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='single-school-tabs'>
                        <ul>
                          <li className='active'>
                            <a href=''>Overview</a>
                          </li>
                          <li>
                            <a href='#features'>Features</a>
                          </li>
                          <li>
                            <a href='#location'>Location</a>
                          </li>
                          <li>
                            <a href='#programs'>Programs</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-8'>
                      <div className='single-school-about'>
                        <SchoolOverview school={school} />
                        <SchoolFeature school={school} />
                        <SchoolLocation school={school} />
                        <SchoolPrograms school={school.id} />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='single-school-sidebar'>
                        <InstitutionDetails school={school} />
                        <CostAndDuration school={school} />
                        {/* <AverageTimeToReceive school={school} /> */}
                        {/* <TopDisciplines school={school} />
                      <ProgramLevels school={school} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </PermissionComponent>
          </>
        )
      )}
      {!hideFooter && <AuthFooter />}
    </div>
  );
}

export default SingleSchool;
