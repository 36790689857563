import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

function MyComponent({ school }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAsayaWtIY4qoqIIcq38dU7a3KIBUIEKLY',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    if (school.latitude && school.longitude) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: school.latitude,
        lng: school.longitude,
      });
      map.fitBounds(bounds);

      setMap(map);
    }
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      {school.latitude && school.longitude && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: school.latitude,
            lng: school.longitude,
          }}
          zoom={10}
          // onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <Marker
            position={{
              lat: school.latitude,
              lng: school.longitude,
            }}
          ></Marker>
        </GoogleMap>
      )}
    </>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
