import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import { links } from '../../domain/links.enum';
import { AgGridReact } from 'ag-grid-react';

import {
  STUDENT_PROFILE_ENUM,
  STUDENT_PROFILE_MENU,
} from '../../domain/student_profile_menu';
import AllStudentComponent from '../../components/students/AllStudentComponent';
import { useAllStudents } from '../../shared/hooks/UseStudent';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';

export const Students = (props) => {
  const { data, setPageNumber } = useAllStudents();
  const auth_user = useCheckAuthenticated();
  const { user } = auth_user;
  const {
    students_loading,
    students,
    student_page,
    student_pages,
    total_students,
  } = data;
  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader active={STUDENT_PROFILE_ENUM.STUDENTS} />
      <AuthBreadCrumb title={'Students'} />
      {/* {user && user.role==="AGENT" && } */}
      <AllStudentComponent
        students_loading={students_loading}
        students={students}
        student_page={student_page}
        student_pages={student_pages}
        total_students={total_students}
      />

      <AuthFooter />
    </div>
  );
};
