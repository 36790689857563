import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthBreadCrumb } from '../../components/auth_common/AuthBreadCrumb';
import { AuthFooter } from '../../components/auth_common/AuthFooter';
import { AuthHeader } from '../../components/auth_common/AuthHeader';
import Auth_header_mobile from '../../components/auth_common/Auth_header_mobile';
import SingleApplicationComponent from '../../components/my_applications/SingleApplicationComponent';
import {
  useChangeStatusApplication,
  useSingleApplication,
  useChangeCommissionAmountEdchimp,
  useChangeCommissionAmountAgent,
  useMarkCommissionAsPaidAgent,
} from '../../shared/hooks/UseApplication';
import {
  useAllNotifications,
  useUpdateNotification,
} from '../../shared/hooks/UseNotification';
import * as qs from 'qs';
import { useAllAgents, useCurrentAgent } from '../../shared/hooks/UseAgent';
import { ApplicationStatusEnum } from '../../domain/ApplicationStatusEnum';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { UserRoles } from '../../domain/UserRole';
import PermissionComponent from '../../components/auth/PermissionComponent';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../../components/Form/Form';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { URI } from '../../domain/constant';

function SingleApplication() {
  const user_data = useCheckAuthenticated();
  const { user } = user_data;

  const { updateApplicationStatusData } = useChangeStatusApplication();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const params = useParams();
  const { application_data } = useSingleApplication(params.id);
  const { application } = application_data;
  const [paymentErrorMessage, setPaymentErrorMessage] = useState(false);
  const payNowBtnHandler = async ({
    amount,
    plan,
    currency,
    application_id,
  }) => {
    try {
      setPaymentLoading(true);
      setPaymentErrorMessage(false);
      const processingFee = Number((amount * 3) / 100);
      const total_amount = Number(amount) + processingFee;
      var stripe = await loadStripe(
        'pk_live_51KgHwMEgCNSQ0rVCTGNaa47w82jEzZxo415rRWoOgnP5gijwreNowNxmKmRH3zAflbVaaezFeLrJ457N1u9TV3CE00cdLVCY4x'
      );

      var opts = { plan, total_amount };
      // checkoutButton.innerText = 'Processing.....';
      // const URI = 'http://localhost:3002';
      fetch(`${URI}/api/payments/create-checkout-session`, {
        method: 'POST',
        body: {
          application_id,
          currency,
          plan,
          amount: total_amount,
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (session) {
          return stripe.redirectToCheckout({ sessionId: session.id });
        })
        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          setPaymentLoading(false);
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function (error) {
          setPaymentErrorMessage(true);
          console.error('Error:', error);
        });
    } catch (error) {
      setPaymentErrorMessage(true);
    }
  };

  const { agent_data } = useCurrentAgent();

  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [notificationParam, setNotificationParam] = useState(null);

  // const [notificatiton_data, reloadNotificationData] =
  //   useSelectAllNotification();
  const [updateData] = useUpdateNotification();

  useEffect(() => {
    if (notificationParam) {
      updateData(notificationParam, { is_read: true });
      // reloadNotificationData();
    }
  }, [notificationParam]);

  useEffect(() => {
    if (queryParams.notification) {
      setNotificationParam(queryParams.notification);
    }
  }, [queryParams && queryParams.notification]);

  // state for ChangeCommissionAmountEdchimp
  const [showEditCommissionAmountEdchimp, setShowEditCommissionAmountEdchimp] =
    useState(false);
  const { changeCommissionAmountEdchimpFunction } =
    useChangeCommissionAmountEdchimp();
  const [showEditCommissionAmountAgent, setShowEditCommissionAmountAgent] =
    useState(false);
  const { changeCommissionAmountAgentFunction } =
    useChangeCommissionAmountAgent();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '450px',
    },
  };
  const [showAgentPaymentModel, setShowAgentPaymentModel] = useState(false);
  const { markCommissionAsPaidAgentFunction } = useMarkCommissionAsPaidAgent();

  return (
    <div>
      <Auth_header_mobile />
      <AuthHeader />
      <AuthBreadCrumb
        title={
          application &&
          application.applicationDetails &&
          `Application ID ${application.applicationDetails.application_id}`
        }
      />

      <section className='profile-main ptb-30'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <div class=''>
                <div className='main-sec-heading'>
                  <div
                    className='d-flex  justify-content-between '
                    style={{ flex: 1 }}
                  >
                    <div>
                      <h1>Show Application</h1>
                      {application &&
                        application.applicationDetails &&
                        application.applicationDetails.assigned_by && (
                          <div>
                            <PermissionComponent
                              name={'application'}
                              permission={'delete'}
                              hideOnAgent={true}
                              hideOnStudent={true}
                            >
                              Assigned By :{' '}
                              {
                                application.applicationDetails.assigned_by
                                  .first_name
                              }{' '}
                              {
                                application.applicationDetails.assigned_by
                                  .last_name
                              }
                            </PermissionComponent>
                          </div>
                        )}
                    </div>

                    <div className='d-flex gap-2'>
                      {application &&
                        application.applicationDetails &&
                        application.applicationDetails
                          .commission_prospect_edchimp && (
                          <PermissionComponent
                            name={'application'}
                            permission={'view_edchimp_prospect_commission'}
                            hideOnAgent={true}
                            hideOnStudent={true}
                          >
                            <div>
                              <div className='card'>
                                <div className='card-header'>
                                  <div className='d-flex justify-content-between gap-2 '>
                                    <div>Commission Edchimp</div>
                                    <div
                                      onClick={() =>
                                        setShowEditCommissionAmountEdchimp(
                                          !showEditCommissionAmountEdchimp
                                        )
                                      }
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <i
                                        className={
                                          showEditCommissionAmountEdchimp
                                            ? 'fa fa-close'
                                            : 'fa fa-pencil'
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className='card-body'>
                                  {showEditCommissionAmountEdchimp ? (
                                    <div className='price-card-title'>
                                      <Formik
                                        initialValues={{
                                          commission_prospect_edchimp:
                                            application.applicationDetails
                                              .commission_prospect_edchimp,
                                        }}
                                        validationSchema={Yup.object({
                                          commission_prospect_edchimp:
                                            Yup.string().required('Required'),
                                        })}
                                        onSubmit={async (
                                          values,
                                          { setSubmitting, resetForm }
                                        ) => {
                                          setSubmitting(true);
                                          changeCommissionAmountEdchimpFunction(
                                            params.id,
                                            values
                                          );

                                          setSubmitting(false);
                                        }}
                                      >
                                        {(formik) => {
                                          return (
                                            <Form>
                                              <div className='row'>
                                                <div className='col-md-9'>
                                                  <TextInput
                                                    label='Commission'
                                                    name='commission_prospect_edchimp'
                                                    type='text'
                                                    placeholder='Enter '
                                                  />
                                                </div>
                                                <div className='col-md-3'>
                                                  <button
                                                    type='submit'
                                                    className='btn btn-sm btn-primary'
                                                  >
                                                    <i className='fa fa-save'></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </Form>
                                          );
                                        }}
                                      </Formik>
                                    </div>
                                  ) : (
                                    <div className='price-card-title'>
                                      {!application.applicationDetails
                                        .paid_from_college && 'Prospect'}
                                      {
                                        application.applicationDetails
                                          .commission_prospect_edchimp
                                      }
                                      {application.applicationDetails.currency}
                                      <br />
                                      <span
                                        className={
                                          application.applicationDetails
                                            .paid_from_college
                                            ? 'badge badge inclass'
                                            : 'badge badge-draft'
                                        }
                                      >
                                        {application.applicationDetails
                                          .paid_from_college
                                          ? 'Paid'
                                          : 'Not Paid'}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </PermissionComponent>
                        )}
                      {application &&
                        application.applicationDetails &&
                        application.applicationDetails
                          .commission_prospect_agent && (
                          <PermissionComponent
                            name={'application'}
                            permission={'view_agent_prospect_commission'}
                            hideOnAgent={false}
                            hideOnStudent={true}
                            hideOnCounsellor={true}
                          >
                            <div>
                              <div className='card'>
                                <div className='card-header'>
                                  <div className='d-flex justify-content-between gap-2 '>
                                    <div>Commission Agent</div>
                                    <div
                                      onClick={() =>
                                        setShowEditCommissionAmountAgent(
                                          !showEditCommissionAmountAgent
                                        )
                                      }
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <i
                                        className={
                                          showEditCommissionAmountAgent
                                            ? 'fa fa-close'
                                            : 'fa fa-pencil'
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className='card-body'>
                                  {showEditCommissionAmountAgent ? (
                                    <div className='price-card-title'>
                                      <Formik
                                        initialValues={{
                                          commission_prospect_agent:
                                            application.applicationDetails
                                              .commission_prospect_agent,
                                        }}
                                        validationSchema={Yup.object({
                                          commission_prospect_agent:
                                            Yup.string().required('Required'),
                                        })}
                                        onSubmit={async (
                                          values,
                                          { setSubmitting, resetForm }
                                        ) => {
                                          setSubmitting(true);
                                          changeCommissionAmountAgentFunction(
                                            params.id,
                                            values
                                          );

                                          setSubmitting(false);
                                        }}
                                      >
                                        {(formik) => {
                                          return (
                                            <Form>
                                              <div className='row'>
                                                <div className='col-md-9'>
                                                  <TextInput
                                                    label='Commission'
                                                    name='commission_prospect_agent'
                                                    type='text'
                                                    placeholder='Enter '
                                                  />
                                                </div>
                                                <div className='col-md-3'>
                                                  <button
                                                    type='submit'
                                                    className='btn btn-sm btn-primary'
                                                  >
                                                    <i className='fa fa-save'></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </Form>
                                          );
                                        }}
                                      </Formik>
                                    </div>
                                  ) : (
                                    <div className='price-card-title'>
                                      {!application.applicationDetails
                                        .paid_to_agent && 'Prospect'}
                                      {application &&
                                        application.applicationDetails &&
                                        application.applicationDetails
                                          .commission_prospect_agent}
                                      {application.applicationDetails.currency}
                                      <br />
                                      <div className='d-flex justify-content-between gap-2'>
                                        <div>
                                          <span
                                            className={
                                              application.applicationDetails
                                                .paid_to_agent
                                                ? 'badge badge inclass'
                                                : 'badge badge-draft'
                                            }
                                          >
                                            {application.applicationDetails
                                              .paid_to_agent
                                              ? 'Paid'
                                              : 'Not Paid'}
                                          </span>
                                        </div>
                                        {!application.applicationDetails
                                          .paid_to_agent && (
                                          <div>
                                            <div>
                                              <div>
                                                <a
                                                  className='badge badge inclass'
                                                  onClick={() =>
                                                    setShowAgentPaymentModel(
                                                      true
                                                    )
                                                  }
                                                >
                                                  <i className='fa fa-check'></i>
                                                  Mark As Paid
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </PermissionComponent>
                        )}
                    </div>
                  </div>
                </div>
                {/* {user &&
                  (user.role === UserRoles.AGENT ||
                    user.role === UserRoles.STUDENT) && ( */}
                <>
                  {application &&
                    application.applicationDetails &&
                    application.applicationDetails
                      .application_current_status ===
                      ApplicationStatusEnum.APPLIED && (
                      <div class='export-flex'>
                        <div class=''>
                          {application.applicationDetails.application_fee >
                          0 ? (
                            <>
                              {paymentLoading ? (
                                <div>
                                  <button class='btn btn-generate'>
                                    <i className='fa fa-spin fa-spinner'></i>
                                    Loading
                                  </button>
                                </div>
                              ) : (
                                <button
                                  class='btn btn-generate'
                                  onClick={() =>
                                    payNowBtnHandler({
                                      amount:
                                        application.applicationDetails
                                          .application_fee +
                                        application.applicationDetails
                                          .application_fee *
                                          0.03,
                                      plan: `Application Fee for Application ${application.applicationDetails.application_id}`,
                                      application_id:
                                        application.applicationDetails._id,
                                      currency:
                                        application.applicationDetails &&
                                        application.applicationDetails
                                          .program &&
                                        application.applicationDetails.program
                                          .school &&
                                        application.applicationDetails.program
                                          .school.market &&
                                        application.applicationDetails.program
                                          .school.market.currency
                                          ? application.applicationDetails
                                              .program.school.market.currency
                                          : 'INR',
                                    })
                                  }
                                >
                                  <i class='fa fa-money'></i>Pay Now
                                </button>
                              )}

                              {paymentErrorMessage && (
                                <p> Something Went wrong </p>
                              )}
                            </>
                          ) : (
                            <button
                              class='btn btn-generate'
                              onClick={() =>
                                updateApplicationStatusData(
                                  application.applicationDetails._id,
                                  {
                                    application_status:
                                      ApplicationStatusEnum.APPLICATION_FEE_PAID,
                                  }
                                )
                              }
                            >
                              <i class='fa fa-money'></i>Pay Now
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='students-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              {application && (
                <SingleApplicationComponent
                  application={application.applicationDetails}
                  student={application.studentDetails}
                  applicationRequirements={application.applicationRequirements}
                  agent={application.agent}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal
        style={customStyles}
        isOpen={showAgentPaymentModel}
        onRequestClose={() => setShowAgentPaymentModel(false)}
        contentLabel='Change Status'
      >
        <h2> Enter Agent Commission Payout </h2>
        <Formik
          initialValues={{
            commission_prospect_agent: '',
            commission_received_notes_agent: '',
            commission_paid_agent_date: '',
          }}
          validationSchema={Yup.object({
            commission_prospect_agent: Yup.string().required('Required'),
            commission_paid_agent_date: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            // application.applicationDetails
            // .paid_from_college
            // updateApplicationStatusData(application._id, values);
            markCommissionAsPaidAgentFunction(
              application &&
                application.applicationDetails &&
                application.applicationDetails._id,
              values
            );
            setShowAgentPaymentModel(false);
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            //
            return (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='User'> Commission </label>
                    <TextInput name='commission_prospect_agent' type='number' />
                  </div>
                  <div className='col-md-12'>
                    <label htmlFor='User'> Notes </label>
                    <TextInput name='commission_received_notes_agent' />
                  </div>
                  <div className='col-md-12'>
                    <label htmlFor='User'> Payment Date </label>
                    <TextInput name='commission_paid_agent_date' type='date' />
                  </div>
                  <div className=''>
                    <button className='btn btn-success btn-sm' type='submit'>
                      <i className='fa fa-save'></i>
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <hr />
        <div className='d-flex justify-content-end'>
          <a onClick={() => setShowAgentPaymentModel(false)}>X</a>
        </div>
      </Modal>
      <AuthFooter />
    </div>
  );
}

export default SingleApplication;
