import {
  GET_APPLICATION_NOTES_STARTED,
  GET_APPLICATION_NOTES,
  GET_APPLICATION_NOTES_ENDED,
  ADD_APPLICATION_NOTE_STARTED,
  ADD_APPLICATION_NOTE,
  ADD_APPLICATION_NOTE_ENDED,
  EDIT_APPLICATION_NOTE_STARTED,
  EDIT_APPLICATION_NOTE,
  EDIT_APPLICATION_NOTE_ENDED,
  GET_APPLICATION_NOTE,
  GET_APPLICATION_NOTE_STARTED,
  GET_APPLICATION_NOTE_ENDED,
} from '../types/application_note_types';

const initialState = {
  application_notes_loading: true,
  application_notes: null,
  application_note_page: null,
  application_note_pages: null,
  total_application_notes: 0,

  application_note: null,
  application_note_loading: null,

  add_application_note_loading: true,
  edit_application_note_loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_APPLICATION_NOTES_STARTED:
      return {
        ...state,
        application_notes: null,
        application_note_pages: null,
        application_note_page: null,
        total_application_notes: 0,
        application_notes_loading: true,
      };
    case GET_APPLICATION_NOTES:
      return {
        ...state,
        application_notes: payload,
      };
    case GET_APPLICATION_NOTES_ENDED:
      return {
        ...state,
        application_notes_loading: false,
      };

    case ADD_APPLICATION_NOTE_STARTED:
      return {
        ...state,
        application_note_message: null,
        add_application_note_loading: true,
      };
    case ADD_APPLICATION_NOTE:
      return {
        ...state,
        application_note_message: payload,
      };
    case ADD_APPLICATION_NOTE_ENDED:
      return {
        ...state,
        add_application_note_loading: false,
      };
    case GET_APPLICATION_NOTE_STARTED:
      return {
        ...state,
        application_note: null,
        application_note_loading: true,
      };
    case GET_APPLICATION_NOTE:
      return {
        ...state,
        application_note: payload,
      };
    case GET_APPLICATION_NOTE_ENDED:
      return {
        ...state,
        application_note_loading: false,
      };
    case EDIT_APPLICATION_NOTE_STARTED:
      return {
        ...state,
        application_note_message: null,
        edit_application_note_loading: true,
      };
    case EDIT_APPLICATION_NOTE:
      return {
        ...state,
        application_note_message: payload,
      };
    case EDIT_APPLICATION_NOTE_ENDED:
      return {
        ...state,
        edit_application_note_loading: false,
      };

    default:
      return state;
  }
}
