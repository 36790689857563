export const GET_SCHOOLS_STARTED = 'GET_SCHOOLS_STARTED';
export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_SCHOOLS_ENDED = 'GET_SCHOOLS_ENDED';
export const GET_SCHOOLS_ERROR = 'GET_SCHOOLS_ERROR';
export const ADD_SCHOOL_STARTED = 'ADD_SCHOOL_STARTED';
export const ADD_SCHOOL = 'ADD_SCHOOL';
export const ADD_SCHOOL_ENDED = 'ADD_SCHOOL_ENDED';
export const ADD_SCHOOL_ERROR = 'ADD_SCHOOL_ERROR';
export const EDIT_SCHOOL_STARTED = 'EDIT_SCHOOL_STARTED';
export const EDIT_SCHOOL = 'EDIT_SCHOOL';
export const EDIT_SCHOOL_ENDED = 'EDIT_SCHOOL_ENDED';
export const EDIT_SCHOOL_ERROR = 'EDIT_SCHOOL_ERROR';
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOL_STARTED = 'GET_SCHOOL_STARTED';
export const GET_SCHOOL_ENDED = 'GET_SCHOOL_ENDED';
export const GET_SCHOOL_ERROR = 'GET_SCHOOL_ERROR';
// School Programs
export const GET_SCHOOL_PROGRAM_STARTED = 'GET_SCHOOL_PROGRAM_STARTED';
export const GET_SCHOOL_PROGRAM = 'GET_SCHOOL_PROGRAM';
export const GET_SCHOOL_PROGRAM_ENDED = 'GET_SCHOOL_PROGRAM_ENDED';
export const GET_SCHOOL_PROGRAM_ERROR = 'GET_SCHOOL_PROGRAM_ERROR';

// All Schools
export const GET_ALL_SCHOOLS_STARTED = 'GET_ALL_SCHOOLS_STARTED';
export const GET_ALL_SCHOOLS = 'GET_ALL_SCHOOLS';
