import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { TextInput, SelectBox } from '../../components/Form/Form';
import * as Yup from 'yup';
import { useUpdateAddressInfo } from '../../shared/hooks/UserAddressInfo';
import { Country, State, City } from 'country-state-city';
import CountryStateCity from '../common/CountryStateCity';
import SingleProfileComponent from '../cards/SingleProfileComponent';

export const AddressDetails = ({ student_info, hideEdit }) => {
  const { userAddress, student } = student_info;
  const { updateAddressInformation } = useUpdateAddressInfo();
  const [editing, setEditing] = useState(false);

  return (
    <>
      {student_info && (
        <div className='personal-information-box clearfix'>
          <div className='d-flex justify-content-between'>
            <h2>Address Details</h2>
            {!hideEdit && (
              <div>
                <button
                  className='btn btn-assign'
                  onClick={() => setEditing(!editing)}
                >
                  {' '}
                  {editing ? (
                    <i className='fa fa-close'></i>
                  ) : (
                    <i className='fa fa-pencil'></i>
                  )}{' '}
                </button>
              </div>
            )}
          </div>
          {editing ? (
            <Formik
              initialValues={{
                address:
                  userAddress && userAddress.address ? userAddress.address : '',
                city: userAddress && userAddress.city ? userAddress.city : '',
                country:
                  userAddress && userAddress.country ? userAddress.country : '',
                country_code:
                  userAddress && userAddress.country
                    ? Country.getAllCountries().filter(
                        (item) => item.name === userAddress.country
                      )[0].isoCode
                    : '',
                state:
                  userAddress && userAddress.state ? userAddress.state : '',
                state_code:
                  userAddress && userAddress.country && userAddress.state
                    ? State.getStatesOfCountry(
                        Country.getAllCountries().filter(
                          (item) => item.name === userAddress.country
                        )[0].isoCode
                      ) &&
                      State.getStatesOfCountry(
                        Country.getAllCountries().filter(
                          (item) => item.name === userAddress.country
                        )[0].isoCode
                      ).filter((item) => item.name == userAddress.state)[0]
                        .isoCode
                    : '',
                zipcode:
                  userAddress && userAddress.zipcode ? userAddress.zipcode : '',
              }}
              validationSchema={Yup.object({
                address: Yup.string().required('Required'),
                city: Yup.string().required('Required'),
                country: Yup.string().required('Required'),
                state: Yup.string().required('Required'),
                zipcode: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await updateAddressInformation(student._id, values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-12'>
                        <TextInput label='Address' name='address' />
                      </div>
                      <CountryStateCity formik={formik} />

                      <div className='col-md-12'>
                        <TextInput label='Postal/Zip code' name='zipcode' />
                      </div>
                    </div>
                    <div className='save-button'>
                      <button type='submit' className='btn primary-btn w-100'>
                        Save & Continue
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div>
              <div className='row'>
                <SingleProfileComponent
                  col={12}
                  label={'Address'}
                  value={userAddress && userAddress.address}
                />
                <SingleProfileComponent
                  col={12}
                  label={'Country'}
                  value={userAddress && userAddress.country}
                />
                <SingleProfileComponent
                  col={12}
                  label={'State'}
                  value={userAddress && userAddress.state}
                />
                <SingleProfileComponent
                  col={12}
                  label={'City'}
                  value={userAddress && userAddress.city}
                />
                <SingleProfileComponent
                  col={12}
                  label={'zipcode'}
                  value={userAddress && userAddress.zipcode}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
