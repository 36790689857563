import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import * as Yup from 'yup';
import SlabForm from './SlabForm';
import { useSingleSlab, useUpdateSlab } from '../../shared/hooks/UseSlab';

function EditSlabComponent() {
  const [initialZone, setInitialZone] = useState(null);
  const params = useParams();
  const { data } = useSingleSlab(params.id);
  const { slab, slab_loading } = data;
  const { updateSlabData } = useUpdateSlab();
  const navigate = useNavigate();
  useEffect(() => {
    if (slab) {
      const mappedZones = slab.zone.map((zone) => {
        return {
          commission: zone.commission,
          country: zone.country._id,
        };
      });
      setInitialZone(mappedZones);
    }
  }, [slab]);
  return (
    <div>
      {slab && initialZone && (
        <div className='personal-information-box clearfix'>
          <div className='main-sec-heading'>
            <h1>Edit Slab</h1>
          </div>

          <Formik
            initialValues={{
              name: slab.name ? slab.name : '',
              zone: initialZone ? initialZone : [],
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              updateSlabData(slab._id, values);
              navigate('/settings/slabs');
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return <SlabForm formik={formik} />;
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default EditSlabComponent;
