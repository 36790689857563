import React from 'react';

function ReceiptComponent() {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="receipt-box">
                <div className="receipt-box-flex">
                  <div className="invoice-number">
                    <h4>Invoice Number</h4>
                  </div>
                  <div className="invoice-number">
                    <h4>#20233-37535854752</h4>
                  </div>
                </div>
                <div className="receipt-box-flex">
                  <div className="invoice-number">
                    <h4>Billed To</h4>
                  </div>
                  <div className="invoice-number">
                    <h4>Shubham Pathak (shubhampathak1996@gmail.com)</h4>
                  </div>
                </div>
                <div className="receipt-box-flex">
                  <div className="invoice-number">
                    <h4>Issue Date</h4>
                  </div>
                  <div className="invoice-number">
                    <h4>Mar 27, 2023</h4>
                  </div>
                </div>
                <div className="receipt-box-flex">
                  <div className="invoice-number">
                    <h4>Student Name</h4>
                  </div>
                  <div className="invoice-number">
                    <h4>Shubham Pathak</h4>
                  </div>
                </div>
                <div className="item-details">
                  <h2>Item Details</h2>
                  <div className="item-details-flex">
                    <div className="item-name">
                      <h4>
                        Postgraduate Diploma - Respiratory Medicine, University
                        of Birmingham - Edgbaston
                      </h4>
                    </div>
                    <div className="item-price">
                      <p>£0.00 GBP</p>
                    </div>
                  </div>
                  <div className="item-details-flex">
                    <div className="item-name">
                      <h4>
                        Postgraduate Diploma - Respiratory Medicine, University
                        of Birmingham - Edgbaston
                      </h4>
                    </div>
                    <div className="item-price">
                      <p>£0.00 GBP</p>
                    </div>
                  </div>
                  <div className="item-total-price">
                    <h5>TOTAL: £0.00 GBP</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReceiptComponent;
