import {
  GET_AGENTS_STARTED,
  GET_AGENTS,
  GET_AGENTS_ENDED,
  ADD_AGENT_STARTED,
  ADD_AGENT,
  ADD_AGENT_ENDED,
  EDIT_AGENT_STARTED,
  EDIT_AGENT,
  EDIT_AGENT_ENDED,
  GET_AGENT,
  GET_AGENT_STARTED,
  GET_AGENT_ENDED,
  GET_CURRENT_AGENT,
  GET_CURRENT_AGENT_STARTED,
  GET_CURRENT_AGENT_ENDED,
} from '../types/agent_types';
import * as qs from 'qs';
import { api } from '../../domain/api';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

//addAgent
export const addAgent = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_AGENT_STARTED,
    });
    const { data } = await api.post(`/agents/add`, formData);
    dispatch({
      type: ADD_AGENT,
      payload: data,
    });
    dispatch({
      type: ADD_AGENT_ENDED,
    });
    dispatch(setAlert('Agent added Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: ADD_AGENT_ENDED,
    });
    // dispatch(setAlert('Failed ', 'danger'));
    dispatch(handleError(error));
  }
};

export const getAgents =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENTS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/agents?&${query}`);

      dispatch({
        type: GET_AGENTS,
        payload: data,
      });
      dispatch({
        type: GET_AGENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENTS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const filterAgents =
  ({ value = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENTS_STARTED,
      });
      let searchUrl = ``;
      if (value) {
        searchUrl = `/agents/filter`;
      } else {
        searchUrl = `/agents/filter`;
      }
      const { data } = await api.get(searchUrl);

      dispatch({
        type: GET_AGENTS,
        payload: data,
      });
      dispatch({
        type: GET_AGENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENTS_ENDED,
      });

      dispatch(handleError(error));
    }
  };

export const getAgent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AGENT_STARTED,
    });
    const { data } = await api.get(`/agents/${id}`);

    dispatch({
      type: GET_AGENT,
      payload: data,
    });
    dispatch({
      type: GET_AGENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getCurrentAgent = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CURRENT_AGENT_STARTED,
    });
    const { data } = await api.get(`/agents/current-user`);

    dispatch({
      type: GET_CURRENT_AGENT,
      payload: data,
    });
    dispatch({
      type: GET_CURRENT_AGENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const getAgentBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AGENT_STARTED,
    });
    const { data } = await api.get(`/agents/slug/${slug}`);

    dispatch({
      type: GET_AGENT,
      payload: data,
    });
    dispatch({
      type: GET_AGENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const updatePasswordAgent = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/${id}/change-password`, formData);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(setAlert('Password Updated Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const editAgent = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/edit-all-details/${id}`, formData);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(setAlert('Agent Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const assignCRMUser = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/assign-crm-user/${id}`, formData);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(setAlert('User Assigned Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const assignBulkUser =
  ({ agents, crm_user }) =>
  async (dispatch) => {
    try {
      const { data } = await api.post(`/agents/bulk-assign`, {
        agents: agents,
        crm_user: crm_user,
      });
      dispatch(setAlert('Users Assigned Successfully', 'success'));
      window.location.reload();
    } catch (error) {
      dispatch(handleError(error));
    }
  };
export const changeCommissionTier = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/update-slab-info/${id}`, formData);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(setAlert('Commission Tier Changed Successfully', 'success'));
    window.location.reload();
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const editAgentProfileStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/change-status/${id}`, formData);
    window.location.reload();
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(setAlert('Agent Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const editAgentInfo = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/agent-info/${id}`, formData);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(getCurrentAgent());
    dispatch(setAlert('Agent Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const editAgentBankInfo = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(
      `/agents//bank-account-info/${id}`,
      formData
    );
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch(getCurrentAgent());
    dispatch(setAlert('Agent Edited Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};

export const draftAgent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    dispatch({
      type: GET_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/${id}/draft`);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: GET_AGENT,
      payload: data,
    });

    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch({
      type: GET_AGENT_ENDED,
    });
    dispatch(setAlert('Move to draft successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch({
      type: GET_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const activateAgent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AGENT_STARTED,
    });
    const { data } = await api.put(`/agents/${id}/active`);
    dispatch({
      type: EDIT_AGENT,
      payload: data,
    });
    dispatch({
      type: GET_AGENT_STARTED,
    });
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch({
      type: GET_AGENT,
      payload: data,
    });
    dispatch({
      type: GET_AGENT_ENDED,
    });
    dispatch(setAlert('Agent Published Successfully', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_AGENT_ENDED,
    });
    dispatch({
      type: GET_AGENT_ENDED,
    });

    dispatch(handleError(error));
  }
};
export const deleteAgent = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/agents/${id}`);
    dispatch(setAlert('Agent Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleError(error));
  }
};
