import React from 'react';
import { SelectBox, TextInput } from '../Form/Form';
import { Country, State, City } from 'country-state-city';
import CreatableSelect from 'react-select/creatable';
function CountryStateCity({ formik, col }) {
  return (
    <>
      <div className={col ? `col-md-${col}` : 'col-md-12'}>
        <SelectBox
          label='Country'
          name='country_code'
          onChange={(e) => {
            formik.setFieldValue('country_code', e.target.value);
            formik.setFieldValue(
              'country',
              Country.getCountryByCode(e.target.value).name
            );
            formik.setFieldValue(
              'dial_code',
              Country.getCountryByCode(e.target.value).phonecode
            );
          }}
        >
          <option value=''></option>
          {Country.getAllCountries() &&
            Country.getAllCountries().map((e) => {
              return (
                <option key={e.isoCode} value={e.isoCode}>
                  {e.name}
                </option>
              );
            })}
        </SelectBox>
        {formik.touched.country && formik.errors && formik.errors.country ? (
          <div className='text-danger err'>{formik.errors.country}</div>
        ) : null}
      </div>
      <div className={col ? `col-md-${col}` : 'col-md-12'}>
        {formik.values.country_code &&
        State.getStatesOfCountry(formik.values.country_code) &&
        State.getStatesOfCountry(formik.values.country_code).length > 0 ? (
          <>
            <SelectBox
              label='State'
              name='state_code'
              onChange={(e) => {
                formik.setFieldValue('state_code', e.target.value);

                formik.setFieldValue(
                  'state',
                  State.getStateByCodeAndCountry(
                    e.target.value,
                    formik.values.country_code
                  ).name
                );
              }}
            >
              <option value=''></option>
              {State.getStatesOfCountry(formik.values.country_code).map((e) => {
                return (
                  <option key={e.name} value={e.isoCode}>
                    {e.name}
                  </option>
                );
              })}
            </SelectBox>
            {formik.touched.state && formik.errors && formik.errors.state ? (
              <div className='text-danger err'>{formik.errors.state}</div>
            ) : null}
          </>
        ) : (
          <TextInput label='Province/ State' name='state' />
        )}
      </div>
      <div className={col ? `col-md-${col}` : 'col-md-12'}>
        {formik.values.country_code &&
        formik.values.state_code &&
        City.getCitiesOfState(
          formik.values.country_code,
          formik.values.state_code
        ) &&
        City.getCitiesOfState(
          formik.values.country_code,
          formik.values.state_code
        ).length > 0 ? (
          <>
            <SelectBox label='City' name='city'>
              <option value=''></option>
              {City.getCitiesOfState(
                formik.values.country_code,
                formik.values.state_code
              ).map((e) => {
                return (
                  <option key={e.name} value={e.name}>
                    {e.name}
                  </option>
                );
              })}
            </SelectBox>
          </>
        ) : (
          <TextInput label='City' name='city' />
        )}
      </div>
    </>
  );
}

export default CountryStateCity;
